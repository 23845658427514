import m from 'mithril';
import Api from './components/Api';
import { Button, Card, Checkbox, Table } from 'construct-ui';
import sjefFilter from '../../views/components/sjefFilter';
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';
import sjefIconCopy from '@sjefapp/sjeficons/icons/sjefIconCopy';
import sjefPagination from '../../views/components/sjefPagination';
import infoToggle from '../../views/components/infoToggle';
import { _t } from '../../lib/i18n';
import app from '../../app';
import sjefBreadcrumbs from '../../views/components/sjefBreadcrumbs';
import sjefTh from '../../views/components/sjefTh';
import sjefButton from '../../views/components/sjefButton';
import sjefIconCreativeCommons from '@sjefapp/sjeficons/icons/sjefIconCreativeCommons';
import sjefLink from '../../views/components/sjefLink';

var filterData;
var sortData;

const loadList = async () => {
	var loadData = { ...filterData, ...vwAccounts.metadata, ...sortData };
	const results = await Api.get('accounts', loadData);
	vwAccounts.accounts = results.data;
	vwAccounts.metadata = results.metadata;
	m.redraw();
};

const duplicateRecord = async (id) => {
	// get account
	const currentObject = await app.get('/masterdata/accounts/' + id);

	if (currentObject.data) {
		//remove timestamps and id
		const newObject = currentObject.data;
		delete newObject.id;
		delete newObject.updated_at;
		delete newObject.created_at;
		delete newObject.paymentprofile_id;
		newObject.name = newObject.name + ' (copy)';

		const response = await app.post('/masterdata/accounts', newObject);

		if (response.result === 'success') {
			app.toast('Success', {
				intent: 'positive',
			});
			return m.route.set('/example');
		}

		app.toast(response.message || 'failed', {
			intent: 'negative',
		});
	} else {
		app.toast('failed', {
			intent: 'negative',
		});
	}
};

const deleteRecords = async (selected_records) => {
	if (selected_records.length > 0) {
		const response = await app.delete('/masterdata/accounts', {
			id: selected_records,
		});

		if (response.result === 'success') {
			app.toast('Success', {
				intent: 'positive',
			});
			return m.route.set('/example');
		}

		app.toast(response.message || 'failed', {
			intent: 'negative',
		});
	}
};

const vwAccounts = {
	accounts: [],
	metadata: {
		offset: 0,
		limit: 25,
		total: 0,
	},
	selected_accounts: [],

	oninit: async () => {
		await loadList();
		m.redraw();
	},
	view: () => {
		return m(
			'.accounts.vw-accounts',
			m('.pageHeader', [
				m('h3.title', 'Example - Accounts'),
				m(sjefBreadcrumbs),
				m(sjefFilter, {
					filters: [
						{
							name: 'id',
						},
						{
							name: 'date',
							type: 'date',
						},
						{
							name: 'name',
						},
						{
							name: 'active',
							type: 'boolean',
						},
					],
					onFilterUpdate: (filterReturnData) => {
						filterData = filterReturnData;
						loadList();
					},
				}),
				m(
					'.flexer.actions',
					m(sjefButton, {
						label: 'Nieuw',
						buttonLayout: 'filled',
						onclick: () => {
							m.route.set('/example/:id', { id: 'new' }, { state: { account: false } });
						},
					}),
					m(sjefButton, {
						label: 'Delete',
						buttonLayout: 'danger',
						disabled: !vwAccounts.selected_accounts.length,
						onclick: () => deleteRecords(vwAccounts.selected_accounts),
					}),
					m(sjefButton, {
						label: 'Archiveer',
						buttonLayout: 'inline',
						disabled: !vwAccounts.selected_accounts.length,
					})
				),
			]),
			m(
				'.pageContent',
				m(
					Card,
					m(
						'.flexer',
						m(sjefPagination, {
							paginationParams: vwAccounts.metadata,
							onPaginationUpdate: async (paginationReturnData) => {
								vwAccounts.metadata = Object.assign(vwAccounts.metadata, paginationReturnData);
								await loadList();
							},
						})
					),
					m(
						Table,
						{
							bordered: false,
							striped: true,
						},
						[
							m('tr', [
								m(sjefTh),
								m(sjefTh, {
									width: 'small',
									name: 'id',
									onSortUpdate: (sortReturnData) => {
										sortData = sortReturnData;
										loadList();
									},
								}),
								m(sjefTh, {
									name: 'name',
									onSortUpdate: (sortReturnData) => {
										sortData = sortReturnData;
										loadList();
									},
								}),
								m('th', 'active'),
								m('th', 'actions'),
							]),
							vwAccounts.accounts.map((account) => {
								return m('tr', [
									m(
										'td',
										{ style: { width: '20px' } },
										m(Checkbox, {
											class: 'js-accounts-checkbox',
											'data-account-id': account.id,
											checked: vwAccounts.selected_accounts.includes(account.id),

											onchange: () => {
												if (vwAccounts.selected_accounts.includes(account.id) == false) {
													vwAccounts.selected_accounts.push(account.id);
												} else {
													vwAccounts.selected_accounts.splice(vwAccounts.selected_accounts.indexOf(account.id), 1);
												}

												m.redraw();
											},
										})
									),
									m(
										'td',
										m(sjefLink, {
											label: account.id,
											href: `/example/${account.id}`,
										})
									),
									m('td', account.name),
									m('td', account.active == 1 ? 'yes' : 'no'),
									m(
										'td.actions',
										{
											onHover() {
												console.log('test');
											},
										},
										m(sjefIconEdit, {
											onclick: () => {
												m.route.set(
													'/example/:id',
													{ id: account.id },
													{
														state: {
															account: account,
														},
													}
												);
											},
										}),
										m(sjefIconCopy, {
											onclick: () => duplicateRecord(account.id),
										})
									),
								]);
							}),
						]
					),
					m(
						'.flexer',
						m(sjefPagination, {
							paginationParams: vwAccounts.metadata,
							onPaginationUpdate: async (paginationReturnData) => {
								vwAccounts.metadata = Object.assign(vwAccounts.metadata, paginationReturnData);
								await loadList();
							},
						})
					)
				)
			)
		);
	},
};

export default vwAccounts;
