import m from 'mithril';
import { _t } from '../../lib/i18n';
import 'swagger-ui-dist/swagger-ui.css';
import SwaggerUI from 'swagger-ui-dist/swagger-ui-bundle.js';
import layout from '../../views/layout';
import sjefButton from '../../views/components/sjefButton';

/**
 * @class
 * Mithril component for displaying combined Swagger documentation
 */
class vwDocumentationApi {
	constructor() {
		/** @type {Object} Combined Swagger documentation object */
		this.combinedSwaggerDoc = {};
	}

	/**
	 * Merge multiple swagger documents into a single document
	 * @param {Array<Object>} endpoints - Array of endpoint objects with a prefix and endpoint URL
	 * @returns {Promise<Object>} Combined Swagger document
	 */
	async mergeSwaggerDocs(endpoints) {
		const swaggerDocs = await Promise.all(endpoints.map(async ({ endpoint }) => await app.get(endpoint)));

		return swaggerDocs.reduce(
			(acc, doc, index) => {
				const prefixedPaths = {};
				const { prefix } = endpoints[index];

				Object.keys(doc.paths).forEach((path) => {
					prefixedPaths[`/${prefix}${path}`] = doc.paths[path];
				});

				acc.paths = { ...acc.paths, ...prefixedPaths };
				acc.definitions = { ...acc.definitions, ...doc.definitions };
				acc.tags = [...acc.tags, ...doc.tags];
				return acc;
			},
			{
				openapi: '3.0.0',
				info: {
					title: 'Documentation - Sjef API',
					version: '1.0.0',
				},
				host: process.env.API_ROOT,
				servers: [{ url: `` }],
				basePath: '/v2',
				schemes: ['https'],
				consumes: ['application/json'],
				produces: ['application/json'],
				components: {
					securitySchemes: {
						JWT: {
							type: 'http',
							description: 'JWT Bearer token in header',
							scheme: 'bearer',
							bearerFormat: 'JWT',
						},
					},
				},
				security: [
					{
						JWT: [],
					},
				],
				paths: {},
				definitions: {},
				tags: [],
			}
		);
	}

	/** @type {Array<Object>} Swagger endpoint configurations */
	swaggerUrls = [
		// {
		// 	prefix: 'hooliwood',
		// 	// endpoint: `/masterdata/docs/json`,
		// 	endpoint: `http://127.0.0.1:9999/docs/json`,
		// },
		// {
		// 	prefix: 'wallets',
		// 	// endpoint: `/auth/docs/json`,
		// 	endpoint: `http://127.0.0.1:3026/docs/json`,
		// },
		{
			prefix: 'authentication',
			endpoint: `/auth/docs/json`,
			// endpoint: `http://127.0.0.1:3999/docs/json`,
		},
		{
			prefix: 'balanceplatform',
			endpoint: `/balanceplatform/docs/json`,
		},
		{
			prefix: 'clients',
			endpoint: `/clients/docs/json`,
		},
		{
			prefix: 'masterdata',
			endpoint: `/masterdata/docs/json`,
			// endpoint: `http://127.0.0.1:3000/docs/json`,
		},
		// {
		// 	prefix: 'olap',
		// 	endpoint: `https://olap.sjef.dev/docs/json`,
		// 	// endpoint: `/olap/docs/json`,
		// 	// endpoint: `http://127.0.0.1:3022/docs/json`,
		// },
		{
			prefix: 'operations',
			endpoint: `/operations/docs/json`,
			// endpoint: `http://127.0.0.1:3019/docs/json`,
		},

		{
			prefix: 'payouts',
			endpoint: `/payouts/docs/json`,
		},
		{
			prefix: 'media',
			endpoint: `/media/docs/json`,
		},

		{
			prefix: 'settlements',
			endpoint: `/settlements/docs/json`,
		},

		{
			prefix: 'terminals',
			endpoint: `/terminals/docs/json`,
			// endpoint: `/terminals/docs/json`,
		},
		{
			prefix: 'transactions',
			endpoint: `/transactions/docs/json`,
		},
		{
			prefix: 'reporting',
			endpoint: `/reporting/docs/json`,
		},
		{
			prefix: 'user',
			endpoint: `/user/docs/json`,
			// endpoint: `http://127.0.0.1:3017/docs/json`,
		},
		{
			prefix: 'webhooks',
			endpoint: `/adyenWebhooks/docs/json`,
		},
	];

	/**
	 * Initialize Swagger UI
	 */
	async oncreate() {
		this.combinedSwaggerDoc = await this.mergeSwaggerDocs(this.swaggerUrls);

		SwaggerUI({
			spec: this.combinedSwaggerDoc,
			dom_id: '#swagger-ui',
			docExpansion: 'none',
		});
	}

	/**
	 * Render the component view
	 * @returns {Object} Mithril VNode tree
	 */
	view() {
		return m(
			layout,
			m(sjefButton, {
				buttonLayout: 'empty',
				label: 'Download JSON',
				onclick: async () => {
					const blob = new Blob([JSON.stringify(this.combinedSwaggerDoc, null, 2)], { type: 'application/json' });
					const url = URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					a.download = 'swagger.json';
					document.body.appendChild(a);
					a.click();
					URL.revokeObjectURL(url);
				},
			}),
			m('.swagger-container', { id: 'swagger-ui' })
		);
	}
}

export default vwDocumentationApi;
