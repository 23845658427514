import m from 'mithril';
/* eslint:disable */

class sjefTableSettings {
	view({ attrs: { pagination, tools, ...htmlAttrs } }) {
		const additionalClass = htmlAttrs.class ? htmlAttrs.class : '';

		return m('.sjefTableSettings', [
			tools ? m('.gridTools tableTools', tools.map((tool) => {
				if (tool) {
					return m('.gridTool', tool)
				}
			})) : void(0),
			pagination ? pagination : void(0)
		]
		);
	}
}

export default sjefTableSettings;
