import { Checkbox, Table } from 'construct-ui';
import m from 'mithril';
import app from '../../../app';

/**
 * Class for managing toggle columns in a table.
 */
class vwTabToggleColumns {
	columnNames;
	endpointData;
	endpointSubmit;

	dataItems = [];
	activeStates = {};
	toggleAllColumnStates = {};
	isToggleAll = false;

	/**
	 * @param {object} attrs - Initial attributes for the component.
	 */
	constructor({ attrs }) {
		this.columnNames = attrs.columns;
		this.endpointData = attrs.endpointData;
		this.endpointSubmit = attrs.endpointSubmit;
		this.record = attrs.record;

		this.initializeActiveStates();
	}

	/**
	 * Initialization method for Mithril.js
	 */
	async oninit() {
		const { data } = await app.get(this.endpointData);
		this.dataItems = data;
		this.updateToggleAllColumnStates();
	}

	/**
	 * Initialize active states for each column.
	 */
	initializeActiveStates() {
		this.columnNames.forEach((column) => {
			this.activeStates[column] = this.parseActiveStates(column);
			this.toggleAllColumnStates[column] = false;
		});
	}

	/**
	 * Parse active states for a column.
	 * @param {string} column - Column name.
	 * @returns {Array} Array of active states.
	 */
	parseActiveStates(column) {
		const value = this.record[column];
		return value ? value.split(',').filter(Boolean).map(Number) : [];
	}

	/**
	 * Update the state of the "toggle all" checkboxes.
	 */
	updateToggleAllColumnStates() {
		this.columnNames.forEach((column) => {
			this.toggleAllColumnStates[column] = this.isAllItemsActive(
				this.dataItems.map(({ id }) => id),
				this.activeStates[column]
			);
		});
		this.isToggleAll = this.columnNames.every((column) => this.toggleAllColumnStates[column]);
	}

	/**
	 * Check if all items in an array are active.
	 * @param {Array} array1 - First array.
	 * @param {Array} array2 - Second array.
	 * @returns {boolean} True if all items are active, false otherwise.
	 */
	isAllItemsActive(array1, array2) {
		return array1.every((item) => array2.includes(item));
	}

	/**
	 * Toggle the active state of an item.
	 * @param {number} itemId - ID of the item.
	 * @param {string} column - Column name.
	 */
	async toggleItemState(itemId, column) {
		const index = this.activeStates[column].indexOf(itemId);
		if (index === -1) {
			this.activeStates[column].push(itemId);
		} else {
			this.activeStates[column].splice(index, 1);
		}
		this.updateToggleAllColumnStates();
		await this.submit(column);
	}

	/**
	 * Toggle the active state of all items in a column or all columns.
	 * @param {string|null} column - Column name, or null to toggle all columns.
	 */
	async toggleAllStates(column = null) {
		if (column) {
			this.toggleAllColumnStates[column] = !this.toggleAllColumnStates[column];
			this.activeStates[column] = this.toggleAllColumnStates[column] ? this.dataItems.map(({ id }) => id) : [];
		} else {
			this.isToggleAll = !this.isToggleAll;
			this.columnNames.forEach((col) => {
				this.activeStates[col] = this.isToggleAll ? this.dataItems.map(({ id }) => id) : [];
				this.toggleAllColumnStates[col] = this.isToggleAll;
			});
		}
		this.updateToggleAllColumnStates();
		await this.submit(column);
	}

	/**
	 * Submit updated states to the server.
	 * @param {string|null} column - Column name, or null to update all columns.
	 */
	async submit(column = null) {
		const payload = { id: this.record.id };
		const columnsToUpdate = column ? [column] : this.columnNames;
		columnsToUpdate.forEach((col) => (payload[col] = this.activeStates[col].join(',')));

		const result = await app.patch(this.endpointSubmit, payload);
		app.toast(result.success ? 'success' : 'failed', { intent: result.success ? 'positive' : 'negative' });
	}

	/**
	 * Mithril view function.
	 * @returns {object} Mithril virtual DOM.
	 */
	view() {
		return m('div', [
			m('label', 'Toggle All Columns'),
			m(
				'div',
				this.columnNames.map((column) => `Current ${column}: ${this.activeStates[column].join(', ')}`)
			),
			m(Checkbox, { label: 'Toggle All', checked: this.isToggleAll, onchange: () => this.toggleAllStates() }),
			m(Table, { bordered: false, striped: true }, [
				m('thead', [m('tr', [m('th', 'Name'), ...this.columnNames.map((column) => m('th', column))])]),
				m('thead', [
					m('tr', [
						m('th', 'Name'),
						...this.columnNames.map((column) =>
							m(
								'th',
								m(Checkbox, {
									label: ``,
									checked: this.toggleAllColumnStates[column],
									onchange: () => this.toggleAllStates(column),
								})
							)
						),
					]),
				]),
				m(
					'tbody',
					this.dataItems.map((item) =>
						m('tr', [
							m('td', item.name),
							...this.columnNames.map((column) =>
								m(
									'td',
									m(Checkbox, {
										checked: this.activeStates[column].includes(item.id),
										onchange: () => this.toggleItemState(item.id, column),
									})
								)
							),
						])
					)
				),
			]),
		]);
	}
}

export default vwTabToggleColumns;
