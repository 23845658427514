import m from 'mithril'
import { Drawer } from 'construct-ui';
import app from '../../../app';
import { withHooks } from 'mithril-hooks';
import sjefTableLayout from '../../../views/components/sjefTableLayout';


const extractFieldsFromSchema = (config) => {
	if (!config || !Array.isArray(config.schema)) {
		return [];
	}

	const moduleSettings = ((app.userSettings || {}).tableLayouts || {})[config.moduleName] || {};

	return config.schema.flatMap((group) =>
		(group.fields || []).map((field) => {
			const fieldSettings = moduleSettings[field.name];
			if (fieldSettings) {
				return {
					...field,
					listShow: fieldSettings.show,
					listColumnPosition: fieldSettings.position,
				};
			}
			return field;
		})
	);
}

const TableLayout = withHooks(({ fields, state, config }) => {
	const { isDrawerOpen, setIsDrawerOpen, setFields } = state;
	const endpointTableLayout = '/user/settings/tableLayout';

	const fetchRecords = async (fields) => {
		const result = await app.patch(endpointTableLayout, {
			type: config.moduleName,
			settings: fields.reduce(
				(acc, field) => ({
					...acc,
					[field.name]: {
						show: field.listShow,
						position: field.listColumnPosition,
					},
				}),
				{}
			),
		});

		if (result.success) {
			const settingsResult = await app.get('/user/settings');

			app.userSettings = settingsResult.data;

			setFields(extractFieldsFromSchema(config))

			// this.triggerAction(vnode);
		}

	}

	return m(Drawer, {
		closeOnEscapeKey: true,
		closeOnOutsideClick: true,
		class: 'sjefDrawerlarge',
		content: [
			m(sjefTableLayout, {
				fields: fields,
				onModify: async (newFields) => {
					fetchRecords(newFields)
				},
				onCloseDrawer: () => setIsDrawerOpen(false),
			}),
		],
		hasBackdrop: true,
		transition: 300,
		position: 'right',
		isOpen: isDrawerOpen,
		onClose: () => (setIsDrawerOpen(false)),
	})
})
export default TableLayout
