import m from 'mithril';
import app from '../../../app';

import vwVerifyTfa from './vwVerifyTfa';
import vwSetupTfa from './vwSetupTfa';

const tfaForm = {
	view: ({ attrs: { loggedIn } }) => {
		return app.tfa.setup
			? m(vwVerifyTfa, {
					completed: () => {
						loggedIn();
					},
			  })
			: m(vwSetupTfa, {
					completed: async () => {
						await loggedIn();
						m.redraw();
					},
			  });
	},
};

export default tfaForm;
