const phoneNumberCountryCodes = [
    {"name": "Netherlands", "code": "NL", "countryCode": "+31"},
    {"name": "Belgium", "code": "BE", "countryCode": "+32"},
    {"name": "United Kingdom", "code": "GB", "countryCode": "+44"},
    {"name": "Germany", "code": "DE", "countryCode": "+49"},
    {"name": "Afghanistan", "code": "AF", "countryCode": "+93"},
    {"name": "Åland Islands", "code": "AX", "countryCode": "+358"},
    {"name": "Albania", "code": "AL", "countryCode": "+355"},
    {"name": "Algeria", "code": "DZ", "countryCode": "+213"},
    {"name": "American Samoa", "code": "AS", "countryCode": "+1-684"},
    {"name": "Andorra", "code": "AD", "countryCode": "+376"},
    {"name": "Angola", "code": "AO", "countryCode": "+244"},
    {"name": "Anguilla", "code": "AI", "countryCode": "+1-264"},
    {"name": "Antarctica", "code": "AQ", "countryCode": "+672"},
    {"name": "Antigua and Barbuda", "code": "AG", "countryCode": "+1-268"},
    {"name": "Argentina", "code": "AR", "countryCode": "+54"},
    {"name": "Armenia", "code": "AM", "countryCode": "+374"},
    {"name": "Aruba", "code": "AW", "countryCode": "+297"},
    {"name": "Australia", "code": "AU", "countryCode": "+61"},
    {"name": "Austria", "code": "AT", "countryCode": "+43"},
    {"name": "Azerbaijan", "code": "AZ", "countryCode": "+994"},
    {"name": "Bahamas", "code": "BS", "countryCode": "+1-242"},
    {"name": "Bahrain", "code": "BH", "countryCode": "+973"},
    {"name": "Bangladesh", "code": "BD", "countryCode": "+880"},
    {"name": "Barbados", "code": "BB", "countryCode": "+1-246"},
    {"name": "Belarus", "code": "BY", "countryCode": "+375"},
    {"name": "Belgium", "code": "BE", "countryCode": "+32"},
    {"name": "Belize", "code": "BZ", "countryCode": "+501"},
    {"name": "Benin", "code": "BJ", "countryCode": "+229"},
    {"name": "Bermuda", "code": "BM", "countryCode": "+1-441"},
    {"name": "Bhutan", "code": "BT", "countryCode": "+975"},
    {"name": "Bolivia", "code": "BO", "countryCode": "+591"},
    {"name": "Bosnia and Herzegovina", "code": "BA", "countryCode": "+387"},
    {"name": "Botswana", "code": "BW", "countryCode": "+267"},
    {"name": "Bouvet Island", "code": "BV", "countryCode": "+47"},
    {"name": "Brazil", "code": "BR", "countryCode": "+55"},
    {"name": "British Indian Ocean Territory", "code": "IO", "countryCode": "+246"},
    {"name": "Brunei Darussalam", "code": "BN", "countryCode": "+673"},
    {"name": "Bulgaria", "code": "BG", "countryCode": "+359"},
    {"name": "Burkina Faso", "code": "BF", "countryCode": "+226"},
    {"name": "Burundi", "code": "BI", "countryCode": "+257"},
    {"name": "Cambodia", "code": "KH", "countryCode": "+855"},
    {"name": "Cameroon", "code": "CM", "countryCode": "+237"},
    {"name": "Canada", "code": "CA", "countryCode": "+1"},
    {"name": "Cape Verde", "code": "CV", "countryCode": "+238"},
    {"name": "Cayman Islands", "code": "KY", "countryCode": "+1-345"},
    {"name": "Central African Republic", "code": "CF", "countryCode": "+236"},
    {"name": "Chad", "code": "TD", "countryCode": "+235"},
    {"name": "Chile", "code": "CL", "countryCode": "+56"},
    {"name": "China", "code": "CN", "countryCode": "+86"},
    {"name": "Christmas Island", "code": "CX", "countryCode": "+61"},
    {"name": "Cocos (Keeling) Islands", "code": "CC", "countryCode": "+61"},
    {"name": "Colombia", "code": "CO", "countryCode": "+57"},
    {"name": "Comoros", "code": "KM", "countryCode": "+269"},
    {"name": "Congo", "code": "CG", "countryCode": "+242"},
    {"name": "Congo, The Democratic Republic of the", "code": "CD", "countryCode": "+243"},
    {"name": "Cook Islands", "code": "CK", "countryCode": "+682"},
    {"name": "Costa Rica", "code": "CR", "countryCode": "+506"},
    {"name": "Croatia", "code": "HR", "countryCode": "+385"},
    {"name": "Cuba", "code": "CU", "countryCode": "+53"},
    {"name": "Cyprus", "code": "CY", "countryCode": "+357"},
    {"name": "Czech Republic", "code": "CZ", "countryCode": "+420"},
    {"name": "Denmark", "code": "DK", "countryCode": "+45"},
    {"name": "Djibouti", "code": "DJ", "countryCode": "+253"},
    {"name": "Dominica", "code": "DM", "countryCode": "+1-767"},
    {"name": "Dominican Republic", "code": "DO", "countryCode": "+1-809"},
    {"name": "Ecuador", "code": "EC", "countryCode": "+593"},
    {"name": "Egypt", "code": "EG", "countryCode": "+20"},
    {"name": "El Salvador", "code": "SV", "countryCode": "+503"},
    {"name": "Equatorial Guinea", "code": "GQ", "countryCode": "+240"},
    {"name": "Eritrea", "code": "ER", "countryCode": "+291"},
    {"name": "Estonia", "code": "EE", "countryCode": "+372"},
    {"name": "Ethiopia", "code": "ET", "countryCode": "+251"},
    {"name": "Falkland Islands (Malvinas)", "code": "FK", "countryCode": "+500"},
    {"name": "Faroe Islands", "code": "FO", "countryCode": "+298"},
    {"name": "Fiji", "code": "FJ", "countryCode": "+679"},
    {"name": "Finland", "code": "FI", "countryCode": "+358"},
    {"name": "France", "code": "FR", "countryCode": "+33"},
    {"name": "French Guiana", "code": "GF", "countryCode": "+594"},
    {"name": "French Polynesia", "code": "PF", "countryCode": "+689"},
    {"name": "French Southern Territories", "code": "TF", "countryCode": "+262"},
    {"name": "Gabon", "code": "GA", "countryCode": "+241"},
    {"name": "Gambia", "code": "GM", "countryCode": "+220"},
    {"name": "Georgia", "code": "GE", "countryCode": "+995"},
    {"name": "Germany", "code": "DE", "countryCode": "+49"},
    {"name": "Ghana", "code": "GH", "countryCode": "+233"},
    {"name": "Gibraltar", "code": "GI", "countryCode": "+350"},
    {"name": "Greece", "code": "GR", "countryCode": "+30"},
    {"name": "Greenland", "code": "GL", "countryCode": "+299"},
    {"name": "Grenada", "code": "GD", "countryCode": "+1-473"},
    {"name": "Guadeloupe", "code": "GP", "countryCode": "+590"},
    {"name": "Guam", "code": "GU", "countryCode": "+1-671"},
    {"name": "Guatemala", "code": "GT", "countryCode": "+502"},
    {"name": "Guernsey", "code": "GG", "countryCode": "+44-1481"},
    {"name": "Guinea", "code": "GN", "countryCode": "+224"},
    {"name": "Guinea-Bissau", "code": "GW", "countryCode": "+245"},
    {"name": "Guyana", "code": "GY", "countryCode": "+592"},
    {"name": "Haiti", "code": "HT", "countryCode": "+509"},
    {"name": "Heard Island and Mcdonald Islands", "code": "HM", "countryCode": "+672"},
    {"name": "Holy See (Vatican City State)", "code": "VA", "countryCode": "+379"},
    {"name": "Honduras", "code": "HN", "countryCode": "+504"},
    {"name": "Hong Kong", "code": "HK", "countryCode": "+852"},
    {"name": "Hungary", "code": "HU", "countryCode": "+36"},
    {"name": "Iceland", "code": "IS", "countryCode": "+354"},
    {"name": "India", "code": "IN", "countryCode": "+91"},
    {"name": "Indonesia", "code": "ID", "countryCode": "+62"},
    {"name": "Iran, Islamic Republic Of", "code": "IR", "countryCode": "+98"},
    {"name": "Iraq", "code": "IQ", "countryCode": "+964"},
    {"name": "Ireland", "code": "IE", "countryCode": "+353"},
    {"name": "Isle of Man", "code": "IM", "countryCode": "+44-1624"},
    {"name": "Israel", "code": "IL", "countryCode": "+972"},
    {"name": "Italy", "code": "IT", "countryCode": "+39"},
    {"name": "Jamaica", "code": "JM", "countryCode": "+1-876"},
    {"name": "Japan", "code": "JP", "countryCode": "+81"},
    {"name": "Jersey", "code": "JE", "countryCode": "+44-1534"},
    {"name": "Jordan", "code": "JO", "countryCode": "+962"},
    {"name": "Kazakhstan", "code": "KZ", "countryCode": "+7"},
    {"name": "Kenya", "code": "KE", "countryCode": "+254"},
    {"name": "Kiribati", "code": "KI", "countryCode": "+686"},
    {"name": "Korea, Republic of", "code": "KR", "countryCode": "+82"},
    {"name": "Kuwait", "code": "KW", "countryCode": "+965"},
    {"name": "Kyrgyzstan", "code": "KG", "countryCode": "+996"},
    {"name": "Latvia", "code": "LV", "countryCode": "+371"},
    {"name": "Lebanon", "code": "LB", "countryCode": "+961"},
    {"name": "Lesotho", "code": "LS", "countryCode": "+266"},
    {"name": "Liberia", "code": "LR", "countryCode": "+231"},
    {"name": "Libyan Arab Jamahiriya", "code": "LY", "countryCode": "+218"},
    {"name": "Liechtenstein", "code": "LI", "countryCode": "+423"},
    {"name": "Lithuania", "code": "LT", "countryCode": "+370"},
    {"name": "Luxembourg", "code": "LU", "countryCode": "+352"},
    {"name": "Macao", "code": "MO", "countryCode": "+853"},
    {"name": "North Macedonia", "code": "MK", "countryCode": "+389"},
    {"name": "Madagascar", "code": "MG", "countryCode": "+261"},
    {"name": "Malawi", "code": "MW", "countryCode": "+265"},
    {"name": "Malaysia", "code": "MY", "countryCode": "+60"},
    {"name": "Maldives", "code": "MV", "countryCode": "+960"},
    {"name": "Mali", "code": "ML", "countryCode": "+223"},
    {"name": "Malta", "code": "MT", "countryCode": "+356"},
    {"name": "Marshall Islands", "code": "MH", "countryCode": "+692"},
    {"name": "Martinique", "code": "MQ", "countryCode": "+596"},
    {"name": "Mauritania", "code": "MR", "countryCode": "+222"},
    {"name": "Mauritius", "code": "MU", "countryCode": "+230"},
    {"name": "Mayotte", "code": "YT", "countryCode": "+262"},
    {"name": "Mexico", "code": "MX", "countryCode": "+52"},
    {"name": "Micronesia, Federated States of", "code": "FM", "countryCode": "+691"},
    {"name": "Moldova, Republic of", "code": "MD", "countryCode": "+373"},
    {"name": "Monaco", "code": "MC", "countryCode": "+377"},
    {"name": "Mongolia", "code": "MN", "countryCode": "+976"},
    {"name": "Montserrat", "code": "MS", "countryCode": "+1-664"},
    {"name": "Morocco", "code": "MA", "countryCode": "+212"},
    {"name": "Mozambique", "code": "MZ", "countryCode": "+258"},
    {"name": "Myanmar", "code": "MM", "countryCode": "+95"},
    {"name": "Namibia", "code": "NA", "countryCode": "+264"},
    {"name": "Nauru", "code": "NR", "countryCode": "+674"},
    {"name": "Nepal", "code": "NP", "countryCode": "+977"},
    {"name": "Netherlands", "code": "NL", "countryCode": "+31"},
    {"name": "Netherlands Antilles", "code": "AN", "countryCode": "+599"},
    {"name": "New Caledonia", "code": "NC", "countryCode": "+687"},
    {"name": "New Zealand", "code": "NZ", "countryCode": "+64"},
    {"name": "Nicaragua", "code": "NI", "countryCode": "+505"},
    {"name": "Niger", "code": "NE", "countryCode": "+227"},
    {"name": "Nigeria", "code": "NG", "countryCode": "+234"},
    {"name": "Niue", "code": "NU", "countryCode": "+683"},
    {"name": "Norfolk Island", "code": "NF", "countryCode": "+672"},
    {"name": "Northern Mariana Islands", "code": "MP", "countryCode": "+1-670"},
    {"name": "Norway", "code": "NO", "countryCode": "+47"},
    {"name": "Oman", "code": "OM", "countryCode": "+968"},
    {"name": "Pakistan", "code": "PK", "countryCode": "+92"},
    {"name": "Palau", "code": "PW", "countryCode": "+680"},
    {"name": "Palestinian Territory, Occupied", "code": "PS", "countryCode": "+970"},
    {"name": "Panama", "code": "PA", "countryCode": "+507"},
    {"name": "Papua New Guinea", "code": "PG", "countryCode": "+675"},
    {"name": "Paraguay", "code": "PY", "countryCode": "+595"},
    {"name": "Peru", "code": "PE", "countryCode": "+51"},
    {"name": "Philippines", "code": "PH", "countryCode": "+63"},
    {"name": "Pitcairn Islands", "code": "PN", "countryCode": "+64"},
    {"name": "Poland", "code": "PL", "countryCode": "+48"},
    {"name": "Portugal", "code": "PT", "countryCode": "+351"},
    {"name": "Puerto Rico", "code": "PR", "countryCode": "+1-787"},
    {"name": "Qatar", "code": "QA", "countryCode": "+974"},
    {"name": "Reunion", "code": "RE", "countryCode": "+262"},
    {"name": "Romania", "code": "RO", "countryCode": "+40"},
    {"name": "Russian Federation", "code": "RU", "countryCode": "+7"},
    {"name": "Rwanda", "code": "RW", "countryCode": "+250"},
    {"name": "Saint Helena", "code": "SH", "countryCode": "+290"},
    {"name": "Saint Kitts and Nevis", "code": "KN", "countryCode": "+1-869"},
    {"name": "Saint Lucia", "code": "LC", "countryCode": "+1-758"},
    {"name": "Saint Pierre and Miquelon", "code": "PM", "countryCode": "+508"},
    {"name": "Saint Vincent and the Grenadines", "code": "VC", "countryCode": "+1-784"},
    {"name": "Samoa", "code": "WS", "countryCode": "+685"},
    {"name": "San Marino", "code": "SM", "countryCode": "+378"},
    {"name": "Sao Tome and Principe", "code": "ST", "countryCode": "+239"},
    {"name": "Saudi Arabia", "code": "SA", "countryCode": "+966"},
    {"name": "Senegal", "code": "SN", "countryCode": "+221"},
    {"name": "Serbia and Montenegro", "code": "CS", "countryCode": "+381"},
    {"name": "Seychelles", "code": "SC", "countryCode": "+248"},
    {"name": "Sierra Leone", "code": "SL", "countryCode": "+232"},
    {"name": "Singapore", "code": "SG", "countryCode": "+65"},
    {"name": "Slovakia", "code": "SK", "countryCode": "+421"},
    {"name": "Slovenia", "code": "SI", "countryCode": "+386"},
    {"name": "Solomon Islands", "code": "SB", "countryCode": "+677"},
    {"name": "Somalia", "code": "SO", "countryCode": "+252"},
    {"name": "South Africa", "code": "ZA", "countryCode": "+27"},
    {"name": "South Georgia and the South Sandwich Islands", "code": "GS", "countryCode": "+500"},
    {"name": "Spain", "code": "ES", "countryCode": "+34"},
    {"name": "Sri Lanka", "code": "LK", "countryCode": "+94"},
    {"name": "Sudan", "code": "SD", "countryCode": "+249"},
    {"name": "Suriname", "code": "SR", "countryCode": "+597"},
    {"name": "Svalbard and Jan Mayen", "code": "SJ", "countryCode": "+47"},
    {"name": "Swaziland", "code": "SZ", "countryCode": "+268"},
    {"name": "Sweden", "code": "SE", "countryCode": "+46"},
    {"name": "Switzerland", "code": "CH", "countryCode": "+41"},
    {"name": "Syrian Arab Republic", "code": "SY", "countryCode": "+963"},
    {"name": "Taiwan", "code": "TW", "countryCode": "+886"},
    {"name": "Tajikistan", "code": "TJ", "countryCode": "+992"},
    {"name": "Tanzania, United Republic of", "code": "TZ", "countryCode": "+255"},
    {"name": "Thailand", "code": "TH", "countryCode": "+66"},
    {"name": "Timor-Leste", "code": "TL", "countryCode": "+670"},
    {"name": "Togo", "code": "TG", "countryCode": "+228"},
    {"name": "Tokelau", "code": "TK", "countryCode": "+690"},
    {"name": "Tonga", "code": "TO", "countryCode": "+676"},
    {"name": "Trinidad and Tobago", "code": "TT", "countryCode": "+1-868"},
    {"name": "Tunisia", "code": "TN", "countryCode": "+216"},
    {"name": "Turkey", "code": "TR", "countryCode": "+90"},
    {"name": "Turkmenistan", "code": "TM", "countryCode": "+993"},
    {"name": "Turks and Caicos Islands", "code": "TC", "countryCode": "+1-649"},
    {"name": "Tuvalu", "code": "TV", "countryCode": "+688"},
    {"name": "Uganda", "code": "UG", "countryCode": "+256"},
    {"name": "Ukraine", "code": "UA", "countryCode": "+380"},
    {"name": "United Arab Emirates", "code": "AE", "countryCode": "+971"},
    {"name": "United Kingdom", "code": "GB", "countryCode": "+44"},
    {"name": "United States", "code": "US", "countryCode": "+1"},
    {"name": "United States Minor Outlying Islands", "code": "UM", "countryCode": "+1"},
    {"name": "Uruguay", "code": "UY", "countryCode": "+598"},
    {"name": "Uzbekistan", "code": "UZ", "countryCode": "+998"},
    {"name": "Vanuatu", "code": "VU", "countryCode": "+678"},
    {"name": "Venezuela", "code": "VE", "countryCode": "+58"},
    {"name": "Vietnam", "code": "VN", "countryCode": "+84"},
    {"name": "Virgin Islands, British", "code": "VG", "countryCode": "+1-284"},
    {"name": "Virgin Islands, U.S.", "code": "VI", "countryCode": "+1-340"},
    {"name": "Wallis and Futuna", "code": "WF", "countryCode": "+681"},
    {"name": "Western Sahara", "code": "EH", "countryCode": "+212"},
    {"name": "Yemen", "code": "YE", "countryCode": "+967"},
    {"name": "Zambia", "code": "ZM", "countryCode": "+260"},
    {"name": "Zimbabwe", "code": "ZW", "countryCode": "+263"}
];

module.exports = phoneNumberCountryCodes;
