import m from 'mithril';
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';

import { Button, Card, Checkbox, Drawer, Table } from 'construct-ui';
import sjefFilter from '../../views/components/sjefFilter';
import sjefPagination from '../../views/components/sjefPagination';
import sjefBreadcrumbs from '../../views/components/sjefBreadcrumbs';
import sjefTh from '../../views/components/sjefTh';
import { _t } from '../../lib/i18n';
import { awaitLoad } from '../../lib/helpers';

import vwBaseRecords from '../../classes/vwBaseRecords';
import sjefButton from '../../views/components/sjefButton';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconServerCog from '@sjefapp/sjeficons/icons/sjefIconServerCog';
import sjefTableLayout from '../../views/components/sjefTableLayout';
import app from '../../app';
import sjefLink from '../../views/components/sjefLink';

class vwClients extends vwBaseRecords {
	endpoint = `/clients/clients`;
	filterOpen = false;
	drawerOpen = false;
	fields = [
		{ name: 'id', listColumnPosition: 0, listShow: true, draggable: false, listActiveToggle: false },
		{ name: 'name', listColumnPosition: 1, listShow: true, draggable: true, listActiveToggle: true },
		{ name: 'companyNumber', listColumnPosition: 2, listShow: true, draggable: true, listActiveToggle: true },
		{ name: 'active', listColumnPosition: 3, listShow: true, draggable: true, listActiveToggle: true },
	];

	view() {
		return m(
			'.clients.vw-clients',
			m(Drawer, {
				closeOnEscapeKey: true,
				closeOnOutsideClick: true,
				content: [
					m(sjefTableLayout, {
						fields: this.fields,
						onModify: (newFields) => {
							this.fields = newFields;
						},
						onCloseDrawer: () => {
							this.drawerOpen = false;
						},
					}),
				],
				hasBackdrop: true,
				transition: 300,
				position: 'right',
				isOpen: this.drawerOpen,
				// isOpen: true,
				onClose: () => (this.drawerOpen = false),
			}),
			m(
				'.pageHeader',
				{
					class: this.filterOpen ? 'filterOpen' : '',
				},
				m('.title', [m('h3', 'Clients')]),
				m(
					'.sjefBreadcrumbWrapper',
					m(sjefBreadcrumbs),
					m(sjefButton, {
						buttonLayout: 'empty',
						label: m(sjefIconServerCog),
						onclick: () => (this.drawerOpen = true),
					})
				),
				m(
					'.filterToggle',
					{
						onclick: () => (this.filterOpen = !this.filterOpen),
					},
					m(sjefIconChevronDown)
				),

				m(
					'.actions.flexer',
					m(sjefButton, {
						buttonLayout: 'filled',
						label: _t('_.buttons.new'),
						onclick: () => {
							m.route.set('/clients/:id', { id: 'new' });
						},
					}),
					m(sjefButton, {
						buttonLayout: 'danger',
						label: _t('_.buttons.delete'),
						disabled: !this.selectedRecords.length,
						onclick: () => this.deleteRecords(),
					})
				),
				m(sjefFilter, {
					filters: [
						{
							name: 'id',
							type: 'text',
						},
						{
							name: 'name',
							type: 'text',
						},
						{
							name: 'active',
							type: 'boolean',
						},
					],
					onFilterUpdate: (filterReturnData) => {
						this.filterData = filterReturnData;
						this.getRecords();
					},
				})
			),

			this.isLoading
				? m('.loading', 'Loading...')
				: m(
						Card,
						{
							// class: awaitLoad(this.records),
						},
						m(
							'',
							// { class: awaitLoad(this.records) },
							m('', app.user.sub),
							m(
								'.flexer',
								m(sjefPagination, {
									subContent:
										this.selectedRecords.length > 0
											? this.selectedRecords.length + ' ' + _t('_.fields.selected', { defaultValue: 'Selected' })
											: void 0,
									paginationParams: this.metadata,
									onPaginationUpdate: async (paginationReturnData) => {
										this.toggleAllRecordsSelection(false);
										this.paginateRecords(paginationReturnData);
									},
								})
							),
							m(
								Table,
								{
									bordered: false,
									striped: true,
								},
								[
									m('tr', [
										m(
											'th',
											{
												class: 'cSmall',
											},
											m(Checkbox, {
												class: 'selectRecord',
												onchange: (evt) => this.toggleAllRecordsSelection(evt.target.checked),
											})
										),
										...this.fields
											.sort((a, b) => a.listColumnPosition - b.listColumnPosition)
											.filter((field) => field.listShow == true)
											.map((field) => {
												if (field.name === 'id') {
													return m(sjefTh, {
														name: 'id',
														class: 'cSmall',
														label: _t('_.fields.id'),
														onSortUpdate: (sortReturnData) => this.sortRecords(sortReturnData),
													});
												}
												if (field.name === 'name') {
													return m(sjefTh, {
														name: 'name',
														label: _t('_.th.name'),
														onSortUpdate: (sortReturnData) => this.sortRecords(sortReturnData),
													});
												}
												if (field.name === 'companyNumber') {
													return m('th', _t('_.fields.companyNumber'));
												}
												if (field.name === 'active') {
													return m('th', _t('_.fields.active'));
												}
											}),
										m('th', { class: 'cSmall' }, ''),
									]),

									this.records.map((record) => {
										return m('tr', [
											m(
												'td',
												m(Checkbox, {
													checked: this.selectedRecords.includes(record.id),
													onchange: () => this.toggleRecordSelection(record.id),
												})
											),
											...this.fields
												.sort((a, b) => a.listColumnPosition - b.listColumnPosition)
												.filter((field) => field.listShow == true)
												.map((field) => {
													if (field.name === 'id') {
														return m(
															'td',
															m(sjefLink, {
																label: record.id,
																href: '/clients/' + record.id,
															})
														);
													}
													if (field.name === 'name') {
														return m(
															'td',
															m(sjefLink, {
																label: record.name,
																href: '/clients/' + record.name,
															})
														);
													}
													if (field.name === 'companyNumber') {
														return m('td', record.companyNumber);
													}
													if (field.name === 'active') {
														return m('td', record.active);
													}
												}),
											m(
												'td',
												m(sjefButton, {
													buttonLayout: 'inline',
													label: m(sjefIconEdit),
													href: '/clients/' + record.id,
												})
											),
										]);
									}),
								]
							),
							m(
								'.flexer',
								m(sjefPagination, {
									paginationParams: this.metadata,
									onPaginationUpdate: async (paginationReturnData) => {
										this.paginateRecords(paginationReturnData);
									},
								})
							)
						)
				  )
		);
	}
}

export default vwClients;
