import m from 'mithril';
import eventsListEvent from './eventsListEvent';
import sjefTable from './sjefTable';
import sjefTh from './sjefTh';

class eventsList {
	events = null;

	oninit(vnode) {
		console.log('vnode', vnode)
		if (vnode.attrs.events) {
			this.events = vnode.attrs.events;
		} else {
			return;
		}

		console.log(this.events);
	}

	view(vnode) {
		return [
			// m(
			// 	'ul.eventsList',
			// 	this.events && this.events.map((event) => {
			// 		return m(eventsListEvent, {
			// 			event: event,
			// 			onDelete: () => {
			// 				if (typeof vnode.attrs.onDelete === 'function') {
			// 					vnode.attrs.onDelete();
			// 				}
			// 			}
			// 		});
			// 	})
			// ),
			this.events &&
			// m(sjefTable, {
			// 	content: [
			// 		m('tr', [
			// 			m('tr'),
			// 			m('tr', _t('_.fields.name')),
			// 			m('tr', _t('_.fields.eventStart')),
			// 			m('tr', _t('_.fields.eventEnd')),
			// 			m('tr', _t('_.fields.name')),
			// 		])
			// 	]
			// })

			m(sjefTable, {
				records: this.events,
				config: 'lockerEvents',
				fieldsToExclude: ['active', 'id']
			})
		];
	}
}

export default eventsList;
