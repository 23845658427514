import m from 'mithril';
import { hasCommonElements, generateFieldsFromRecords, goToHref, countTrueActions } from '../../lib/helpers';
import { Card, PopoverMenu, Table, Switch } from 'construct-ui';
import vwBaseRecords from '../../classes/vwBaseRecords';
import { _t } from '../../lib/i18n';
import Sortable from 'sortablejs';
import sjefTableSettings from './sjefTableSettings';
import { Checkbox } from 'construct-ui';
import sjefLink from './sjefLink';
import { downloadBase64 } from '../../utils/files';
import sjefTh from './sjefTh';
import moduleConfigs from '../../moduleConfigs/index';
import sjefIconCheckbox from '@sjefapp/sjeficons/icons/sjefIconCheckbox';
import sjefIconMoodAngry from '@sjefapp/sjeficons/icons/sjefIconMoodAngry';
import sjefButton from './sjefButton';
import sjefModal from './sjefModal';
import sjefIconDragDrop2 from '@sjefapp/sjeficons/icons/sjefIconDragDrop2';
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';
import sjefIconTrash from '@sjefapp/sjeficons/icons/sjefIconTrash';
import sjefIconTrash from '@sjefapp/sjeficons/icons/sjefIconTrash';
import sjefIconDownload from '@sjefapp/sjeficons/icons/sjefIconDownload';
import sjefPagination from './sjefPagination';
import app from '../../app';
import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefFilter from './sjefFilter';
import sjefIconArrowsSort from '@sjefapp/sjeficons/icons/sjefIconArrowsSort';
import sjefCheckbox from './sjefCheckbox';
import sjefPopover from './sjefPopover';
import tableStore from '../../lib/tableStore';
import actionsMenu from './actionsMenu';
import sjefIconClock from '@sjefapp/sjeficons/icons/sjefIconClock';
import sjefLoading from '../../views/components/sjefLoading';

/* eslint:disable */
const listSizes = {
	fixed: 'cFixed',
	small: 'cSmall',
	medium: 'cMedium',
	large: 'cLarge',
};

let listDragSort = false;
let listDragSortField = false;

document.addEventListener('keydown', function (event) {
	if (event.key === 'Escape' && listDragSort) {
		listDragSort = false;
		m.redraw();
	}
});

function extractFieldsFromSchema(config) {
	if (!config || !Array.isArray(config.schema)) {
		return [];
	}

	const moduleSettings = ((app.userSettings || {}).tableLayouts || {})[config.moduleName] || {};

	return config.schema.flatMap((group) =>
		(group.fields || []).map((field) => {
			const fieldSettings = moduleSettings[field.name];
			if (fieldSettings) {
				return {
					...field,
					listShow: fieldSettings.show,
					listColumnPosition: fieldSettings.position,
				};
			}
			return field;
		})
	);
}

const toggleDragSort = (field) => {
	// todo: when clicking multiple
	listDragSortField = field;
	listDragSort = !listDragSort;
};

const replaceDynamicLink = (link, record) => {
	return link.replace(/:(\w+)/g, (match, paramName) => {
		return record[paramName];
	});
};

const getInputMarker = (name, marker) => {
	name = name.toLowerCase();

	if (marker == 'status') {
		if (name == 'completed') {
			return m(sjefIconCheckbox);
		} else if (name == 'cancelled' || name == 'canceled' || name == 'rejected' || name == 'failed') {
			return m(sjefIconMoodAngry);
		} else if (name == 'open') {
			return m(sjefIconClock);
		}
	}

	return '';
};

class sjefTable extends vwBaseRecords {
	endpoint;
	config = false;
	listDragSort = false;
	listDragSortField = false;
	fields;
	editableFields = [];
	headerKeys;
	sortable = null;
	self = this;

	toggleDragSort() {
		this.listDragSortField = field;
		this.listDragSort = !this.listDragSort;
	}

	stripActiveSort(activeSort) {
		if (!activeSort) {
			return false;
		}

		return activeSort.replace('-', '');
	}

	async download(recordId) {
		try {
			const response = await app.get(`${this.endpoint}/${recordId}/download`);

			if (!response.success || response.error) {
				return alert(response.error);
			}

			downloadBase64(response.data.base64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', response.data.filename);

			// return response.data;
		} catch (error) {
			alert(error.message);
			// return response.error('3001', error.message);
		}
	}

	async cancel(recordId) {
		try {
			const response = await app.post(`${this.endpoint}/${recordId}/cancel`);
			if (!response.success || response.error) {
				return false;
			}

			this.reload();

			return true;
		} catch (error) {
			return false;
		}
	}

	initializeSortable(vnode) {
		const tableElement = document.querySelector('.sjefTable');
		console.log('Initializing Sortable on:', tableElement); // Check if element is correctly selected

		if (tableElement) {
			this.sortable = new Sortable(tableElement, {
				handle: '.drag-handle',
				animation: 150,
				ghostClass: 'dragging',

				onChoose: function (event) {
					console.log('onChoose event:', event);
					event.item.classList.add('selectedRow');
				},
				onUnchoose: function (event) {
					console.log('onUnchoose event:', event);
					event.item.classList.remove('selectedRow');
				},
				onEnd: function (event) {
					console.log('onEnd event:', event);
					let option2 = document.getElementById('activateSort').dataset.field;
					let field = vnode.attrs.sortable.field || option2 || 'position';

					// update logic here
					app.patch(`${vnode.attrs.sortable.endpoint}/position`, {
						id: event.item.dataset.id,
						positionName: field,
						position: event.newIndex,
					});

					// i presume these are the fields you need
					console.log(vnode.attrs.config, vnode.attrs.sortField, event.oldIndex, event.newIndex);
				},
			});

			console.log(this.sortable);
		} else {
			console.log('Sortable element not found.');
		}
	}

	oncreate(vnode) {
		if (vnode.attrs.sortable) {
			this.initializeSortable(vnode);
		}
	}

	onupdate(vnode) {
		// used to trigger the fields after saving the tableLayout fields
		if (this.previousActionCounter !== vnode.attrs.actionCounter) {
			this.previousActionCounter = vnode.attrs.actionCounter;
			// Perform the action

			if (vnode.attrs.fields) {
				this.fields = vnode.attrs.fields;
				m.redraw();
			}
		}
	}

	async oninit(vnode) {
		this.isLoading = true;
		if (vnode.attrs.config) {
			this.config = new moduleConfigs[vnode.attrs.config]();
			this.endpoint = this.config.endpoint;
			this.fields = await extractFieldsFromSchema(this.config);
			await this.paginateRecords(tableStore.metadata(), tableStore.records());
		}

		console.log(this.endpoint);

		if (vnode.attrs.fields) {
			this.fields = vnode.attrs.fields;
		}

		if (vnode.attrs.records?.length) {
			tableStore.records(vnode.attrs.records);
		}

		if (vnode.attrs.endpoint) {
			this.endpoint = vnode.attrs.endpoint;
		}

		// if (!this.endpoint) {
		// 	this.endpoint = vnode.attrs.endpoint;
		// }

		// console.log(this.endpoint)
		// console.log(tableStore.records());

		// console.log(vnode)
		// if (vnode.attrs.records) {
		// 	tableStore.records(vnode.attrs.records);
		// }

		// console.log(tableStore.records());

		// console.log(tableStore.records())

		// if (vnode.attrs.records) {
		// 	tableStore.records(vnode.attrs.records);
		// 	tableStore.metadata().total = vnode.attrs.records.length;
		// }

		// if (!tableStore.records() || tableStore.records().length == 0) {
		// 	tableStore.records(this.paginateRecords(tableStore.metadata(), tableStore.records()));
		// }

		// if ((!this.fields || this.fields.length == 0) && vnode.attrs.fields) {
		// 	this.fields = vnode.attrs.fields;
		// }

		// if (!this.config && vnode.attrs.config) {
		// 	this.config = new moduleConfigs[vnode.attrs.config]();
		// 	this.fields = await extractFieldsFromSchema(this.config);
		// }

		// if (this.config && !this.endpoint) {
		// 	this.endpoint = this.config.endpoint;
		// }

		// console.log(tableStore.records());
		// console.log(tableStore.records().length);

		// tableStore.records = this.paginateRecords(tableStore.metadata, tableStore.records);
		this.isLoading = false;

		m.redraw();
	}

	onremove() {
		// Destroy sortable instance
		if (this.sortable) {
			this.sortable.destroy();
		}
	}

	renderTableButtons(record) {
		const actions = [];

		// canUpdate action
		if (this.config?.actions.canUpdate()) {
			actions.push(
				m(sjefButton, {
					buttonLayout: 'inline',
					label: [m(sjefIconEdit), m('label', _t('_.buttons.edit'))],
					href: `${this.config.route}/${record.id}`,
				})
			);
		}

		// canCancel action
		if (this.config?.actions.canCancel && this.config.actions.canCancel(record)) {
			actions.push(
				m(sjefModal, {
					onSubmit: () => this.cancel(record.id),
					buttonLabel: [m(sjefIconTrash), m('label', _t('_.buttons.delete'))],
					buttonConfirm: _t('_.modals.confirmCancelButton'),
					buttonLayout: 'inline',
					modalTitle: _t('_.modals.confirmCancelTitle', { name: record.name }),
					modalContent: _t('_.modals.confirmCancelContent', { name: record.name }),
				})
			);
		}

		// canDownload action
		if (this.config?.actions.canDownload && this.config.actions.canDownload(record)) {
			actions.push(
				m(sjefButton, {
					onclick: () => this.download(record.id),
					label: [m(sjefIconDownload), m('label', _t('_.buttons.download'))],
					buttonLayout: 'inline',
				})
			);
		}

		// formDropDownButtons actions
		if (this.config?.formDropDownButtons) {
			this.config.formDropDownButtons.forEach((button) => {
				let buttonAttrs = typeof button.componentAttrs === 'function' ? button.componentAttrs(record) : button.componentAttrs;

				// hide button if a record status demands it
				if (
					!(
						record.status &&
						((buttonAttrs.hideStatuses &&
							buttonAttrs.hideStatuses.length &&
							buttonAttrs.hideStatuses.includes(record.status.toLowerCase())) ||
							(buttonAttrs.buttonHideStatuses &&
								buttonAttrs.buttonHideStatuses.length &&
								buttonAttrs.buttonHideStatuses.includes(this.record.status.toLowerCase())))
					)
				) {
					actions.push(
						m(button.component, {
							...buttonAttrs,
						})
					);
				}
			});
		}

		// Conditionally render sjefPopover or just the actions
		const content =
			actions.length > 2
				? m(sjefPopover, {
					class: record.status ? record.status.toLowerCase() : '',
					content: actions,
				})
				: actions;

		return content;
	}


	view({
		attrs: {
			content = '',
			fieldsToShow,
			checkboxAction = void 0,
			fieldsToExclude = [],
			endpoint = '',
			checkbox = true,
			sortable = false,
			tableSettings = true,
			...htmlAttrs
		},
	}) {
		const additionalClass = htmlAttrs.class ? htmlAttrs.class : '';

		if (tableStore.records().length) {
			let allKeys = new Set();

			// Gather all unique keys from each record
			tableStore.records().forEach((record) => {
				Object.keys(record).forEach((key) => {
					if (!fieldsToExclude.includes(key)) {
						allKeys.add(key);
					}
				});
			});

			// Convert the set of keys to an array
			let allKeysArray = Array.from(allKeys);

			// If 'fieldsToShow' is defined, filter 'allKeysArray' to only include these fields
			if (fieldsToShow && fieldsToShow.length) {
				this.headerKeys = allKeysArray.filter((key) => fieldsToShow.includes(key));
			} else {
				this.headerKeys = allKeysArray;
			}
		}
		if (this.isLoading) {
			return m(sjefLoading);
		}

		return m(
			'.sjefTableRoot',
			tableSettings && this.endpoint && this.fields
				? m(
					'.filterWrapper',
					m(sjefFilter, {
						filters: this.fields.filter((field) => field.filter !== false),
						defaultFilter: this.fields.find((field) => field.defaultFilter),
						onFilterUpdate: (filterReturnData) => {
							console.log('filterReturnData', filterReturnData);
							tableStore.filterData(filterReturnData);
							this.getRecords();
						},
					})
				)
				: void 0,
			m(
				'',
				this.endpoint
					? m(sjefTableSettings, {
						tools: [
							this.config &&
							sortable &&
							m(sjefIconDragDrop2, {
								'data-field': 'position',
								id: 'activateSort',
								onclick: () => toggleDragSort('position'),
							}),
							tableStore.selectedRecords()?.length > 0
								? `${tableStore.selectedRecords().length} ${_t('_.fields.selected', { defaultValue: 'Selected' })}`
								: undefined,
						],
						pagination: m(sjefPagination, {
							paginationParams: tableStore.metadata(),
							onPaginationUpdate: async (paginationReturnData) => {
								this.toggleAllRecordsSelection(false);
								this.paginateRecords(paginationReturnData);
							},
						}),
					})
					: void 0,
				m(
					'.sjefTableWrapper',
					{
						class: additionalClass.includes('list') ? 'list' : '',
					},
					m(
						Table,
						{
							class: 'sjefTable ' + additionalClass,
							bordered: false,
							striped: true,
						},
						// content is passed, ignore the rest
						content
							? content
							: // only records passed, get TH values from keys in records array
							tableStore.records()?.length && !this.fields
								? [
									m(
										'tr',
										[
											m(
												'th',
												{ class: 'cSmall table-checkbox' },
												checkbox
													? m(sjefCheckbox, {
														class: 'selectRecord',
														checked: tableStore.allRecordsSelected(),
														onChange: (value) => {
															this.toggleAllRecordsSelection(value);
															m.redraw();
														},
													})
													: void 0
											),
										].concat(this.headerKeys.map((header) => m('th', header)))
									),
									tableStore.records()?.map(
										(record) =>
											m(
												'tr',
												{ 'data-id': record.id },
												[
													m(
														'td',
														{
															class: 'table-checkbox',
														},
														checkbox
															? m(sjefCheckbox, {
																checked: tableStore.selectedRecords()?.includes(record.id),
																onChange: () => {
																	console.log(tableStore.selectedRecords());
																	this.toggleRecordSelection(record.id);
																	m.redraw();
																},
															})
															: void 0
													),
												].concat(this.headerKeys.map((header) => m('td', header in record ? record[header] : '')))
											),
										additionalClass.includes('list') ? m('tr.spacer') : void 0
									),
								]
								: // records and config passed. fields from config

								this.fields && tableStore.records().length && this.config
									? [
										m('tr', [
											// (!tableSettings && this.endpoint) ?
											checkbox && this.config.hideCheckboxes !== true
												? m(
													'th',
													{ class: 'cSmall table-checkbox' },
													m(sjefCheckbox, {
														class: 'selectRecord',
														checked: tableStore.allRecordsSelected(),
														disabled: hasCommonElements(
															tableStore.records()?.map((item) => item.id),
															tableStore.disabledRecords()
														),
														onChange: async (value) => {
															if (checkboxAction && typeof checkboxAction === 'function') {
																let checkboxResult = await checkboxAction('all');

																if (!checkboxResult.success) {
																	alert(checkboxResult.message);
																	return;
																}
															}

															listDragSort = false;
															this.toggleAllRecordsSelection(value);
															m.redraw();
														},
													})
												)
												: void 0,
											// : void(0),
											this.fields
												.sort((a, b) => a.listColumnPosition - b.listColumnPosition)
												.map((field) => {
													if (field.listShow) {
														return m(sjefTh, {
															name: _t(`_.fields.${field.name}`),
															// name: field.name,
															// label: _t(`_.fields.${field}`),
															class: this.stripActiveSort(tableStore.activeSort()) == field.name ? 'currentSort' : '',
															toggleDragSort: (field) => this.toggleDragSort(field),
															onSortUpdate: (sortReturnData) => {
																if (sortReturnData.sort) {
																	tableStore.activeSort(sortReturnData.sort);
																}

																this.sortRecords(sortReturnData);
															},
														});
													}
												}),
											m('th', { class: 'cSmall' }, ''),
										]),
										tableStore.records().map((record, recordIndex) => {
											return [
												m(
													'tr',
													{
														'data-id': record.id,
														class: record.status ? record.status.toLowerCase() : '',
													},
													[
														this.config.hideCheckboxes !== true &&
														m(
															'td',
															{
																class: !listDragSort ? ' table-checkbox' : '',
															},
															!listDragSort
																? [
																	checkbox
																		? m(sjefCheckbox, {
																			disabled: tableStore.disabledRecords().includes(record.id),
																			checked:
																				tableStore.selectedRecords()?.includes(record.id) ||
																				tableStore.disabledRecords().includes(record.id),
																			onChange: async (value) => {
																				if (checkboxAction && typeof checkboxAction === 'function') {
																					let checkboxResult = await checkboxAction(record.id);

																					if (!checkboxResult.success) {
																						alert(checkboxResult.message);
																						return;
																					}
																				}

																				this.toggleRecordSelection(record.id);

																				if (value === false) {
																					tableStore.allRecordsSelected(false);
																					m.redraw();
																				}

																				m.redraw();
																			},
																		})
																		: void 0,
																]
																: m(sjefIconArrowsSort, { class: 'drag-handle' })
														),
														this.fields
															.sort((a, b) => a.listColumnPosition - b.listColumnPosition)
															.map((field) => {
																let content;

																if (field.listShow) {
																	if (field.listEdit === true) {
																		content = m(
																			'.editInputWrapper',
																			{
																				class:
																					this.editableFields[record.id] &&
																						this.editableFields[record.id][field.name]
																						? ''
																						: 'readonly',
																			},
																			m('input.listEdit', {
																				type: 'input',
																				readonly: !(
																					this.editableFields[record.id] &&
																					this.editableFields[record.id][field.name]
																				),
																				value: record[field.name],
																				oninput: function (evt) {
																					record[field.name] = evt.target.value;
																				},
																				onclick: () => {
																					if (
																						!(
																							this.editableFields[record.id] &&
																							this.editableFields[record.id][field.name]
																						) &&
																						field.link
																					) {
																						// todo fix link
																						m.route.set(field.link);
																					}
																				},
																			}),
																			m(sjefIconEdit, {
																				class: 'edit',
																				onclick: () => {
																					const newEditableRecord = { ...this.editableFields };

																					if (newEditableRecord.hasOwnProperty(record.id)) {
																						// If the record with the given ID already exists, add properties to it
																						newEditableRecord[record.id][field.name] = record[field.name];
																					} else {
																						// If the record with the given ID doesn't exist, create a new record
																						newEditableRecord[record.id] = {
																							[field.name]: record[field.name],
																						};
																					}

																					this.editableFields = newEditableRecord;

																					m.redraw();
																				},
																			}),
																			m(sjefIconCheck, {
																				class: 'save',
																				onclick: async () => {
																					const data = {
																						id: record.id,
																						[field.name]: record[field.name],
																					};

																					const result = await app.patch(self.endpoint, data);

																					if (result.success) {
																						app.toast('Record saved', {
																							intent: 'positive',
																						});

																						// Remove the field from this.editableFields
																						if (this.editableFields.hasOwnProperty(record.id)) {
																							delete this.editableFields[record.id][field.name];

																							// If there are no more properties in the record.id object, remove it
																							if (
																								Object.keys(this.editableFields[record.id]).length === 0
																							) {
																								delete this.editableFields[record.id];
																							}
																						}
																					} else {
																						app.toast('Something went wrong', {
																							intent: 'negative',
																						});
																					}

																					m.redraw();
																				},
																			}),
																			m(sjefIconX, {
																				class: 'cancel',
																				onclick: () => {
																					record[field.name] = this.editableFields[record.id][field.name];

																					// Remove the field from this.editableFields
																					if (this.editableFields.hasOwnProperty(record.id)) {
																						delete this.editableFields[record.id][field.name];

																						// If there are no more properties in the record.id object, remove it
																						if (Object.keys(this.editableFields[record.id]).length === 0) {
																							delete this.editableFields[record.id];
																						}
																					}

																					m.redraw();
																				},
																			})
																		);
																	} else if (field.link) {
																		if (field.onClickAction) {
																			content = m(sjefLink, {
																				label: record[field.name],
																				onclick: () => field.onClickAction(record.id),
																			});
																		} else {
																			const newLink = replaceDynamicLink(field.link, record);

																			content = m(sjefLink, {
																				label: record[field.name],
																				href: newLink,
																			});
																		}
																	} else if (field.formInputType === 'password') {
																		content = '****';
																	} else if (field.type === 'boolean') {
																		content = m(Switch, {
																			defaultChecked: record.active,
																			onchange: (evt) => {
																				record[field.name] = evt.target.checked;
																				if (field.onChange) {
																					field.onChange(record.id, evt.target.checked);
																				} else {
																					app.patch(this.endpoint, record);
																				}
																			},
																		});
																	} else if (field.inputMarker) {
																		content = m(
																			'.inputMarker',
																			getInputMarker(record[field.name], field.inputMarker),
																			record[field.name]
																		);
																	} else {
																		if (field.formatter && typeof field.formatter === 'function') {
																			content = m('', {
																				class: 'format-' + field.formatter.name
																			}, field.formatter(record[field.name]));
																		} else {
																			content = m('', record[field.name]);
																		}
																	}

																	return m(
																		'td',
																		{
																			class: [field.name, listSizes[field.listSize]].join(' '),
																		},
																		content
																	);
																}

																// else {
																// 	return m('td', '');
																// }
															}),
														this.config &&
														m(
															'td.actions',
															// this.getAmountActions(this.config),
															// m(sjefPopover, {
															// 	class: record.status ? record.status.toLowerCase() : '',
															// 	content: [
															// 		this.config?.actions.canUpdate() ?
															// 			m(sjefButton, {
															// 				buttonLayout: 'inline',
															// 				label: [m(sjefIconEdit), 'Edit'],
															// 				href: `${this.config.route}/${record.id}`,
															// 			}) : void (0),
															// 		this.config?.actions.canCancel &&
															// 			this.config.actions.canCancel(record) ?
															// 			m(sjefModal, {
															// 				onSubmit: () => this.cancel(record.id),
															// 				buttonLabel: [m(sjefIconTrash), 'Delete'],
															// 				buttonConfirm: _t('_.modals.confirmCancelButton'),
															// 				buttonLayout: 'inline',
															// 				modalTitle: _t('_.modals.confirmCancelTitle', { name: record.name }),
															// 				modalContent: _t('_.modals.confirmCancelContent', { name: record.name }),
															// 			}) : void (0),

															// 		this.config?.actions.canDownload ?
															// 			this.config.actions.canDownload(record) ?
															// 				m(sjefButton, {
															// 					onclick: () => this.download(record.id),
															// 					label: m(sjefIconDownload) + ' ' + 'Download',
															// 					buttonLayout: 'inline',
															// 				}) : void (0)
															// 			: void (0),
															// 		// this.buttons?
															// 		this.config?.formDropDownButtons ?
															// 			this.config.formDropDownButtons.map((button) => {
															// 				let buttonAttrs = typeof button.componentAttrs === 'function'
															// 					? button.componentAttrs(record)
															// 					: button.componentAttrs;

															// 				// hide button if a record status demands it
															// 				// hide cancel button if status === canceled
															// 				if (!(record.status
															// 					&& ((buttonAttrs.hideStatuses && buttonAttrs.hideStatuses.length && buttonAttrs.hideStatuses.includes(record.status.toLowerCase()))
															// 						|| (buttonAttrs.buttonHideStatuses && buttonAttrs.buttonHideStatuses.length && buttonAttrs.buttonHideStatuses.includes(this.record.status.toLowerCase()))))) {
															// 					return m(button.component, {
															// 						...buttonAttrs,
															// 					});
															// 				}
															// 			})
															// 			: void (0)

															// 	],
															// })
															this.renderTableButtons(record)
														),
													]
												),
												additionalClass.includes('list') ? m('tr.spacer') : void 0,
											];
										}),
									]
									: void 0
					)
				),
				this.endpoint &&
				m(sjefTableSettings, {
					tools: [
						tableStore.selectedRecords()?.length > 0
							? `${tableStore.selectedRecords().length} ${_t('_.fields.selected', { defaultValue: 'Selected' })}`
							: undefined,
					],
					pagination: m(sjefPagination, {
						paginationParams: tableStore.metadata(),
						onPaginationUpdate: async (paginationReturnData) => {
							this.toggleAllRecordsSelection(false);
							this.paginateRecords(paginationReturnData);
						},
					}),
				})
			)
		);
	}
}

export default sjefTable;
