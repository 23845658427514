import m from 'mithril';
import sjefButton from '../views/components/sjefButton';
import vwClientAdyenDetails from '../modules/clients/components/vwClientAdyenDetails';
import vwClientAdyenPaymentScheme from '../modules/clients/components/vwClientAdyenPaymentScheme';
import vwClientWeFactInvoices from '../modules/clients/components/vwClientWeFactInvoices';
import vwClientWeFactPricequotes from '../modules/clients/components/vwClientWeFactPricequotes';
import sjefIconFileInvoice from '@sjefapp/sjeficons/icons/sjefIconFileInvoice';
import sjefIconArrowMoveRight from '@sjefapp/sjeficons/icons/sjefIconArrowMoveRight';
import sjefIconBuildingBank from '@sjefapp/sjeficons/icons/sjefIconBuildingBank';
import sjefIconPrison from '@sjefapp/sjeficons/icons/sjefIconPrison';
import sjefIconArrowsSort from '@sjefapp/sjeficons/icons/sjefIconArrowsSort';
import sjefIconNote from '@sjefapp/sjeficons/icons/sjefIconNote';
import sjefIconNotebook from '@sjefapp/sjeficons/icons/sjefIconNotebook';
import sjefIconMail from '@sjefapp/sjeficons/icons/sjefIconMail';
import sjefIconCode from '@sjefapp/sjeficons/icons/sjefIconCode';
import sjefIconBinary from '@sjefapp/sjeficons/icons/sjefIconBinary';
import sjefIconPhone from '@sjefapp/sjeficons/icons/sjefIconPhone';
import sjefIconAddressBook from '@sjefapp/sjeficons/icons/sjefIconAddressBook';
import sjefIconArrowBigDown from '@sjefapp/sjeficons/icons/sjefIconArrowBigDown';
import { _t } from '../lib/i18n';
import sjefIconFlag from '@sjefapp/sjeficons/icons/sjefIconFlag';
import sjefIconBuildingFactory from '@sjefapp/sjeficons/icons/sjefIconBuildingFactory';
import sjefIconHome from '@sjefapp/sjeficons/icons/sjefIconHome';
import sjefIconMessage2Code from '@sjefapp/sjeficons/icons/sjefIconMessage2Code';
import sjefIconBuildingWarehouse from '@sjefapp/sjeficons/icons/sjefIconBuildingWarehouse';
import sjefIconTextCaption from '@sjefapp/sjeficons/icons/sjefIconTextCaption';
import sjefIconUserPlus from '@sjefapp/sjeficons/icons/sjefIconUserPlus';
import vwClientInvites from '../modules/clients/components/vwClientInvites';
import sjefIconMap from '@sjefapp/sjeficons/icons/sjefIconMap';
import app from '../app';

const moduleName = 'clients';
const baseRoute = '/clients';
const endpoint = '/clients/clients';
// const endpoint = 'http://127.0.0.1:3007/clients';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				form: true,
				viewMode: 'edit',
				icon: sjefIconArrowMoveRight,
			},
			{
				slug: 'wefactInvoices',
				component: vwClientWeFactInvoices,
				icon: sjefIconFileInvoice,
				inCard: false,
				condition: app.isBoarded,

			},
			{
				slug: 'wefactPricequotes',
				component: vwClientWeFactPricequotes,
				icon: sjefIconPrison,
				inCard: false,
			},
			{
				slug: 'adyen',
				component: vwClientAdyenDetails,
				icon: sjefIconBuildingBank,
				inCard: false,
			},
			{
				slug: 'paymentScheme',
				component: vwClientAdyenPaymentScheme,
				icon: sjefIconArrowsSort,
			},
			{
				slug: 'invites',
				component: vwClientInvites,
				icon: sjefIconUserPlus
			}
		];

		this.formButtons = [
			{
				component: sjefButton,
				componentAttrs: {
					label: 'Open client',
					iconLeft: sjefIconArrowBigDown,
					buttonLayout: 'neutral',
					onclick: async () => {
						await app.switchAccessLevel(7, 'client', { cid: this.view.record.id });
					},
				}
			}
		];
		this.schemaClass = '';
		this.schema = [
			{
				groupName: 'General',
				subTitle: 'Please fill in the fields.',
				class: '',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
						placeholder: _t('_.fields.placeholders.id', { defaultValue: 'ID' }),
						defaultValue: null,
					},
					{
						name: 'uuid',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.uuid', { defaultValue: 'UUID' }),
					},
					{
						name: 'nameId',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						icon: sjefIconNote,
						readonly: true,
						formEdit: false,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.nameId', { defaultValue: 'Name ID' }),
					},
					{
						name: 'active',
						type: 'boolean',
						class: 'absolute right top',
						// formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listShow: true,
						required: true,
						defaultValue: true,
					},
					// {
					// 	name: 'active',
					// 	type: 'boolean',
					// 	defaultValue: true,
					// 	formInputType: 'checkbox',
					// 	formShow: false,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 2,
					// 	listShow: false,
					// 	required: true,
					// 	placeholder: _t('_.fields.placeholders.active', { defaultValue: 'Active' }),
					// },
					{
						name: 'name',
						type: 'string',
						icon: sjefIconBinary,
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:id`,
						placeholder: _t('_.fields.placeholders.name', { defaultValue: 'Name' }),
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'fixed',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'phoneNumberCountryCode',
						class: 'width-30',
						icon: sjefIconPhone,
						type: 'string',
						formInputType: 'phoneNumberCountryCodeSelect',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.phoneNumberCountryCode', { defaultValue: 'Country Code' }),
					},
					{
						name: 'phoneNumber',
						type: 'string',
						formInputType: 'text',
						icon: sjefIconPhone,
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 12,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.phoneNumber', { defaultValue: 'Phone Number' }),
					},
					{
						name: 'companyNumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						class: 'width-50',
						icon: sjefIconFileInvoice,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.companyNumber', { defaultValue: 'Company Number' }),
					},
					{
						name: 'taxNumber',
						type: 'string',
						formInputType: 'text',
						icon: sjefIconFileInvoice,
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.taxNumber', { defaultValue: 'Tax Number' }),
					},
					{
						name: 'shopperStatement',
						type: 'string',
						formInputType: 'text',
						icon: sjefIconTextCaption,
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.shopperStatement', { defaultValue: 'Shopper statement' }),
					},
					{
						name: 'hubspotId',
						type: 'string',
						formInputType: 'text',
						icon: sjefIconNotebook,
						createShow: false,
						formShow: true,
						listShow: false,
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.hubspotId', { defaultValue: 'Hubspot ID' }),
					},
					{
						name: 'wefactId',
						type: 'string',
						readonly: true,
						createShow: false,
						editShow: false,
						icon: sjefIconFileInvoice,
						formInputType: 'text',
						listShow: false,
						maxLength: 255,
						minLength: 0,
						required: false,
						placeholder: _t('_.fields.placeholders.wefactId', { defaultValue: 'WeFact ID' }),
					},
					{
						name: 'email',
						type: 'string',
						icon: sjefIconMail,
						formInputType: 'email',
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.email', { defaultValue: 'Email' }),
					},
					{
						name: 'emailKyc',
						type: 'string',
						icon: sjefIconMail,
						formInputType: 'email',
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.emailKyc', { defaultValue: 'Email' }),
					},
					{
						name: 'emailInvoice',
						type: 'string',
						icon: sjefIconMail,
						formInputType: 'email',
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.emailInvoice', { defaultValue: 'Email' }),
					},
					{
						name: 'industryCode',
						type: 'string',
						formInputType: 'select',
						options: [
							{ value: '', label: 'Select industry code' },
							{ value: '7223', label: '7223	- Special food services' },
							{ value: '7224', label: '7224	- Drinking places (alcoholic beverages)' },
							{ value: '532289', label: '532289	- All other consumer goods rental' },
						],
						icon: sjefIconCode,
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						maxLength: 10,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.industryCode', { defaultValue: 'Industry Code' }),
					},
					{
						name: 'planId',
						type: 'string',
						formInputType: 'select',
						options: [{ value: null, label: _t('_.fields.placeholders.planId') }, { value: 'planA', label: 'Plan A' }, { value: 'planB', label: 'Plan B' }],
						icon: sjefIconBuildingFactory,
						// formShow: false,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.planId', { defaultValue: 'Select one of the plans' }),
					},
					{
						name: 'region',
						type: 'string',
						formInputType: 'select',
						options: [{ value: null, label: _t('_.fields.placeholders.region') }, { value: 'EU', label: 'EU' }, { value: 'UK', label: 'UK' }],
						icon: sjefIconMap,
						// formShow: false,
						editShow: false,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						placeholder: _t('_.fields.placeholders.region', { defaultValue: 'Region' }),
					},
				]
			},
			{
				groupName: 'Address',
				class: 'column',
				// referenceCopy: true,
				fields: [
					{
						name: 'visitName',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						icon: sjefIconNote,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.visitName', { defaultValue: 'visit Name' }),
					},
					{
						name: 'visitAddress',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						icon: sjefIconHome,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.visitAddress', { defaultValue: 'visit Address' }),
					},
					{
						name: 'visitAddress2',
						type: 'string',
						icon: sjefIconHome,
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: false,
						allowEmptyValue: true,
						placeholder: _t('_.fields.placeholders.visitAddress2', { defaultValue: 'visit Address 2' }),
					},
					{
						name: 'visitPostalCode',
						icon: sjefIconMessage2Code,
						class: 'width-50',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.visitPostalCode', { defaultValue: 'visit Postal Code' }),
					},
					{
						name: 'visitCity',
						icon: sjefIconBuildingWarehouse,
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.visitCity', { defaultValue: 'visit City' }),
					},
					{
						name: 'visitState',
						icon: sjefIconAddressBook,
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.visitState', { defaultValue: 'visit State' }),
					},
					{
						name: 'visitCountry',
						icon: sjefIconFlag,
						type: 'string',
						formInputType: 'countrySelect',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.visitCountry', { defaultValue: 'visit Country' }),
					},
				]
			},
			{
				groupName: 'Invoice',
				class: 'column',
				fold: true,
				foldState: 'closed',
				referenceCopy: true,
				// conditionalField: 'wefactId',
				fields: [
					{
						name: 'invoiceName',
						reference: 'visitName',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						icon: sjefIconNote,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.invoiceName', { defaultValue: 'Invoice Name' }),
					},
					{
						name: 'invoiceAddress',
						reference: 'visitAddress',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						icon: sjefIconHome,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.invoiceAddress', { defaultValue: 'Invoice Address' }),
					},
					{
						name: 'invoiceAddress2',
						reference: 'visitAddress2',
						type: 'string',
						icon: sjefIconHome,
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: false,
						allowEmptyValue: true,
						placeholder: _t('_.fields.placeholders.invoiceAddress2', { defaultValue: 'Invoice Address 2' }),
					},
					{
						name: 'invoicePostalCode',
						reference: 'visitPostalCode',
						icon: sjefIconMessage2Code,
						class: 'width-50',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.invoicePostalCode', { defaultValue: 'Invoice Postal Code' }),
					},
					{
						name: 'invoiceCity',
						reference: 'visitCity',
						icon: sjefIconBuildingWarehouse,
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.invoiceCity', { defaultValue: 'Invoice City' }),
					},
					{
						name: 'invoiceState',
						reference: 'visitState',
						icon: sjefIconAddressBook,
						type: 'string',
						formInputType: 'text',
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.invoiceState', { defaultValue: 'Invoice State' }),
					},
					{
						name: 'invoiceCountry',
						reference: 'visitCountry',
						icon: sjefIconFlag,
						type: 'string',
						formInputType: 'countrySelect',
						formShow: true,
						listActiveToggle: true,
						listShow: false,
						required: true,
						placeholder: _t('_.fields.placeholders.invoiceCountry', { defaultValue: 'Invoice Country' }),
					},
				]
			},
		];
	}
}
