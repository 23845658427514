import m from 'mithril';
import { Table, Form } from 'construct-ui';
import app from '../../../app';
import sjefModal from "../../../views/components/sjefModal";
import { _t } from '../../../lib/i18n';
import sjefInput from '../../../views/components/sjefInput';
import sjefIconMailForward from '@sjefapp/sjeficons/icons/sjefIconMailForward';
import { formatDate } from '../../../lib/helpers';
import sjefIconBan from '@sjefapp/sjeficons/icons/sjefIconBan';

class vwClientInvites {
	records = []
	modalOpen = false;
	clientId;
	async oninit({ attrs }) {
		this.clientId = attrs.record.id;
		this.fetchData();
	}

	fetchData = async () => {
		const response = await app.get('/auth/invitations');
		this.records = response.data;
	}

	sendInvitation = async (email, clientIds, roles = []) => {
		const response = await app.post('/auth/invitations', {
			ident: email,
			clientIds,
			roles
		});

		if (response.success) {
			app.toast(_t('clients.clientInvite.toast.sent'));
			await this.fetchData();
		} else {
			app.toast('Something went wrong');
		}

	}

	blockInvitation = async (id) => {
		const response = await app.post('/auth/invitations/block', { id });
		if (response.success) {
			app.toast(_t('clients.clientInvite.toast.blocked'));
			await this.fetchData();
		} else {
			app.toast('Something went wrong');
		}
	}

	view() {
		return m(
			'.invites',
			[
				m('.header', [
					m(sjefModal, {
						buttonLabel: _t('clients.clientInvite.title'),
						modalTitle: _t('_.labels.invite'),
						buttonLayout: 'filled',
						isOpen: this.modalOpen,
						modalContent: [
							m('p', _t(`clients.clientInvite.description`)),
							m(Form,
								[
									m(sjefInput, {
										placeholder: _t('_.fields.placeholders.email'),
										oninput: e => {
											this.email = e.target.value;
										}
									}),
									m(sjefInput, {
										placeholder: _t('_.fields.placeholders.role'),
										oninput: e => {
											this.role = e.target.value;
										}
									}),
								]
							),
						],
						onSubmit: async () => {
							await this.sendInvitation(this.email, [this.clientId], [this.role]);
							return true;
						}
					}
					)
				]),
				m(Table, {
					bordered: this.bordered,
					interactive: this.interactive,
					striped: this.striped
				}, [
					m('tr', [
						m('th', { style: { width: '30%' } }, _t('clients.clientInvite.email')),
						m('th', _t('clients.clientInvite.status')),
						m('th', _t('clients.clientInvite.createdAt')),
						m('th', _t('clients.clientInvite.createdBy')),
						m('th', _t('clients.clientInvite.expiresAt')),
						m('th', _t('clients.clientInvite.actions.title'))
					]), this.records.length > 0 && this.records.map(record => {
						return m('tr', [
							m('td', record.ident),
							m('td', record.status),
							m('td', formatDate(record.createdAt)),
							m('td', record.createdBy),
							m('td', formatDate(record.expiresAt)),
							m('td', m('.actions', [
								m('button', {
									onclick: async () => {
										await this.sendInvitation(record.ident, [this.clientId])
									},
									title: _t('clients.clientInvite.actions.resend')
								}, m(sjefIconMailForward)),
								m('button', {
									onclick: async () => {
										await this.blockInvitation(record.id)
									},
									title: _t('clients.clientInvite.actions.block')
								}, m(sjefIconBan))
							]))
						]);
					})
				])]

		);
	}
}

export default vwClientInvites;
