import m from 'mithril';
import {
	Chart,
	ArcElement,
	CategoryScale,
	DoughnutController,
	RadarController,
	PolarAreaController,
	PieController,
	RadialLinearScale,
	// RadarScale,
	BarElement,
	BarController,
	Legend, // For legends
	Filler, // For area filling
	Tooltip, // For tooltips
	Title, // For titles
	LineController,
	LineElement,
	LinearScale,
	Linear,
	PointElement,
	TimeScale, // For time-based data
	ScatterController, // For scatter charts
	BubbleController, // For bubble charts
} from 'chart.js';

const registerComponents = (type) => {
	const commonComponents = [Tooltip, Filler, Legend, Title, ScatterController, BubbleController];

	if (type === 'line') {
		return commonComponents.concat([LineController, LineElement, PointElement, RadialLinearScale, CategoryScale]);
	} else if (type === 'bar') {
		return commonComponents.concat([BarController, BarElement, RadialLinearScale, CategoryScale]);
	} else if (type === 'radar') {
		console.log('Check RadarScale looks like its not supported anymore');
		// return commonComponents.concat([RadarController, LineElement, PointElement, RadarScale]);
	} else if (type === 'pie') {
		return commonComponents.concat([PieController, ArcElement, CategoryScale]);
	} else if (type === 'polarArea') {
		return commonComponents.concat([PolarAreaController, RadialLinearScale, ArcElement, RadialLinearScale]);
	} else if (type === 'doughnut') {
		return commonComponents.concat([DoughnutController, ArcElement, CategoryScale]);
	} else {
		return commonComponents;
	}
};

const sjefChart = {
	oninit(vnode) {
		if (!vnode.attrs.id) {
			throw new Error('Id not passed to shefChart component');
		}
	},

	oncreate(vnode) {
		const { id, dataObj, type, position, colors, size } = vnode.attrs;

		const componentsToRegister = registerComponents(type);
		Chart.register(...componentsToRegister);

		const ctx = vnode.dom.querySelector(`#${id}`).getContext('2d');

		const labels = Object.keys(dataObj);
		const data = Object.values(dataObj);

		const defaultColors = ['#02a388', 'lightblue', '#F4D03F', '#CAD2D3', '#FA8072', '#F5B041'];
		const finalColors = colors || defaultColors;
		let commonAxesOptions = {};

		if (['line', 'bar'].includes(type) == true) {
			commonAxesOptions = {
				ticks: {
					font: {
						family: 'Arial',
						weight: 'bold',
						lineHeight: 1.2
					},
				},
			};
		}

		new Chart(ctx, {
			showTooltips: true,

			type: type,
			data: {
				labels: labels,
				datasets: [
					{
						data: data,
						backgroundColor: finalColors,
					},
				],
			},
			options: {
				scales: {
					x: commonAxesOptions,
					y: commonAxesOptions,
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: true,
						mode: 'index',
						intersect: false,
					},
				},
			},
		});
	},
	view(vnode) {
		// TODO: generateUniqueId is not defined
		const id = vnode.attrs.id; // || generateUniqueId();
		const size = vnode.attrs.size;
		let style = {};

		if (size) {
			style = {
				width: size + 'px',
				flexBasis: size + 'px',
				maxWidth: size + 'px',
				position: 'relative',
			};
		}

		return m(
			'.sjefChart',
			{
				style: style,
			},
			m('canvas', {
				id: id,
			})
		);
	},
};

export default sjefChart;
