
const periods = {
	getCurrent: () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear().toString().slice(-2);
		const month = "0" + (currentDate.getMonth() + 1).toString().slice(-2);
		return `${year}${month}`
	}

}
export default periods
