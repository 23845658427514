import { Form, FormGroup, FormLabel, Input } from 'construct-ui';
import m from 'mithril';
import app from '../../../app';
import sjefButton from '../../components/sjefButton';

let email = '';
let password = '';
let repeatPassword = '';
let token;
let errorMsg = '';

const doForgotPassword = async () => {
	try {
		errorMsg = '';

		// todo: add password requirements (min length, must contain numbers etc.)
		if (password !== repeatPassword) {
			//todo: add error message
			return;
		}

		const result = await app.post('/auth/reset', { ident: email, password: password, resetToken: token });

		if (result.success) {
			//todo: add notification with message 'password updated'
			m.route.set('/login');
		} else {
			//todo: add notification with error message
			errorMsg = result.message;
		}
	} catch (err) {
		console.warn('RESET PASSWORD ERROR', err);
	}
};

const resetPasswordForm = {
	oninit: ({ attrs }) => {
		token = attrs.token;
	},
	view: () => {
		return m(
			Form,
			{
				align: 'top',
				justify: 'space-between',
			},
			[
				m('h1w', 'Nieuw wachtwoord instellen'),
				m(FormGroup, [
					m(FormLabel, 'Wachtwoord'),
					m(Input, {
						onchange: (ev) => {
							password = ev.target.value;
						},
						onkeypress: async (ev) => {
							if (ev.keyCode === 13 || ev.which === 13) {
								// Enter key
								ev.preventDefault();
							}
						},
					}),
				]),
				m(FormGroup, [
					m(FormLabel, 'Herhaal Wachtwoord'),
					m(Input, {
						onchange: (ev) => {
							repeatPassword = ev.target.value;
						},
						onkeypress: async (ev) => {
							if (ev.keyCode === 13 || ev.which === 13) {
								// Enter key
								ev.preventDefault();
							}
						},
					}),
				]),
				m(sjefButton, {
					label: 'wachtwoord resetten',
					buttonLayout: 'filled large',
					onclick: async () => await doForgotPassword(),
				}),
			]
		);
	},
};

export default resetPasswordForm;
