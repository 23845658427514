import m from 'mithril';
import app from '../../../app';
import { Callout, Form, FormGroup, FormLabel, Input } from 'construct-ui';
import sjefButton from '../../../views/components/sjefButton';

// user inputs
let code = '';

const doTfaVerification = async () => {
	try {

		const tfaResultData = await app.post('/auth/tfa/verify', { code: code });
		const tfaResult = tfaResultData.data;

		if (!tfaResult.error) {
			await app.setUser({
				lvl: tfaResult.identity.lvl,
				token: tfaResult.token,
				identity: tfaResult.identity,
				tfa: false,
			});

			return true;
		} else {
			return false
		}
	} catch (err) {
		return false;
	}
};

const isvalidData = () => {
	return code.length == 6;
};

class vwVerifyTfa {
	oninit() {
		if(app.isAuthenticated) {
			m.route.set('/');
		}
	}
	view({ attrs: { completed } }) {
		return m(
			Form,
			{
				align: 'top',
				justify: 'space-between',
			},
			[
				m('h1', 'Verifieer 2FA Code'),
				m(FormGroup, [
					m(FormLabel, 'Code'),
					m(Input, {
						onchange: (ev) => {
							code = ev.target.value;
						},
						onkeypress: async (ev) => {
							if (ev.keyCode === 13 || ev.which === 13) {
								// Enter key
								ev.preventDefault();
							}
						},
					}),
				]),
				m(sjefButton, {
					label: 'validate',
					buttonLayout: 'filled large',
					intent: isvalidData() ? 'positive' : 'default',
					onclick: async () => {
						const result = await doTfaVerification();

						if (result) {
							completed();
						}
					},
				}),
			]
		);
	}
}

export default vwVerifyTfa;
