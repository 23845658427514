const moduleName = 'settlements';
const baseRoute = '/banking/settlements';
const endpoint = '/settlements/settlements';
import { daysAgo, price } from "../lib/helpers";

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => false,
			canUpdate: () => false,
			canDelete: () => false,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'read',
			},
		];
		this.hideCheckboxes = true;
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'uuid',
						type: 'integer',
						formInputType: false,
						formShow: false,
						// link: `${baseRoute}/:uuid`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
					},
					{
						name: 'code',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						// link: `${baseRoute}/:uuid`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						required: true,

					},
					{
						name: 'description',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
					},
					{
						name: 'type',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
						advancedFilterType: 'select',
						advancedFilterOptions: ['daily', 'weekly', 'monthly', 'event', 'custom'],
					},
					{
						name: 'amountAvailable',
						type: 'number',
						listShow: true,
						listSize: 'small',
						formatter: price,
					},
					{
						name: 'amountTotal',
						type: 'number',
						listShow: true,
						listSize: 'small',
						formatter: price,
					},
					{
						name: 'amountSplit',
						type: 'number',
						listShow: true,
						listSize: 'small',
						formatter: price,
					},
					{
						name: 'amountCommission',
						type: 'number',
						listShow: true,
						listSize: 'small',
						formatter: price,
					},
					{
						name: 'amountCommissionMarkup',
						type: 'number',
						listShow: true,
						listSize: 'small',
						formatter: price,
					},
					{
						name: 'processedAt',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
						formatter: daysAgo,
					},
				],
			},
		];
	}
}
