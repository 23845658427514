const COLORS = {
	red: {
		default: '#ff0000',
		muted: '#ff000033'
	},
	green: {
		default: '#14b8a6',
		muted: '#14b8a633'
	},
	yellow: {
		default: '#f59e0b',
		muted: '#f59e0b33'
	},
	purple: {
		default: '#8b5cf6',
		muted: '#8b5cf633'
	},
	blue: {
		default: '#0ea5e9',
		muted: '#0ea5e933'
	},
	gray: {
		default: '#6c757d',
		muted: '#6c757d33'
	},
}
export default COLORS;
