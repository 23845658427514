/**
 * @fileOverview This module provides a form to create a business line in Adyen Balance Platform.
 * @module vwClientAdyenBusinessLinesCreateModal
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import { Form, FormLabel, Select, FormGroup } from 'construct-ui';
import validation from '@sjefapp/sjef-validation';

import sjefModal from '../../../views/components/sjefModal';
import constants from '../../../lib/constants';
import response from '../../../lib/response';
import app from '../../../app';
import sjefIconPlus from '@sjefapp/sjeficons/icons/sjefIconPlus';
import sjefInput from '../../../views/components/sjefInput';
import industryCodes from '../../../utils/industryCodes';

/**
 * Object defining the view component of the form, including initialization and rendering logic.
 */
class vwClientAdyenBusinessLinesCreateModal {
	/**
	 * Define validation schema for the form data.
	 */
	validationSchema = {
		type: 'object',
		properties: {
			industryCode: { type: 'string', minLength: 1, pattern: '\\S+' },
		},
		required: ['industryCode'],
	};

	/**
	 * @type {integer} clientId - The ID of the client.
	 */
	clientId;

	/**
	 * @type {Function} callbackOnSuccess - The callback function to be called when an operation completes successfully.
	 */
	callbackOnSuccess;

	/**
	 * @type {Object} data - An object that holds the data to be submitted. Structure depends on form requirements.
	 */
	data = {};

	/**
	 * Initialize the component with the provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {Object} vnode.attrs - The attributes passed to the component.
	 * @param {integer} vnode.attrs.clientId - The ID of the client.
	 * @param {Function} vnode.attrs.callbackOnSuccess - The function to call when the action completes with success.
	 */
	oninit = async ({ attrs }) => {
		this.clientId = attrs.clientId;
		this.callbackOnSuccess = attrs.callbackOnSuccess;
	};

	/**
	 * Asynchronous function to handle form submission.
	 * Validates the form data according to the defined schema,
	 * makes a request to create a business line with the validated data,
	 * handles the response, and provides user feedback.
	 *
	 * @returns {object} a Promise resolving to a response object
	 */
	onSubmit = async () => {
		if (validation.validate(this.validationSchema, this.data).valid === false) {
			return response.failed('0422', constants.MESSAGE_INVALID_DATA, validation.validate(this.validationSchema, this.data).errors);
		}

		const result = await app.post(`/balanceplatform/clients/${this.clientId}/businesslines`, {
			service: 'paymentProcessing',
			industryCode: this.data.industryCode,
		});

		if (result.success === false) {
			alert(result.error);
			return response.failed('1111', result.error);
		}

		this.callbackOnSuccess();

		app.toast('Saved', {
			intent: 'positive',
		});

		return response.success();
	};

	/**
	 * Resets the `data` variable to an empty object.
	 * @function onOpen
	 */
	onOpen = () => {
		this.data = {};
	};

	/**
	 * Render the component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	view() {
		return m(sjefModal, {
			onOpen: this.onOpen,
			onSubmit: this.onSubmit,
			modalTitle: 'Create Business Linesss',
			modalSubTitle: 'Fill in fields to create business lines',
			buttonLabel: m(sjefIconPlus),
			buttonLayout: 'iconHover',
			modalSize: 'lg',
			modalContent: m(
				'',
				m(
					Form,
					{
						onsubmit: () => {
							return false;
						},
					},
					[
						m('h3', 'General Info'),
						m(FormGroup, {
							content: [
								// m(FormLabel, { for: 'industryCode' }, 'Industry Code'),
								m(
									'',
									m(sjefInput, {
										label: 'Industry Code',
										type: 'select',
										id: 'industryCode',
										options: industryCodes,
										value: this.data.industryCode,
										onChange: (newValue) => {
											this.data.industryCode = newValue;
										},
									})
								),
							],
						}),
					]
				)
			),
		});
	}
}

export default vwClientAdyenBusinessLinesCreateModal;
