import m from 'mithril';
import { Card, Form } from 'construct-ui';
import app from '../../../app';
import sjefForm from '../../../views/components/sjefForm';
import sjefIconBuildingBank from '@sjefapp/sjeficons/icons/sjefIconBuildingBank';
import sjefIconCoinEuro from '@sjefapp/sjeficons/icons/sjefIconCoinEuro';
import sjefIconNote from '@sjefapp/sjeficons/icons/sjefIconNote';
import sjefIconCurrencyEuro from '@sjefapp/sjeficons/icons/sjefIconCurrencyEuro';
import sjefIconDetails from '@sjefapp/sjeficons/icons/sjefIconDetails';
import sjefIconPencil from '@sjefapp/sjeficons/icons/sjefIconPencil';
import { _t } from "../../../lib/i18n";

class vwClientMutations {
	form = {
		mutation: {
			balanceAccountId: "",
			amount: 0,
			type: "",
			reference: "",
			description: "",
			currency: "",
		}
	};
	balanceAccountsOptions = [];

	currencyOptions = ['EUR', 'USD', 'GBP'];

	createMutation = async () => {
		const result = await app.post(`/clientbalances/management/mutations`, {
			clientId: this.clientId,
			...this.form.mutation,
		});

		if (result.success) {
			app.toast('Mutation created', { intent: 'positive' });
		} else {
			app.toast('Mutation failed to create', { intent: 'negative' });
		}
	}

	setValue = ({ field, value }) => {
		this.form.mutation[field] = value;
	}

	oninit({ attrs }) {
		this.balanceAccountsOptions = attrs.balanceAccounts.map((balanceAccount) => balanceAccount.id);
		this.currencyOptions = Array.from(new Set(attrs.balanceAccounts.map((balanceAccount) => balanceAccount.balances).flat().map((balance) => balance.currency)))
		this.clientId = attrs.clientId;
	}

	view() {
		return m('.mutations',
			m(Card,
				[
					m('h3', _t('_.fields.adyen.mutations.title')),
					m(sjefForm, {
						fields: [
							{
								field: 'balanceAccountId',
								label: _t('_.fields.adyen.mutations.balanceAccount'),
								type: 'select',
								required: true,
								value: this.form.mutation.balanceAccountId,
								onChange: (value) => this.setValue({ field: 'balanceAccountId', value }),
								options: ['', ...this.balanceAccountsOptions],
								icon: sjefIconBuildingBank,
							},
							{
								field: 'currency',
								label: _t('_.fields.adyen.mutations.currency'),
								type: 'select',
								required: true,
								value: this.form.mutation.currency,
								onChange: (value) => this.setValue({ field: 'currency', value }),
								options: ['', ...this.currencyOptions],
								icon: sjefIconCoinEuro
							},
							{
								field: 'amount',
								label: _t('_.fields.adyen.mutations.amount'),
								type: 'number',
								required: true,
								step: "0.01",
								value: this.form.mutation.amount,
								onChange: (value) => {

									this.setValue({ field: 'amount', value: parseFloat(value) })
								},
								icon: sjefIconCurrencyEuro
							},
							{
								field: 'type',
								label: _t('_.fields.adyen.mutations.type'),
								type: 'select',
								required: true,
								value: this.form.mutation.type,
								onChange: (value) => this.setValue({ field: 'type', value }),
								options: ['', 'reservation', 'invoice'],
								icon: sjefIconDetails,
							},
							{
								field: 'reference',
								label: _t('_.fields.adyen.mutations.reference'),
								type: 'text',
								validationType: 'string',
								required: true,
								value: this.form.mutation.reference,
								onChange: (value) => this.setValue({ field: 'reference', value }),
								icon: sjefIconNote,
							},
							{
								field: 'description',
								label: _t('_.fields.adyen.mutations.description'),
								type: 'text',
								validationType: 'string',
								required: false,
								value: this.form.mutation.description,
								onChange: (value) => this.setValue({ field: 'description', value }),
								icon: sjefIconPencil,
							},

						],
						schema: {
							type: 'object',
							required: ['balanceAccountId', 'amount', 'type', 'reference', 'currency'],
							properties: {
								balanceAccountId: {
									minLength: 1,
									type: "string",
								},
								currency: {
									type: "string",
									minLength: 1,
								},
								amount: {
									type: 'number',
								},
								type: {
									type: 'string',
									minLength: 1,
								},
								reference: {
									type: 'string',
									minLength: 1,
								},
							}
						},
						submit: this.createMutation,
					}),
				]))
	}

}
export default vwClientMutations;
