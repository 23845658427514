import m from 'mithril';
import app from '../app';
import { Card } from 'construct-ui';
import { SkeletonImage, SkeletonText, SkeletonTextBody } from '../views/components/skeletons';
import { _t } from '../lib/i18n';
import SjefCardWithHeader from '../views/components/sjefCardWithHeader';
import sjefButton from '../views/components/sjefButton';
import sjefIconChevronRight from '@sjefapp/sjeficons/icons/sjefIconChevronRight';
import sjefHeader from '../assets/img/headers/029.jpg';
import sjefApexChart from '../views/components/sjefApexChart';
import COLORS from '../utils/colors';

const vwHome = {
	organisationName: '',
	clientName: '',
	administrationName: '',
	counters: {},
	greetings: () => {
		const date = new Date();
		const hours = date.getHours();

		if (hours >= 0 && hours < 12) {
			return _t(`modules.home.greeting.goodmorning`);
		}
		if (hours >= 12 && hours < 18) {
			return _t(`modules.home.greeting.goodafternoon`);
		}
		if (hours >= 18 && hours < 24) {
			return _t(`modules.home.greeting.goodevening`);
		}
	},
	title: () => {
		if (app.lvl >= 9) {
			return 'Sjef Admin'
		}
		if (vwHome.administrationName) {
			return vwHome.administrationName;
		}
		if (vwHome.clientName) {
			return vwHome.clientName;
		}
		if (vwHome.organisationName) {
			return vwHome.organisationName;
		}
		return false;
	},
	oninit: async function ({ attrs }) {
		const result = await app.get(`/user/profile`);

		if (result.data) {
			vwHome.organisationName = result.data.organisationName;
			vwHome.clientName = result.data.clientName;
			vwHome.administrationName = result.data.administrationName;
			m.redraw();

			const currentUsername = vwHome.administrationName || vwHome.clientName || vwHome.organisationName;
			app.store('currentUsername', currentUsername);
		}

		const endpoint = `/clientbalances/counters/current`;
		const showError = false;
		const countersResponse = await app.get(endpoint, {}, showError);

		if (!countersResponse.success) {
			return;
		}
		vwHome.counters = countersResponse.data;
		m.redraw();
	},
	onremove: () => {
		vwHome.organisationName = '';
		vwHome.clientName = '';
		vwHome.administrationName = '';
	},
	view: () => {
		return [
			m('.columns.columns-3-2', [
				m('', [
					SjefCardWithHeader({
						title: `${vwHome.greetings()}, `,
						imageHeader: sjefHeader,
						headerHeight: '120px',
						headerPaddingTop: '3rem',
						bodyPaddingTop: '2rem',
						children: [
							vwHome.title() ? m('h1', vwHome.title()) : [m(SkeletonText), m(SkeletonTextBody)],
							m('p', _t(`modules.home.intro.paragraph1`)),
							m('p', _t(`modules.home.intro.paragraph2`)),
						],
					}),
					app.lvl == 7 && m('.columns.columns-2-2', [
						vwHome.counters.client &&
						m(
							Card,
							{ style: { marginTop: '2rem' } },
							m('h2', _t(`modules.home.transactions.title`)),
							m(sjefApexChart, {
								style: 'minimal',
								color: COLORS.purple.default,
								options: {
									legend: {
										show: true,
									},
									colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
									chart: {
										type: 'bar',
									},
									labels: ['Client', 'Year', 'Month', 'Day'],
									series: [
										{
											data: [
												{
													fillColor: COLORS.blue.default,
													x: 'Client',
													y: vwHome.counters.client.volume,
												},
												{
													fillColor: COLORS.green.default,
													x: 'Year',
													y: vwHome.counters.year.volume,
												},
												{
													fillColor: COLORS.yellow.default,
													x: 'Month',
													y: vwHome.counters.month.volume,
												},
												{
													fillColor: COLORS.purple.default,
													x: 'Day',
													y: vwHome.counters.day.volume,
												},
											],
										},
									],
								},
							})
						),
						vwHome.counters.client &&
						m(
							Card,
							{ style: { marginTop: '2rem' } },
							m('h2', _t(`modules.home.turnover.title`)),
							m(sjefApexChart, {
								style: 'minimal',
								color: COLORS.purple.default,
								options: {
									legend: {
										show: true,
									},
									tooltip: {
										enabled: false,
									},
									colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
									chart: {
										type: 'bar',
									},
									series: [
										{
											data: [
												{
													fillColor: COLORS.blue.default,
													x: 'Client',
													y: vwHome.counters.client.turnover,
												},
												{
													fillColor: COLORS.green.default,
													x: 'Year',
													y: vwHome.counters.year.turnover,
												},
												{
													fillColor: COLORS.yellow.default,
													x: 'Month',
													y: vwHome.counters.month.turnover,
												},
												{
													fillColor: COLORS.purple.default,
													x: 'Day',
													y: vwHome.counters.day.turnover,
												},
											],
										},
									],
								},
							})
						),
					]),
				]),
				m('', [
					app.lvl == 7 && vwHome.clientName &&
					SjefCardWithHeader({
						title: 'Balance Platform',
						headerPaddingTop: '3rem',
						bodyPaddingTop: '1rem',
						headerHeight: '120px',
						children: [
							m('h2', _t(`modules.home.balanceplatform.subtitle`)),
							m('p', _t(`modules.home.balanceplatform.paragraph1`)),
							m('p', _t(`modules.home.balanceplatform.paragraph2`)),
							m('p', _t(`modules.home.balanceplatform.paragraph3`)),

							m(
								'.flexer',
								m(sjefButton, {
									buttonLayout: 'filled',
									label: _t(`modules.home.balanceplatform.button`),
									iconLeft: sjefIconChevronRight,
									onclick: () => m.route.set('/banking/overview'),
								})
							),
						],
					}),
				]),
			]),
		];
	},
};

export default vwHome;
