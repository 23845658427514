import m from 'mithril';
import { formatDate } from '../../lib/helpers';
import sjefIconCalendarEvent from '@sjefapp/sjeficons/icons/sjefIconCalendarEvent';

class sjefBanner {
	dates;
	title;
	subTitle;
	color;
	icon;
	style;

	oninit({ attrs }) {
		this.color = attrs.color;
		this.title = attrs.title;
		this.subTitle = attrs.subTitle;
		this.dates = attrs.dates;
		this.icon = attrs.icon;
		this.img = attrs.img
		this.style = attrs.style;
	}

	view() {
		return [
			m(
				'.sjefBanner',
				{
					class: 'color-' + (this.color || 'green'),
					style: this.style,
				},
				[
					m(
						'.titleWrapper',
						{
							class: this.icon ? 'hasIcon' : '',
						},
						this.icon
							? m(
								'.icon',

								this.icon
							)
							: void 0,
						m('', this.title ? m('h1', this.title) : void 0, this.subTitle ? m('h6', this.subTitle) : void 0)
					),
					this.dates && this.dates.length
						? m(
							'.dates',
							this.dates.map((date) => {
								return m('.date', m(sjefIconCalendarEvent), m('p', formatDate(date)));
							})
						)
						: void 0,

					this.img ?
						m('img', { src: this.img })
						: void (0)
				]
			),
		];
	}
}

export default sjefBanner;
