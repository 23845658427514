import vwModifiersTab from '../modules/items/tabs/vwModifiersTab';
import vwTabToggleColumns from '../modules/_default/tabs/vwTabToggleColumns';

const moduleName = 'items';
const baseRoute = '/products/items';
const endpoint = '/masterdata/items';
export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
			{
				slug: 'categories',
				viewMode: 'read',
				component: vwTabToggleColumns,
				componentAttrs: {
					endpointData: '/masterdata/categories',
					endpointSubmit: endpoint,
					columns: ['categories'],
				},
			},
			{
				slug: 'modifiers',
				viewMode: 'read',
				component: vwModifiersTab,
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: '.grid-1',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'parentId', //
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'departmentId',
						type: 'integer',
						formInputType: 'selectAsync',
						asyncEndpoint: '/masterdata/departments',
						asyncField: 'departmentId',
						asyncFieldLabel: 'department',
						asyncFilterOn: 'name',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'displaygroupId',
						type: 'integer',
						formInputType: 'selectAsync',
						asyncEndpoint: '/masterdata/displaygroups',
						asyncField: 'displaygroupId',
						asyncFieldLabel: 'displaygroup',
						asyncFilterOn: 'name',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'taxrateId',
						type: 'integer',
						formInputType: 'selectAsync',
						asyncEndpoint: '/masterdata/taxrates',
						asyncField: 'taxrateId',
						asyncFieldLabel: 'taxrate',
						asyncFilterOn: 'name',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 4,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'courseId',
						type: 'integer',
						formInputType: 'selectAsync',
						asyncEndpoint: '/masterdata/courses',
						asyncField: 'courseId',
						asyncFieldLabel: 'course',
						asyncFilterOn: 'name',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 5,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'barcode',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 6,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						formTranslations: 'nameTranslations',
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 7,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'nameKitchen',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 8,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'description',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						formTranslations: 'descriptionTranslations',
						listActiveToggle: true,
						listColumnPosition: 9,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'categories',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 10,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'metaName',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 11,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'metaCategory',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 12,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'metaSubcategory',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 13,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'metaBrand',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 14,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'metaManufacturer',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 15,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'metaSupplier',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 16,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'metaUnitOfSale',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 17,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'metaUnitOfPacking',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 18,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'metaVolumM1',
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 19,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'price',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 20,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'priceRetail',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 21,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'priceCosts',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 22,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'coinAmount',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 23,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'tokenAmount',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 24,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgNormal',
						type: 'string',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 25,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgThumbnail',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 26,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgSvg',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 27,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgKioskNormal',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 28,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgKioskThumbnail',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 29,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgPosNormal',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 30,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgPosThumbnail',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 31,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgWebshopNormal',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 32,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgWebshopThumbnail',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 33,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'videoWebshop',
						type: 'string',
						format: 'url',
						formInputType: 'url',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 34,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'electronicShelfLabel',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 35,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'isParentLeading',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 36,
						listShow: false,
						listSize: 'small',
						required: true,
					},
					{
						name: 'isOrderItem',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 37,
						listShow: false,
						listSize: 'small',
						required: true,
					},
					{
						name: 'isStockItem',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 38,
						listShow: false,
						listSize: 'small',
						required: true,
					},
					{
						name: 'isBackorderItem',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 39,
						listShow: false,
						required: true,
					},
					{
						name: 'isCashcardItem',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 40,
						listShow: false,
						required: true,
					},
					{
						name: 'isHiddenOnReceipt',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 41,
						listShow: false,
						required: true,
					},
					{
						name: 'isUniqueItem',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 42,
						listShow: false,
						required: true,
					},
					{
						name: 'uniqueItemType',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 43,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'uniqueItemTypePrintjobs',
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 44,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'posColor',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 45,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'posVisible',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 46,
						listShow: false,
						required: true,
					},
					{
						name: 'posSearchable',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 47,
						listShow: false,
						required: true,
					},
					{
						name: 'posOpenprice',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 48,
						listShow: false,
						required: true,
					},
					{
						name: 'elockersActive',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 49,
						listShow: false,
						required: true,
					},
					{
						name: 'elockersType',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 50,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'positionKitchen',
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 51,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'archived',
						type: 'boolean',
						defaultValue: false,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						required: true,
					},
				],
			},
		];
	}
}
