import { Card } from 'construct-ui';
import m from 'mithril';
import { _t } from '../../../lib/i18n';
import vwProfileCustomerData from './vwProfileCustomerData';
import vwExampleTab from '../../_default/tabs/vwExampleTab.js';
import vwProfileVisitedEvents from './vwProfileVisitedEvents';
import vwProfileTransactions from './vwProfileTransactions';
import vwProfilePercentileRanking from './vwProfilePercentileRanking.js';
import vwProfileCupsDeposits from './vwProfileCupsDeposits';
import vwProfileTotalSpent from './vwProfileTotalSpent';
import app from '../../../app';
import vwProfileBadges from './vwProfileBadges';
import vwProfileTopProducts from './vwProfileTopProducts';
import sjefButton from '../../../views/components/sjefButton';
import sjefBreadcrumbs from '../../../views/components/sjefBreadcrumbs';
import sjefIconArrowLeft from '@sjefapp/sjeficons/icons/sjefIconArrowLeft';
import sjefLoading from '../../../views/components/sjefLoading';
/**
 * Represents a view model for a Client form.
 * @class
 */
// const endpoint = '/olap/profiles';
const endpoint = 'https://olap.sjef.dev/profiles';
class vwProfileDashboard {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	profile = false;
	transactions = false;
	endpoint = endpoint;

	async oninit(vnode) {
		console.log('dddd');
		const result = await app.get(`${this.endpoint}/${vnode.attrs.id}`);
		if (result.success) {
			this.profile = result.data;
		}

		if (this.profile.profileId) {
			await this.getTransactions(this.profile.profileId);
		}

		console.log('PROFILE:', this.profile);
	}

	async getTransactions(id) {
		const result = await app.get(`${this.endpoint}/${id}/transactions`);

		if (result.success) {
			this.transactions = result.data;

			return;
		}
	}

	view(vnode) {
		if (!this.profile || !this.transactions) {
			return m(sjefLoading);
		}

		const data = { profile: this.profile, transactions: this.transactions };

		return m(
			'',
			m('.pageHeader', [
				m(sjefButton, {
					iconLeft: sjefIconArrowLeft,
					buttonLayout: 'previous',
					label: _t('_.buttons.backToList', { defaultValue: 'Go back' }),
					onclick: () => m.route.set('/profiles'),
				}),
				m(sjefBreadcrumbs, {
					hideLast: true,
				}),
			]),
			m(
				'.columns-2',
				m(Card, m(vwProfileCustomerData, data)),
				m(Card, m(vwProfileVisitedEvents, data)),
				m(Card, m(vwProfileTotalSpent, data)),
				m(Card, m(vwProfilePercentileRanking, data))
			),
			m(Card, m(vwProfileCupsDeposits, data)),
			m('.columns-2', m(Card, m(vwProfileBadges, data)), m(Card, m(vwProfileTopProducts, data))),
			m('', m(Card, m(vwProfileTransactions, data)))
		);
	}
}

export default vwProfileDashboard;
