
import m from 'mithril';

/* eslint:disable */

class sjefBlur {
	view(vnode) {
		return m('.sjefBlur', 
			vnode.attrs.description ? m('h3.description', vnode.attrs.description) : void(0),
			m('svg.svg-loading', 
				{ viewBox: '0 0 600 300' }, [
					m('symbol', { id: 's-text' }, m('text', { 'text-anchor': 'middle', x: '50%', y: '50%', dy: '.35em' }, 'SJEF')),
					m('use.svg-text', { 'xlink:href': '#s-text' }),
					m('use.svg-text', { 'xlink:href': '#s-text' }),
					m('use.svg-text', { 'xlink:href': '#s-text' }),
					m('use.svg-text', { 'xlink:href': '#s-text' }),
					m('use.svg-text', { 'xlink:href': '#s-text' }),
				]
			)
		)
	}
}

export default sjefBlur;
