import m from 'mithril';
import sjefInput from './sjefInput';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconBucket from '@sjefapp/sjeficons/icons/sjefIconBucket';
import sjefIconMessageForward from '@sjefapp/sjeficons/icons/sjefIconMessageForward';
import sjefIconTimeline from '@sjefapp/sjeficons/icons/sjefIconTimeline';
import { Card, Collapse, Form } from 'construct-ui';
import { Tooltip } from 'construct-ui';
import sjefCheckbox from './sjefCheckbox';
import sjefButton from './sjefButton';
import { price, formatDate } from '../../lib/helpers';
import sjefIconDoor from '@sjefapp/sjeficons/icons/sjefIconDoor';
import sjefIconShoppingCart from '@sjefapp/sjeficons/icons/sjefIconShoppingCart';
import sjefIconCheckbox from '@sjefapp/sjeficons/icons/sjefIconCheckbox';
import app from '../../app';
import sjefIconTrash from '@sjefapp/sjeficons/icons/sjefIconTrash';

class eventsListEvent {
	event;
	open = false;


	oninit(vnode) {
		this.event = vnode.attrs.event;
	}

	view(vnode) {
		if (!this.event) {
			return;
		}

		return [
			m(
				'li.event',
				{
					class: this.open ? 'open' : '',
				},
				m(
					'.eventData',
					m(
						'.stretcher',
						m('.eventTitle', {
							onclick: () => {
								m.route.set(`/elockers/manage?eventId=${this.event.id}`)
							}
						}, m('h4', this.event.name), m('p', formatDate(this.event.eventStart))),
						m(
							'.flex.quickActions',
							m(Tooltip, {
								content: 'Active',
								position: 'top',
								hasArrow: false,
								trigger: m(
									'.iconHover',
									{
										class: this.event.active ? 'completed' : '',
									},
									m(sjefIconCheckbox)
								),
							}),
							m(Tooltip, {
								content: 'Active In Shop',
								position: 'top',
								hasArrow: false,
								trigger: m(
									'.iconHover',
									{
										class: this.event.activeInShop ? 'completed' : '',
									},
									m(sjefIconShoppingCart)
								),
							}),
							// m(Tooltip, {
							// 	content: 'Presale',
							// 	position: 'top',
							// 	hasArrow: false,
							// 	trigger: m(
							// 		'.iconHover',
							// 		{
							// 			class: this.event.presaleDone ? 'completed' : '',
							// 		},
							// 		m(sjefIconMessageForward)
							// 	),
							// }),

							// m(Tooltip, {
							// 	content: 'Allocations',
							// 	position: 'top',
							// 	hasArrow: false,
							// 	trigger: m(
							// 		'.iconHover',
							// 		{
							// 			class: this.event.allocationsDone ? 'completed' : '',
							// 		},
							// 		m(sjefIconBucket)
							// 	),
							// })
						)
					),
					m(
						'.iconHover',
						{
							onclick: () => {
								this.open = !this.open;
								m.redraw();
							},
						},
						m(sjefIconChevronDown)
					)
				)
			),
			m(
				Collapse,
				{
					isOpen: this.open,
					duration: 200,
				},
				m(
					Form,
					{ class: '' },
					m(
						'.columns-2-1',
						m(
							Card,
							m(
								'.flexer',
								m('h3', 'Event info'),
								m(
									'.flexer',
									m(sjefCheckbox, {
										key: 'active',
										label: 'Active',
										defaultChecked: this.event.active === true,
										checked: this.event.active,
										onChange: () => {
											this.event.active = !this.event.active;
										},
									}),
									m(sjefCheckbox, {
										key: 'activeInShop',
										label: 'Active In Shop',
										defaultChecked: this.event.activeInShop === true,
										checked: this.event.activeInShop,
										onChange: () => {
											this.event.activeInShop = !this.event.activeInShop;
										},
									}),
									m(Tooltip, {
										key: 'delete',
										content: 'Delete',
										position: 'top',
										hasArrow: false,
										trigger: m(
											'.iconHover',
											{
												key: 'danger',
												class: 'danger',
												onclick: async () => {
													let result = await app.delete('/lockermanagement/events', {
														id: this.event.id
													})

													if (result.success) {
														if (typeof vnode.attrs.onDelete === 'function') {
															vnode.attrs.onDelete();
														}
													}
												}
											},
											m(sjefIconTrash)
										),
									})
								)
							),
							m(
								'.formGrid.grid-2',
								m(sjefInput, {
									label: 'Name',
									key: 'name',
									value: this.event.name,
									onChange: (newValue) => {
										this.event.name = newValue;
									},
								}),
								m('', { key: 'filler1' }),
								m(sjefInput, {
									label: 'Event Start',
									key: 'eventStart',
									type: 'datetime-local',
									value: this.event.eventStart,
									onChange: (newValue) => {
										this.event.eventStart = newValue;
									},
								}),
								m(sjefInput, {
									label: 'Event End',
									key: 'eventEnd',
									type: 'datetime-local',
									value: this.event.eventEnd,
									onChange: (newValue) => {
										this.event.eventEnd = newValue;
									},
								}),
								m(sjefInput, {
									label: 'Mode',
									key: 'mode',
									type: 'select',
									value: this.event.mode,
									options: ['10-10', '12-12'],
									onChange: (newValue) => {
										this.event.mode = newValue;
									},
								})
							),
							m(
								'.flexer',
								m(sjefButton, {
									key: 'save',
									label: 'Save',
									buttonLayout: 'filled',
									onclick: async () => {

										//  MELVIN eventActiveAtHour
										this.event.eventActivateAtHour = 0;
										this.event.eventDeactivateAtHour = 0;
										this.event.useDefaultConfig = true;
										await app.post('/lockermanagement/events', this.event)
									},
								})
							)
						),
						m(
							Card,
							m(
								'',
								m('h3', 'Products'),
								m(
									'.lockerProducts',
									m('.lockerTile', m('.iconHover.background', m(sjefIconDoor)), m('h5', 'Locker Medium'), m('p', price(6))),
									m('.lockerTile', m('.iconHover.background', m(sjefIconDoor)), m('h5', 'Locker Large'), m('p', price(10)))
								)
							)
						)
					)
				)
			),
		];
	}
}

export default eventsListEvent;
