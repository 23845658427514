import m from 'mithril';

import { Table } from 'construct-ui';
import { renderPaymentRow, renderLineRow } from '../../../../src/lib/helpers';

class vwLinesPaymentsTab {
	lines;
	payments;

	constructor({ attrs }) {
		this.lines = attrs.record.lines || [];
		this.payments = attrs.record.payments || [];
	}

	view() {
		return m('div', [
			m('p', 'Lines'),
			m(Table, { bordered: false, striped: false }, [
				m(
					'thead',
					m('tr', [
						m('th', 'Barcode'),
						m('th', 'Name'),
						m('th', 'Quantity'),
						m('th', 'Amount'),
						m('th', 'DiscountAmount'),
						m('th', 'Subtotal'),
						m('th', 'Taxes'),
						m('th', 'Total'),
					])
				),
				m(
					'tbody',
					this.lines.map((line) => renderLineRow(line, 'YYYY-MM-DD HH:mm'))
				),
			]),
			m('p', { style: 'margin-top: 30px;' }, 'Payments'),
			m(Table, { bordered: false, striped: false }, [
				m(
					'thead',
					m('tr', [
						m('th', 'Date / Time'),
						m('th', 'Paymentmethod'),
						m('th', 'PspName'),
						m('th', 'PspMethod'),
						m('th', 'Status'),
						m('th', 'Amount'),
					])
				),
				m(
					'tbody',
					this.payments.map((payment) => renderPaymentRow(payment, 'DD-MM-YYYY HH:MM'))
				),
			]),
		]);
	}
}

export default vwLinesPaymentsTab;
