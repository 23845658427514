'use strict';
import m from 'mithril';
import { Checkbox } from 'construct-ui';
import Super from './Super';
import './PermissionsSelector.css';

let policyList = [];

const isSelected = (obj, act, role_resources) => {
	return obj in role_resources && role_resources[obj].includes(act);
};

export const PermissionsSelector = {
	oninit: async ({ attrs: { role = '' } }) => {
		const response = await Super.get(
			'policies',
			role ? { role } : { role: '' }
		);
		const result = response.data;

		policyList = Object.keys(result.resources).reduce((list, obj) => {
			if (obj !== '*') {
				result.resources[obj].map((act) => {
					list.push({
						obj: obj,
						act: act,
						checked: isSelected(obj, act, result.roleResources),
					});
				});
			}
			return list;
		}, []);
		policyList.sort((a, b) => {
			if (a.obj < b.obj) return -1;
			if (a.obj > b.obj) return 1;
			if (a.act > b.act) return -1;
			if (a.act < b.act) return 1;
			return 0;
		});

		// console.log(policyList)
		m.redraw();
	},
	view: ({ attrs: { cb } }) => {
		let lastObj = '';
		return m('.PermissionsSelector', [
			// m(ControlGroup,
			policyList.map((p, idx) => {
				// prefix with group (obj of policy)
				let obj = void 0;
				if (lastObj !== p.obj) {
					lastObj = p.obj;
					obj = m('.obj', p.obj);
				}
				return [
					obj,
					m(Checkbox, {
						class: 'checkbox',
						indeterminate: false,
						value: idx,
						label: m('.label', m('.act', p.act)),
						checked: p.checked,
						onchange: (ev) => {
							p.checked = ev.currentTarget.checked;
							console.log(
								'selected',
								ev.currentTarget.checked,
								p
							);
							if (typeof cb === 'function') cb(p);
						},
					}),
				];
			}),
			// )
		]);
	},
};
