import { Button, Col, Grid, Input, Tag } from 'construct-ui';
import m from 'mithril';
import app from '../../app';
import sjefIconChevronLeft from '@sjefapp/sjeficons/icons/sjefIconChevronLeft';
import sjefIconMenu from '@sjefapp/sjeficons/icons/sjefIconMenu';
import FeatureFlags, { bottomMenuFlag } from '../../featureFlags';

const bottomMenu = {
	view: () => {
		return m(
			'.bottomMenu',
			m(Tag, {
				label: `v${app.version}`,
				rounded: true,
				size: 'xs',
			}),
			m(
				'.menuItems',

				FeatureFlags.isEnabled(bottomMenuFlag) && [
					m(
						'a',
						{
							target: '_blank',
							class: 'apiDocs',
							onclick: () => {
								m.route.set('/docs/api');
							},
						},
						'API docs'
					),
					m('.about', 'About'),
					m('.help', 'Help'),
					m('.contact', 'Contact'),
				]
			)
		);
	},
};
export default bottomMenu;
