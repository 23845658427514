import m from 'mithril';
import sjefButton from '../views/components/sjefButton';
import app from '../app';
import sjefIconArrowRight from '@sjefapp/sjeficons/icons/sjefIconArrowRight';
import { _t } from '../lib/i18n';
import sjefModal from '../views/components/sjefModal';
import sjefIconArrowBigUpLine from '@sjefapp/sjeficons/icons/sjefIconArrowBigUpLine';

const moduleName = 'organisations';
const baseRoute = '/organisations';
const endpoint = '/clients/organisations';
// const endpoint = 'http://127.0.0.1:3007/organisations';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];
		this.formButtons = [
			{
				component: sjefButton,
				componentAttrs: (recordId) => ({
					iconLeft: m(sjefIconArrowBigUpLine),
					label: _t('_.labels.openOrganisation'),
					buttonLayout: 'neutral',
					onclick: async () => {
						// console.log('record', recordId)
						await app.switchAccessLevel(8, 'organisation', { oid: recordId });
					},
				})
			}
		];
		this.layoutType = 'small';
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
						filter: false,
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						onClickAction: async (recordId) => {
							await app.switchAccessLevel(8, 'organisation', { oid: recordId });
						},
						// defaultFilter: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
				],
			},
		];
	}
}
