import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconChevronUp from '@sjefapp/sjeficons/icons/sjefIconChevronUp';
import { Collapse, Input } from 'construct-ui';
import m from 'mithril';
import { _t } from '../../lib/i18n';
import sjefLink from './sjefLink';

/**
 * Class representing a translation input field.
 */
class sjefInputTranslations {
	defaultLang = 'nl';
	form = {};
	field = {};
	fieldName;
	translateField;
	isOpen = false;
	isTranslated = false;
	languages = ['nl', 'en', 'de', 'fr', 'es', 'it'];

	/**
	 * Initialize a new sjefInputTranslations object.
	 * @param {Object} vnode - The Mithril virtual node.
	 */
	constructor({ attrs: { form, fieldName, translateField } }) {
		this.form = form;
		this.fieldName = fieldName;
		this.translateField = translateField;

		this.field = this.form[this.fieldName] || {
			nl: '',
			en: '',
			de: '',
			fr: '',
			it: '',
			es: '',
		};

		this.checkIfTranslated();
	}

	/**
	 * Checks if the field has been translated.
	 */
	checkIfTranslated() {
		this.isTranslated = Object.values(this.field).some((value) => value);
	}

	/**
	 * Fetch the translation for a specific language.
	 * @param {string} lang - The language to translate to.
	 * @param {string} text - The text to translate.
	 * @returns {Promise<string|null>} The translation or null if failed.
	 */
	async fetchTranslation(lang, text) {
		const url = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyB9PQl9OROzOo1erpDpMl3iuqg2xsPnrrg`;

		try {
			const result = await m.request({
				url,
				method: 'POST',
				body: {
					source: this.defaultLang,
					target: lang,
					q: text,
					key: 'AIzaSyB9PQl9OROzOo1erpDpMl3iuqg2xsPnrrg',
				},
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return result.data.translations[0].translatedText;
		} catch (error) {
			console.error(`Error translating to ${lang}: ${error}`);
			return null;
		}
	}
	// async fetchTranslation(lang, text) {
	// 	const url = `https://api.mymemory.translated.net/get?q=${text}&langpair=nl|${lang}`;
	// 	try {
	// 		const result = await m.request({ url, method: 'GET' });

	// 		return result.responseData.translatedText;
	// 	} catch (error) {
	// 		console.error(`Error translating to ${lang}: ${error}`);
	// 		return null;
	// 	}
	// }

	/**
	 * Translates the text to all available languages.
	 * @returns {Promise<void>}
	 */
	async translate() {
		const text = this.form[this.translateField];
		await Promise.all(
			this.languages
				.filter((lang) => this.defaultLang !== lang)
				.map(async (lang) => {
					const translation = await this.fetchTranslation(lang, text);
					this.field[lang] = translation;
				})
		);

		this.field[this.defaultLang] = text;
		this.checkIfTranslated();
	}

	/**
	 * Toggles the field open/closed state and triggers translation if necessary.
	 */
	toggle() {
		this.isOpen = !this.isOpen;
		if (this.isOpen && !this.isTranslated && this.form[this.translateField]) {
			this.translate();
		}
	}

	/**
	 * Renders the component view.
	 * @returns {Object} The Mithril virtual DOM tree.
	 */
	view() {
		return m('.nameTranslations', [
			m(
				'.nameTranslationsInner',
				m(
					'.translationToggle',
					{ onclick: () => this.toggle() },
					this.isOpen ? m(sjefIconChevronUp) : m(sjefIconChevronDown)
				)
			),
			m(
				Collapse,
				{
					duration: 300,
					isOpen: this.isOpen,
				},
				[
					m(
						'',
						m(
							'.flexer',
							m('h4', _t('_.fields.translations', { defaultValue: 'Translations' })),
							m(
								'a',
								{
									onclick: () => this.translate(),
								},

								_t('_.buttons.autoTranslate', { defaultValue: 'Auto translate' })
							)
						),
						this.languages.map((lang) =>
							m(Input, {
								id: lang,
								value: this.field[lang] || '',
								type: 'text',
								placeholder: `${lang}`,
								oninput: (ev) => (this.field[lang] = ev.target.value),
							})
						)
					),
				]
			),
		]);
	}
}

export default sjefInputTranslations;
