import m from 'mithril';
import './css/index.scss';
import { routes } from './routes';
import app from './app';
import { version } from '../package.json';

import { initLocales, currentLanguage } from './lib/i18n.js';

const start = async () => {
	// eslint-disable-next-line no-undef
	const API_ROOT = `${process.env.API_ROOT}` || '/';
	// eslint-disable-next-line no-undef
	// let API_VERSION = process.env.API_VERSION || 'v1';
	// eslint-disable-next-line no-undef
	app.version = version;

	await initLocales('en');
	app.language = currentLanguage();

	await app.init({ apiRoot: API_ROOT });

	await routes();

	app.checkIdle();

	activeStates();

	const currentRoute = m.route.get();

	if (currentRoute && currentRoute.startsWith('/login') && app.isAuthenticated) {
		const redirect = m.route.param('redirect');
		if (redirect) {
			m.route.set(redirect);
		} else {
			m.route.set('/');
		}
	}
};

// @ts-ignore
window.addEventListener('DOMContentLoaded', start);

window.addEventListener('focus', () => {
	// Check for possible account(level) change. If so reload
	if (app.apiToken && app.restore('token') && (app.apiToken !== app.restore('token'))) {
		app.events.reloadRequired = true;
		m.redraw();
	}
});

// eslint-disable-next-line no-undef
if (module.hot) {
	// eslint-disable-next-line no-undef
	module.hot.accept(() => {
		// console.log('reload?', msg);
		document.location.reload();
	});
}

const activeStates = () => {
    const container = document.body; // Use a more specific container if possible

    container.addEventListener('click', function(event) {
        const target = event.target.closest('.active-state-elem');

        // Remove active class from all elements
        document.querySelectorAll('.active-state-elem.js-active-state').forEach((elem) => {
            elem.classList.remove('js-active-state');
        });

        if (!target) return; // Click was not on an .active-state-elem

        // Add active class to clicked element
        target.classList.add('js-active-state');
    });
};

// TODO: disableDoubleClick gaat niet werken, buttons zijn op dit moment welicht zichtbaar, maar nieuwe zijn nog onbekend en daar werkt deze dan niet meer voor!!!
// Dit afvangen in de sjefButton en die altijd gebruiken voor buttons!?
// const disableDoubleClick = () => {
// 	document.addEventListener('DOMContentLoaded', function () {
// 		const buttons = document.querySelectorAll('button');

// 		buttons.forEach((button) => {
// 			let isClicked = false;

// 			button.addEventListener('click', function (event) {
// 				if (isClicked) return;
// 				isClicked = true;

// 				setTimeout(() => {
// 					isClicked = false;
// 				}, 1000); // disable for 1 second
// 			});
// 		});
// 	});
// };
