import m from 'mithril';
import { Card } from 'construct-ui';

const iconStyle = (color) => {
	return {
		color,
		height: 'fit-content'
	}
}

class sjefWidget {
	oninit({ attrs }) {
		this.key = attrs.key;
		this.color = attrs.color;
		this.title = attrs.title;
		this.subTitle = attrs.subTitle;
		this.icon = attrs.icon;
		this.progress = attrs.progress;
		this.style = attrs.style
	}
	view() {
		const progressCircleStyle = (color) => `stroke:${color}`
		const mutedProgressCircleStyle = (color) => `stroke:${color}33`

		const progressCircle = (progress, color) => {
			return m.trust(`<svg role="progressbar" aria-valuenow="${progress}" aria-valuemax="100" class="block" viewBox="0 0 45 45" width="82" height="82" data-v-cb758208="">
				<circle style=${mutedProgressCircleStyle(color)} stroke-width="1" fill="none" cx="50%" cy="50%" r="15.91549431" data-v-cb758208=""></circle>
				<circle style=${progressCircleStyle(color)} stroke-width="1" stroke-dasharray="${progress},100" stroke-linecap="round" fill="none" cx="50%" cy="50%" r="15.91549431" data-v-cb758208=""></circle>
			</svg>`)
		}

		return m(Card, { key: this.key, class: 'widget', style: this.style },
			[
				m('.widget__progress-bar', { style: iconStyle(this.color) }, [
					m('', {
						style: {
							transform: 'rotate(-90deg)',
							transformOrigin: 'center'
						}
					}, progressCircle(this.progress, this.color)),
					m(this.icon, { class: 'widget__progress-bar-icon', style: iconStyle(this.color), size: 24 })
				]),
				m('.widget__text', [
					m('.widget__text-title', this.title),
					m('.widget__text-subtitle', this.subTitle),
				])
			]
		)
	}
}

export default sjefWidget;
