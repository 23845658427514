import m from 'mithril';
import { Collapse, Tree, TreeNode } from 'construct-ui';
import app from '../../app';
import sjefIcon123 from '@sjefapp/sjeficons/icons/sjefIcon123';
import sjefIconBucketDroplet from '@sjefapp/sjeficons/icons/sjefIconBucketDroplet';
import { ARCHIVE } from 'construct-ui/lib/esm/components/icon/generated/IconNames';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconHome from '@sjefapp/sjeficons/icons/sjefIconHome';
import sjefIconCalendar from '@sjefapp/sjeficons/icons/sjefIconCalendar';
import sjefIconHomeCog from '@sjefapp/sjeficons/icons/sjefIconHomeCog';
import sjefIconMan from '@sjefapp/sjeficons/icons/sjefIconMan';
import sjefIconFriends from '@sjefapp/sjeficons/icons/sjefIconFriends';
import sjefIconChartAreaLine from '@sjefapp/sjeficons/icons/sjefIconChartAreaLine';
import sjefIconServerCog from '@sjefapp/sjeficons/icons/sjefIconServerCog';
import sjefIconStar from '@sjefapp/sjeficons/icons/sjefIconStar';
import sjefIconStarOff from '@sjefapp/sjeficons/icons/sjefIconStarOff';
import tableStore from '../../lib/tableStore';
import { resetTableStore } from '../../lib/helpers';
import sjefIconAsset from '@sjefapp/sjeficons/icons/sjefIconAsset';
import sjefIconCone from '@sjefapp/sjeficons/icons/sjefIconCone';
import sjefIconTags from '@sjefapp/sjeficons/icons/sjefIconTags';
import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';
import sjefCheckbox from './sjefCheckbox';
import eventBus from '../../lib/eventBus';
import sjefIconBuildingBank from '@sjefapp/sjeficons/icons/sjefIconBuildingBank';
import sjefIconFiles from '@sjefapp/sjeficons/icons/sjefIconFiles';
import sjefIconReport from '@sjefapp/sjeficons/icons/sjefIconReport';
import sjefIconCoinEuro from '@sjefapp/sjeficons/icons/sjefIconCoinEuro';
import sjefIconSwitchHorizontal from '@sjefapp/sjeficons/icons/sjefIconSwitchHorizontal';
import sjefIconSettings from '@sjefapp/sjeficons/icons/sjefIconSettings';
import sjefIconMoneybag from '@sjefapp/sjeficons/icons/sjefIconMoneybag';
import sjefIconBarcode from '@sjefapp/sjeficons/icons/sjefIconBarcode';
import { _t } from '../../lib/i18n';
import sjefIconFlag from '@sjefapp/sjeficons/icons/sjefIconFlag';

/**`
 * sideMenu component, auto generated after login
 * @type {import("mithril").Component}
 */

const icons = {
	home: m(sjefIconHome),
	calendar: m(sjefIconCalendar),
	operations: m(sjefIconServerCog),
	crm: m(sjefIconMan),
	clients: m(sjefIconFriends),
	admin: m(sjefIconChartAreaLine),
	assets: m(sjefIconAsset),
	customerProfiles: m(sjefIconCone),
	organisations: m(sjefIconTags),
	createEvent: m(sjefIconCalendar),
	lockerEvents: m(sjefIconCalendar),
	cabinets: m(sjefIconHomeCog),
	lockers: m(sjefIconHomeCog),
	maintenance: m(sjefIconHomeCog),
	banking: m(sjefIconBuildingBank),
	kyc: m(sjefIconFlag),
	users: m(sjefIconFriends),
	administrations: m(sjefIconServerCog),
	invoices: m(sjefIconFiles),
	projects: m(sjefIconFiles),
	reports: m(sjefIconReport),
	inpersonpayments: m(sjefIconCoinEuro),
	Transactions: m(sjefIconSwitchHorizontal),
	masterdata: m(sjefIconServerCog),
	settings: m(sjefIconSettings),
	Payments: m(sjefIconMoneybag),
	products: m(sjefIconBarcode),
};

let navigationFavourites = [];

const createMenuArray = (items) => {
	Object.values(items).map((item) => {
		app.menuArray.push(item);
		if (item.children) createMenuArray(item.children);
	});
};

const getNavigationFavourites = () => {
	navigationFavourites = [];

	if (app.menu) {
		app.userSettings.navigationFavourites?.split(',').forEach(function (elem) {
			const splittedItem = elem.trim().split('.');

			if (splittedItem[0] == app.lvl) {
				navigationFavourites.push(splittedItem[splittedItem.length - 1]);
			}
		});
	}

	m.redraw();
};

const toggleFavourite = async (item) => {
	const arrayItem = app.lvl + '.' + item;

	let navFavs = app.userSettings.navigationFavourites?.split(',').map((s) => s.trim()) || [];

	if (navFavs.includes(arrayItem)) {
		navFavs = navFavs.filter((item) => item !== arrayItem);
	} else {
		navFavs.push(arrayItem);
	}

	const localNavigationFavourites = navFavs.join(',');
	app.userSettings.navigationFavourites = localNavigationFavourites;

	const result = app.patch('/user/settings/navigationFavourites', {
		id: app.user.uid,
		favourites: app.userSettings.navigationFavourites,
	});

	getNavigationFavourites();

	m.redraw();
};

const hasChildren = (item) => {
	let state = false;

	if (item.children) {
		state = true;

		const states = [];
		item.children.forEach(function (elem) {
			states.push(elem.hidden);
		});

		if (states.filter((state) => state !== true).length !== states.length && states.length !== 0) {
			state = false;
		}
	}

	return state ? 'hasChildren' : '';
};

const itemOpen = (item) => {
	return item.open ? 'itemOpen' : '';
};

/* eslint-enable */
const findRouteById = (menuItems, id, parentRoute = '') => {
	for (const item of menuItems) {
		const currentRoute = parentRoute ? `${parentRoute}/${item.route}` : item.route;

		if (item.id === id) {
			return currentRoute;
		}

		if (item.children) {
			const childRoute = findRouteById(item.children, id, currentRoute);
			if (childRoute) return childRoute;
		}
	}
	return null;
};

const isMenuSelected = (id) => {
	const selected = app.restore('menuSelected');

	return selected === id;
};

const findMenuItemById = (menuItems, id) => {
	const idTrimmed = id.trim();

	for (const item of menuItems) {
		if (item.id === idTrimmed) {
			// Using object destructuring to omit the 'children' property
			const { children, ...itemWithoutChildren } = item;
			return itemWithoutChildren;
		}
		// If the item has children, recursively search within them
		if (item.children) {
			const result = findMenuItemById(item.children, idTrimmed);
			if (result) return result;
		}
	}
	// Return null if no item is found
	return null;
};

const menuItems = (items, isTop = true, parentRoute = ['']) => {
	const currentRoute = m.route.get();

	return Object.keys(items)
		.filter((k) => {
			if (!items[k].component) {
				return true;
			}
			return true;
			return app.accessibleComponents.includes(items[k].component);
		})
		.map((k) => {
			const item = items[k];
			const itemRoute = [...parentRoute, item.route].join('/');

			if (item.hidden) return void 0;
			if (isTop) {
				// console.log('isSelected', currentRoute.startsWith(itemRoute));
				// console.log('isSelectedParent', item.id, [...parentRoute,item.route].join("/"));

				return [
					m(TreeNode, {
						label: item.label,
						// label: _t(`modules.${item.id}.title`),
						class: [
							'main',
							// 'active-state-elem',
							'iconElement',
							hasChildren(item),
							'menu-item-' + item.id,
							itemOpen(item),
						].join(' '),
						// contentLeft: m(sjefIconBucketDroplet), // todo: get from item (just 'BucketDroplet' maybe)
						// onmouseover: (e) => {
						// 	if (item.id == 'clients') {
						// 		let clients = app.get('/clients/clients');
						// 		consoel
						// 	}
						// 	e.target.style.backgroundColor = 'red'; // Set background to red on hover
						// },
						// onmouseout: (e) => {
						// 	e.target.style.backgroundColor = ''; // Remove background color when not hovering
						// },
						contentLeft: icons[item.id] || m(sjefIconBucketDroplet),
						contentRight:
							hasChildren(item) !== ''
								? m(sjefIconChevronDown)
								: item.route
								? // m(
								  // 		'div.starIcon',
								  // 		{
								  // 			onclick: () => {
								  // 				toggleFavourite(item.id);
								  // 				m.redraw();
								  // 			},
								  // 		},
								  // 		m('.iconStar', {
								  // 			class: navigationFavourites.includes(item.id) ? 'favourite' : '' },
								  // 			m(sjefIconCheck)
								  // 		),
								  //   )
								  m(sjefCheckbox, {
										class: 'iconElement',
										checked: navigationFavourites.includes(item.id),
										onChange: () => {
											toggleFavourite(item.id);
										},
								  })
								: void 0,

						isSelected: isMenuSelected(item.id),
						// ||
						// currentRoute.startsWith(itemRoute),
						isExpanded: true,
						// ||
						// currentRoute.startsWith(itemRoute),

						// hasCaret:
						// 	item.children && item.children.length ? true : false,
						onClick: () => {
							// if (isMenuMinimized() == false) {

							const sameMenu = app.menuSelected == item.id;

							item.open = !item.open;
							app.menuSelected = item.id;
							app.store('menuSelected', item.id);

							if (item.module) {
								document.body.classList.remove('mobile-menu-open');
								if (sameMenu) {
									resetTableStore(true);
									eventBus.publish('refreshList', { timestamp: new Date().toISOString() });
								} else {
									resetTableStore();
									m.route.set(itemRoute);
								}

								item.open = false;
							}
							// closeOtherMenus(items, k);
							// }
						},

						children: item.children
							? m(
									Collapse,
									{
										duration: 0, // 200
										isOpen: item.open,
									},
									// m(Collapse) should have only 1 child for smooth behaviour
									m('', menuItems(item.children, false, [...parentRoute, item.route]))
							  )
							: void 0,
					}),
				];
			} else {
				return m(
					m.route.Link,
					{
						class: 'menuNavLink',
						href: `${item.route}`,
						onclick: (e) => {
							if (!(e.altKey || e.metaKey)) {
								e.preventDefault();
							}
						},
					},
					m(TreeNode, {
						label: item.label,
						contentLeft: m(sjefIcon123),
						contentRight: [
							m(sjefCheckbox, {
								class: 'iconElement',
								checked: navigationFavourites.includes(item.id),
								onChange: () => {
									toggleFavourite(item.id);
								},
							}),
						],
						isSelected: app.menuSelected === item.id,
						isExpanded: app.menuSelected === item.id || currentRoute.startsWith(itemRoute),

						onClick: () => {
							app.menuSelected = item.id;
							// console.log('parentRoute', [...parentRoute, item.route].join('/'));
							// if(item.path) m.route.set( item.path )
							if (item.module) {
								document.body.classList.remove('mobile-menu-open');
								m.route.set(itemRoute);
								item.open = false;
							}
						},

						children:
							item.children && Object.keys(item.children).length > 0
								? menuItems(item.children, false, [...parentRoute, item.route])
								: void 0,
					})
				);
			}
		});
};

const sideMenu = {
	oninit: function () {
		getNavigationFavourites();
	},

	oncreate: async () => {
		// console.debug('oncreate sideMenu');
		app.menuArray = [];

		createMenuArray(app.menu);
		// console.log('app.menu', app.menu);
	},

	menuMinimized: () => {
		console.log('perform menuMimized');
	},

	view: () => {
		return m(
			'',
			m(
				'.favouritesWrapper',
				m(Tree, {
					onbeforeremove: (vnode) => {
						return new Promise((resolve) => {
							vnode.dom.classList.add('removing');
							setTimeout(resolve, 200);
						});
					},
					class: 'favourites menu',
					interactive: true,
					nodes: [
						navigationFavourites.map((item) => {
							// console.log(app.menu);
							const route = findMenuItemById(app.menu, item)?.route;

							if (route) {
								const route = findRouteById(app.menu, item);

								return m(
									m.route.Link,
									{
										class: 'menuNavLink',
										href: `${findMenuItemById(app.menu, item).route}`,
										onclick: (e) => {
											if (!(e.altKey || e.metaKey)) {
												e.preventDefault();
											}
										},
									},
									m(TreeNode, {
										contentLeft: m(sjefIconStar, {
											class: 'favourite',
										}),
										// class: 'main iconElement',
										class: [
											'main',
											// 'active-state-elem',
											'iconElement',
										].join(' '),
										label: findMenuItemById(app.menu, item).label,
										onclick: () => {
											m.route.set(route);
											m.redraw();
										},
									})
								);
							}
						}),
					],
				})
			),

			m(Tree, {
				class: 'menu',
				interactive: true,
				nodes: menuItems(app.menu),
			})
		);
	},
};

export default sideMenu;
