import m from 'mithril'
import sjefFilterContainer from './sjefFilter'
import sjefTable from './sjefTable'
import TableLayout from './sjefTableLayout'
import sjefDataHeader from './sjefDataHeader'
import { useState, withHooks, useEffect } from 'mithril-hooks';
import app from '../../../app'


const DataList = withHooks(({ config }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [records, setRecords] = useState([]);
	const [fields, setFields] = useState([]);
	const [selectedRecords, setSelectedRecords] = useState([]);
	const [allRecordsSelected, setAllRecordsSelected] = useState(false);
	const [metadata, setMetadata] = useState({
		offset: 0,
		limit: 25,
		total: 0,
	});
	const [filterData, setFilterData] = useState(undefined);
	const [sortData, setSortData] = useState({});
	const [activeSort, setActiveSort] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const [activeFilters, setActiveFilters] = useState({});

	const tableConfig = new config;

	const toggleAllRecordsSelection = (state) => {
		setAllRecordsSelected(state);

		if (state === true) {
			setSelectedRecords(records.map((record) => record.id));
		}

		if (state === false) {
			setSelectedRecords([]);
		}
	}

	useEffect(
		async () => {
			const defaultFilters = await setDefaultFilter();
			setActiveFilters(defaultFilters);
			setFilterData(convertToFilterParams(defaultFilters));
		}, []
	)

	useEffect(
		async () => {
			if (filterData === undefined) return;
			setIsLoading(true);
			const extractedFields = extractFieldsFromSchema(tableConfig);
			setFields(extractedFields)
			await fetchRecords()
			setIsLoading(false);

		}, [filterData, sortData]
	)

	const setDefaultFilter = async () => {
		if (tableConfig?.defaultValues) {
			const defaultFilterParams = {};
			await Promise.all(
				tableConfig.defaultValues.map(async (defaultValueObject) => {
					if (defaultValueObject.value) {
						defaultFilterParams[defaultValueObject.filterName] = defaultValueObject.value;
						return;
					}
					const defaultValue = defaultValueObject.endpoint ? await app.get(defaultValueObject.endpoint) : {};
					const defaultFilter = defaultValue.data[defaultValueObject.attribute][0] || {};
					defaultFilterParams[defaultValueObject.filterName] = defaultFilter;
				}
				));
			return defaultFilterParams
		}
	}

	const convertToFilterParams = (fields) => {
		const filters = fields
		const filtersParams = Object.keys(filters).reduce((paramsObject, key) => {
			paramsObject[`filter[${key}]`] = filters[key];
			return paramsObject;
		}, {});
		return filtersParams;
	}

	const setFilterFields = () => {
		const filtersParams = convertToFilterParams(activeFilters);
		Object.keys(filtersParams).forEach((key) => {
			if (filtersParams[key] === '') {
				delete filtersParams[key];
			}
		});

		setFilterData(filtersParams);
	}

	const setSorting = (sortingData) => {
		setActiveSort(sortingData.sort);
		setSortData(sortingData);
	}

	const extractFieldsFromSchema = (config) => {
		if (!config || !Array.isArray(config.schema)) {
			return [];
		}

		const moduleSettings = ((app.userSettings || {}).tableLayouts || {})[config.moduleName] || {};

		return config.schema.flatMap((group) =>
			(group.fields || []).map((field) => {
				const fieldSettings = moduleSettings[field.name];
				if (fieldSettings) {
					return {
						...field,
						listShow: fieldSettings.show,
						listColumnPosition: fieldSettings.position,
					};
				}
				return field;
			})
		);
	}

	const fetchRecords = async () => {
		const result = await app.get(tableConfig.endpoint, { ...metadata, ...filterData, ...sortData });
		if (result.success) {
			setRecords(result.data.sort((a, b) => a.listColumnPosition - b.listColumnPosition));
			setMetadata(result.metadata);
		}
	}

	const archiveRecords = async () => {
		const results = await app.delete(tableConfig.endpoint, { id: selectedRecords, archived: true });
		if (results.success) {
			await fetchRecords();
		}
	}

	const deleteRecords = async () => {
		const results = await app.delete(tableConfig.endpoint, { id: selectedRecords });
		if (results.success) {
			await fetchRecords();
		}
	}

	return m('', [
		m(sjefDataHeader, {
			fields,
			listTabs: tableConfig.listTabs,
			moduleName: tableConfig.moduleName,
			actions: tableConfig.actions,
			listButtons: tableConfig.listButtons,
			selectedRecords,
			archiveRecords,
			deleteRecords,
			state: { setIsDrawerOpen, isDrawerOpen }
		}),
		!isLoading && sjefFilterContainer({ fields, activeFilters, setFilterFields }),
		!isLoading && m(sjefTable, {
			fields,
			records,
			config: tableConfig,
			selectedRecords,
			setSelectedRecords,
			metadata,
			setMetadata,
			activeSort,
			setSorting,
			allRecordsSelected,
			toggleAllRecordsSelection,
		}),
		m(TableLayout, { fields, state: { setIsDrawerOpen, isDrawerOpen, setFields }, config: tableConfig }),
	])


})

export default DataList
