[
	{
		"id": "home",
		"label": "Home",
		"route": "",
		"hidden": true,
		"module": "vwHome"
	},
	{
		"id": "organisations",
		"label": "Organisations",
		"route": "organisations",
		"module": "vwList",
		"config": "organisations",
		"children": [
			{
				"id": "organisation",
				"label": "organisation",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "organisations"
			}
		]
	},
	{
		"id": "assets",
		"label": "Assets",
		"route": "assets",
		"module": "vwList",
		"config": "assets",
		"children": [
			{
				"id": "asset",
				"label": "Asset",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "assets"
			}
		]
	},
	{
		"id": "profiles",
		"label": "Customer Profiles",
		"hidden": true,
		"route": "profiles",
		"module": "vwList",
		"config": "customerProfiles",
		"children": [
			{
				"id": "customerProfile",
				"label": "Customer Profile",
				"hidden": true,
				"route": ":id",
				"module": "vwProfileDashboard"
			}
		]
	},
	{
		"id": "admin",
		"label": "Admin",
		"route": "admin",
		"module": false,
		"children": [
			{
				"id": "users",
				"label": "Users",
				"route": "users",
				"module": "vwUsers",
				"children": [
					{
						"id": "user",
						"label": "User",
						"hidden": true,
						"route": ":id",
						"module": "vwUser"
					}
				]
			},
			{
				"id": "roles",
				"label": "Roles",
				"route": "roles",
				"module": "vwRoles",
				"children": [
					{
						"id": "role",
						"label": "Role",
						"hidden": true,
						"route": ":id",
						"module": "vwRoleForm"
					}
				]
			},
			{
				"id": "jobs",
				"label": "Jobs",
				"route": "jobs",
				"module": "vwJobs",
				"children": [
					{
						"id": "jobNew",
						"label": "New Job",
						"hidden": true,
						"route": ":id",
						"module": "vwJobNew"
					}
				]
			}
		]
	}
]
