import vwRoleForm from './Super/RoleForm';
import vwRoles from './Super/Roles';
import vwUser from './Super/User';
import vwUserForm from './Super/UserForm';
import vwUsers from './Super/Users';
import { vwJobNew } from './admin/vwJobNew';
import { vwJobs } from './admin/vwJobs';

import vwExampleModuleList from './ExampleModule/vwExampleModuleList';
import vwExampleModuleForm from './ExampleModule/vwExampleModuleForm';

import vwHome from './vwHome';

import vwPsp from './clients/vwPsp';

// import vwAssets from './assets/vwAssets';
// import vwAssetForm from './assets/vwAssetForm';

import vwProfileDashboard from './customerProfiles/components/vwProfileDashboard';

import vwUserProfile from './profile/vwProfile';

import vwPosList from './inPersonPayments/vwPosList';
import vwSettings from './settings/vwSettings';

import vwEventOverview from './../views/lockers/eventOverview';
import vwTickets from './../views/lockers/vwTickets';

import vwCalendar from './calendar/vwCalendar';
import vwClients from './clients/vwClients';

import vwLockerLanding from './../views/lockers/vwLockers';
import vwLockerOverview from './../views/lockers/vwLockerOverview';

import vwList from './_default/vwList';
import vwForm from './_default/vwForm';
import DataList from './_default/dataList/dataList';

import vwDocumentationApi from './documentation/vwDocumentationApi';

import vwInvoices from './clients/vwInvoices';
import vwKYC from './clients/vwKYC';

import vwPaymentConfigs from './organisations/vwPaymentConfigs';

const modules = {
	vwUsers,
	vwUser,
	vwRoles,
	vwUserForm,
	vwRoleForm,
	vwExampleModuleList,
	vwExampleModuleForm,
	vwHome,
	vwPsp,
	vwJobs,
	vwJobNew,
	vwPosList,
	vwSettings,
	vwProfileDashboard,
	vwUserProfile,
	vwEventOverview,
	vwTickets,
	vwCalendar,
	vwClients,
	vwLockerOverview,
	vwLockerLanding,

	vwDocumentationApi,

	vwList,
	vwForm,
	DataList,
	vwInvoices,

	vwKYC,
	vwPaymentConfigs,
};
export default modules;
