import m from 'mithril';

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';

import sjefBreadcrumbs from '../../views/components/sjefBreadcrumbs';
import app from '../../app';

class vwCalendar {
	events = [];

	oncreate = async (vnode) => {
		let calendar;

		const loadEvents = async (start, end) => {
			start = convertToUnixTimestamp(start, 'Europe/Amsterdam');
			end = convertToUnixTimestamp(end, 'Europe/Amsterdam');

			// Simulate fetching new events data from a server (you can replace this with actual data fetching)
			// const events = await hubspotServiceDealsEvents(start, end);
			const result = await app.get(`/hubspot/deals/events`, {
				'filter[eventStart]': start,
				'filter[eventEnd]': end,
			});

			if (result.success) {
				this.events = result.data;
			}

			// Remove existing events from the calendar
			calendar.getEvents().forEach((event) => {
				event.remove();
			});

			// Add the new events to the calendar
			calendar.addEventSource(this.events);
		};

		// Initialize FullCalendar
		const calendarEl = vnode.dom;
		calendar = new Calendar(calendarEl, {
			timeZone: 'Europe/Amsterdam',
			plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
			headerToolbar: {
				left: 'prev,next today',
				center: 'title',
				right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
			},
			events: [],
			datesSet: (info) => {
				console.log(info);
				// Extract the start and end dates of the current view
				const { start, end } = info;
				console.log(start);
				// Call loadEvents with the updated dates
				loadEvents(start, end);
			},
			eventClick: (info) => {
				// Hide the popover when the event is clicked again
				const popoverEl = document.querySelector('.fc-popover');
				popoverEl.style.visibility = 'hidden';
			},
		});

		calendar.render();
	};

	view() {
		return m(
			'.calendar',
			m(sjefBreadcrumbs),

			m('', 'Test'),
			m('div', { id: 'calendar' })
		);
	}
}

function convertToUnixTimestamp(dateString, utcOffset = 'Europe/Amsterdam') {
	return moment.utc(dateString, 'YYYY-MM-DDTHH:mm:ss').utcOffset(utcOffset).format('YYYY-MM-DDTHH:mm:ss');
}

export default vwCalendar;
