import app from '../../../app';

const endPoints = {
	accounts: {
		url: '/masterdata/accounts', 
	},
	accountGet: {
		url: '/masterdata/accounts/6',
	},
};

const Api = {
	menu: false,
	secure: false, // { obj: "ui", act: "super"},
	data: {
		accounts: [],
	},

	get: async (key, data = {}, reload = false) => {
		if (
			Object.keys(data) ||
			reload ||
			!Api.data[key] ||
			(!Api.data[key].length && key in endPoints)
		) {
			const r = await app.get(endPoints[key].url, data);
			console.log(r);
			if (r.error) {
				// SHOW ERROR TOAST
			} else {
				Api.data[key] = {
					data: r.data,
					metadata: r.metadata
				}
			}
		}

		return Api.data[key];
	},
};

export default Api;
