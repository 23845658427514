import { CustomSelect, ListItem, Popover } from 'construct-ui';
import m from 'mithril';
import vwAccounts from '../../modules/ExampleModule/vwExampleModuleList';
import { Tooltip } from 'construct-ui';
/* eslint:disable */

// ---- should be used in the parent component
// onPaginationUpdate: async (paginationReturnData) => {
// 	vwAccounts.metadata = Object.assign(
// 		vwAccounts.metadata,
// 		paginationReturnData
// 	);
// 	loadList();
// },

const compareNumbers = (a, b) => {
	return a - b;
};

class sjefPagination {
	maxPagesVisible = 9;
	offset = 0;
	limit = 25;
	pagination = this;
	itemCount = 0;
	limitOptions = [5, 10, 25, 50, 100];

	returnVisiblePages(pages) {
		if (pages < this.maxPagesVisible) {
			return [...Array(pages)];
		}


		// Get the first and last page part length
		let firstPartLength = Math.ceil(this.maxPagesVisible / 3);
		let secondPartLength = Math.floor(this.maxPagesVisible / 3);
		let currentPages = Array.from({ length: firstPartLength }, (_, i) => i + this.offset - Math.ceil(firstPartLength / 2 - 1));
		let firstPages = Array.from({ length: firstPartLength }, (_, i) => i);
		let totalPagesAmount = Math.ceil(this.total / this.limit);


		// create array if current page and trailing pages.
		if (currentPages.includes(firstPartLength)) {
			for (let i = firstPartLength - 1; i >= 0; i--) {
				if (currentPages.includes(i) == false) {
					currentPages.push(i);
				}
			}
		}

		//add checks for trailing pages not going out of available range (lower than zero or larger than total amount of pages)
		let newCurrentPages = [];
		currentPages.forEach(function (elem) {
			let max = Math.max(...currentPages);

			if (elem < 0) {
				newCurrentPages.push(max + 1);
			} else if (max < totalPagesAmount) {
				newCurrentPages.push(parseInt(elem));
			}
		});
		currentPages = newCurrentPages.sort(compareNumbers);

		// define last pages
		let lastPages = [];
		for (let i = totalPagesAmount - secondPartLength + 1; i < totalPagesAmount; i++) {
			lastPages.push(i);
		}

		// merge first, current and last pages and remove duplicates
		let allPages = firstPages.concat(currentPages, lastPages);

		allPages = [...new Set(allPages)];

		// add 'pagination-gaps' to array on non-directly-ascending pages
		let gaps = [];
		allPages.forEach(function (elem, idx) {
			if (allPages[idx + 1] !== elem + 1 && idx !== allPages.length - 1) {
				gaps.push(idx);
			}
		});
		gaps.reverse().forEach(function (elem) {
			allPages.splice(elem + 1, 0, 'pagination-gap');
		});

		return allPages;
	}

	recordsShown(offset, total, limit) {
		// console.log(offset, total, limit)
		const maxOffset = Math.ceil(total / limit);

		if (offset !== maxOffset - 1) {
			return limit + ' / ' + total;
		}

		return (total % limit) + ' / ' + total;
	}

	updatePagination(args) {
		if (typeof this.parentOnPaginationUpdate === 'function') {
			this.parentOnPaginationUpdate(args);
		}
	}

	oninit(vnode) {
		if (typeof vnode.attrs.onPaginationUpdate === 'function') {
			this.parentOnPaginationUpdate = vnode.attrs.onPaginationUpdate;
		}
	}	

	view({ attrs: { subContent = null, paginationParams, onclick = void 0, ...htmlAttrs } }) {
		var self = this;
		self.offset = paginationParams.offset || 0;
		self.limit = paginationParams.limit || 25;
		self.total = paginationParams.total || 0;

		return m(
			'.paginationWrapper',
			m(
				'.flex.gridFilterAmounts',
				m('.filterAmounts', self.recordsShown(self.offset, self.total, self.limit)),
				subContent ? m('.subContent', subContent) : void 0
			),
			m(
				'.flex.gridPaginationPages',
				m(
					'.pagination',
					self.returnVisiblePages(Math.ceil(self.total / self.limit)).map(function (page, idx) {
						if (page == undefined) {
							page = idx;
						}

						return m(
							'.pageLink',
							{
								class: self.offset == page ? 'active' : '',
								onclick: () => {
									if (page !== 'pagination-gap') {
										self.offset = page;
										self.updatePagination({
											offset: self.offset,
											limit: self.limit,
										});
									}
								},
							},
							page !== 'pagination-gap'
								? page + 1
								: 
                                // m(
								// 		'span',
								// 		{
								// 			onclick: () => {
								// 				// todo:
								// 				let pages = self.returnVisiblePages(Math.ceil(self.total / self.limit));

								// 				let counter = idx;
								// 				let toolTipPages = [];

								// 				while (pages.includes(counter) == false) {
								// 					toolTipPages.push(counter + 1);
								// 					counter++;
								// 				}

								// 				console.log(toolTipPages);
								// 			},
								// 		},
								// 		'...'
								//   ),

                                m(Popover, {
                                    class: 'paginationPopover',
                                    content: (() => {
                                        let pages = self.returnVisiblePages(Math.ceil(self.total / self.limit));
                                        let counter = pages[idx-1] + 1;
                                        let toolTipPages = [];

                                        while (!pages.includes(counter)) {
                                            toolTipPages.push(counter + 1);
                                            counter++;
                                        }

                                        // return toolTipPages.join(', ');

                                        return toolTipPages.map(item => {
                                            return m('span', {
                                                onclick: () => {
                                                    // console.log(item);

                                                    self.offset = item - 1;
													self.updatePagination({
														offset: self.offset,
														limit: self.limit,
													});
                                                }
                                            }, item);
                                        })
                                    })(), 
                                    position: 'top',
                                    hasArrow: true,
                                    closeOnContentClick: true,
                                    closeOnEscapeKey: true,
                                    size: 'lg',
                                    trigger: m(
                                        'span',
                                        '...'
                                    ),
							})
						);
					})
				),
				m(
					'.limit',
					m(CustomSelect, {
						class: 'active-state-elem paginationSelect',
						value: self.limit,
						itemRender: (item, isSelected) =>
							m(ListItem, {
								label: item,
								selected: isSelected,
							}),
						onSelect: (evt) => {
							self.limit = evt;
							self.offset = 0;
							self.updatePagination({
								offset: self.offset,
								limit: self.limit,
							});
						},
						options: self.limitOptions,
					})
				)
			)
		);
	}
}
export default sjefPagination;
