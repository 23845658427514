import vwLinesPaymentsTab from '../modules/transactions/tabs/vwLinesPaymentsTab';
// import vwExampleTab from '../modules/_default/tabs/vwExampleTab';

const moduleName = 'transactions';
const baseRoute = '/transactions';
const endpoint = '/transactions/transactions';
// const endpoint = 'http://127.0.0.1:3000/categories';
export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => false,
			canUpdate: () => false,
			canDelete: () => false,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'read',
			},
			{
				slug: 'lines&payments',
				viewMode: 'read',
				component: vwLinesPaymentsTab,
				componentAttrs: {
					endpointData: '/transactions/transactions',
					endpointSubmit: endpoint,
					columns: ['lines', 'payments'],
				},
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'uuid',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'accountId',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'account',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'areaId',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'area',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'balancedue',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'clerkId',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'clerk',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerCity',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerCountry',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerEmail',
						type: 'string',
						formInputType: 'email',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerHousenumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerId',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'customerName',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerPhoneNumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerPhoneNumberCountryCode',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerStreetname',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'customerZipcode',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'discountAmount',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'fulfillment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						required: true,
					},
					{
						name: 'location',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'locationId',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'locale',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'notes',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'reference',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'source',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'status',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'subtotal',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'subtotalId',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'table',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'tableId',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'taxes',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'terminalSequence',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					// {
					// 	name: 'timestamp',
					// 	type: 'string',
					// 	formInputType: 'text',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 1,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	minLength: 1,
					// 	maxLength: 255,
					// 	nullable: true,
					// 	required: true,
					// },
					{
						name: 'total',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'totalCoins',
						type: 'number',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					// {
					// 	name: 'createdAt',
					// 	type: 'string',
					// 	formInputType: 'text',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 1,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	minLength: 1,
					// 	maxLength: 255,
					// 	nullable: true,
					// 	required: true,
					// },
					// {
					// 	name: 'updatedAt',
					// 	type: 'string',
					// 	formInputType: 'text',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 1,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	minLength: 1,
					// 	maxLength: 255,
					// 	nullable: true,
					// 	required: true,
					// },
				],
			},
		];
	}
}
