'use strict';
import { Checkbox } from 'construct-ui';
import m from 'mithril';
import Super from './Super';

let RolesList = [];

export const RolesSelector = {
	oninit: async ({ attrs: { selected = [] } }) => {
		const response = await Super.get('roles');
		RolesList = response.data
		RolesList.sort((a, b) => {
			if (a.role < b.role) return -1;
			if (a.role > b.role) return 1;
			return 0;
		});
		console.log('RolesList', selected);

		m.redraw();
	},
	view: ({ attrs: { selected = [], cb } }) => {
		console.log('RoleSelector', selected);
		return m('.RolesSelector', [
			RolesList.map((p, idx) => {
				return [
					m(Checkbox, {
						class: 'roleCheckbox',
						checked: selected.includes(p.role), // false,
						indeterminate: false,
						value: idx,
						label: m('.roleLabel', m('.role', p.role)),
						onchange: (ev) => {
							p.checked = ev.currentTarget.checked;
							console.log(
								'selected',
								ev.currentTarget.checked,
								p
							);
							if (typeof cb === 'function') cb(p);
						},
					}),
				];
			}),
		]);
	},
};
