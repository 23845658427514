import { Button, Card, Table } from 'construct-ui';
import m from 'mithril';
import sjefBreadcrumbs from '../../views/components/sjefBreadcrumbs';
import { _t } from '../../lib/i18n';
import app from '../../app';

import vwPosSettingsModal from './components/vwPosSettingsModal';
import sjefButton from '../../views/components/sjefButton';
import sjefIconSettings from '@sjefapp/sjeficons/icons/sjefIconSettings';

class vwPosList {
	locations = [];
	terminals = [];

	async oninit(vnode) {
		this.title = 'POS';

		// load all locations
		const responseLocations = await app.get('/masterdata/locations?sort=name');

		if (responseLocations && responseLocations.data) {
			this.locations = responseLocations.data;
		}

		// load all idents / pos
		const responseTerminals = await app.get('/terminals/administration/terminals?filter[identType]=turbopos');
		// const responseTerminals = await app.get('http://127.0.0.1:3017/administration/terminals?filter[identType]=turbopos');

		if (responseTerminals && responseTerminals.data) {
			this.terminals = responseTerminals.data;
		}
	}

	groupTerminalsByLocation() {
		const terminalsByLocation = new Map();

		this.terminals.forEach((terminal) => {
			const location = this.locations.find((loc) => loc.id === terminal.lid);
			if (location) {
				if (!terminalsByLocation.has(location.id)) {
					terminalsByLocation.set(location.id, {
						location,
						terminals: [],
					});
				}
				terminalsByLocation.get(location.id).terminals.push(terminal);
			}
		});

		return terminalsByLocation;
	}

	renderTableRows() {
		const terminalsByLocation = this.groupTerminalsByLocation();

		return Array.from(terminalsByLocation.values()).map((entry) => {
			const { location, terminals } = entry;
			return [
				m('tr', [
					m('td', { colspan: 4 }, location.name),
					m('td', [
						m(vwPosSettingsModal, {
							level: 'location',
							title: `Location: ${location.name}`,
							locationId: location.id,
							identId: 0,
							// callbackOnSuccess: this.redraw,
						}),
					]),
				]),
				terminals.map((terminal) => {
					return m('tr', [
						m('td', terminal.ident),
						m('td', terminal.status),
						m('td', terminal.lastSeen),
						m('td', [
							m(vwPosSettingsModal, {
								level: 'terminal',
								title: `Terminal: ${terminal.ident}`,
								locationId: location.id,
								identId: terminal.id,
								// callbackOnSuccess: this.redraw,
							}),
						]),
					]);
				}),
			];
		});
	}

	view() {
		return m('.profile', [
			m(
				'.pageHeader',
				m(sjefBreadcrumbs, {
					labelParams: {
						':id': this.titleBreadcrumb,
					},
				}),
				m('h1', this.title)
			),
			m('.columns-2',
				m(Card, m('h3', 'Totals')), 
				m(Card)),
			m(
				'',
				m(
					Card,
					m('.headingIconWrapper',
						m('h3', 'List Terminals by Location'),
						m(vwPosSettingsModal, {
							title: `Default`,
							level: 'default',
							locationId: 0,
							identId: 0,
							// callbackOnSuccess: this.redraw,
						})
					),
					m(Table, [
						m('thead', [m('tr', [m('th', 'Ident'), m('th', 'Status'), m('th', 'Last Seen'), m('th', 'Actions')])]),
						m('tbody', this.renderTableRows()),
					])
				)
			),
		]);
	}
}

export default vwPosList;
