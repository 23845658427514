import { Form, FormGroup, FormLabel, Input } from 'construct-ui';
import m from 'mithril';
import app from '../../../app';
import sjefButton from '../../components/sjefButton';

let email = '';
let errorMsg = '';


const doForgotPassword = async () => {
	try {
		errorMsg = '';

		const result = await app.post('/auth/forgotPassword', { ident: email });

		if (result.success) {
			//todo: add notification with message 'we have sent you an email to recover your password'
			m.route.set('/login');
		} else {
			errorMsg = result.message;
		}
	} catch (err) {
		console.warn('RESET PASSWORD ERROR', err);
	}
};

const forgotPasswordForm = {
	view: () => {
		return m(
			Form,
			{
				align: 'top',
				justify: 'space-between',
			},
			[
				m('h1', 'Wachtwoord vergeten?'),
				m(FormGroup, [
					m(FormLabel, 'Email'),
					m(Input, {
						onchange: (ev) => {
							username = ev.target.value;
						},
						onkeypress: async (ev) => {
							if (ev.keyCode === 13 || ev.which === 13) {
								// Enter key
								ev.preventDefault();
							}
						},
					}),
				]),
				m(sjefButton, {
					label: 'versturen',
					buttonLayout: 'filled large',
					onclick: async () => await doForgotPassword(),
				}),
			]
		);
	},
};

export default forgotPasswordForm;
