import app from "../../app"

export const disableJob = async ( _id ) => {
	const result = await app.post( '/jobs/disable', { _id });
	app.toast(result.success ? 'Job disabled' : result.message);
}

export const enableJob = async ( _id ) => {
	const result = await app.post( '/jobs/enable', { _id });
	app.toast(result.success ? 'Job enabled' : result.message);
}

export const deleteJob = async ( _id ) => {
	const result = await app.post( '/jobs/delete', { _id });
	app.toast(result.success ? 'Job deleted' : result.message);
}

export const unlockJob = async ( _id ) => {
	const result = await app.post( '/jobs/unlock', { _id });
	app.toast(result.success ? 'Job unlocked' : result.message);
}

export const rerunJob = async ( _id ) => {
	const result = await app.post( '/jobs/rerun', { _id });
	app.toast(result.success ? 'Job rerun set' : result.message);
}


export const getClients = async (stack) => {
	const result = await app.post('/jobs/clients', { stack: stack });
	if(result.success) return result.data;
	return [];
}

export const jobProperties = {
	name: {
		type: 'string',
		enum: ['transactions']
	},
	data:{
		type: 'object',
		properties: {
			client_id: {
				type: 'number'
			},
			meta_client: {
				type: 'number'
			},
			stack: {
				type: 'string'
			}
		}
	},
	priority: {
		type: 'number',
		default: 0
	},
	startDate: {
		type: 'string'
	},
	endDate: {
		type: 'string'
	},
	skipImmediate: {
		type: 'boolean'
	},
	skipDays: {
		type: 'number'
	},
	repeatTimezone: {
		type: 'string'
	},
	repeatEvery: {
		type: 'string'
	}
};