import { Checkbox, Table } from 'construct-ui';
import m from 'mithril';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwItemsTab {
	menuId;
	dataItems = [];
	items = [];

	/**
	 * @param {object} attrs - Initial attributes for the component.
	 */
	constructor({ attrs }) {
		this.menuId = attrs.record.id;
		// this.initializeActiveStates();
	}

	/**
	 * Initialization method for Mithril.js
	 */
	async oninit() {
		// get all items
		const resultItems = await app.get('/masterdata/items');
		this.dataItems = resultItems.data;

		await this.loadMenuItems();

		// console.log(mappedResult);
	}

	async loadMenuItems() {
		// get all items
		const resultMenuItems = await app.get(`/masterdata/menus/${this.menuId}/items`);

		this.items = resultMenuItems.data.reduce((acc, item) => {
			acc[item.id] = {
				id: item.id,
				description: item.description,
				price: item.price,
			};
			return acc;
		}, {});
	}

	/**
	 * Toggle the active state of an item.
	 * @param {number} itemId - ID of the item.
	 * @param {string} column - Column name.
	 */
	async setItemState(item) {
		console.log('item', item);

		item = JSON.parse(JSON.stringify(item));
		item.price = 1.55;

		// item.price = 1.55;
		const url = `/masterdata/menus/${this.menuId}/items`;
		const method = this.items[item.id] ? 'delete' : 'post';
		const data = {
			items: [
				{
					itemId: item.id,
					price: item.price,
				},
			],
		};

		// if (method === 'delete') {
		// 	delete this.items[item.id];
		// } else {
		// 	// this.items[item.id] = {item};
		// 	// console.log({
		// 	// 	id: item.id,
		// 	// 	description: item.description,
		// 	// 	price: item.price !== null ? item.price : this.items[item.id].price,
		// 	// });
		// 	// this.items[item.id] = {
		// 	// 	id: item.id,
		// 	// 	description: item.description,
		// 	// 	// price: item.price !== null ? item.price : this.items[item.id].price,
		// 	// };
		// }

		const result = await app[method](url, data);

		this.loadMenuItems();

		app.toast(result.success ? 'success' : 'failed', { intent: result.success ? 'positive' : 'negative' });
	}

	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	view() {
		return m('div', [
			m('label', 'Toggle All Columns'),
			m('div'),
			m(Checkbox, { label: 'Toggle All', checked: this.isToggleAll, onchange: () => this.toggleAllStates() }),
			m(Table, { bordered: false, striped: true }, [
				m('thead', [m('tr', [m('th', ''), m('th', 'Name'), m('th', 'Price')])]),
				m(
					'tbody',
					this.dataItems.map((item) =>
						m('tr', [
							m(
								'td',
								m(Checkbox, {
									checked: this.items[item.id],
									onchange: () => this.setItemState(this.items[item.id] || item),
								})
							),
							m('td', item.name),
							m(
								'td',
								this.items[item.id] && this.items[item.id].price !== item.price
									? m('', [m('', this.items[item.id].price), m('', 'overwritten')])
									: m('', item.price)
							),
						])
					)
				),
			]),
		]);
	}
}

export default vwItemsTab;
