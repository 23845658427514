/* eslint:disable */
import { Button, Input, ListItem, SelectList } from 'construct-ui';
import m from 'mithril';

class sjefSelectCountry {
	countries;
	selectedItem;
	loading = true;
	closeOnSelect = true;
	parentOnSelect;

	fetchCountries = async () => {
		fetch('https://restcountries.com/v3.1/all')
			.then((response) => response.json())
			.then((data) => {
				this.countries = data.map((country) => {
					return {
						name: country.name.common,
						code: country.cca2,
						phone_code: country.idd.root + country.idd.suffixes,
					};
				});
				this.countries = this.countries.sort((a, b) => a.name.localeCompare(b.name));
				console.log(this.countries);
			})
			.catch((error) => console.error('Error:', error));
	};

	oninit = async () => {
		await this.fetchCountries();
		this.loading = false;
	};

	renderItem = (item) =>
		m(ListItem, {
			label: item.name,
			selected: this.selectedItem && this.selectedItem.name === item.name,
		});

	itemPredicate(query, item) {
		return item.name.toLowerCase().includes(query.toLowerCase());
	}

	handleSelect = (item) => {
		this.selectedItem = item;

		if (typeof this.parentOnSelect === 'function') {
			this.parentOnSelect(item);
		}
	};

	view({ attrs: { classes = [], onSelectCountry = '', content, color = 'currentColor', onclick = void 0, ...htmlAttrs } }) {
		var htmlAttrsClass = htmlAttrs.class || void 0;
		delete htmlAttrs.class;

		if (typeof onSelectCountry === 'function') {
			this.parentOnSelect = onSelectCountry;
		}

		return [
			m(SelectList, {
				class: ['countrySelect', htmlAttrsClass],
				closeOnSelect: this.closeOnSelect,
				items: this.countries,
				itemRender: this.renderItem,
				itemPredicate: this.itemPredicate,
				onSelect: this.handleSelect,
				loading: this.loading,
				popoverAttrs: {
					hasArrow: false,
				},
				trigger: m(Button, {
					class: 'countrySelectTrigger ' + (this.loading ? 'loading' : ''),
					align: 'left',
					placeholder: 'test test',
					compact: true,
					// sublabel: 'Country:',
					label: this.selectedItem && this.selectedItem.name,
				}),
			}),
		];
	}
}
export default sjefSelectCountry;
