import sjefIconArrowRight from '@sjefapp/sjeficons/icons/sjefIconArrowRight';
import { Card } from 'construct-ui';
import m from 'mithril';
import app from '../../../app';
import sjefIconBook from '@sjefapp/sjeficons/icons/sjefIconBook';
import sjefModal from '../../../views/components/sjefModal';
import sjefTable from '../../../views/components/sjefTable';
import sjefTh from '../../../views/components/sjefTh';
import { price } from '../../../lib/helpers';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwProfileVisitedEvents {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */

	filteredAttributes = ['total'];
	visitedEvents = false;
	eventOrder = ['Aviato', 'Feyenoord - Celtic', 'Awakenings', 'Defqon.1', 'De Amsterdamse Zomer'];

	sort(attr, direction) {
		if (attr === 'name') {
			this.visitedEventsTable.sort((a, b) => direction === 'ASC' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
		} else if (attr === 'total') {
			this.visitedEventsTable.sort((a, b) => direction === 'ASC' ? parseFloat(a.total) - parseFloat(b.total) : parseFloat(b.total) - parseFloat(a.total));
		}
	}

	oninit(vnode) {
		this.profile = vnode.attrs.profile;
		this.transactions = vnode.attrs.transactions;
		this.filter('administrationId');
	}

	filter(property) {
		const results = [];

		this.transactions.forEach((transaction) => {
			const value = transaction[property];

			if (value !== null) {
				let result = results.find((item) => item[property] === value);

				if (!result) {
					result = {
						[property]: value,
					};
					this.filteredAttributes.forEach((attribute) => {
						result[attribute] = 0;
					});
					results.push(result);
				}

				this.filteredAttributes.forEach((attribute) => {
					result[attribute] += transaction[attribute];
				});
			}
		});

		// add eventNames to grouped administrations
		for (let index = 0; index < results.length; index++) {
			results[index].name = this.eventOrder[index];
		}

		// split array preview list and table list
		if (!this.visitedEvents) {
			this.visitedEvents = results.slice(0, 5);
		}
		this.visitedEventsTable = results;

		m.redraw();
	}

	view() {
		return m('.profileComponent.visitedEvents',
		
			m('.flexTitle',
				m('h3', 'Visited Events'),
				this.visitedEventsTable && this.visitedEventsTable.length > 0 ?
				m(sjefModal, {
					buttonLayout: 'a',
					buttonLabel: m(sjefIconBook),
					modalSize: 'lg',
					footer: false,
					modalTitle: 'All visited events',
					modalContent: m(sjefTable, { 
						class: 'visitedEvents',
						content: [
						m('thead',
							m('tr', [
								m(sjefTh, {
									name: 'Name', 
									onSortUpdate: (evt) => {
										this.sort('name', evt.sort[0] == '-' ? 'ASC' : 'DESC')
									}
								}),
								m(sjefTh, {
									name: 'Total',
									onSortUpdate: (evt) => {
										this.sort('total', evt.sort[0] == '-' ? 'ASC' : 'DESC')
									}
								})
							])
						),
						m('tbody',
							this.visitedEventsTable.map(event => {
								return m('tr', [
									m('td', event.name),
									m('td', price(event.total))
								]);
							})
						)
					]})
				}) : void(0),
			),
			this.visitedEvents && this.visitedEvents.length > 0 ?

			m('ol', 
				this.visitedEvents.map((event, index) => {
					return m('li', {
						class: 'visitedEvent',
						onclick: () => {
							if (event.link) {
                                window.location.href = event.link;
                            } else {
                                console.log('visited event: ' + event.name);
                            }						}
					}, [
						m('span',
						event.name, 
						// event.link ? m(sjefIconArrowRight) : ''
						),
						event.total ? m('span.totalSpent', price(event.total)) : void(0)
					])
				})

			) 
			: m('p', 'No events visited...')	
		);
	}
}

export default vwProfileVisitedEvents;
