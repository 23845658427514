/* eslint:disable */
import { Dialog, Tooltip } from 'construct-ui';
import m from 'mithril';
import sjefButton from './sjefButton';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';

export class sjefModal {
	autofocus = true;
	basic = false;
	closeOnEscapeKey = true;
	hasBackdrop = true;
	class = false;
	inline = false;
	isOpen = false;
	transition = true;
	isSubmitting = false;
	onSubmit;

	/**
	 * Constructor to initialize the modal
	 * @param {Object} vnode - Mithril virtual node
	 */
	constructor(vnode) {
		if (typeof vnode.attrs.setRef === 'function') {
			vnode.attrs.setRef(this);
		}
	}

	close = () => {
		this.isOpen = false;
		this.isSubmitting = false;
	};

	open = () => {
		if (typeof this.onOpen === 'function') {
			this.onOpen();
		}
		this.isOpen = true;
	};

	submit = async () => {
		this.isSubmitting = true;

		// Call the onSubmit callback if it is defined
		if (typeof this.onSubmit === 'function') {
			const result = await this.onSubmit();

			if (!result) {
				this.isSubmitting = false;
				return;
			}
			this.close();
		}
	};

	renderButton(attrs) {
		const button = m(sjefButton, {
			class: (attrs.buttonLabel === '+' ? 'btnPlus' : '') + ' ' + (attrs.buttonLabel === 'x' ? 'btn' : ''),
			label: attrs.buttonLabel,
			iconLeft: attrs.buttonIconLeft || false,
			iconRight: attrs.buttonIconRight || false,
			hideStatuses: attrs.buttonHideStatuses,
			type: 'button',
			buttonLayout: attrs.buttonLayout,
			onclick: () => {
				this.open();
			},
		});

		return attrs.tooltip ?
			m(Tooltip, {
				style: {
					'pointerEvents': 'none',
				},
				position: 'top-end',
				hasArrow: false,
				content: attrs.tooltip,
				trigger: button
			}) :
			button;
	}

	view({
		attrs: {
			modalTitle = false,
			modalSubTitle = false,
			tooltip = false,
			modalSize = 'md',
			modalContent = '',
			footer = true,
			invertIntents = false,
			cancelLabel = 'Cancel',
			buttonConfirm = 'Save',
			buttonClass = '',
			buttonHideStatuses = [],
			buttonIconRight = false,
			buttonIconLeft = false,
			closeOnOutsideClick = true,
			buttonLayout,
			buttonLabel,
			extraButtons = [],
			confirmDisabled = false,
			classes = [],
			onOpen = false,
			onSubmit = false,
		},
	}) {
		classes.push('sjefModal');
		classes.push('modal-' + modalSize);

		if (footer == false) {
			classes.push('noFooter');
		}

		// Store callbacks in the component's state
		this.onOpen = onOpen;
		this.onSubmit = onSubmit;


		return m('.modalButtonWrapper', [
			this.renderButton({ tooltip, buttonLayout, buttonLabel, buttonIconLeft, buttonIconRight, buttonClass, buttonHideStatuses }),
			m(
				'.sjefModal',
				m(Dialog, {
					class: classes.join(' '),
					autofocus: this.autofocus,
					basic: this.basic,
					closeOnEscapeKey: this.closeOnEscapeKey,
					closeOnOutsideClick: closeOnOutsideClick,
					content: modalContent,
					hasBackdrop: this.hasBackdrop,
					isOpen: this.isOpen,
					inline: this.inline,
					onClose: this.close,
					title: modalTitle || modalSubTitle ? m('div', [
						modalTitle ? m('h3', modalTitle) : null,
						modalSubTitle ? m('p', modalSubTitle) : null
					]) : null,
					transitionDuration: this.transition ? 200 : 0,
					footer: m('', [
						m(sjefIconX, {
							class: 'iconRemove',
							onclick: this.close,
						}),
						footer ?
							m(
								'.flexer',
								m(
									'.buttons',
									m(sjefButton, {
										label: cancelLabel,
										buttonLayout: !invertIntents ? 'neutral' : 'filled',
										onclick: () => {
											this.close();
										},
									}),
									extraButtons ?
										extraButtons
										: void (0),
									m(sjefButton, {
										label: buttonConfirm,
										buttonLayout: !invertIntents ? 'filled' : 'neutral',
										disabled: confirmDisabled,
										onclick: async () => {
											// Call the submit method when the "SAVE" button is clicked
											await this.submit();
										},
									})
								)
							) : void (0)
					]),
				})
			),
		]);
	}
}

export default sjefModal;
