import sjefIconArrowUp from '@sjefapp/sjeficons/icons/sjefIconArrowUp';
import sjefIconArrowDown from '@sjefapp/sjeficons/icons/sjefIconArrowDown';
import m from 'mithril';
/* eslint:disable */

class sjefTag {
	view({ attrs: { intent, description = '', icon = true, content, onclick = void 0, ...htmlAttrs } }) {
		if (!intent) {
			// Remove all non-numeric and non-dot characters
			const strippedContent = content.replace(/[^\d.-]/g, "");
			const numericValue = Number(strippedContent);
		
			// Check if the value is a number
			if (!isNaN(numericValue)) {
				intent = numericValue > 0 ? 'positive' : 'negative';
			} else {
				intent = 'positive'; // Default value if not a number
			}
		}

		
		

		return m(
			'.sjefTagWrapper',
			m(
				'.sjefTag',
				{
					class: intent,
				},
				icon ? (intent == 'positive' ? m(sjefIconArrowUp) : m(sjefIconArrowDown)) : void(0),
				content
			),
			description ? m('span.sjefTagDescription', description) : void 0
		);
	}
}
export default sjefTag;
