import m from 'mithril';
import app from '../../app';
import { el } from '@fullcalendar/core/internal-common';
import sjefTable from '../components/sjefTable';
import tableStore from '../../lib/tableStore';
import { _t } from '../../lib/i18n';
import sjefInput from '../components/sjefInput';
import sjefIconFilterOff from '@sjefapp/sjeficons/icons/sjefIconFilterOff';
import sjefIconFilter from '@sjefapp/sjeficons/icons/sjefIconFilter';
import sjefIconArrowLeft from '@sjefapp/sjeficons/icons/sjefIconArrowLeft';
import sjefCheckbox from '../components/sjefCheckbox';
import sjefButton from '../components/sjefButton';

class vwTickets {
	mergedTickets = [];
	displayedTickets = [];
	tickets = [];
	areas = [];
	activeArea;
	events = [];
	activeEvent;
	eventNames;
	areaId;
	loading = false;
	exportData = {
		"fileType": "pdf",
		"status": "available",
		"lockerType": "medium",
	};
	eventId;
	filters = {
		c: '',
		lt: '',
		w: '',
	};

	async oninit() {
		this.loading = true;

		this.getQueryParams();

		if (this.queryParams.eventId) {
			app.store('eventId', this.queryParams.eventId);
			this.eventId = this.queryParams.eventId;
		} else {
			this.eventId = app.restore('eventId');
		}

		if (this.queryParams.areaId) {
			app.store('areaId', this.queryParams.areaId);
			this.areaId = this.queryParams.areaId;
		} else {
			this.areaId = app.restore('areaId');
		}

		if (this.areaId) {
			this.getTickets();
		}

		await this.getAllAreasInEvent();

		await this.getEvents();

		this.loading = false;
	}

	resetFilters() {
		this.filters = {
			c: '',
			lt: '',
			w: '',
		};
	}

	getFilterData() {
		let filterData = {};

		if (this.filters['c']) {
			filterData['color'] = this.filters['c'];
		}

		if (this.filters['lt']) {
			filterData['lockerType'] = this.filters['lt'];
		}

		if (this.filters['w']) {
			filterData['wave'] = this.filters['w'];
		}

		return filterData;
	}

	async getAllAreasInEvent() {
		console.log(this.eventId);
		let areaResult = await app.get(`/lockermanagement/areas?filter[eventId]=${this.eventId}`);

		if (areaResult.success) {
			this.areas = areaResult.data;
			this.areaSlugs = this.areas.map((area) => area.slug);
			this.activeArea = this.areas.find((area) => area.id == this.areaId);

			m.redraw();
		}
	}

	async getEvents() {
		let eventResult = await app.get('/lockermanagement/events');

		if (eventResult.success) {
			this.events = eventResult.data;
			this.eventNames = this.events.map((event) => event.name);

			this.activeEvent = this.events.find((event) => event.id == this.eventId);

			m.redraw();
		}
	}

	getQueryParams() {
		const queryString = m.route.get().split('?')[1];
		const params = new URLSearchParams(queryString);
		this.queryParams = Object.fromEntries(params);
	}

	combineTickets() {
		this.mergedTickets = [...this.tickets['medium'], ...this.tickets['large']];
		this.displayedTickets = this.mergedTickets;
		m.redraw();
	}

	async getTickets() {
		let ticketResult = await app.post('/lockermanagement/tickets/retrieve', {
			areaId: this.areaId,
			status: 'available',
		});

		if (ticketResult.success) {
			this.tickets = ticketResult.data;
			console.log(this.tickets);
		} else {
			this.tickets = {}; // Set to empty array on failure or no data
		}

		if (this.tickets) {
			this.combineTickets();
		}

		m.redraw();
	}

	getUniqueColors() {
		let colors = [''].concat([...new Set(this.mergedTickets.map((ticket) => ticket.c))]);

		return colors;
	}

	getUniqueWaves() {
		let waves = this.mergedTickets
			.map((ticket) => ticket.w)
			.filter((value, index, self) => {
				return self.indexOf(value) === index; // Ensuring uniqueness
			});
		return ['', ...waves]; // Prepend the empty option for clearing filter
	}

	hasActiveFilters() {
		return Object.values(this.filters).some((value) => value !== '' && value !== null && value !== undefined);
	}

	applyFilters() {
		let filteredTickets = this.mergedTickets;

		Object.keys(this.filters).forEach((filterKey) => {
			const filterValue = this.filters[filterKey];
			if (filterValue) {
				filteredTickets = filteredTickets.filter((ticket) => ticket[filterKey] == filterValue);
			}
		});

		this.displayedTickets = filteredTickets;
		m.redraw();
	}

	view() {
		const colors = this.getUniqueColors();
		const waves = this.getUniqueWaves();

		return [
			m(
				'.flexer.center.titleFlexer',
				m(
					'.flex.center',
					m(
						'.iconHover.background',
						{
							onclick: () => {
								let eventId = '';
								if (app.restore('eventId')) {
									eventId = '/' + app.restore('eventId');
								}
								m.route.set('/lockermanagement/events' + eventId);
							},
						},
						m(sjefIconArrowLeft)
					),
					m('h1.title', _t('_.labels.tickets') + ' (' + this.displayedTickets.length + ')')
				),
				m(
					'.actions.flexer',
					m(sjefButton, {
						disabled: false,
						label: _t('_.buttons.export'),
						buttonLayout: 'ghost',
						onclick: async () => {
							let filterData = this.getFilterData();
							let data = {...filterData, ...this.exportData};
							data.areaId = this.activeArea.id;

							let exportResult = await app.post('/lockermanagement/tickets/export', data)

							if (exportResult.success) {
								app.toast('success');
							}
						},
					}),
					m(sjefButton, {
						disabled: tableStore.selectedRecords().length === 0,
						label: _t('_.buttons.activate'),
						buttonLayout: 'ghost',
						onclick: async () => {
							let activateResult = await app.post('/lockermanagement/tickets/activate', {
								areaId: this.activeArea.id,
								ticketIds: tableStore.selectedRecords(),
							});

							if (activateResult.success) {
								app.toast('success');
							}
						},
					}),
					m(sjefButton, {
						disabled: tableStore.selectedRecords().length === 0,
						label: _t('_.buttons.deactivate'),
						buttonLayout: 'ghost',
						onclick: async () => {
							let deactivateResult = await app.post('/lockermanagement/tickets/deactivate', {
								areaId: this.activeArea.id,
								ticketIds: tableStore.selectedRecords(),
							});

							if (deactivateResult.success) {
								app.toast('success');
							}
						},
					})
				)
			),
			!this.loading
				? [
						m(
							'.filterWrapper',

							m(
								'.filterBar.customFilters',
								m(
									'.customFilters',
									m(sjefInput, {
										label: _t('_.fields.color'),
										type: 'select',
										options: colors,
										onChange: (value) => {
											this.filters.c = value;
											this.applyFilters();
										},
										value: this.filters.c,
									}),
									m(sjefInput, {
										label: _t('_.fields.wave'),
										type: 'select',
										options: waves,
										onChange: (value) => {
											this.filters.w = value;
											this.applyFilters();
										},
										value: this.filters.w,
									}),
									m(sjefInput, {
										label: _t('_.fields.type'),
										type: 'select',
										options: ['', 'medium', 'large'],
										onChange: (value) => {
											this.filters.lt = value;
											this.applyFilters();
										},
										value: this.filters.lt,
									}),
									m(
										'.iconHover.background',
										{
											onclick: () => {
												this.resetFilters();
											},
										},
										[this.hasActiveFilters() == true ? m(sjefIconFilterOff) : m(sjefIconFilter)]
									)
								),
								m(
									'.flex',
									// m(
									// 	'.filters',
									// 	m(sjefInput, {
									// 		type: 'select',
									// 		label: _t('_.labels.event'),
									// 		options: this.eventNames,
									// 		value: this.activeEvent?.name,
									// 		onChange: async (value) => {
									// 			// this.resetFilters(); // still possible...?
									// 			this.activeEvent = this.events.find((event) => event.name == value);

									// 			if (this.activeEvent) {
									// 				this.eventId = this.activeEvent.id;
									// 				app.store('eventId', this.eventId);
									// 				await this.getAllAreasInEvent();
									// 				m.redraw();
									// 			}

									// 		},
									// 	})
									// ),
									m(
										'.filters',
										m(sjefInput, {
											type: 'select',
											label: _t('_.labels.area'),
											options: this.areaSlugs,
											value: this.activeArea?.slug,
											onChange: (value) => {
												// this.resetFilters(); // still possible...?
												this.activeArea = this.areas.find((area) => area.slug == value);

												if (this.activeArea) {
													this.areaId = this.activeArea.id;
													app.store('areaId', this.areaId);
													this.getTickets();
													m.redraw();
												}
											},
										})
									)
								)
							)
						),

						this.mergedTickets.length
							? [
									this.displayedTickets.length
										? m(sjefTable, {
												content: [
													m('tr', [
														m(
															'th',
															m(sjefCheckbox, {
																checked: tableStore.selectedRecords().length == this.displayedTickets.length, // || field.defaultValue || false) === true,
																onChange: (value) => {
																	if (value === true) {
																		let ticketIds = this.displayedTickets.map((ticket) => ticket.tid);
																		tableStore.selectedRecords(ticketIds);
																	} else {
																		tableStore.selectedRecords([]);
																	}
																},
															})
														), // Assuming you might want a blank header for a control column like checkboxes or actions
														m('th', _t('_.fields.tickets_tid')), // Transaction ID
														m('th', _t('_.fields.tickets_lid')), // Locker ID
														m('th', _t('_.fields.tickets_lt')), // Locker Type
														m('th', _t('_.fields.tickets_lno')), // Locker Number
														m('th', _t('_.fields.tickets_lpin')), // Locker PIN
														m('th', _t('_.fields.tickets_w')), // Weight (assumption)
														m('th', _t('_.fields.tickets_c')), // Color
													]),
													// Assuming you will have a map or similar to render rows here
													...this.displayedTickets.map((ticket) =>
														m('tr', [
															m(
																'td',
																m(sjefCheckbox, {
																	checked: tableStore.selectedRecords().includes(ticket.tid), // || field.defaultValue || false) === true,
																	onChange: (value) => {
																		let records = tableStore.selectedRecords();

																		if (value === true) {
																			records.push(ticket.tid);
																		} else {
																			records = records.filter((record) => record !== ticket.tid);
																		}

																		tableStore.selectedRecords(records);
																	},
																})
															),
															m('td'), // For controls like checkboxes or edit/delete buttons
															m('td', ticket.tid),
															m('td', ticket.lid),
															m('td', ticket.lt),
															m('td', ticket.lno),
															m('td', ticket.lpin),
															m('td', ticket.w),
															m('td', ticket.c), // Color displayed in its own color
														])
													),
												],
										  })
										: void 0,
							  ]
							: void 0,
				  ]
				: void 0,
		];
	}
}

export default vwTickets;
