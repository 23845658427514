import m, { trust } from 'mithril';
import { Dialog, Table } from 'construct-ui';
import moment from 'moment';

const dt = (dt) => {
	return dt ? moment(dt).format(`dd DD MMM HH:mm:ss`) : '-';
};

const printData = (key, val) => {
	if(typeof val === 'boolean'){
		if(val) return 'true';
		return 'false';
	}else if(typeof val === 'string'){
		return val;
	}else if(typeof val === 'object' && val === null){
		return '-';
	}else if(typeof val === 'object'){
		return Object.keys(val).map( k => {
			return k+': '+printData(k, val[k]);
		}).join("<br>")
	}else if(key === 'dt'){
		return dt(val)
	}else{
		return JSON.stringify(val);
	}
}

let isOpen = false;
let job = {};

export const openJob = (jobData) => {
	isOpen = true;
	job = jobData;
}

export const vwJob = {
	view: () => {
		return m(Dialog, {
			isOpen: isOpen,
			closeOnOutsideClick: true,
			hasCloseButton: true,
			closeOnEscapeKey: true,
			onClose: () => { isOpen = false },

			title: m('h1', job.name || ''),
			content: m(Table, [
				Object.keys( job ).map( key => {
					return m('tr', m('td',key), m('td', trust(printData(key, job[key]))) )
				})
				
			])
		});
	}
};
