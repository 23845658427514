import m from 'mithril';

import sjefIconUsers from '@sjefapp/sjeficons/icons/sjefIconUsers';
import sjefIconBuilding from '@sjefapp/sjeficons/icons/sjefIconBuilding';
import sjefIconDeviceDesktop from '@sjefapp/sjeficons/icons/sjefIconDeviceDesktop';

const logoSrc = new URL('../../assets/img/sjef_logo.png', import.meta.url).href;

const icons = {
	organisation: sjefIconBuilding,
	client: sjefIconUsers,
	administration: sjefIconDeviceDesktop
}
const switchingPage = {
	view: () => {
		const type = m.route.param('type');
		const initialLoad = type ? false : true
		return initialLoad ? m('.switching-screen', m('.animation-wrapper', [
			m('.circle.circle1'),
			m('.circle.circle2'),
			m('img.logo', {
				src: logoSrc,
			})
		])) :
			m('.switching-screen', m('.animation-wrapper', [
				m('.circle.circle1', m(sjefIconUsers, { class: 'circle__icon', size: 132 })),
				m('.circle.circle2', m(icons[type], { class: 'circle__icon', size: 132 })),
				m(icons[type], { class: 'iconLogo', size: 264 })
			]))
	},
};

export default switchingPage;
