const moduleName = 'displaygroups';
const baseRoute = '/masterdata/displaygroups';
const endpoint = '/masterdata/displaygroups';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
					},

					{
						name: 'active',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						required: true,
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'archived',
						type: 'boolean',
						defaultValue: false,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						required: true,
					},
				],
			},
		];
	}
}
