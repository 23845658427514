import m, { redraw } from 'mithril'
// import sjefFilter from '../../../views/components/sjefFilter';
import {
	Button,
	ButtonGroup,
	Checkbox,
	Input,
	ListItem,
	Tooltip,
	Select,
	SelectList,
	Switch,
	Tag,
	Popover,
	Card,
	Drawer,
	Tabs,
	TabItem,
} from 'construct-ui';
import sjefButton from '../../../views/components/sjefButton';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefInput from '../../../views/components/sjefInput';
import sjefIconAdjustmentsHorizontal from '@sjefapp/sjeficons/icons/sjefIconAdjustmentsHorizontal';
import { dateInput } from '../../../modules/modules/dateInput';
import sjefIconArrowsDiff from '@sjefapp/sjeficons/icons/sjefIconArrowsDiff';
import sjefIconArrowDown from '@sjefapp/sjeficons/icons/sjefIconArrowDown';
import sjefIconArrowUp from '@sjefapp/sjeficons/icons/sjefIconArrowUp';
// import tableStore from '../../lib/tableStore';
import sjefIconSearch from '@sjefapp/sjeficons/icons/sjefIconSearch';
import { _t } from '../../../lib/i18n';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
/* eslint:disable */

import sjefIconAdjustmentsHorizontal from '@sjefapp/sjeficons/icons/sjefIconAdjustmentsHorizontal';
import sjefRangeFilter from '../../../views/components/sjefRangeFilter';
import sjefIconChevronLeft from '@sjefapp/sjeficons/icons/sjefIconChevronLeft';
import { withHooks, useState, useEffect } from 'mithril-hooks';


const sjefFilterContainer = ({ fields, activeFilters, setFilterFields }) => {
	return m(sjefFilter, {
		filters: fields.filter((field) => field.filter !== false),
		defaultFilter: fields.find((field) => field.defaultFilter),
		activeFilters: activeFilters,
		onFilterUpdate: (filterReturnData) => {
			setFilterFields(filterReturnData);
		},
	})

}

const sjefFilter = withHooks(({ filters, defaultFilter, activeFilters, onFilterUpdate }) => {
	const [activeTab, setActiveTab] = useState('defaultTabs');
	const [selectedFilters, setSelectedFilters] = useState(activeFilters);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [showAdvancedFilters, setShowAdvancedFilters] = useState(true);
	const [selectFieldOptions, setSelectFieldOptions] = useState({});

	const tabs = ['defaultTabs', 'savedTabs'];

	const applyFilters = () => {
		onFilterUpdate(selectedFilters);
		m.redraw();
	}

	const updateFilter = (name, value) => {
		const newFilters = selectedFilters;
		newFilters[name] = value;
		setSelectedFilters(newFilters);
		if (value === '') {
			removeFilter(name);
		}
	}

	useEffect(() => {
		filters.forEach(async (field) => {
			if (field.popoverFilterType === 'select' && field.endpoint) {
				const options = await fetchSelectOptions(field);
				setSelectFieldOptions((prevOptions) => ({ ...prevOptions, [field.name]: options }));
			}
		});
	}, [])

	const fetchSelectOptions = async (field) => {
		const response = await app.get(field.endpoint);
		return response.data[field.optionsKey];
	}

	const isFilterActive = () => {
		return Object.keys(activeFilters).some(key => activeFilters[key] != '')
	}

	const findTarget = (field) => {
		if (activeFilters[field.name]) {
			// document.querySelector
			const item = document.querySelector(`.cui-tag[data-filter="${field.name}"]`);

			if (item) {
				return item;
			}
		}
	}

	const togglePopover = () => {
		setIsPopoverOpen(!isPopoverOpen)
	}

	const clearAllFilters = () => {
		// setSelectedFilters(JSON.parse(JSON.stringify(selectedFilters)))

		let setFields = document.querySelectorAll('.hasFilterValue .advancedFilterField');

		setFields.forEach(field => {
			switch (field.type) {
				case 'text':
				case 'date':
				case 'password':
				case 'datetime-local':
				case 'email':
				case 'number':
				case 'search':
				case 'tel':
				case 'url':
					field.value = ''; // Set text and similar inputs to empty
					break;
				case 'select-one':
				case 'select-multiple':
					field.selectedIndex = 0; // Reset select fields to the first option
					break;
				// case 'checkbox':
				// case 'radio':
				// 	field.checked = field.defaultChecked; // Reset checkboxes and radios to their default state
				// 	break;
				default:
					console.log(`Unhandled field type: ${field.type}`); // Log any unhandled types
					break;
			}
		});


		applyFilters();
	}

	const getTabLabel = (tab) => {
		if (tab == 'defaultTabs') {
			return _t('_.filterTabs.defaultTabs')
		} else if (tab == 'savedTabs') {
			return _t('_.filterTabs.savedTabs')
		}
	}

	const removeFilter = (key) => {
		// setActiveFilters(activeFilters, ...{ key: '' });
		const filterKey = `filter[${key}]`;
		// Remove from local active filters state
		if (activeFilters[filterKey]) {
			delete activeFilters[filterKey];
			// Optionally, also clear from any global or shared state
			// delete tableStore.filterData()[filterKey];
		}

		// Reset the associated input or select field
		// This assumes you can directly manipulate the value. Depending on your setup, this may need to be handled by mithril-managed properties or states.
		const elem = document.querySelector(`[data-filter="${key}"]`);
		if (elem) {
			if (elem.tagName === 'SELECT') {
				elem.value = ''; // Reset select to the default/empty option
			} else {
				elem.value = ''; // Reset input field
			}
		}

		applyFilters();
	}

	return m(
		'.filters',
		m(
			'.filterBar',
			{
				class: isPopoverOpen ? 'isOpen' : '',
			},
			isPopoverOpen &&
			m('.popoverCloser', {
				onclick: () => togglePopover(),
			}),
			m('.inputFilterWrapper',
				m(sjefInput, {
					type: 'text',
					readonly: !defaultFilter,
					placeholder: defaultFilter ? _t('_.search.search') : 'Open filters',
					icon: m(''),
					value: defaultFilter ? activeFilters[defaultFilter.name] : '',
					onChange: (value) => {
						if (defaultFilter) {
							updateFilter(defaultFilter?.name, value);
						}
					},
					onclick: () => {
						if (!defaultFilter) {
							togglePopover(); // Opens the popover if no default filter is selected
						}
					},
					onkeydown: (e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							applyFilters();
						}
					},
				}),
				m('.iconHover.filterToggle', { onclick: () => togglePopover() }, m(sjefIconAdjustmentsHorizontal)), // remove when switching to popover
				isFilterActive() ?
					m('.iconHover.clearFilters', { onclick: () => clearAllFilters() }, m(sjefIconX)) // remove when switching to popover
					: void (0),
			),
			m(Drawer, {
				isOpen: isPopoverOpen, // Control the display of the popover based on state
				hasArrow: false,
				closeOnEscapeKey: true,

				// hasBackdrop: true, // when switching to popover
				// backdropClass: 'light',  // when switching to popover
				// position: 'bottom-start',  // when switching to popover
				position: 'left',

				onClose: () => togglePopover(), // Toggle popover state on close
				class: 'filter',
				trigger: m('.iconHover.filterToggle', { onclick: () => togglePopover() }, m(sjefIconAdjustmentsHorizontal)),
				content: [
					m(
						'form',
						{
							onsubmit: (e) => {
								e.preventDefault();
								applyFilters();
							},
						},
						[
							m(
								'.flexer.center',
								m('h2', _t('_.labels.filters')),

								m(
									'.iconHover',
									{
										onclick: () => {
											togglePopover();
										},
									},
									m(sjefIconChevronLeft)
								)
							),
							m(
								'.subMenuTabs.list',
								m(Tabs, {
									class: 'list-' + tabs.length + ' active-' + (tabs.indexOf(activeTab) + 1),
								}, [
									tabs.map((tab) => {
										return m(TabItem, {
											key: tab,
											label: getTabLabel(tab),
											active: tab == activeTab,
											// onclick: () => {
											// 	activeTab = tab
											// },
										});
									}),
								])
							),
							m(
								'.filter-inner',
								activeTab == 'defaultTabs' ? [
									m(
										'.filter-fields',
										filters
											.filter((f) => f.filter !== false && f.listShow !== false)
											.map((filter) => {
												return m(
													'.filter-field',
													{
														class: activeFilters[filter.name] ? 'active' : '',
													},
													[
														m('label', { for: `filter-${filter.name}` }, _t(`_.fields.${filter.name}`)),
														activeFilters[filter.name] ?
															m(sjefButton, {
																label: 'remove',
																class: 'filter-remove',
																clickState: false,
																buttonLayout: 'remove',
																onclick: () => {
																	activeFilters[filter.name] = '';
																	removeFilter(filter.name);
																}
															}) : void (0),
														filter.popoverFilterType === 'select'
															? m(sjefInput, {
																id: `filter-${filter.name}`,
																type: 'select',
																emptyOption: true,
																options: filter.options || selectFieldOptions[filter.name],
																onChange: (value) => {
																	updateFilter(filter.name, value);
																},
																value: activeFilters[filter.name],
															})
															: filter.popoverFilterType === 'range'
																? [
																	m(sjefRangeFilter, {
																		type: filter.type,
																		onChange: (filterString) => {
																			updateFilter(filter.name, filterString);
																		},
																	}),
																]
																: m(sjefInput, {
																	id: `filter-${filter.name}`,
																	type: filter.type || 'text',
																	onChange: (value) => {
																		updateFilter(filter.name, value);
																	},
																	value: activeFilters[filter.name],
																	placeholder: 'Enter ' + filter.name,
																}),
													]
												);
											})
									),
									m(
										'.flex.spread.filterButtons',
										m(sjefButton, {
											type: 'submit',
											buttonLayout: 'filled',
											label: _t('_.buttons.applyFilters'),
											onclick: () => {
												applyFilters();
												togglePopover();
											},
										}),
										m(sjefButton, {
											buttonLayout: 'inline',
											label: _t('_.buttons.clearFilters'),
											onclick: () => clearAllFilters(),
										})
									)
								] : void (0)
							),
						]
					),
				],
			}),
			showAdvancedFilters
				? filters.some((field) => field.advancedFilterType) &&
				m(
					'.advancedFilters',
					{
						class: filters.filter((field) => field.advancedFilterType).length >= 4 ? 'wrappedAdvanced' : '',
					},
					filters
						.filter((field) => field.advancedFilterType)
						.map((field) => {
							return m(
								'',
								m(
									'.advancedFilter',
									{
										onmouseover: () => {
											let target = findTarget(field);

											if (target) {
												target.classList.add('js-filterHover');
											}
										},
										onmouseleave: () => {
											let target = findTarget(field);

											if (target) {
												target.classList.remove('js-filterHover');
											}
										},
										class: [
											// tableStore.filterData()[`filter[${field.name}]`] ? 'hasFilterValue' : '',
											field.advancedFilterType,
											field.icon ? 'hasIcon' : '',
										].join(' '),
									},
									m(
										'.filterTitle truncate-small',
										field.name,
										field.advancedFilterType === 'date' && field.advancedFilterTypeDateRange
											? [
												m(
													'.dateFilterActions',
													m(
														'div',
														{
															'data-filter': `${field.name}RangeToggle`,
															class: dateTypeToggle[field.name] === 'after' ? 'active' : '',
															onclick: () => {
																// Toggle active state for Arrow Up
																dateTypeToggle[field.name] =
																	dateTypeToggle[field.name] === 'after' ? false : 'after';

															},
														},
														m(sjefIconArrowUp)
													),

													m(
														'div',
														{
															'data-filter': `${field.name}RangeToggle`,
															class: dateTypeToggle[field.name] === 'before' ? 'active' : '',
															onclick: () => {
																// Toggle active state for Arrow
																dateTypeToggle[field.name] =
																	dateTypeToggle[field.name] === 'before' ? false : 'before';

															},
														},
														m(sjefIconArrowDown)
													),

													m(
														'div',
														{
															'data-filter': `${field.name}RangeToggle`,
															class: dateTypeToggle[field.name] === 'range' ? 'active' : '',
															onclick: () => {
																// Toggle active state for sjefIconArrowsMoveHorizontal
																dateTypeToggle[field.name] =
																	dateTypeToggle[field.name] === 'range' ? false : 'range';

															},
														},
														m(sjefIconArrowsDiff)
													)
												),
											]
											: void 0
									),
									field.advancedFilterType == 'select'
										? [
											m(
												'select',
												{
													class: 'truncate-small advancedFilterField',
													'data-filter': field.name,
													onchange: (evt) => {
														console.log(field.name, evt.target.value)
														if (evt.target.value) {
															updateFilter(field.name, evt.target.value);
														} else {

															removeFilter(field.name);
														}

													},
												},
												m('option', { value: '' }, ''),
												(field.advancedFilterOptions || field.options || selectFieldOptions[field])?.map((option) =>
													m('option', { value: option }, option)
												)
											),
											field.icon ? m(field.icon) : void 0,
										]
										: field.advancedFilterType == 'boolean'
											? m(Checkbox, {
												'data-filter': field.name,
												// checked: tableStore.filterData()['filter[' + field.name + ']'] ? true : false,
												onchange: (evt) => {
													if (evt.target.checked) {
														updateFilter(field.name, evt.target.checked);
													} else {
														removeFilter(field.name);
													}

												},
											})
											: field.advancedFilterType === 'date'
												? [
													dateTypeToggle[field.name] === 'range'
														? [
															m(
																'.rangeWrapper',
																m(Input, {
																	class: 'advancedFilterField',
																	'data-filter': `${field.name}From`,
																	type: 'datetime-local',
																	value: dateRangeValues[field.name]?.from,
																	onchange: (evt) => {
																		// Ensure the object exists before setting a property
																		if (!dateRangeValues[field.name]) {
																			dateRangeValues[field.name] = {};
																		}
																		dateRangeValues[field.name].from = evt.target.value;

																		if (
																			dateRangeValues[field.name].from &&
																			dateRangeValues[field.name].to
																		) {
																			updateFilter(
																				field.name,
																				`between,${dateRangeValues[field.name].from},${dateRangeValues[field.name].to
																				}`
																			);
																		}
																	},
																}),
																m(Input, {
																	'data-filter': `${field.name}To`,
																	type: 'datetime-local',
																	class: 'advancedFilterField',
																	value: dateRangeValues[field.name]?.to,
																	min: dateRangeValues[field.name]?.from,
																	onchange: (evt) => {
																		if (
																			new Date(evt.target.value) <
																			new Date(dateRangeValues[field.name].from)
																		) {
																			alert(
																				'The "enddate" date must not be earlier than the "startdate" date.'
																			);
																			evt.target.value = dateRangeValues[field.name].from;
																		} else {
																			if (!dateRangeValues[field.name]) {
																				dateRangeValues[field.name] = {};
																			}
																			dateRangeValues[field.name].to = evt.target.value;

																			if (
																				dateRangeValues[field.name].from &&
																				dateRangeValues[field.name].to
																			) {
																				addFilter({
																					name: field.name,
																					value: `between,${dateRangeValues[field.name].from
																						},${dateRangeValues[field.name].to}`,
																				});
																			}
																		}
																	},
																})
															),
														]
														: m(Input, {
															'data-filter': field.name,
															class: 'advancedFilterField',
															type: !dateTypeToggle[field.name] ? 'date' : 'datetime-local',
															// value: tableStore.filterData()['filter[' + field.name + ']'],
															onchange: (evt) => {
																updateFilter(
																	field.name,
																	`${dateTypeToggle[field.name]},${evt.target.value}`
																);
															},
														}),
												]
												: void 0
								)
							);
						})
				)
				: void 0
		),
		Object.entries(activeFilters).some(([key, value]) => value) &&
		m(
			'.activeFilters',
			Object.keys(activeFilters)
				.filter((key) => activeFilters[key])
				.map((key) =>
					m(Tooltip, {
						content: key,
						trigger: m(Tag, {
							label: `${key}: ${activeFilters[key]}`,
							onRemove: () => {
								updateFilter(key, '');
								applyFilters();
							},
						}),
					})
				)
		)
	)
})


export default sjefFilterContainer
