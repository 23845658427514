import app from '../../../app';

const endPoints = {
	roles: {
		url: '/auth/acl/roles',
	},
	idents: {
		url: '/auth/ident/list',
	},
	ident: {
		url: '/auth/ident/get',
	},
	policies: {
		url: '/auth/acl/permissions',
	},
	clients: {
		url: '/auth/clients/',
	},
};

const Super = {
	menu: false,
	secure: false, // { obj: "ui", act: "super"},
	data: {
		roles: [],
		users: [],
		policies: [],
		clients: [],
	},

	get: async (key, data = {}, reload = false) => {
		if (Object.keys(data) || reload || !Super.data[key] || (!Super.data[key].length && key in endPoints)) {
			const r = await app.get(endPoints[key].url, data);
			if (r.error) {
				// SHOW ERROR TOAST
			} else {
				Super.data[key] = r[key] || r;
			}
		}
		return Super.data[key];
	},
};

export default Super;
