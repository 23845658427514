[
	{
		"id": "home",
		"label": "Home",
		"route": "",
		"hidden": true,
		"module": "vwHome"
	},
	{
		"id": "clients",
		"label": "Clients",
		"parent": "masterdata",
		"route": "clients",
		"module": "vwList",
		"config": "clients",
		"children": [
			{
				"id": "client",
				"label": "client",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "clients"
			}
		]
	},
	{
		"id": "projects",
		"label": "Projects",
		"route": "projects",
		"module": "vwList",
		"config": "projects",
		"children": [
			{
				"id": "project",
				"label": "Project",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "projects"
			}
		]
	},
	{
		"id": "assets",
		"label": "Assets",
		"route": "assets",
		"module": "vwList",
		"config": "assets",
		"children": [
			{
				"id": "asset",
				"label": "Asset",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "assets"
			}
		]
	},
	{
		"id": "reports",
		"label": "Reports",
		"route": "reports",
		"module": false,
		"children": [
			{
				"id": "reportReconciliation",
				"label": "Reconciliation",
				"route": "reconciliation",
				"module": "vwList",
				"config": "reportReconciliation"
			}
		]
	}
]
