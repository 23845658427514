import m from 'mithril';
import sjefInput from './sjefInput';
import sjefPopover from './sjefPopover';
import { Popover } from 'construct-ui';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconMoneybag from '@sjefapp/sjeficons/icons/sjefIconMoneybag';
import { _t } from '../../lib/i18n';

class sjefSelect {
	selectOpen = false;
	options = [];
	activeOption;
	prefix;
	iconLeft;
	type;
	useValueAsLabel = false;
	toggleSelectOpen() {
		this.selectOpen = !this.selectOpen;
	}

	oninit(vnode) {
		this.options = vnode.attrs.options;
		this.prefix = vnode.attrs.prefix || 'adyen';
		if (vnode.attrs.activeOption) {
			this.activeOption = vnode.attrs.activeOption;
		} else {
			this.activeOption = this.options[0];
		}
		this.iconLeft = vnode.attrs.iconLeft || '';
		this.type = vnode.attrs.type === 'wide' ? { width: '100%' } : {};
		this.useValueAsLabel = vnode.attrs.useValueAsLabel || false;
	}

	view(vnode) {
		const value = this.useValueAsLabel ? this.activeOption : _t(`_.fields.${this.prefix}.${this.activeOption}`);

		const optionLabel = (option) => {
			if (this.useValueAsLabel) {
				return option;
			}
			return _t(`_.fields.${this.prefix}.${option}`);
		};

		return [
			m(
				'.sjefSelectWrapper',
				{
					class: this.selectOpen ? 'open' : '',
					style: this.type,
				},
				m(Popover, {
					class: 'sjefSelectPopover',
					closeOnContentClick: true,
					hasArrow: false,
					trigger: m(sjefInput, {
						readonly: true,
						class: 'sjefSelect',
						value,
						onclick: () => {
							this.toggleSelectOpen();
						},
					}),
					onClose() {
						this.selectOpen = false;
						m.redraw();
					},
					content: [
						this.options.map((option) => {
							return [
								m(
									'.option',
									{
										onclick: (e) => {
											this.activeOption = option;
											vnode.attrs.onChange(option);
										},
									},
									m('span.truncate', optionLabel(option))
								),
							];
						}),
					],
				}),
				m(this.iconLeft, { class: 'iconLeft' }),
				m(sjefIconChevronDown)
			),
		];
	}
}

export default sjefSelect;
