import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import { Card, Collapse } from 'construct-ui';
import m from 'mithril';
/* eslint:disable */

class dropDownCard {
	isOpen = false;

	oninit (vnode) {
		if (vnode.attrs.isOpen == true) {
			this.isOpen = true;
		}
	}

	view({ attrs: { classes = [], content = '', title = '', onclick = void 0, ...htmlAttrs } }) {
		
		var htmlAttrsClass = htmlAttrs.class || '';
		delete htmlAttrs.class;

		

		return m(
			'.dropDownCard',
			{
				onclick,
				class: [
					classes.length > 0 ? classes.join(' ') : '',
					this.isOpen ? 'open ' : null,
					...htmlAttrsClass,
				].filter(Boolean).join('') 
			},
			m('h4', {
				onclick: () => this.isOpen = !this.isOpen
			}, [
				m('span', title),
				m(sjefIconChevronDown)
			]),
			m(
				Collapse,
				{
					isOpen: this.isOpen,
					duration: 100,
				},
				m(Card, {}, content)
			)
		);
	}
}
export default dropDownCard;
