import m from 'mithril';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwExampleTab {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	view() {
		return m('', [m('', 'Here content for the tab')]);
	}
}

export default vwExampleTab;
