import m from 'mithril';
import { Card, Collapse, Form, FormGroup, TabItem, Tabs, Tooltip } from 'construct-ui';
import { _t } from '../../lib/i18n';

import sjefIconArrowLeft from '@sjefapp/sjeficons/icons/sjefIconArrowLeft';
import sjefButton from '../../views/components/sjefButton';
import sjefModal from '../../views/components/sjefModal';

import vwBaseRecordForm from '../../classes/vwBaseRecordForm';
import modalFormUnsaved from '../../views/modals/modalFormUnsaved';
import actionsMenu from '../../views/components/actionsMenu';
import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import app from '../../app';
// import sjefSelectAsync from '../../views/components/sjefSelectAsync';
// import sjefSelectCountry from '../../views/components/sjefSelectCountry';
// import sjefInputTranslations from '../../views/components/sjefInputTranslations';

/**
 * @class vwForm
 * @extends {vwBaseRecordForm}
 */

class vwForm extends vwBaseRecordForm {
	/**
	 * @constructor
	 * @param {Object} vnode - Mithril vnode
	 */
	constructor(vnode) {
		super();

		this.config = new vnode.attrs.config(this);
		this.showAdvanced = false;
		this.route = this.config.route;
		this.endpoint = this.config.endpoint;
		this.fields = this.config.fields;
		this.tabs = this.config.tabs;
		this.formButtons = this.config.formButtons;
		this.formDirtyFields = this.formDirtyFields;
		this.fieldStates = {}; // State for managing individual field properties
		this.activeTab = 0;

		// this.form = vnode.ts
		this.initializeReferences();

		this.formState = vnode.attrs.id == 'new' ? 'create' : 'edit';

		this.schema = configToAjvSchema(this.config.schema, this.formState);
		// this.initializeFieldStates();
	}

	initializeReferences() {
		// create reference fields array if existing
		this.referenceFields = this.config.schema.reduce((acc, group) => {
			group.fields.forEach((field) => {
				if (field.reference) {
					const referencedField = this.config.schema.flatMap((g) => g.fields).find((f) => f.name === field.reference);
					if (referencedField && referencedField.type === field.type) {
						console.log(referencedField.type, field.type, referencedField.type === field.type);
						acc[field.reference] = field.name;
					} else {
						throw new Error(`Type mismatch for field ${field.name} and its reference ${field.reference}`);
					}
				}
			});
			return acc;
		}, {});
	}

	// initializeFieldStates() {
	// 	this.config.schema.forEach((group) => {
	// 		group.fields.forEach((field) => {
	// 			this.fieldStates[field.name] = {
	// 				value: this.form[field.name],
	// 			};
	// 		});
	// 	})
	// }

	// onupdate (vnode) {
	// 	this.oldForm = vnode.state.form;
	// }
	initializeTab() {
		const defaultActive = this.tabs.findIndex((tab) => tab.default === true);
		const tabActive = app.restore(`${this.modulename}/activeFormTab`);

		// this.tabStored = app.restore('activeFormTab') || defaultActive || 0;
		this.tabStored = this.formState === 'create' ? 0 : tabActive || defaultActive || 0;

		this.setTabActive(this.tabStored);
	}
	async oninit(vnode) {
		await super.oninit(vnode);
		this.initializeTab()
	}

	/**
		 * Mithril view function
		 * @return {Object}
		 */
	view(vnode) {
		return m(
			`.${this.config.moduleName}.details.vw-${this.config.moduleName}.vwDetails.layout-${vnode.attrs.id == 'new' ? 'small' : this.config.layoutType
			}`,
			{
				class: [this.formLoading ? 'loading' : '', this.formDirtyFields.length ? 'formDirty' : void 0, vnode.attrs.id == 'new' ? 'create' : 'edit'].join(' '),
			},
			[
				this.renderHeader(vnode),
				m(
					Form,
					{
						onsubmit: () => this.onSubmit(false),
					},
					[this.renderTabContent(vnode)]
				),
				m(modalFormUnsaved, this.getModalUnsavedFormProperties()),
			]
		);
	}

	/**
		 * Render header section
		 * @return {Object}
		 */
	renderHeader(vnode) {
		return [
			m(
				'.flexer.end',
				m(
					'.flexer.center',
					m(
						'.iconHover.background',
						{
							onclick: () => m.route.set(this.config.route),
						},
						m(sjefIconArrowLeft)
					),
					m('h1', this.title)
					// m(sjefBreadcrumbs)
					// [vnode.attrs.id && vnode.attrs.id !== 'new'] ?
					// m('h4.title',
					// 	[
					// 		m('span', _t('_.fields.edit')),
					// 		m('span.lowercase.space-left', _t(`_.singular.${vnode.attrs.key}`))
					// 	]
					// )
					// :
					// m('h4.title',
					// 	[
					// 		m('span', _t('_.fields.new')),
					// 		m('span.lowercase.space-left', _t(`_.singular.${vnode.attrs.key}`))
					// 	]
					// )
				),
				// this.viewMode == 'edit' ? m('.flexer.actions', this.renderButtons(vnode)) : void 0
				m('.flexer.actions', this.renderButtons(vnode))
			),
		];
	}

	/**
		 * Render action buttons
		 * @return {Array}
		 */
	renderButtons(vnode) {
		return [
			vnode.attrs.id != 'new'
				? [
					this.renderDropdownButtons(vnode),
					this.renderExtraButtons(vnode),

					m(
						'.buttonFloat',
						vnode.attrs.id == 'new'
							? [this.renderSaveButton()]
							: [
								m('h2.edit', 'Form has modifications'),
								m('.flexer.actions', m('', this.renderDiscardButton('icon')), this.renderSaveButton('icon')),
							]
					),
					this.config.actions.canDelete() ? this.renderDeleteButton() : void 0,
					this.renderSaveAndCloseButton(),
				]
				: void 0,
		];
	}

	/**
		 * Render save button
		 * @return {Object}
		 */
	renderDiscardButton(type = 'default') {
		return m(sjefButton, {
			label: type == 'icon' ? m(sjefIconX) : _t('_.buttons.discard'),
			buttonLayout: type == 'icon' ? 'iconHover' : 'danger',
			onclick: async () => {
				this.refs['modalFormUnsaved'].close();
				await this.setForm();
				this.formDirtyFields = [];
				m.redraw();
			},
		});
	}

	/**
		 * Render save button
		 * @return {Object}
		 */
	renderSaveButton(type = 'default') {
		return m(sjefButton, {
			label: type == 'icon' ? m(sjefIconCheck) : _t('_.buttons.save'),
			buttonLayout: type == 'icon' ? 'iconHover' : 'filled',
			onclick: async () => {
				await this.onSubmit(false);
				m.redraw();
			},
		});
	}

	/**
		 * Render delete button
		 * @return {Object}
		 */
	// renderDeleteButton() {
	// 	return m(sjefModal, {
	// 		onSubmit: async () => {
	// 			await this.deleteRecord();
	// 			m.redraw();
	// 		},
	// 		buttonLabel: 'Delete',
	// 		buttonLayout: 'danger',
	// 		buttonClass: 'negative',
	// 		invertIntents: true,
	// 		buttonConfirm: _t('_.modals.confirmDeleteButton'),
	// 		buttonCancel: _t('_.modals.cancelDeleteButton'),
	// 		modalTitle: _t('_.modals.confirmDeleteTitle'),
	// 		modalContent: _t('_.modals.confirmDeleteContent'),
	// 	});
	// }

	// buttonLayout: 'empty',
	// 										label: m(sjefIconArchive),
	// 										disabled: !tableStore.selectedRecords().length,
	// 										onclick: () => this.archiveRecords(),

	renderDeleteButton() {
		return [
			m(Tooltip, {
				content: 'Delete',
				position: 'top-end',
				hasArrow: false,
				trigger: [
					// m(
					// 	'.iconHover.background',
					m(sjefModal, {
						onSubmit: async () => {
							await this.deleteRecord();
							m.redraw();
						},
						// buttonLabel: m(sjefIconTrash),
						// buttonLayout: 'empty',
						buttonLabel: _t('_.buttons.delete'),
						buttonLayout: 'neutral',
						buttonConfirm: _t('_.modals.confirmDeleteButton'),
						buttonCancel: _t('_.modals.cancelDeleteButton'),
						modalTitle: _t('_.modals.confirmDeleteTitle'),
						modalContent: _t('_.modals.confirmDeleteContent'),
					}),
					// ),
				],
			}),
		];
	}

	/**
		 * Render save and close button
		 * @return {Object}
		 */
	renderSaveAndCloseButton() {
		// return m(sjefButton, {
		// 	label: _t('_.buttons.saveAndClose'),
		// 	buttonLayout: 'filled',
		// 	onclick: async () => {
		// 		await this.onSubmit();
		// 		m.redraw();
		// 	},
		// });

		return [
			m(Tooltip, {
				content: 'Save',
				position: 'top-end',
				hasArrow: false,
				trigger: [
					//  m(
					// '.iconHover.background',
					m(sjefButton, {
						onclick: async () => {
							await this.onSubmit();
							m.redraw();
						},
						// label: m(sjefIconCheck),
						// buttonLayout: 'empty',
						label: 'Save and close',
						buttonLayout: 'filled',
					}),
					// ),
				],
			}),
		];
	}

	/**
		 * Render buttons
		 * @return {Object}
		 */
	renderExtraButtons(vnode) {
		if (this.config.formButtons) {
			return this.config.formButtons.map((button) => {
				const buttonAttrs = typeof button.componentAttrs === 'function' ? button.componentAttrs(vnode.attrs.id) : button.componentAttrs;

				if (
					!(
						this.record.status &&
						((buttonAttrs.hideStatuses &&
							buttonAttrs.hideStatuses.length &&
							buttonAttrs.hideStatuses.includes(this.record.status.toLowerCase())) ||
							(buttonAttrs.buttonHideStatuses &&
								buttonAttrs.buttonHideStatuses.length &&
								buttonAttrs.buttonHideStatuses.includes(this.record.status.toLowerCase())))
					)
				) {
					return m(button.component, {
						...buttonAttrs,
					});
				}
			});
		}
	}

	/**
		 * Render dropdown buttons
		 * @return {Object}
		 */
	renderDropdownButtons(vnode) {
		// Check if there are any dropdown buttons configured
		if (this.config.formDropDownButtons && this.config.formDropDownButtons.length > 0) {
			// Map over each button configuration to create button components
			const buttonComponents = this.config.formDropDownButtons.map((buttonConfig) => {
				const { component, componentAttrs } = buttonConfig;

				// Check if componentAttrs is a function and call it with the current vnode's attributes
				// This allows the component attributes to be dynamically determined based on the vnode's state
				const attrs = typeof componentAttrs === 'function' ? componentAttrs(vnode.attrs) : componentAttrs;

				return m(component, attrs);
			});

			// Use actionsMenu to render the buttons in a dropdown menu
			return m(actionsMenu, {
				content: buttonComponents,
				// Add other necessary attributes or configurations if needed
			});
		}
		return null; // Return null if no buttons are configured
	}

	/**
		 * Render tab content
		 * @return {Object}
		 */
	renderTabContent(vnode) {
		return m('.formFlexer', [
			this.renderTabs(vnode),
			m(this.tabActive.inCard === false ? '.activeTabWrapper' : Card, this.renderActiveTabContent(vnode)),
		]);
	}

	/**
		 * Render tabs
		 * @return {Object}
		 */
	renderTabs(vnode) {
		if (this.tabs.length > 1) {
			return m(
				'.subMenuTabs',
				m(Tabs, [
					this.tabs.map((tab, idx) =>
						m(TabItem, {
							label: [tab.icon ? m(tab.icon) : '', [_t(`modules.${this.config.moduleName}.tabs.${tab.slug}`) || tab.slug]],
							active: this.isTabActive(tab),
							hidden: this.isTabHidden(tab),
							onclick: () => this.setTabActive(idx),
						})
					),
				])
			);
		}
	}

	/**
		 * Render active tab content
		 * @return {Object}
		 */
	renderActiveTabContent(vnode) {
		return m(
			'.activeTab',
			{
				key: this.tabActive.slug,
				class: [
					this.tabActive.form ? 'form' :
						this.tabActive.size ? this.tabActive.size : '',
				].join(' '),
			},
			[
				this.tabActive.default && [this.renderFormFields(vnode)],
				this.tabActive.component &&
				(!this.tabActive.inCard
					? [
						m(this.tabActive.component, {
							// Render without Card when this.tabActive.inCard is false
							...this.tabActive.componentAttrs,
							record: this.record,
						}),
					]
					: m(Card, [
						// Wrap in m(Card) by default
						m(this.tabActive.component, {
							...this.tabActive.componentAttrs,
							record: this.record,
						}),
					])),
				!this.tabActive.default && !this.tabActive.component && 'No component installed for tab',
			]
		);
	}

	/**
		 * Render form fields
		 * @return {Object}
		 */
	renderFormFields(vnode) {
		const inCard = this.tabs.filter((tab) => tab.default === true)[0]?.inCard;

		// Initialize openStates if not already initialized
		if (!vnode.state.openStates) {
			vnode.state.openStates = {};
			this.config.schema.forEach((group, index) => {
				vnode.state.openStates[index] = group.foldState === 'open'; // use index as key
			});
		}

		return m(inCard === false ? '' : Card, [
			this.tabActive.title ? m('h2', this.tabActive.title) : void 0,
			m('.cardSections', [
				m(
					'.detailsFlex',
					this.tabActive.form
						? m(
							'.formSidebar',
							vnode.attrs.id == 'new'
								? [
									m('h2', _t('_.fields.new') + ' ' + _t(`_.singular.${vnode.attrs.key}`)),
									m('h6', _t('_.labels.createClientSubtitle')),
								]
								: [
									m('h2', _t('_.fields.edit') + ' ' + _t(`_.singular.${vnode.attrs.key}`)),
									m('h6', _t('_.labels.editClientSubtitle')),
								]
						)
						: void 0,
					m(
						'.' + (this.config.schemaClass || ''),
						this.config.schema.map((group, index) => {
							group.fields = this.sortFormFields(group.fields);
							const openState = vnode.state.openStates[index];

							if (group.conditionalField) {
								const conditionalFieldValueInForm = this.form[group.conditionalField];
								const conditionalFieldValueInRecord = this.record[group.conditionalField];

								if (!conditionalFieldValueInForm && !conditionalFieldValueInRecord) {
									return false;
								}
							}

							if (group.fields.some((field) => field.formShow == true)) {
								return [
									m(
										group.inCard ? Card : '',
										{ class: 'form ' + group.class },
										m('.columnInner', [
											group.fold !== true
												? [
													!group.hideTitle ? m('h3', group.groupName) : void 0,
													!group.hideTitle && group.subTitle ? m('h6', group.subTitle) : void 0,
													group.fields
														.filter((field) => this.shouldFieldBeDisplayed(field))
														.map((field) => {
															return this.renderFormGroup(field);
														}),
												]
												: [
													m(
														'h3.foldTitle',
														{
															class: vnode.state.openStates[index] ? 'open' : '',
														},

														[
															m(
																'',
																{
																	onclick: () => {
																		vnode.state.openStates[index] = !vnode.state.openStates[index];
																		m.redraw();
																	},
																},
																group.groupName,
																m(sjefIconChevronDown)
															),
															group.referenceCopy &&
															m(sjefButton, {
																label: _t('_.buttons.copy'),
																buttonLayout: 'empty',
																onclick: () => {
																	const self = this;
																	const fields = group.fields.filter((field) => field.reference);
																	fields.forEach(function (field) {
																		if (!self.form[field.name]) {
																			self.form[field.name] = self.form[field.reference];
																		}
																	});

																	console.log(this.form);
																},
															}),
														]
													),
													m(
														Collapse,
														{
															isOpen: openState,
															duration: 300,
															oncreate: ({ dom }) => {
																if (openState) {
																	setTimeout(() => {
																		dom.classList.add('open');
																	}, 300);
																}
															},
															onupdate: ({ dom, attrs }) => {
																if (attrs.isOpen) {
																	setTimeout(() => {
																		dom.classList.add('open');
																	}, 300);
																} else {
																	dom?.classList.remove('open');
																}
															},
														},
														[
															m(
																'.foldContent',
																group.fields
																	.filter((field) => this.shouldFieldBeDisplayed(field))
																	.map((field) => {
																		return this.renderFormGroup(field);
																	})
															),
														]
													),
												],
										])
									),
								];
							}
						})
					)
				),
			]),
			vnode.attrs.id == 'new' ? [m('.createSave', this.renderSaveButton())] : void 0,
		]);
	}

	shouldFieldBeDisplayed(field) {
		// Check if formShow is explicitly set to false
		if (field.formShow === false) {
			return false;
		}

		// Handle conditional fields
		if (field.conditionalField) {
			if (this.form[field.conditionalField]) {
				return true;
			}
			return false;
		}

		// Handle createShow and editShow based on formState
		if (this.formState === 'create' && field.createShow === false) {
			return false;
		}

		if (this.formState === 'edit' && field.editShow === false) {
			return false;
		}

		// If none of the above conditions are met, show the field
		return true;
	}

	sortFormFields(fields) {
		// Splitting into two arrays: one with 'row-' and one without.
		const rowFields = [];
		const nonRowFields = [];

		fields.forEach((field) => {
			if (field.class) {
				const classes = field.class.split(' ');
				const rowClass = classes.find((cls) => cls.startsWith('row-'));
				if (rowClass) {
					const rowIndex = parseInt(rowClass.split('-')[1], 10);
					rowFields.push({ field, rowIndex });
				} else {
					nonRowFields.push(field);
				}
			} else {
				nonRowFields.push(field);
			}
		});

		// Sorting the 'row-' array
		rowFields.sort((a, b) => a.rowIndex - b.rowIndex);

		// Merging the two arrays back
		const sortedFields = [];
		let rowPointer = 0;
		for (let i = 1; ; i++) {
			if (rowFields[rowPointer] && rowFields[rowPointer].rowIndex === i) {
				sortedFields.push(rowFields[rowPointer].field);
				rowPointer++;
			} else if (nonRowFields.length > 0) {
				sortedFields.push(nonRowFields.shift());
			} else if (rowFields[rowPointer]) {
				sortedFields.push(rowFields[rowPointer].field);
				rowPointer++;
			} else {
				break;
			}
		}

		return sortedFields;
	}

	/**
		 * Render form group
		 * @param {Object} field - The field object
		 * @return {Object}
		 */
	renderFormGroup(field) {
		return m(FormGroup, {
			class: [
				field.class ? field.class : '',
				field.type,
				this.formErrors[field.name] ? 'hasError' : '',
			],
			content: [
				// m(
				// 	FormLabel,
				// 	{
				// 		for: `${field.name}`,
				// 	},
				// 	_t(`_.fields.${field.name}`, { defaultValue: `${field.name}` })
				// ),
				this.renderInputField(field),
				this.formErrors[field.name] ? m('.error', this.formErrors[field.name]) : void 0,
			],
		});
	}
}

/**
	 * Convert config schema to Ajv schema
	 * @param {Object[]} configSchema - Config schema array
	 * @return {Object} - Ajv schema
	 */
const configToAjvSchema = (configSchema, formState) => {
	const ajvSchema = {
		type: 'object',
		properties: {},
		required: [],
	};

	configSchema.forEach((group) => {
		group.fields.forEach((field) => {
			// skip validation for fields that are not shown

			if (field.formShow === false || (field.editShow === false && formState === 'edit') || (field.createShow === false && formState === 'create')) {
				return;
			}

			ajvSchema.properties[field.name] = {
				type: field.type === 'integer' ? 'number' : field.type,
			};

			for (const prop of ['type', 'format', 'minLength', 'minimum', 'maximum', 'maxLength']) {
				if (field[prop] !== undefined) {
					ajvSchema.properties[field.name][prop] = field[prop];
				}
			}

			if (field.nullable) {
				ajvSchema.properties[field.name].type = [ajvSchema.properties[field.name].type, 'null'];
			}

			if (field.required) {
				ajvSchema.required.push(field.name);
			}

			if (field.defaultValue) {
				ajvSchema.properties[field.name].default = field.defaultValue;
			}
		});
	});

	// console.log(ajvSchema);
	return ajvSchema;
};

export default vwForm;
