{
    "_": {
        "actions": {
            "save": ""
        },
        "buttons": {
            "activate": "",
            "activateAdyen": "Activate Adyen",
            "applyFilters": "",
            "archive": "",
            "autoTranslate": "Auto translate",
            "backToList": "Go back",
            "cancel": "",
            "clearFilters": "",
            "close": "Close",
            "complete": "",
            "copy": "",
            "create": "",
            "deactivate": "",
            "delete": "Delete",
            "discard": "Discard changes",
            "download": "",
            "edit": "",
            "export": "",
            "finish": "",
            "generateTickets": "",
            "maintenance": "",
            "manage": "",
            "new": "New",
            "rollback": "",
            "save": "Save",
            "saveAndClose": "Save & Close",
            "setMaintenance": "",
            "switch": ""
        },
        "fields": {
            "${field}": "",
            "account": "",
            "accountId": "",
            "active": "active",
            "activeInShop": "",
            "activeKiosk": "",
            "activePos": "",
            "activeWebshop": "",
            "address": "address",
            "address2": "address2",
            "adyen": {
                "balanceAccounts": "",
                "businessLineId": "",
                "businessLines": "",
                "canDoPayouts": "",
                "canReceiveFromPlatformPayments": "",
                "canReceivePayments": "",
                "capabilities": "",
                "clientConfig": "",
                "clientMutations": "",
                "merchantId": "",
                "mutations": {
                    "amount": "",
                    "balanceAccount": "",
                    "currency": "",
                    "description": "",
                    "reference": "",
                    "title": "",
                    "type": ""
                },
                "receiveFromBalanceAccount": "",
                "receiveFromPlatformPayments": "",
                "receivePayments": "",
                "sendToBalanceAccount": "",
                "sendToTransferInstrument": "",
                "shopperStatement": "",
                "status": "",
                "storeId": "",
                "stores": ""
            },
            "adyenCompanyAccount": "",
            "adyenEcomStoreId": "",
            "adyenEcomStoreName": "",
            "adyenMerchantAccount": "",
            "adyenPosStoreId": "",
            "adyenPosStoreName": "",
            "allowNegativepayment": "",
            "allowOverpayment": "",
            "allowSplitpayment": "",
            "allowZeropayment": "",
            "allowed": "",
            "allowedAreas": "",
            "amount": "",
            "amountAvailable": "Available",
            "amountCommission": "Commission",
            "amountCommissionMarkup": "CommissionMarkup",
            "amountSplit": "Split",
            "amountTotal": "Total",
            "and": "",
            "apiKey": "",
            "archived": "",
            "area": "",
            "areaId": "",
            "askforAccount": "",
            "balanceAccountId": "balanceAccountId",
            "balancedue": "",
            "balanceoverview": {
                "EUR": "",
                "GBP": "",
                "USD": ""
            },
            "balances": "",
            "barcode": "",
            "bic": "",
            "branchId": "",
            "cabinetId": "cabinetId",
            "cabinetNumber": "cabinetNumber",
            "cancelledAt": "cancelledAt",
            "cashfunctionId": "",
            "categories": "",
            "city": "city",
            "clerk": "",
            "clerkId": "",
            "client": "",
            "clientCodeKantoorLegacy": "",
            "clientId": "",
            "clientKey": "",
            "code": "Code",
            "coinAmount": "",
            "coinButtonValues": "",
            "coinLabel": "",
            "coinSmallestSize": "",
            "coinValue": "",
            "color": "color",
            "colorKiosk": "",
            "colorPos": "",
            "colorWebshop": "",
            "colorWebshopSecondary": "",
            "companyNumber": "companyNumber",
            "completedAt": "completedAt",
            "countries": "",
            "country": "country",
            "courseId": "",
            "create": "",
            "createAllocation": "",
            "createdAt": "",
            "currencies": "",
            "currency": "currency",
            "customerCity": "",
            "customerCountry": "",
            "customerEmail": "",
            "customerHousenumber": "",
            "customerId": "",
            "customerName": "",
            "customerPhoneNumber": "",
            "customerPhoneNumberCountryCode": "",
            "customerStreetname": "",
            "customerZipcode": "",
            "date": "Date",
            "days": "",
            "daysAgo": "",
            "departmentId": "",
            "description": "",
            "descriptionLongTranslations": "",
            "descriptionTranslations": "",
            "direction": "",
            "discountAmount": "",
            "discountId": "",
            "displayName": "",
            "displaygroupId": "",
            "domestic/foreign": {
                "domestic": "",
                "foreign": ""
            },
            "edit": "",
            "electronicShelfLabel": "",
            "elockersActive": "",
            "elockersType": "",
            "email": "email",
            "emailInvoice": "emailInvoice",
            "emailKyc": "emailKyc",
            "enabled": "",
            "end": "",
            "environment": "",
            "estimated Arrival Time": "estimated Arrival Time",
            "estimatedArrivalTime": "estimatedArrivalTime",
            "eventActivateAt": "",
            "eventDeactivateAt": "",
            "eventEnd": "",
            "eventLasts": "",
            "eventStart": "",
            "external_identifier": "",
            "fulfillment": "",
            "generalledgerAccount": "",
            "hours": "",
            "hubspotId": "",
            "iban": "",
            "id": "id",
            "imgKioskNormal": "",
            "imgKioskThumbnail": "",
            "imgNormal": "",
            "imgPosNormal": "",
            "imgPosThumbnail": "",
            "imgSvg": "",
            "imgSvgFallbackItem": "",
            "imgThumbnail": "",
            "imgWebshopNormal": "",
            "imgWebshopThumbnail": "",
            "inAt": "In at",
            "inBy": "In by",
            "industryCode": "industryCode",
            "invoiceAddress": "invoiceAddress",
            "invoiceAddress2": "invoiceAddress2",
            "invoiceCity": "invoiceCity",
            "invoiceCountry": "invoiceCountry",
            "invoiceName": "invoiceName",
            "invoicePostalCode": "invoicePostalCode",
            "invoiceState": "invoiceState",
            "isBackorderItem": "",
            "isCashcardItem": "",
            "isCoinPayment": "",
            "isCoins": "",
            "isDefault": "",
            "isHiddenOnReceipt": "",
            "isOrderItem": "",
            "isParentLeading": "",
            "isPlu": "",
            "isPreferredPayment": "",
            "isStockItem": "",
            "isTip": "",
            "isUniqueItem": "",
            "isVoucherPayment": "",
            "isWallet": "",
            "isWalletPayment": "",
            "large": "",
            "latitude": "",
            "legalEntityId": "",
            "locale": "",
            "location": "",
            "locationId": "",
            "locationName": "",
            "lockVersion": "lockVersion",
            "lockerId": "lockerId",
            "lockerMode": "",
            "lockerNumber": "lockerNumber",
            "lockerSerialNumber": "lockerSerialNumber",
            "lockerType": "lockerType",
            "logout": "",
            "longitude": "",
            "manufacturer": "",
            "masBasket": "",
            "medium": "",
            "merchantAccount": "",
            "merchantName": "",
            "merchantReference": "",
            "metaBrand": "",
            "metaCategory": "",
            "metaManufacturer": "",
            "metaName": "",
            "metaSubcategory": "",
            "metaSupplier": "",
            "metaUnitOfPacking": "",
            "metaUnitOfSale": "",
            "metaVolumM1": "",
            "modeLocker": "",
            "modeStartHour": "",
            "modeTimeslot": "",
            "modeValidHours": "",
            "model": "",
            "name": "name",
            "nameId": "nameId",
            "nameKioskTranslations": "",
            "nameKitchen": "",
            "namePosTranslations": "",
            "nameTranslations": "",
            "nameWebshopTranslations": "",
            "new": "",
            "next": "next",
            "noResults": "",
            "notes": "",
            "open": "",
            "openAll": "",
            "outAt": "Out at",
            "outBy": "Out by",
            "parentId": "",
            "parent_id": "",
            "paymentMethods": "",
            "paymentmethod": "",
            "paymentmethodId": "",
            "paymentprofileId": "",
            "paymentserviceproviderId": "",
            "paymentserviceproviderMethod": "",
            "payoutId": "payoutId",
            "percentage": "",
            "periodType": "periodType",
            "periodValue": "periodValue",
            "phoneNumber": "phoneNumber",
            "phoneNumberCountryCode": "phoneNumberCountryCode",
            "pincode": "",
            "placeholders": {
                "active": "Active",
                "address": "",
                "address2": "",
                "adyenBusinessLines": "",
                "city": "",
                "companyNumber": "Company Number",
                "country": "",
                "email": "Email",
                "emailInvoice": "Email",
                "emailKyc": "Email",
                "hubspotId": "Hubspot ID",
                "id": "ID",
                "industryCode": "Industry Code",
                "invoiceAddress": "Invoice Address",
                "invoiceAddress2": "Invoice Address 2",
                "invoiceCity": "Invoice City",
                "invoiceCountry": "Invoice Country",
                "invoiceName": "Invoice Name",
                "invoicePostalCode": "Invoice Postal Code",
                "invoiceState": "Invoice State",
                "merchantId": "",
                "name": "Name",
                "nameId": "Name ID",
                "phoneNumber": "Phone Number",
                "phoneNumberCountryCode": "Country Code",
                "planId": "Plan ID",
                "region": "Region",
                "role": "",
                "shopperStatement": "Shopper statement",
                "taxNumber": "Tax Number",
                "uuid": "UUID",
                "visitAddress": "visit Address",
                "visitAddress2": "visit Address 2",
                "visitCity": "visit City",
                "visitCountry": "visit Country",
                "visitName": "visit Name",
                "visitPostalCode": "visit Postal Code",
                "visitState": "visit State",
                "wefactId": "WeFact ID",
                "zipcode": ""
            },
            "planId": "",
            "posAdminOnly": "",
            "posColor": "",
            "posOpendrawer": "",
            "posOpenprice": "",
            "posPrintreceipt": "",
            "posSearchable": "",
            "posVisible": "",
            "position": "",
            "positionKiosk": "",
            "positionKitchen": "",
            "positionPos": "",
            "positionWebshop": "",
            "preprint": "",
            "previous": "",
            "price": "",
            "priceCosts": "",
            "priceRetail": "",
            "problems": "",
            "processed At": "processed At",
            "processedAt": "processedAt",
            "profileId": "",
            "projectEnd": "",
            "projectName": "",
            "projectStart": "",
            "pspEftReceipt": "",
            "pspMethod": "",
            "pspName": "",
            "pspPayload": "",
            "pspResponse": "",
            "pspVariant": "",
            "quantityMaximum": "",
            "quantityMinimum": "",
            "reason": "reason",
            "redirectUrl": "",
            "reference": "",
            "region": "region",
            "requested": "",
            "requiredAccount": "",
            "requiredBookingnumber": "",
            "reserve": "",
            "saleschannels": "",
            "selected": "Selected",
            "sequence": "sequence",
            "serialNumber": "serialNumber",
            "serialnumber": "",
            "service": "",
            "settlementId": "settlementId",
            "shopSelection": "",
            "shopperStatement": "shopperStatement",
            "sjefId": "SjefId",
            "slug": "",
            "source": "",
            "stack": "",
            "start": "",
            "status": "",
            "statusAssets": "",
            "subtotal": "",
            "subtotalId": "",
            "supplier": "supplier",
            "table": "",
            "tableId": "",
            "taxNumber": "taxNumber",
            "taxes": "",
            "taxrateId": "",
            "terminalSequence": "",
            "ticketeer": "",
            "tickets_c": "",
            "tickets_lid": "",
            "tickets_lno": "",
            "tickets_lpin": "",
            "tickets_lt": "",
            "tickets_tid": "",
            "tickets_w": "",
            "timeWindow": "",
            "timestamp": "",
            "timezone": "",
            "today": "",
            "token": "",
            "tokenAmount": "",
            "tokenDescription": "",
            "total": "",
            "totalCoins": "",
            "translations": "Translations",
            "type": "type",
            "uniqueItemType": "",
            "uniqueItemTypePrintjobs": "",
            "updatedAt": "",
            "uuid": "",
            "verificationStatus": "",
            "videoWebshop": "",
            "viewWebshop": "",
            "visitAddress": "visitAddress",
            "visitAddress2": "visitAddress2",
            "visitCity": "visitCity",
            "visitCountry": "visitCountry",
            "visitName": "visitName",
            "visitPostalCode": "visitPostalCode",
            "visitState": "visitState",
            "wave": "",
            "webdata": "",
            "wefactId": "",
            "yesterday": "",
            "zipcode": "zipcode"
        },
        "filterTabs": {
            "defaultTabs": "",
            "savedTabs": ""
        },
        "labels": {
            "active": "",
            "allocations": "",
            "area": "",
            "areaReport": "",
            "cabinets": "",
            "checklist": "",
            "createAllocation": "",
            "createArea": "",
            "createClientSubtitle": "",
            "createEvent": "",
            "createLocation": "",
            "deleteArea": "",
            "deleteLocation": "",
            "editAllocation": "",
            "editClientSubtitle": "",
            "editLocation": "",
            "event": "",
            "eventEnd": "",
            "eventName": "",
            "eventStart": "",
            "eventTitle": "",
            "eventType": "",
            "filters": "",
            "generalInfo": "",
            "invite": "",
            "locationName": "",
            "locations": "",
            "logout": {
                "confirmContent": "",
                "confirmTitle": ""
            },
            "maintenance": "",
            "maintenanceCancel": "",
            "maintenanceCancelContent": "",
            "maintenanceComplete": "",
            "maintenanceCompleteContent": "",
            "noAreas": "",
            "openOrganisation": "",
            "report": "",
            "switch": {
                "confirmContent": "",
                "confirmTitle": ""
            },
            "tickets": "",
            "ticketsGenerated": ""
        },
        "messages": {
            "notAvailable": ""
        },
        "modals": {
            "cancelDeleteButton": "",
            "confirmCancelButton": "",
            "confirmCancelContent": "",
            "confirmCancelTitle": "",
            "confirmDeleteButton": "",
            "confirmDeleteContent": "",
            "confirmDeleteTitle": "",
            "reload": {
                "description": "",
                "title": ""
            }
        },
        "months": {
            "april": "",
            "august": "",
            "december": "",
            "february": "",
            "january": "",
            "july": "",
            "june": "",
            "march": "",
            "may": "",
            "november": "",
            "october": "",
            "september": ""
        },
        "records": {
            "noRecordsAvailable": ""
        },
        "search": {
            "search": ""
        },
        "singular": {
            "accounts": "",
            "administrations": "",
            "areas": "",
            "assets": "",
            "balance_mutations": "",
            "branches": "",
            "cabinets": "",
            "cashfunctions": "",
            "categories": "",
            "clerks": "",
            "clients": "",
            "courses": "",
            "departments": "",
            "displaygroups": "",
            "events": "",
            "items": "",
            "locations": "",
            "lockerEvents": "",
            "lockers": "",
            "maintenance": "",
            "menus": "",
            "modifiers": "",
            "organisations": "",
            "paymentmethods": "",
            "paymentprofiles": "",
            "payments": "",
            "paymentserviceproviders": "",
            "payouts": "",
            "projects": "",
            "psps": "",
            "settlements": "",
            "subtotals": "",
            "suppliers": "",
            "taxrates": "",
            "tickets": "",
            "transactions": ""
        },
        "th": {
            "name": ""
        }
    },
    "_fields": {
        "name": ""
    },
    "clients": {
        "clientConfig": {
            "balanceAccountId": "",
            "merchantAccount": "",
            "modal": {
                "edit": {
                    "button": "",
                    "title": ""
                },
                "new": {
                    "button": "",
                    "title": ""
                }
            },
            "paymentConfigId": "",
            "region": "",
            "storeId": "",
            "type": ""
        },
        "clientInvite": {
            "actions": {
                "block": "",
                "resend": "",
                "title": ""
            },
            "createdAt": "",
            "createdBy": "",
            "description": "",
            "email": "",
            "expiresAt": "",
            "status": "",
            "title": "",
            "toast": {
                "blocked": "",
                "sent": ""
            }
        },
        "fields": {
            "hubspotId": "HubSpotID",
            "nameId": "nameID",
            "wefactId": "WeFactID"
        },
        "invoices": {
            "subtitle": "",
            "table": {
                "th": {
                    "amountExcl": "Amount Exc.",
                    "amountIncl": "Amount Inc.",
                    "amountOutstanding": "Amount outstanding",
                    "code": "Code",
                    "date": "Date",
                    "status": "Status"
                }
            },
            "title": ""
        },
        "pricequotes": {
            "subtitle": "",
            "title": ""
        },
        "tabs": {
            "adyen": "Adyen",
            "details": "Details",
            "invoices": "Invoices"
        }
    },
    "countries": {
        "AD": "",
        "AE": "",
        "AF": "",
        "AG": "",
        "AI": "",
        "AL": "",
        "AM": "",
        "AN": "",
        "AO": "",
        "AQ": "",
        "AR": "",
        "AS": "",
        "AT": "",
        "AU": "",
        "AW": "",
        "AX": "",
        "AZ": "",
        "BA": "",
        "BB": "",
        "BD": "",
        "BE": "",
        "BF": "",
        "BG": "",
        "BH": "",
        "BI": "",
        "BJ": "",
        "BM": "",
        "BN": "",
        "BO": "",
        "BR": "",
        "BS": "",
        "BT": "",
        "BV": "",
        "BW": "",
        "BY": "",
        "BZ": "",
        "CA": "",
        "CC": "",
        "CD": "",
        "CF": "",
        "CG": "",
        "CH": "",
        "CK": "",
        "CL": "",
        "CM": "",
        "CN": "",
        "CO": "",
        "CR": "",
        "CS": "",
        "CU": "",
        "CV": "",
        "CX": "",
        "CY": "",
        "CZ": "",
        "DE": "",
        "DJ": "",
        "DK": "",
        "DM": "",
        "DO": "",
        "DZ": "",
        "EC": "",
        "EE": "",
        "EG": "",
        "EH": "",
        "ER": "",
        "ES": "",
        "ET": "",
        "FI": "",
        "FJ": "",
        "FK": "",
        "FM": "",
        "FO": "",
        "FR": "",
        "GA": "",
        "GB": "",
        "GD": "",
        "GE": "",
        "GF": "",
        "GG": "",
        "GH": "",
        "GI": "",
        "GL": "",
        "GM": "",
        "GN": "",
        "GP": "",
        "GQ": "",
        "GR": "",
        "GS": "",
        "GT": "",
        "GU": "",
        "GW": "",
        "GY": "",
        "HK": "",
        "HM": "",
        "HN": "",
        "HR": "",
        "HT": "",
        "HU": "",
        "ID": "",
        "IE": "",
        "IL": "",
        "IM": "",
        "IN": "",
        "IO": "",
        "IQ": "",
        "IR": "",
        "IS": "",
        "IT": "",
        "JE": "",
        "JM": "",
        "JO": "",
        "JP": "",
        "KE": "",
        "KG": "",
        "KH": "",
        "KI": "",
        "KM": "",
        "KN": "",
        "KR": "",
        "KW": "",
        "KY": "",
        "KZ": "",
        "LB": "",
        "LC": "",
        "LI": "",
        "LK": "",
        "LR": "",
        "LS": "",
        "LT": "",
        "LU": "",
        "LV": "",
        "LY": "",
        "MA": "",
        "MC": "",
        "MD": "",
        "MG": "",
        "MH": "",
        "MK": "",
        "ML": "",
        "MM": "",
        "MN": "",
        "MO": "",
        "MP": "",
        "MQ": "",
        "MR": "",
        "MS": "",
        "MT": "",
        "MU": "",
        "MV": "",
        "MW": "",
        "MX": "",
        "MY": "",
        "MZ": "",
        "NA": "",
        "NC": "",
        "NE": "",
        "NF": "",
        "NG": "",
        "NI": "",
        "NL": "",
        "NO": "",
        "NP": "",
        "NR": "",
        "NU": "",
        "NZ": "",
        "OM": "",
        "PA": "",
        "PE": "",
        "PF": "",
        "PG": "",
        "PH": "",
        "PK": "",
        "PL": "",
        "PM": "",
        "PN": "",
        "PR": "",
        "PS": "",
        "PT": "",
        "PW": "",
        "PY": "",
        "QA": "",
        "RE": "",
        "RO": "",
        "RU": "",
        "RW": "",
        "SA": "",
        "SB": "",
        "SC": "",
        "SD": "",
        "SE": "",
        "SG": "",
        "SH": "",
        "SI": "",
        "SJ": "",
        "SK": "",
        "SL": "",
        "SM": "",
        "SN": "",
        "SO": "",
        "SR": "",
        "ST": "",
        "SV": "",
        "SY": "",
        "SZ": "",
        "TC": "",
        "TD": "",
        "TF": "",
        "TG": "",
        "TH": "",
        "TJ": "",
        "TK": "",
        "TL": "",
        "TM": "",
        "TN": "",
        "TO": "",
        "TR": "",
        "TT": "",
        "TV": "",
        "TW": "",
        "TZ": "",
        "UA": "",
        "UG": "",
        "UM": "",
        "US": "",
        "UY": "",
        "UZ": "",
        "VA": "",
        "VC": "",
        "VE": "",
        "VG": "",
        "VI": "",
        "VN": "",
        "VU": "",
        "WF": "",
        "WS": "",
        "YE": "",
        "YT": "",
        "ZA": "",
        "ZM": "",
        "ZW": ""
    },
    "modules": {
        "accounts": {
            "tabs": {
                "details": "",
                "example": ""
            },
            "title": ""
        },
        "administrations": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "areas": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "assets": {
            "tabs": {
                "assets": "",
                "details": "",
                "list": ""
            },
            "title": ""
        },
        "balance_mutations": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "balanceoverview": {
            "account_balance": "",
            "continue_modal": {
                "button": "",
                "description": ""
            },
            "edit_balance_account": "",
            "intro": {
                "paragraph1": "",
                "paragraph2": "",
                "paragraph3": "",
                "subtitle": "",
                "title": ""
            },
            "recent_mutations": "",
            "recent_payouts": "",
            "redirect_modal": {
                "description": ""
            },
            "widgets": {
                "authorised": "",
                "availableForPayout": "",
                "invoiced": "",
                "payout": "",
                "sentForSettle": "",
                "settled": ""
            }
        },
        "branches": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "cabinets": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "cashfunctions": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "categories": {
            "tabs": {
                "details": "",
                "locations": "",
                "profile": ""
            },
            "title": ""
        },
        "clerks": {
            "tabs": {
                "allowedAreas": "",
                "details": ""
            },
            "title": ""
        },
        "clients": {
            "tabs": {
                "adyen": "",
                "details": "",
                "invites": "",
                "paymentScheme": "",
                "wefactInvoices": "",
                "wefactPricequotes": ""
            },
            "title": ""
        },
        "courses": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "departments": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "displaygroups": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "events": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "home": {
            "balanceplatform": {
                "button": "",
                "paragraph1": "",
                "paragraph2": "",
                "paragraph3": "",
                "subtitle": ""
            },
            "greeting": {
                "goodafternoon": "",
                "goodevening": "",
                "goodmorning": ""
            },
            "intro": {
                "paragraph1": "",
                "paragraph2": ""
            },
            "transactions": {
                "title": ""
            },
            "turnover": {
                "title": ""
            }
        },
        "items": {
            "tabs": {
                "categories": "",
                "details": "",
                "modifiers": ""
            },
            "title": ""
        },
        "locations": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "lockerEvents": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "lockers": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "maintenance": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "menus": {
            "tabs": {
                "details": "",
                "items": ""
            },
            "title": ""
        },
        "modifiers": {
            "tabs": {
                "details": "",
                "items": ""
            },
            "title": ""
        },
        "organisations": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "paymentmethods": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "paymentprofiles": {
            "tabs": {
                "details": "",
                "paymentMethods": ""
            },
            "title": ""
        },
        "payments": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "paymentserviceproviders": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "payouts": {
            "title": ""
        },
        "projects": {
            "tabs": {
                "assets": "",
                "details": "",
                "payments": ""
            },
            "title": ""
        },
        "psps": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "settlements": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "subtotals": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "suppliers": {
            "tabs": {
                "details": "",
                "example": ""
            },
            "title": ""
        },
        "taxrates": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "tickets": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "transactions": {
            "tabs": {
                "details": "",
                "lines&payments": ""
            },
            "title": ""
        }
    },
    "reconciliation": {
        "new": ""
    },
    "schema": {
        "tiers": {
            "quantity": ""
        }
    }
}