import m from 'mithril';
import sjefBanner from './sjefBanner';

const SjefCardWithHeader = ({ title, width, imageHeader, children, headerHeight = '200px', headerPaddingSide = '4rem', headerPaddingTop = '3rem', bodyPaddingSide = '4rem', bodyPaddingTop = '1rem', style }) => (
	m('.card_with_header', { style: { width, ...style } }, [
		imageHeader ? m('.header-image', { style: { height: headerHeight } }, [
			m('img', { src: imageHeader, style: { height: headerHeight } }),
			m('h1', { style: { top: headerPaddingTop, left: headerPaddingSide } }, title),
		]) : m(sjefBanner, { title, style: { borderRadius: '8px 8px 0 0', border: 0, padding: `${headerPaddingTop} ${headerPaddingSide}`, height: headerHeight, marginBottom: 0 } }),
		m('.card-content', { style: { padding: `${bodyPaddingTop} ${bodyPaddingSide}` } }, [
			children
		]
		),
	]));

export default SjefCardWithHeader;
