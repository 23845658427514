
/* eslint:disable */
import m from 'mithril';

class sjefIconBuildingFactory {
    view({ attrs: { classes = [], size = "24", strokeWidth = "2", color = "currentColor", fill = "none", onclick = void(0), ...htmlAttrs  }}){
        return m('.sjefIcon.sjefIconBuildingFactory',{
                onclick,
                class  : ['icon-sjefIcon2Fa', ...classes].join(" "),
                ...htmlAttrs
            },
            m('svg[xmlns=http://www.w3.org/2000/svg].icon-svg', {
                width  : size || "24",
                height : size || "24",
                stroke : color || "currentColor",
                fill   : fill || "none",
                "stroke-width": strokeWidth || "2",
                "viewBox": "0 0 24 24",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            }, m.trust('<path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 21c1.147 -4.02 1.983 -8.027 2 -12h6c.017 3.973 .853 7.98 2 12" /><path d="M12.5 13h4.5c.025 2.612 .894 5.296 2 8" /><path d="M9 5a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1" /><line x1="3" y1="21" x2="22" y2="21" />'))
        )
    }
}
export default sjefIconBuildingFactory;

