import sjefIconWashMachine from '@sjefapp/sjeficons/icons/sjefIconWashMachine';

const { default: vwAssetsOverview } = require('../modules/assets/vwAssetsOverview');

const moduleName = 'assets';
const baseRoute = '/assets';
const endpoint = '/operations/assets';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.listTabs = [
			{
				default: true,
				slug: 'list',
				viewMode: 'edit',
			},
			{
				slug: 'assets',
				inCard: false,
				projectId: 1,
				component: vwAssetsOverview,
			},
		];
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];
		this.layoutType = 'small';

		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'sjefId',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: false,
						listColumnPosition: 1,
						title: true,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'displayName',
						type: 'string',
						class: 'width-50',
						defaultFilter: true,
						formInputType: 'text',
						link: `${baseRoute}/:id`,
						formShow: true,
						icon: sjefIconWashMachine,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'type',
						type: 'string',
						advancedFilterType: 'select',
						advancedFilterOptions: ['terminal', 'bulk'],
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 4,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'serialnumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 5,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'manufacturer',
						type: 'string',
						formInputType: 'select',
						options: ['adyen', 'multiSource', 'ProDVX', 'APC', 'teltonika'],
						// defaultFilter: true,
						formShow: true,
						advancedFilterType: 'select',
						icon: sjefIconWashMachine,
						// advancedFilterOptions: ['adyen', 'multiSource', 'ProDVX', 'APC', 'teltonika'],
						listActiveToggle: true,
						listColumnPosition: 6,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'model',
						type: 'string',
						advancedFilterType: 'select',
						advancedFilterOptions: ['S1F2', 'TurboPos', 'AMS1'],
						formInputType: 'string',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 8,
						listShow: true,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					// {
					// 	name: 'date', //dummyfield
					// 	type: 'number',
					// 	filterType: 'date',
					// 	filterTypeDateRange: true,
					// 	filterOptions: ['S1F2', 'TurboPos', 'AMS1'],
					// 	formInputType: 'number',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 8,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	minimum: 1,
					// 	nullable: true,
					// 	required: true,
					// },
				],
			},
		];
	}
}
