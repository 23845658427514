import { PopoverMenu } from 'construct-ui';
import m from 'mithril';
import { isContentFalsy } from '../../lib/helpers';

class sjefPopover {
	classes;

	oninit(vnode) {
		this.classes = 'sjefPopover ' + vnode.attrs.class
	}

	view(vnode) {

		if (isContentFalsy(vnode.attrs.content)) {
            console.log('All content is falsy, not rendering sjefPopover.');
            return;  // Return nothing if all content is falsy
        }

		return [
			m(PopoverMenu, {
				trigger: m(
					'.popoverTrigger',
					[m(''), m(''), m('')]
				),
				class: this.classes,
				closeOnOutsideClick: true,
				content: vnode.attrs.content,
				hasArrow: false,
				position: vnode.attrs.position || 'bottom-end',
			}),
		];
	}
}

export default sjefPopover;
