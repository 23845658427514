/**
 * @fileOverview View component for displaying and managing business lines.
 * @module vwClientAdyenStores
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import { Card, FormGroup, FormLabel, Collapse, Checkbox } from 'construct-ui';

import vwClientAdyenStoresCreateModal from './vwClientAdyenStoresCreateModal';
import sjefInput from '../../../views/components/sjefInput';
import { _t } from '../../../lib/i18n';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import icons from '../../../lib/icons';
import response from '../../../lib/response';
import sjefLoading from '../../../views/components/sjefLoading';
import sjefCheckbox from '../../../views/components/sjefCheckbox';
import sjefDialog from '../../../views/components/sjefDialog';
import sjefButton from '../../../views/components/sjefButton';

const iconLookup = {
	ideal: icons.idealIcon,
	bcmc: icons.bcmcIcon,
	maestro: icons.maestroIcon,
	applepay: icons.applePayIcon,
	mc: icons.mastercardIcon,
	diners: icons.dinersIcon,
	discover: icons.discoverIcon,
	jcb: icons.jcbIcon,
	visa: icons.visaIcon,
	amex: icons.amexIcon,
	bc: icons.amexIcon
};
/**
 * The view module exports an object representing a Mithril component.
 * @module vwClientAdyenStores
 * @namespace
 */

class vwClientAdyenStores {
	/**
	 * @type {Array} businessLines - An array to store business lines.
	 */
	businessLines = [];

	/**
	 * @type {Array} stores - An array to store business lines.
	 */
	stores = [];

	/**
	 * @type {Function} callbackRedraw - The callback function to be called for redrawing the UI.
	 */
	callbackRedraw;

	/**
	 * @type {string} clientId - The ID of the client.
	 */
	clientId;
	loading = true;
	openPaymentStates = {};
	showConfirmationModal = false;

	/**
	 * Initializes the view component with provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {string} vnode.attrs.clientId - The client's ID.
	 * @param {Array} vnode.attrs.stores - The business lines data.
	 * @param {Function} vnode.attrs.callbackRedraw - Callback to redraw the view.
	 */
	oninit = async ({ attrs }) => {
		this.clientId = attrs.clientId || parseInt(app.restore('activeRecordId'));
		this.storeType = attrs.storeType;
		this.merchantAccounts = attrs.merchantAccounts;
		this.businessLines = attrs.businessLines;

		this.loading = true;
		this.stores = await this.getAdyenStores();
		this.loading = false;
	};

	getAdyenStores = async () => {
		const result = await app.get(`/balanceplatform/clients/${this.clientId}/stores`);

		if (result.success === false) {
			return response.failed('1111', result.error);
		}

		return result.data;
	};

	/**
	 * Reassigns the stores property to the stores variable.
	 *
	 * @async
	 * @function
	 * @returns {Promise<void>} No explicit return.
	 */
	redraw = async () => {
		this.stores = await this.getAdyenStores();
	};



	getIcon(type) {
		if (iconLookup.hasOwnProperty(type)) {
			return m(iconLookup[type]);
		}
		return null;
	}

	setSelectedStore = (store) => {
		this.selectedStore = store;
	}

	setSelectedPaymentMethod = (paymentMethod) => {
		this.selectedPaymentMethod = paymentMethod;
	}

	renderCreateModals() {
		const posBusinessLines = this.businessLines.filter((bL) => bL.salesChannels.includes('pos'));
		const ecomBusinessLines = this.businessLines.filter((bL) => bL.salesChannels.find((channel) => channel !== 'pos'));

		const posModal = posBusinessLines.length ? m(vwClientAdyenStoresCreateModal, {
			clientId: this.clientId,
			storeType: 'pos',
			businessLines: posBusinessLines,
			merchantAccountId: this.merchantAccounts?.pos,
			callbackOnSuccess: this.redraw
		}) : void (0);

		const ecomModal = ecomBusinessLines.length ? m(vwClientAdyenStoresCreateModal, {
			clientId: this.clientId,
			storeType: 'ecom',
			businessLines: ecomBusinessLines,
			merchantAccountId: this.merchantAccounts?.ecom,
			callbackOnSuccess: this.redraw
		}) : void (0);

		return [posModal, ecomModal];
	}

	/**
	 * Renders the view component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */

	view() {
		return m(
			'.adyen',
			{
				class: (this.loading ? 'isLoading' : void 0),
			},
			[
				sjefDialog({
					isOpen: this.showConfirmationModal,
					content: 'Are you sure you want to change the status of this payment method?',
					close: () => {
						this.showConfirmationModal = false;
					},
					footer: [
						m(sjefButton, {
							label: 'Cancel',
							buttonLayout: 'neutral',
							onclick: () => {
								this.showConfirmationModal = false;
							},
						}),
						m(sjefButton, {
							label: 'Confirm',
							buttonLayout: 'filled',
							onclick: async () => {
								console.warn('this.clientId', this.clientId);
								this.showConfirmationModal = false;
								const response = await app.patch(`/balanceplatform/clients/${this.clientId}/stores/${this.selectedStore.storeId}/paymentmethods/${this.selectedPaymentMethod.id}`, {
									merchantId: this.selectedStore.merchantId,
									enabled: !this.selectedPaymentMethod.enabled,
								})
								if (response.success) {
									app.toast('Payment method status updated', { intent: 'success' });
									this.stores = await this.getAdyenStores();
									return;
								}
								app.toast('Failed to update payment method status', { intent: 'danger' });
							},
						}),
					],
				}),
				m(
					'.flexer',
					m(
						'h2',
						{
							onclick: (e) => {
								let el = e.target;
								while (el && el !== document) {
									if (el.classList.contains('adyen')) {
										el.classList.toggle('isFolded');
										break;
									}
									el = el.parentElement;
								}
							},
						},
						'Stores'
					),
					m('.flexer', this.renderCreateModals())
				),
				(this.stores && !this.loading) ?
					this.stores.map((store, storeIndex) => {
						return m(Card, [
							m('h3', store.name),
							m(FormGroup, { content: [m(FormLabel, _t('_.fields.adyen.storeId')), m(sjefInput, { copy: true, value: store.storeId, readonly: true })] }),
							m(
								'.greyed.columns.columns-2',
								m(
									'.column',
									m(FormGroup, {
										content: [m(FormLabel, _t('_.fields.adyen.merchantId')), m('div', [m('div', store.merchantId)])],
									}),

									m(FormGroup, {
										content: [m(FormLabel, _t('_.fields.adyen.shopperStatement')), m('div', [m('div', store.shopperStatement)])],
									}),

									m(FormGroup, {
										content: [m(FormLabel, _t('_.fields.adyen.status')), m('div', [m('div', store.status)])],
									})
								),

								m('.column', [
									m(FormGroup, {
										content: [
											m(FormLabel, _t('_.fields.address')),
											m('div', [
												m('div', store.address),
												m('div', store.postalCode),
												m('div', store.city),
												m('div', store.country),
											]),
										],
									}),
								])
							),
							m(
								'',
								m('.column', [
									m(
										'.linkList',
										m('h4', _t('_.fields.paymentMethods')),
										store.paymentMethods
											.sort((a, b) => a.type.localeCompare(b.type)) // Sort paymentMethods by type
											.map((paymentMethod, idx) => {
												const uniqueKey = `store-${storeIndex}-pm-${idx}`;
												return [
													m('.linkListItem.active-state-elem', {
														class: this.openPaymentStates[uniqueKey] ? 'open' : '',
													}, [
														m('.inner',
															m('.title', {

															},
																m(sjefCheckbox, {
																	checked: paymentMethod.enabled,
																	onChange: async () => {
																		this.setSelectedStore(store);
																		this.setSelectedPaymentMethod(paymentMethod);
																		this.showConfirmationModal = true;
																	},
																}
																),
																m('.icon', this.getIcon(paymentMethod.type)),
																m('.name', paymentMethod.type),
															),
															m('.iconHover', {
																onclick: () => {
																	this.openPaymentStates[uniqueKey] = !this.openPaymentStates[uniqueKey];
																},
															}, m(sjefIconChevronDown)),
														),
														m(Collapse, {
															isOpen: this.openPaymentStates[uniqueKey],
															duration: 200,
														}, [
															m('',
																m(FormGroup, {
																	content: [
																		m(FormLabel, _t('_.fields.currencies')),
																		paymentMethod.currencies.length ?
																			paymentMethod.currencies.map((cur) => {
																				return m('', cur ? cur : '-')
																			}) : m('', '-')
																	],
																}),
																m(FormGroup, {
																	content: [
																		m(FormLabel, _t('_.fields.countries')),
																		paymentMethod.countries.length ?
																			paymentMethod.countries.map((country) => {
																				return m('', country ? country : '-')
																			}) : m('', '-')
																	],
																}),
															)
														])
													]),
												];
											})
									),
								])
							),
						]);
					}) : m(sjefLoading)
			]
		);
	}
}

export default vwClientAdyenStores;
