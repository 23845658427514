const moduleName = 'balance_mutations';
const baseRoute = '/banking/balance_mutations';
const endpoint = '/clientbalances/mutations';
import { price, writtenDate } from "../lib/helpers";
import periods from "../utils/periods";

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.defaultValues = [
			{
				endpoint: '/clientbalances/mutations/metadata',
				attribute: 'balanceAccountIds',
				filterName: 'balanceAccountId',
			},
			{
				endpoint: '/clientbalances/mutations/metadata',
				attribute: 'currencies',
				filterName: 'currency',
			},
			{
				filterName: 'periodType',
				value: 'month'
			},
			{
				filterName: 'periodValue',
				value: periods.getCurrent()
			}
		]


		this.actions = {
			canCreate: () => false,
			canUpdate: () => false,
			canDelete: () => false,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'read',
			},
		];
		this.hideCheckboxes = true;
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'balanceAccountId',
						type: 'string',
						formInputType: false,
						formShow: false,
						// link: `${baseRoute}/:uuid`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						endpoint: '/clientbalances/mutations/metadata',
						optionsKey: 'balanceAccountIds',
						popoverFilterType: 'select',
					},
					{
						name: 'settlementId',
						type: 'integer',
						formInputType: 'text',
						formShow: true,
						// link: `${baseRoute}/:uuid`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						required: true,

					},
					{
						name: 'payoutId',
						type: 'string',
						listShow: false,
						listSize: 'small',
						required: true,
					},
					{
						name: 'amount',
						type: 'number',
						listShow: true,
						listSize: 'small',
						required: true,
						formatter: price,

					},
					{
						name: 'currency',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
						endpoint: '/clientbalances/mutations/metadata',
						optionsKey: 'currencies',
						popoverFilterType: 'select',
					},
					{
						name: 'sequence',
						type: 'integer',
						listShow: true,
						listSize: 'small',
						required: true,
					},
					{
						name: 'type',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
						popoverFilterType: 'select',
						options: [
							{ value: 'payout', label: 'Payout' },
							{ value: 'settlement', label: 'Settlement' },
						],
					},
					{
						name: 'reference',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
					},
					{
						name: 'createdAt',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
						formatter: writtenDate,
					},
					{
						name: 'periodType',
						type: 'select',
						popoverFilterType: 'select',
						options: [
							{ value: 'day', label: 'Day' },
							{ value: 'week', label: 'Week' },
							{ value: 'month', label: 'Month' },
							{ value: 'year', label: 'Year' },
						],

					},
					{
						name: 'periodValue',
						type: 'string',

					},
				],
			},
		];
	}
}
