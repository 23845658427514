const moduleName = 'invoices';
const baseRoute = '/invoices';
// const endpoint = '/masterdata/areas';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
	}
}
