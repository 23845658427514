import m from 'mithril'
import sjefCheckbox from '../../../views/components/sjefCheckbox';
import sjefTh from '../../../views/components/sjefTh';
import { _t } from '../../../lib/i18n';


const listSizes = {
	fixed: 'cFixed',
	small: 'cSmall',
	medium: 'cMedium',
	large: 'cLarge',
};

const sjefTableHeadRow = (fields, activeSort, setSorting, allRecordsSelected, setAllRecordsSelected, hideCheckboxes) => {
	// can have checkbox to select all
	// should trigger sorting

	const stripActiveSort = (activeSort) => {
		if (!activeSort) {
			return false;
		}

		return activeSort.replace('-', '');
	}

	return m(
		'tr',
		[
			!hideCheckboxes && m(
				'th',
				{ class: 'cSmall table-checkbox' },
				m(sjefCheckbox, {
					class: 'selectRecord',
					checked: allRecordsSelected,
					onChange: (value) => {
						setAllRecordsSelected(value);
					},
				})
			),
			fields.map((field) => {
				if (field.listShow) {
					return m(sjefTh, {
						name: _t(`_.fields.${field.name}`),
						// name: field.name,
						activeSort: activeSort,
						// label: _t(`_.fields.${field}`),
						class: stripActiveSort(activeSort) == field.name ? 'currentSort' : '',
						// toggleDragSort: (field) => toggleDragSort(field),
						onSortUpdate: (sortReturnData) => {
							if (sortReturnData.sort) {
								setSorting(sortReturnData);
							}
						}
					})
				}
				return m('th', { class: [field.name, listSizes[field.listSize]].join(' ') })
			}),
		]
	)

}

export default sjefTableHeadRow
