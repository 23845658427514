import { Callout, Form, FormGroup, FormLabel, Input } from 'construct-ui';
import m from 'mithril';
import app from '../../app';
import sjefButton from '../components/sjefButton';
import { initRoutes } from '../../routes';

import loginForm from './components/loginForm';
import tfaForm from './components/tfaForm';

const logoSrc = new URL('../../assets/img/sjef_logo.png', import.meta.url).href;

/**
 * @type { import("mithril").Component }
 * @property {Object} secure - Defines security or public when false
 * @property {string} secure.obj - Define security object
 * @property {string} secure.act - Define security action
 * @property {string} redirect - After login, redirect to this path, add to url param
 */

const isAuthenticated = () => {
	app.isAuthenticated = true;

	initRoutes(app.lvl);

	if (!login.redirect) {
		m.route.set('/switching');

		// Delay route change to the next tick of the event loop
		setTimeout(() => {
			m.route.set('/');
		}, 1100);
	} else {
		m.route.set('/' + login.redirect);
	}
};

const tfaRequired = () => {
	return app.tfa === true || app.tfa.required === true
};

const login = {
	secure: false,
	menu: false,
	redirect: '',
	oninit: ({ attrs }) => {
		login.redirect = attrs.redirect || '';
		if (attrs.logout) {
			app.isAuthenticated = false;
		}
		console.debug('init Login', login.redirect);
	},

	view: () => {
		return m('.login', [
			m(
				'.homePageInner',
				m('', m('img', { src: logoSrc })),
				m(
					'',
					tfaRequired()
						? m(tfaForm, {
							loggedIn: () => {
								isAuthenticated();
								m.redraw();
							},
						})
						: m(loginForm, {
							loggedIn: () => {
								isAuthenticated();
							},
							completed: () => {
								m.redraw();
							},
						})
				)
			),

			// m(Button, { label: 'TEST MODULE', onclick: () => m.route.set('/test01')})
		]);
	},
};

export default login;
