import m from 'mithril';
import { Tag } from "construct-ui";
import COLORS from "../utils/colors";
import { daysAgo, price, truncate } from '../lib/helpers';

const moduleName = 'payouts';
const baseRoute = '/banking/payouts';
const endpoint = '/payouts/payouts';

const tagStyle = {
	"Success": { backgroundColor: COLORS.green.muted, color: COLORS.green.default },
	"Pending": { backgroundColor: COLORS.purple.muted, color: COLORS.purple.default },
	"Failed": {
		backgroundColor: COLORS.red.muted, color: COLORS.red.default
	}
}
export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => false,
			canUpdate: () => false,
			canDelete: () => false,
		};
		this.tabs = [
			{
				default: true,
				viewMode: 'read',
			},
		];
		this.hideCheckboxes = true;
		this.listConfig = {
			title: "Payouts",
			columns: {
				uuid: {
					hidden: true,
				},
				status: {
					component: Tag,
					componentProps: {
						rounded: true,
						size: 'xs', style: tagStyle['Success']
					}
				},
			}
		};

		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'code',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						// link: `${baseRoute}/:uuid`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						required: true,
					},
					{
						name: 'amount',
						type: 'number',
						formInputType: 'integer',
						formShow: true,
						required: true,
						listShow: true,
						listSize: 'small',
						formatter: price,
					},
					{
						name: 'currency',
						type: 'string',
						listShow: false,
						listActiveToggle: true,
						listSize: 'small',
						required: true,

					},
					{
						name: 'estimated Arrival Time',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
					},
					{
						name: 'status',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
						advancedFilterType: 'select',
						advancedFilterOptions: ['booked', 'failed', 'scheduled', 'pending'],

					},
					{
						name: 'reason',
						type: 'string',
						listShow: true,
						listSize: 'small',
						required: true,
						formatter: truncate
					},
					{
						name: 'processed At',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
						formatter: daysAgo
					},
				],
			},
		];
	}
}
