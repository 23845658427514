[
	{
		"id": "home",
		"label": "Home",
		"hidden": true,
		"route": "",
		"module": "vwHome"
	},
	{
		"id": "administrations",
		"label": "Administrations",
		"route": "administrations",
		"module": "vwList",
		"config": "administrations",
		"accessRules": {
			"role": [
				"7:administrator"
			]
		},
		"children": [
			{
				"id": "administration",
				"label": "administration",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "administrations",
				"accessRules": {
					"role": [
						"7:administrator"
					]
				}
			}
		]
	},
	{
		"id": "kyc",
		"label": "KYC",
		"route": "kyc",
		"module": "vwKYC",
		"accessRules": {
			"role": [
				"7:administrator"
			]
		}
	},
	{
		"id": "banking",
		"label": "Banking",
		"route": "banking",
		"accessRules": {
			"role": [
				"7:administrator"
			]
		},
		"children": [
			{
				"id": "overview",
				"label": "Overview",
				"route": "overview",
				"module": "vwPsp",
				"config": "psps",
				"accessRules": {
					"role": [
						"7:administrator"
					]
				}
			},
			{
				"id": "balance_mutations",
				"label": "Balance mutations",
				"route": "balance_mutations",
				"module": "DataList",
				"config": "balanceMutations",
				"accessRules": {
					"role": [
						"7:administrator"
					]
				}
			},
			{
				"id": "settlements",
				"label": "Settlements",
				"parent": "masterdata",
				"route": "settlements",
				"module": "vwList",
				"config": "settlements",
				"accessRules": {
					"role": [
						"7:administrator"
					]
				}
			},
			{
				"id": "payouts",
				"label": "Payouts",
				"route": "payouts",
				"module": "vwList",
				"config": "payouts",
				"accessRules": {
					"role": [
						"7:administrator"
					]
				},
				"children": [
					{
						"id": "payout",
						"label": "payout",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "payouts",
						"accessRules": {
							"role": [
								"7:administrator"
							]
						}
					}
				]
			},
			{
				"id": "payment-methods",
				"hidden": true,
				"label": "Payment methods",
				"route": "banking/payment_methods"
			}
		]
	},
	{
		"id": "invoices",
		"label": "Invoices",
		"route": "invoices",
		"module": "vwInvoices",
		"config": "invoices",
		"hidden": true
	},
	{
		"id": "users",
		"label": "Users",
		"route": "users",
		"module": "vwInvites",
		"hidden": true, // hidden from menu
		"config": "users",
		"accessRules": {
			"role": [
				"7:administrator"
			]
		}
	}
]
