import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';
import sjefIconEditOff from '@sjefapp/sjeficons/icons/sjefIconEditOff';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import { Collapse, FormGroup, FormLabel, Input } from 'construct-ui';
import m from 'mithril';

import { _t } from '../../lib/i18n';
import sjefInput from './sjefInput';

class sjefTier {
	tier;
	open = false;
	openStates = [];
	edit = false;
    key;

	oninit(vnode) {
		if (vnode.attrs.tier) {
			this.tier = vnode.attrs.tier;
			// Initialize openStates based on the structure of tier.fees
			this.initializeOpenStates();
		}

        if (vnode.attrs.key) {
            this.key = vnode.attrs.key;
        }

        // if not this.tier.threshold. input .focus just like toggleEdit. 
	}

    oncreate(vnode) {
        // Focus the input field for dynamically created instances without a threshold
        if (!this.tier.threshold) {
            this.focusInputField();
        }
    }

    initializeOpenStates() {
        Object.keys(this.tier.fees).forEach((method) => {
            this.openStates[method] = { isOpen: false }; // Top-level state
            Object.keys(this.tier.fees[method]).forEach((category) => {
                // Initialize sub-level state if not already initialized
                if (!this.openStates[method][category]) {
                    this.openStates[method][category] = false;
                }
            });
        });
    }

    focusInputField() {
        requestAnimationFrame(() => {
            const inputSelector = `.tier-${this.key} .readonlyEdit input[type="number"]`;
            const inputElement = document.querySelector(inputSelector);
            if (inputElement) {
                inputElement.focus();
            }
        });
    }

    toggleEdit() {
        this.edit = !this.edit;
        // Optionally refocus when toggling edit mode
        if (this.edit) {
            this.focusInputField();
        }
    }

	view(vnode) {
		if (!this.tier) {
			throw new Error('no tier passed');
		}

		console.log(vnode.attrs.isValid);

		return [
			m(
				'.tier', { 
					class: [this.key ? ('tier-' + this.key) : '', (vnode.attrs.isValid === false) ? 'inValid' : ''].join(' '),
				},
				m(
					'.readonlyEdit',
					// { class: !this.edit ? 'readonly' : '' },
					m('.sjefInputWrapper',
					m('.inputWrapper', 
					m(FormGroup,
						m('label', _t('schema.tiers.quantity')),
						m(Input, {
							type: 'number',
							id: this.key,
							value: this.tier.threshold,
							onchange: (e) => {
								this.tier.threshold = e.target.value;
								m.redraw(); 
							},
							onblur: () => {
								if (typeof vnode.attrs.onValidate === 'function') {
									vnode.attrs.onValidate(this.tier.threshold);
								}
							}
						}),
					)))
					// m(
					// 	'.editToggle',
					// 	{
                    //         onclick: () => this.toggleEdit(),
					// 	},
					// 	m(sjefIconEdit),
                    //     m(sjefIconEditOff)
					// )
				),
				vnode.attrs.tiersLength > 1 ?
				m(sjefIconX, {
					onclick: () => {
						if (typeof vnode.attrs.onRemove === 'function') {
							vnode.attrs.onRemove();
						}
					}
				}) : void(0),
				Object.entries(this.tier.fees).map(([paymentMethod, details]) => {
					return m('.payment-method', [
						m(
							'h5',
							{
								class: this.openStates[paymentMethod].isOpen ? 'active' : '',
								onclick: () => {
									// Toggle top-level state for this payment method
									this.openStates[paymentMethod].isOpen = !this.openStates[paymentMethod].isOpen;
									m.redraw();
								},
							},
							[`${paymentMethod}`, m(sjefIconChevronDown)]
						),
						m(
							Collapse,
							{
								isOpen: this.openStates[paymentMethod].isOpen,
							},
							[
								m(
									'.categories',
									Object.entries(details).map(([category, categoryDetails]) => {
										return m('.category', [
											m(
												'h4',
												{
													class: this.openStates[paymentMethod][category] ? 'active' : '',
													onclick: () => {
														// Toggle state for this category
														this.openStates[paymentMethod][category] = !this.openStates[paymentMethod][category];
														m.redraw();
													},
												},
												[`${category.charAt(0).toUpperCase() + category.slice(1)}`, m(sjefIconChevronDown)]
											),
											m(
												Collapse,
												{
													isOpen: this.openStates[paymentMethod][category],
												},
												[
													m(
														'',
														Object.entries(categoryDetails).map(([feeLevel, feeValues]) => {
															return m('.fee-details', [
																m('h5', `${feeLevel}`),
																m(
																	'.rates',
																	m(FormGroup, [
																		m(FormLabel, { for: `fixed-${feeLevel}` }, 'Fixed Fee'),
																		m(Input, {
																			id: `fixed-${feeLevel}`,
																			value: feeValues.fixed,
																			oninput: (e) => {
																				feeValues.fixed = e.target.value;
																			},
																		}),
																	]),
																	m(FormGroup, [
																		m(FormLabel, { for: `variable-${feeLevel}` }, 'Variable Fee'),
																		m(Input, {
																			id: `variable-${feeLevel}`,
																			value: feeValues.variable,
																			oninput: (e) => {
																				feeValues.variable = e.target.value;
																			},
																		}),
																	])
																),
															]);
														})
													),
												]
											),
										]);
									})
								),
							]
						),
					]);
				})
			),
		];
	}
}

export default sjefTier;
