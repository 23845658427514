const industryCodes = [{
	value: '11',
	label: '11 - Agriculture, forestry, fishing, and hunting',
},
{
	value: '2211',
	label: '2211 - Electric power generation, transmission, and distribution',
},
{
	value: '2212',
	label: '2212 - Natural gas distribution',
},
{
	value: '2213',
	label: '2213 - Water, sewage, and other systems',
},
{
	value: '23',
	label: '23 - Construction &amp; installation',
},
{
	value: '311',
	label: '311 - Food manufacturing',
},
{
	value: '3121B',
	label: '3121B - Non-alcoholic beverage manufacturing',
},
{
	value: '313',
	label: '313 - Textile mills',
},
{
	value: '314',
	label: '314 - Textile product mills',
},
{
	value: '315',
	label: '315 - Apparel manufacturing',
},
{
	value: '316',
	label: '316 - Leather and allied product manufacturing',
},
{
	value: '321',
	label: '321 - Wood product manufacturing',
},
{
	value: '322',
	label: '322 - Paper manufacturing',
},
{
	value: '323',
	label: '323 - Printing and related support activities',
},
{
	value: '3255',
	label: '3255 - Paint, coating, and adhesive manufacturing',
},
{
	value: '326',
	label: '326 - Plastics and rubber products manufacturing',
},
{
	value: '327',
	label: '327 - Nonmetallic mineral product manufacturing',
},
{
	value: '331',
	label: '331 - Primary metal manufacturing',
},
{
	value: '332',
	label: '332 - Fabricated metal product manufacturing',
},
{
	value: '333',
	label: '333 - Machinery manufacturing',
},
{
	value: '334',
	label: '334 - Computer and electronic product manufacturing',
},
{
	value: '335',
	label: '335 - Electrical equipment, appliance, and component manufacturing',
},
{
	value: '336',
	label: '336 - Transportation (equipment) manufacturing',
},
{
	value: '337',
	label: '337 - Furniture and related product manufacturing',
},
{
	value: '339D',
	label: '339D - Other miscellaneous durable goods manufacturing',
},
{
	value: '339E',
	label: '339E - Other miscellaneous nondurable goods manufacturing',
},
{
	value: '4232',
	label: '4232 - Furniture and home furnishing merchant wholesalers',
},
{
	value: '4233',
	label: '4233 - Lumber and other construction materials merchant wholesalers',
},
{
	value: '42341',
	label: '42341 - Photographic equipment and supplies merchant wholesalers',
},
{
	value: '42342',
	label: '42342 - Office equipment merchant wholesalers',
},
{
	value: '42343',
	label: '42343 - Computer and computer peripheral equipment and software merchant wholesalers',
},
{
	value: '42344',
	label: '42344 - Other commercial equipment merchant wholesalers',
},
{
	value: '42349',
	label: '42349 - Other professional equipment and supplies merchant wholesalers',
},
{
	value: '4235',
	label: '4235 - Metal and mineral (except petroleum) merchant wholesalers',
},
{
	value: '4236',
	label: '4236 - Household appliances, electrical, and electronic goods merchant wholesalers',
},
{
	value: '4237',
	label: '4237 - Hardware, plumbing, and heating equipment and supplies merchant wholesalers',
},
{
	value: '4238',
	label: '4238 - Machinery, equipment, and supplies merchant wholesalers',
},
{
	value: '42399',
	label: '42399 - Other miscellaneous durable goods merchant wholesalers',
},
{
	value: '4241',
	label: '4241 - Paper and paper products merchant wholesalers',
},
{
	value: '4243',
	label: '4243 - Apparel, piece goods, and notions merchant wholesalers',
},
{
	value: '4244',
	label: '4244 - Grocery and related products merchant wholesalers',
},
{
	value: '4245',
	label: '4245 - Farm product raw material merchant wholesalers',
},
{
	value: '42491',
	label: '42491 - Farm supplies merchant wholesalers',
},
{
	value: '42492',
	label: '42492 - Book, periodical, and newspaper merchant wholesalers',
},
{
	value: '42493',
	label: "42493 - Flower, nursery stock, and florists' supplies merchant wholesalers",
},
{
	value: '42495',
	label: '42495 - Paint, varnish, and supplies merchant wholesalers',
},
{
	value: '42499',
	label: '42499 - Other miscellaneous nondurable goods merchant wholesalers',
},
{
	value: '42511',
	label: '42511 - Business-to-business electronic markets',
},
{
	value: '42512',
	label: '42512 - Wholesale trade agents and brokers',
},
{
	value: '44121',
	label: '44121 - Recreational vehicle dealers',
},
{
	value: '4412A',
	label: '4412A - Mobile home dealers',
},
{
	value: '4412B',
	label: '4412B - Utility trailer dealers',
},
{
	value: '441222',
	label: '441222 - Boat dealers',
},
{
	value: '441228',
	label: '441228 - Motorcycle, ATV, and all other motor vehicle dealers',
},
{
	value: '442A',
	label: '442A - Furniture stores',
},
{
	value: '442B',
	label: '442B - Home furnishings stores',
},
{
	value: '443141',
	label: '443141 - Household appliance stores',
},
{
	value: '443142',
	label: '443142 - Electronics stores',
},
{
	value: '4431A',
	label: '4431A - Computer software stores',
},
{
	value: '4431B',
	label: '4431B - Telecommunication Equipment and Telephone Stores',
},
{
	value: '44411',
	label: '44411 - Home centers',
},
{
	value: '44412',
	label: '44412 - Paint and wallpaper stores',
},
{
	value: '44413',
	label: '44413 - Hardware stores',
},
{
	value: '44419',
	label: '44419 - Other building material stores',
},
{
	value: '4451',
	label: '4451 - Grocery stores, Supermarkets',
},
{
	value: '4452',
	label: '4452 - Specialty food stores',
},
{
	value: '311811',
	label: '311811 - Bakeries',
},
{
	value: '44612',
	label: '44612 - Cosmetics, beauty supplies, and perfume stores',
},
{
	value: '44613',
	label: '44613 - Optical goods store',
},
{
	value: '446199',
	label: '446199 - All other health and personal care stores',
},
{
	value: '447',
	label: '447 - Gasoline stations',
},
{
	value: '44811',
	label: "44811 - Men's clothing stores",
},
{
	value: '44812',
	label: "44812 - Women's clothing stores",
},
{
	value: '4481A',
	label: "4481A - Men's and Women's clothing stores",
},
{
	value: '44813',
	label: "44813 - Children's and Infants' clothing stores",
},
{
	value: '44814',
	label: '44814 - Family clothing stores',
},
{
	value: '4481B',
	label: '4481B - Sports and riding apparel stores',
},
{
	value: '44819',
	label: '44819 - Other Clothing (accessories) stores',
},
{
	value: '4482',
	label: '4482 - Shoe stores',
},
{
	value: '44832',
	label: '44832 - Luggage and leather goods stores',
},
{
	value: '45111',
	label: '45111 - Sporting goods stores',
},
{
	value: '45112',
	label: '45112 - Hobby, toy, and game stores',
},
{
	value: '45113',
	label: '45113 - Sewing, needlework, and piece Goods stores',
},
{
	value: '45114',
	label: '45114 - Musical instrument and supplies stores',
},
{
	value: '4512',
	label: '4512 - Book stores and news dealers',
},
{
	value: '4522',
	label: '4522 - Department stores',
},
{
	value: '4523',
	label: '4523 - General merchandise stores, including warehouse clubs and supercenters',
},
{
	value: '4531',
	label: '4531 - Florists',
},
{
	value: '45321',
	label: '45321 - Office supplies and stationery stores',
},
{
	value: '45322',
	label: '45322 - Gift, novelty, and souvenir stores',
},
{
	value: '4533',
	label: '4533 - Used merchandise stores',
},
{
	value: '45391',
	label: '45391 - Pet and Pet Supplies stores',
},
{
	value: '45393',
	label: '45393 - Manufactured (mobile) home dealers',
},
{
	value: '4539H',
	label: '4539H - Discount stores',
},
{
	value: '4539I',
	label: '4539I - Duty-free stores',
},
{
	value: '4539J',
	label: '4539J - Second hand stores',
},
{
	value: '4539K',
	label: '4539K - Antique stores',
},
{
	value: '453998',
	label: '453998 - All other miscellaneous store retailers',
},
{
	value: '4542A',
	label: '4542A - Vending machines (Food)',
},
{
	value: '4542B',
	label: '4542B - Vending machines (Non-Food)',
},
{
	value: '481B',
	label: '481B - Other air transportation',
},
{
	value: '482A',
	label: '482A - Passenger rail transportation',
},
{
	value: '482B',
	label: '482B - Freight rail transportation',
},
{
	value: '483B',
	label: '483B - Ferries',
},
{
	value: '483C',
	label: '483C - Water Freight',
},
{
	value: '484',
	label: '484 - Truck transportation',
},
{
	value: '4851',
	label: '4851 - Urban transit systems',
},
{
	value: '4852',
	label: '4852 - Interurban and rural bus transportation',
},
{
	value: '4853',
	label: '4853 - Taxi and limousine service',
},
{
	value: '4854',
	label: '4854 - School and employee bus transportation',
},
{
	value: '4855',
	label: '4855 - Charter bus industry',
},
{
	value: '4859',
	label: '4859 - Other transit and ground passenger transportation',
},
{
	value: '487',
	label: '487 - Scenic and sightseeing transportation',
},
{
	value: '4881A',
	label: '4881A - Airports, airport terminals, flying fields',
},
{
	value: '48819',
	label: '48819 - Other support activities for air transportation',
},
{
	value: '4882',
	label: '4882 - Support activities for rail transportation',
},
{
	value: '4883',
	label: '4883 - Support Activities for water Transportation',
},
{
	value: '48841',
	label: '48841 - Motor vehicle towing',
},
{
	value: '4884A',
	label: '4884A - Bridge and road fees, tolls',
},
{
	value: '4884B',
	label: '4884B - Electric vehicle charging',
},
{
	value: '48849',
	label: '48849 - Other support activities for road transportation',
},
{
	value: '4889',
	label: '4889 - Other support activities for transportation',
},
{
	value: '491',
	label: '491 - Postal service',
},
{
	value: '492',
	label: '492 - Couriers and messengers',
},
{
	value: '493',
	label: '493 - Warehousing and storage',
},
{
	value: '5111',
	label: '5111 - Newspaper, periodical, book, and directory publishers (except internet)',
},
{
	value: '5112A',
	label: '5112A - Digital goods - audiovisual media including books, movies, and music',
},
{
	value: '5112B',
	label: '5112B - Digital goods - games',
},
{
	value: '51121',
	label: '51121 - Digital goods - software applications',
},
{
	value: '51211',
	label: '51211 - Motion picture and video production',
},
{
	value: '51212',
	label: '51212 - Motion picture and video distribution',
},
{
	value: '51213',
	label: '51213 - Motion picture and video exhibition',
},
{
	value: '5122',
	label: '5122 - Sound recording industries',
},
{
	value: '515',
	label: '515 - Broadcasting (except internet)',
},
{
	value: '517311',
	label: '517311 - Wired and Wireless Telecommunications Carriers',
},
{
	value: '51731B',
	label: '51731B - Wireless telecommunications carriers - Prepaid',
},
{
	value: '51731D',
	label: '51731D - Other wireless telecommunications carriers',
},
{
	value: '5179',
	label: '5179 - Other telecommunications',
},
{
	value: '517911',
	label: '517911 - Telecommunications Resellers',
},
{
	value: '51911',
	label: '51911 - News Agency',
},
{
	value: '51913',
	label: '51913 - Internet publishing and broadcasting and web search portals',
},
{
	value: '5191A',
	label: '5191A - All other information services',
},
{
	value: '53211',
	label: '53211 - Passenger car rental and leasing',
},
{
	value: '53212',
	label: '53212 - Truck, and utility trailer rental and leasing',
},
{
	value: '5321B',
	label: '5321B - Motor home and recreational vehicle rental',
},
{
	value: '53221',
	label: '53221 - Consumer electronics and appliances rental',
},
{
	value: '532281',
	label: '532281 - Formal wear and costume rental',
},
{
	value: '532282',
	label: '532282 - Video tape and disc rental',
},
{
	value: '532283',
	label: '532283 - Home health equipment rental',
},
{
	value: '532284',
	label: '532284 - Recreational goods rental',
},
{
	value: '532289',
	label: '532289 - All other consumer goods rental',
},
{
	value: '5324',
	label: '5324 - Commercial, industrial machinery and equipment rental and leasing',
},
{
	value: '5411A',
	label: '5411A - Lawyers (except bankruptcy)',
},
{
	value: '541211',
	label: '541211 - Offices of certified public accountants',
},
{
	value: '541213',
	label: '541213 - Tax preparation services',
},
{
	value: '5413',
	label: '5413 - Architectural, engineering, and related services',
},
{
	value: '5414',
	label: '5414 - Specialized design services',
},
{
	value: '5415',
	label: '5415 - Computer systems design and related services',
},
{
	value: '5416',
	label: '5416 - Management, scientific, and technical consulting services',
},
{
	value: '54171',
	label: '54171 - Research and development in the Physical, Engineering, and Life Sciences',
},
{
	value: '5417B',
	label: '5417B - Other research and development in the Social Sciences and Humanities',
},
{
	value: '5418',
	label: '5418 - Advertising, public relations, and related services',
},
{
	value: '54192',
	label: '54192 - Photographic services',
},
{
	value: '54194',
	label: '54194 - Veterinary services',
},
{
	value: '54199',
	label: '54199 - All other professional, scientific, and technical services',
},
{
	value: '55',
	label: '55 - Management of companies and enterprises',
},
{
	value: '5611',
	label: '5611 - Office administrative services',
},
{
	value: '5612',
	label: '5612 - Facilities support services',
},
{
	value: '5613',
	label: '5613 - Employment services',
},
{
	value: '56141',
	label: '56141 - Document preparation services',
},
{
	value: '56142',
	label: '56142 - Telephone call centers',
},
{
	value: '56143',
	label: '56143 - Business service centers',
},
{
	value: '56149',
	label: '56149 - Other business support services',
},
{
	value: '5616A',
	label: '5616A - Security services',
},
{
	value: '56171',
	label: '56171 - Exterminating and pest control services',
},
{
	value: '56172',
	label: '56172 - Janitorial services',
},
{
	value: '56173',
	label: '56173 - Landscaping services',
},
{
	value: '56174',
	label: '56174 - Carpet and upholstery cleaning services',
},
{
	value: '56179',
	label: '56179 - Other services to buildings and dwellings',
},
{
	value: '5619',
	label: '5619 - Other support services',
},
{
	value: '562',
	label: '562 - Waste management and remediation services',
},
{
	value: '6111',
	label: '6111 - Elementary and secondary schools',
},
{
	value: '6112',
	label: '6112 - Junior colleges',
},
{
	value: '6113',
	label: '6113 - Colleges, universities, and professional schools',
},
{
	value: '6114',
	label: '6114 - Business schools, and computer and management training',
},
{
	value: '6115',
	label: '6115 - Technical and trade schools',
},
{
	value: '6116A',
	label: '6116A - Dance Schools',
},
{
	value: '61161',
	label: '61161 - Fine arts schools',
},
{
	value: '61162',
	label: '61162 - Sports and recreation instruction',
},
{
	value: '61163',
	label: '61163 - Language schools',
},
{
	value: '61169',
	label: '61169 - All other schools and instruction',
},
{
	value: '6214',
	label: '6214 - Outpatient care centers',
},
{
	value: '6219',
	label: '6219 - Other ambulatory health care services',
},
{
	value: '6241',
	label: '6241 - Individual and family services',
},
{
	value: '6242',
	label: '6242 - Community food and housing, and emergency and other relief services',
},
{
	value: '6243',
	label: '6243 - Vocational rehabilitation services',
},
{
	value: '6244',
	label: '6244 - Child day care services',
},
{
	value: '624A',
	label: '624A - All other social services',
},
{
	value: '7111',
	label: '7111 - Performing arts companies',
},
{
	value: '7112',
	label: '7112 - Spectator sports',
},
{
	value: '7113',
	label: '7113 - Promoters of performing arts, sports, and similar events',
},
{
	value: '7114',
	label: '7114 - Agents and managers for artists, athletes, entertainers, and other public figures',
},
{
	value: '7115',
	label: '7115 - Independent artists, writers, and performers',
},
{
	value: '712',
	label: '712 - Museums, historical sites, and similar institutions',
},
{
	value: '71311',
	label: '71311 - Amusement and theme parks',
},
{
	value: '71312',
	label: '71312 - Amusement arcades',
},
{
	value: '7139A',
	label: '7139A - Public golf courses',
},
{
	value: '7139B',
	label: '7139B - Private golf courses and country clubs',
},
{
	value: '71392',
	label: '71392 - Skiing facilities',
},
{
	value: '71393',
	label: '71393 - Marinas',
},
{
	value: '71394',
	label: '71394 - Fitness and recreational sports centers',
},
{
	value: '71395',
	label: '71395 - Bowling centers',
},
{
	value: '7139C',
	label: '7139C - All other amusement and recreation industries',
},
{
	value: '7139D',
	label: '7139D - Billiard and pool establishments',
},
{
	value: '7139E',
	label: '7139E - Aquariums, seaquariums, dolphinariums, and zoos',
},
{
	value: '72111',
	label: '72111 - Hotels (except casino hotels) and motels',
},
{
	value: '7211B',
	label: '7211B - Other traveler accommodation',
},
{
	value: '7212',
	label: '7212 - Recreational vehicle parks and recreational camps',
},
{
	value: '7213',
	label: "7213 - Rooming and boarding houses, dormitories, and workers' Camps",
},
{
	value: '7223',
	label: '7223 - Special food services',
},
{
	value: '722511',
	label: '722511 - Full-service restaurants',
},
{
	value: '722513',
	label: '722513 - Limited-service restaurants (Fast food restaurants)',
},
{
	value: '722514',
	label: '722514 - Cafeterias, grill buffets, and buffets',
},
{
	value: '722515',
	label: '722515 - Snacks and nonalcoholic beverage bars',
},
{
	value: '8111',
	label: '8111 - Automotive repair and maintenance',
},
{
	value: '8112',
	label: '8112 - Electronic and precision equipment repair and maintenance (including computers)',
},
{
	value: '8113',
	label: '8113 - Commercial and industrial machinery and equipment (except automotive and electronic) repair and maintenance',
},
{
	value: '8114',
	label: '8114 - Personal and household goods repair and maintenance',
},
{
	value: '81211',
	label: '81211 - Hair, nail, and skin care services',
},
{
	value: '8121A',
	label: '8121A - Massage parlors',
},
{
	value: '8121B',
	label: '8121B - Health and beauty spas',
},
{
	value: '8122',
	label: '8122 - Death care services',
},
{
	value: '8123',
	label: '8123 - Drycleaning and Laundry services',
},
{
	value: '8129E',
	label: '8129E - Other personal services',
},
{
	value: '8134',
	label: '8134 - Civic and social organizations',
},
{
	value: '81391',
	label: '81391 - Business associations',
},
{
	value: '81392',
	label: '81392 - Professional organizations',
},
{
	value: '81393',
	label: '81393 - Labor unions and similar labelor organizations',
},
{
	value: '81399',
	label: '81399 - Other similar organizations (except business, professional, labelor, and political organizations)',
},
];
export default industryCodes;
