import m from 'mithril';
import { Checkbox, Input, Select, TextArea, RadioGroup } from 'construct-ui';

import sjefSelectAsync from '../../views/components/sjefSelectAsync';
import sjefSelectCountry from '../../views/components/sjefSelectCountry';
import sjefInputImage from '../../views/components/sjefInputImage';
import sjefInputTranslations from '../../views/components/sjefInputTranslations';
import countryCodes from '../../lib/countries.js';
import { _t } from '../../lib/i18n';
import sjefInput from '../../views/components/sjefInput.js';
import sjefCheckbox from '../../views/components/sjefCheckbox.js';
import { sleep } from '../../lib/helpers.js';

/**
 * @class
 * @classdesc Base class for form record views.
 */
const FormInputFieldsTrait = {
	referenceFields: '',

	/**
	 * Render input field based on its type
	 * @param {Object} field - The field object
	 * @return {Object}
	 */
	renderInputField(field) {
		// set formInputType for generic fields if not specified
		if (!field.formInputType) {
			if (field.type == 'string') {
				field.formInputType = 'text';
			} else if (field.type == 'integer') {
				field.formInputType = 'number';
			} else if (field.type == 'boolean') {
				field.formInputType = 'checkbox';
			}
		}

		// check correct field types on init
		this.form[field.name] = this.parseValue(field.type, this.form[field.name]);

		if (
			['text', 'password', 'number', '', 'email', 'url', 'tel', 'range', 'month', 'week', 'time', 'color', 'file', 'hidden'].includes(
				field.formInputType
			)
		) {
			return [
				m(sjefInput, {
					class: field.type,
					id: `${field.name}`,
					placeholder: field.placeholder,
					readonly: field.readonly || false,
					label: _t(`_.fields.${field.name}`),
					icon: field.icon,
					value: this.parseValue(field.type, this.form[field.name] || field.defaultValue || ''),
					type: field.formInputType,
					intent: this.formErrors[field.name] ? 'negative' : 'none',

					onChange: (newValue) => { // Pass the onchange handler to sjefInput
						if ((newValue instanceof Event) === false) {
							if (field.formInputType == 'number') {

								// Ensure newValue is a string before trying to replace characters
								if (newValue || newValue === 0) { // Check for newValue being 0 as well
									newValue = String(newValue).replace(',', '.');
									this.setValue({
										field: `${field.name}`,
										value: this.parseValue(field.type, newValue),
										valueOriginal: this.record[field.name],
									});
								}
							} else {
								this.setValue({
									field: `${field.name}`,
									value: this.parseValue(field.type, newValue),
									valueOriginal: this.record[field.name],
								});
							}
						}
					},
				}),
				field.formTranslations
					? m(sjefInputTranslations, {
						form: this.form,
						fieldName: field.formTranslations,
						translateField: field.name,
					})
					: '',
			];
			// } else if (field.formInputType === 'translation') {
			// 	return m(sjefInputTranslations, {
			// 		form: this.form,
			// 		fieldName: field.name,
			// 		translateField: field.formTranslateField,
			// 	});
			// } else if (field.formInputType === 'number') {
			// 	return [
			// 		m(Input, {
			// 			id: `${field.name}`,
			// 			value: this.form[field.name] || field.defaultValue || '',
			// 			type: field.formInputType,
			// 			intent: this.formErrors[field.name] ? 'negative' : 'none',
			// 			onblur: (ev) => {
			// 				ev.currentTarget.value = ev.currentTarget.value.replace(',', '.');
			// 				this.setValue({
			// 					field: `${field.name}`,
			// 					value: this.parseValue(field.type, ev.currentTarget.value),
			// 					valueOriginal: this.record[field.name],
			// 				});
			// 			},
			// 		}),
			// 	];
		} else if (field.formInputType === 'datetime-local') {
			return [
				m(sjefInput, {
					id: `${field.name}`,
					label: `${field.name}`,
					icon: field.icon,
					readonly: field.readonly || false,
					value: this.parseValue(field.type, this.form[field.name] || field.defaultValue || '').substring(0, 19),
					type: field.formInputType,
					intent: this.formErrors[field.name] ? 'negative' : 'none',
					onChange: (newValue) => {
						if ((newValue instanceof Event) === false) {
							const parsedValue = this.parseValue(field.type, newValue);

							this.setValue({
								field: `${field.name}`,
								value: parsedValue ? `${parsedValue}Z` : null,
								valueOriginal: this.record[field.name],
							});
						}
					},
				}),
			];
			// } else if (field.formInputType === 'translation') {
			// 	return m(sjefInputTranslations, {
			// 		form: this.form,
			// 		fieldName: field.name,
			// 		translateField: field.formTranslateField,
			// 	});
		} else if (field.formInputType === 'checkbox') {
			return m(sjefCheckbox, {
				id: `${field.name}`,
				label: `${field.name}`,
				readonly: field.readonly || false,
				defaultChecked: this.form[field.name] === true, // || field.defaultValue || false) === true,
				checked: this.form[field.name] === true, // || field.defaultValue || false) === true,
				onChange: () => {
					this.setValue({
						field: `${field.name}`,
						value: !this.form[field.name],
						valueOriginal: this.record[field.name],
					}, 'checkbox');
				},
			});
		} else if (field.formInputType === 'radio') {
			if (!field.options || field.options.length === 0) {
				alert(`${field.name} has no options in the this.config`);
			}

			return m(RadioGroup, {
				name: `${field.name}`,
				options: field.options,
				readonly: field.readonly || false,
				selectedValue: this.form[field.name] || field.defaultValue || '',
				onchange: (value) => {
					this.setValue({
						field: `${field.name}`,
						value,
						valueOriginal: this.record[field.name],
					});
				},
			});
		} else if (field.formInputType === 'select') {
			if (!field.options || field.options.length === 0) {
				alert(`${field.name} has no options in the this.config`);
			}

			return [
				m(
					'.sjefInputWrapper.selectStyle',
					m(
						'.inputWrapper',
						{
							class: this.form[field.name] ? 'no-placeholder' : void 0,
						},
						field.name && m('label.truncate', { for: field.name }, field.name),
						m('.inputRelative',
							field.icon ? m('span.icon', m(field.icon)) : void (0),
							m(Select, {
								id: `${field.name}`,
								class: 'active-state-elem',
								readonly: field.readonly || false,
								options: field.options,
								defaultValue: this.form[field.name] || field.defaultValue || '',
								onchange: (event) => {
									this.setValue({
										field: `${field.name}`,
										value: event.currentTarget.value,
										valueOriginal: this.record[field.name],
									});
								},
							}))
					)
				),
			];
		} else if (field.formInputType === 'countrySelect' || field.formInputType === 'phoneNumberCountryCodeSelect') {
			let countries;

			if (field.formInputType === 'countrySelect') {
				countries = countryCodes.map((country) => ({
					value: country.code,
					label: _t(`countries.${country.code}`, { defaultValue: country.name }),
				}));
			}

			if (field.formInputType === 'phoneNumberCountryCodeSelect') {
				countries = countryCodes.map((country) => ({
					value: country.code,
					label: _t(`countries.${country.code}`) + ' (' + country.countryCode + ')',
				}));
			}

			// console.log(this.form[field.name])

			return [
				m(
					'.sjefInputWrapper.selectStyle',
					m('.inputWrapper', {
						class: this.form[field.name] ? 'no-placeholder' : void 0,
					}, [
						m('label.truncate', _t(`_.fields.${field.name}`)),
						m('.inputRelative',
							field.icon ? m('span.icon', m(field.icon)) : void (0),
							field.placeholder ? m('.placeholder', field.placeholder) : void (0),
							m(Select, {
								class: 'active-state-elem',
								id: `${field.name}`,
								// placeholder: field.placeholder,
								readonly: field.readonly || false,
								options: countries, // Pass the transformed countries array
								value: this.form[field.name] || field.defaultValue || '',
								onchange: (event) => {
									this.setValue({
										field: `${field.name}`,
										value: event.currentTarget.value,
										valueOriginal: this.record[field.name],
									});
								},
							}),
						)
					])
				),
			];
		} else if (field.formInputType === 'selectAsync') {
			if (!field.asyncEndpoint || !field.asyncField) {
				alert(`${field.name} asyncEndpoint or asyncField is missing`);
			}

			return m(sjefSelectAsync, {
				id: `${field.name}`,
				field: field.asyncField,
				label: field.name,
				readonly: field.readonly || false,
				endpoint: field.asyncEndpoint,
				filterOn: field.asyncFilterOn,
				defaultLabel: this.record[field.asyncFieldLabel] || '',
				onSelect: (data) => this.setValue(data, 'string'),
			});
		} else if (field.formInputType === 'textarea') {
			return m(TextArea, {
				id: `${field.name}`,
				value: this.form[field.name],
				intent: this.formErrors[field.name] ? 'negative' : 'none',
				readonly: field.readonly || false,
				onchange: (ev) => {
					this.setValue({
						field: `${field.name}`,
						value: this.parseValue(field.type, ev.currentTarget.value),
						valueOriginal: this.record[field.name],
					});
				},
			});
		} else if (field.formInputType === 'selectCountry') {
			return m(sjefSelectCountry, {
				id: `${field.name}`,
				value: this.form[field.name],
				intent: this.formErrors[field.name] ? 'negative' : 'none',
				readonly: field.readonly || false,

				onSelectCountry: (value) => {
					if ((value instanceof Event) === false) {
						this.setValue({
							field: `${field.name}`,
							value: value.code,
							valueOriginal: this.record[field.name],
						});
					}
				},
			});
		} else if (field.formInputType === 'image') {
			if (!this.record.id) {
				return;
			}

			return m(sjefInputImage, {
				endpoint: field.endpoint,
				readonly: field.readonly || false,
				recordId: this.record.id,
				nameImageField: field.name,
				directory: `${this.config.moduleName}/${this.record.id}`,
				image: this.record[field.name] || '',
			});
		}
	},

	// TODO: refactor
	/**
	 * Parse the value to its type
	 * @param {string} type
	 * @param {string} value
	 * @return {Object}
	 */
	parseValue(type, value) {
		// if (type === 'string' && value !== null) {
		// 	console.log(type, value, value.toString());
		// 	return value.toString();
		// } else


		if (type === 'integer') {
			const parsedValue = parseInt(value);

			if (!isNaN(parsedValue)) {
				return parsedValue;
			}
			return null;
		} else if (type === 'boolean' && value !== null) {
			if (typeof value === 'string') {
				return value.toLowerCase() === 'true';
			} else if (typeof value === 'boolean') {
				return value;
			}
		} else if (type === 'number' && value !== null) {
			const parsedValue = parseFloat(value);

			console.log(parsedValue)

			return parsedValue;
		} else if (type === 'date' && value !== null) {
			console.log('parseValue', type, value);
		} else if (type === 'string' && value != null) {

			return value.toString();
		}
		return value;
	},
};

export default FormInputFieldsTrait;
