/* eslint:disable */
import m from 'mithril';
import { goToHref } from '../../lib/helpers';
import sjefIconLoader2 from '@sjefapp/sjeficons/icons/sjefIconLoader2';
import sjefIconArrowBigUpLine from '@sjefapp/sjeficons/icons/sjefIconArrowBigUpLine';
import app from '../../app';

// This function return the correct format if either a function or an object is given.
let processInlineIcon = (icon, type = 'iconElement') => {
	if (typeof icon === 'function') {
		return m(icon, { classes: [type] });
	} else if (typeof icon === 'object') {
		let attributes = icon.attrs;
		attributes.classes = [type];

		return m(icon.tag, attributes);
	}

	if (icon) {
		return m(`.sjefIcon.custom.${type}`, icon);
	}

	return void 0;
};

let capitalize = (word) => {
	let capitalized = word[0].toUpperCase() + word.substring(1);

	return capitalized;
};


const buttonLayoutHandler = (buttonLayout) => {
	const layouts = buttonLayout.split(' ');
	const layoutClasses = layouts.map((layout) => {
		return 'btn' + capitalize(layout)
	})
	return layoutClasses.join(' ');
};

class sjefButton {
	tag = 'button';
	isLoading = false; // Initialize loading state
	loadingIcon = sjefIconLoader2;

	view(vnode) {
		const {
			classes = [],
			sublabel = '',
			label = '',
			type = 'button',
			key = false,
			buttonLayout = '',
			clickState = true,
			href = '',
			disabled = false,
			iconLeft,
			hideStatuses = [],
			iconRight,
			color = 'currentColor',
			onclick = void 0,
			...htmlAttrs
		} = vnode.attrs;

		const self = this; // Store a reference to the instance

		if (!onclick) {
			classes.push('noHover');
		}

		if (onclick && href) {
			throw new Error("sjefButton component cannot have both 'url' AND 'onclick' properties");
		}

		if (buttonLayout == 'a') {
			this.tag = 'a';
		}

		var htmlAttrsClass = htmlAttrs.class || void 0;
		delete htmlAttrs.class;

		const handleButtonClick = (e) => {
			e.preventDefault(); // Prevent default if necessary, especially if this button could submit a form
			if (!this.isLoading) {
				self.isLoading = true;
				console.log("Loading started"); // Debugging output
				vnode.dom.classList.add('loading');
				m.redraw();

				const result = onclick ? onclick() : href ? goToHref(e, href) : void 0;

				// console.log("Received result:", result); // Check what result is received

				if (result instanceof Promise) {
					result.then(() => {
						// console.log("Promise resolved"); // Debug success
					}).catch((error) => {
						// console.error("Promise error:", error); // Properly log errors
						app.toast('problem')

					}).finally(() => {
						self.isLoading = false;
						console.log("Loading finished"); // Confirm loading finishes
						vnode.dom.classList.remove('loading');
						m.redraw();
					});
				} else {
					self.isLoading = false;
					vnode.dom.classList.remove('loading');
					m.redraw();
				}
			}
		};

		return m(
			this.tag + '.sjefButton.iconElement',
			{
				type: type,
				key: key,
				onclick: handleButtonClick,
				class: [
					htmlAttrsClass,
					clickState !== false ? 'active-state-elem' : '',
					disabled ? 'disabled' : void 0,
					iconLeft ? 'hasIconLeft hasIcon' : void 0,
					iconRight ? 'hasIconRight hasIcon' : void 0,
					buttonLayout ? buttonLayoutHandler(buttonLayout) : void 0,
					...classes,
				].join(' '),
				...htmlAttrs,
			},
			iconLeft && this.isLoading ? processInlineIcon(self.loadingIcon, 'iconLeft iconLoading') :
				iconLeft ? processInlineIcon(self.loadingIcon, 'iconLeft iconLoading') : void 0,
			processInlineIcon(iconLeft, 'iconLeft'),
			sublabel ? m('span', sublabel) : null,
			buttonLayout == 'fieldEdit' ? m(sjefIconArrowBigUpLine) : m('span', label),
			iconRight && !iconLeft && this.isLoading ? processInlineIcon(self.loadingIcon, 'iconRight iconLoading') :
				!iconRight && !iconLeft && this.isLoading ? processInlineIcon(self.loadingIcon, 'iconRight iconLoading') :
					iconRight ? processInlineIcon(iconRight, 'iconRight') : void (0),
			// iconRight ? processInlineIcon(self.loadingIcon, 'iconRight iconLoading') : void 0
		);
	}
}

export default sjefButton;


