import m from 'mithril';
const logoSrc = new URL('../../assets/img/sjef_logo.png', import.meta.url).href;
import { Form, FormGroup, FormLabel, Input } from 'construct-ui';
import sjefButton from '../components/sjefButton';
import app from '../../app';

let registerToken = false;
let password = '';
let repeatPassword = '';
const errorMessages = [];

const validatePassword = () => {
	errorMessages.length = 0;

	if (password !== repeatPassword) {
		errorMessages.push('Passwords do not match.');
	}

	if (password.length < 8) {
		errorMessages.push('Must be at least 8 characters long.');
	}
	if (!/(?=.*[a-z])/.test(password)) {
		errorMessages.push('Must contain at least one lowercase letter.');
	}
	if (!/(?=.*[A-Z])/.test(password)) {
		errorMessages.push('Must contain at least one uppercase letter.');
	}
	if (!/(?=.*\d)/.test(password)) {
		errorMessages.push('Must contain at least one number.');
	}
	if (!/(?=.*[@$!%*?&])/.test(password)) {
		errorMessages.push('Must contain at least one special character (@, $, !, %, *, ?, &).');
	}

	if (errorMessages.length > 0) {
		m.redraw();
		return false;
	}

	return true;
};

const registerUser = async () => {
	if (!validatePassword()) {
		return;
	}

	const result = await app.post('/auth/register', { password: password, registerToken: registerToken });

	if (!result.success) {
		errorMessages.push(result.message);
		m.redraw();
		return;
	}

	m.route.set('/');
};

const register = {
	secure: false,
	menu: false,
	oninit: ({ attrs }) => {
		if (!attrs.token || app.isAuthenticated === true) {
			m.route.set('/');
		}

		registerToken = attrs.token;
	},
	view: () => {
		return m('.login', [
			m(
				'.homePageInner',
				m('', m('img', { src: logoSrc })),
				m(
					'',
					m(
						Form,
						{
							align: 'top',
							justify: 'space-between',
						},
						[
							m('h1', 'Registreren'),
							errorMessages.length > 0
								? m(
										'div',
										{ style: 'color: red;' },
										m(
											'ul',
											errorMessages.map((message) => m('li', message))
										)
								  )
								: null,
							m(FormGroup, [
								m(FormLabel, 'Wachtwoord'),
								m(Input, {
									type: 'password',
									onchange: (ev) => {
										password = ev.target.value;
									},
									onkeypress: async (ev) => {
										if (ev.keyCode === 13 || ev.which === 13) {
											// Enter key
											ev.preventDefault();
										}
									},
								}),
							]),
							m(FormGroup, [
								m(FormLabel, 'Herhaal wachtwoord'),
								m(Input, {
									type: 'password',
									onchange: (ev) => {
										repeatPassword = ev.target.value;
									},
									onkeypress: async (ev) => {
										if (ev.keyCode === 13 || ev.which === 13) {
											// Enter key
											ev.preventDefault();
										}
									},
								}),
							]),

							m(sjefButton, {
								label: 'Registreren',
								buttonLayout: 'filled large',
								onclick: async () => await registerUser(),
							}),
						]
					)
				)
			),
		]);
	},
};

export default register;
