const moduleName = 'psps';
const baseRoute = '/psps';
const endpoint = '/balanceplatform/me/details';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'read',
			},
		];

		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'uuid',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:uuid`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
					},
					{
						name: 'code',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:uuid`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'amount',
						type: 'number',
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'currency',
						type: 'string',
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'estimatedArrivalTime',
						type: 'string',
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'status',
						type: 'string',
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'reason',
						type: 'string',
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'processedAt',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
				],
			},
		];
	}
}
