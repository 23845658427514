
/* eslint:disable */
import m from 'mithril';

class sjefIconPrison {
    view({ attrs: { classes = [], size = "24", strokeWidth = "2", color = "currentColor", fill = "none", onclick = void(0), ...htmlAttrs  }}){
        return m('.sjefIcon.sjefIconPrison',{
                onclick,
                class  : ['icon-sjefIcon2Fa', ...classes].join(" "),
                ...htmlAttrs
            },
            m('svg[xmlns=http://www.w3.org/2000/svg].icon-svg', {
                width  : size || "24",
                height : size || "24",
                stroke : color || "currentColor",
                fill   : fill || "none",
                "stroke-width": strokeWidth || "2",
                "viewBox": "0 0 24 24",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            }, m.trust('<path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 4v16" /><path d="M14 4v16" /><path d="M6 4v5" /><path d="M6 15v5" /><path d="M10 4v5" /><path d="M11 9h-6v6h6z" /><path d="M10 15v5" /><path d="M8 12h-.01" />'))
        )
    }
}
export default sjefIconPrison;

