import m from 'mithril';

class IconTitle {
	view({ attrs }) {
		return m('.iconTitle', [
			m('.icon', attrs.icon),
			m('',
				m('h2', attrs.title),
				m('h6', attrs.subTitle)
			)
		]);
	}
}

export default IconTitle;