import app from "../app";
import tableStore from "../lib/tableStore";
import sjefButton from "../views/components/sjefButton";
import m from 'mithril';
import sjefModal from "../views/components/sjefModal";
import { _t } from "../lib/i18n";
import { Form } from "construct-ui";
import sjefInput from "../views/components/sjefInput";
import sjefIconLocationBroken from "@sjefapp/sjeficons/icons/sjefIconLocationBroken";
import sjefIconDoor from "@sjefapp/sjeficons/icons/sjefIconDoor";
import sjefIconLock from "@sjefapp/sjeficons/icons/sjefIconLock";
import sjefIconBarcode from "@sjefapp/sjeficons/icons/sjefIconBarcode";
import sjefIconResize from "@sjefapp/sjeficons/icons/sjefIconResize";
import sjefIconCheck from "@sjefapp/sjeficons/icons/sjefIconCheck";
import sjefIconX from "@sjefapp/sjeficons/icons/sjefIconX";


const moduleName = 'maintenance';
const baseRoute = '/lockermanagement/maintenance';
const endpoint = '/lockermanagement/maintenance';
// const endpoint = 'http://127.0.0.1:3000/areas';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => false,
			canUpdate: () => true,
			canDelete: () => false,
		};
		this.formButtons = [
			{
				component: sjefModal,
				componentAttrs: (record) => ({
					buttonLabel: _t('_.buttons.complete'),
					buttonIconLeft: m(sjefIconCheck),
					buttonHideStatuses: ['canceled', 'completed'],
					buttonLayout: 'ghost',
					buttonConfirm: _t('_.buttons.complete'),
					modalTitle: _t('_.labels.maintenanceComplete'),
					modalContent: _t('_.labels.maintenanceCompleteContent'),
					onSubmit: async () => {
						if (record) {
							const completeResult = await app.post(`/lockermanagement/maintenance/${record.id}/complete`);

							if (completeResult.success) {
								app.toast('success');
							}

							m.redraw()
						}
					},
				})
			},
			{
				component: sjefModal,
				componentAttrs: (record) => ({
					buttonHideStatuses: ['canceled', 'completed'],
					buttonIconLeft: m(sjefIconX),
					buttonLabel: _t('_.buttons.cancel'),
					buttonLayout: 'ghost',
					modalTitle: _t('_.labels.maintenanceCancel'),
					modalContent: _t('_.labels.maintenanceCancelContent'),
					onSubmit: async () => {
						console.log(record)

						if (record) {
							const cancelResult = await app.post(`/lockermanagement/maintenance/${record.id}/cancel`);

							if (cancelResult.success) {
								app.toast('success');
							}

							m.redraw()
						}
					},
				})
			}
		];
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						class: 'width-50',
						type: 'integer',
						formInputType: 'number',
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						filter: false,
					},
					{
						name: 'cabinetId',
						type: 'integer',
						formInputType: 'text',
						icon: sjefIconDoor,
						class: 'width-50',
						formShow: true,
						readonly: true,

						// link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'cabinetNumber',
						type: 'string',
						icon: sjefIconDoor,
						readonly: true,

						// formInputType: 'number',
						formShow: true,
						// link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'lockerId',
						type: 'integer',
						icon: sjefIconLock,
						formInputType: 'number',
						class: 'width-50',
						formShow: true,
						popoverFilterType: 'range',
						readonly: true,

						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'lockerNumber',
						icon: sjefIconLock,
						type: 'string',
						formInputType: 'text',
						formShow: true,
						popoverFilterType: 'range',
						readonly: true,

						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'lockerSerialNumber',
						type: 'string',
						icon: sjefIconBarcode,
						formShow: true,
						readonly: true,
						class: 'width-50',
						// link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'lockerType',
						icon: sjefIconResize,
						type: 'string',
						formInputType: 'select',
						options: ['medium', 'large'],
						formShow: true,
						readonly: true,
						advancedFilterType: 'select',
						popoverFilterType: 'select',
						// link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						minLength: 1,
						nullable: false,
						required: true,
					},
					{
						name: 'type',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'active',
						type: 'boolean',
						popoverFilterType: 'select',
						popoverFilterOptions: [true, false],
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						required: true,
						onChange: async (recordId, value) => {
							if (recordId) {
								let recordResult;
								if (value === true) {
									recordResult = await app.post(endpoint + '/activate',
										{
											lockerIds: [recordId]
										});
								} else {
									recordResult = await app.post(endpoint + '/deactivate',
										{
											lockerIds: [recordId]
										});
								}

								console.log(recordResult)

								if (recordResult.success) {
									app.toast('success');
								} else {
									app.toast('problem');
								}
							}
						}
					},
					{
						name: 'code',
						type: 'string',
						formInputType: 'text',
						readonly: true,
						formShow: true,
						// link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						// listEdit: true,
						maxLength: 255,
						minLength: 1,
						nullable: false,
						required: true,
					},
					{
						name: 'status',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						inputMarker: 'status',
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						advancedFilterType: 'select',
						options: ['Canceled', 'Completed', 'Open'],
						popoverFilterType: 'select',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					// {
					// 	name: 'cancelledAt',
					// 	type: 'datetime-local',
					// 	formInputType: 'date',
					// 	formShow: false,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 1,
					// 	listShow: true,
					// 	popoverFilterType: 'range',
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	nullable: true,
					// 	required: true,
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	nullable: true,
					// 	required: true,
					// },
					// {
					// 	name: 'completedAt',
					// 	type: 'datetime-local',
					// 	formInputType: 'date',
					// 	formShow: false,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 1,
					// 	listShow: true,
					// 	popoverFilterType: 'range',
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	nullable: true,
					// 	required: true,
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	nullable: true,
					// 	required: true,
					// },
					// {
					// 	name: 'createdAt',
					// 	type: 'datetime-local',
					// 	formInputType: 'date',
					// 	formShow: false,
					// 	listColumnPosition: 1,
					// 	listActiveToggle: true,
					// 	listShow: false,
					// 	popoverFilterType: 'range',
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	nullable: true,
					// 	required: true,
					// },
					// {
					// 	name: 'updatedAt',
					// 	type: 'datetime-local',
					// 	formInputType: 'date',
					// 	formShow: false,
					// 	readonly: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 1,
					// 	listShow: false,
					// 	popoverFilterType: 'range',
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	nullable: true,
					// 	required: true,
					// },
				],
			},
		];
	}
}
