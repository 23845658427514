import m from 'mithril';
import tableStore from '../lib/tableStore';

/**
 * @class
 * @classdesc Base class for records views.
 */
class vwBaseRecords {
	attrs = {};
	// selectedRecords = [];
	filterOpen = false;
	drawerOpen = false;

	listTabs = [];
	listTabActive = {};
	listTabIndexActive = 1;

	listTabStored = false;
	parentOnSelect;

	/**
	 * Called when a component is initialized. Used for getting records.
	 */
	async oninit({ attrs }) {
		this.attrs = attrs;
		await this.getRecords();
		m.redraw();

	}

	/**
	 * Fetches the records and updates the records and metadata.
	 */
	async getRecords() {
		tableStore.isLoading(true);

		const result = await app.get(this.endpoint, { ...tableStore.metadata(), ...tableStore.filterData(), ...tableStore.sortData() });
		if (result.success) {
			tableStore.records(result.data);
			tableStore.metadata(result.metadata);
		}

		tableStore.isLoading(false);

		m.redraw();
	}

	async reload() {
		await this.getRecords();
		m.redraw();
	}

	/**
	 * Sets all records to selected or unselected
	 */
	async toggleAllRecordsSelection(state) {
		tableStore.selectedRecords([]);
		tableStore.allRecordsSelected(state);

		if (state === true) {
			tableStore.records().forEach(function (elem) {
				tableStore.selectedRecords().push(elem.id);
			});

			document.querySelector('.selectRecord').classList.add('checked');
		}

		if (state === false) {
			document.querySelector('.selectRecord').classList.remove('checked');
		}

		console.log(tableStore.selectedRecords());
		m.redraw();
	}
	/**
	 * Deletes the selected records and fetches the remaining records.
	 */
	async deleteRecords() {
		const results = await app.delete(this.endpoint, { id: tableStore.selectedRecords(), archived: true });
		if (results.success) {
			await this.getRecords();
		}
	}

	/**
	 * Deletes the selected records and fetches the remaining records.
	 */
	async archiveRecords() {
		const results = await app.patch(this.endpoint, { id: tableStore.selectedRecords(), active: false, archived: true });
		if (results.success) {
			await this.getRecords();
		}
	}

	/**
	 * Sorts the records based on the provided data and fetches the records.
	 *
	 * @param {Object} data - The data to use for sorting.
	 */
	async sortRecords(data) {

		tableStore.sortData(data);


		await this.getRecords();
	}

	/**
	 * Check if the tab is active.
	 *
	 * @param {Object} tab - The tab to check.
	 * @return {boolean}
	 */
	isListTabActive(tab) {
		if (Object.keys(this.listTabActive).length == 0) {
			return tab.default === true;
		}
		return this.listTabActive.slug === tab.slug;
	}

	setListTabActive = (index) => {
		// check for selection of current tab
		if (Object.keys(this.listTabActive).length !== 0) {
			const processedTabs = this.listTabs;

			if (processedTabs.indexOf(this.listTabActive) == index) {
				return;
			}
		}

		this.listTabActive = this.listTabs[index];
		this.listTabIndexActive = index + 1;
		console.log(this.listTabIndexActive);
		this.viewMode = this.listTabActive?.viewMode;
		this.listTabStored = false;
	};

	/**
	 * Paginates the records based on the provided data and fetches the records.
	 *
	 * @param {Object} data - The data to use for pagination.
	 */
	async paginateRecords(data) {
		console.log('data', data)
		tableStore.metadata({ ...tableStore.metadata(), ...data });
		await this.getRecords();
	}

	/**
	 * Toggles the selection of a record.
	 *
	 * @param {number} recordId - The ID of the record to toggle selection.
	 */
	toggleRecordSelection(recordId) {
		const index = tableStore.selectedRecords().indexOf(recordId);
		if (index >= 0) {
			tableStore.selectedRecords().splice(index, 1);
		} else {
			tableStore.selectedRecords().push(recordId);
		}

		if (tableStore.records()?.length > 0 && tableStore.records().length === tableStore.selectedRecords().length) {
			this.toggleAllRecordsSelection(true);
		}
	}

	/**
	 * View function for mithril.js. Override in subclasses.
	 */
	view() {
		return m('', 'No view implemented');
	}
}

export default vwBaseRecords;
