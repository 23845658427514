/* eslint:disable */
import sjefIconChevronRight from '@sjefapp/sjeficons/icons/sjefIconChevronRight';
import sjefIconHome from '@sjefapp/sjeficons/icons/sjefIconHome';
import { Breadcrumb, BreadcrumbItem, Collapse } from 'construct-ui';
import m from 'mithril';
import app from '../../app';

var mockMenu = require('../../mockMenu.json');

if (app.lvl) {
	if (app.activeTab === 'sjef') {
		if (app.lvl == 6) {
			mockMenu = require('../../menu/6.json');
		} else if (app.lvl == 7) {
			mockMenu = require('../../menu/7.json');
		} else if (app.lvl == 8) {
			mockMenu = require('../../menu/8.json');
		} else if (app.lvl == 9) {
			mockMenu = require('../../menu/9.json');
		}
	} else if (app.activeTab === 'elockers') {
		mockMenu = require('../../menu/elockers.json');
	}
}

console.log(app);
var refactoredMenu = [];

let breadcrumbs;
let paramReplacementObjects;

const createBreadcrumbsArray = async (path) => {
	refactorJson(mockMenu);

	let splittedPathArray = path.split('/').filter((line) => line);

	breadcrumbs = splittedPathArray.reduce(
		(tmpObj, p, idx) => {
			tmpObj.last.push(p);
			let label = p;
			let param = app.currentRawRoute.split('/').filter((line) => line)[idx];

			if (param && param.startsWith(':') && paramReplacementObjects && paramReplacementObjects[param]) {
				label = paramReplacementObjects[param];
			}

			tmpObj.all.push({
				route: '/' + tmpObj.last.join('/'),
				label: label,
			});

			return tmpObj;
		},
		{ last: [], all: [] }
	).all;

	breadcrumbs.unshift({
		label: m(sjefIconHome),
		route: '/',
	});
	// console.log(breadcrumbs);
};

// remove all json hierarchies from menuMock.js so that all menu items are 'equal' and are more easily query-able.
const refactorJson = async (menu) => {
	var tempMenu = JSON.parse(JSON.stringify(menu));
	tempMenu.forEach(function (elem) {
		elem.children?.forEach(function (childElem) {
			tempMenu.push(childElem);
		});

		elem.children = [];
	});

	// if the menu still has children run the function again with the new structure (1 layer of children is removed).
	// if no children in the tempMenu, the global refactoredMenu is overwritten.
	if (tempMenu.filter((line) => line.children?.length > 0).length > 0) {
		refactorJson(tempMenu);
	} else {
		refactoredMenu = tempMenu;
	}
};

class sjefBreadcrumbs {
	setBreadCrumbs(vnode) {
		paramReplacementObjects = vnode.attrs.labelParams;
		refactoredMenu = [];
		let currentRoute = m.route.get();
		createBreadcrumbsArray(currentRoute);
	}

	oninit(vnode) {
		this.setBreadCrumbs(vnode);
	}

	onupdate(vnode) {
		this.setBreadCrumbs(vnode);
	}

	view({ attrs: { classes = [], labelParams = {}, hideLast = false, onclick = void 0, ...htmlAttrs } }) {
		if (hideLast) {
			breadcrumbs.pop();
		}

		return m(
			Breadcrumb,
			{
				size: 'sm',
				// seperator: m(sjefIconChevronRight),
				class: 'sjefBreadcrumb breadcrumbs-' + breadcrumbs.length,
			},
			breadcrumbs.map((item, idx) =>
				m(
					BreadcrumbItem,
					{
						onclick: () => {
							idx < breadcrumbs.length - 1 ? m.route.set(item.route) : void 0;
						},
					},
					item.label
				)
			)
		);
	}
}

export default sjefBreadcrumbs;
