/**
 * @fileoverview This module creates a comprehensive view of Adyen details.
 * It imports multiple components and services to fetch and display this.data.
 * It uses the Mithril.js framework and construct-ui library for layout and components.
 * @module vwClientAdyenDetails
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import { Grid, Col, Table, FormGroup, FormLabel, Collapse, Tabs, TabItem, Select, Card } from 'construct-ui';
import { _t } from '../../../lib/i18n';

import vwClientAdyenBalanceAccounts from './vwClientAdyenBalanceAccounts';
import vwClientAdyenBusinessLines from './vwClientAdyenBusinessLines';
import vwClientAdyenStores from './vwClientAdyenStores';
import sjefIconCloudStorm from '@sjefapp/sjeficons/icons/sjefIconCloudStorm';
import sjefIconBuildingBank from '@sjefapp/sjeficons/icons/sjefIconBuildingBank';
import sjefBanner from '../../../views/components/sjefBanner';
import sjefSelect from '../../../views/components/sjefSelect';
import sjefIconBuildingBank from '@sjefapp/sjeficons/icons/sjefIconBuildingBank';
import app from '../../../app';
import { getBooleanIcons } from '../../../lib/helpers';
import response from '../../../lib/response';
import { _t } from '../../../lib/i18n';
import vwClientAdyenClientConfig from './vwClientAdyenClientConfig';
import vwClientMutations from './vwClientMutations';

/**
 * The view module exports an object representing a Mithril component.
 * @namespace
 */
class vwClientAdyenDetails {
	/**
	 * @type {boolean} isLoading - A flag to control the loading state of the view.
	 */
	isLoading = true;

	tabs = ['stores', 'businessLines', 'balanceAccounts', 'capabilities', 'clientConfig', 'clientMutations'];
	activeTab = 'stores';
	// DONT REMOVE
	// _t('_.fields.adyen.stores'),
	// _t('_.fields.adyen.businessLines'),
	// _t('_.fields.adyen.balanceAccounts'),
	// _t('_.fields.adyen.capabilities'),
	// _t('_.fields.adyen.clientMutations'),
	// _t('_.fields.adyen.clientConfig'),
	// _t('_.fields.adyen.receiveFromPlatformPayments'),
	// _t('_.fields.adyen.receiveFromBalanceAccount'),
	// _t('_.fields.adyen.sendToBalanceAccount'),
	// _t('_.fields.adyen.sendToTransferInstrument'),
	// _t('_.fields.adyen.receivePayments'),
	// _t('_.fields.adyen.canDoPayouts'),
	// _t('_.fields.adyen.canReceiveFromPlatformPayments'),
	// _t('_.fields.adyen.canReceivePayments'),

	/**
	 * @type {string} clientId - The ID of the client whose details are fetched.
	 */
	clientId;

	/**
	 * @type {Object} this.data - The object to store the Adyen details of the client.
	 */
	data = {};

	/**
	 * Initializes the view component with provided attributes and fetches Adyen details.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {string} vnode.attrs.clientId - The ID of the client.
	 */
	oninit = async ({ attrs }) => {
		if (!this.clientId) {
			this.clientId = attrs.clientId || app.restore('activeRecordId');
		}
		this.clientId = attrs.record.id;

		this.activeTab = app.restore('activeBankingTab') || 'stores';

		if (!attrs.record.adyenAccountHolderId) {
			this.isNotAvailable = true;
			this.isLoading = false;

			return
		}

		await this.setAdyenDetails();
		this.isLoading = false;
	};

	/**
	 * Fetches Adyen details for the provided clientId using adyenGetDetails service
	 * and assigns the response to the this.data variable.
	 * @async
	 * @function
	 * @returns {Promise<Object>} The Adyen details of the client.
	 */

	setAdyenDetails = async () => {
		const result = await app.get(`/balanceplatform/clients/${this.clientId}`);

		if (result.success === false) {
			return response.failed('1111', result.error);
		}

		this.data = result.data;
		this.data.clientId = this.clientId;
		return this.data;
	};

	/**
	 * Renders the view component. It includes information about the account holder, legal entity,
	 * business lines, balance accounts, and capabilities.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */

	renderActiveTab = (activeTab = this.activeTab) => {
		const capabilities = { ...this.data.accountHolder.capabilities, canDoPayouts: this.data.canDoPayouts, canReceiveFromPlatformPayments: this.data.canReceiveFromPlatformPayments, canReceivePayments: this.data.canReceivePayments }
		switch (activeTab) {
			case 'stores':
				return m(vwClientAdyenStores, {
					clientId: this.data.clientId,
					businessLines: this.data.legalEntity?.businessLines,
					merchantAccounts: {
						pos: this.data.merchantAccountPos,
						ecom: this.data.merchantAccountEcom,
					}
				});
			case 'businessLines':
				return m(vwClientAdyenBusinessLines, {
					clientId: this.data.clientId,
					businessLines: this.data.legalEntity?.businessLines,
					callbackRedraw: this.setAdyenDetails,
				});
			case 'balanceAccounts':
				return m(vwClientAdyenBalanceAccounts, {
					balanceAccounts: this.data.accountHolder.balanceAccounts,
					primaryBalanceAccount: this.data.accountHolder.primaryBalanceAccount,
				});
			case 'clientConfig':
				return m(vwClientAdyenClientConfig, {
					clientId: this.clientId,
				})
			case 'clientMutations':
				return m(vwClientMutations,
					{
						clientId: this.data.clientId,
						balanceAccounts: this.data.accountHolder.balanceAccounts,
						currencies: this.data.accountHolder,
					}
				)
			case 'capabilities':
				return [
					m('.adyen', [
						m('.tableList', [
							m('.titles.row', [
								m('', m('h2', 'Capabilities')),
								m(
									'.actions',
									m('', _t('_.fields.enabled')),
									m('', _t('_.fields.requested')),
									m('', _t('_.fields.allowed')),
									m('', _t('_.fields.verificationStatus')),
									m('', _t('_.fields.problems'))
								),
							]),
							m('.content', [

								Object.keys(capabilities).map((key) => {
									// console.log(item)
									const item = capabilities[key];
									return [
										m('.row.content', [
											m('.title', _t(`_.fields.adyen.${key}`)),
											m(
												'.actions',
												m('', { class: item.enabled }, [
													m('label', _t(`_.fields.adyen.${key}`)),
													m('.svgWrapper', m(getBooleanIcons(item.enabled))),
												]),
												m('', { class: item.requested }, [
													m('label', _t(`_.fields.adyen.${key}`)),
													m('.svgWrapper', m(getBooleanIcons(item.requested))),
												]),
												m('', { class: item.allowed }, [
													m('label', _t(`_.fields.adyen.${key}`)),
													m('.svgWrapper', m(getBooleanIcons(item.allowed))),
												]),
												m('', item.verificationStatus || item.status),
												m(
													'.problems',
													{
														onclick: (e) => {
															item.expanded = !item.expanded; // Toggle expand on click
														},
														style: { cursor: 'pointer' }, // Add cursor pointer for better UX
													},
													[
														m('label', _t(`_.fields.adyen.${key}`)),

														m(
															'',
															item.problems && item.problems.length > 0
																? m(sjefIconCloudStorm)
																: '-'
														),
													]
												)
											),
										]),
										item.expanded && item.problems && item.problems.length > 0
											? m('.row.expanded', [
												item.problems.map((problem) =>
													problem.verificationErrors.map((error) =>
														m('.problem-detail', [
															m('.detail', problem.entity.id),
															m('.detail', problem.entity.type),
															m('.detail', error.code),
															m('.detail', error.message),
														])
													)
												),
											])
											: null,
									];
								}),
							]),
						]),
					]),]
		}
	};


	view() {
		if (this.isLoading === true) {
			return m('.loading', 'Loading...');
		}
		return this.isNotAvailable ?
			m('.notAvailable', 'Not available') : [
				m(sjefBanner, {
					title: 'Banking',
					subTitle: 'View adyen data',
					icon: m(sjefIconBuildingBank),
				}),
				this.tabs.length > 1
					? m(sjefSelect, {
						options: this.tabs,
						onChange: (value) => {
							this.activeTab = value;
							app.store('activeBankingTab', this.activeTab);
							m.redraw();
						},
						activeOption: this.activeTab,
					})
					: void 0,
				m('.selectTabs', [
					this.renderActiveTab()
				]),
			]

	}
}

export default vwClientAdyenDetails;
