import m from 'mithril';

import debounce from 'lodash.debounce';
import { FormGroup, FormLabel, Input, List, ListItem } from 'construct-ui';
import sjefButton from './sjefButton';
import sjefLoading from './sjefLoading';
import { _t } from '../../lib/i18n';
import app from '../../app';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';

const sjefSelectAsync = {
	oninit: function ({ attrs }) {
		Object.assign(this, {
			endpoint: attrs.endpoint,
			onSelect: attrs.onSelect,
			field: attrs.field,
			filterOn: attrs.filterOn || 'name',
			intent: attrs.intent || 'none', // TODO: not working
			isOpen: false,
			searchQuery: '',
			selectedOption: attrs.defaultLabel || '',
			options: [],
			metadata: {
				offset: 0,
				limit: 10,
				lastpage: 0,
				total: 0,
			},
			fetchOptions: this.fetchOptions.bind(this),
			debouncedFetchOptions: debounce(this.fetchOptions.bind(this), 500),
			toggleDropdown: this.toggleDropdown.bind(this),
			handleSearch: this.handleSearch.bind(this),
			selectOption: this.selectOption.bind(this),
			clearSelection: this.clearSelection.bind(this),
			loadMoreOptions: this.loadMoreOptions.bind(this),
			loading: false,
			label: attrs.label,
		});

		document.addEventListener('click', (event) => this.handleClickOutside(event));
	},

	handleClickOutside: function (event) {
		const dropdown = document.querySelector('.sjef-select-dropdown-content');
		if (dropdown && !dropdown.contains(event.target)) {
			this.isOpen = false;
			m.redraw(); // Ensure Mithril updates the view
		}
	},

	onremove: function () {
		// Remove the event listener when the component is removed
		document.removeEventListener('click', this.handleClickOutside);
	},

	fetchOptions: async function () {
		this.loading = true;
		try {
			const filter = `filter[${this.filterOn}]`;
			console.log(filter);
			const { data, metadata } = await app.get(this.endpoint, {
				[filter]: this.searchQuery,
				offset: this.metadata.offset,
				limit: this.metadata.limit,
			});

			this.metadata = metadata;
			this.options = [...this.options, ...data.map((record) => ({ value: record.id, label: record.name, field: this.field, record: record }))];
		} catch (error) {
			console.error('Error fetching options:', error);
		} finally {
			this.loading = false;
		}
	},

	toggleDropdown: function () {
		this.isOpen = !this.isOpen;
		this.searchQuery = '';
		if (this.isOpen && this.options.length === 0) {
			this.fetchOptions();
		}
	},

	handleSearch: function ({ target: { value } }) {
		this.searchQuery = value;
		this.loading = true;
		this.metadata.offset = 0;
		this.options = [];
		this.debouncedFetchOptions();
	},

	selectOption: function (data) {
		this.selectedOption = data.label;
		this.isOpen = false;
		this.searchQuery = '';
		this.onSelect(data);
	},

	clearSelection: function () {
		this.selectedOption = null;
		this.fetchOptions();
		this.onSelect({ value: '', label: '', field: this.field, record: {} });
	},

	loadMoreOptions: function ({ target: { scrollTop, scrollHeight, clientHeight } }) {
		if (this.metadata.offset < this.metadata.total && this.isOpen && scrollHeight - scrollTop === clientHeight) {
			this.metadata.offset++;
			this.fetchOptions();
		}
	},

	view: function () {
		return [
			m(
				'.sjefInputWrapper',
				m(
					'.inputWrapper',
					m('label', this.label),
					m(
						`.sjef-select`,
						{
							class: this.loading ? 'loading' : '',
						},
						[
							m(
								'',
								m(
									FormGroup,
									m(Input, {
										type: 'text',
										class: 'active-state-elem',
										intent: this.intent,
										value: this.selectedOption,
										onclick: this.toggleDropdown,
										oninput: this.handleSearch,
										readonly: true,
										placeholder: 'Select an Option',
									})
								),
								this.selectedOption &&
									m(sjefButton, {
										label: m(sjefIconX),
										buttonLayout: 'remove',
										class: 'sjef-select-clear-button',
										onclick: this.clearSelection,
									})
							),
							this.isOpen &&
								m(`.sjef-select-dropdown-content`, [
									m(Input, {
										type: 'text',
										class: 'search',
										value: this.searchQuery,
										oninput: this.handleSearch,
										placeholder: 'Search options...',
									}),
									this.loading && m(sjefLoading),
									m(
										List,
										{
											class: 'sjef-select-options-list',
											style: { overflowY: 'auto' },
											oncreate: ({ dom }) => dom.addEventListener('scroll', this.loadMoreOptions),
											onremove: ({ dom }) => dom.removeEventListener('scroll', this.loadMoreOptions),
										},
										this.options
											.filter(({ label }) => label.toLowerCase().includes(this.searchQuery.toLowerCase()))
											.map((option) =>
												m(ListItem, {
													label: option.label,
													onclick: () => this.selectOption(option),
												})
											),
										this.options.length == 0 && !this.loading ? m('', _t('_.fields.noResults')) : void 0
									),
									this.loadingMoreOptions && m(`.sjef-select-loading`, 'Loading more options...'),
								]),
						]
					)
				)
			),
		];
	},
};

export default sjefSelectAsync;
