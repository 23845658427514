import { Checkbox, Table } from 'construct-ui';
import m from 'mithril';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwItemsTab {
	modifierId;
	dataItems = [];
	items = [];

	/**
	 * @param {object} attrs - Initial attributes for the component.
	 */
	constructor({ attrs }) {
		this.modifierId = attrs.record.id;
		// this.initializeActiveStates();
	}

	/**
	 * Initialization method for Mithril.js
	 */
	async oninit() {
		// get all modifiers
		const result = await app.get('/masterdata/items');
		this.dataItems = result.data;
		console.log('this.dataItems', this.dataItems);
		await this.loadModifierItems();
	}

	async loadModifierItems() {
		// get all modifiers
		// const result = await app.get(`http://127.0.0.1:3000/modifiers/${this.modifierId}/items`);
		const result = await app.get(`/masterdata/modifiers/${this.modifierId}/items`);

		this.items = result.data
			? result.data.reduce((acc, item) => {
					acc[item.id] = {
						id: item.id,
						name: item.name,
						nameTranslations: item.nameTranslations,
					};
					return acc;
			  }, {})
			: {};
	}

	/**
	 * Toggle the active state of an modifier.
	 * @param {number} modifierId - ID of the modifier.
	 * @param {string} column - Column name.
	 */
	async setState(item) {
		item = JSON.parse(JSON.stringify(item));

		const url = `/masterdata/modifiers/${this.modifierId}/items`;
		const method = this.items[item.id] ? 'delete' : 'post';
		const data = {
			items: [
				{
					itemId: item.id,
				},
			],
		};

		const result = await app[method](url, data);

		this.loadModifierItems();

		app.toast(result.success ? 'success' : 'failed', { intent: result.success ? 'positive' : 'negative' });
	}

	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	view() {
		return m('div', [
			m(Table, { bordered: false, striped: true }, [
				m('thead', [m('tr', [m('th', 'Name'), m('th', '')])]),
				m(
					'tbody',
					this.dataItems.map((item) =>
						m('tr', [
							m('td', item.name),
							m(
								'td',
								m(Checkbox, {
									checked: this.items[item.id],
									onchange: () => this.setState(this.items[item.id] || item),
								})
							),
						])
					)
				),
			]),
		]);
	}
}

export default vwItemsTab;
