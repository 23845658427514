import m from 'mithril';
import camelCase from 'lodash.camelcase';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconDragDrop from '@sjefapp/sjeficons/icons/sjefIconDragDrop';
import sjefIconDragDrop2 from '@sjefapp/sjeficons/icons/sjefIconDragDrop2';
import sjefIconSort90 from '@sjefapp/sjeficons/icons/sjefIconSort90';

/* eslint:disable */

const processSortingData = (name, direction) => {
	name = direction + camelCase(name);
	return { sort: name };
};

class sjefTh {
	parentOnSortUpdate;
	sortable = true;

	invertDirection = (identifier) => {
		let tableRow = document.querySelector(`[data-order-by="${identifier}"]`);

		if (tableRow.dataset.orderDirection == 'ASC') {
			tableRow.dataset.orderDirection = 'DESC';
			return '-';
		}

		tableRow.dataset.orderDirection = 'ASC';
		return '';
	};

	updateSort(name, direction) {
		let sortingSlug = processSortingData(name, direction);

		if (typeof this.parentOnSortUpdate === 'function') {
			this.parentOnSortUpdate(sortingSlug);
		}

		// m.redraw();
	}

	oninit({ attrs: { onSortUpdate } }) {
		if (typeof onSortUpdate === 'function') {
			this.parentOnSortUpdate = onSortUpdate;
		} else {
			this.sortable = false;
		}
	}

	view({ attrs: { name = '', activeSort, width = '', listDragSort = false, onclick = void 0, toggleDragSort, ...htmlAttrs } }) {
		var self = this;

		const additionalClass = htmlAttrs.class ? htmlAttrs.class : ''; // Check if htmlAttrs.class exists
		const direction = activeSort?.charAt(0) === '-' ? 'DESC' : 'ASC';

		const sortName = activeSort?.replace(/-/, '');
		return m(
			'th.sjefTh',
			{
				class: additionalClass + ' ' + (!self.sortable ? 'unsortable' : ''),
				'data-order-by': name,
				'data-order-direction': sortName === name ? direction : 'ASC',
				onclick: () => {
					if (self.sortable) {
						let newDirection = self.invertDirection(name);
						self.updateSort(name, newDirection);
					}
				},
			},
			m('', name),
			name ? m(sjefIconChevronDown, { class: 'sortIcon' }) : void 0,
			listDragSort && name
				? m(sjefIconSort90, {
					onclick: () => toggleDragSort(name),
				})
				: void 0
		);
	}
}

export default sjefTh;
