import m from 'mithril';
import sjefBreadcrumbs from '../../views/components/sjefBreadcrumbs';
import { Card } from 'construct-ui';
import sjefIconGrave from '@sjefapp/sjeficons/icons/sjefIconGrave';
import sjefIconCactus from '@sjefapp/sjeficons/icons/sjefIconCactus';
import sjefIconBoxAlignTopLeft from '@sjefapp/sjeficons/icons/sjefIconBoxAlignTopLeft';
import sjefIcon360View from '@sjefapp/sjeficons/icons/sjefIcon360View';
import sjefIconSignal3G from '@sjefapp/sjeficons/icons/sjefIconSignal3G';
import sjefIconBadge8K from '@sjefapp/sjeficons/icons/sjefIconBadge8K';
import sjefIconPaint from '@sjefapp/sjeficons/icons/sjefIconPaint';
import sjefIconPageBreak from '@sjefapp/sjeficons/icons/sjefIconPageBreak';

class vwSettings {
	view() {
		return m(
			'.settings.vwSettings.archive',
			m(
				'.pageHeader',
				m('h1.title', 'Settings'),
				m(sjefBreadcrumbs),
			),
			m('.columns-3', [
				m(Card, [
					m('h3', 'Betalingen'),
					m('.setting-item',
						m('.title',
							m(sjefIconPaint),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
					m('.setting-item',
						m('.title',
							m(sjefIconPageBreak),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
					m('.setting-item',
						m('.title',
							m(sjefIconGrave),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
				]),
				m(Card, [
					m('h3', 'Weergave'),
					m('.setting-item',
						m('.title',
							m(sjefIconGrave),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
					m('.setting-item',
						m('.title',
							m(sjefIconCactus),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
					m('.setting-item',
						m('.title',
							m(sjefIconBoxAlignTopLeft),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
				]),
				m(Card, [
					m('h3', 'Geluid'),
					m('.setting-item',
						m('.title',
							m(sjefIcon360View),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
					m('.setting-item',
						m('.title',
							m(sjefIconBadge8K),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
					m('.setting-item',
						m('.title',
							m(sjefIconSignal3G),	
							'Setting'
						),
						m('p', 'Voorzichtig settinkje')
					),
				]),
			])
		)
	}
}

export default vwSettings;