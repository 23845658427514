import m from 'mithril';
import sjefInput from './sjefInput';

class sjefRangeFilter {
	filterState = 'between';
	valueA = '';
	valueB = '';
	filterString = '';
	parentOnChange;
	type;

	oninit(vnode) {

		if (typeof vnode.attrs.onChange === 'function') {
			this.parentOnChange = vnode.attrs.onChange;
		}

		this.setType(vnode.attrs.type);
	}

	setType(t) {
		console.log(t)
		if (!t || ['text', 'string', 'integer'].includes(t)) {
			this.type = 'text';
		} else if (t == 'date') {
			console.log('d')
			this.type = 'datetime-local';
		}  else {
			throw new Error(`Field type '${t}' not supported for range filter`);
		}
	}

	createFilterString() {
		if (!this.valueA || (this.filterState == 'between' && (!this.valueA || !this.valueB))) {
			// reset filter
			this.parentOnChange('');
			return;
		}

		if (this.filterState !== 'between') {
			this.filterString = `${this.filterState},${this.valueA}`;
		} else {
			this.filterString = `${this.filterState},${this.valueA},${this.valueB}`;
		}

		let lastOfFilterString = this.filterString[this.filterString.length - 1];

		if (lastOfFilterString && lastOfFilterString !== ',') {
			if (typeof this.parentOnChange === 'function') {
				this.parentOnChange(this.filterString);
			}
		}

		m.redraw();
	}

	view(vnode) {
		return [
			m(
				'.sjefRangeWrapper',
				m(sjefInput, {
					type: 'select',
					options: ['between', 'before', 'after'],
					value: this.filterState,
					onChange: (value) => {
						this.filterState = value;

						this.createFilterString(vnode);
					},
				}),
				m(sjefInput, {
					value: this.valueA,
					type: this.type,
					onChange: (value) => {
						if (value == ':00') {
							value = '';
						}

						this.valueA = value;

						this.createFilterString();
					},
				}),
				this.filterState === 'between'
					? m(sjefInput, {
							value: this.valueB,
							type: this.type,
							onChange: (value) => {
								if (value == ':00') {
									value = '';
								}

								this.valueB = value;

								this.createFilterString();
							},
						})
					: void 0,
			),
		];
	}
}

export default sjefRangeFilter;
