import m from 'mithril';
import { _t } from '../../lib/i18n';
import Sortable from 'sortablejs';
import { Switch, Tooltip } from 'construct-ui';
import infoToggle from './infoToggle';
import sjefButton from './sjefButton';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefIconMenu2 from '@sjefapp/sjeficons/icons/sjefIconMenu2';
import sjefIconLock from '@sjefapp/sjeficons/icons/sjefIconLock';
import sjefIconChevronRight from '@sjefapp/sjeficons/icons/sjefIconChevronRight';

function validateFields(fields) {
	// Sort the fields by listColumnPosition
	fields.sort((a, b) => a.listColumnPosition - b.listColumnPosition);

	let firstDraggableFound = false;
	let lastDraggableFound = false;

	for (let i = 0; i < fields.length; i++) {
		const isDraggable = fields[i].hasOwnProperty('draggable') ? fields[i].listDraggable : true;

		if (isDraggable) {
			firstDraggableFound = true;
		} else {
			if (firstDraggableFound) {
				lastDraggableFound = true;
			}
		}

		if (lastDraggableFound && isDraggable) {
			return false;
		}
	}

	return true;
}

// todo: make fields static
// mutable check
// check if draggable fields are at the begin and end of fields array
class sjefTableLayout {
	fields;
	// oldFields;
	draggingAllowed = true;

	oninit(vnode) {
		this.fields = JSON.parse(JSON.stringify(vnode.attrs.fields));
		// this.oldFields = JSON.parse(JSON.stringify(vnode.attrs.fields)); // future reset button potentially
	}

	oncreate(vnode) {
		this.sortable = new Sortable(vnode.dom.querySelector('.sortable-container'), {
			draggable: '.sortable-item',
			animation: 150,
			ghostClass: 'dragging',

			onEnd: (evt) => {
				const list = document.querySelector('.sjefTableLayout .tableFields').querySelectorAll('li');

				let tempFields = JSON.parse(JSON.stringify(this.fields));
				list.forEach((elem, idx) => {
					const targetField = tempFields.find((field) => field.name === elem.dataset.name);
					if (targetField) {
						targetField.listColumnPosition = idx;
					}
				});

				this.fields = tempFields;
			},
		});
	}

	onremove() {
		this.sortable.destroy();
	}

	view(vnode) {
		const {
			attrs: { fields, onclick = void 0, ...htmlAttrs },
		} = vnode;

		if (!fields) {
			throw new Error('No fields argument passed to component sjefTableLayout');
		}

		if (!validateFields(fields)) {
			throw new Error('No provided fields array is not valid. The draggable items should be adjacent.');
		}

		// set all draggable to false if only 1 true
		if (fields.length - fields.filter((field) => field.listDraggable === false).length == 1) {
			fields.filter((field) => (field.listDraggable = false));
		}

		const toggleActive = (name) => {
			const targetField = this.fields.find((field) => field.name === name);

			if (targetField && targetField.listActiveToggle !== false) {
				targetField.listShow = !targetField.listShow;
			}
		};

		return m('.sjefTableLayout', htmlAttrs, [
			m('.flexer.center',
				m('h2', 'Table layout'),
				m('.iconHover', {
					onclick: () => {
						if (typeof vnode.attrs.onCloseDrawer == 'function') {
							vnode.attrs.onCloseDrawer();
						}
					},
				}, m(sjefIconChevronRight))
			),
			m(
				'section.tableOrdering',

				m('h3.title', [
					m('', 'Table columns '),
					m(infoToggle, {
						content:
							'Toggle the table columns you would (not) like to be visisble in the table, or use drag and drop to change their order.',
					}),
				]),
				this.fields.length
					? m(
							'ul.sortable-container.tableFields',
							this.fields.map((field, idx) => {
								return m(
									'li',
									{
										class: [
											field.listDraggable !== false ? 'sortable-item' : '',
											field.listShow ? 'active' : '',
											field.listActiveToggle !== false ? 'toggle' : '',
										]
											.filter(Boolean)
											.join(' '),
										'data-name': field.name,
										// 'data-position': field.listColumnPosition,
										// 'data-active': field.active ? 1 : 0,
										onclick: () => toggleActive(field.name),
									},
									[
										m(
											'.listActiveToggle',
											m(
												'.toggleInner'
												// field.listActiveToggle == false ? m('.disabled') : void(0)
											)
										),
										m('span', field.name),
										field.listActiveToggle == false ? m(sjefIconLock) : void 0,
										field.listDraggable !== false ? m(sjefIconMenu2) : void 0,
									]
								);
							})
					  )
					: void 0,
				m('.flexer.actions', [
					m(sjefButton, {
						buttonLayout: 'ghost',
						label: 'Cancel',
						onclick: () => {
							if (typeof vnode.attrs.onCloseDrawer == 'function') {
								vnode.attrs.onCloseDrawer();
							}
						},
					}),
					m(sjefButton, {
						buttonLayout: 'filled',
						label: 'Save',
						onclick: () => {
							if (typeof vnode.attrs.onModify == 'function') {
								vnode.attrs.onModify(this.fields);
							}
							if (typeof vnode.attrs.onCloseDrawer == 'function') {
								vnode.attrs.onCloseDrawer();
							}
						},
					}),
				])
			),
			m('section.tableSorting'),
			m('section.tableFilters'),
		]);
	}
}

export default sjefTableLayout;
