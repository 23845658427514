import sjefIconDownload from '@sjefapp/sjeficons/icons/sjefIconDownload';
import vwAssetsList from '../modules/projects/tabs/vwAssetsTab';
import sjefButton from '../views/components/sjefButton';
import m from 'mithril';
import { _t } from '../lib/i18n';
import { downloadBase64 } from '../utils/files';
import vwPaymentsTab from '../modules/projects/tabs/vwPaymentsTab';
import sjefIconDetails from '@sjefapp/sjeficons/icons/sjefIconDetails';
import sjefIconCash from '@sjefapp/sjeficons/icons/sjefIconCash';
import sjefIconBox from '@sjefapp/sjeficons/icons/sjefIconBox';
import { writtenDate } from '../lib/helpers';

const moduleName = 'projects';
const baseRoute = '/projects';
const endpoint = '/operations/projects';

export default class Config {
	downloadSettingsPdf = async () => {
		try {
			const response = await app.get(`${this.endpoint}/${this.view.record.id}/downloadSettings`);

			if (!response.success || response.error) {
				return alert(response.error);
			}

			downloadBase64(response.data.base64, 'application/pdf', response.data.filename);

			// return response.data;
		} catch (error) {
			alert(error.message);

			// return response.error('3001', error.message);
		}
	};

	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;

		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
				icon: sjefIconDetails,
				title: 'Details',
			},
			{
				slug: 'payments',
				inCard: false,
				component: vwPaymentsTab,
				icon: sjefIconCash,
			},
			{
				slug: 'assets',
				inCard: false,
				projectId: 1,
				component: vwAssetsList,
				icon: sjefIconBox,
			},
		];
		this.buttons = [
			// {
			// 	component: sjefButton,
			// 	componentAttrs: {
			// 		label: 'PDF assets',
			// 		buttonLayout: 'neutral',
			// 		onclick: () => driverActivate(),
			// 	},
			// },
		];
		this.formButtons = [
			{
				component: sjefButton,
				componentAttrs: {
					id: 'barcodeInput',
					label: 'Project Settings',
					buttonLayout: 'neutral',
					iconRight: m(sjefIconDownload),
					onclick: async () => {
						await this.downloadSettingsPdf();
					},
				},
			},
		];
		this.schema = [
			{
				groupName: 'General',
				inCard: true,
				hideTitle: true,
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
					},
					// {
					// 	name: 'clientId', //
					// 	type: 'integer',
					// 	formInputType: false,
					// 	formShow: false,
					// 	link: `${baseRoute}/:id`,
					// 	listActiveToggle: false,
					// 	listColumnPosition: 0,
					// 	listShow: true,
					// 	listSize: 'small',
					// },
					{
						name: 'name',
						class: 'width-33',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'displayName',
						class: 'width-33',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listEdit: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'clientId',
						type: 'integer',
						formInputType: 'selectAsync',
						formShow: true,

						asyncEndpoint: '/clients/clients',
						asyncField: 'clientId',
						asyncFieldLabel: 'clientName',
						asyncFilterOn: 'name',

						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						required: true,
					},
					{
						class: 'width-50',
						name: 'projectStart',
						type: 'string',
						formInputType: 'datetime-local',
						// format: 'date-time',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						required: true,
						formatter: writtenDate,
					},
					{
						name: 'projectEnd',
						type: 'string',
						// format: 'date-time',
						formInputType: 'datetime-local',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
						formatter: writtenDate,
					},
					{
						name: 'active',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						required: true,
					},
					{
						class: 'width-33',
						name: 'type',
						type: 'string',
						options: ['', 'event', 'venue'],
						formInputType: 'select',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: true,
						minLength: 1,
						required: true,
					},
					{
						class: 'width-33',
						name: 'stack',
						type: 'string',
						options: ['', 'events', 'app', 'elockers', 'dev', 'local', 'rodeo'],
						formInputType: 'select',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: true,
						minLength: 1,
						required: true,
					},
					{
						name: 'clientCodeKantoorLegacy',
						class: '',
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'status',
						class: '',
						type: 'string',
						formShow: false,
						listActiveToggle: true,
						listShow: true,
					},
					{
						name: 'statusAssets',
						class: '',
						type: 'string',
						formShow: false,
						listActiveToggle: true,
						listShow: false,
					},
				],
			},
			{
				groupName: 'Adyen',
				inCard: true,
				class: 'column',
				fields: [
					{
						name: 'adyenCompanyAccount',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
					},
					{
						name: 'adyenMerchantAccount',
						type: 'string',
						class: '',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
					},
					{
						class: '', //
						name: 'adyenPosStoreId',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
					},
					{
						class: '',
						name: 'adyenPosStoreName',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
					},
					{
						class: '', //
						name: 'adyenEcomStoreId',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
					},
					{
						class: '',
						name: 'adyenEcomStoreName',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
					},
				],
			},
		];
	}
}
