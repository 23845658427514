/**
 * Sends a failed response to the client with the specified error code and message.
 * @function
 * @param {string} error_code - The error code to include in the response.
 * @param {string} error_message - The error message to include in the response.
 * @returns
 */
const failed = (errorCode, error_message, _validationErrors = []) => {
	return {
		success: false,
		error: error_message,
		errorCode: errorCode,
	};
};

/**
 * Sends a successful response to the client with the specified data (if any).
 * @function
 * @param {Object|boolean} [data=false] - The data to include in the response (if any).
 * @returns
 */
const success = (data = false) => {
	return {
		success: true,
		data: data,
	};
};

export default {
	failed,
	success,
};
