import m from 'mithril';

/* eslint:disable */

class sjefLoading {
	view(vnode) {
		return m(
			'.sjefLoading',
			// m(
			// 	'.coffee-container',
			// 	m('.coffee-header', [
			// 		m('.coffee-header__buttons.coffee-header__button-one'),
			// 		m('.coffee-header__buttons.coffee-header__button-two'),
			// 		m('.coffee-header__display'),
			// 		m('.coffee-header__details'),
			// 	]),
			// 	m('.coffee-medium', [
			// 		m('.coffee-medium__exit'),
			// 		m('.coffee-medium__arm'),
			// 		m('.coffee-medium__liquid'),
			// 		m('.coffee-medium__smoke.coffee-medium__smoke-one'),
			// 		m('.coffee-medium__smoke.coffee-medium__smoke-two'),
			// 		m('.coffee-medium__smoke.coffee-medium__smoke-three'),
			// 		m('.coffee-medium__smoke.coffee-medium__smoke-for'),
			// 		m('.coffee-medium__cup'),
			// 	]),
			// 	m('.coffee-footer')
			// )

			m('.lds-ripple',
				m(''),
				m('')
			)
		);
	}
}

export default sjefLoading;
