/* eslint:disable */
import sjefIconDots from '@sjefapp/sjeficons/icons/sjefIconDots';
import { PopoverMenu } from 'construct-ui';
import m from 'mithril';
import sjefModal from './sjefModal';
import sjefIconDotsVertical from '@sjefapp/sjeficons/icons/sjefIconDotsVertical';

class actionsMenu {

	// no autoClose in sjefModal because of conflicts bewtween nested components
	hasSjefModal(content) {
		return content.some((item) => item.tag === sjefModal);
	}

	view(
		{
			attrs: {
				content,
				blurred,
				crooked = false,
				closeOnContentClick,
				...htmlAttrs
			}
		}) {
		delete htmlAttrs.class;

		const closePopoverOnClick = closeOnContentClick !== undefined ? closeOnContentClick : !this.hasSjefModal(content);

		return m(PopoverMenu, {
			class: 'menuPopover actionsPopover sjefDropdown',
			closeOnContentClick: closePopoverOnClick,
			hasArrow: false,
			content: content,
			trigger:
				m(
					'.iconHover',
					{
						class: blurred ? 'blurred' : '',
					},
					crooked ? m(sjefIconDotsVertical, {
						class: 'actionsMenu',
					}) :
						m(sjefIconDots, {
							class: 'actionsMenu',
						})
				),
		});
	}
}
export default actionsMenu;
