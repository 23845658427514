import { Checkbox, Table } from 'construct-ui';
import m from 'mithril';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwModifiersTab {
	itemId;
	dataModifiers = [];
	modifiers = [];

	/**
	 * @param {object} attrs - Initial attributes for the component.
	 */
	constructor({ attrs }) {
		this.itemId = attrs.record.id;
	}

	/**
	 * Initialization method for Mithril.js
	 */
	async oninit() {
		// get all modifiers
		const result = await app.get('/masterdata/modifiers');
		this.dataModifiers = result.data;

		await this.loadItemModifiers();
	}

	async loadItemModifiers() {
		// get all modifiers
		// const result = await app.get(`http://127.0.0.1:3000/items/${this.itemId}/modifiers`);
		const result = await app.get(`/masterdata/items/${this.itemId}/modifiers`);

		this.modifiers = result.data
			? result.data.reduce((acc, modifier) => {
					acc[modifier.id] = {
						id: modifier.id,
						name: modifier.name,
						nameTranslations: modifier.nameTranslations,
					};
					return acc;
			  }, {})
			: {};
	}

	/**
	 * Toggle the active state of an modifier.
	 * @param {number} modifierId - ID of the modifier.
	 * @param {string} column - Column name.
	 */
	async setState(modifier) {
		modifier = JSON.parse(JSON.stringify(modifier));

		const url = `/masterdata/items/${this.itemId}/modifiers`;
		const method = this.modifiers[modifier.id] ? 'delete' : 'post';
		const data = {
			modifiers: [
				{
					modifierId: modifier.id,
				},
			],
		};

		const result = await app[method](url, data);

		this.loadItemModifiers();

		app.toast(result.success ? 'success' : 'failed', { intent: result.success ? 'positive' : 'negative' });
	}

	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	view() {
		return m('div', [
			m(Table, { bordered: false, striped: true }, [
				m('thead', [m('tr', [m('th', 'Name'), m('th', '')])]),
				m(
					'tbody',
					this.dataModifiers.map((modifier) =>
						m('tr', [
							m('td', modifier.name),
							m(
								'td',
								m(Checkbox, {
									checked: this.modifiers[modifier.id],
									onchange: () => this.setState(this.modifiers[modifier.id] || modifier),
								})
							),
						])
					)
				),
			]),
		]);
	}
}

export default vwModifiersTab;
