import { Callout, Form, FormGroup, FormLabel, Input } from 'construct-ui';
import m from 'mithril';
import app from '../../../app';
import sjefButton from '../../components/sjefButton';
import sjefLink from '../../components/sjefLink';
import { error } from 'ajv/dist/vocabularies/applicator/dependencies';

let username = '';
let password = '';
let errorMsg = '';
let tfa = false;

const stripCodeFromString = (inputString) => {
    // Use a regular expression to match the code and the colon
    const regex = /^[0-9]+: /;
    // Replace the matched part with an empty string
    const result = inputString.replace(regex, '');
    return result;
}


const doLogin = async () => {
	try {
		errorMsg = '';

		const loginResult = await app.post('/auth/login', { ident: username, password });

		if (!loginResult.error) {
			console.log('loginResult', loginResult);

			if (loginResult.success === false) {
				errorMsg = stripCodeFromString(loginResult.message);
			}

			tfa = loginResult.data.tfa;

			await app.setUser({
				lvl: loginResult.data.user.lvl,
				token: loginResult.data.token,
				identity: loginResult.data.user, // TODO: rename to loginResult.data.identity
				tfa: tfa,
			});

			// await app.boot();

			console.debug('login success', loginResult);
			return true;
		} else {
			console.log(loginResult)
			
		}
	} catch (err) {
		console.warn('LOGIN ERROR', err);
		return false;
	}
};

const isvalidLoginData = () => {
	return username.length > 6 && password.length > 7;
};

const loginForm = {
	view: ({ attrs: { completed, loggedIn } }) => {
		return m(
			Form,
			{
				align: 'top',
				justify: 'space-between',
			},
			[
				m('h1', 'Welkom bij Sjef'),
				m(FormGroup, [
					m(FormLabel, 'Username'),
					m(Input, {
						onchange: (ev) => {
							username = ev.target.value;
						},
						onkeypress: async (ev) => {
							if (ev.keyCode === 13 || ev.which === 13) {
								// Enter key
								ev.preventDefault();
							}
						},
					}),
				]),
				m(FormGroup, [
					m(FormLabel, 'Password'),
					m(Input, {
						type: 'password',
						onchange: (ev) => {
							password = ev.target.value;
						},
						onkeypress: async (ev) => {
							if (ev.keyCode === 13 || ev.which === 13) {
								// Enter key
								ev.preventDefault();
							}
						},
					}),
				]),
				m(sjefButton, {
					label: 'Login',
					buttonLayout: 'filled large',
					intent: isvalidLoginData() ? 'positive' : 'default',
					onclick: async () => {
						const result = await doLogin();


						if (result === false) {
							return;
						}

						completed();

						if (tfa.required === false) {
							loggedIn();
						}
					},
				}),
				errorMsg &&
				m('p.error', errorMsg),
				m(
					'.flexer',
					m(sjefLink, {
						href: '/forgot-password',
						label: 'Wachtwoord vergeten?',
					})
				),
			]
		);
	},
};

export default loginForm;
