import { Dialog } from 'construct-ui';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';

import m from 'mithril';

const sjefDialog = (props) => {
	const state = { ...props };

	const close = () => {
		if (typeof state.close === 'function') {
			state.close();
		}
	};

	const renderFooter = () => {
		if (props.footer) {
			return props.footer;
		}
		return m(sjefIconX, {
			class: 'iconRemove',
			onclick: () => {
				close();
			},
		});
	};

	return m(
		'.sjefModal',
		m(Dialog, {
			footer: renderFooter(),
			isOpen: state.isOpen,
			isSubmitting: state.isSubmitting,
			closeOnOutsideClick: true,
			onClose: close,
			...props,
		})
	);
};

export default sjefDialog;
