import {
	Button,
	ButtonGroup,
	Checkbox,
	Input,
	ListItem,
	Tooltip,
	Select,
	SelectList,
	Switch,
	Tag,
	Popover,
	Card,
	Drawer,
	Tabs,
	TabItem,
} from 'construct-ui';
import m from 'mithril';
import sjefButton from './sjefButton';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefInput from './sjefInput';
import sjefIconAdjustmentsHorizontal from '@sjefapp/sjeficons/icons/sjefIconAdjustmentsHorizontal';
import { dateInput } from '../../modules/modules/dateInput';
import sjefIconArrowsDiff from '@sjefapp/sjeficons/icons/sjefIconArrowsDiff';
import sjefIconArrowDown from '@sjefapp/sjeficons/icons/sjefIconArrowDown';
import sjefIconArrowUp from '@sjefapp/sjeficons/icons/sjefIconArrowUp';
import tableStore from '../../lib/tableStore';
import sjefIconSearch from '@sjefapp/sjeficons/icons/sjefIconSearch';
import { _t } from '../../lib/i18n';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
/* eslint:disable */

import { Button, Input, Popover, Tag, Tooltip } from 'construct-ui';
import { _t } from '../../lib/i18n';
import tableStore from '../../lib/tableStore';
import sjefIconAdjustmentsHorizontal from '@sjefapp/sjeficons/icons/sjefIconAdjustmentsHorizontal';
import sjefRangeFilter from './sjefRangeFilter';
import sjefIconChevronLeft from '@sjefapp/sjeficons/icons/sjefIconChevronLeft';

class sjefFilter {
	parentOnFilterUpdate;
	activeFilters = {}; // Now directly used for input and filter state
	showAdvancedFilters = true;
	filters;
	defaultFilter;
	isPopoverOpen = false; // Added state to manage popover visibility
	activeTab = 'defaultTabs';
	tabs = ['defaultTabs', 'savedTabs'];

	oninit({ attrs: { onFilterUpdate, filters, defaultFilter } }) {
		this.parentOnFilterUpdate = onFilterUpdate;
		this.filters = filters;
		this.defaultFilter = defaultFilter;
		// Initialize filters, assume 'name' is one of the filters
		this.filters.forEach((filter) => {
			this.activeFilters[filter.name] = '';
		});

		let allShowFilters = this.filters.filter((f) => f.filter !== false);
		if (allShowFilters.length == 1) {
			this.defaultFilter = allShowFilters[0];
		}

		this.initialActiveFilters = JSON.parse(JSON.stringify(this.activeFilters));
	}

	applyFilters() {
		const filteredActiveFilters = {};
		Object.keys(this.activeFilters).forEach((key) => {
			const value = this.activeFilters[key].trim();
			if (value) {
				filteredActiveFilters[`filter[${key}]`] = value;
			}
		});

		this.parentOnFilterUpdate(filteredActiveFilters);
		m.redraw();
	}

	updateFilter(name, value) {
		this.activeFilters[name] = value;

		if (value === '') {
			this.removeFilter(name);
		}

		this.applyFilters();
	}

	isFilterActive() {
		return Object.keys(this.activeFilters).some(key => this.activeFilters[key] != '')
	}

	findTarget(field) {
		if (tableStore.filterData()[`filter[${field.name}]`]) {
			// document.querySelector
			const item = document.querySelector(`.cui-tag[data-filter="${field.name}"]`);

			if (item) {
				return item;
			}
		}
	}

	togglePopover() {
		this.isPopoverOpen = !this.isPopoverOpen;
		m.redraw();
	}

	clearAllFilters() {
		this.activeFilters = JSON.parse(JSON.stringify(this.initialActiveFilters));

		let setFields = document.querySelectorAll('.hasFilterValue .advancedFilterField');

		setFields.forEach(field => {
			switch (field.type) {
				case 'text':
				case 'date':
				case 'password':
				case 'datetime-local':
				case 'email':
				case 'number':
				case 'search':
				case 'tel':
				case 'url':
					field.value = ''; // Set text and similar inputs to empty
					break;
				case 'select-one':
				case 'select-multiple':
					field.selectedIndex = 0; // Reset select fields to the first option
					break;
				// case 'checkbox':
				// case 'radio':
				// 	field.checked = field.defaultChecked; // Reset checkboxes and radios to their default state
				// 	break;
				default:
					console.log(`Unhandled field type: ${field.type}`); // Log any unhandled types
					break;
			}
		});
	

		this.applyFilters();
	}

	getTabLabel(tab) {
		if (tab == 'defaultTabs') {
			return _t('_.filterTabs.defaultTabs')
		} else if (tab == 'savedTabs') {
			return _t('_.filterTabs.savedTabs')
		}
	}

	removeFilter(key) {
		this.activeFilters[key] = '';
		const filterKey = `filter[${key}]`;
		// Remove from local active filters state
		if (this.activeFilters[filterKey]) {
			delete this.activeFilters[filterKey];
			// Optionally, also clear from any global or shared state
			delete tableStore.filterData()[filterKey];
		}

		// Reset the associated input or select field
		// This assumes you can directly manipulate the value. Depending on your setup, this may need to be handled by mithril-managed properties or states.
		const elem = document.querySelector(`[data-filter="${key}"]`);
		if (elem) {
			if (elem.tagName === 'SELECT') {
				elem.value = ''; // Reset select to the default/empty option
			} else {
				elem.value = ''; // Reset input field
			}
		}

		this.applyFilters(); // Re-apply filters to update the view
	}

	view(vnode) {
		return m(
			'.filters',
			m(
				'.filterBar',
				{
					class: this.isPopoverOpen ? 'isOpen' : '',
				},
				this.isPopoverOpen &&
				m('.popoverCloser', {
					onclick: () => this.togglePopover(),
				}),
				m('.inputFilterWrapper',
					m(sjefInput, {
						type: 'text',
						readonly: !this.defaultFilter,
						placeholder: this.defaultFilter ? _t('_.search.search') : 'Open filters',
						icon: m(''),
						value: this.defaultFilter ? this.activeFilters[this.defaultFilter.name] : '',
						onChange: (value) => {
							if (this.defaultFilter) {
								this.updateFilter(this.defaultFilter?.name, value);
							}
						},
						onclick: () => {
							if (!this.defaultFilter) {
								this.togglePopover(); // Opens the popover if no default filter is selected
							}
						},
						onkeydown: (e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								this.applyFilters();
							}
						},
					}),
					m('.iconHover.filterToggle', { onclick: () => this.togglePopover() }, m(sjefIconAdjustmentsHorizontal)), // remove when switching to popover
					this.isFilterActive() ?
						m('.iconHover.clearFilters', { onclick: () => this.clearAllFilters() }, m(sjefIconX)) // remove when switching to popover
					: void(0),
				),
				m(Drawer, {
					isOpen: this.isPopoverOpen, // Control the display of the popover based on state
					hasArrow: false,
					closeOnEscapeKey: true,

					// hasBackdrop: true, // when switching to popover
					// backdropClass: 'light',  // when switching to popover
					// position: 'bottom-start',  // when switching to popover
					position: 'left',

					onClose: () => this.togglePopover(), // Toggle popover state on close
					class: 'filter',
					trigger: m('.iconHover.filterToggle', { onclick: () => this.togglePopover() }, m(sjefIconAdjustmentsHorizontal)),
					content: [
						m(
							'form',
							{
								onsubmit: (e) => {
									e.preventDefault();
									this.applyFilters();
								},
							},
							[
								m(
									'.flexer.center',
									m('h2', _t('_.labels.filters')),

									m(
										'.iconHover',
										{
											onclick: () => {
												this.togglePopover();
											},
										},
										m(sjefIconChevronLeft)
									)
								),
								m(
									'.subMenuTabs.list',
									m(Tabs, {
										class: 'list-' + this.tabs.length + ' active-' + (this.tabs.indexOf(this.activeTab) + 1),
									}, [
										this.tabs.map((tab) => {
											return m(TabItem, {
												key: tab,
												label: this.getTabLabel(tab),
												active: tab == this.activeTab,
												onclick: () => {
													this.activeTab = tab
													m.redraw();
												},
											});
										}),
									])
								),
								m(
									'.filter-inner',
									this.activeTab == 'defaultTabs' ? [
									m(
										'.filter-fields',
										this.filters
											.filter((f) => f.filter !== false && f.listShow !== false)
											.map((filter) => {
												return m(
													'.filter-field',
													{
														class: this.activeFilters[filter.name] ? 'active' : '',
													},
													[
														m('label', { for: `filter-${filter.name}` }, _t(`_.fields.${filter.name}`)),
														this.activeFilters[filter.name] ?
														m(sjefButton, {
															label: 'remove',
															class: 'filter-remove',
															clickState: false,
															buttonLayout: 'remove',
															onclick: () => {
																this.activeFilters[filter.name] = '';
																this.removeFilter(filter.name);
															}
														}) : void(0),
														filter.popoverFilterType === 'select'
															? m(sjefInput, {
																	id: `filter-${filter.name}`,
																	type: 'select',
																	emptyOption: true,
																	options: filter.popoverFilterOptions || filter.options,
																	onChange: (value) => {
																		this.updateFilter(filter.name, value);
																	},
																	value: this.activeFilters[filter.name],
															  })
															: filter.popoverFilterType === 'range'
															? [
																	m(sjefRangeFilter, {
																		type: filter.type,
																		onChange: (filterString) => {
																			this.updateFilter(filter.name, filterString);
																		},
																	}),
															  ]
															: m(sjefInput, {
																	id: `filter-${filter.name}`,
																	type: filter.type || 'text',
																	onChange: (value) => {
																		this.updateFilter(filter.name, value);
																	},
																	value: this.activeFilters[filter.name],
																	placeholder: 'Enter ' + filter.name,
															  }),
													]
												);
											})
									),
									m(
										'.flex.spread.filterButtons',
										m(sjefButton, {
											type: 'submit',
											buttonLayout: 'filled',
											label: _t('_.buttons.applyFilters'),
											onclick: () => {
												this.applyFilters();
												this.togglePopover();
											},
										}),
										m(sjefButton, {
											buttonLayout: 'inline',
											label: _t('_.buttons.clearFilters'),
											onclick: () => this.clearAllFilters(),
										})
									)
								] : void(0)
								),
							]
						),
					],
				}),
				this.showAdvancedFilters
					? this.filters.some((field) => field.advancedFilterType) &&
							m(
								'.advancedFilters',
								{
									class: this.filters.filter((field) => field.advancedFilterType).length >= 4 ? 'wrappedAdvanced' : '',
								},
								this.filters
									.filter((field) => field.advancedFilterType)
									.map((field) => {
										return m(
											'',
											m(
												'.advancedFilter',
												{
													onmouseover: () => {
														let target = this.findTarget(field);

														if (target) {
															target.classList.add('js-filterHover');
															m.redraw();
														}
													},
													onmouseleave: () => {
														let target = this.findTarget(field);

														if (target) {
															target.classList.remove('js-filterHover');
															m.redraw();
														}
													},
													class: [
														tableStore.filterData()[`filter[${field.name}]`] ? 'hasFilterValue' : '',
														field.advancedFilterType,
														field.icon ? 'hasIcon' : '',
													].join(' '),
												},
												m(
													'.filterTitle truncate-small',
													field.name,
													field.advancedFilterType === 'date' && field.advancedFilterTypeDateRange
														? [
																m(
																	'.dateFilterActions',
																	m(
																		'div',
																		{
																			'data-filter': `${field.name}RangeToggle`,
																			class: this.dateTypeToggle[field.name] === 'after' ? 'active' : '',
																			onclick: () => {
																				// Toggle active state for Arrow Up
																				this.dateTypeToggle[field.name] =
																					this.dateTypeToggle[field.name] === 'after' ? false : 'after';

																				m.redraw();
																			},
																		},
																		m(sjefIconArrowUp)
																	),

																	m(
																		'div',
																		{
																			'data-filter': `${field.name}RangeToggle`,
																			class: this.dateTypeToggle[field.name] === 'before' ? 'active' : '',
																			onclick: () => {
																				// Toggle active state for Arrow
																				this.dateTypeToggle[field.name] =
																					this.dateTypeToggle[field.name] === 'before' ? false : 'before';

																				m.redraw();
																			},
																		},
																		m(sjefIconArrowDown)
																	),

																	m(
																		'div',
																		{
																			'data-filter': `${field.name}RangeToggle`,
																			class: this.dateTypeToggle[field.name] === 'range' ? 'active' : '',
																			onclick: () => {
																				// Toggle active state for sjefIconArrowsMoveHorizontal
																				this.dateTypeToggle[field.name] =
																					this.dateTypeToggle[field.name] === 'range' ? false : 'range';

																				m.redraw();
																			},
																		},
																		m(sjefIconArrowsDiff)
																	)
																),
														  ]
														: void 0
												),
												field.advancedFilterType == 'select'
													? [
															m(
																'select',
																{
																	class: 'truncate-small advancedFilterField',
																	'data-filter': field.name,
																	onchange: (evt) => {
																		console.log(field.name, evt.target.value)
																		if (evt.target.value) {
																			this.updateFilter(field.name, evt.target.value);
																		} else {

																			this.removeFilter(field.name);
																		}

																		m.redraw();
																	},
																},
																m('option', { value: '' }, ''),
																(field.advancedFilterOptions || field.options)?.map((option) =>
																	m('option', { value: option }, option)
																)
															),
															field.icon ? m(field.icon) : void 0,
													  ]
													: field.advancedFilterType == 'boolean'
													? m(Checkbox, {
															'data-filter': field.name,
															checked: tableStore.filterData()['filter[' + field.name + ']'] ? true : false,
															onchange: (evt) => {
																if (evt.target.checked) {
																	this.updateFilter(field.name, evt.target.checked);
																} else {
																	this.removeFilter(field.name);
																}

																m.redraw();
															},
													  })
													: field.advancedFilterType === 'date'
													? [
															this.dateTypeToggle[field.name] === 'range'
																? [
																		m(
																			'.rangeWrapper',
																			m(Input, {
																				class: 'advancedFilterField',
																				'data-filter': `${field.name}From`,
																				type: 'datetime-local',
																				value: this.dateRangeValues[field.name]?.from,
																				onchange: (evt) => {
																					// Ensure the object exists before setting a property
																					if (!this.dateRangeValues[field.name]) {
																						this.dateRangeValues[field.name] = {};
																					}
																					this.dateRangeValues[field.name].from = evt.target.value;

																					if (
																						this.dateRangeValues[field.name].from &&
																						this.dateRangeValues[field.name].to
																					) {
																						this.updateFilter(
																							field.name,
																							`between,${this.dateRangeValues[field.name].from},${
																								this.dateRangeValues[field.name].to
																							}`
																						);
																					}
																					this.applyFilters();
																				},
																			}),
																			m(Input, {
																				'data-filter': `${field.name}To`,
																				type: 'datetime-local',
																				class: 'advancedFilterField',
																				value: this.dateRangeValues[field.name]?.to,
																				min: this.dateRangeValues[field.name]?.from,
																				onchange: (evt) => {
																					if (
																						new Date(evt.target.value) <
																						new Date(this.dateRangeValues[field.name].from)
																					) {
																						alert(
																							'The "enddate" date must not be earlier than the "startdate" date.'
																						);
																						evt.target.value = this.dateRangeValues[field.name].from;
																					} else {
																						if (!this.dateRangeValues[field.name]) {
																							this.dateRangeValues[field.name] = {};
																						}
																						this.dateRangeValues[field.name].to = evt.target.value;

																						if (
																							this.dateRangeValues[field.name].from &&
																							this.dateRangeValues[field.name].to
																						) {
																							this.addFilter({
																								name: field.name,
																								value: `between,${
																									this.dateRangeValues[field.name].from
																								},${this.dateRangeValues[field.name].to}`,
																							});
																						}
																						this.applyFilters();
																					}
																				},
																			})
																		),
																  ]
																: m(Input, {
																		'data-filter': field.name,
																		class: 'advancedFilterField',
																		type: !this.dateTypeToggle[field.name] ? 'date' : 'datetime-local',
																		value: tableStore.filterData()['filter[' + field.name + ']'],
																		onchange: (evt) => {
																			this.updateFilter(
																				field.name,
																				`${this.dateTypeToggle[field.name]},${evt.target.value}`
																			);
																		},
																  }),
													  ]
													: void 0
											)
										);
									})
							)
					: void 0
			),
			Object.entries(this.activeFilters).some(([key, value]) => value) &&
				m(
					'.activeFilters',
					Object.keys(this.activeFilters)
						.filter((key) => this.activeFilters[key])
						.map((key) =>
							m(Tooltip, {
								content: key,
								trigger: m(Tag, {
									label: `${key}: ${this.activeFilters[key]}`,
									onRemove: () => {
										this.updateFilter(key, '');
										this.applyFilters();
									},
								}),
							})
						)
				)
		);
	}
}

export default sjefFilter;
