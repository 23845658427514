import { List, ListItem } from 'construct-ui';
import m from 'mithril';
import { _t } from '../../../lib/i18n';
import sjefChart from '../../../views/components/sjefChart';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwProfileCustomerData {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	customerData;
	fieldsToDisplay = ['name', 'email']; // Fields you want to display

	oninit(vnode) {
		// this.customerData = { name: 'Gijs Hoppen', age: '32', email: 'gijs@sjef.app', phoneNumber: '0612344321' };
		this.customerData = vnode.attrs.profile;
	}

	view(vnode) {
        console.log(vnode);
        return m(
            '.profileComponent',
            m('h3', 'Customer Data'),
            m('.dataList',
                this.fieldsToDisplay.map((field) => {
                    const value = this.customerData ? this.customerData[field] : 'n/a';
                    return m('.dataItem', [
                        m('span.key', _t(`_.fields.${field}`)),
                        m('span.value', value ? value : 'n/a')
                    ]);
                })
            ),
        );
    }
}

export default vwProfileCustomerData;
