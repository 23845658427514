import { Card } from 'construct-ui';
import m from 'mithril';
import app from '../../../app';
import { _t, _ct } from '../../../lib/i18n';
import sjefChart from '../../../views/components/sjefChart';
import { price } from '../../../lib/helpers';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwProfileCupsDeposits {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */

	oninit(vnode) {
		this.cupsData = { 
			paid: '195', 
			returned: '188', 
			averageCupPrice: '1.50', 
			moneyMade: '10.50'
		};
	}

	view(vnode) {
		return m(
			'.profileComponent',
			m('h3', 'Cups Deposits'),
			m('.columns-2',
				m('.dataList',
					Object.entries(this.cupsData).map(([key, value]) => {
						return m('.dataItem', [
							m('span.key', _ct(`_.fields.${key}`)),
							m('span.value', { 
								style: {'text-align': 'right'}
							}, price(value))
						]);
					})
				),
				m(sjefChart, {
					id: 'customerData',
					dataObj: { Paid: 195, Returned: 188 },
					// colors: ['#ffcc00', 'purple'],
					type: 'bar',
					size: 100,
					position: 'top'
				})
			)
		);
	}
}

export default vwProfileCupsDeposits;
