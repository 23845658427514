[
	{
		"id": "createEvent",
		"label": "Create Event",
		"route": "",
		"module": "vwLockerLanding"
	},
	{
		"id": "lockerEvents",
		"label": "Events",
		"route": "/lockermanagement/events",
		"module": "vwList",
		"config": "lockerEvents",
		"children": [
			{
				"id": "lockerEvent",
				"label": "lockerEvent",
				"hidden": true,
				"route": ":id",
				"module": "vwEventOverview",
				"config": "lockerEvents"
			}
		]
	},
	{
		"id": "lockerTickets",
		"label": "Tickets",
		"route": "/lockermanagement/tickets",
		"module": "vwTickets",
		"config": "tickets",
		"hidden": true
	},
	{
		"id": "cabinets",
		"label": "Cabinets",
		"route": "/lockermanagement/cabinets",
		"module": "vwList",
		"config": "cabinets",
		"children": [
			{
				"id": "cabinet",
				"label": "Cabinet",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "cabinets"
			}
		]
	},
	{
		"id": "lockers",
		"label": "Lockers",
		"route": "/lockermanagement/lockers",
		"module": "vwList",
		"config": "lockers",
		"children": [
			{
				"id": "locker",
				"label": "Locker",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "lockers"
			}
		]
	},
	{
		"id": "maintenance",
		"label": "Maintenance",
		"route": "/lockermanagement/maintenance",
		"module": "vwList",
		"config": "maintenance",
		"children": [
			{
				"id": "singeMaintenance",
				"label": "Maintenance",
				"hidden": true,
				"route": ":id",
				"module": "vwForm",
				"config": "maintenance"
			}
		]
	}
]

