/* eslint:disable */
import sjefIconMoodAngry from '@sjefapp/sjeficons/icons/sjefIconMoodAngry';
import m from 'mithril';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefIconMoodHappy from '@sjefapp/sjeficons/icons/sjefIconMoodHappy';

const waitForTransition = (resolveTimer = 300) => {
	return new Promise(function (resolve) {
		setTimeout(() => {
			resolve();
		}, resolveTimer);
	});
};

class sjefCallout {
	parentOnClose;

	onbeforeremove = (vnode) => {
		vnode.dom.classList.add('transitionExitCallout');
		return waitForTransition(200);
	}

	closeCallout() {
		if (typeof this.parentOnClose === 'function') {
			this.parentOnClose();
		}
	}

	oninit({ attrs: { onClose } }) {
		if (typeof onClose === 'function') {
			this.parentOnClose = onClose;
		}
	}

    view({ attrs: { 
		classes = [], 
		title, 
		type, 
		subcontent = false,
		dismissable = true, 
		content = '', 
		color = "currentColor", 
		onclick = void(0), ...htmlAttrs  }}){
        var htmlAttrsClass = htmlAttrs.class || void(0);
        delete htmlAttrs.class;
		
		var icon = type == 'info' ? m(sjefIconMoodHappy) : type == 'danger' ? m(sjefIconMoodAngry) : void(0); 
        
        return m('.sjefCallout', {
                class : [
                    htmlAttrsClass,
                    type,
                     ...classes].join(" "),
                    ...htmlAttrs,
					
            },
			icon,
			m('',
				title ? m('h4', title) : void(0),
				content ? m('p', content) : void(0),
				subcontent ? 
					m('.subcontent',
						subcontent
					) 
					: void(0)
			),
			dismissable ? 
			m(sjefIconX, {
				class: 'iconRemove',
				onclick: () => this.closeCallout()
			}) : void(0),
        )
    }
}
export default sjefCallout;