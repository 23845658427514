import vwExampleTab from '../modules/_default/tabs/vwExampleTab';

const moduleName = 'suppliers';
const baseRoute = '/crm/suppliers';
const endpoint = '/masterdata/suppliers';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
			{
				slug: 'example',
				component: vwExampleTab,
			},
		];
		this.schema = [
			{
				groupName: 'Address',
				class: '.grid-3',
				fields: [
					{
						name: 'address',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'address2',
						type: 'string',
						active: false,
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'city',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 4,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'country',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 5,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'zipcode',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
				],
			},
			{
				groupName: 'ContactInfo',
				class: '.grid-2',
				fields: [
					{
						name: 'email',
						type: 'string',
						active: false,
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'phoneNumber',
						type: 'string',
						active: false,
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'phoneNumberCountryCode',
						type: 'string',
						active: false,
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
				],
			},
			{
				groupName: 'General',
				class: '.grid-1',
				fields: [
					{
						name: 'active',
						type: 'boolean',
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						required: true,
					},
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
						minimum: 1,
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						minLength: 1,
						maxLength: 255,
						nullable: true,
						required: true,
					},
					{
						name: 'archived',
						type: 'boolean',
						defaultValue: false,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						required: true,
					},
				],
			},
		];
	}
}
