import m from 'mithril';
import sjefIconArrowUp from '@sjefapp/sjeficons/icons/sjefIconArrowUp';
import sjefApexChart from './sjefApexChart';
import { Card } from 'construct-ui';

class sjefBalanceChart {
	oninit({ attrs }) {
		this.key = attrs.key;
		this.color = attrs.color;
		this.options = attrs.options;
		this.data = attrs.data;

		this.title = attrs.title;
		this.amount = attrs.amount;
		this.subTitle = attrs.subTitle;
		this.icon = attrs.icon;
		this.bottomRow = attrs.bottomRow;
		this.leftTop = attrs.leftTop;
	}
	view() {
		return m(Card, {
			id: 'chart_card', style: {
				paddingLeft: 0,
				paddingRight: 0,
			}
		}, [
			m('.balance-chart__header', [
				m('.balance-chart__header-title', this.title),
				m('.balance-chart__header-amount', this.amount),
				m('.balance-chart__header-subtitle', [
					this.icon && m(sjefIconArrowUp, {
						size: 18
					}), this.subTitle]
				),
			]),
			m('', { style: { marginLeft: '1rem' } }, this.leftTop),
			m(sjefApexChart, {
				style: 'minimal',
				color: this.color,
				options: this.options,
				data: this.data
			}), this.bottomRow
		]
		)
	}
}

export default sjefBalanceChart;
