import m from 'mithril';
import sjefTag from '../../../views/components/sjefTag';
import app from '../../../app';
import { price } from '../../../lib/helpers';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwProfileTotalSpent {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */

	totalSpent;
	lastEventSpent;
	profile;

	oninit(vnode) {
		this.profile = vnode.attrs.profile;
		this.transactions = vnode.attrs.transactions;

		this.totalSpent = this.transactions.reduce((acc, transaction) => {
			return acc + parseFloat(transaction.total);
		}, 0);		
		
		this.lastEventSpent = 1.25;
	}

	view() {
		return m(
			'.profileComponent',
			m('h3', 'Total Spent'),
			m('.digitLarge', 
				price(this.totalSpent)
			),
			m(sjefTag, {
				content: price(this.lastEventSpent),
				description: 'in last event',
			}),
		);
	}
}

export default vwProfileTotalSpent;
