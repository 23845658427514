import m from 'mithril';

export const SkeletonText = {
	view: () => m('.skeleton.skeleton-text')
}
export const SkeletonTextBody = {
	view: () => m('.skeleton.skeleton-text.skeleton-text__body')
}
export const SkeletonImage = {
	view: ({ attrs }) => m('img.skeleton', { width: attrs.width, height: attrs.height })
}

export default SkeletonText
