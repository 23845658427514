/**
 * @fileOverview This module provides a form to create a store in Adyen Balance Platform.
 * @module vwPosSettingsModal
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import { Form, FormLabel, FormGroup, Input, Select, Button, Checkbox } from 'construct-ui';
import validation from '@sjefapp/sjef-validation';

import sjefModal from '../../../views/components/sjefModal';
import constants from '../../../lib/constants';
import response from '../../../lib/response';
import app from '../../../app';
import sjefIconSettings from '@sjefapp/sjeficons/icons/sjefIconSettings';
import sjefButton from '../../../views/components/sjefButton';
import sjefIconArrowBigUpLine from '@sjefapp/sjeficons/icons/sjefIconArrowBigUpLine';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';
import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';

const levels = {
	terminal: 7,
	location: 8,
	default: 9,
};
/**
 * Object defining the view component of the form, including initialization and rendering logic.
 */
class vwPosSettingsModal {
	data = {
		menuId: {},
		coins: {},
	};
	level;
	title = '';

	/**
	 * Define validation schema for the form data.
	 */
	validationSchema = {
		type: 'object',
		// properties: {
		// 	menuId: {
		// 		type: 'integer',
		// 		minimum: 1,
		// 	},
		// 	coins: {
		// 		type: 'boolean',
		// 		minimum: 1,
		// 	},
		// },

		properties: {
			menuId: {
				type: 'object',
				properties: {
					value: {
						type: 'integer',
						minimum: 1
					}
				},
				required: ['value']
			},
			coins: {
				type: 'object',
				properties: {
					value: {
						type: 'boolean',
					}
				},
				required: ['value']
			}
		},

		// required: ['menuId'],
	};
	validationErrors = {};

	/**
	 * Initialize the component with the provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {Object} vnode.attrs - The attributes passed to the component.
	 * @param {string} vnode.attrs.title - The ID of the client.
	 * @param {Function} vnode.attrs.callbackOnSuccess - The function to call when the action completes with success.
	 */
	oninit = async ({ attrs }) => {
		console.log('attrs', attrs);
		this.level = attrs.level; // 10 = terminal, 20 = location, 30 = default
		this.title = attrs.title;
		this.locationId = attrs.locationId;
		this.identId = attrs.identId;
	};

	/**
	 * Asynchronous function to handle form submission.
	 * Validates the form data according to the defined schema,
	 * makes a request to create a business line with the validated data,
	 * handles the response, and provides user feedback.
	 *
	 * @returns {object} a Promise resolving to a response object
	 */
	onSubmit = async (field = false) => {
		console.log('dataToSubmit', this.data);

		let data = field ? { [field]: this.data[field] } : { ...this.data };

		if (validation.validate(this.validationSchema, data).valid === false) {
			this.validationErrors = validation.validate(this.validationSchema, data, { collectErrors: true }).errors.reduce((acc, error) => {
				console.log(error);
				// Slice(1) is used to remove the leading '/' from the instancePath
				acc[error.instancePath.slice(1)] = error.message;
				return acc;
			}, {});

			return response.failed('0422', constants.MESSAGE_INVALID_DATA);
		}

		const postData = this.formatData(data);

		const result = await app.post('/terminals/administration/terminals/settings', {
		// const result = await app.post('http://127.0.0.1:3017/administration/terminals/settings', {
			type: 'turbopos',
			locationId: this.locationId,
			identId: this.identId,
			settings: postData,
		});

		if (result.success === false) {
			// alert(result.error);
			return response.failed('1111', result.error);
		}
		// this.callbackOnSuccess();
		app.toast('Saved', {
			intent: 'positive',
		});
		return response.success();
	};

	/**
	 * Asynchronous function to handle form submission.
	 * Validates the form data according to the defined schema,
	 * makes a request to create a business line with the validated data,
	 * handles the response, and provides user feedback.
	 *
	 * @returns {object} a Promise resolving to a response object
	 */
	onDeleteKey = async (key) => {
		const result = await app.delete('/terminals/administration/terminals/settings', {
		// const result = await app.delete('http://127.0.0.1:3017/administration/terminals/settings', {
			type: 'turbopos',
			locationId: this.locationId,
			identId: this.identId,
			key: key,
		});

		if (result.success === false) {
			// alert(result.error);
			return response.failed('1111', result.error);
		}
		// this.callbackOnSuccess();
		app.toast('Saved', {
			intent: 'positive',
		});

		// return response.success();
		return result;
	};

	/**
	 * Resets the `data` variable to an empty object.
	 * @function onOpen
	 */
	onOpen = async () => {
		// load data
		const result = await app.post('/terminals/administration/terminals/settings/retrieve', {
		// const result = await app.post('http://127.0.0.1:3017/administration/terminals/settings/retrieve', {
			type: 'turbopos',
			locationId: this.locationId,
			identId: this.identId,
		});

		if (result && result.data) {
			this.data = result.data;

			this.initialData = JSON.parse(JSON.stringify(this.data));
		}
	};


	formatData = (data) => {
		const formattedData = {};
	
		Object.keys(data).forEach(key => {
			formattedData[key] = data[key].value;
		});
	
		return formattedData;
	}

	/**
	 * Render the component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	view() {
		return m(sjefModal, {
			onOpen: this.onOpen,
			onSubmit: this.onSubmit,
			modalTitle: `Settings ${this.title}`,
			buttonLabel: m(sjefIconSettings),
			buttonLayout: 'filled small',
			footer: false,
			modalSize: 'lg',
			modalContent: m(
				'',
				m(
					Form,
					{
						class: 'columns-2',
						onsubmit: () => {
							return false;
						},
					},
					[
						m(FormGroup, {
							content: [
								m(FormLabel, { for: 'menuId' }, 'Menu'),
								m(
									'',
									// m('', levels[this.level]),
									// m('', levels[this.data.menuId.level]),
									// m('', this.data.menuId.level),

									// m('small', this.validationErrors['name'] ? this.validationErrors['name'] : '')

									m('.editFieldWrapper', {
										class: levels[this.data.menuId.level] > levels[this.level] ? 'inherited' : ''
									},
										m('.inputWrapper',
											m(Input, {
												id: 'menuId',
												disabled: levels[this.data.menuId.level] > levels[this.level] &&
												this.data.menuId.edit !== true,
												intent: this.validationErrors['name'] ? 'negative' : '',
												value: this.data.menuId.value,
												oninput: (ev) => {
													this.data.menuId.edit = true;
													const value = ev.currentTarget.value;
													this.data.menuId.value = value === '' ? '' : parseInt(value);
												},
											}),
											m(sjefIconArrowBigUpLine, { class: 'inherit' }),
										),
										levels[this.data.menuId.level] > levels[this.level] ?
										m(Button, {
											class: 'fieldEdit',
											label: m(sjefIconEdit),
											onclick: () => {
												this.data.menuId.edit = true;
												this.data.menuId.editTriggered = true;
												this.data.menuId.level = this.level;
											}
										}) :
										[
											m(Button, {
												class: 'fieldEdit',
												disabled: !this.data.menuId.edit,
												label: m(sjefIconCheck),

												onclick: async () => {
													this.data.menuId.editTriggered = false;
													this.data.menuId.edit = false;
													this.onSubmit('menuId');
												}
											}),
											m(Button, {
												class: 'fieldEdit',
												disabled: levels[this.data.menuId.level] > levels[this.level],
												label: m(sjefIconX),

												onclick: async () => {
													if (!this.data.menuId.editTriggered) {
														let result = await this.onDeleteKey('menuId');

														if (result.success == true) {
															this.data = result.data;
														}
	
														m.redraw();
													} 

													this.data.menuId = JSON.parse(JSON.stringify(this.initialData.menuId));
												}
											})
										],
									),

									m('small', this.validationErrors['name'] ? this.validationErrors['name'] : '')
								),
							],
						}),

						m(FormGroup, {
							content: [
								m(FormLabel, { for: 'coins' }, 'Coins'),
								m('.editFieldWrapper',
									{
										class: levels[this.data.coins.level] > levels[this.level] ? 'inherited' : ''
									},
									m('.inputWrapper',
										{
											class: this.data.coins.edit ? '' : 'disabled'
										},
										m(Checkbox, {
											id: 'coins',
											checked: this.data.coins.value,
											disabled: levels[this.data.coins.level] > levels[this.level] && this.data.coins.edit !== true, 
											onchange: (ev) => {
												this.data.coins.value = ev.currentTarget.checked;
												this.data.coins.editTriggered = true;
												this.data.coins.edit = true;

												m.redraw()
											},
										}),
										levels[this.data.coins.level] > levels[this.level] ? 
										m(sjefIconArrowBigUpLine, { class: 'inherit' }) : null,
									),
									levels[this.data.coins.level] > levels[this.level] && !this.data.coins.edit ?
									m(Button, {
										class: 'fieldEdit',
										label: m(sjefIconEdit),
										onclick: () => {
											this.data.coins.edit = true;
											this.data.coins.editTriggered = true;
											this.data.coins.level = this.level;

											m.redraw();
										}
									}) :
									[
										m(Button, {
											class: 'fieldEdit',
											disabled: !this.data.coins.edit, // Button is enabled if in edit mode
											label: m(sjefIconCheck),
											onclick: () => {
												this.data.coins.edit = false;
												this.onSubmit('coins');

												m.redraw();
											}
										}),
										m(Button, {
											class: 'fieldEdit',
											// disabled: !this.data.coins.edit, // Button is enabled if in edit mode
											label: m(sjefIconX),
											onclick: async () => {
												if (!this.data.coins.editTriggered) {
													let result = await this.onDeleteKey('coins');

													if (result.success == true) {
														this.data = result.data;
													}

													m.redraw();
												} 

												this.data.coins = JSON.parse(JSON.stringify(this.initialData.coins));

												m.redraw();
											}
										})
									]
								),
								m('small', this.validationErrors['name'] ? this.validationErrors['name'] : '')
							]
						})
						

					
					]
				)
			),
		});
	}
}

export default vwPosSettingsModal;
