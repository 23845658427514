const moduleName = 'locations';
const baseRoute = '/masterdata/locations';
const endpoint = '/masterdata/locations';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
					},
					{
						name: 'active',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						required: true,
					},
					{
						name: 'bic',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'branchId',
						type: 'integer',
						formInputType: 'selectAsync',
						asyncEndpoint: '/masterdata/branches',
						asyncField: 'branchId',
						asyncFieldLabel: 'branch',
						asyncFilterOn: 'name',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'email',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 4,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'iban',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 5,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'companyNumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 6,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgNormal',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 7,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'imgThumbnail',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 8,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'latitude',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 9,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'longitude',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 10,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'merchantName',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 12,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'slug',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 13,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'taxNumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 14,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'phoneNumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 15,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'phoneNumberCountryCode',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 16,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'position',
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 17,
						listDragSort: true,
						listShow: false,
						listSize: 'small',
						minimum: 1,
						nullable: true,
						required: true,
					},
				],
			},
			{
				groupName: 'Address',
				class: 'column',
				fields: [
					{
						name: 'address',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 18,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'address2',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 19,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'zipcode',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 20,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'city',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 21,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
					{
						name: 'country',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 22,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: true,
						required: true,
					},
				],
			},
		];
	}
}
