import m from 'mithril';
import { _t } from '../../lib/i18n';
import app from '../../app';
import { Card, Tag } from 'construct-ui';
import tableStore from '../../lib/tableStore';
import sjefInput from '../../views/components/sjefInput';
import sjefButton from '../../views/components/sjefButton';
import { Grid, Col } from 'construct-ui';
import sjefIconTrendingUp from '@sjefapp/sjeficons/icons/sjefIconTrendingUp';
import sjefIconCurrencyDollar from '@sjefapp/sjeficons/icons/sjefIconCurrencyDollar';
import vwOnboardAdyenModal from './components/vwOnboardAdyenModal';
import sjefWidget from '../../views/components/sjefWidget';
import sjefBalanceChart from '../../views/components/sjefBalanceChart';
import sjefList from '../../views/components/sjefList';
import COLORS from '../../utils/colors';
import sjefSelect from '../../views/components/sjefSelect';
import sjefLoading from '../../views/components/sjefLoading';
import sjefBankCard from '../../views/components/sjefBankCard';
import sjefIconCards from '@sjefapp/sjeficons/icons/sjefIconCards';
import sjefHeader from '../../assets/img/headers/029.jpg';
import CardWithHeader from '../../views/components/sjefCardWithHeader';
import FeatureFlags, { runningPaymentsFlag } from '../../featureFlags';
import periods from '../../utils/periods';

const blurredData = () => {
	return m('.blur', [
		m(`.columns-4`, [
			m(sjefWidget, {
				color: widgetIcon.settled.color,
				title: _t(`modules.balanceoverview.widgets.settled`),
				subTitle: `${currency['EUR']}100`,
				icon: widgetIcon.settled.icon,
				progress: 100,
				style: { textTransform: 'capitalize' },
			}),
			m(sjefWidget, {
				color: widgetIcon.invoiced.color,
				title: _t(`modules.balanceoverview.widgets.invoiced`),
				subTitle: `${currency['EUR']}100`,
				icon: widgetIcon.invoiced.icon,
				progress: 100,
				style: { textTransform: 'capitalize' },
			}),
			m(sjefWidget, {
				color: widgetIcon.availableForPayout.color,
				title: _t(`modules.balanceoverview.widgets.availableForPayout`),
				subTitle: `${currency['EUR']}100`,
				icon: widgetIcon.availableForPayout.icon,
				progress: 100,
				style: { textTransform: 'capitalize' },
			}),
			m(sjefWidget, {
				color: widgetIcon.payout.color,
				title: _t(`modules.balanceoverview.widgets.payout`),
				subTitle: `${currency['EUR']}100`,
				icon: widgetIcon.payout.icon,
				progress: 100,
				style: { textTransform: 'capitalize' },
			}),

		]),
		m(Grid, { gutter: 36, style: { marginTop: '1rem' } }, [
			[
				m(Col, { span: 8, style: { paddingLeft: '18px', paddingRight: '18px' } }, [
					m(sjefBalanceChart, {
						color: COLORS.purple.default,
						title: _t('modules.balanceoverview.account_balance'),
						amount: 2000,
						options: {
							series: [
								{
									name: 'price',
									data: [0, 100, 150, 200],
								},
							],
							xaxis: {
								type: 'datetime',
								categories: ['2021-01-01', '2021-01-02', '2021-01-03', '2021-01-04'],

							},
						},
					}),
					m(sjefList, {
						title: _t('modules.balanceoverview.recent_payouts'),
						data: [{
							'date/time': '2024-05-23T09:00:00.000Z',
							amount: '100',
							currency: 'EUR',
							reference: '123456',
							type: 'payout',

						}, {
							'date/time': '2024-05-23T09:00:00.000Z',
							amount: '100',
							currency: 'EUR',
							reference: '123456',
							type: 'payout',

						}, {
							'date/time': '2024-05-23T09:00:00.000Z',
							amount: '100',
							currency: 'EUR',
							reference: '123456',
							type: 'payout',

						}, {
							'date/time': '2024-05-23T09:00:00.000Z',
							amount: '100',
							currency: 'EUR',
							reference: '123456',
							type: 'payout',

						}
						].map((item) => {
							return {
								'date/time': new Date(item.createdAt).toDateString(),
								price: m('span.list__cell--bold', `${currency[item.currency]}${item.amount}`),
								reference: m('span.list__cell--bold', item.reference),
								type: m(Tag, {
									label: item.type,
									rounded: true,
									size: 'xs',
									style: tagStyle[item.type],
								}),
							};
						}
						),

					}),
				]),
			],
			m(Col, { span: 4 }, [
				m(
					Card,
					[
						m(sjefBankCard, {
							title: 'Test Bank Card',
							bankNumber: 'this.balanceAccountId',
						}),
						m(sjefSelect, {
							prefix: 'balanceoverview',
							iconLeft: sjefIconCards,
							options: ['1', '2', '3'],
							type: 'wide',
							useValueAsLabel: true,

						}),

					]
				),

			]),
		]),
	]);
}

const tagStyle = {
	payout: { backgroundColor: COLORS.green.muted, color: COLORS.green.default },
	settlement: { backgroundColor: COLORS.purple.muted, color: COLORS.purple.default },
	Failed: {
		backgroundColor: COLORS.red.muted,
		color: COLORS.red.default,
	},
};

const widgetIcon = {
	settled: {
		color: COLORS.green.default,
		icon: sjefIconTrendingUp,
	},
	payout: {
		color: COLORS.yellow.default,
		icon: sjefIconTrendingUp,
	},
	invoiced: {
		color: COLORS.blue.default,
		icon: sjefIconTrendingUp,
	},
	availableForPayout: {
		color: COLORS.purple.default,
		icon: sjefIconTrendingUp,
	},
};

const currency = {
	GBP: '£',
	EUR: '€',
	USD: '$',
};

class vwPsp {
	/**
	 * @constructor
	 * @param {Object} vnode - Mithril vnode
	 */
	chart = null;
	activeTab = 'EUR';
	balanceAccountId = null;
	hasBalanceAccounts = false;
	clientInfo = null;
	loading = false;
	data = {
		clientInfo: null,
		totals: null,
		mutations: null,
		totalGraph: null,
	};

	period;

	async oninit(vnode) {
		this.loading = true;

		await this.initialLoad();
		if (this.balanceAccountId) {
			await this.loadData(this.activeTab);
		} else {
			this.hasBalanceAccounts = false;
			this.loading = false;
		}
	}

	get balance() {
		return clientInfo.balanceAccount.balances[this.currentBalanceIndex];
	}

	async initialLoad() {
		const endpoint = `/balanceplatform/me/details`;
		const clientInfoResponse = await app.get(endpoint, {}, false);

		const mutationsMetaDataEndpoint = `/clientbalances/mutations/metadata`;
		const mutationsMetaDataResponse = await app.get(mutationsMetaDataEndpoint);

		if (!clientInfoResponse.success && !clientInfoResponse.success) {
			return;
		}
		if (clientInfoResponse.success) {
			this.clientInfo = clientInfoResponse.data;
			this.balanceAccountId = clientInfoResponse.data.balanceAccount.id;
		}
		this.data['mutationsMetaData'] = mutationsMetaDataResponse.data;

		this.activeTab = mutationsMetaDataResponse.data.currencies[0] || 'EUR';
	}

	async loadData(activeTab = this.activeTab, balanceAccountId) {
		this.loading = true;
		this.activeTab = activeTab;
		if (balanceAccountId !== undefined) {
			this.balanceAccountId = balanceAccountId;
		}

		const currentDate = new Date();
		const day = currentDate.getDate().toString().slice(-2);
		this.period = `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;

		const totalEndpoint = `/clientbalances/totals?filter[currency]=${this.activeTab}&filter[balanceAccountId]=${this.balanceAccountId}&filter[periodType]=month&filter[periodValue]=${periods.getCurrent()}`;
		const totalGraphEndpoint = `/clientbalances/totals?filter[currency]=${this.activeTab}&filter[balanceAccountId]=${this.balanceAccountId}&filter[periodType]=month&filter[periodValue]=${periods.getCurrent()}&groupBy=day`;
		const mutationsEndpoint = `/clientbalances/mutations?filter[currency]=${this.activeTab}&filter[balanceAccountId]=${this.balanceAccountId}&filter[periodType]=month&filter[periodValue]=${periods.getCurrent()}`;
		const totalRunningPayments = `/clientbalances/totals/runningPayments?filter[currency]=${this.activeTab}&filter[balanceAccountId]=${this.balanceAccountId}&filter[periodType]=month&filter[periodValue]=${periods.getCurrent()}`;


		const totalResponse = await app.get(totalEndpoint);
		const totalGraphResponse = await app.get(totalGraphEndpoint);
		const mutationsResponse = await app.get(mutationsEndpoint);
		const totalRunningPaymentsResponse = await app.get(totalRunningPayments);

		this.data = {
			...this.data,
			clientInfo: this.clientInfo,
			totals: totalResponse.data,
			mutations: mutationsResponse.data,
			totalGraph: totalGraphResponse.data,
			totalRunningPayments: totalRunningPaymentsResponse.data,
		};

		this.data['totalRunningPayments'] = totalRunningPaymentsResponse.data;

		this.loading = false;
		m.redraw();

	}

	async onClickHandler() {
		const {
			data: { url },
		} = await app.get(`/balanceplatform/me/onboardingUrl`);
		if (url) {
			return window.open(url, '_blank');
		}
	}
	view() {
		if (this.loading) {
			return m(sjefLoading);
		}

		if (this.balanceAccountId === null) {
			return m('', [
				CardWithHeader({
					title: _t(`modules.balanceoverview.intro.title`),
					imageHeader: sjefHeader,
					width: '60%',
					style: {
						margin: '0 auto'
					},
					headerPaddingTop: '4rem',
					bodyPaddingTop: '2rem',
					children: [
						m('h2', _t(`modules.balanceoverview.intro.subtitle`)),
						m('p', _t('modules.balanceoverview.intro.paragraph1')),
						m('p', _t(`modules.balanceoverview.intro.paragraph2`)),
						m('p', _t(`modules.balanceoverview.intro.paragraph3`)),
						m('.flexer',
							m(vwOnboardAdyenModal)
						)
					],
				}),
				blurredData()
			]);
		}
		const { clientInfo, totals, mutations, totalGraph, mutationsMetaData, totalRunningPayments } = this.data;

		const accountBalance = totals?.availableForPayout;

		return [
			clientInfo && m('', [
				clientInfo.balanceAccount.balances.length > 1 &&
				m(sjefSelect, {
					prefix: 'balanceoverview',
					activeOption: this.activeTab,
					options: mutationsMetaData.currencies,
					onChange: (selectedOption) => {
						this.activeTab = selectedOption
						this.loadData(this.activeTab);
					},
				}),
				// _t(`_.fields.balanceoverview.GBP`)
				// _t(`_.fields.balanceoverview.EUR`)
				// _t(`_.fields.balanceoverview.USD`)
			]),
			totals && m(`.columns-4`, [
				m(sjefWidget, {
					key: `widget - ${this.activeTab} -${this.balanceAccountId} -${totals.settled} -settled`,
					color: widgetIcon.settled.color,
					title: _t(`modules.balanceoverview.widgets.settled`),
					subTitle: `${currency[this.activeTab]}${totals.settled} `,
					icon: widgetIcon.settled.icon,
					progress: 100,
					style: { textTransform: 'capitalize' },
				}),
				m(sjefWidget, {
					key: `widget - ${this.activeTab} -${this.balanceAccountId} -${totals.invoiced} -invoiced`,
					color: widgetIcon.invoiced.color,
					title: _t(`modules.balanceoverview.widgets.invoiced`),
					subTitle: `${currency[this.activeTab]}${totals.invoiced} `,
					icon: widgetIcon.invoiced.icon,
					progress: 100,
					style: { textTransform: 'capitalize' },
				}),
				m(sjefWidget, {
					key: `widget - ${this.activeTab}-${this.balanceAccountId}-${totals.availableForPayout} -availableForPayout`,
					color: widgetIcon.availableForPayout.color,
					title: _t(`modules.balanceoverview.widgets.availableForPayout`),
					subTitle: `${currency[this.activeTab]}${totals.availableForPayout} `,
					icon: widgetIcon.availableForPayout.icon,
					progress: 100,
					style: { textTransform: 'capitalize' },
				}),
				m(sjefWidget, {
					key: `widget - ${this.activeTab} -${this.balanceAccountId} -${totals.payout} -payout`,
					color: widgetIcon.payout.color,
					title: _t(`modules.balanceoverview.widgets.payout`),
					subTitle: `${currency[this.activeTab]}${totals.payout} `,
					icon: widgetIcon.payout.icon,
					progress: 100,
					style: { textTransform: 'capitalize' },
				}),
			]),
			m(Grid, { gutter: 36, style: { marginTop: '1rem' } }, [
				this.loading || !totalGraph
					? m(Col, { span: 8 }, m(Card, m(sjefLoading)))
					: [
						m(Col, { span: 8, style: { paddingLeft: '18px', paddingRight: '18px' } }, [
							m('', {
								key: `${this.activeTab}-chart-${this.balanceAccountId}`,
							}, [FeatureFlags.isEnabled(runningPaymentsFlag) && totalRunningPayments &&
								m(Card, {
									key: `${this.activeTab}-chart-${this.balanceAccountId}`,
									style: { marginBottom: '3rem' },
								},
									[
										m('h2',),
										m(sjefBalanceChart, {
											color: COLORS.purple.default,
											title: "Status running payments",
											leftTop: m('', [
												m('h3', "Periode"),
												this.period]),
											amount: totalRunningPayments.processed != undefined ? totalRunningPayments.processed.toLocaleString() : '?',
											bottomRow: m('div', { style: { display: 'flex', justifyContent: 'space-between', margin: '0 1rem', fontSize: '2rem', color: COLORS.gray.default } }, [
												m('span', { style: { color: COLORS.green.default, width: '20%' } }, [
													m('h3', "Available for Settlement"),
													totalRunningPayments.settled.toLocaleString(),
												]),
												m('span', { style: { width: '20%' } }, [
													m('h3', "Pending"),
													(totalRunningPayments.booked + totalRunningPayments.authorised + totalRunningPayments.split).toLocaleString(),
												]),
												m('span', { style: { width: '10%' } }, [
													m('h3', "Commision"),
													totalRunningPayments.commission.toLocaleString(),
												]),

											]
											),
											options: {
												series: [
													{
														name: 'Settled',
														data: [totalRunningPayments.settled],
														color: COLORS.green.default
													}, {
														name: 'Booked',
														data: [totalRunningPayments.booked],
														color: COLORS.purple.default
													}, {
														name: 'Authorised',
														data: [totalRunningPayments.authorised],
														color: COLORS.gray.muted
													}, {
														name: 'Split',
														data: [totalRunningPayments.split],
														color: COLORS.gray.muted

													}, {
														name: 'Commision',
														data: [totalRunningPayments.commission],
														color: COLORS.yellow.default
													},
												],
												chart: {
													type: 'bar',
													height: 100,
													stacked: true,
													stackType: '100%'
												},
												plotOptions: {
													bar: {
														horizontal: true,
														borderRadius: 4,
													},
												},
												stroke: {
													width: 1,
													colors: ['#fff']
												},
												xaxis: {
													categories: ['Period'],
													labels: {
														show: false,
													},
													axisBorder: {
														show: true,
													},
													axisTicks: {
														show: true,
													},
												},
												fill: {
													opacity: 1
												},
												legend: {
													show: false
												}
											},
										}
										)]),
							]),

							m(sjefBalanceChart, {
								color: COLORS.purple.default,
								key: `${this.activeTab}-${totalGraph.length}-charts`,
								title: _t('modules.balanceoverview.account_balance'),
								amount: accountBalance != undefined ? accountBalance : '?',
								options: {
									series: [
										{
											name: 'price',
											data: Object.values(totalGraph),
										},
									],
									xaxis: {
										type: 'datetime',
										categories: Object.keys(totalGraph),
									},
								},
							}),
							m(sjefList, {
								key: `${this.activeTab} - ${mutations.length}-mutations`,
								title: _t('modules.balanceoverview.recent_mutations'),
								data: mutations
									.map((item) => {
										return {
											'date/time': new Date(item.createdAt).toDateString(),
											price: m('span.list__cell--bold', `${currency[item.currency]}${item.amount} `),
											reference: m('span.list__cell--bold', item.reference),
											type: m(Tag, {
												label: item.type,
												rounded: true,
												size: 'xs',
												style: tagStyle[item.type],
											}),
										};
									})
									.sort((a, b) => new Date(a['date/time']) - new Date(b['date/time'])),
							}),
						]),
					],
				m(Col, { span: 4 }, [
					clientInfo && m(
						Card,
						{
							key: `${this.balanceAccountId}-bankCard`,
						},
						[
							m(sjefBankCard, {
								title: clientInfo.balanceAccount.description,
								bankNumber: this.balanceAccountId,
							}),
							clientInfo.balanceAccount.balances.length > 1 &&
							m(sjefSelect, {
								prefix: 'balanceoverview',
								activeOption: this.balanceAccountId,
								iconLeft: sjefIconCards,
								options: mutationsMetaData.balanceAccountIds,
								type: 'wide',
								useValueAsLabel: true,
								onChange: (selectedOption) => {
									this.loadData(this.activeTab, selectedOption);
								},
							}),
							this.balanceAccountId
								? [
									m(sjefButton, {
										label: _t('modules.balanceoverview.edit_balance_account'),
										buttonLayout: 'filled wide',
										onclick: this.onClickHandler,
									}),
								]
								: m(vwOnboardAdyenModal, {
									title: `Board: `,
								}),
						]
					),

				]),
			]),
		];
	}
}

export default vwPsp;
