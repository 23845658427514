// Import Mithril
import m from 'mithril';
// Import the helper function
import { goToHref } from '../../lib/helpers';

class sjefLink {
    view({ attrs: { label = '', href, params = {}, state = {}, ...htmlAttrs } }) {
        return m('a.sjefLink', {
            // href: '#',  // Prevent default navigation
            onclick: (event) => goToHref(event, href, params, state),
            ...htmlAttrs
        }, label);
    }
}

export default sjefLink;