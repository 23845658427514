import m from 'mithril';
import { Button, Card, Form, FormGroup, FormLabel, Input, Select } from 'construct-ui';
import { RolesSelector } from './components/RolesSelector';
import sjefIconRun from '@sjefapp/sjeficons/icons/sjefIconRun';
import app from '../../app';
import { clientSelect } from '../modules/clientSelect';
import sjefButton from '../../views/components/sjefButton';
import sjefIconArrowLeft from '@sjefapp/sjeficons/icons/sjefIconArrowLeft';
import sjefInput from '../../views/components/sjefInput';

// 	[
//	{
// 		cid: 0,
// 		name: '* All Clients',
// 	},
// 	{
// 		cid: 1,
// 		name: 'Test Client 1',
// 	},
// 	{
// 		cid: 2,
// 		name: 'Test Client 2',
// 	},
// 	{
// 		cid: 3,
// 		name: 'Test Client 3',
// 	},
// ];

const accessLevels = [
	{ value: 5, label: '5 - Location' },
	{ value: 6, label: '6 - Administration' },
	{ value: 7, label: '7 - Client' },
	{ value: 8, label: '8 - Organisation' },
	{ value: 9, label: '9 - Super' },
]

let user = {};

const vwUserForm = {
	oninit: async ({ attrs }) => {
		user = attrs.user;
		m.redraw();
		// if (user.id !== '0') {
		//     user = await Super.get('user', { id: user.id });
		//     console.log('user.id', user);
		// }
	},
	view: () => {
		return m('.user', [
			m('.pageHeader',
				m(sjefButton, {
					buttonLayout: 'previous',
					iconLeft: sjefIconArrowLeft,
					label: 'Back to Users list',
					onclick: () => m.route.set('/admin/users'),
				}),
				m('.actions', [
					m(sjefButton, {
						label: 'Save',
						buttonLayout: 'filled',
						onclick: async () => {
							if (user.id !== '0') {
								const response = await app.post('/auth/ident/create', {
									ident: user.ident,
									ident_type: user.ident_type,
									lvl: user.lvl,
									cid: user.cid || 0,
								});
								if (response.success) {
									app.toast('User saved', {
										intent: 'positive',
									});
									m.route.set('/admin/users')
								} else {
									app.toast(response.message, {
										intent: 'negative',
									});
								}
							} else {
								const response = await app.post('/auth/ident/create', {
									ident: user.ident,
									ident_type: user.ident_type,
									lvl: user.lvl,
									cid: user.cid || 0,
								});
								if (response.success) {
									app.toast('User saved', {
										intent: 'positive',
									});
									m.route.set('/admin/users')
								} else {
									app.toast(response.message, {
										intent: 'negative',
									});
								}
							}
						},
					}),
				])
			),
			m('h3', user.id ? `ID: ${user.id}` : `New user`),
			m(
				Card,
				m(
					Form,
					{
						gutter: 10,
						justify: 'start',
						onsubmit: () => {
							return false;
						},
					},
					[
						m(FormGroup, {
							span: 6,
							content: [
								m(FormLabel, { for: 'username' }, 'Username'),
								m(Input, {
									id: 'username',
									size: '40',
									value: user.ident,
									readonly: user.id !== '0' && user._id !== '0',
									disabled: user.id !== '0' && user._id !== '0',
									onchange: (ev) => {
										user.ident = ev.currentTarget.value;
									},
								}),
							],
						}),
						// m(FormGroup, {
						// 	span: 6,
						// 	content: [
						// 		m(FormLabel, { for: 'cid' }, 'Default client'),
						// 		m(Select, {
						// 			id: 'cid',
						// 			fluid: true,
						// 			options: clientList,
						// 			value: user.cid || 0,
						// 			onchange: (ev) => {
						// 				user.cid = ev.currentTarget.value;
						// 				console.log( 'default client change', user.cid );
						// 			},
						// 		}),
						// 	],
						// }),
						m(FormGroup, {
							span: 6,
							content: [
								m(FormLabel, { for: 'cid' }, 'Add client'),
								m(clientSelect, { user: user })
							],
						}),
						m(FormGroup, {
							span: 6,
							content: [
								m(FormLabel, { for: 'password' }, '(Re)set password'),
								m(Input, {
									id: 'password',
									value: user.password || null,
									type: 'password',
									placeholder: 'Min. 8 characters',
									oninput: (ev) => {
										user.password = ev.currentTarget.value;
									},
									contentRight:
										user.id !== '0'
											? m(Button, {
												iconRight: sjefIconRun,
												label: 'SAVE',
												disabled: !user.password || user.password.length < 7,
												onclick: async () => {
													const result = await app.post('/auth/ident/resetpassword', {
														ident: user.ident,
														password: user.password,
													});
													if (result.success === false) {
														app.toast(result.message, {
															intent: 'negative',
														});
													} else {
														app.toast('Password reset', {
															intent: 'positive',
														});
													}
													user.password = '';
												},
											})
											: void 0,
								}),
							],
						}),

						m(FormGroup, {
							span: 6,
							content: [
								m(FormLabel, 'Roles'),
								m(RolesSelector, {
									selected: user.roles,
									cb: async (role) => {
										try {
											const result = await app.post('/auth/ident/addrole', {
												ident: user.ident,
												role: role.role,
												add: role.checked,
											});
											if (result.success === false) {
												app.toast(result.message, {
													intent: 'negative',
												});
											} else {
												user.roles = result.data.roles;
												app.toast(`Role ${role.role} ${role.checked ? 'added' : 'removed'}`, {
													intent: 'positive',
													timeout: 1000,
												});
											}
										} catch (err) {
											console.debug(err);
										}
									},
								}),
							],
						}),
						m(FormGroup, {
							span: 6,
							content: [
								m(FormLabel, 'Accesslevel'),
								m(sjefInput, {
									type: 'select',
									options: accessLevels,
									value: accessLevels.find((al) => al.value === user.lvl) || accessLevels[0],
									onchange: async (ev) => {
										user.lvl = ev.target.value;
									},
								}),
							],
						}),
					]
				)
			),
		]);
	},
};

export default vwUserForm;
