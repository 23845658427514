import { Card, Collapse, Form, FormGroup, Table, Tooltip } from 'construct-ui';
import m from 'mithril';
import sjefCheckbox from '../components/sjefCheckbox';
import { _t } from '../../lib/i18n';
import sjefIconFoldDown from '@sjefapp/sjeficons/icons/sjefIconFoldDown';
import sjefInput from '../components/sjefInput';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefModal from '../components/sjefModal';
import sjefIconChevronRight from '@sjefapp/sjeficons/icons/sjefIconChevronRight';
import sjefIconPlus from '@sjefapp/sjeficons/icons/sjefIconPlus';
import sjefIconTable from '@sjefapp/sjeficons/icons/sjefIconTable';
import sjefButton from '../components/sjefButton';
import sjefIconChevronLeft from '@sjefapp/sjeficons/icons/sjefIconChevronLeft';
import sjefIconAddressBook from '@sjefapp/sjeficons/icons/sjefIconAddressBook';
import sjefIconArrowLeft from '@sjefapp/sjeficons/icons/sjefIconArrowLeft';
import sjefTable from '../components/sjefTable';
import tableStore from '../../lib/tableStore';
import app from '../../app';
import sjefBanner from '../components/sjefBanner';
import sjefIconAccessPoint from '@sjefapp/sjeficons/icons/sjefIconAccessPoint';
import actionsMenu from '../components/actionsMenu';
import sjefIconMapPin from '@sjefapp/sjeficons/icons/sjefIconMapPin';

class vwEventOverview {
	activeAllocation = false;
	allocationForm = {};
	allocations = {};
	areaAllocations = [];
	areaFormStep = 1;
	areaOpenStates = {};
	cabinets = [];
	event;
	eventId;
	locationOpenStates = {};
	locations;
	tickets = {};
	locationValue = '';
	eventReport = false;
	allocationState = 'overview';
	areaForm = {
		name: '',
		slug: '',
		shopSelection: false,
		active: true,
		lockerMode: '1010',
		reserve: {
			medium: 0,
			large: 0,
		},
		maxBasket: {
			medium: 2,
			large: 2,
		},
	};

	constructor(vnode) {
		console.log(vnode.attrs.id)
		// super();

		// this.config = new vnode.attrs.config(this);
		// this.showAdvanced = false;
		// this.route = this.config.route;
		// this.endpoint = this.config.endpoint;
		// this.fields = this.config.fields;
		// this.tabs = this.config.tabs;
		// this.buttons = this.config.buttons;
		// this.formDirtyFields = this.formDirtyFields;

		// this.schema = configToAjvSchema(this.config.schema);
	}

	async oninit(vnode) {
		this.eventId = vnode.attrs.id;

		let allocationStore = app.restore('allocationId');
		if (allocationStore) {
			await this.setActiveAllocation(allocationStore);
		} 

		await this.getEvent();

		await this.getLocations(this.eventId);

		await this.getEventReport();

		//todo
		if (this.activeAllocation?.cabinetIds?.length) {
			console.log(this.activeAllocation.cabinetIds);
			tableStore.selectedRecords(this.activeAllocation.cabinetIds);
		}
	}

	async attachCabinets(id) {
		// toggle all either true or false
		let attachResult;
		if (id === 'all') {
			if (tableStore.allRecordsSelected() === false) {
				attachResult = await app.post('/lockermanagement/allocations/' + this.activeAllocation.id + '/lockers', {
					cabinetIds: tableStore.selectedRecords(),
				});

			} else {
				let records = tableStore.records().map((record) => record.id);

				attachResult = await app.delete('/lockermanagement/allocations/' + this.activeAllocation.id + '/lockers', {
					cabinetIds: records,
				});
			}
			//toggle single true
		} else if (tableStore.selectedRecords().includes(id) === false) {
			console.log('add', id); // todo

			attachResult = await app.post('/lockermanagement/allocations/' + this.activeAllocation.id + '/lockers', {
				cabinetIds: [id],
			});
			//toggle single false
		} else {
			console.log('remove', id); // todo

			attachResult = await app.delete('/lockermanagement/allocations/' + this.activeAllocation.id + '/lockers', {
				cabinetIds: [id],
			});
		}

		return attachResult;

		// todo fetch records again
	}

	async generateTickets(allocation) {
		if (!allocation) {
			allocation = this.activeAllocation;
		}

		await app.post('/lockermanagement/tickets/generate', {
			allocationId: allocation.id,
			preprintLarge: 2,
			preprintMedium: 3
		})
	}

	async fetchAndSetAllAllocationsForLocation(locationId) {
		const location = this.locations.find(loc => loc.id === locationId);
		if (location && location.areas) {
			for (let area of location.areas) {
				await this.fetchAndSetAllocationsIfNeeded(area.id);
				this.areaOpenStates[area.id] = true; // Set the area open state to true
			}
		}
	}
	

	async getEventReport() {
		if (!this.event || !this.event.id) {
			return;
		}

		let reportResult = await app.get('/lockermanagement/events/' + this.event.id + '/report');

		if (reportResult.success) {
			this.eventReport = reportResult.data;
			console.log(this.eventReport);
		}
	}

	resetAllocationForm() {
		this.allocationForm = {
			modeLocker: 'eventmode1',
			modeTimeslot: '0000',
			modeStarthour: null,
			modeValidhours: null,
			description: '',
		};
	}

	async getAllocation(id) {
		let allocationResult = await app.get('/lockermanagement/allocations/' + id);

		if (allocationResult.success) {
			this.activeAllocation = allocationResult.data;


			m.redraw();
		}
	}

	toggleAllocationState() {

		
		if (this.allocationState === 'overview') {
			this.allocationState = 'manage';
			return;
		}

		this.allocationState = 'overview';
	}

	setDisabledCabinets() {
		// set all ids that belong to other allocation to disabled.
		let disabledRecords = [1] // dummy
		tableStore.disabledRecords(disabledRecords); 
	}

	async setActiveAllocation(allocation) {
		if (Number.isInteger(parseInt(allocation))) {
			await this.getAllocation(allocation);

		}
		// Check if allocation is an object and has an id property
		else if (allocation && typeof allocation === 'object' && allocation.id) {
			await this.getAllocation(allocation.id);
		}


		// Return immediately if neither condition is met
		else {
			console.log('Invalid allocation data');
			return;
		}

		this.setDisabledCabinets();

		await this.getAreaAllocations(this.activeAllocation.areaId);

		this.cabinets = this.activeAllocation.cabinetIds;

		tableStore.records(this.cabinets);
		tableStore.selectedRecords(this.activeAllocation.cabinetIds);
		app.store('allocationId', this.activeAllocation.id);

		this.allocationState = 'overview';
	}

	async getAreaAllocations (areaId) {
		if (!areaId) {
			this.areaAllocations = [];
			return;
		}

		let areaResult = await app.get('/lockermanagement/allocations?filter[areaId]=' + areaId);

		if (areaResult.success) {
			this.areaAllocations = areaResult.data;
			m.redraw();
			return;
		} 

		this.areaAllocations = [];
	}

	async createLocation(name) {
		if (!name) {
			return;
		}

		let result = await app.post('/lockermanagement/locations', {
			eventId: this.event.id,
			name: name,
			active: true,
		});

		if (result.success) {
			return true;
		}

		return false;
	}

	async toggleAllLocationsAndAreas() {
		const allAreOpen = this.locations.every((location) => this.locationOpenStates[location.id]);
		const operationPromises = [];

		this.locations.forEach((location) => {
			this.locationOpenStates[location.id] = !allAreOpen;
			if (!allAreOpen) {
				operationPromises.push(this.fetchAndSetAreasIfNeeded(location.id));
			}
		});

		await Promise.all(operationPromises);

		if (!allAreOpen) {
			this.locations.forEach((location) => {
				if (location.areas) {
					location.areas.forEach((area) => {
						this.areaOpenStates[area.id] = true;
						this.fetchAndSetAllocationsIfNeeded(area.id); // Ensure allocations are fetched when opening
					});
				}
			});
		} else {
			Object.keys(this.areaOpenStates).forEach((key) => {
				this.areaOpenStates[key] = false; // Close all areas
			});
		}

		m.redraw();
	}

	async fetchAndSetAreasIfNeeded(locationId) {
		// Check if areas for the location have already been fetched and set
		if (!this.locations.find((loc) => loc.id === locationId).areas) {
			let areasResult = await this.getAreas(locationId);
			// Assuming getAreas returns false or an array of areas
			if (areasResult) {
				this.locations = this.locations.map((location) => {
					if (location.id === locationId) {
						return { ...location, areas: areasResult };
					}
					return location;
				});
			}
		}
	}

	async fetchAndSetAllocationsIfNeeded(areaId) {
		if (!this.allocations[areaId]) {
			let allocationResult = await app.get(`/lockermanagement/allocations?filter[areaId]=${areaId}`);
			if (allocationResult.success) {
				this.allocations[areaId] = allocationResult.data;
			} else {
				this.allocations[areaId] = []; // Set to empty array on failure or no data
			}
		}
	}

	onremove() {
		tableStore.disabledRecords([]);
		app.store('allocationId', null);
	}

	async getEvent() {
		if (!this.eventId) {
			return;
		}

		const eventResult = await app.get('/lockermanagement/events/' + this.eventId);

		if (eventResult.success) {
			this.event = eventResult.data;
		}
	}

	async getLocations(eventId) {
		let query = '';
		if (eventId) {
			query = `?filter[eventId]=${eventId}`;
		}

		const locationResult = await app.get('/lockermanagement/locations' + query);

		if (locationResult.success) {
			this.locations = locationResult.data;
		}

		m.redraw();
	}

	async getAreas(locationId) {
		let query = '';
		if (locationId) {
			query = `?filter[locationId]=${locationId}`;
		}

		let areaResult = await app.get(`/lockermanagement/areas` + query);
		if (areaResult.success) {
			return areaResult.data;
		}

		return false;
	}

	view() {
		if (!this.event) {
			return;
		}

		return [
			m(
				'.vwDetails',
				m(
					'.flexer.center',
					m(
						'',
						m(
							'.iconHover.background',
							{
								onclick: () => {
									if (this.allocationState === 'manage') {
										this.toggleAllocationState();
										return;
									}

									if (this.activeAllocation) {
										this.activeAllocation = false;
										app.store('allocationId', null);
										this.cabinets = false;
										m.redraw();
										return;
									}
									m.route.set('/lockermanagement/events');
								},
							},
							m(sjefIconArrowLeft)
						)
					),
					m('h1.title', this.event.name)
				),
				!this.activeAllocation
					? [
							m('.columns-3-1', [
								m(
									'.left',
									m(sjefBanner, {
										// color: 'grey',
										title: this.event?.name,
										// img:  new URL('../../assets/img/testimagefestival.png', import.meta.url),
										subTitle: 'ID&T',
										// subTitle: this.event.clientName,
										icon: m(sjefIconAccessPoint),
										dates: [this.event.eventStart, this.event.eventEnd],
									}),
									// this.eventReport
									// 	? m(Card, [
									// 			m(
									// 				'.flexer',
									// 				m('h3', _t('_.labels.report')),
									// 				m(
									// 					'.iconHover',
									// 					{
									// 						onclick: async () => {
									// 							await this.getEventReport();
									// 						},
									// 					},
									// 					m(sjefIconRefresh)
									// 				)
									// 			),
									// 			m(
									// 				Table,
									// 				{ bordered: false, striped: true, class: 'thTiny' },
									// 				m(
									// 					'tr',
									// 					m('th'),
									// 					Object.keys(this.eventReport.totals.medium).map((item) => {
									// 						return m('th', item);
									// 					})
									// 				),
									// 				Object.keys(this.eventReport.totals).map((item) => {
									// 					return m(
									// 						'tr',
									// 						m('td', item),
									// 						Object.keys(this.eventReport.totals[item]).map((totalItem) => {
									// 							return m('td', this.eventReport.totals[item][totalItem]);
									// 						})
									// 					);
									// 				})
									// 			),
									// 	  ])
									// 	: void 0,
									m(
										'',
										m(
											'.flexer.center',
											m('h2', _t('_.labels.locations')),
											m(Tooltip, {
												hasArrow: false,
												position: 'top-end',
												content: _t('_.fields.openAll'),
												trigger: m(
													'.iconHover',
													{
														onclick: () => {
															this.toggleAllLocationsAndAreas();
														},
													},
													m(sjefIconFoldDown)
												),
											}),
											m(sjefModal, {
												buttonLayout: 'ghost',
												// tooltip: _t('_.labels.createLocation'),
												// buttonLabel: m(sjefIconPlus),
												buttonIconLeft: m(sjefIconPlus),
												buttonLabel: _t('_.buttons.create'),
												modalTitle: _t('_.labels.createLocation'),
												buttonConfirm: _t('_.buttons.save'),
												modalContent: [
													m(sjefInput, {
														value: this.locationValue,
														onChange: (value) => {
															this.locationValue = value;
														},
													}),
												],
												onSubmit: async () => {
													let result = await this.createLocation(this.locationValue);
													if (result) {
														this.locationValue = '';
														this.getLocations(this.event.id);
														m.redraw();
													}
												},
											}),
										),

										this.locations &&
											this.locations.map((location) => {
												return [
													m(
														Card,
														{ class: 'location active-state-elem' },
														m(
															'.repeaterField',
															m('h3', location.name),
															m(Tooltip, {
																hasArrow: false,
																position: 'top-end',
																content: _t('_.fields.open'),
																trigger: m(
																	'.iconHover',
																	{
																		onclick: async () => {
																			this.locationOpenStates[location.id] = !this.locationOpenStates[location.id];
																			if (this.locationOpenStates[location.id]) {
																				await this.fetchAndSetAreasIfNeeded(location.id);
																				await this.fetchAndSetAllAllocationsForLocation(location.id); // New function to fetch allocations
																			}
																			m.redraw();
																		},
																	},

																	m(sjefIconChevronDown)
																),
															}),
															m(sjefModal, {
																buttonLayout: 'ghost',
																// tooltip: _t('_.buttons.edit'),
																buttonIconLeft: m(sjefIconEdit),
																buttonLabel: _t('_.buttons.edit'),
																modalTitle: _t('_.labels.editLocation'),
																buttonConfirm: _t('_.buttons.save'),
																modalContent: [
																	m(sjefInput, {
																		value: location.name,
																		onChange: (value) => {
																			location.name = value;
																		},
																	}),
																],
																onSubmit: async () => {
																	const postData = {
																		id: location.id,
																		name: location.name,
																		eventId: location.eventId,
																		active: location.active,
																	};

																	let result = await app.patch('/lockermanagement/locations', postData);
																},
															}),
															m(sjefModal, {
																buttonLayout: 'ghost',
																// tooltip: _t('_.buttons.delete'),
																buttonLabel: _t('_.buttons.delete'),
																buttonIconLeft: m(sjefIconX),
																modalTitle: _t('_.labels.deleteLocation'),
																buttonConfirm: _t('_.buttons.delete'),
																modalContent: [m('', `Are you sure you want to delete location ${location.name}?`)],
																onSubmit: async () => {
																	let result = await app.delete('/lockermanagement/locations', {
																		id: location.id,
																	});

																	if (result.success) {
																		this.getLocations(this.event.id);
																		m.redraw();
																		return;
																	}

																	alert('location cannot be removed due to relations');
																},
															})
														),
														m(
															Collapse,
															{
																isOpen: this.locationOpenStates[location.id],
																duration: 200,
															},
															m(
																'.areasWrapper',
																m(
																	'.flex.center',
																	{
																		style: {
																			marginBottom: '1rem',
																		},
																	},
																	m('h5', 'Areas'),
																	m(
																		'.repeaterField',
																		// m(sjefInput, {
																		// 	value: location['area'],
																		// 	onChange: (newValue) => {
																		// 		location['area'] = newValue;
																		// 	},
																		// }),
																		m(
																			'',
																			m(sjefModal, {
																				buttonLayout: 'ghost',
																				buttonIconLeft: m(sjefIconPlus),
																				// tooltip: _t('_.labels.createArea'),
																				buttonLabel: _t('_.labels.createArea'),
																				modalTitle: _t('_.labels.createArea'),
																				buttonConfirm: _t('_.buttons.create'),
																				confirmDisabled: this.areaFormStep !== 3,
																				// modalSubtitle: [

																				// 	m('p','location: location'),
																				// 	m('p','area: area'),
																				// ],
																				modalContent: [
																					// m('', `Are you sure you want to delete area ${area.name}?`),
																					this.areaFormStep == 1
																						? [
																								m(sjefInput, {
																									label: _t('_.fields.name'),
																									key: 'name',
																									value: this.areaForm.name,
																									onChange: (value) => {
																										this.areaForm.name = value;
																									},
																								}),
																								m(sjefInput, {
																									label: _t('_.fields.slug'),
																									key: 'slug',
																									value: this.areaForm.slug,
																									onChange: (value) => {
																										this.areaForm.slug = value;
																									},
																								}),
																						  ]
																						: this.areaFormStep == 2
																						? m(
																								'',
																								m(sjefInput, {
																									value: this.areaForm.lockerMode,
																									type: 'select',
																									label: _t('_.fields.lockerMode'),
																									options: ['1010', '0000'],
																									onChange: (value) => {
																										this.areaForm.lockerMode = value;
																									},
																								}),
																								m(sjefCheckbox, {
																									label: _t('_.fields.active'),
																									checked: this.areaForm.active,
																									onChange: (value) => {
																										this.areaForm.active = value;
																									},
																								}),
																								m(sjefCheckbox, {
																									label: _t('_.fields.shopSelection'),
																									checked: this.areaForm.shopSelection,
																									onChange: (value) => {
																										this.areaForm.shopSelection = value;
																									},
																								})
																						  )
																						: this.areaFormStep == 3
																						? m('.columns-2', [
																								m('label', _t('_.fields.reserve')),
																								m(
																									'.columns-2',
																									m(sjefInput, {
																										type: 'number',
																										value: this.areaForm.reserve.medium,
																										label: _t('_.fields.medium'),
																										onChange: (value) => {
																											this.areaForm.reserve.medium = value;
																										},
																									}),
																									m(sjefInput, {
																										type: 'number',
																										label: _t('_.fields.large'),
																										value: this.areaForm.reserve.large,
																										onChange: (value) => {
																											this.areaForm.reserve.large = value;
																										},
																									})
																								),
																								m('label', _t('_.fields.masBasket')),
																								m(
																									'.columns-2',
																									{},
																									m(sjefInput, {
																										type: 'number',
																										value: this.areaForm.maxBasket.medium,
																										label: _t('_.fields.medium'),
																										onChange: (value) => {
																											this.areaForm.maxBasket.medium = value;
																										},
																									}),
																									m(sjefInput, {
																										type: 'number',
																										label: _t('_.fields.large'),
																										value: this.areaForm.maxBasket.large,
																										onChange: (value) => {
																											this.areaForm.maxBasket.large = value;
																										},
																									})
																								),
																						  ])
																						: void 0,
																					m('.buttons.step-buttons', [
																						m(sjefButton, {
																							buttonLayout: 'slider',
																							label: m(sjefIconChevronLeft),
																							onclick: () => {
																								if (this.areaFormStep > 1) this.areaFormStep--;
																							},
																							disabled: this.areaFormStep === 1,
																						}),
																						m(sjefButton, {
																							buttonLayout: 'slider',
																							label: m(sjefIconChevronRight),
																							onclick: () => {
																								this.areaFormStep++;
																							},
																							disabled: this.areaFormStep == 3,
																						}),
																					]),
																				],
																				onSubmit: async () => {
																					let result = await app.post('/lockermanagement/areas', {
																						locationId: location.id,
																						name: this.areaForm.name,
																						slug: this.areaForm.slug.toLowerCase(),
																						activeInShop: this.areaForm.active,
																						active: this.areaForm.active,
																					});

																					if (result.data) {
																						location['areas'] = await this.getAreas(location.id);

																						this.areaFormStep = 1;
																						m.redraw();
																					}
																				},
																			})
																		)
																	)
																),
																location.areas?.length
																	? location.areas.map((area) => {
																			return [
																				m(
																					'.repeaterField',
																					m('',
																						m('.flex.start.center', 
																							m('h3', area.name),
																							// m(
																							// 	'.iconHover',
																							// 	{
																							// 		onclick: async () => {
																							// 			this.areaOpenStates[area.id] =
																							// 				!this.areaOpenStates[area.id];

																							// 			let areaAllocationsResult = await app.get(
																							// 				`/lockermanagement/allocations?filter[areaId]=${area.id}`
																							// 			);

																							// 			if (areaAllocationsResult.success) {
																							// 				this.allocations[area.id] =
																							// 					areaAllocationsResult.data;
																							// 			}

																							// 			m.redraw();
																							// 		},
																							// 	},
																							// 	m(sjefIconChevronDown)
																							// ),
																						)
																					),
																					m(actionsMenu, {
																						content: [
																							m(sjefModal, {
																								buttonLabel: [m(sjefIconEdit), _t('_.buttons.edit')],
																								modalTitle: `Edit area ${area.name}`,
																								buttonConfirm: _t('_.buttons.save'),
																								onOpen: () => {
																									m.redraw();
																								},
																								modalContent: [
																									m(sjefInput, {
																										label: _t('_.fields.name'),
																										value: area.name,
																										onChange: (value) => {
																											area.name = value;
																										},
																									}),
																									m(sjefInput, {
																										label: _t('_.fields.slug'),
																										value: area.slug,
																										onChange: (value) => {
																											area.slug = value;
																										},
																									}),
																									m(
																										'',
																										m(sjefInput, {
																											value: area.lockerMode,
																											type: 'select',
																											label: _t('_.fields.lockerMode'),
																											options: ['1010', '0000'],
																											onChange: (value) => {
																												area.lockerMode = value;
																											},
																										}),
																										m(sjefCheckbox, {
																											label: _t('_.fields.active'),
																											checked: area.active,
																											onChange: (value) => {
																												area.active = value;
																											},
																										}),
																										m(sjefCheckbox, {
																											label: _t('_.fields.shopSelection'),
																											checked: area.shopSelection,
																											onChange: (value) => {
																												area.shopSelection = value;
																											},
																										})
																									),
																								],

																								onSubmit: async () => {
																									let result = await app.patch(
																										'/lockermanagement/areas',
																										area
																									);

																									if (result.success) {
																										location['areas'] = await this.getAreas(
																											location.id
																										);
																										m.redraw();
																										return;
																									}

																									alert('an error ocurred');
																								},
																							}),
																							m(sjefModal, {
																								modalTitle: _t('_.fields.createAllocation'),
																								buttonLabel: [
																									m(sjefIconAddressBook),
																									_t('_.labels.createAllocation'),
																								],
																								buttonConfirm: _t('_.buttons.create'),
																								modalContent: m('.createAllocation', [
																									m(
																										Form,
																										m(sjefInput, {
																											label: _t('_.fields.description'),
																											name: 'description',
																											value: this.allocationForm.description,
																											onChange: (newValue) => {
																												this.allocationForm.description =
																													newValue;
																											},
																										}),
																										m(sjefInput, {
																											label: _t('_.fields.modeLocker'),
																											name: 'modeLocker',
																											type: 'select',
																											value: this.allocationForm.modeLocker,
																											options: ['eventmode1'],
																											onChange: (newValue) => {
																												this.allocationForm.modeLocker =
																													newValue;
																											},
																										}),
																										m(sjefInput, {
																											label: _t('_.fields.modeTimeslot'),
																											type: 'select',
																											name: 'modeTimeslot',
																											options: ['1010', '0000'],
																											value: this.allocationForm.modeTimeslot,
																											onChange: (newValue) => {
																												this.allocationForm.modeTimeslot =
																													newValue;
																											},
																										}),
																										m(sjefInput, {
																											label: _t('_.fields.modeStartHour'),
																											type: 'number',
																											name: 'modeStarthour',
																											value: this.allocationForm.modeStarthour,
																											onChange: (newValue) => {
																												this.allocationForm.modeStarthour =
																													newValue;
																											},
																										}),
																										m(sjefInput, {
																											label: _t('_.fields.modeValidHours'),
																											type: 'number',
																											name: 'modeValidhours',
																											value: this.allocationForm.modeValidhours,
																											onChange: (newValue) => {
																												this.allocationForm.modeValidhours =
																													newValue;
																											},
																										})
																									),
																								]),
																								onSubmit: async (a) => {
																									this.allocationForm.eventId = this.event.id;
																									this.allocationForm.locationId = location.id;
																									this.allocationForm.areaId = area.id;

																									let allocationResult = await app.post(
																										'/lockermanagement/allocations',
																										this.allocationForm
																									);

																									if (!allocationResult.success) {
																										return false;
																									}

																									this.allocation = allocationResult.data;

																									let areaAllocationsResult = await app.get(
																										`/lockermanagement/allocations?filter[areaId]=${area.id}`
																									);

																									if (areaAllocationsResult.success) {
																										this.allocations[area.id] =
																											areaAllocationsResult.data;
																									}

																									m.redraw();
																								},
																							}),
																							this.eventReport
																								? m(sjefModal, {
																										buttonLabel: [
																											m(sjefIconTable),
																											_t('_.labels.areaReport'),
																										],
																										modalSubTitle: _t('_.labels.areaReport'),
																										modalTitle: area.name,
																										buttonConfirm: false,
																										modalSize: 'lg',
																										footer: false,

																										modalContent: [
																											this.eventReport.areas.length
																												? m(
																														Table,
																														{
																															bordered: false,
																															striped: true,
																															class: 'thTiny',
																														},
																														m(
																															'tr',
																															m('th', ''),
																															Object.keys(
																																this.eventReport.areas.find(
																																	(a) =>
																																		a.areaId ===
																																		area.id
																																).totals.medium
																															).map((metric) => {
																																return m(
																																	'th',
																																	metric
																																);
																															})
																														),
																														// Rows for area specifics
																														Object.entries(
																															this.eventReport.areas.find(
																																(a) =>
																																	a.areaId ===
																																	area.id
																															).totals
																														).map(([size, totals]) => {
																															return m(
																																'tr',
																																m('td', size),
																																Object.values(
																																	totals
																																).map((value) => {
																																	return m(
																																		'td',
																																		value
																																	);
																																})
																															);
																														})
																												  )
																												: void 0,
																										],
																								  })
																								: void 0,
																							m(sjefModal, {
																								buttonLabel: [
																									m(sjefIconX),
																									_t('_.labels.deleteArea'),
																								],
																								modalTitle: 'Delete area',
																								buttonConfirm: 'Delete',
																								modalContent: [
																									m(
																										'',
																										`Are you sure you want to delete area ${area.name}?`
																									),
																								],
																								onSubmit: async () => {
																									let result = await app.delete(
																										'/lockermanagement/areas',
																										{
																											id: area.id,
																										}
																									);

																									if (result.success) {
																										location['areas'] = await this.getAreas(
																											location.id
																										);
																										m.redraw();
																									}
																								},
																							}),
																						],
																					})
																				),
																				m(Collapse, { isOpen: this.areaOpenStates[area.id] }, [
																					m(
																						'.allocations',
																						// m('h5', _t('_.labels.allocations')),
																						m(
																							'',

																							this.allocations[area.id]
																								? this.allocations[area.id].map((allocation) => {
																										return [
																											m(
																												'',
																												{
																													class: 'allocation',
																												},
																												m(
																													'p',
																													{
																														onclick: async () => {
																															// if (!this.cabinets) {
																															let cabinetResult =
																																await app.get(
																																	'/lockermanagement/cabinets'
																																);
																															if (
																																cabinetResult.success
																															) {
																																this.cabinets =
																																	cabinetResult.data;

																																this.setActiveAllocation(
																																	allocation
																																);
																															}

																															m.redraw();
																															// }
																														},
																													},

																													allocation.description
																												),
																												// m(actionsMenu, {
																												// 	blurred: true,
																												// 	content:
																													 [
																														m(sjefButton, {
																															buttonLayout: 'a',
																															label: _t('_.labels.tickets'),
																															// iconLeft: m(sjefIconArrowRight),
																															onclick: async () => {
																																m.route.set(
																																	'/lockermanagement/tickets?areaId=' +
																																		area.id +
																																		'&eventId=' +
																																		this.event.id
																																);
																															},
																														}),
																														m(sjefModal, {
																															buttonLayout: 'a underline',
																															buttonLabel: [
																															
																																_t(
																																	'_.buttons.delete'
																																),
																															],
																															// buttonIconLeft: m(sjefIconEdit),
																															modalTitle:
																																_t(
																																	'_.buttons.delete'
																																),
																															buttonConfirm:
																																_t(
																																	'_.buttons.delete'
																																),
																															modalContent: [
																																m(
																																	'p',
																																	'Are you sure you want to delete allocation ' +
																																		allocation.description
																																),
																															],
																															onSubmit: async () => {
																																let result =
																																	await app.delete(
																																		'/lockermanagement/allocations',
																																		{
																																			id: allocation.id,
																																		}
																																	);

																																if (result.success) {
																																	let areaAllocationsResult =
																																		await app.get(
																																			`/lockermanagement/allocations?filter[areaId]=${area.id}`
																																		);

																																	if (
																																		areaAllocationsResult.success
																																	) {
																																		this.allocations[
																																			area.id
																																		] =
																																			areaAllocationsResult.data;
																																	}
																																}

																																m.redraw();
																															},
																														}),
																													],
																												// })
																											),
																										];
																								  })
																								: void 0
																						)
																					),
																				]),
																			];
																	  })
																	: m('p', _t('_.labels.noAreas'))
															)
														)
													),
												];
											})
										// m(
										// 	Card,
										// 	m(
										// 		'.repeaterField',
										// 		m(sjefInput, {
										// 			name: 'locationCreate',
										// 			id: 'locationCreate',
										// 			value: this.locationValue,
										// 			onChange: (newValue) => {
										// 				this.locationValue = newValue;
										// 			},
										// 		}),
										// 		m(Tooltip, {
										// 			hasArrow: false,
										// 			position: 'top-end',
										// 			content: _t('_.labels.createLocation'),
										// 			trigger: m(
										// 				'.iconHover',
										// 				{
										// 					onclick: async () => {
										// 						if (this.locationValue) {
										// 							let result = await this.createLocation(this.locationValue);
										// 							if (result) {
										// 								this.locationValue = '';
										// 								this.getLocations(this.event.id);
										// 								m.redraw();
										// 							}
										// 						}
										// 					},
										// 				},
										// 				m(sjefIconPlus)
										// 			),
										// 		})
										// 	)
										// )
									)
								),
								m('.right', [
									m(Card, {}, [
										m('h3', _t('_.labels.active')),
										m(sjefCheckbox, {
											checked: this.event.active,
											label: _t('_.fields.active'),
											onChange: async () => {
												this.event.active = !this.event.active;

												// melvin
												let result = await app.patch('/lockermanagement/events', {
													id: this.event.id,
													name: this.event.name,
													active: this.event.active,
												});

												console.log(result);
											},
										}),
										m(sjefCheckbox, {
											checked: this.event.activeInShop,
											label: _t('_.fields.activeInShop'),
											onChange: () => {
												this.event.activeInShop = !this.event.activeInShop;
											},
										}),
									]),
									m(Card, [
										m('h3', _t('_.labels.checklist')),
										m(sjefCheckbox, {
											checked: true,
											label: _t('_.labels.ticketsGenerated'),
											disabled: true,
										}),
									]),
								]),
							]),
					  ]
					: [
							m(
								'.flexer.center',
								m('h2', this.activeAllocation.description),
								m('.actions.flexer',
								this.allocationState == 'manage' && [
									this.activeAllocation.status == 'new' ?
										m(sjefButton, {
											label: _t('_.buttons.generateTickets'),
											buttonLayout: 'ghost',
											onclick: async () => {
												let result = await app.post('/lockermanagement/tickets/generate', {
													allocationId: this.activeAllocation.id,
													preprintLarge: 2,
													preprintMedium: 3
												});

												if (result.success) {
													app.toast('yes') // todo
												} else {
													app.toast('no')
												}
											}
										}) : 
										m(sjefButton, {
											label: _t('_.buttons.rollback'),
											buttonLayout: 'ghost',
											iconLeft: m(sjefIconX),
											onclick: async () => {
												// todo
												let result = await app.post('/lockermanagement/tickets/rollback', {
													allocationId: this.activeAllocation.id,
												});

												console.log(result)

												if (result.success) {
													app.toast('yes') // todo
												} else {
													app.toast('no')
												}
											}
										})
										,
									],
									m(sjefModal, {
										buttonLayout: 'ghost',
										buttonIconLeft: m(sjefIconEdit),
										buttonLabel: _t('_.buttons.edit'),
										modalTitle: _t('_.labels.editAllocation'),
										// tooltip: _t('_.labels.editAllocation'),
										onOpen: () => {
											// console.log(this.activeAllocation);
											this.allocationForm.id = this.activeAllocation.id;
											this.allocationForm.modeTimeslot = this.activeAllocation.modeTimeslot;
											this.allocationForm.modeStarthour = this.activeAllocation.modeStarthour;
											this.allocationForm.modeValidhours = this.activeAllocation.modeValidhours;
											this.allocationForm.description = this.activeAllocation.description;
										},
										onSubmit: async () => {
											let result = await app.patch('/lockermanagement/allocations', this.allocationForm);

											if (result.success) {
												let allocationResult = await app.get('/lockermanagement/allocations/' + this.allocationForm.id);

												if (allocationResult.success) {
													this.activeAllocation = allocationResult.data;
												}

												m.redraw();
											}
										},
										modalContent: [
											m(
												Form,
												{},
												m('h5', _t('_.labels.generalInfo')),
												m(sjefInput, {
													label: 'Description',
													name: 'description',
													value: this.allocationForm.description,
													onChange: (newValue) => {
														this.allocationForm.description = newValue;
													},
												}),
												m(sjefInput, {
													label: 'Mode',
													type: 'select',
													name: 'modeTimeslot',
													options: ['1010', '0000'],
													value: this.allocationForm.modeTimeslot,
													onChange: (newValue) => {
														this.allocationForm.modeTimeslot = newValue;
													},
												}),
												m(sjefInput, {
													label: 'Mode Starthour',
													type: 'number',
													name: 'modeStarthour',
													value: this.allocationForm.modeStarthour,
													onChange: (newValue) => {
														this.allocationForm.modeStarthour = newValue;
													},
												}),
												m(sjefInput, {
													label: 'Mode Valid Hours',
													type: 'number',
													name: 'modeValidhours',
													value: this.allocationForm.modeValidhours,
													onChange: (newValue) => {
														this.allocationForm.modeValidhours = newValue;
													},
												})
												// m('label', _t('_.fields.preprint')),
												// m(
												// 	'.columns-2',
												// 	{},
												// 	m(sjefInput, {
												// 		type: 'number',
												// 		value: this.allocationForm.preprint.medium,
												// 		onChange: (value) => {
												// 			this.allocationForm.preprint?.medium = value;
												// 		},
												// 	}),
												// 	m(sjefInput, {
												// 		type: 'number',
												// 		value: this.allocationForm.preprint.large,
												// 		onChange: (value) => {
												// 			this.allocationForm.preprint?.large = value;
												// 		},
												// 	})
												// ),
											),
										],
									}),
									m(sjefModal, {
										buttonLayout: 'ghost',
										// buttonLabel: m(sjefIconX),
										buttonIconLeft: m(sjefIconX),
										modalTitle: _t('_.buttons.delete'),
										buttonLabel: _t('_.buttons.delete'),
										// tooltip: _t('_.buttons.delete'),
										buttonConfirm: _t('_.buttons.delete'),
										modalContent: [m('p', 'Are you sure you want to delete allocation ' + this.activeAllocation.description)],
										onSubmit: async () => {
											let result = await app.delete('/lockermanagement/allocations', {
												id: this.activeAllocation.id,
											});

											if (result.success) {
											}

											m.redraw();
										},
									})
								)
							),
							this.allocationState == 'overview' 
								? [
									this.activeAllocation && this.areaAllocations.length ? [
										m(
											'.columns-3-2',
											m(
												Card,
												m('h3', _t('_.labels.generalInfo')),
												m(
													'.readonlyFields',
													m(
														FormGroup,
														{ class: 'readonly' },
														m('label', _t('_.fields.modeTimeslot')),
														m('p', this.activeAllocation.modeTimeslot)
													),
													m(
														FormGroup,
														{ class: 'readonly' },
														m('label', _t('_.fields.modeStartHour')),
														m('p', this.activeAllocation.modeStarthour)
													),
													m(
														FormGroup,
														{ class: 'readonly' },
														m('label', _t('_.fields.modeValidHours')),
														m('p', this.activeAllocation.modeValidhours)
													)
												)
											),
											m(Card, [
												m('h3', [ m('span', { style: {'opacity' : 0.8}}, _t('_.labels.area') + ': '),  m('span', this.activeAllocation.areaName)]),
												this.areaAllocations.length ? [
													m('.areaAllocations', 
														this.areaAllocations.map((allocation) => {
															return m('.areaAllocation', {
																class: this.activeAllocation.id == allocation.id ? 'active' : '',
																onclick: () => {
																	this.setActiveAllocation(allocation.id);
																}
															},
																m(sjefIconMapPin),
																m('span', allocation.description)
															)
														})
													)
												] : void(0)
											]),
										),
										m(
											'.flexer',
											m('h2', _t('_.labels.cabinets')),
											m(sjefButton, {
												buttonLayout: 'filled',
												label: _t('_.buttons.manage'),
												onclick: () => {
													this.toggleAllocationState();
													m.redraw();
												},
											})
										),
										this.cabinets.length ? 
										this.cabinets.map((cabinet) => {
											return m('p', cabinet)
										}) : void(0)
									] : void(0)
								  ]
								: m(sjefTable, {
										tableSettings: true,
										endpoint: '/lockermanagement/cabinets',
										config: 'cabinets',
										checkboxAction: async (type) => this.attachCabinets(type),
								  }),
					  ]
			),
		];
	}
}

export default vwEventOverview;
