import modalReportsCreateProjectCompletion from '../views/modals/modalReportsCreateProjectCompletion';

const moduleName = 'reportReconciliation';
const baseRoute = '/reporting/reconciliations';
const endpoint = '/reporting/reconciliations';
// const endpoint = 'http://127.0.0.1:3020/projects/completion';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => false,
			canUpdate: () => false,
			canDelete: () => false,
			canCancel: (record) => record.status !== 'cancelled',
			canDownload: (record) => record.status === 'completed',
		};
		this.hideCheckboxes = true,
		this.listButtons = [
			{
				component: modalReportsCreateProjectCompletion,
				componentAttrs: {
					onSuccess: () => {
						this.view.reload();
					},
				},
			}
		];
		this.schema = [
			{
				groupName: 'General',
				class: '.grid-1',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						listActiveToggle: false,
						listColumnPosition: 0,
						// link: `${baseRoute}/:id`,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						// link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'projectName',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'status',
						type: 'string',
						advancedFilterType: 'select',
						advancedFilterOptions: ['cancelled', 'completed', 'failed'],
						formInputType: 'text',
						formShow: true,
						inputMarker: 'status',
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'createdAt',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
				],
			},
		];

		this.formButtons = [
			{
				component: modalReportsCreateProjectCompletion,
				componentAttrs: {
					onSuccess: () => {
						this.view.reload();
					},
				},
			},
		];
	}
}
