import m from 'mithril';
import { _t } from '../../lib/i18n';
import sjefIconCalendar from '@sjefapp/sjeficons/icons/sjefIconCalendar';
import sjefIconMarkdown from '@sjefapp/sjeficons/icons/sjefIconMarkdown';
import sjefIconCertificate from '@sjefapp/sjeficons/icons/sjefIconCertificate';
import sjefIconPlus from '@sjefapp/sjeficons/icons/sjefIconPlus';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefInput from '../components/sjefInput';
import Slider from '../components/slider';
import sjefIconMapPin from '@sjefapp/sjeficons/icons/sjefIconMapPin';
import sjefIconThumbUp from '@sjefapp/sjeficons/icons/sjefIconThumbUp';
import sjefButton from '../components/sjefButton';
import sjefCheckbox from '../components/sjefCheckbox';

const venueLocations = {
	Willem: [
		{
			name: '',
			description: '',
			modeLocker: '',
			modeTimeslot: '1010',
			modeStarthour: '0',
			modeValidhours: '0',
		},
	],
	Ruud: [
		{
			name: '',
			description: '',
			modeLocker: '',
			modeTimeslot: '1010',
			modeStarthour: '0',
			modeValidhours: '0',
		},
	],
};

let formData = {
	type: 'venue', // or venue
	name: '',
	eventType: 'oneDay', // Default to one-day event
	eventStart: '',
	eventEnd: '',
	days: [], // Array to hold days for multi-day event
	locations: {
		// Hans: [
		// 	{
		// 		name: '',
		// 		description: '',
		// 		modeLocker: '',
		// 		modeTimeslot: '1010',
		// 		modeStarthour: '0',
		// 		modeValidhours: '0',
		// 	},
		// ],
		// Arie: [
		// 	{
		// 		name: '',
		// 		description: '',
		// 		modeLocker: '',
		// 		modeTimeslot: '1010',
		// 		modeStarthour: '0',
		// 		modeValidhours: '0',
		// 	},
		// ],
	},
};

let locationValue = '';
let areaValues = {};

const addLocation = () => {
	formData.locations[locationValue] = [
		{
			name: '',
			description: '',
			modeLocker: '',
			modeTimeslot: '',
			modeStarthour: '',
			modeValidhours: '',
		},
	];
	areaValues[locationValue] = [
		{
			name: '',
			description: '',
			modeLocker: '',
			modeTimeslot: '',
			modeStarthour: '',
			modeValidhours: '',
		},
	];

	locationValue = '';
	m.redraw();
};

const getSlides = () => {
	const slides = [
		{
			title: 'Create an Event',
			subTitle: 'Create a new upcoming event',
			icon: m(sjefIconCalendar),
			tabName: 'Event details',
			tabContent: [
				m(sjefInput, {
					label: _t('_.labels.eventType'),
					description: 'Select the type of event',
					icon: m(sjefIconCertificate),
					value: formData.eventType,
					type: 'select',
					options: ['oneDay', 'multiDay'],
					onChange: (value) => {
						formData.eventType = value;
						if (value === 'oneDay') {
							formData.days = []; // Clear days if switching back to one-day event
						} else if (value === 'multiDay' && formData.days.length === 0) {
							formData.days.push({ start: '', end: '' }); // Add one row by default
						}
						m.redraw();
					},
				}),
				m(sjefInput, {
					label: _t('_.labels.eventTitle'),
					description: 'Event name is a field',
					icon: m(sjefIconCertificate),
					value: formData.name,
					onChange: (value) => {
						formData.name = value;
					},
				}),
				formData.eventType === 'oneDay'
					? m('.flex', [
							m(sjefInput, {
								label: _t('_.labels.eventStart'),
								icon: m(sjefIconCalendar),
								value: formData.eventStart,
								type: 'datetime-local',
								onChange: (value) => {
									formData.eventStart = value;
								},
							}),
							m(sjefInput, {
								label: _t('_.labels.eventEnd'),
								type: 'datetime-local',
								icon: m(sjefIconCalendar),
								value: formData.eventEnd,
								onChange: (value) => {
									formData.eventEnd = value;
								},
							}),
					  ])
					: m('.multiDayContainer', [
							formData.days.map((day, index) =>
								m('.dayEntry', [
									m(
										'.flexer',
										m(
											'.flex',
											m(sjefInput, {
												label: `Day ${index + 1} Start`,
												icon: m(sjefIconCalendar),
												type: 'datetime-local',
												value: day.start,
												onChange: (value) => {
													formData.days[index].start = value;
													m.redraw();
												},
											}),
											m(sjefInput, {
												label: `Day ${index + 1} End`,
												icon: m(sjefIconCalendar),
												type: 'datetime-local',
												value: day.end,
												onChange: (value) => {
													formData.days[index].end = value;
													m.redraw();
												},
											})
										),
										m(
											'.iconHover',
											{
												onclick: () => {
													formData.days.splice(index, 1);
													m.redraw();
												},
											},
											m(sjefIconX)
										)
									),
								])
							),
							m(
								'.iconHover',
								{
									onclick: () => {
										formData.days.push({ start: '', end: '' });
										m.redraw();
									},
								},
								m(sjefIconPlus)
							),
					  ]),
			],
		},
		{
			title: 'Add locations',
			subTitle: 'Explanation',
			icon: m(sjefIconMarkdown),
			tabName: 'Locations',
			tabContent: [
				formData.type === 'event'
					? [
							Object.keys(formData.locations).length > 0
								? Object.keys(formData.locations).map((location, idx) => {
										return m(
											'.repeaterField',
											m(sjefInput, {
												// key: 'location-' + idx,
												value: location,
												label: _t('_.labels.locationName'),
												onChange: (value) => {
													if (value !== location) {
														formData.locations[value] = formData.locations[location];
														delete formData.locations[location];
														m.redraw();
													}
												},
											}),
											m(
												'.iconHover',
												{
													// key: `iconRemove-${idx}`,
													onclick: () => {
														delete formData.locations[location];
														delete areaValues[location];
														m.redraw(); // Ensure Mithril redraws the view
													},
												},
												m(sjefIconX)
											)
										);
								  })
								: m('p', 'No locations added yet'),
							m(
								'.repeaterField',
								m(sjefInput, {
									value: locationValue,
									label: _t('_.labels.locationName'),
									onkeyup: (e) => {
										if (e.key === 'Enter' && locationValue) {
											addLocation();
										}
									},
									oninput: (e) => {
										locationValue = e.target.value;
									},
								}),
								m(
									'.iconHover',
									{
										onclick: () => {
											if (locationValue) {
												addLocation();
											}
										},
									},
									m(sjefIconPlus)
								)
							),
					  ]
					: formData.type === 'venue'
					? [
							Object.keys(venueLocations).map((location, idx) => {
								return m(
									'.repeaterField',
									m(sjefCheckbox, {
										checked: formData.locations[location] ? true : false,
										label: location,
										onChange: (value) => {
											if (value) {
												// push location to formData.locations
												formData.locations[location] = venueLocations[location];
											} else {
												// remove location from formData.locations
												delete formData.locations[location];
											}
										},
									})
								);
							}),
					  ]
					: void 0,
			],
		},
		{
			title: 'Add areas',
			subTitle: 'Explanation',
			icon: m(sjefIconCalendar),
			tabName: 'Areas',
			tabContent: [
				Object.keys(formData.locations).length > 0
					? Object.keys(formData.locations).map((location, idx) => {
							return m('.locationSection', { class: idx === 0 ? 'first' : '' }, [
								m('.locationTitle', idx + 1 + '. ' + location),
								formData.locations[location] && formData.locations[location].length > 0
									? formData.locations[location].map((area, areaIdx) => {
											return m('.areaField', [
												m(
													'.repeaterField',
													m(sjefInput, {
														icon: m(sjefIconMapPin),
														placeholder: _t('_.labels.area'),
														// key: `area-${idx}-${areaIdx}`,
														value: area.name,
														onChange: (newValue) => {
															area.name = newValue;
															console.log(formData.locations);

															m.redraw();
														},
													}),
													m(
														'.iconHover',
														{
															// key: `iconRemoveArea-${idx}-${areaIdx}`,
															onclick: () => {
																formData.locations[location].splice(areaIdx, 1);
																m.redraw();
															},
														},
														m(sjefIconX)
													)
												),
												m(
													'form',
													m(sjefInput, {
														label: _t('_.fields.description'),
														name: 'description',
														value: area.description || '',
														onChange: (newValue) => {
															area.description = newValue;
															console.log(formData.locations);

															m.redraw();
														},
													}),
													m(sjefInput, {
														label: _t('_.fields.modeLocker'),
														name: 'modeLocker',
														type: 'select',
														value: area.modeLocker || '',
														options: ['eventmode1'],
														onChange: (newValue) => {
															area.modeLocker = newValue;
															console.log(formData.locations);

															m.redraw();
														},
													}),
													m(sjefInput, {
														label: _t('_.fields.modeTimeslot'),
														type: 'select',
														name: 'modeTimeslot',
														options: ['1010', '0000'],
														value: area.modeTimeslot || '',
														onChange: (newValue) => {
															area.modeTimeslot = newValue;
															console.log(formData.locations);

															m.redraw();
														},
													}),
													m(sjefInput, {
														label: _t('_.fields.modeStartHour'),
														type: 'number',
														name: 'modeStarthour',
														value: area.modeStarthour || '',
														onChange: (newValue) => {
															area.modeStarthour = newValue;
															console.log(formData.locations);

															m.redraw();
														},
													}),
													m(sjefInput, {
														label: _t('_.fields.modeValidHours'),
														type: 'number',
														name: 'modeValidhours',
														value: area.modeValidhours || '',
														onChange: (newValue) => {
															area.modeValidhours = newValue;
															console.log(formData.locations);
															m.redraw();
														},
													}),
													areaIdx > 0 &&
														m(
															'.iconHover',
															{
																onclick: () => {
																	e.preventDefault();
																	const previousArea = formData.locations[location][areaIdx - 1];
																	area.name = previousArea.name;
																	area.description = previousArea.description;
																	area.modeLocker = previousArea.modeLocker;
																	area.modeTimeslot = previousArea.modeTimeslot;
																	area.modeStarthour = previousArea.modeStarthour;
																	area.modeValidhours = previousArea.modeValidhours;
																	m.redraw();
																},
															},
															m(sjefButton, { buttonLayout: 'empty' }, 'Copy settings')
														)
												),
											]);
									  })
									: m('p', 'No areas added yet'),
								m(
									'.repeaterField',
									m(sjefInput, {
										value: areaValues[location] ? areaValues[location].name : '',
										onkeyup: (e) => {
											if (e.key === 'Enter') {
												if (areaValues[location] && areaValues[location].name) {
													const newArea = {
														name: areaValues[location].name,
														description: areaValues[location].description,
														modeLocker: areaValues[location].modeLocker,
														modeTimeslot: areaValues[location].modeTimeslot,
														modeStarthour: areaValues[location].modeStarthour,
														modeValidhours: areaValues[location].modeValidhours,
													};
													formData.locations[location].push(newArea);
													areaValues[location] = {
														name: '',
														description: '',
														modeLocker: '',
														modeTimeslot: '',
														modeStarthour: '',
														modeValidhours: '',
													}; // Reset the field
													m.redraw(); // Ensure Mithril redraws the view
												}
											}
										},
										oninput: (e) => {
											if (!areaValues[location]) {
												areaValues[location] = {
													name: '',
													description: '',
													modeLocker: '',
													modeTimeslot: '',
													modeStarthour: '',
													modeValidhours: '',
												};
											}
											areaValues[location].name = e.target.value;
											console.log(formData.locations);
										},
									}),
									m(
										'.iconHover',
										{
											onclick: () => {
												if (areaValues[location] && areaValues[location].name) {
													const newArea = {
														name: areaValues[location].name,
														description: areaValues[location].description,
														modeLocker: areaValues[location].modeLocker,
														modeTimeslot: areaValues[location].modeTimeslot,
														modeStarthour: areaValues[location].modeStarthour,
														modeValidhours: areaValues[location].modeValidhours,
													};
													formData.locations[location].push(newArea);
													areaValues[location] = {
														name: '',
														description: '',
														modeLocker: '',
														modeTimeslot: '',
														modeStarthour: '',
														modeValidhours: '',
													}; // Reset the field
													m.redraw(); // Ensure Mithril redraws the view
												}
											},
										},
										m(sjefIconPlus)
									)
								),
							]);
					  })
					: m('p', 'No locations added yet'),
			],
		},
		{
			title: 'Confirmation',
			subTitle: 'Explanation',
			icon: m(sjefIconThumbUp),
			type: 'confirmation',
			tabName: 'Confirmation',
			tabContent: [],
		},
	];

	// // Generate the content for the confirmation tab
	const confirmationContent = slides.slice(0, -1).map((slide) =>
		m.fragment({}, [
			m(
				'h3',
				{
					id: slide.tabName.replace(' ', '').toLowerCase(),
				},
				slide.title
			),
			slide.tabContent,
		])
	);

	const confirmationTabs = slides.slice(0, -1).map((slide) =>
		m.fragment({}, [
			m(
				'a',
				{
					href: '#' + slide.tabName.replace(' ', '').toLowerCase(),
				},
				slide.tabName
			),
		])
	);

	const confirmation = [m('.confirmation', m('.tabsWrapper', confirmationTabs), m('.contentWrapper', confirmationContent))];

	slides.slice(0, -1).map((slide) => m.fragment({}, [m('h3', slide.title), slide.tabContent]));

	// // Add the generated content to the last slide
	// slides[slides.length - 1].tabContent = confirmation; //  not working as assigning to slides within slides which is non existent gives error

	return slides;
};

class vwLockerLanding {
	oninit(vnode) {}

	view() {
		return [
			m(
				'.vwLockers.vwDetails.layout-small',
				m(Slider, {
					padding: 'large',
					slides: getSlides(),
				})
			),
		];
	}
}

export default vwLockerLanding;
