{
	"_": {
		"actions": {
			"save": "Opslaan"
		},
		"buttons": {
			"activate": "Activeren",
			"activateAdyen": "Adyen activeren",
			"applyFilters": "Filters toepassen",
			"archive": "Archiveren",
			"autoTranslate": "Automatisch vertalen",
			"backToList": "Teruggaan",
			"cancel": "Annuleren",
			"clearFilters": "Filters wissen",
			"close": "Sluiten",
			"complete": "Voltooien",
			"copy": "Kopiëren",
			"create": "Creëren",
			"deactivate": "Deactiveren",
			"delete": "Verwijderen",
			"discard": "Verwerpen",
			"download": "Downloaden",
			"edit": "Bewerken",
			"export": "Exporteren",
			"finish": "Voltooien",
			"generateTickets": "Tickets genereren",
			"maintenance": "Onderhoud",
			"manage": "Beheren",
			"new": "Nieuw",
			"rollback": "Terugdraaien",
			"save": "Opslaan",
			"saveAndClose": "Opslaan en sluiten",
			"setMaintenance": "Onderhoud instellen",
			"switch": "Verlaten"
		},
		"fields": {
			"${field}": "",
			"account": "Account",
			"accountId": "Account Id",
			"active": "Actief",
			"activeInShop": "Actief in winkel",
			"activeKiosk": "Kiosk actief",
			"activePos": "Pos actief",
			"activeWebshop": "Webshop actief",
			"address": "Adres",
			"address2": "Adres 2",
			"adyen": {
				"balanceAccounts": "Saldo rekeningen",
				"businessLineId": "Business line ID",
				"businessLines": "Business lines",
				"canDoPayouts": "Kan uitbetalingen doen",
				"canReceiveFromPlatformPayments": "Kan ontvangen van platformbetalingen",
				"canReceivePayments": "Kan betalingen ontvangen",
				"capabilities": "Capaciteiten",
				"clientConfig": "Client Config",
				"clientMutations": "Mutaties",
				"merchantId": "Handelaar ID",
				"mutations": {
					"amount": "Bedrag",
					"balanceAccount": "Balance Account",
					"currency": "Valuta",
					"description": "Beschrijving",
					"reference": "Referentie",
					"title": "Voeg mutatie toe",
					"type": "Type"
				},
				"receiveFromBalanceAccount": "Ontvangen van saldo rekening",
				"receiveFromPlatformPayments": "Ontvangen van platformbetalingen",
				"receivePayments": "Betalingen ontvangen",
				"sendToBalanceAccount": "Versturen naar saldo rekeningen",
				"sendToTransferInstrument": "Versturen naar overboekingsinstrument",
				"shopperStatement": "Aankoopafschrift",
				"status": "Status",
				"storeId": "Winkel ID",
				"stores": "Winkels"
			},
			"adyenCompanyAccount": "Adyen bedrijfsaccount",
			"adyenEcomStoreId": "AdyenEcom winkel ID",
			"adyenEcomStoreName": "AdyenEcom winkelnaam",
			"adyenMerchantAccount": "Adyen merchant account",
			"adyenPosStoreId": "AdyenPos winkel ID",
			"adyenPosStoreName": "AdyenPos winkelnaam",
			"allowNegativepayment": "Negatieve betaling toestaan",
			"allowOverpayment": "Overbetaling toestaan",
			"allowSplitpayment": "Gesplitste betaling toestaan",
			"allowZeropayment": "Nulbetaling toestaan",
			"allowed": "Toegestaan",
			"allowedAreas": "Toegestane gebieden",
			"amount": "Bedrag",
			"amountAvailable": "Beschikbaar bedrag",
			"amountCommission": "Commissiebedrag",
			"amountCommissionMarkup": "Commissiemarkering",
			"amountSplit": "Bedrag gesplitst",
			"amountTotal": "Totaalbedrag",
			"and": "en",
			"apiKey": "API-sleutel",
			"archived": "Gearchiveerd",
			"area": "Gebied",
			"areaId": "Gebied ID",
			"askforAccount": "Vraag om account",
			"balanceAccountId": "balanceAccountId",
			"balancedue": "Verschuldigd saldo",
			"balanceoverview": {
				"EUR": "EUR",
				"GBP": "GBP",
				"USD": "USD"
			},
			"balances": "Saldo's",
			"barcode": "Barcode",
			"bic": "BIC",
			"branchId": "Tak ID",
			"cabinetId": "Kast ID",
			"cabinetNumber": "Kastnummer",
			"cancelledAt": "Geannuleerd op",
			"cashfunctionId": "Kassafunctie ID",
			"categories": "Categorieën",
			"city": "Stad",
			"clerk": "Bediende",
			"clerkId": "Bediende ID",
			"client": "Klant",
			"clientCodeKantoorLegacy": "Klantcode KantoorLegacy",
			"clientId": "Klant ID",
			"clientKey": "Klant sleutel",
			"code": "Code",
			"coinAmount": "Muntbedrag",
			"coinButtonValues": "Muntknopwaarden",
			"coinLabel": "Muntlabel",
			"coinSmallestSize": "Kleinste munt",
			"coinValue": "Muntwaarde",
			"color": "Kleur",
			"colorKiosk": "Kiosk kleur",
			"colorPos": "Pos kleur",
			"colorWebshop": "Webshop kleur",
			"colorWebshopSecondary": "Webshop secundaire kleur",
			"companyNumber": "Bedrijfsnummer",
			"completedAt": "Voltooid op",
			"countries": "Landen",
			"country": "Land",
			"courseId": "Cursus ID",
			"create": "Creëren",
			"createAllocation": "Toewijzing creëren",
			"createdAt": "Gemaakt op",
			"currencies": "Valuta's",
			"currency": "Valuta",
			"customerCity": "Klant stad",
			"customerCountry": "Klant land",
			"customerEmail": "Klant e-mail",
			"customerHousenumber": "Klant huisnummer",
			"customerId": "Klant ID",
			"customerName": "Klantnaam",
			"customerPhoneNumber": "Klant telefoonnummer",
			"customerPhoneNumberCountryCode": "Klant telefoonnummer landcode",
			"customerStreetname": "Klant straatnaam",
			"customerZipcode": "Klant postcode",
			"date": "Datum",
			"days": "Dagen",
			"daysAgo": "",
			"departmentId": "Afdeling ID",
			"description": "Beschrijving",
			"descriptionLongTranslations": "Lange beschrijving vertalingen",
			"descriptionTranslations": "Beschrijving vertalingen",
			"direction": "Richting",
			"discountAmount": "Korting bedrag",
			"discountId": "Korting ID",
			"displayName": "Weergavenaam",
			"displaygroupId": "Weergavegroep ID",
			"domestic/foreign": {
                "domestic": "Binnenland",
                "foreign": "Buitenland"
            },
			"edit": "Bewerken",
			"electronicShelfLabel": "Elektronisch schaplabel",
			"elockersActive": "Elockers actief",
			"elockersType": "Elockers type",
			"email": "E-mail",
			"emailInvoice": "E-mail factuur",
			"emailKyc": "E-mail KYC",
			"enabled": "Ingeschakeld",
			"end": "Einde",
			"environment": "Omgeving",
			"estimated Arrival Time": "Geschatte aankomsttijd",
			"estimatedArrivalTime": "Geschatte aankomsttijd",
			"eventActivateAt": "Evenement activeren om",
			"eventDeactivateAt": "Evenement deactiveren om",
			"eventEnd": "Einde evenement",
			"eventLasts": "Evenement duurt",
			"eventStart": "Begin evenement",
			"external_identifier": "Externe identifier",
			"fulfillment": "Vervulling",
			"generalledgerAccount": "Grootboekrekening",
			"hours": "Uren",
			"hubspotId": "Hubspot ID",
			"iban": "IBAN",
			"id": "ID",
			"imgKioskNormal": "Kiosk afbeelding normaal",
			"imgKioskThumbnail": "Kiosk afbeelding miniatuur",
			"imgNormal": "Afbeelding normaal",
			"imgPosNormal": "Pos afbeelding normaal",
			"imgPosThumbnail": "Pos afbeelding miniatuur",
			"imgSvg": "Afbeelding SVG",
			"imgSvgFallbackItem": "Afbeelding SVG fallback item",
			"imgThumbnail": "Afbeelding miniatuur",
			"imgWebshopNormal": "Webshop afbeelding normaal",
			"imgWebshopThumbnail": "Webshop afbeelding miniatuur",
			"inAt": "In om",
			"inBy": "In door",
			"industryCode": "Industriecode",
			"invoiceAddress": "Factuuradres",
			"invoiceAddress2": "Factuuradres 2",
			"invoiceCity": "Factuurstad",
			"invoiceCountry": "Factuurland",
			"invoiceName": "Factuurnaam",
			"invoicePostalCode": "Factuurpostcode",
			"invoiceState": "Factuurstaat",
			"isBackorderItem": "Is nabestelling item",
			"isCashcardItem": "Is cashcard item",
			"isCoinPayment": "Is muntbetaling",
			"isCoins": "Is munten",
			"isDefault": "Is standaard",
			"isHiddenOnReceipt": "Is verborgen op ontvangstbewijs",
			"isOrderItem": "Is bestelitem",
			"isParentLeading": "Is leidend ouder",
			"isPlu": "Is PLU",
			"isPreferredPayment": "Is voorkeursbetaling",
			"isStockItem": "Is voorraadartikel",
			"isTip": "Is fooi",
			"isUniqueItem": "Is uniek item",
			"isVoucherPayment": "Is waardebon betaling",
			"isWallet": "Is portemonnee",
			"isWalletPayment": "Is portemonnee betaling",
			"large": "Groot",
			"latitude": "Breedtegraad",
			"legalEntityId": "Juridische entiteit ID",
			"locale": "Taal- en regiocode",
			"location": "Locatie",
			"locationId": "Locatie ID",
			"locationName": "Locatie naam",
			"lockVersion": "Lock versie",
			"lockerId": "Kluis ID",
			"lockerMode": "Kluis modus",
			"lockerNumber": "Kluisnummer",
			"lockerSerialNumber": "Kluis serienummer",
			"lockerType": "Kluis type",
			"logout": "Uitloggen",
			"longitude": "Lengtegraad",
			"manufacturer": "Fabrikant",
			"masBasket": "Maximale mand",
			"medium": "Medium",
			"merchantAccount": "Handelaar account",
			"merchantName": "Handelaar naam",
			"merchantReference": "Handelaar referentie",
			"metaBrand": "Meta merk",
			"metaCategory": "Meta categorie",
			"metaManufacturer": "Meta fabrikant",
			"metaName": "Meta naam",
			"metaSubcategory": "Meta subcategorie",
			"metaSupplier": "Meta leverancier",
			"metaUnitOfPacking": "Meta verpakkingseenheid",
			"metaUnitOfSale": "Meta verkoopeenheid",
			"metaVolumM1": "Meta volume M1",
			"modeLocker": "Kluis modus",
			"modeStartHour": "Start uur",
			"modeTimeslot": "Tijdslot",
			"modeValidHours": "Geldige uren",
			"model": "Model",
			"name": "Naam",
			"nameId": "Naam ID",
			"nameKioskTranslations": "Kiosk naam vertalingen",
			"nameKitchen": "Keuken naam",
			"namePosTranslations": "Pos naam vertalingen",
			"nameTranslations": "Naam vertalingen",
			"nameWebshopTranslations": "Webshop naam vertalingen",
			"new": "Nieuw",
			"next": "Volgende",
			"noResults": "Geen resultaten",
			"notes": "Notities",
			"open": "Open",
			"openAll": "Alles openen",
			"outAt": "Uit om",
			"outBy": "Uit door",
			"parentId": "Ouder ID",
			"parent_id": "Ouder ID",
			"paymentMethods": "Betaalmethoden",
			"paymentmethod": "Betaalmethode",
			"paymentmethodId": "Betaalmethode ID",
			"paymentprofileId": "Betaalprofiel ID",
			"paymentserviceproviderId": "Betaaldienstaanbieder ID",
			"paymentserviceproviderMethod": "Betaaldienstaanbieder methode",
			"payoutId": "payoutId",
			"percentage": "Percentage",
			"periodType": "periodType",
			"periodValue": "periodValue",
			"phoneNumber": "Telefoonnummer",
			"phoneNumberCountryCode": "Telefoonnummer landcode",
			"pincode": "Pincode",
			"placeholders": {
				"active": "Actief",
				"address": "",
				"address2": "",
				"adyenBusinessLines": "",
				"city": "",
				"companyNumber": "Bedrijfsnummer",
				"country": "",
				"email": "E-mail",
				"emailInvoice": "E-mail factuur",
				"emailKyc": "E-mail KYC",
				"hubspotId": "Hubspot ID",
				"id": "ID",
				"industryCode": "Industriecode",
				"invoiceAddress": "Factuuradres",
				"invoiceAddress2": "Factuuradres 2",
				"invoiceCity": "Factuurstad",
				"invoiceCountry": "Factuurland",
				"invoiceName": "Factuurnaam",
				"invoicePostalCode": "Factuurpostcode",
				"invoiceState": "Factuurstaat",
				"merchantId": "",
				"name": "Naam",
				"nameId": "Naam ID",
				"phoneNumber": "Telefoonnummer",
				"phoneNumberCountryCode": "+ 316",
				"planId": "Plan ID",
				"region": "Region",
				"role": "Rol",
				"shopperStatement": "Aankoopafschrift",
				"taxNumber": "Belastingnummer",
				"uuid": "UUID",
				"visitAddress": "Adres",
				"visitAddress2": "Adres 2",
				"visitCity": "Stad",
				"visitCountry": "Land",
				"visitName": "Naam",
				"visitPostalCode": "Postcode",
				"visitState": "Staat",
				"wefactId": "WeFact ID",
				"zipcode": ""
			},
			"planId": "Plan ID",
			"posAdminOnly": "Pos alleen admin",
			"posColor": "Pos kleur",
			"posOpendrawer": "Pos lade openen",
			"posOpenprice": "Pos open prijs",
			"posPrintreceipt": "Pos bon printen",
			"posSearchable": "Pos doorzoekbaar",
			"posVisible": "Pos zichtbaar",
			"position": "Positie",
			"positionKiosk": "Kiosk positie",
			"positionKitchen": "Keuken positie",
			"positionPos": "Pos positie",
			"positionWebshop": "Webshop positie",
			"preprint": "Vooraf printen",
			"previous": "Vorige",
			"price": "Prijs",
			"priceCosts": "Prijs kosten",
			"priceRetail": "Prijs detailhandel",
			"problems": "Problemen",
			"processed At": "Verwerkt om",
			"processedAt": "Verwerkt om",
			"profileId": "Profiel ID",
			"projectEnd": "Einde project",
			"projectName": "Projectnaam",
			"projectStart": "Begin project",
			"pspEftReceipt": "PSP EFT bon",
			"pspMethod": "PSP methode",
			"pspName": "PSP naam",
			"pspPayload": "PSP payload",
			"pspResponse": "PSP respons",
			"pspVariant": "PSP variant",
			"quantityMaximum": "Maximale hoeveelheid",
			"quantityMinimum": "Minimale hoeveelheid",
			"reason": "Reden",
			"redirectUrl": "Redirect URL",
			"reference": "Referentie",
			"region": "region",
			"requested": "Aangevraagd",
			"requiredAccount": "Account vereist",
			"requiredBookingnumber": "Boekingsnummer vereist",
			"reserve": "Reserveren",
			"saleschannels": "Verkoopkanalen",
			"selected": "Geselecteerd",
			"sequence": "sequence",
			"serialNumber": "Serienummer",
			"serialnumber": "Serienummer",
			"service": "Service",
			"settlementId": "settlementId",
			"shopSelection": "Winkel selectie",
			"shopperStatement": "Aankoopafschrift",
			"sjefId": "Sjef ID",
			"slug": "Slug",
			"source": "Bron",
			"stack": "Stapelen",
			"start": "Start",
			"status": "Status",
			"statusAssets": "Status activa",
			"subtotal": "Subtotaal",
			"subtotalId": "Subtotaal ID",
			"supplier": "Leverancier",
			"table": "Tafel",
			"tableId": "Tafel ID",
			"taxNumber": "Belastingnummer",
			"taxes": "Belastingen",
			"taxrateId": "Belastingtarief ID",
			"terminalSequence": "Terminal volgorde",
			"ticketeer": "Kaartjesverkoper",
			"tickets_c": "Kleur",
			"tickets_lid": "Kluis ID",
			"tickets_lno": "Kluisnummer",
			"tickets_lpin": "Kluis pin",
			"tickets_lt": "Type",
			"tickets_tid": "Ticket ID",
			"tickets_w": "Golf",
			"timeWindow": "Tijdvenster",
			"timestamp": "Tijdstempel",
			"timezone": "Tijdzone",
			"today": "",
			"token": "Token",
			"tokenAmount": "Token bedrag",
			"tokenDescription": "Token beschrijving",
			"total": "Totaal",
			"totalCoins": "Totale munten",
			"translations": "Vertalingen",
			"type": "Type",
			"uniqueItemType": "Uniek itemtype",
			"uniqueItemTypePrintjobs": "Uniek itemtype printopdrachten",
			"updatedAt": "Bijgewerkt op",
			"uuid": "UUID",
			"verificationStatus": "Verificatiestatus",
			"videoWebshop": "Webshop video",
			"viewWebshop": "Webshop weergave",
			"visitAddress": "Bezoekadres",
			"visitAddress2": "Bezoekadres 2",
			"visitCity": "Bezoek stad",
			"visitCountry": "Bezoek land",
			"visitName": "Bezoek naam",
			"visitPostalCode": "Bezoek postcode",
			"visitState": "Bezoek staat",
			"wave": "Golf",
			"webdata": "",
			"wefactId": "WeFact ID",
			"yesterday": "",
			"zipcode": "Postcode"
		},
		"filterTabs": {
			"defaultTabs": "Standaard",
			"savedTabs": "Opgeslagen"
		},
		"labels": {
			"active": "Actief",
			"allocations": "Toewijzingen",
			"area": "Gebied",
			"areaReport": "Gebiedsrapport",
			"cabinets": "Kasten",
			"checklist": "Checklist",
			"createAllocation": "Toewijzing creëren",
			"createArea": "Gebied creëren",
			"createClientSubtitle": "Vul de vereiste velden in",
			"createEvent": "Evenement creëren",
			"createLocation": "Locatie creëren",
			"deleteArea": "Gebied verwijderen",
			"deleteLocation": "Locaties verwijderen",
			"editAllocation": "Toewijzing bewerken",
			"editClientSubtitle": "Bewerk je klantgegevens",
			"editLocation": "Locatie bewerken",
			"event": "Evenement",
			"eventEnd": "Einde evenement",
			"eventName": "Evenementnaam",
			"eventStart": "Begin evenement",
			"eventTitle": "",
			"eventType": "",
			"filters": "Filters",
			"generalInfo": "Algemene info",
			"invite": "",
			"locationName": "",
			"locations": "Locaties",
			"logout": {
				"confirmContent": "Weet je zeker dat je wilt uitloggen?",
				"confirmTitle": "Uitloggen bevestigen"
			},
			"maintenance": "Onderhoud",
			"maintenanceCancel": "Onderhoud annuleren",
			"maintenanceCancelContent": "Weet je zeker dat je het onderhoud wilt annuleren?",
			"maintenanceComplete": "Onderhoud voltooien",
			"maintenanceCompleteContent": "Weet je zeker dat je het onderhoud wilt voltooien?",
			"noAreas": "Geen gebieden",
			"openOrganisation": "Organisatie openen",
			"report": "Rapport",
			"switch": {
				"confirmContent": "Weet je zeker dat je {{name}} wilt verlaten?",
				"confirmTitle": "Wisselen"
			},
			"tickets": "Tickets",
			"ticketsGenerated": "Tickets gegenereerd"
		},
		"messages": {
			"notAvailable": ""
		},
		"modals": {
			"cancelDeleteButton": "Annuleren",
			"confirmCancelButton": "Bevestigen",
			"confirmCancelContent": "Weet je zeker dat je wilt annuleren?",
			"confirmCancelTitle": "Annulering bevestigen",
			"confirmDeleteButton": "Verwijderen",
			"confirmDeleteContent": "Weet je zeker dat je wilt verwijderen?",
			"confirmDeleteTitle": "Verwijdering bevestigen",
			"reload": {
				"description": "Wil je de pagina herladen?",
				"title": "Pagina herladen"
			}
		},
		"months": {
			"april": "",
			"august": "",
			"december": "",
			"february": "",
			"january": "",
			"july": "",
			"june": "",
			"march": "",
			"may": "",
			"november": "",
			"october": "",
			"september": ""
		},
		"records": {
			"noRecordsAvailable": "Geen records beschikbaar"
		},
		"search": {
			"search": "Zoeken..."
		},
		"singular": {
			"accounts": "Account",
			"administrations": "Administratie",
			"areas": "Gebied",
			"assets": "Activa",
			"balance_mutations": "",
			"branches": "Tak",
			"cabinets": "Kast",
			"cashfunctions": "Kassafunctie",
			"categories": "Categorie",
			"clerks": "Bediende",
			"clients": "Klant",
			"courses": "Cursus",
			"departments": "Afdeling",
			"displaygroups": "Weergavegroep",
			"events": "Evenement",
			"items": "Item",
			"locations": "Locatie",
			"lockerEvents": "Kluis evenement",
			"lockers": "Kluis",
			"maintenance": "Onderhoud",
			"menus": "Menu",
			"modifiers": "Modifier",
			"organisations": "Organisatie",
			"paymentmethods": "Betaalmethode",
			"paymentprofiles": "Betaalprofiel",
			"payments": "Betaling",
			"paymentserviceproviders": "Betaaldienstaanbieder",
			"payouts": "Uitbetaling",
			"projects": "Project",
			"psps": "PSP",
			"settlements": "Afwikkeling",
			"subtotals": "Subtotaal",
			"suppliers": "Leverancier",
			"taxrates": "Belastingtarief",
			"tickets": "Ticket",
			"transactions": "Transactie"
		},
		"th": {
			"name": "Naam"
		}
	},
	"_fields": {
		"name": "Naam"
	},
	"clients": {
		"clientConfig": {
			"balanceAccountId": "Balance Account ID",
			"merchantAccount": "Merchant Account",
			"modal": {
				"edit": {
					"button": "Aanpasses",
					"title": "Pas de client configuration aan"
				},
				"new": {
					"button": "New",
					"title": "Voeg een nieuwe client configuration toe"
				}
			},
			"paymentConfigId": "Payment config ID",
			"region": "Region",
			"storeId": "Store ID",
			"type": "Type"
		},
		"clientInvite": {
			"actions": {
				"block": "Blokkeer uitnodiging",
				"resend": "Verzend uitnodiging opnieuw",
				"title": "Acties"
			},
			"createdAt": "Aangemaakt op",
			"createdBy": "Aangemaakt door",
			"description": "Maak een uitndoiging aan voor de klant",
			"email": "E-mail",
			"expiresAt": "Verloopt op",
			"status": "Status",
			"title": "Uitnodiging versturen",
			"toast": {
				"blocked": "Uitnodiging geblokkeerd",
				"sent": "Uitnodiging verstuurd"
			}
		},
		"fields": {
			"hubspotId": "HubSpotID",
			"nameId": "naamID",
			"wefactId": "WeFactID"
		},
		"invoices": {
			"subtitle": "",
			"table": {
				"th": {
					"amountExcl": "Bedrag excl.",
					"amountIncl": "Bedrag incl.",
					"amountOutstanding": "Openstaand bedrag",
					"code": "Code",
					"date": "Date",
					"status": "Status"
				}
			},
			"title": ""
		},
		"pricequotes": {
			"subtitle": "",
			"title": ""
		},
		"tabs": {
			"adyen": "Adyen",
			"details": "Details",
			"invoices": "Facturen"
		}
	},
	"countries": {
		"AD": "Andorra",
		"AE": "Verenigde Arabische Emiraten",
		"AF": "Afghanistan",
		"AG": "Antigua en Barbuda",
		"AI": "Anguilla",
		"AL": "Albanië",
		"AM": "Armenië",
		"AN": "Nederlandse Antillen",
		"AO": "Angola",
		"AQ": "Antarctica",
		"AR": "Argentinië",
		"AS": "Amerikaans-Samoa",
		"AT": "Oostenrijk",
		"AU": "Australië",
		"AW": "Aruba",
		"AX": "Ålandeilanden",
		"AZ": "Azerbeidzjan",
		"BA": "Bosnië en Herzegovina",
		"BB": "Barbados",
		"BD": "Bangladesh",
		"BE": "België",
		"BF": "Burkina Faso",
		"BG": "Bulgarije",
		"BH": "Bahrein",
		"BI": "Burundi",
		"BJ": "Benin",
		"BM": "Bermuda",
		"BN": "Brunei Darussalam",
		"BO": "Bolivia",
		"BR": "Brazilië",
		"BS": "Bahama's",
		"BT": "Bhutan",
		"BV": "Bouvet eiland",
		"BW": "Botswana",
		"BY": "Wit-Rusland",
		"BZ": "Belize",
		"CA": "Canada",
		"CC": "Cocoseilanden",
		"CD": "Congo, Democratische Republiek",
		"CF": "Centraal-Afrikaanse Republiek",
		"CG": "Congo",
		"CH": "Zwitserland",
		"CK": "Cookeilanden",
		"CL": "Chili",
		"CM": "Kameroen",
		"CN": "China",
		"CO": "Colombia",
		"CR": "Costa Rica",
		"CS": "Servië en Montenegro",
		"CU": "Cuba",
		"CV": "Kaapverdië",
		"CX": "Christmaseiland",
		"CY": "Cyprus",
		"CZ": "Tsjechië",
		"DE": "Duitsland",
		"DJ": "Djibouti",
		"DK": "Denemarken",
		"DM": "Dominica",
		"DO": "Dominicaanse Republiek",
		"DZ": "Algerije",
		"EC": "Ecuador",
		"EE": "Estland",
		"EG": "Egypte",
		"EH": "Westelijke Sahara",
		"ER": "Eritrea",
		"ES": "Spanje",
		"ET": "Ethiopië",
		"FI": "Finland",
		"FJ": "Fiji",
		"FK": "Falklandeilanden",
		"FM": "Micronesië, Federale Staten van",
		"FO": "Faeröer",
		"FR": "Frankrijk",
		"GA": "Gabon",
		"GB": "Verenigd Koninkrijk",
		"GD": "Grenada",
		"GE": "Georgië",
		"GF": "Frans-Guyana",
		"GG": "Guernsey",
		"GH": "Ghana",
		"GI": "Gibraltar",
		"GL": "Groenland",
		"GM": "Gambia",
		"GN": "Guinee",
		"GP": "Guadeloupe",
		"GQ": "Equatoriaal-Guinea",
		"GR": "Griekenland",
		"GS": "Zuid-Georgië en Zuidelijke Sandwicheilanden",
		"GT": "Guatemala",
		"GU": "Guam",
		"GW": "Guinee-Bissau",
		"GY": "Guyana",
		"HK": "Hongkong",
		"HM": "Heard en McDonaldeilanden",
		"HN": "Honduras",
		"HR": "Kroatië",
		"HT": "Haïti",
		"HU": "Hongarije",
		"ID": "Indonesië",
		"IE": "Ierland",
		"IL": "Israël",
		"IM": "Isle of Man",
		"IN": "India",
		"IO": "Brits Indische Oceaanterritorium",
		"IQ": "Irak",
		"IR": "Iran, Islamitische Republiek",
		"IS": "IJsland",
		"IT": "Italië",
		"JE": "Jersey",
		"JM": "Jamaica",
		"JO": "Jordanië",
		"JP": "Japan",
		"KE": "Kenia",
		"KG": "Kirgizië",
		"KH": "Cambodja",
		"KI": "Kiribati",
		"KM": "Comoren",
		"KN": "Saint Kitts en Nevis",
		"KR": "Korea, Republiek",
		"KW": "Koeweit",
		"KY": "Kaaimaneilanden",
		"KZ": "Kazachstan",
		"LB": "Libanon",
		"LC": "Saint Lucia",
		"LI": "Liechtenstein",
		"LK": "Sri Lanka",
		"LR": "Liberia",
		"LS": "Lesotho",
		"LT": "Litouwen",
		"LU": "Luxemburg",
		"LV": "Letland",
		"LY": "Libië",
		"MA": "Marokko",
		"MC": "Monaco",
		"MD": "Moldavië, Republiek",
		"MG": "Madagaskar",
		"MH": "Marshalleilanden",
		"MK": "Noord-Macedonië",
		"ML": "Mali",
		"MM": "Myanmar",
		"MN": "Mongolië",
		"MO": "Macao",
		"MP": "Noordelijke Marianen",
		"MQ": "Martinique",
		"MR": "Mauritanië",
		"MS": "Montserrat",
		"MT": "Malta",
		"MU": "Mauritius",
		"MV": "Malediven",
		"MW": "Malawi",
		"MX": "Mexico",
		"MY": "Maleisië",
		"MZ": "Mozambique",
		"NA": "Namibië",
		"NC": "Nieuw-Caledonië",
		"NE": "Niger",
		"NF": "Norfolk",
		"NG": "Nigeria",
		"NI": "Nicaragua",
		"NL": "Nederland",
		"NO": "Noorwegen",
		"NP": "Nepal",
		"NR": "Nauru",
		"NU": "Niue",
		"NZ": "Nieuw-Zeeland",
		"OM": "Oman",
		"PA": "Panama",
		"PE": "Peru",
		"PF": "Frans-Polynesië",
		"PG": "Papoea-Nieuw-Guinea",
		"PH": "Filipijnen",
		"PK": "Pakistan",
		"PL": "Polen",
		"PM": "Saint Pierre en Miquelon",
		"PN": "Pitcairn",
		"PR": "Puerto Rico",
		"PS": "Palestina, Staat",
		"PT": "Portugal",
		"PW": "Palau",
		"PY": "Paraguay",
		"QA": "Qatar",
		"RE": "Réunion",
		"RO": "Roemenië",
		"RU": "Rusland",
		"RW": "Rwanda",
		"SA": "Saoedi-Arabië",
		"SB": "Salomonseilanden",
		"SC": "Seychellen",
		"SD": "Soedan",
		"SE": "Zweden",
		"SG": "Singapore",
		"SH": "Sint-Helena, Ascension en Tristan da Cunha",
		"SI": "Slovenië",
		"SJ": "Svalbard en Jan Mayen",
		"SK": "Slowakije",
		"SL": "Sierra Leone",
		"SM": "San Marino",
		"SN": "Senegal",
		"SO": "Somalië",
		"SR": "Suriname",
		"ST": "Sao Tomé en Principe",
		"SV": "El Salvador",
		"SY": "Syrië",
		"SZ": "Eswatini",
		"TC": "Turks- en Caicoseilanden",
		"TD": "Tsjaad",
		"TF": "Franse Zuidelijke Gebieden",
		"TG": "Togo",
		"TH": "Thailand",
		"TJ": "Tadzjikistan",
		"TK": "Tokelau",
		"TL": "Oost-Timor",
		"TM": "Turkmenistan",
		"TN": "Tunesië",
		"TO": "Tonga",
		"TR": "Turkije",
		"TT": "Trinidad en Tobago",
		"TV": "Tuvalu",
		"TW": "Taiwan",
		"TZ": "Tanzania",
		"UA": "Oekraïne",
		"UG": "Oeganda",
		"UM": "Kleine afgelegen eilanden van de Verenigde Staten",
		"US": "Verenigde Staten",
		"UY": "Uruguay",
		"UZ": "Oezbekistan",
		"VA": "Heilige Stoel (Vaticaanstad)",
		"VC": "Saint Vincent en de Grenadines",
		"VE": "Venezuela",
		"VG": "Britse Maagdeneilanden",
		"VI": "Amerikaanse Maagdeneilanden",
		"VN": "Vietnam",
		"VU": "Vanuatu",
		"WF": "Wallis en Futuna",
		"WS": "Samoa",
		"YE": "Jemen",
		"YT": "Mayotte",
		"ZA": "Zuid-Afrika",
		"ZM": "Zambia",
		"ZW": "Zimbabwe"
	},
	"modules": {
		"accounts": {
			"tabs": {
				"details": "Details",
				"example": "Voorbeeld"
			},
			"title": ""
		},
		"administrations": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"areas": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"assets": {
			"tabs": {
				"assets": "",
				"details": "Details",
				"list": ""
			},
			"title": ""
		},
		"balance_mutations": {
			"tabs": {
				"details": ""
			},
			"title": ""
		},
		"balanceoverview": {
			"account_balance": "Rekeningsaldo",
			"continue_modal": {
				"button": "Doorgaan",
				"description": "Alles is ingesteld! Nog maar één laatste stap te gaan. Klik op de knop hieronder om door te gaan.",
			},
			"edit_balance_account": "Rekeningsaldo bewerken",
			"intro": {
				"paragraph1": "Ons Balance Platform stelt je in staat om je saldo en transacties te beheren. Met behulp van ons platform kun je eenvoudig je beschikbare saldo bekijken, uitbetalingen doen en transacties volgen.",
				"paragraph2": "Daarnaast bieden we ook handige widgets en grafieken om je financiële gegevens visueel weer te geven. Je kunt bijvoorbeeld zien hoeveel er is uitbetaald, hoeveel er nog in behandeling is en hoe je saldo zich in de loop van de tijd heeft ontwikkeld.",
				"paragraph3": "Kortom, ons Balance Platform is een krachtige tool om je financiën te beheren en inzicht te krijgen in je transacties.",
				"subtitle": "Over het Balance Platform",
				"title": "Je bent helaas nog niet geactiveerd op het Balance Platform!"
			},
			"recent_mutations": "Recente mutaties",
			"recent_payouts": "Recente uitbetalingen",
			"redirect_modal": {
				"description": ""
			},
			"widgets": {
				"authorised": "Geautoriseerd",
				"availableForPayout": "Beschikbaar voor uitbetaling",
				"invoiced": "Gefactureerd",
				"payout": "Uitbetaling",
				"sentForSettle": "Verzonden om te regelen",
				"settled": "Afgehandeld"
			}
		},
		"branches": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"cabinets": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"cashfunctions": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"categories": {
			"tabs": {
				"details": "Details",
				"locations": "Locaties",
				"profile": "Profiel"
			},
			"title": ""
		},
		"clerks": {
			"tabs": {
				"allowedAreas": "Toegestane Gebieden",
				"details": "Details"
			},
			"title": ""
		},
		"clients": {
			"tabs": {
				"adyen": "Banking",
				"details": "Details",
				"invites": "",
				"paymentScheme": "Betalingsregeling",
				"wefactInvoices": "Facturen",
				"wefactPricequotes": "Prijsoffertes"
			},
			"title": ""
		},
		"courses": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"departments": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"displaygroups": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"events": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"home": {
			"balanceplatform": {
				"button": "Ga naar het Balance Platform",
				"paragraph1": "Ons Balance Platform stelt je in staat om je saldo en transacties te beheren. Met behulp van ons platform kun je eenvoudig je beschikbare saldo bekijken, uitbetalingen doen en transacties volgen.",
				"paragraph2": "Daarnaast bieden we ook handige widgets en grafieken om je financiële gegevens visueel weer te geven. Je kunt bijvoorbeeld zien hoeveel er is uitbetaald, hoeveel er nog in behandeling is en hoe je saldo zich in de loop van de tijd heeft ontwikkeld.",
				"paragraph3": "Kortom, ons Balance Platform is een krachtige tool om je financiën te beheren en inzicht te krijgen in je transacties.",
				"subtitle": "Over het Balance Platform"
			},
			"greeting": {
				"goodafternoon": "Goedemiddag",
				"goodevening": "Goedenavond",
				"goodmorning": "Goedemorgen"
			},
			"intro": {
				"paragraph1": "Welkom bij Sjef!",
				"paragraph2": "Hier kun je al je administratieve zaken regelen, zoals het beheren van je klanten, het bijhouden van je voorraad en het volgen van je transacties."
			},
			"transactions": {
				"title": "Transacties"
			},
			"turnover": {
				"title": "Omzet"
			}
		},
		"items": {
			"tabs": {
				"categories": "Categorieën",
				"details": "Details",
				"modifiers": "Wijzigers"
			},
			"title": ""
		},
		"locations": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"lockerEvents": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"lockers": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"maintenance": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"menus": {
			"tabs": {
				"details": "Details",
				"items": "Items"
			},
			"title": ""
		},
		"modifiers": {
			"tabs": {
				"details": "Details",
				"items": "Items"
			},
			"title": ""
		},
		"organisations": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"paymentmethods": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"paymentprofiles": {
			"tabs": {
				"details": "Details",
				"paymentMethods": "Betaalmethoden"
			},
			"title": ""
		},
		"payments": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"paymentserviceproviders": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"payouts": {
			"title": ""
		},
		"projects": {
			"tabs": {
				"assets": "Activa",
				"details": "Details",
				"payments": "Betalingen"
			},
			"title": ""
		},
		"psps": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"settlements": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"subtotals": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"suppliers": {
			"tabs": {
				"details": "Details",
				"example": "Voorbeeld"
			},
			"title": ""
		},
		"taxrates": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"tickets": {
			"tabs": {
				"details": "Details"
			},
			"title": ""
		},
		"transactions": {
			"tabs": {
				"details": "Details",
				"lines&payments": "Lijnen & Betalingen"
			},
			"title": ""
		}
	},
	"reconciliation": {
		"new": "Nieuwe Afstemming"
	},
	"schema": {
		"tiers": {
			"quantity": "Drempel"
		}
	}
}
