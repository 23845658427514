import m from 'mithril';
import { Tooltip } from 'construct-ui';
import sjefIconInfoCircle from '@sjefapp/sjeficons/icons/sjefIconInfoCircle';
/* eslint:disable */

class infoToggle {
	view({ attrs: { size = 'lg', position = 'bottom-end', content, onclick = void(0), ...htmlAttrs  }}){
		return m(Tooltip, {
			content: content,
			position: position,
			hasArrow: true,
			size: size,
			trigger: m(sjefIconInfoCircle, {
				class: 'infoToggle'
			}
			),
			...htmlAttrs
		},
		)
	}
}
export default infoToggle;

