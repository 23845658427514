import m from 'mithril';
import { Card, Checkbox, Form, FormGroup, FormLabel, Input, TabItem, Tabs } from 'construct-ui';
import sjefButton from '../../views/components/sjefButton';

import app from '../../app';
import sjefIconArrowLeft from '@sjefapp/sjeficons/icons/sjefIconArrowLeft';
import sjefBreadcrumbs from '../../views/components/sjefBreadcrumbs';
import sjefCallout from '../../views/components/sjefCallout';
import sjefModal from '../../views/components/sjefModal';
// import Api from './components/Api';

// const loadRecord = async () => {
// 	console.log('load account');
// 	vwAccounts.accounts = await Api.get('accounts');
// };

const tabs = [
	{
		slug: 'personal',
		label: 'Persoonlijk',
	},
	{
		slug: 'invoice',
		label: 'Invoice',
	},
	{
		slug: 'shipping',
		label: 'Shipping',
	},
];
let activeTab = tabs[0];
let account = {};
let isNew;
let title;
let breadcrumbtitle;
let attentionCallout = true;
let actionCallout = true;
const modalOpen = false;

const vwAccountForm = {
	self: this,

	oninit: async ({ attrs }) => {
		console.log('this', self);

		if (attrs.id && attrs.id !== 'new') {
			account = await app.get('/masterdata/accounts/' + attrs.id);
			account = account.data;
			isNew = false;
			title = `Example: ${account.id} - ${account.name}`;
			breadcrumbtitle = account.name;
		} else {
			isNew = true;
			title = 'New Account';
			breadcrumbtitle = title;
			account = {
				active: true,
			};
		}

		console.log('account', account);
	},
	view: () => {
		return m('.account', [
			m(
				'.flexer',
				m(sjefButton, {
					iconLeft: sjefIconArrowLeft,
					buttonLayout: 'previous',
					label: 'Back to accounts',
					onclick: () => m.route.set('/crm/accounts'),
				}),
				m(sjefBreadcrumbs, {
					labelParams: {
						':id': breadcrumbtitle,
					},
				})
			),
			m(
				Form,
				{
					onsubmit: () => {
						return false;
					},
				},
				[
					m(
						'.flexer',
						m('h3', title),
						m(FormGroup, {
							span: 12,
							class: 'formButtons',
							content: [
								!isNew
									? m(sjefButton, {
										label: 'Delete',
										buttonLayout: 'danger',
										onclick: async () => {
											const response = await app.delete(`/masterdata/accounts`, {
												id: account.id,
											});

											if (response.result === 'success') {
												app.toast('Success', {
													intent: 'positive',
												});
												return m.route.set('/crm/accounts');
											}

											app.toast(response.message || 'failed', {
												intent: 'negative',
											});
										},
									})
									: '',
								m(sjefButton, {
									label: 'Cancel',
									buttonLayout: 'danger',
									onclick: async () => {
										m.route.set('/crm/accounts');
									},
								}),
								m(sjefButton, {
									label: 'Save',
									buttonLayout: 'ghost',
									onclick: async () => {
										const postBody = {
											name: account.name,
											email: account.email,
											active: account.active,
											invoice_name: account.invoice_name,
											invoice_streetname: account.invoice_streetname,
											invoice_housenumber: account.invoice_housenumber,
											invoice_zipcode: account.invoice_zipcode,
											invoice_city: account.invoice_city,
											invoice_country: account.invoice_country,
											// paymentprofile_id: account.paymentprofile_id,
											phone_number_country_code: account.phone_number_country_code,
											phone_number: account.phone_number,
											shipping_name: account.shipping_name,
											shipping_streetname: account.shipping_streetname,
											shipping_housenumber: account.shipping_housenumber,
											shipping_zipcode: account.shipping_zipcode,
											shipping_city: account.shipping_city,
											shipping_country: account.shipping_country,
										};

										let response;
										if (account.id) {
											postBody.id = account.id;
											response = await app.post('/masterdata/accounts/update', postBody);
										} else {
											response = await app.post('/masterdata/accounts', postBody);
										}

										if (response.result === 'success') {
											app.toast('Success', {
												intent: 'positive',
											});

											return m.route.set('/crm/accounts/' + response.data.id, { id: response.data.id });
										}

										app.toast(response.message || 'failed', {
											intent: 'negative',
										});
									},
								}),
								m(sjefButton, {
									label: 'Save & Close',
									buttonLayout: 'filled',
									onclick: async () => {
										const postBody = {
											name: account.name,
											email: account.email,
											active: account.active,
											invoice_name: account.invoice_name,
											invoice_streetname: account.invoice_streetname,
											invoice_housenumber: account.invoice_housenumber,
											invoice_zipcode: account.invoice_zipcode,
											invoice_city: account.invoice_city,
											invoice_country: account.invoice_country,
											// paymentprofile_id: account.paymentprofile_id,
											phone_number_country_code: account.phone_number_country_code,
											phone_number: account.phone_number,
											shipping_name: account.shipping_name,
											shipping_streetname: account.shipping_streetname,
											shipping_housenumber: account.shipping_housenumber,
											shipping_zipcode: account.shipping_zipcode,
											shipping_city: account.shipping_city,
											shipping_country: account.shipping_country,
										};

										let response;
										if (account.id) {
											postBody.id = account.id;
											response = await app.post('/masterdata/accounts/update', postBody);
										} else {
											response = await app.post('/masterdata/accounts', postBody);
										}

										if (response.result === 'success') {
											app.toast('Success', {
												intent: 'positive',
											});
											return m.route.set('/crm/accounts');
										}

										app.toast(response.message || 'failed', {
											intent: 'negative',
										});
									},
								}),
							],
						})
					),
					modalOpen ? m('', 'hallo') : void 0,
					actionCallout
						? m(sjefCallout, {
							type: 'danger',
							title: 'Actie vereist',
							content:
								'Aenean ut eros et nisl sagittis vestibulum. Nullam nulla eros, ultricies sit amet, nonummy id, imperdiet feugiat, pede. Sed lectus. Donec mollis hendrerit risus. Phasellus nec sem in justo pellentesque facilisis. Etiam imperdiet imperdiet orci. Nunc nec neque. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi.',
							subcontent: [
								m(sjefModal, {
									buttonLayout: 'filled',
									buttonLabel: 'Hier aanmaken',
									modalSize: 'lg',
									modalTitle: 'Ja mensen',
									modalContent: 'Een zeker formulier',
								}),
								m(sjefButton, {
									label: 'Naar accounts',
									buttonLayout: 'ghost',
									onclick: async () => {
										m.route.set('/crm/accounts');
									},
								}),
							],
							onClose: () => {
								actionCallout = false;
							},
						})
						: void 0,
					attentionCallout
						? m(sjefCallout, {
							type: 'info',
							title: 'Attention',
							content:
								'Cras ultricies mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia.',
							onClose: () => {
								attentionCallout = false;
							},
						})
						: void 0,
					m(Card, [
						m(Tabs, [
							tabs.map((tab, idx) =>
								m(TabItem, {
									label: [tab.label],
									active: activeTab.slug === tab.slug,
									// loading: page === 'Projects' && this.isLoading,
									onclick: () => (activeTab = tabs[idx]),
								})
							),
						]),
						activeTab.slug == 'personal'
							? m(
								'',
								m('h3', activeTab.label),
								m(FormGroup, {
									span: 6,
									content: [
										m(FormLabel, { for: 'name' }, 'Name'),
										m(Input, {
											id: 'name',
											value: account.name,
											onchange: (ev) => {
												account.name = ev.currentTarget.value;
											},
										}),
									],
								}),
								m(FormGroup, {
									span: 6,
									content: [
										m(FormLabel, { for: 'email' }, 'Email'),
										m(Input, {
											id: 'email',
											value: account.email,
											onchange: (ev) => {
												account.email = ev.currentTarget.value;
											},
										}),
									],
								}),
								m(FormGroup, {
									span: 6,
									content: [
										m(FormLabel, { for: 'paymentprofile_id' }, 'Paymentprofile Id'),
										m(Input, {
											id: 'paymentprofile_id',
											value: account.paymentprofile_id,
											onchange: (ev) => {
												account.paymentprofile_id = ev.currentTarget.value;
											},
										}),
									],
								}),
								m(FormGroup, {
									span: 6,
									content: [
										m(
											FormLabel,
											{
												for: 'phone_number_country_code',
											},
											'Phone number country code'
										),
										m(Input, {
											id: 'phone_number_country_code',
											value: account.phone_number_country_code,
											onchange: (ev) => {
												account.phone_number_country_code = ev.currentTarget.value;
											},
										}),
									],
								}),
								m(FormGroup, {
									span: 6,
									content: [
										m(FormLabel, { for: 'phone_number' }, 'Phone number'),
										m(Input, {
											id: 'phone_number',
											value: account.phone_number,
											onchange: (ev) => {
												account.phone_number = ev.currentTarget.value;
											},
										}),
									],
								}),

								m(FormGroup, {
									span: 6,
									content: [
										m(Checkbox, {
											id: 'active',
											checked: account.active,
											onchange: (ev) => {
												account.active = !account.active;
											},
										}),
										m(FormLabel, { for: 'active' }, 'Active'),
									],
								})
							)
							: activeTab.slug == 'invoice'
								? m(
									'',
									m('h3', activeTab.label),
									m(FormGroup, {
										span: 6,
										content: [
											m(FormLabel, { for: 'invoice_name' }, 'Invoice name'),
											m(Input, {
												id: 'invoice_name',
												value: account.invoice_name,
												onchange: (ev) => {
													account.invoice_name = ev.currentTarget.value;
												},
											}),
										],
									}),
									m(FormGroup, {
										span: 6,
										content: [
											m(FormLabel, { for: 'invoice_streetname' }, 'Invoice Streetname'),
											m(Input, {
												id: 'invoice_streetname',
												value: account.invoice_streetname,
												onchange: (ev) => {
													account.invoice_streetname = ev.currentTarget.value;
												},
											}),
										],
									}),
									m(FormGroup, {
										span: 6,
										content: [
											m(FormLabel, { for: 'invoice_housenumber' }, 'Invoice housenumber'),
											m(Input, {
												id: 'invoice_housenumber',
												value: account.invoice_housenumber,
												onchange: (ev) => {
													account.invoice_housenumber = ev.currentTarget.value;
												},
											}),
										],
									}),
									m(FormGroup, {
										span: 6,
										content: [
											m(FormLabel, { for: 'invoice_zipcode' }, 'Invoice zipcode'),
											m(Input, {
												id: 'invoice_zipcode',
												value: account.invoice_zipcode,
												onchange: (ev) => {
													account.invoice_zipcode = ev.currentTarget.value;
												},
											}),
										],
									}),
									m(FormGroup, {
										span: 6,
										content: [
											m(FormLabel, { for: 'invoice_city' }, 'Invoice city'),
											m(Input, {
												id: 'invoice_city',
												value: account.invoice_city,
												onchange: (ev) => {
													account.invoice_city = ev.currentTarget.value;
												},
											}),
										],
									}),
									m(FormGroup, {
										span: 6,
										content: [
											m(FormLabel, { for: 'invoice_country' }, 'Invoice country'),
											m(Input, {
												id: 'invoice_country',
												value: account.invoice_country,
												onchange: (ev) => {
													account.invoice_country = ev.currentTarget.value;
												},
											}),
										],
									})
								)
								: activeTab.slug == 'shipping'
									? m(
										'',
										m('h3', activeTab.label),
										m(FormGroup, {
											span: 6,
											content: [
												m(FormLabel, { for: 'shipping_name' }, 'Shipping name'),
												m(Input, {
													id: 'shipping_name',
													value: account.shipping_name,
													onchange: (ev) => {
														account.shipping_name = ev.currentTarget.value;
													},
												}),
											],
										}),
										m(FormGroup, {
											span: 6,
											content: [
												m(FormLabel, { for: 'shipping_streetname' }, 'Shipping Streetname'),
												m(Input, {
													id: 'shipping_streetname',
													value: account.shipping_streetname,
													onchange: (ev) => {
														account.shipping_streetname = ev.currentTarget.value;
													},
												}),
											],
										}),
										m(FormGroup, {
											span: 6,
											content: [
												m(FormLabel, { for: 'shipping_housenumber' }, 'Shipping housenumber'),
												m(Input, {
													id: 'shipping_housenumber',
													value: account.shipping_housenumber,
													onchange: (ev) => {
														account.shipping_housenumber = ev.currentTarget.value;
													},
												}),
											],
										}),
										m(FormGroup, {
											span: 6,
											content: [
												m(FormLabel, { for: 'shipping_zipcode' }, 'Shipping zipcode'),
												m(Input, {
													id: 'shipping_zipcode',
													value: account.shipping_zipcode,
													onchange: (ev) => {
														account.shipping_zipcode = ev.currentTarget.value;
													},
												}),
											],
										}),
										m(FormGroup, {
											span: 6,
											content: [
												m(FormLabel, { for: 'shipping_city' }, 'Shipping city'),
												m(Input, {
													id: 'shipping_city',
													value: account.shipping_city,
													onchange: (ev) => {
														account.shipping_city = ev.currentTarget.value;
													},
												}),
											],
										}),
										m(FormGroup, {
											span: 6,
											content: [
												m(FormLabel, { for: 'shipping_country' }, 'Shipping country'),
												m(Input, {
													id: 'shipping_country',
													value: account.shipping_country,
													onchange: (ev) => {
														account.shipping_country = ev.currentTarget.value;
													},
												}),
											],
										})
									)
									: void 0,
					]),
				]
			),
		]);
	},
};

export default vwAccountForm;
