/**
 * @fileOverview This module provides a form to create a store in Adyen Balance Platform.
 * @module vwOnboardAdyenModal
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import app from '../../../app';
import sjefModal from '../../../views/components/sjefModal';
import sjefIconChevronRight from '@sjefapp/sjeficons/icons/sjefIconChevronRight';
import sjefButton from '../../../views/components/sjefButton';
import { _t } from '../../../lib/i18n';


/**
 * Object defining the view component of the form, including initialization and rendering logic.
 */
class vwOnboardAdyenModal {
	constructor(props) {
		this.state = {
			adyenUrl: '',
			modalOpen: true,
		};
		this.onSubmit = this.onSubmit.bind(this);
	}
	oninit({ attrs }) {
		this.title = attrs.title;
		this.style = attrs.style;
		this.isSubmitting = false;
	};

	async onSubmit(field = false) {
		this.isSubmitting = true;
		const onboardingResponse = await app.post(`/balanceplatform/me/onboarding`);

		if (onboardingResponse.success) {
			const url = onboardingResponse.data.url;
			if (url) {
				this.state = { adyenUrl: url };
			}
			this.isSubmitting = false;
		};
	}

	onDeleteKey = async (key) => {
		return result;
	};

	/**
	 * Resets the `data` variable to an empty object.
	 * @function onOpen
	 */
	onOpen = async () => {

	};

	redirectToAdyen = () => {
		return m(
			'',
			[
				m('h3', _t('modules.balanceoverview.redirect_modal.description')),
			],
		)
	}

	continueToAdyen = () => {
		return m(
			'',
			[
				m('h3', _t('modules.balanceoverview.continue_modal.description')),
				m(sjefButton, {
					label: _t('modules.balanceoverview.continue_modal.button'),
					buttonLayout: 'filled wide',
					onclick: () => {
						this.state.modalOpen = false;
						window.open(this.state.adyenUrl, '_blank');
					},
				}),
			],
		)
	}

	/**
	 * Render the component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	view() {
		if (this.state.modalOpen === false) {
			return null;
		}

		return m(sjefModal, {
			onOpen: this.onOpen,
			onSubmit: this.onSubmit,
			modalTitle: `Boarding`,
			buttonLabel: 'Board',
			buttonLayout: 'filled wide',
			buttonConfirm: "Board",
			disabled: this.isSubmitting,
			buttonIconRight: sjefIconChevronRight,
			footer: this.state.adyenUrl.length === 0,
			modalSize: 'md',
			modalContent: this.state.adyenUrl.length === 0 ? this.redirectToAdyen() : this.continueToAdyen(),
			style: this.style,
		});
	}
}

export default vwOnboardAdyenModal;
