import { Card } from 'construct-ui';
import m from 'mithril';
import app from '../../../app';
import sjefTag from '../../../views/components/sjefTag';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwProfilePercentileRanking {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	oninit (vnode) {
		this.percentileRanking = '8.4%';
		this.percentileRankingSinceLastEvent = '-0.8%';
	}

	view(vnode) {
		return m('.profileComponent',
			m('h3', 'Percentile Rank on spending'),
			//beetje info op verschillende gebieden, klein tabelletje

			m('.digitLarge', 
				m('span', this.percentileRanking),
			),
			m(sjefTag, {
				content: this.percentileRankingSinceLastEvent,
				description: 'since last event',
			}),
		);
	}
}

export default vwProfilePercentileRanking;
