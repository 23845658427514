import { Drawer, MenuDivider, MenuItem, PopoverMenu } from 'construct-ui';
import m from 'mithril';
import { _t, languageResource, currentLanguage, changeLang } from '../../lib/i18n';
import app from '../../app';
import sjefIconMenu from '@sjefapp/sjeficons/icons/sjefIconMenu';
import sjefIconSearch from '@sjefapp/sjeficons/icons/sjefIconSearch';
import sjefInput from './sjefInput';
import sjefIconCloud from '@sjefapp/sjeficons/icons/sjefIconCloud';
import sjefIconHomeCog from '@sjefapp/sjeficons/icons/sjefIconHomeCog';
import sjefIconCloudRain from '@sjefapp/sjeficons/icons/sjefIconCloudRain';
import sjefIconArrowBigUpLine from '@sjefapp/sjeficons/icons/sjefIconArrowBigUpLine';
import sjefIconSun from '@sjefapp/sjeficons/icons/sjefIconSun';
import sjefIconMoon from '@sjefapp/sjeficons/icons/sjefIconMoon';
import sjefIconMenu2 from '@sjefapp/sjeficons/icons/sjefIconMenu2';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefIconEye from '@sjefapp/sjeficons/icons/sjefIconEye';
import sjefIconEyeOff from '@sjefapp/sjeficons/icons/sjefIconEyeOff';
import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';
import sjefIconChevronDown from '@sjefapp/sjeficons/icons/sjefIconChevronDown';
import sjefIconChevronRight from '@sjefapp/sjeficons/icons/sjefIconChevronRight';
import sjefButton from './sjefButton';
import tableStore from '../../lib/tableStore';
import sjefModal from './sjefModal';
import sjefBreadcrumbs from './sjefBreadcrumbs';

const avatar = new URL('../../assets/img/avatars/test_avatar.svg', import.meta.url);
let languageDrawerOpen = false;

const toggleMenu = () => {
	document.getElementById('layout').classList.toggle('menu-minimized');
	document.getElementById('layout').classList.add('was-once-minimized');
};

let activeLanguage = app.restore('language') || 'en';
let mode = app.restore('mode');

const languages = Object.keys(languageResource);
// const logoSrc = 'https://sjef.app/wp-content/uploads/2023/05/640847233d9e52189f01b772_sjef-Logo.png';
const logoSrc = new URL('../../assets/img/sjef_logo.png', import.meta.url).href;

const setActiveLanguage = async (lan) => {
	changeLang(lan);
	activeLanguage = lan;
};

class topMenu {
	userClients = [];

	async oninit() {
		// let result = await app.get('http://127.0.0.1:3007/me');
		// console.log(result);
		// if (result.success === true) {
		// 	this.userClients = result.data;
		// }
	}

	oncreate() {
		const scrollMenuItems = document.querySelectorAll('.scrollMenuItem');

		scrollMenuItems.forEach(function (elem, idx) {
			elem.classList.add('item-' + (idx + 1));
		});
	}

	changeMode(newMode, vnode) {
		mode = newMode;
		app.store('mode', newMode);

		if (typeof vnode.attrs.onModeChange === 'function') {
			vnode.attrs.onModeChange(newMode);
		}
	}

	toggleMobileMenu() {
		document.body.classList.toggle('mobile-menu-open');
	}

	toggleScrollMenuOpen() {
		const state = document.querySelector('.topMenu').classList.contains('js-scroll-inactive');
		if (state === true) {
			document.querySelector('.topMenu').classList.remove('js-scroll-inactive');
			document.querySelector('.topMenu').classList.add('js-scroll-active');
		} else {
			document.querySelector('.topMenu').classList.remove('js-scroll-active');
			document.querySelector('.topMenu').classList.add('js-scroll-inactive');
		}
	}

	view(vnode) {
		// const changeMode = (newMode) => {
		// 	mode = newMode;
		// 	app.store('mode', newMode);

		// 	if (typeof vnode.attrs.onModeChange === 'function') {
		// 		vnode.attrs.onModeChange(newMode);
		// 	}
		// };

		// const toggleMobileMenu = () => {
		// 	document.body.classList.toggle('mobile-menu-open');
		// };
		return m(
			'.topMenu',
			{
				class: 'js-scroll-inactive',
			},
			[
				m(
					'',
					m(
						'.menuFolder',
						{
							onclick: () => {
								toggleMenu();
								if (typeof vnode.attrs.onMenuMinimized === 'function') {
									vnode.attrs.onMenuMinimized(); // Call the callback passed from the parent
								}
							},
						},
						[m(''), m(''), m('')]
					),
					m(sjefBreadcrumbs, {

					}),
					// m(sjefInput, {
					// 	class: 'masterSearch',
					// 	placeholder: 'Atomic search',
					// 	icon: m(sjefIconSearch),
					// 	name: 'atomicsearch',
					// 	type: 'text',
					// }),
					m(
						'.sjefLogoWrapper',
						m('img.sjefLogo', {
							src: logoSrc,
						})
					)
				),
				m(
					'.scrolledMenu',
					{
						onclick: () => {
							this.toggleScrollMenuOpen();
						},
					},
					[m('.trigger', m(''), m(''), m(''))],
					m('.circularBackground')
				),
				m(
					'.topMenuRight',
					{
						class: 'items-' + document.querySelectorAll('.scrollMenuItem').length,
					},
					[
						m('.closeScrollMenu', {
							onclick: () => {
								this.toggleScrollMenuOpen();
							},
						}),
						app.user.lvl > app.lvl
							? 
							[ 
								m('.scrollMenuItem',
								m(sjefModal, {
									buttonLayout: 'iconHover background',
									buttonLabel: m(sjefIconArrowBigUpLine),
									modalTitle: _t('_.labels.switch.confirmTitle'),
									modalContent: _t('_.labels.switch.confirmContent', { name: app.restore('currentUsername') }),
									buttonConfirm: _t('_.buttons.switch'),
									onSubmit: () => {
										app.switchAccessLevelParent();	
									}
								}),
							)
								// m(sjefIconArrowBigUpLine, {
								// 	class: 'topMenuAction ',
								// 	onclick: () => {
								// 		app.switchAccessLevelParent();
								// 	},
							  	// })
							]
							: '',

						// m(PopoverMenu, {
						// 	class: 'languages sjefDropdown',
						// 	closeOnContentClick: true,
						// 	hasArrow: false,
						// 	menuAttrs: { size: 'lg' },
						// 	trigger: m('.topMenuAction scrollMenuItem', m('.languageMenuTrigger sjefDropdown ' + activeLanguage)),
						// 	content: languages.map((lan) => {
						// 		return m(MenuItem, {
						// 			'data-lan': lan,
						// 			onclick: () => setActiveLanguage(lan),
						// 		});
						// 	}),
						// }),

						m(
							'.topMenuAction scrollMenuItem', {
								onclick: () => {
									languageDrawerOpen = !languageDrawerOpen;
								},
							},
							m('.languageMenuTrigger sjefDropdown ' + activeLanguage)
						),

						// m(mode === 'day' ? sjefIconMoon : sjefIconSun, {
						// 	class: 'topMenuAction scrollMenuItem',
						// 	onclick: () => {
						// 		this.changeMode(mode === 'day' ? 'night' : 'day', vnode);
						// 	},
						// }),

						// m(sjefIconHomeCog, {
						// 	class: 'topMenuAction scrollMenuItem',
						// }),
						m(
							'.topMenuAction.blurToggle.js-blurToggle scrollMenuItem',
							{
								onclick: (ev) => {
									ev.stopPropagation();
									// document.body.classList.toggle('userIdle')
									app.setIdle();
								},
							},
							m(sjefIconEye, { class: 'iconIdle' }),
							m(sjefIconEyeOff, { class: 'iconNotIdle' })
						),
						m(
							'.mobileMenuToggle',
							{
								class: 'mobileTopMenuAction',
								onclick: () => {
									this.toggleMobileMenu();
								},
							},
							[
								m(sjefIconMenu2, {
									class: 'open',
								}),
								m(sjefIconX, {
									class: 'close',
								}),
							]
						),
						// m(
						// 	Col,
						// 	m('.username', [§
						// 		`${
						// 			app.user?.sub || 'anonymous'
						// 		} [${app.user?.roles?.join('/')}]`,
						// 		m(Button, {
						// 			label: 'logout',
						// 			size: 'xs',
						// 			basic: true,
						// 			onclick: () => {
						// 				app.logout();
						// 			},
						// 		}),

						// 		// m(Tag, {
						// 		// 	label: `v${app.version}`,
						// 		// 	rounded: true,
						// 		// 	size: 'xs',
						// 		// }),
						// 	])
						// ),
						m(PopoverMenu, {
							class: 'menuPopover sjefDropdown accountDropDown',
							closeOnContentClick: false,
							hasArrow: false,
							position: 'bottom-end',
							content: [
								m('.spacer',
									m('img', {
										src: avatar
									}),
								),
								m('span.sub', app.user.sub),
								m('.spacer',
									// m(MenuItem, {
									// 	label: 'Manage account',
									// 	onclick: () => {
									// 		console.log('manage account');
									// 	}
									// })
								),
								// m(MenuDivider),
								// m('.spacer.betweenButtons',
								// 	m(MenuItem, {
								// 		label: 'Test',
								// 	}),

								// 	m(MenuItem, {
								// 		label: 'Contact',
								// 	}),

								// 	m(MenuItem, {
								// 		label: 'Account',
								// 	})
								// ),

								// m(MenuDivider),
								...this.userClients.map((client) => {
									return m(MenuItem, {
										label: client.name,
										onclick: () => {
											alert('Should switch to client ' + client.name);

											// TODO: changes in to switch client
											// - change cid to the new cid in microservice
											// - move to modal and list all clients not in this menu, so a switch client modal
										},
									});
								}),

								m('.spacer',
									m(sjefModal, {
										buttonLabel: _t('_.fields.logout'),
										modalTitle: _t('_.labels.logout.confirmTitle'),
										modalContent: _t('_.labels.logout.confirmContent'),
										buttonConfirm: _t('_.fields.logout'),
										onSubmit: () => {
											app.logout();
										}
									}),
									// m(MenuItem, {
									// 	label: 'Logout',
									// 	intent: 'negative',
									// 	onclick: () => {
									// 		app.logout();
									// 	},
									// }),
								)
							],
							menuAttrs: { size: 'md' },
							// trigger: m('.userMenuTrigger.sjefDropdown', {}, app.user?.sub?.length > 0 ? app.user.sub[0] : ''),
							trigger: m(
								'.userMenuTrigger.sjefDropdown',
								{},
								m('img', {
									src: avatar,
								})
							),
						}),
					]
				),
			],
			m(Drawer, {
				closeOnEscapeKey: true,
				closeOnOutsideClick: true,
				class: 'languageDrawer sjefDrawer',
				content: m(
					'.languageDrawerInner',
					m('.flexer.center',
						m('h2', 'Select language'),
						m('.iconHover', {
							onclick: () => {
								languageDrawerOpen = false;
							}
						}, m(sjefIconChevronRight))
					),
					m('.selectLanguage',
					languages.map((lan) => {
						return [
							m(
								'.flagWrapper', {
									class: activeLanguage == lan ? 'active' : '',
									onclick: () => {
										setActiveLanguage(lan);
									},
								},
								m('.flag', {
									'data-lan': lan,
									
								}),
								m('.border-element', [
									m(sjefIconCheck)
								]),
							
							),
						];
					})
					)
				),
				hasBackdrop: true,
				position: 'right',
				isOpen: languageDrawerOpen,
				onClose: () => {
					languageDrawerOpen = false;
					m.redraw();
				},
			})
		);
	}
}
export default topMenu;
