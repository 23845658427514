import m from 'mithril';

import { _t } from '../../../lib/i18n';

import { Table } from 'construct-ui';

import vwBaseRecords from '../../../classes/vwBaseRecords';
import { downloadBase64File } from '../../../lib/helpers';
import sjefIconDownload from '@sjefapp/sjeficons/icons/sjefIconDownload';
import sjefButton from '../../../views/components/sjefButton';
import SjefCardWithHeader from '../../../views/components/sjefCardWithHeader';

class vwClientWeFactPricequotes extends vwBaseRecords {
	endpoint = `/wefact/pricequotes/list`;
	isNotAvailable;

	oninit({ attrs }) {
		this.isNotAvailable = !attrs.record?.wefactId
		super.oninit({ attrs })
	}

	/**
	 * Fetches the records and updates the records and metadata.
	 */
	async getRecords() {
		const result = await app.post(this.endpoint, {
			clientId: this.attrs.record.id,
		});

		if (result.success) {
			this.records = result.data;
		}
	}

	async downloadPricequote(pricequoteId) {
		const result = await app.post(`/wefact/pricequotes/download`, {
			clientId: this.attrs.record.id,
			pricequoteId: pricequoteId,
		});

		if (result.success) {
			downloadBase64File(result.data.base64, result.data.filename);
		}
	}

	view() {
		if (this.isNotAvailable) {
			return m('.alert.alert-warning', _t('_.messages.notAvailable'));
		}

		const priceQuoteRow = (record) => {
			return m('tr', [
				m('td', record.date),
				m('td', record.code),
				m('td', record.status),
				m('td', record.amountExcl),
				m('td', record.amountIncl),
				m('td', [
					m(
						sjefButton,
						{
							label: m(sjefIconDownload),
							buttonLayout: 'iconHover',
							onclick: async () => {
								await this.downloadPricequote(record.id);
							},
						},

					),
				]),
			]);
		};

		return m(
			'.pricequotes',
			[
				SjefCardWithHeader({
					title: _t('clients.pricequotes.title'),
					subTitle: _t('clients.pricequotes.subtitle'),
					headerPaddingTop: '2.5rem',
					headerHeight: '100px',
					headerPaddingSide: '2rem',
					bodyPaddingSide: '1rem',
					bodyPaddingTop: 0,
					children: m(Table, {
					}, [
						m('tr', [
							m('th', _t('clients.invoices.table.th.date', { defaultValue: 'Date' })),
							m('th', _t('clients.invoices.table.th.code', { defaultValue: 'Code' })),
							m('th', _t('clients.invoices.table.th.status', { defaultValue: 'Status' })),
							m('th', _t('clients.invoices.table.th.amountExcl', { defaultValue: 'Amount Exc.' })),
							m('th', _t('clients.invoices.table.th.amountIncl', { defaultValue: 'Amount Inc.' })),
							m('th', ''),
						]),
						this.records?.map((record) => {
							return priceQuoteRow(record)
						}),
					])
				}),
			],

		);

	}
}

export default vwClientWeFactPricequotes;
