import m from 'mithril';
import { Button, Icons, ListItem, SelectList } from 'construct-ui';
import Super from '../Super/components/Super';
import app from '../../app';

let clientList = [];
const selectedItems = new Map();

export const clientSelect = {
	oninit: async ({ attrs: { user } }) => {
		const clientResponse = await app.get("/clients/clients");
		clientList = clientResponse.data

		if (user.cids) {
			user.cids.map(cid => {
				const client = clientList.find(c => c.id === cid);
				if (client) selectedItems.set(cid, client);
			})
		}
	},
	view: () => {
		return m(SelectList, {
			id: 'cids',
			closeOnSelect: false,
			items: clientList,
			itemRender: itemRender,
			itemPredicate: itemPredicate,
			onSelect: onSelect,
			popoverAttrs: { hasArrow: false },
			trigger: m(Button, {
				compact: true,
				class: 'clientSelect',
				iconLeft: Icons.CHEVRON_DOWN,
				sublabel: 'Clients: ',
				label: `${selectedItems.size} selected`
			}),
		});
	},
};

const itemRender = (item) => {
	return m(ListItem, {
		label: item.name,
		selected: selectedItems.has(item.id),
	});
}

const itemPredicate = (query, item) => {
	return item.name.toLowerCase().includes(query.toLowerCase());
};

const onSelect = (item) => {
	if (selectedItems.has(item.id)) {
		selectedItems.delete(item.id);
	} else selectedItems.set(item.id, item);
};
