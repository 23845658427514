import m from 'mithril';
import { Button, Table } from 'construct-ui';
import Super from './components/Super';
import app from '../../app';
import sjefTable from '../../views/components/sjefTable';
import sjefButton from '../../views/components/sjefButton';

const vwUsers = {
	menu: { id: 'users', label: 'Users' },
	secure: { obj: 'users', act: 'read' },
	users: [],

	oninit: async () => {
		const response = await Super.get('idents', { identType: 'user' });
		vwUsers.users = response.data.idents
	},

	view: () => {
		return m(
			'',
			m('.pageHeader',
				m('h3.title', 'Users'),
				m('.actions',
					m(sjefButton, {
						label: 'New',
						buttonLayout: 'filled',
						onclick: () => {
							// app.goto('user', { id: 0 });
							m.route.set('/admin/users/0', { user: { id: 0 } });
						},
					})
				),
			),
			vwUsers.users.length &&
			m(sjefTable, {
				content: [
					m('tr', [m('th', 'username'), m('th', 'roles')]),
					vwUsers.users.map((user) => {
						return m('tr', [
							m(
								'td',
								m(
									'a',
									{
										onclick: () => {
											m.route.set('/admin/users/:id', { id: user.id }, { state: { user: user } });
										},
									},
									user.ident
								)
							),
							m('td', user.roles.length ? user.roles.join(',') : ' '),
						]);
					}),
				],
			})
		);
	},
};

export default vwUsers;
