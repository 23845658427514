// tableStore.js
import stream from 'mithril/stream';

const tableStore = {
	title: stream(null),
	selectedRecords: stream([]),
	disabledRecords: stream([]),
	records: stream([]),
	allRecordsSelected: stream(false),
	metadata: stream({
		offset: 0,
		limit: 25,
		total: 0,
	}),
	filterData: stream({}),
	sortData: stream(),
	isLoading: stream(false),
	activeSort: stream(null),
};

// todo uid id

export default tableStore;
