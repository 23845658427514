import m from 'mithril';
import { Button, Card, Form, FormGroup, FormLabel, Input } from 'construct-ui';
import { PermissionsSelector } from './components/PermissionsSelector';
import app from '../../app';
import sjefButton from '../../views/components/sjefButton';
import sjefIconArrowLeft from '@sjefapp/sjeficons/icons/sjefIconArrowLeft';
import sjefBreadcrumbs from '../../views/components/sjefBreadcrumbs';
import { _t } from '../../lib/i18n';

let isNew = false;
let role = {};
const vwRoleForm = {
	oninit: ({ attrs }) => {
		console.log('roleForm attrs', attrs);
		if (!attrs.role) {
			isNew = true;
			role = {
				role: '',
			};
		} else {
			isNew = false;
			role = attrs.role;
		}
	},
	view: () => {
		return m('.role', [
			m('.pageHeader', [
				m(sjefButton, {
					iconLeft: sjefIconArrowLeft,
					buttonLayout: 'previous',
					label: _t('_.buttons.backToList', { defaultValue: 'Go back' }),
					onclick: () => m.route.set('/admin/roles'),
				}),

				m('h3.title', `Role:  ${role.role}`),
				m(
					'.actions',
					m(sjefButton, {
						buttonLayout: 'filled',
						label: 'Save',
						onclick: async () => {
							const response = await app.post('/auth/acl/roles/create', {
								role: role.role,
							});

							if (response.success === false) {
								app.toast(response.error, {
									intent: 'negative',
								});

								return;
							}

							app.toast('Role saved', {
								intent: 'positive',
								timeout: 1000,
							});

							m.route.set('/admin/roles');
						},
					})
				),
				// m(sjefBreadcrumbs, {
				// 	labelParams: {
				// 		':id': this.titleBreadcrumb,
				// 	},
				// }),
			]),
			m(
				Card,
				isNew
					? [
							m('', 'New role'),
							m(Form, [
								m(FormGroup, [
									m(FormLabel, 'role'),
									m(Input, {
										value: role.role,
										readonly: !isNew,
										onchange: (ev) => {
											role.role = ev.currentTarget.value;
										},
									}),
								]),
							]),
					  ]
					: m(Form, [
							m(FormGroup, [
								m(FormLabel, 'Permissions'),
								m(PermissionsSelector, {
									role: role.role,
									cb: async ({ obj, act, checked }) => {
										try {
											const result = await app.post('/auth/acl/roles/setpermission', {
												role: role.role,
												obj,
												act,
												allow: checked,
											});
											if (result.success === false) {
												app.toast(result.error, {
													intent: 'negative',
												});
											} else {
												app.toast('Permission set', {
													intent: 'positive',
													timeout: 1000,
												});
											}
										} catch (err) {
											console.debug(err);
										}
									},
								}),
							]),
					  ])
			),
		]);
	},
};

export default vwRoleForm;
