import { Card } from 'construct-ui';
import m from 'mithril';
import eventsList from '../components/eventsList';
import sjefIconPlus from '@sjefapp/sjeficons/icons/sjefIconPlus';
import app from '../../app';

// const events = [
// 	{
// 		active: true,
// 		presaleDone: true,
// 		allocationsDone: true,
// 		name: 'Summer Festival',
// 		date: '2024-06-21',
// 		mode: '10-10',
// 	},
// 	{
// 		active: true,
// 		presaleDone: true,
// 		allocationsDone: false,
// 		name: 'Winter Gala',
// 		date: '2024-12-15',
// 		mode: '10-10',
// 	},
// 	{
// 		active: true,
// 		presaleDone: true,
// 		allocationsDone: false,
// 		name: 'Spring Workshop',
// 		date: '2024-03-27',
// 		mode: '12-12',
// 	},
// 	{
// 		active: true,
// 		presaleDone: false,
// 		allocationsDone: false,
// 		name: 'Autumn Concert',
// 		date: '2024-09-10',
// 		mode: '12-12',
// 	},
// ];

class vwLockerOverview {
	events = null;

	async oninit() {
		this.getEvents();
	}

	async getEvents() {
		let result = await app.get('/lockermanagement/events');

		console.log(result);

		if (result.success) {
			this.events = result.data;
		}

		m.redraw();
	}

	view() {
		return [
			m('.vwDetails.vwLockers.vwEventsOverview', [
				m(
					'.flexer',
					m('h1', 'Events'),
					m(
						'.iconHover.background',
						{
							onclick: () => {
								m.route.set('/elockers/manage');
							},
						},
						m(sjefIconPlus)
					)
				),
				this.events
					? m(eventsList, {
							events: this.events,
							onDelete: async () => {
								await this.getEvents();
							}
					  })
					: void 0,
			]),
		];
	}
}

export default vwLockerOverview;
