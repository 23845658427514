import m from 'mithril';
import ApexCharts from 'apexcharts'

const style = {
	minimal: {
		chart: {

			type: 'area',
			curve: 'smooth',
			toolbar: {
				autoSelected: "pan",
				show: false
			},
			height: 250,
			width: "100%",
			sparkline: {
				enabled: false,
			},

		},
		grid: {
			show: false,
			padding: {
				right: 0,
				left: 0
			},
		},
		yaxis: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		legend: {
			show: false
		},
	}
}

class sjefApexChart {
	oninit({ attrs }) {
		this.color = attrs.color;
		this.options = {
			...style[attrs.style],
			colors: [this.color],
			...attrs.options
		}
		this.data = attrs.data;
	}

	oncreate(vnode) {
		const chartElement = vnode.dom.querySelector("#chart");
		if (!this.chart && chartElement) {
			this.chart = new ApexCharts(chartElement, this.options);
			this.chart.render();
		}
	}

	view() {
		const chartStyle = {
			paddingLeft: 0,
			paddingRight: 0,
		}

		return m('', { style: chartStyle }, [
			m('#chart')])
	}
}

export default sjefApexChart;
