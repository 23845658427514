import m from 'mithril'
import { TabItem, Tabs } from 'construct-ui';
import { _t } from '../../../lib/i18n';


const sjefTabs = ({ listTabs, listTabIndexActive, moduleName, isListTabActive }) => {


	if (listTabs && listTabs.length > 0) {
		return m(
			'.subMenuTabs.list',
			m(
				Tabs,
				{
					class: 'list-' + listTabs.length + ' active-' + listTabIndexActive,
				},
				[
					listTabs.map((tab, idx) =>
						m(TabItem, {
							class: 'active-state-elem',
							label: [_t(`modules.${moduleName}.tabs.${tab.slug}`) || tab.slug],
							active: isListTabActive(tab),
							onclick: () => {
								// setListTabActive(idx);
							},
						})
					),
				]
			)
		);
	}
}

export default sjefTabs
