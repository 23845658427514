const featureFlags = {
	development: {
		administrations: true,
		bottomMenu: true,
		sideMenu: true,
		runningPayments: true,
		menutree: true,
		notSupportedLanguages: true,
		elockers: true,
	},
	production: {
		administrations: true,
		bottomMenu: false,
		sideMenu: false,
		runningPayments: true,
		menutree: false,
		notSupportedLanguages: false,
		elockers: false,
	},
};

export const administrationFlag = 'administrations';
export const bottomMenuFlag = 'bottomMenu';
export const sideMenuFlag = 'sideMenu';
export const runningPaymentsFlag = 'runningPayments';
export const menutreeFlag = 'menutree';
export const notSupportedLanguagesFlag = 'notSupportedLanguages';
export const elockersFlag = 'elockers';

const FeatureFlags = {
	isEnabled: (feature) => {
		if (featureFlags[process.env.BUILD_ENV][feature] === undefined) {
			return true;
		}
		return featureFlags[process.env.BUILD_ENV][feature];
	},
	flags: featureFlags,
};

export default FeatureFlags;
