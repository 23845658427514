import m from 'mithril';
import { _t } from '../../../lib/i18n';
import sjefButton from '../../../views/components/sjefButton';
import sjefModal from '../../../views/components/sjefModal';
import { Card, Form, FormGroup, FormLabel, Input } from 'construct-ui';
import dropDownCard from '../../../views/components/dropDownCard';
import app from '../../../app';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwPaymentsTab {
	projectId;
	endpoint = '/operations/projects';
	fields = [
		{
			name: 'adyenCompanyAccount',
			type: 'string',
			formInputType: 'text',
			maxLength: 255,
			required: true,
			value: '',
		},
		{
			name: 'adyenMerchantAccount',
			type: 'string',
			class: '',
			formInputType: 'text',
			maxLength: 255,
			minLength: 1,
			required: true,
			value: '',
		},
		{
			name: 'adyenPosStoreId',
			type: 'string',
			formInputType: 'text',
			maxLength: 255,
			minLength: 1,
			required: true,
			value: '',
		},
		{
			class: '',
			name: 'adyenPosStoreName',
			type: 'string',
			formInputType: 'text',
			maxLength: 255,
			minLength: 1,
			required: true,
		},
		{
			class: '',
			name: 'adyenEcomStoreId',
			type: 'string',
			formInputType: 'text',
			maxLength: 255,
			minLength: 1,
			required: true,
			value: '',
		},
		{
			class: '',
			name: 'adyenEcomStoreName',
			type: 'string',
			formInputType: 'text',
			formShow: true,
			maxLength: 255,
			minLength: 1,
			required: true,
			value: '',
		},
	];

	oninit = async () => {
		const getFields = await app.get(this.endpoint + '/' + this.projectId);

		if (getFields.success == true) {
			this.fields.forEach(function (field) {
				field.value = getFields.data[field.name];
			});
		}
	};

	saveAdyenInfo = async () => {
		let form = { id: this.projectId };

		this.fields.forEach(function (field) {
			if (field.value) {
				form[field.name] = field.value;
			}
		});

		const result = await app.patch(this.endpoint, form);
		console.log(result);
		if (result.success == true) {
			app.toast('Success', { intent: 'positive' });
			return;
		}

		app.toast(result.message || 'failed', { intent: 'negative' });
	};

	constructor({ attrs }) {
		this.projectId = attrs.record.id;
	}

	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	view() {
		return m('', [
			m(
				Card,
				{ class: 'flex-direction-column' },
				m('h2', 'Payments'),
				m(sjefModal, {
					buttonLabel: _t('_.buttons.activateAdyen', { defaultValue: 'Activate Adyen' }),
					buttonLayout: 'filled',
					closeOnOutsideClick: false,
					onSubmit: async () => await this.saveAdyenInfo(),
					modalTitle: _t('_.buttons.activateAdyen', { defaultValue: 'Activate Adyen' }),
					modalContent: m(
						Form,
						this.fields.map((field) => {
							return m(FormGroup, { class: field.class }, [
								m(FormLabel, { for: field.name }, field.name),
								m(Input, {
									type: field.formInputType,
									id: field.name,
									name: field.name,
									maxlength: field.maxLength,
									minlength: field.minLength,
									required: field.required,
									value: field.value,
									oninput: (event) => {
										field.value = event.target.value; // Update the variable when the input changes
									},
								}),
							]);
							return null; // Ignore fields that shouldn't be included
						})
					),
				})
			),
		]);
	}
}

export default vwPaymentsTab;
