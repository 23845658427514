import moment from 'moment-timezone';
import m from 'mithril';
import constants from './constants';
import app from '../app';
import tableStore from './tableStore';
import { _t } from './i18n';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefIconPlus from '@sjefapp/sjeficons/icons/sjefIconPlus';
import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';
import sjefIconSun from '@sjefapp/sjeficons/icons/sjefIconSun';


// DO NOT REMOVE
// _t('_.months.january')
// _t('_.months.february')
// _t('_.months.march')
// _t('_.months.april')
// _t('_.months.may')
// _t('_.months.june')
// _t('_.months.july')
// _t('_.months.august')
// _t('_.months.september')
// _t('_.months.october')
// _t('_.months.november')
// _t('_.months.december')

const downloadBase64File = (base64Data, filename, type = 'application/pdf') => {
	const binaryString = atob(base64Data);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	const blob = new Blob([bytes.buffer], { type: type });

	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = filename;
	link.click();
};

const truncate = (string, length = 50) => {
	if (!string) {
		return string;
	}

	if (string.length > length) {
		return string.substring(0, length) + '...';
	} else {
		return string;
	}
}

const writtenDate = (inputDate) => {
	if (!inputDate) {
		return inputDate;
	}

	// Check if inputDate is already a Date object
	let date;
	if (inputDate instanceof Date) {
		date = inputDate;
	} else {
		date = new Date(inputDate);
	}

	// Check if the date is valid
	if (isNaN(date.getTime())) {
		throw new Error("Invalid date");
	}

	const day = date.getDate();
	const monthNames = [
		"January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];

	const month = monthNames[date.getMonth()];
	const monthName = _t(`_.months.${month.toLowerCase()}`);

	const year = date.getFullYear();
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');

	return `${day} ${monthName} ${year}, ${hours}:${minutes}`;
}



const getBooleanIcons = (bool) => {
	if (bool === false) {
		return sjefIconX
	}

	return sjefIconCheck
}

// See whether an item (table) should be folded because there are too many records.
const isFolded = (array, children = false) => {
	if (!children) {
		return array.length > 25;
	}

	let childrenCount = 0;
	array.forEach(function (elem) {
		childrenCount += elem[children]?.length;
	});

	return array.length + childrenCount > 25;
};

const deepEqual = (obj1, obj2) => {
	return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const price = (value, currency = false) => {
	let symbol;
	if (!currency) {
		symbol = app.currencySymbol;
	} else if (currency == 'EUR') {
		symbol = '€';
	} else if (currency == 'USD') {
		symbol = '$';
	} else if (currency == 'GBP') {
		symbol = '£';
	}

	// Remove currency symbol if present
	const sanitizedValue = value.toString().replace(symbol, '');

	if (!isNaN(parseFloat(sanitizedValue))) {
		return symbol + ' ' + parseFloat(sanitizedValue).toFixed(2);
	}
};

const isNumeric = (value) => {
	return !isNaN(parseFloat(value)) && isFinite(value);
};

const generateFieldsFromRecords = (records) => {
	const allKeys = records.reduce((keysArray, obj) => {
		return keysArray.concat(Object.keys(obj));
	}, []);

	const uniqueKeys = [...new Set(allKeys)];

	return uniqueKeys;
};

const parseUTC = (value) => {
	return moment.utc(value, 'YYYY-MM-DDTHH:mm:ss').utcOffset(constants.TIMEZONE).format();
};

const formatUTC = (value) => {
	return moment.utc(value, 'YYYY-MM-DDTHH:mm:ss').tz(constants.TIMEZONE).format('YYYY-MM-DD');
};

const isContentFalsy = (content) => {
	// Check if every item in the array is falsy
	return content.every(item => {
		if (Array.isArray(item)) {
			// Recursively check for nested arrays
			return isContentFalsy(item);
		}
		return !item; // Check if the item is falsy
	});
}

const daysAgo = (date) => {
	if (!date) {
		return date;
	}

	const now = new Date();
	const inputDate = new Date(date);

	// Calculate the difference in milliseconds
	const differenceInMilliseconds = now - inputDate;

	// Convert the difference from milliseconds to days
	const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

	if (differenceInDays === 0) {
		return _t('_.fields.today');
	} else if (differenceInDays === 1) {
		return _t('_.fields.yesterday');
	} else {
		return `${differenceInDays}` + ' ' + _t('_.fields.daysAgo');
	}
}

// Example usage:
const dateInput = new Date('2024-05-23');  // Example date
console.log(daysAgo(dateInput));  // Output: e.g., "1 day ago"


// Function to handle navigation
const goToHref = (e, href, params = {}, state = {}) => {
	const isAbsolute = /^(https?:)?\/\//i.test(href);
	if (e.ctrlKey || e.metaKey) {
		// Open in new tab for absolute or modified relative URL
		window.open(href, '_blank');
	} else if (isAbsolute) {
		window.location.href = href;
	} else {
		// Using Mithril's routing system
		m.route.set(href, params, { state });
	}
}

const hasCommonElements = (array1, array2) => {
	if (array1.length === 0 || array2.length === 0) {
		return false;
	}

	return array1.some(item => array2.includes(item));
}

const sleep = (time = 3000) => {
	return new Promise(resolve => setTimeout(resolve, time));
}

const resetTableStore = (state = false) => {
	tableStore.selectedRecords([]);
	tableStore.allRecordsSelected(false);
	tableStore.metadata({
		offset: 0,
		limit: 25,
		total: 0,
	});
	tableStore.filterData({});
	tableStore.sortData();
	tableStore.records([]);

	if (state === true) {
		return;
	}

	tableStore.isLoading(false);
	tableStore.activeSort(null);
};

const awaitLoad = (state) => {
	return !state ? 'awaitLoad loading' : 'awaitLoad loaded';
};

const renderPaymentRow = (payment, format) => {
	return m('tr', [
		m('td', formatUTC(payment.timestamp, format)),
		m('td', payment.paymentmethod),
		m('td', payment.pspName),
		m('td', payment.pspMethod),
		m('td', payment.status),
		m('td', payment.amount),
	]);
};

const renderLineRow = (line, format) => {
	return m('tr', [
		m('td', line.barcode),
		m('td', line.name),
		m('td', line.quantity),
		m('td', line.coinAmount),
		m('td', line.discountAmount),
		m('td', line.subtotal),
		m('td', line.taxes),
		m('td', line.total),
	]);
};

const formatDate = (passedDate) => {
	const date = moment(passedDate);
	const formattedDate = date.format('DD-MM-YYYY');
	return formattedDate;
};

const getColors = () => {
	return ['PINK', 'BLACK', 'BLUE']
}

const calculateTimeDifference = (time1, time2) => {
	// Calculate the absolute difference between the two timestamps in milliseconds

	const timestamp1 = new Date(time1).getTime();
	const timestamp2 = new Date(time2).getTime();

	const timeDifferenceInMilliseconds = Math.abs(timestamp1 - timestamp2);

	// Convert the time difference to days and hours
	const days = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
	const hours = Math.floor((timeDifferenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	// Initialize an empty result object
	const result = {};

	// Conditionally add days and hours to the result object if they are not zero
	if (days > 0) {
		result.days = days;
	}
	if (hours > 0) {
		result.hours = hours;
	}

	return result;
};

const durationText = (event) => {
	const timeDifference = calculateTimeDifference(event.eventStart, event.eventEnd);
	let text = _t('_.fields.eventLasts') + ' ';
	if (timeDifference.days > 0) {
		text += timeDifference.days + ' ' + _t('_.fields.days');
	}
	if (timeDifference.hours > 0) {
		text += (timeDifference.days > 0 ? ' ' + _t('_.fields.and') : '') + timeDifference.hours + ' ' + _t('_.fields.hours');
	}
	return text;
}

export {
	hasCommonElements,
	parseUTC,
	formatDate,
	sleep,
	resetTableStore,
	calculateTimeDifference,
	formatUTC,
	isNumeric,
	durationText,
	isFolded,
	price,
	awaitLoad,
	writtenDate,
	getColors,
	goToHref,
	downloadBase64File,
	isContentFalsy,
	renderPaymentRow,
	getBooleanIcons,
	truncate,
	renderLineRow,
	daysAgo,
	deepEqual
};
