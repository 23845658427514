class EventBus {
    constructor() {
        this.events = {};
    }

    subscribe(eventName, handler) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(handler);
    }

    publish(eventName, data) {
        const handlers = this.events[eventName];
        if (handlers) {
            handlers.forEach(handler => {
                handler(data);
            });
        }
    }

    unsubscribe(eventName, handler) {
        const handlers = this.events[eventName];
        if (handlers) {
            this.events[eventName] = handlers.filter(h => h !== handler);
        }
    }
}

// Create a single instance of EventBus and export it for app-wide use
const eventBus = new EventBus();
export default eventBus;
