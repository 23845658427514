import { Button, Table } from 'construct-ui';
import m from 'mithril';
import Super from './components/Super';
import sjefTable from '../../views/components/sjefTable';
import sjefButton from '../../views/components/sjefButton';

let roles = [];

const vwRoles = {
	oninit: async () => {
		const response = await Super.get('roles');
		roles = response.data
	},
	view: () => {
		return m(
			'.roles',
			m(
				'.pageHeader',
				m('h3.title', 'Roles'),
				m(
					'.actions',
					m(sjefButton, {
						label: 'New',
						buttonLayout: 'filled',
						onclick: () => {
							// app.goto('user', { id: 0 });
							m.route.set('/admin/roles/:id', { id: 'new' }, { state: { role: false } });
						},
					})
				)
			),
			m(sjefTable, {
				content: [
					m('tr', [
						m('th', 'role'),
						m('th', 'users'),
						// m('th', 'permissions')
					]),
					roles &&
					roles.map((role) => {
						return m('tr', [
							m(
								'td',
								m(
									'a',
									{
										onclick: () => {
											// if (role.role !== 'super') {
											m.route.set('/admin/roles/:id', { id: role.role }, { state: { role: role } });
											// }
										},
									},
									role.role
								)
							),
							m('td', role.users.join(', ')),
							// m(
							// 	'td',
							// 	m.trust(
							// 		Object.keys(role.permissions)
							// 			.map((p) => {
							// 				return '<b>' + p + ':</b> ' + role.permissions[p].join(' / ');
							// 			})
							// 			.join('<br>')
							// 	)
							// ),
						]);
					}),
				],
			})
		);
	},
};

export default vwRoles;
