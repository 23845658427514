import m from 'mithril';
import { Input, Popover } from 'construct-ui';
import sjefIconSettings2 from '@sjefapp/sjeficons/icons/sjefIconSettings2';
import sjefIconMenu2 from '@sjefapp/sjeficons/icons/sjefIconMenu2';

class sjefMenuTree {
	menuTree;
	searchResults = [];

	constructor() {
		this.hoveredOrganisationIndex = null;
		this.hoveredClientIndex = null;
	}

	oninit(vnode) {
		this.menuTree = vnode.attrs.menuTree;
	}

	renderContent() {
		return m(
			'.menuTreeInner',
			m(
				'.search',
				m(Input, {
					type: 'search',
					placeholder: 'Zoek organisatie...',
					oninput: (e) => this.filterMenuTree(e.target.value),
				})
			),
			m('.menuTreeRows', [
				this.renderOrganisations(),
				this.hoveredOrganisationIndex !== null ? this.renderClients(this.menuTree.organisations[this.hoveredOrganisationIndex]) : null,
				this.hoveredOrganisationIndex !== null && this.hoveredClientIndex !== null
					? this.renderAdministrations(this.menuTree.organisations[this.hoveredOrganisationIndex].clients[this.hoveredClientIndex])
					: null,
			])
		);
	}

	renderOrganisations() {
		return m(
			'.menuTreeOrganisations',
			this.menuTree.organisations.map((org, index) =>
				m(
					'.organisation.treeItem',
					{
						class: this.hoveredOrganisationIndex === index ? 'active' : '',
						onmouseover: () => {
							this.hoveredOrganisationIndex = index;
							this.hoveredClientIndex = null; // Reset client index
						},
						onclick: () => {
							app.switchAccessLevel(8, 'organisation', { oid: org.id })
						}
					},
					org.name
				)
			)
		);
	}

	renderClients(organisation) {
		return m(
			'.menuTreeClients',
			organisation.clients.map((client, index) =>
				m(
					'.client.treeItem',
					{
						class: this.hoveredClientIndex === index ? 'active' : '',
						onmouseover: () => (this.hoveredClientIndex = index),
						onclick: () => {
							app.switchAccessLevel(7, 'client', { cid: client.id })
						}
					},
					client.name
				)
			)
		);
	}

	renderAdministrations(client) {
		if (client.administrations.length > 0) {
			return m(
				'.menuTreeAdministrations',
				client.administrations.map((admin) => m('.administration.treeItem', {
					onclick: () => {
						app.switchAccessLevel(6, 'administration', { cid: client.id, aid: admin.id })
					}
				}, admin.name))
			);
		}

		return void 0;
	}

	filterMenuTree(searchTerm) {
		if (!searchTerm) {
			this.searchResults = [];
			return;
		}

		const lowerSearchTerm = searchTerm.toLowerCase();
		this.searchResults = [];

		this.menuTree.organisations.forEach((org, orgIndex) => {
			if (org.name.toLowerCase().includes(lowerSearchTerm)) {
				this.searchResults.push({ type: 'org', name: org.name, index: orgIndex });
			}

			org.clients.forEach((client, clientIndex) => {
				if (client.name.toLowerCase().includes(lowerSearchTerm)) {
					this.searchResults.push({ type: 'client', name: client.name, index: clientIndex, parentIndex: orgIndex });
				}

				client.administrations.forEach((admin, adminIndex) => {
					if (admin.name.toLowerCase().includes(lowerSearchTerm)) {
						this.searchResults.push({
							type: 'admin',
							name: admin.name,
							index: adminIndex,
							parentIndex: orgIndex,
							clientIndex: clientIndex,
						});
					}
				});
			});
		});

		// Optional: React to search results, e.g., by updating the view or logging
		console.log(this.searchResults);
	}

	view() {
		return m(Popover, {
			closeOnEscapeKey: true,
			closeOnContentClick: false,
			hasArrow: false,
			inline: true,
			trigger: m(sjefIconMenu2, { class: 'menuTreeTrigger' }),
			content: this.renderContent(),
			position: 'bottom',
		});
	}
}

export default sjefMenuTree;
