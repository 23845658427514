/**
 * @fileOverview This module provides a form to create a store in Adyen Balance Platform.
 * @module vwClientAdyenStoresCreateModal
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import { Form, FormLabel, FormGroup, Input, Select } from 'construct-ui';
import validation from '@sjefapp/sjef-validation';

import sjefModal from '../../../views/components/sjefModal';
import constants from '../../../lib/constants';
import response from '../../../lib/response';
import app from '../../../app';
import sjefIconPlus from '@sjefapp/sjeficons/icons/sjefIconPlus';
import sjefInput from '../../../views/components/sjefInput';
import { _t } from '../../../lib/i18n';
import sjefIcon360View from '@sjefapp/sjeficons/icons/sjefIcon360View';
import sjefIconBarcode from '@sjefapp/sjeficons/icons/sjefIconBarcode';
import sjefIconLineDashed from '@sjefapp/sjeficons/icons/sjefIconLineDashed';
import sjefIconTextCaption from '@sjefapp/sjeficons/icons/sjefIconTextCaption';
import sjefIconSignature from '@sjefapp/sjeficons/icons/sjefIconSignature';
import sjefIconAddressBook from '@sjefapp/sjeficons/icons/sjefIconAddressBook';
import sjefIconBuildingSkyscraper from '@sjefapp/sjeficons/icons/sjefIconBuildingSkyscraper';
import sjefIconEPassport from '@sjefapp/sjeficons/icons/sjefIconEPassport';
import sjefIconPhone from '@sjefapp/sjeficons/icons/sjefIconPhone';

/**
 * Object defining the view component of the form, including initialization and rendering logic.
 */
class vwClientAdyenStoresCreateModal {
	/**
	 * Define validation schema for the form data.
	 */
	validationSchema = {
		type: 'object',
		properties: {
			merchantId: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
			},
			businesslineId: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
			},
			name: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
			},
			shopperStatement: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
				maxLength: 22,
			},
			address: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
				maxLength: 255,
			},
			address2: {
				type: 'string',
				maxLength: 255,
			},
			city: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
				maxLength: 255,
			},
			country: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
				maxLength: 2,
			},
			zipcode: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
				maxLength: 255,
			},
			phoneNumberCountryCode: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
				maxLength: 2,
			},
			phoneNumber: {
				type: 'string',
				minLength: 1,
				pattern: '\\S+',
				maxLength: 16,
			},
		},
		required: [
			'name',
			'merchantId',
			'businesslineId',
			'shopperStatement',
			'address',
			'city',
			'zipcode',
			'country',
			'phoneNumberCountryCode',
			'phoneNumber',
		],
	};
	validationErrors = {};

	/**
	 * @type {integer} clientId - The ID of the client.
	 */
	clientId;

	/**
	 * @type {Array} businessLines - An array to store business lines.
	 */
	businessLines = [];

	/**
	 * @type {Function} callbackOnSuccess - The callback function to be called when an operation completes successfully.
	 */
	callbackOnSuccess;

	/**
	 * @type {Object} data - An object that holds the data to be submitted. Structure depends on form requirements.
	 */
	data = {};

	/**
	 * @type {Object} data - An array taht holds the available merchants.
	 */
	merchants = [];

	/**
	 * Initialize the component with the provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {Object} vnode.attrs - The attributes passed to the component.
	 * @param {integer} vnode.attrs.clientId - The ID of the client.
	 * @param {Function} vnode.attrs.callbackOnSuccess - The function to call when the action completes with success.
	 */
	oninit = async ({ attrs }) => {
		this.clientId = attrs.clientId;
		this.storeType = attrs.storeType;
		this.businessLines = attrs.businessLines;
		this.callbackOnSuccess = attrs.callbackOnSuccess;

		this.merchantAccountId = attrs.merchantAccountId;

		const result = await app.get(`/balanceplatform/merchants`);

		if (result.success === false) {
			return response.failed('1111', result.error);
		}
	};

	/**
	 * Asynchronous function to handle form submission.
	 * Validates the form data according to the defined schema,
	 * makes a request to create a business line with the validated data,
	 * handles the response, and provides user feedback.
	 *
	 * @returns {object} a Promise resolving to a response object
	 */
	onSubmit = async () => {
		if (validation.validate(this.validationSchema, this.data).valid === false) {
			this.validationErrors = validation.validate(this.validationSchema, this.data, { collectErrors: true }).errors.reduce((acc, error) => {
				console.log(error);
				// Slice(1) is used to remove the leading '/' from the instancePath
				acc[error.instancePath.slice(1)] = error.message;
				return acc;
			}, {});
			return response.failed('0422', constants.MESSAGE_INVALID_DATA);
		}

		const result = await app.post(`/balanceplatform/clients/${this.clientId}/stores`, {
			merchantId: this.merchantAccountId,
			businesslineId: this.data.businesslineId,
			name: this.data.name,
			shopperStatement: this.data.shopperStatement,
			address: this.data.address,
			address2: this.data.address2,
			city: this.data.city,
			country: this.data.country,
			zipcode: this.data.zipcode,
			phoneNumberCountryCode: this.data.phoneNumberCountryCode,
			phoneNumber: this.data.phoneNumber,
		});

		if (result.success === false) {
			// alert(result.error);
			return response.failed('1111', result.error);
		}

		this.callbackOnSuccess();

		app.toast('Saved', {
			intent: 'positive',
		});

		return response.success();
	};

	/**
	 * Resets the `data` variable to an empty object.
	 * @function onOpen
	 */
	onOpen = () => {
		this.data = {
			merchantId: '',
			businesslineId: null,
			name: null,
			shopperStatement: null,
			address: null,
			address2: null,
			city: null,
			country: null,
			zipcode: null,
			phoneNumberCountryCode: null,
			phoneNumber: null,
			// merchantId: 'SjefPlatformECOM_NL',
			// businesslineId: 'SE322KH223222Q5J6LBFX7M7K',
			// name: 'ecom Store',
			// shopperStatement: 'Sjef.app B.V.',
			// address: 'Basisweg 59',
			// address2: 'string',
			// city: 'Amsterdam',
			// country: 'NL',
			// zipcode: '1043 AN',
			// phoneNumberCountryCode: 'NL',
			// phoneNumber: '0202442820',
		};
	};

	/**
	 * Render the component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	view() {
		return m(sjefModal, {
			onOpen: this.onOpen,
			onSubmit: this.onSubmit,
			modalTitle: 'Create Store',
			modalSubTitle: 'Fill in fields to create new store',
			buttonLabel: m(sjefIconPlus),
			buttonLayout: 'iconHover',
			tooltip: `Create ${this.storeType.toUpperCase()} Store`,
			modalSize: 'lg',
			modalContent: m(
				'',
				m(
					Form,
					{
						class: '',
						onsubmit: () => {
							return false;
						},
					},
					[
						m('h2', 'General info'),
						m(
							'.fieldFlex',
							m(sjefInput, {
								label: 'Merchant ID',
								id: 'merchantId',
								intent: this.validationErrors['name'] ? 'negative' : '',
								value: this.merchantAccountId,
								readonly: true,
								icon: m(sjefIconBarcode),
								placeholder: _t('_.fields.placeholders.merchantId'),
								disabled: true,
							}),
							m(
								'',
								m(sjefInput, {
									label: 'Business Line',
									type: 'select',
									id: 'businesslineId',
									icon: m(sjefIconLineDashed),
									placeholder: _t('_.fields.placeholders.adyenBusinessLines'),
									intent: this.validationErrors['businesslineId'] ? 'negative' : '',
									emptyOption: true,
									options: [
										...(this.businessLines || []).map((businessLine) => {
											// Check if businessLine.id and businessLine.industryCode are defined
											console.log('Business Line:', businessLine);
											return {
												value: businessLine.id,
												label: `${businessLine.industryCode} - ${businessLine.id}`,
											};
										}),
									],
									value: this.data.businesslineId,
									onChange: (newValue) => {
										this.data.businesslineId = newValue;
									},
								}),
								m('small', this.validationErrors['businesslineId'] ? this.validationErrors['businesslineId'] : '')
							)
						),
						m(
							'.fieldFlex',
							m(
								'',
								m(sjefInput, {
									label: 'Name',
									id: 'name',
									icon: m(sjefIconTextCaption),
									placeholder: _t('_.fields.placeholders.name'),
									intent: this.validationErrors['name'] ? 'negative' : '',
									value: this.data.name,
									onChange: (newValue) => {
										this.data.name = newValue;
									},
								}),
								m('small', this.validationErrors['name'] ? this.validationErrors['name'] : '')
							),
							m(
								'',
								m(sjefInput, {
									label: 'Shopper statement',
									id: 'shopperStatement',
									icon: m(sjefIconSignature),
									placeholder: _t('_.fields.placeholders.shopperStatement'),
									intent: this.validationErrors['shopperStatement'] ? 'negative' : '',
									value: this.data.shopperStatement,
									onChange: (newValue) => {
										this.data.shopperStatement = newValue;
									},
								}),
								m('small', this.validationErrors['shopperStatement'] ? this.validationErrors['shopperStatement'] : '')
							)
						),
						m(
							'.fieldFlex',
							m(
								'',
								m(sjefInput, {
									label: 'Address',
									id: 'address',
									icon: m(sjefIconAddressBook),
									placeholder: _t('_.fields.placeholders.address'),
									intent: this.validationErrors['address'] ? 'negative' : '',
									value: this.data.address,
									onChange: (newValue) => {
										this.data.address = newValue;
									},
								}),
								m('small', this.validationErrors['address'] ? this.validationErrors['address'] : '')
							),
							m(
								'',
								m(sjefInput, {
									label: 'Address2',
									id: 'address2',
									icon: m(sjefIconAddressBook),
									placeholder: _t('_.fields.placeholders.address2'),
									intent: this.validationErrors['address2'] ? 'negative' : '',
									value: this.data.address2,
									onChange: (newValue) => {
										this.data.address2 = newValue;
									},
								}),
								m('small', this.validationErrors['address2'] ? this.validationErrors['address2'] : '')
							)
						),
						m(
							'.fieldFlex',
							m(
								'',
								m(sjefInput, {
									label: 'City',
									id: 'city',
									icon: m(sjefIconBuildingSkyscraper),
									placeholder: _t('_.fields.placeholders.city'),
									intent: this.validationErrors['city'] ? 'negative' : '',
									value: this.data.city,
									onChange: (newValue) => {
										this.data.city = newValue;
									},
								}),
								m('small', this.validationErrors['city'] ? this.validationErrors['city'] : '')
							),
							m(
								'',
								m(sjefInput, {
									label: 'Country',
									id: 'country',
									value: this.data.country,
									icon: m(sjefIconEPassport),
									placeholder: _t('_.fields.placeholders.country'),
									intent: this.validationErrors['country'] ? 'negative' : '',
									onChange: (newValue) => {
										this.data.country = newValue;
									},
								}),
								m('small', this.validationErrors['country'] ? this.validationErrors['country'] : '')
							),
							m(
								'',
								m(sjefInput, {
									label: 'Zipcode',
									id: 'zipcode',
									value: this.data.zipcode,
									icon: m(sjefIconAddressBook),
									placeholder: _t('_.fields.placeholders.zipcode'),
									intent: this.validationErrors['zipcode'] ? 'negative' : '',
									onChange: (newValue) => {
										this.data.zipcode = newValue;
									},
								}),
								m('small', this.validationErrors['zipcode'] ? this.validationErrors['zipcode'] : '')
							)
						),

						m(
							'.columns-1-3',
							[
								m(
									'',
									m(sjefInput, {
										label: 'Phone Number Country Code',
										id: 'phoneNumberCountryCode',
										// type: 'countrySelect',
										value: this.data.phoneNumberCountryCode,
										icon: m(sjefIconPhone),
										placeholder: _t('_.fields.placeholders.phoneNumberCountryCode'),
										intent: this.validationErrors['phoneNumberCountryCode'] ? 'negative' : '',
										onChange: (newValue) => {
											this.data.phoneNumberCountryCode = newValue;
										},
									}),
									m('small', this.validationErrors['phoneNumberCountryCode'] ? this.validationErrors['phoneNumberCountryCode'] : '')
								),
							],
							[
								m(
									'',
									m(sjefInput, {
										label: 'Phone number',
										id: 'phoneNumber',
										value: this.data.phoneNumber,
										icon: m(sjefIconPhone),
										intent: this.validationErrors['phoneNumber'] ? 'negative' : '',
										onChange: (newValue) => {
											this.data.phoneNumber = newValue;
										},
									}),
									m('small', this.validationErrors['phoneNumber'] ? this.validationErrors['phoneNumber'] : '')
								),
							]
						),
					]
				)
				// Object.keys(JSON.stringify(this.validationErrors)).length ?
				// m('', JSON.stringify(this.validationErrors)) : void(0)
			),
		});
	}
}

export default vwClientAdyenStoresCreateModal;
