import m from 'mithril';
import sjefTag from '../../../views/components/sjefTag';
import app from '../../../app';
import sjefTable from '../../../views/components/sjefTable';
import sjefTh from '../../../views/components/sjefTh';
import { Select } from 'construct-ui';
import { price } from '../../../lib/helpers';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwProfileTopProducts {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */

	totalSpent;
	profile;
	filteredTransactions;
	filteredAttributes = ['quantity', 'total'];
	filterOptions = ['subcategory', 'channel', 'daytime', 'category', 'item', 'city', 'temperature'];
	currentSortAttribute;
	keys;

	oninit(vnode) {
		this.profile = vnode.attrs.profile;
		this.transactions = vnode.attrs.transactions;
		this.filter('subcategory');
	}

	orderBy(attr, direction) {
		this.currentSortAttribute = attr;

		if (direction === 'ASC') {
			this.filteredTransactions.sort((a, b) => {
				if (a[attr] < b[attr]) return -1;
				if (a[attr] > b[attr]) return 1;
				return 0;
			});
		} else if (direction === 'DESC') {
			this.filteredTransactions.sort((a, b) => {
				if (a[attr] > b[attr]) return -1;
				if (a[attr] < b[attr]) return 1;
				return 0;
			});
		}

		m.redraw();
	}

	filter(property) {
		console.log(property);
		const results = [];

		this.transactions.forEach((transaction) => {
			const value = transaction[property];

			// Ignore records with null values
			if (value !== null) {
				// Check if the result for this value already exists in the array
				let result = results.find((item) => item[property] === value);

				if (!result) {
					result = {
						[property]: value,
					};
					this.filteredAttributes.forEach((attribute) => {
						result[attribute] = 0;
					});
					results.push(result);
				}

				// Update the filtered attributes (e.g., quantity and total)
				this.filteredAttributes.forEach((attribute) => {
					result[attribute] += transaction[attribute];
				});
			}
		});

		this.filteredTransactions = results;
		let tempArray = JSON.parse(JSON.stringify(this.filteredAttributes));
		tempArray.unshift(property);

		this.keys = tempArray;


		m.redraw();
	}

	view() {
		console.log('rendering view');

		return m(
			'.profileComponent',
			m(
				'.flexTitle',
				m('h3', 'Top Products'),
				m(
					'.flexFilters',
					m(Select, {
						name: 'filterBy',
						options: this.filterOptions,
						onchange: (evt) => {
							this.filter(evt.target.value);
							m.redraw();
						},
					})
				)
			),

			m(sjefTable, {
				class: 'topProducts',
				content: [
					m(
						'thead',
						m(
							'tr',
							this.keys.map((key) => m(sjefTh, {
								name: key,
								onSortUpdate: (evt) => {
									this.orderBy(evt.sort.replace(/-/g, ""), evt.sort[0] == '-' ? 'ASC' : 'DESC');
								}
							}))
						)
					),
					m(
						'tbody',
						this.filteredTransactions.map((transaction) => {
							return m(
								'tr',
								this.keys.map((key) =>
									m('td', key == 'total' ? price(transaction[key]) : transaction[key])
								)
							);
						})
					),
				],
			})
		);
	}
}

export default vwProfileTopProducts;
