import m from 'mithril'
import { withHooks, useState } from 'mithril-hooks'
import { FormGroup } from 'construct-ui'
import Ajv from 'ajv'
import sjefInput from './sjefInput'
import { _t } from '../../lib/i18n'
import addFormats from 'ajv-formats'

const formGroup = (field, error, children) => {
	return m(FormGroup, {
		class: [
			field.class ? field.class : '',
			field.type,
			error ? 'hasError' : '',
		],
		content: [
			children,
			error ? m('.error', error.message) : void 0,
		],
	});
}


const sjefForm = withHooks(({ fields = [], submit, presubmit, schema }) => {
	const [formErrors, setFormErrors] = useState([])
	const ajv = new Ajv({ allErrors: true })
	addFormats(ajv)

	const clearErrorForField = (fieldName) => {
		setFormErrors(formErrors.filter(error => fieldName !== error.instancePath.slice(1)))
	}

	const formField = (field, error = false) => {
		return m(sjefInput, {
			...field,
			error: error,
			intent: error ? 'negative' : 'none',
			onchange: () => clearErrorForField(field.field),
		})
	}

	const defaultSchema = {
		type: 'object',
		required: fields.filter((field) => field.required).map((field) => field.field),
		properties: fields.reduce((acc, field) => {
			acc[field.field] = {
				type: field.validationType,
			}
			return acc
		}, {})
	}

	return m('form', {
		onsubmit: (e) => {
			e.preventDefault()

			const validate = fields.length > 0 ? ajv.compile(schema || defaultSchema) : {}
			const valid = validate(fields.reduce((acc, field) => {
				acc[field.field] = field.value
				return acc
			}, {}))

			if (!valid) {
				const err = validate.errors.map((error) => {
					const message = error.message === 'must NOT have fewer than 1 characters' ? 'Required' : error.message;
					return { ...error, message };
				})

				setFormErrors(err)
				return
			}
			submit()
		}
	}, [
		fields.map((field) => {
			if (!field.type) return;
			return formGroup(
				field,
				formErrors.find((error) => field.field === error.instancePath.slice(1)),
				formField(field, formErrors.some((error) => field.field === error.instancePath.slice(1)))
			)
		}),
		m('button', { class: "sjefButton iconElement active-state-elem btnFilled", type: 'submit' }, _t('_.actions.save'))
	])
})

export default sjefForm
