import m from 'mithril';
import { Card } from 'construct-ui';
import vwBaseRecords from '../../classes/vwBaseRecords';
import tableStore from '../../lib/tableStore';


class sjefList extends vwBaseRecords {
	constructor(vnode) {
		super();
		if (vnode.attrs.data) return;

		this.config = new vnode.attrs.config(this); // error here means add in index.js

		this.endpoint = this.config.endpoint;

		this.listConfig = this.config.listConfig;
	}

	async oninit({ attrs }) {
		this.title = attrs?.title || this.listConfig?.title;
		this.data = attrs.data;

		if (attrs.config) {
			this.config = attrs.config;

			await this.paginateRecords(tableStore.metadata(), tableStore.records())
		}
	}

	getAmountOfColumn() {
		if (!this.data) return 8;

		const lengthOfAllRows = this.data.map((item) => {
			return Object.keys(item).length
		})
		return Math.max(...lengthOfAllRows)
	}

	amountOfWidth() {
		return `${100 / this.getAmountOfColumn()}%`;
	}

	dataRecords() {
		if (this.data === undefined) {
			return tableStore.records()
		} else {
			return this.data
		}
	}


	renderData() {
		return m('table', {
		}, [
			m('thead', {
			}, [
				m('tr', Array.from({ length: this.getAmountOfColumn() }).map(() => {
					return m('th', { style: { width: this.amountOfWidth() } })
				}))
			]),
			m('tbody', {
			}, [
				this.dataRecords().length > 0 && this.dataRecords()
					.map((row) => {
						row = Object.keys(row).map((key) => [key, row[key]])
						return m('tr', {
						}, row.map((column) => {
							const cellConfig = this.listConfig?.columns[column[0]];
							if (cellConfig?.hidden) return;
							const cellStyle = cellConfig?.style || {};
							return m('td.list__cell', { style: { width: this.amountOfWidth(), ...cellStyle } },
								m(cellConfig?.component || '', { ...cellConfig?.componentProps, label: column[1] }, column[1])
							)
						}))
					}),
			])
		])
	}

	view() {
		if (!this.data) return;
		return m(Card, { class: 'list' }, [m('h5', this.title), this.renderData()])
	}
}

export default sjefList;
