/**
 * @fileOverview This module provides a form to update a business line in Adyen Balance Platform.
 * @module vwClientAdyenBusinessLinesUpdateModal
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import { Form, FormLabel, Select, FormGroup } from 'construct-ui';
import validation from '@sjefapp/sjef-validation';

import sjefModal from '../../../views/components/sjefModal';
import response from '../../../lib/response';
import app from '../../../app';
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';
import { _t } from '../../../lib/i18n';
import industryCodes from '../../../utils/industryCodes';
import sjefInput from '../../../views/components/sjefInput';

/**
 * Object defining the view component of the form, including initialization and rendering logic.
 */
class vwClientAdyenBusinessLinesUpdateModal {
	/**
	 * Define validation schema for the form data.
	 */
	validationSchema = {
		type: 'object',
		properties: {
			industryCode: { type: 'string', minLength: 1, pattern: '\\S+' },
		},
		required: ['industryCode'],
	};

	/**
	 * @type {string} businessLineId - The ID of the businessline.
	 */
	businessLineId = '';

	/**
	 * @type {integer} clientId - The ID of the client.
	 */
	clientId = '';

	/**
	 * @type {Function} callbackOnSuccess - The callback function to be called when an operation completes successfully.
	 */
	callbackOnSuccess;

	/**
	 * @type {Object} data - An object that holds the data to be submitted. Structure depends on form requirements.
	 */
	data = {};

	/**
	 * Initialize the component with the provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {Object} vnode.attrs - The attributes passed to the component.
	 * @param {integer} vnode.attrs.clientId - The ID of the client.
	 * @param {string} vnode.attrs.businessLineId - The ID of the client.
	 * @param {Function} vnode.attrs.callbackOnSuccess - The function to call when the action completes with success.
	 */
	oninit = async ({ attrs }) => {
		this.businessLineId = attrs.businessLineId;
		this.clientId = attrs.clientId;
		this.callbackOnSuccess = attrs.callbackOnSuccess;
		this.data = attrs.data;
	};

	/**
	 * Resets the component state.
	 * @function onOpen
	 */
	onOpen = () => {
		this.data = {
			webAddress: this.data.webData[0].webAddress,
			industryCode: this.data.industryCode,
		};
	};

	/**
	 * Asynchronous function to handle form submission.
	 * Validates the form data according to the defined schema,
	 * makes a request to create a business line with the validated data,
	 * handles the response, and provides user feedback.
	 *
	 * @returns {object} a Promise resolving to a response object
	 */
	onSubmit = async () => {
		if (validation.validate(this.validationSchema, this.data).valid === false) {
			return response.failed('0422', constants.MESSAGE_INVALID_DATA, validation.validate(this.validationSchema, this.data).errors);
		}

		const result = await app.patch(`/balanceplatform/clients/${this.clientId}/businesslines/${this.businessLineId}`, {
			industryCode: this.data.industryCode,
			webData: [{
				webAddress: this.data.webAddress,
			}]
		});

		if (result.success === false) {
			return response.failed('1111', result.error);
		}

		this.callbackOnSuccess();

		app.toast('Updated', {
			intent: 'positive',
		});

		return response.success();
	};

	/**
	 * Render the component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	view() {
		return m(sjefModal, {
			onOpen: this.onOpen,
			onSubmit: this.onSubmit,
			modalTitle: 'Update Business Line',
			buttonLabel: [m(sjefIconEdit), m('label', _t('_.buttons.edit'))],
			buttonLayout: 'ghost',
			modalSize: 'lg',
			modalContent: m(
				'',
				m(
					Form,
					{
					},
					[
						m(FormGroup, {
							content: [
								m(FormLabel, { for: 'industryCode' }, 'Industry Code'),
								m(
									'',
									m(sjefInput, {
										label: 'Industry Code',
										type: 'select',
										id: 'industryCode',
										options: industryCodes,
										value: { value: this.data.industryCode, label: '' },
										onChange: (newValue) => {
											this.data.industryCode = newValue;
										},
									})
								),
								m(FormLabel, { for: 'webAddress' }, 'Web Address'),
								m(
									'',
									m(sjefInput, {
										label: 'Web Address',
										type: 'text',
										id: 'webAddress',
										value: this.data.webAddress,
										onChange: (newValue) => {
											this.data.webAddress = newValue;
										},
									})
								),
							],
						}),
					]
				)
			),
		});
	}
}

export default vwClientAdyenBusinessLinesUpdateModal;
