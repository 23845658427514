import m from 'mithril'
import { Table, Switch } from 'construct-ui';
import sjefTableBodyRow from './sjefTableBodyRow';
import sjefTableHeadRow from './sjefTableHeadRow';
import sjefTableSettings from '../../../views/components/sjefTableSettings';
import sjefPagination from '../../../views/components/sjefPagination';

import { _t } from '../../../lib/i18n';
import { withHooks } from 'mithril-hooks';


const additionalClass = '';

const sjefTable = withHooks(({
	fields = [],
	records = [],
	config,
	selectedRecords,
	setSelectedRecords,
	metadata,
	setMetadata,
	activeSort,
	setSorting,
	allRecordsSelected,
	toggleAllRecordsSelection,
}) => {
	const renderRecords = () => records.map((record) => m(sjefTableBodyRow, {
		record,
		fields,
		selectedRecords,
		setSelectedRecords,
		config
	}));

	return m('.sjefTableRoot',
		m('', m('.sjefTableWrapper', {
			class: additionalClass.includes('list') ? 'list' : ''
		}, m(Table, {
			class: 'sjefTable ' + additionalClass,
			bordered: false,
			striped: true,
		}, [
			sjefTableHeadRow(fields, activeSort, setSorting, allRecordsSelected, toggleAllRecordsSelection, config.hideCheckboxes),
			records.length > 0 ? renderRecords() : m('', "No records found")
		]))),
		m(sjefTableSettings, {
			tools: [
				selectedRecords.length > 0 ?
					`${selectedRecords.length} ${_t('_.fields.selected', { defaultValue: 'Selected' })}` :
					undefined,
			],
			pagination: m(sjefPagination, {
				paginationParams: metadata,
				onPaginationUpdate: async (paginationReturnData) => {
					toggleAllRecordsSelection(!allRecordsSelected);
					setMetadata({
						metadata,
						...paginationReturnData
					});
				},
			}),
		})
	)
})



export default sjefTable
