import m from 'mithril';
import Dropzone from 'dropzone';

import app from '../../app';
import { Button } from 'construct-ui';
import sjefButton from './sjefButton';

/**
 * Represents a view model for a input of an image (dropzone).
 * @class
 */
class sjefInputImage {
	image = '';
	nameImageField;
	recordId;
	directory;
	endpoint;
	endpointUpload;

	constructor({ attrs }) {
		this.endpoint = attrs.endpoint;
		this.endpointUpload = `${app.apiRoot}/media/images`;
		// this.endpointUpload = 'http://127.0.0.1:3022/images';

		this.image = attrs.image?.replace('{size}', 'thumb');

		this.directory = attrs.directory;
		this.nameImageField = attrs.nameImageField;
		this.recordId = attrs.recordId;
	}

	oncreate() {
		this.initializeDropzone();
	}

	initializeDropzone() {
		new Dropzone('#dropzoneImage', {
			url: this.endpointUpload,
			paramName: 'file',
			parallelUploads: 1,
			maxFilesize: this.imgFilesize || 5, // MB
			acceptedFiles: 'image/*',
			headers: {
				Authorization: `Bearer ${app.apiToken}`,
			},
		})
			.on('sending', (file, xhr, formData) => {
				formData.append('directory', this.directory);
			})
			.on('success', async (file, img) => {
				if (img.success) {
					this.updateRecord(img.data);
				} else {
					app.toast(img.message, { intent: 'negative' });
				}
			});
	}

	async deleteImage() {
		let success = true;

		const result = await app.delete(this.endpointUpload, {
			imageUrl: this.image.replace('thumb', '{size}'),
		});

		if (result.error) {
			success = false;
		}

		alert(success);

		if (success) {
			const result = await this.updateRecord({
				id: this.recordId,
				imageUrl: null,
			});

			if (result.success) {
				app.toast('success', { intent: 'positive' });

				this.image = null;
			} else {
				app.toast(result.message, { intent: 'negative' });
			}
		} else {
			app.toast('error', { intent: 'negative' });
		}
	}

	async updateRecord(data) {
		const payload = {
			id: this.recordId,
			[this.nameImageField]: data.imageUrl,
		};

		const result = await app.patch(this.endpoint, payload);

		if (result.success) {
			this.image = data.imageUrl ? data.imageUrl.replace('{size}', 'thumb') : null;

			app.toast('success', { intent: 'positive' });
		} else {
			app.toast(thumbnail.message, { intent: 'negative' });
		}
	}

	/**
	 * This function generates a view of the inputImage.
	 * @return {Object} Mithril virtual DOM object
	 */
	view() {
		return m('.inputImageWrapper', [
			this.image
				? m('img', {
						src: this.image,
				  })
				: m('', ''),
			m(sjefButton, { 
				label: 'Delete', 
				buttonLayout: 'danger',
				onclick: () => this.deleteImage() 
			}),
			// TODO: hide input if thumbnail is set
			m('form', { class: 'dropzone', id: 'dropzoneImage' }, [m('div.fallback', [m('input', { name: 'file', type: 'file', multiple: true })])]),
		]);
	}
}

export default sjefInputImage;
