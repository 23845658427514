import { Callout, Form, FormGroup, FormLabel, Input } from 'construct-ui';
import m from 'mithril';
import app from '../../app';
import sjefButton from '../components/sjefButton';
import forgotPasswordForm from './components/forgotPasswordForm';
import resetPasswordForm from './components/resetPasswordForm';

const logoSrc = new URL('../../assets/img/sjef_logo.png', import.meta.url).href;

/**
 * @type { import("mithril").Component }
 * @property {Object} secure - Defines security or public when false
 * @property {string} secure.obj - Define security object
 * @property {string} secure.act - Define security action
 * @property {string} redirect - After login, redirect to this path, add to url param
 */

const forgotPassword = {
	token: false,
	secure: false,
	menu: false,
	oninit: ({ attrs }) => {
		if (attrs.token) {
			forgotPassword.token = attrs.token;
			console.log(forgotPassword.token);
		}
	},

	view: () => {
		return m('.login', [
			m(
				'.homePageInner',
				m('', m('img', { src: logoSrc })),
				m('', forgotPassword.token === false ? m(forgotPasswordForm) : m(resetPasswordForm, { token: forgotPassword.token }))
			),

			// m(Button, { label: 'TEST MODULE', onclick: () => m.route.set('/test01')})
		]);
	},
};

export default forgotPassword;
