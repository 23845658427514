import { Card, Form, FormGroup, FormLabel, Input } from "construct-ui";
import m from "mithril";
import sjefButton from "../../views/components/sjefButton";
import sjefBreadcrumbs from "../../views/components/sjefBreadcrumbs";
import sjefIconArrowLeft from "@sjefapp/sjeficons/icons/sjefIconArrowLeft";
import dropDownCard from "../../views/components/dropDownCard";
import { _t } from "../../lib/i18n";
import app from "../../app";
import vwProfileBadges from "../customerProfiles/components/vwProfileBadges";

class vwUserProfile {
	titleBreadcrumb;



	oninit(vnode) {
		this.title = app.user.sub;
	}

	view() {
		return m('.profile', [
			m(
				Form,
				{
					onsubmit: () => {
						return false;
					},
				},
				m(
					'.pageHeader',
					m(sjefButton, {
						iconLeft: sjefIconArrowLeft,
						buttonLayout: 'previous',
						label: 'Back to Assets list',
						onclick: () => m.route.set('/operations/assets'),
					}),
					m(sjefBreadcrumbs, {
						labelParams: {
							':id': this.titleBreadcrumb,
						},
					}),
					m('h1', this.title),
				),
				m('.columns-2',
					m(Card, 
						m('h2', 'Personal Info'),
						m(FormGroup, {
							content: [
								m(FormLabel, _t('_fields.name')),
								m(Input, {
									id: 'name',
									value: app.user.sub,
									readonly: true,
								})
							]
						})
					),
					m(Card, 
						// m(vwProfileBadges),
					),
					m(Card, 
						// m(vwProfileBadges),
					),
					m(Card, 
						// m(vwProfileBadges),
					)
				),
			),
		])

	}
}

export default vwUserProfile;