import m from 'mithril';
import badges from '../../../lib/badges';
import { Tooltip } from 'chart.js';
import app from '../../../app';
import { price } from '../../../lib/helpers';

const BronzeDancer = new URL('../../../assets/badges/BronzeDancer.png', import.meta.url);
const SilverDancer = new URL('../../../assets/badges/SilverDancer.png', import.meta.url);
const GoldDancer = new URL('../../../assets/badges/GoldDancer.png', import.meta.url);

const BronzeBeer = new URL('../../../assets/badges/BronzeBeer.png', import.meta.url);
const SilverBeer = new URL('../../../assets/badges/SilverBeer.png', import.meta.url);
const GoldBeer = new URL('../../../assets/badges/GoldBeer.png', import.meta.url);

const BronzeDollarbill = new URL('../../../assets/badges/BronzeDollarbill.png', import.meta.url);
const SilverDollarbill = new URL('../../../assets/badges/SilverDollarbill.png', import.meta.url);
const GoldDollarbill = new URL('../../../assets/badges/GoldDollarbill.png', import.meta.url);

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwProfileBadges {
	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */

	profile;
	transactions;

	eventsConditions = {
		bronze: 1,
		silver: 4, 
		gold: 8
	};

	beerConditions = {
		bronze: 1, 
		silver: 2,
		gold: 5
	};

	spentConditions = {
		bronze: 0.4,
		silver: 0.6,
		gold: 1,
	};

	beerCount;
	spent;
	eventsCount;

	oninit(vnode) {
		this.profile = vnode.attrs.profile;
		this.transactions = vnode.attrs.transactions;
		this.spent = this.sumTotal(this.transactions);

		this.eventsCount = this.countUniqueAdministrationIds(this.transactions);
		this.beerCount = this.sumBeerQuantities(this.transactions);
	}

	sumTotal(transactions) {
		return transactions.reduce((acc, transaction) => acc + transaction.total, 0);
	}

	countUniqueAdministrationIds(transactions) {
		const uniqueIds = new Set();
		transactions.forEach(transaction => {
			uniqueIds.add(transaction.administrationId);
		});
		return uniqueIds.size;
	}

	sumBeerQuantities(transactions) {
		return transactions.filter(transaction => transaction.subcategory === 'beer')
						   .reduce((acc, transaction) => acc + transaction.quantity, 0);
	}

	view() {
		return m(
			'.profileComponent.customerBadges',
			m('h2', 'Customer Badges'),
			m(
				'',
				// m('h4', 'Snoeier'),
				m(
					'.badgeRow',
					m('h4', 'Frequent Visitor'),
					m('.badges',
						m(
							'.bronzeDancer',
							{
								class:
									this.eventsCount >= this.eventsConditions.bronze && this.eventsCount < this.eventsConditions.silver
										? 'active'
										: '',
							},
							m('img', { src: BronzeDancer }),
							m('span.progress', this.eventsCount + ' / ' + this.eventsConditions.silver)
						),
						m(
							'.silverDancer',
							{
								class:
									this.eventsCount >= this.eventsConditions.silver && this.eventsCount < this.eventsConditions.gold
										? 'active'
										: '',
							},
							m('img', { src: SilverDancer }),
							m('span.progress', this.eventsCount + ' / ' + this.eventsConditions.gold)
						),
						m(
							'.goldDancer',
							{
								class: this.eventsCount >= this.eventsConditions.gold ? 'active' : '',
							},
							m('img', { src: GoldDancer }),
							m('span.progress', this.eventsCount)
						)
					)
				)
			),
			m(
				'',
				// m('h4', 'Pils Laaien'),
				m(
					'.badgeRow',
					m('h4', 'Professional Beer Drinker'),
					m('.badges',
					m(
						'.bronzeBeer',
						{
							class:
								this.beerCount >= this.beerConditions.bronze && this.beerCount < this.beerConditions.silver ? 'active' : '',
						},
						m('img', { src: BronzeBeer }),
						m('span.progress', this.beerCount + ' / ' + this.beerConditions.silver)
					),
					m(
						'.silverBeer',
						{
							class:
								this.beerCount >= this.beerConditions.silver && this.beerCount < this.beerConditions.gold ? 'active' : '',
						},
						m('img', { src: SilverBeer }),
						m('span.progress', this.beerCount + ' / ' + this.beerConditions.gold)
					),
					m(
						'.goldBeer',
						{
							class: this.beerCount >= this.beerConditions.gold ? 'active' : '',
						},
						m('img', { src: GoldBeer }),
						m('span.progress', this.beerCount)
					))
				)
			),
			m(
				'',
				// m('h4', 'Big Spender'),
				m(
					'.badgeRow',
					m('h4', 'Big Spender'),
					m('.badges',
					m(
						'.bronzeDollarbill',
						{
							class: this.spent >= this.spentConditions.bronze && this.spent < this.spentConditions.silver ? 'active' : '',
						},
						m('img', { src: BronzeDollarbill }),
						m('span.progress', parseFloat(this.spent).toFixed(2) + ' / ' + parseFloat(this.spentConditions.silver).toFixed(2))
					),
					m(
						'.silverDollarbill',
						{
							class: this.spent >= this.spentConditions.silver && this.spent < this.spentConditions.gold ? 'active' : '',
						},
						m('img', { src: SilverDollarbill }),
						m('span.progress', price(this.spent) + ' / ' + price(this.spentConditions.gold))
					),
					m(
						'.goldDollarbill',
						{
							class: this.spent >= this.spentConditions.gold ? 'active' : '',
						},
						m('img', { src: GoldDollarbill }),
						m('span.progress', price(this.spent))
					)
					)
				)
			)
		);
	}
}

export default vwProfileBadges;
