import m from 'mithril';
import { Callout, Form, FormGroup, FormLabel, Input } from 'construct-ui';
import sjefButton from '../../../views/components/sjefButton';
import app from '../../../app';

let qrCode;
let setupKey;

// user inputs
let code = '';

const createTfa = async () => {
	try {
		const result = await app.get('/auth/tfa/create');

		if (result.success === false) {
			return;
		}

		qrCode = result.data.qr;
		setupKey = result.data.secret;

		return true;
	} catch (err) {
		return false;
	}
};

const validateCode = async () => {
	try {
		const resultData = await app.post('/auth/tfa/validate', { code: code });
		const result = resultData.data;

		if (!result.valid) {
			return false;
		}

		app.tfa.setup = true;
		app.store('tfa', app.tfa);

		await app.setUser({
			lvl: result.identity.lvl,
			token: result.token,
			identity: result.identity,
			tfa: false,
		});

		return true;
	} catch (err) {
		return false;
	}
};

class vwSetupTfa {
	oncreate() {
		createTfa();
	}
	view({ attrs: { completed } }) {
		return m(
			Form,
			{
				align: 'top',
				justify: 'space-between',
			},
			[
				m('h1', '2FA Instellen'),
				m(FormGroup, [
					m('img', {
						src: qrCode,
					}),
				]),
				m(FormGroup, [m(FormLabel, `Setup Key: ${setupKey}`)]),
				m(FormGroup, [
					m(FormLabel, 'Code'),
					m(Input, {
						onchange: (ev) => {
							code = ev.target.value;
						},
						onkeypress: async (ev) => {
							if (ev.keyCode === 13 || ev.which === 13) {
								// Enter key
								ev.preventDefault();
							}
						},
					}),
				]),
				m(sjefButton, {
					label: 'validate',
					buttonLayout: 'filled large',
					onclick: async () => {
						const result = await validateCode();

						if (result) {
							completed();
						}
					},
				}),
			]
		);
	}
}

export default vwSetupTfa;
