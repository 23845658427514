import m from 'mithril';
import { _t } from '../../../lib/i18n';
import { downloadBase64 } from '../../../utils/files';
import sjefTable from '../../../views/components/sjefTable';
import app from '../../../app';
import { Card } from 'construct-ui';
import sjefButton from '../../../views/components/sjefButton';
import actionsMenu from '../../../views/components/actionsMenu';

/**
 * Represents a view model for a Client form.
 * @class
 */
class vwAssetsList {
	project;
	endpoint = '/operations/projectAssets';
	assets;

	constructor({ attrs }) {
		this.project = attrs.record;
	}

	storeAssetPlanning = async () => {
		if (!this.project.stack) {
			return alert('No stack');
		}
		if (!this.project.clientCodeKantoorLegacy) {
			return alert('No clientCode');
		}
		const resultLocations = await app.post('/terminals/locations', {
			stack: this.project.stack,
			client_code: this.project.clientCodeKantoorLegacy,
		});

		if (resultLocations.success) {
			let planningAssets = {};
			resultLocations.data.forEach((location) => {
				let assets = {};
				Object.keys(location.asset_planning || {}).forEach((key) => {
					const assetName = key.toLowerCase();

					if (location.asset_planning[assetName] > 0) {
						assets[assetName] = { planned: parseInt(location.asset_planning[assetName]), totalOut: 0 };
					}
				});
				planningAssets[location.id] = {
					locationName: location.name,
					assets: assets,
				};
			});
			// update project
			// const result = await app.patch('http://127.0.0.1:3019/projects', {
			const result = await app.patch('/operations/projects', {
				id: this.project.id,
				planningAssets: planningAssets,
				status_assets: this.project.status_assets === 'new' ? 'planned' : this.project.status_assets,
			});

			if (result.success) {
				this.project.planningAssets = planningAssets;
				console.log('this.project.planningAssets', this.project.planningAssets);
			}
		}
	};

	// getAssets = async () => {
	// 	let result = await app.get(this.endpoint + '?filter[projectId]=' + parseInt(this.project.id));

	// 	console.log(result);

	// 	if (result.success) {
	// 		this.assets = result.data;
	// 	}
	// };

	downloadPdf = async (type) => {
		try {
			const response = await app.get(`${this.endpoint}/${this.project.id}/download?type=${type}`);
			// const response = await app.get(`http://127.0.0.1:3019/projectAssets/${this.project.id}/download?type=${type}`);

			if (!response.success || response.error) {
				return alert(response.error);
			}

			downloadBase64(response.data.base64, 'application/pdf', response.data.filename);

			// return response.data;
		} catch (error) {
			alert(error.message);
			// return response.error('3001', error.message);
		}
	};

	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */
	view() {
		return m('', [
			m(
				'.assetsButtons.flexer',
				m('.iconHover.background',
					m(actionsMenu, {
						content: [
							m(
								sjefButton,
								{
									buttonLayout: 'ghost',
									label: 'Download PDF (overview)',
									onclick: () => {
										this.downloadPdf('overview');
									},
								},
							),
							m(
								sjefButton,
								{
									buttonLayout: 'ghost', // Adjust the layout as needed
									label: 'Download PDF (checkedout)',
									onclick: () => {
										this.downloadPdf('checkedout');
									},
								},
							),
							m(
								sjefButton,
								{
									buttonLayout: 'ghost', // Adjust the layout as needed
									label: 'Download PDF (packingSlip)',
									onclick: () => {
										this.downloadPdf('packingSlip');
									},
								},
							),
							m(
								sjefButton,
								{
									buttonLayout: 'ghost', // Adjust the layout as needed
									label: 'Get & Store Planning',
									onclick: () => {
										this.storeAssetPlanning();
									},
								},
							)
						]
					})
				)
			),
			this.assets instanceof Promise == false &&
				m(sjefTable, {
					// config: 'projects',
					// fields: ['id', 'outAt', 'locationId', 'projectId'],
					// config: 'accounts',
					// fieldsToShow: ['id', 'type'],
					fieldsToExclude: ['manufacturer'],
					config: 'assets',
					endpoint: this.endpoint + '?filter[projectId]=' + this.project.id,
					tableSettings: true,
					records: this.assets,
				})

			// m(Card, this.project.planningAssets ? this.project.planningAssets : ''),

			// this.assets instanceof Promise == false &&
			// 	m(
			// 		Card,
			// 		m(sjefTable, {
			// 			// config: 'projects',
			// 			// fields: ['id', 'outAt', 'locationId', 'projectId'],
			// 			// config: 'accounts',
			// 			// fieldsToShow: ['id', 'type'],
			// 			// config: 'assets',
			// 			// endpoint: this.endpoint + '?filter[projectId]=' + this.projectId,
			// 			tableSettings: true,
			// 			records: this.assets,
			// 		})
			// 	),
		]);
	}
}

export default vwAssetsList;
