import m from 'mithril'
import { Switch } from 'construct-ui';
import sjefCheckbox from '../../../views/components/sjefCheckbox';
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';
import sjefTableRowActions from './sjefTableRowActions';
import sjefIconCheckbox from '@sjefapp/sjeficons/icons/sjefIconCheckbox';
import sjefIconMoodAngry from '@sjefapp/sjeficons/icons/sjefIconMoodAngry';
import sjefIconClock from '@sjefapp/sjeficons/icons/sjefIconClock';
import sjefLink from '../../../views/components/sjefLink';
import app from '../../../app';
import { _t } from '../../../lib/i18n';
import { useState, withHooks } from 'mithril-hooks';


const listSizes = {
	fixed: 'cFixed',
	small: 'cSmall',
	medium: 'cMedium',
	large: 'cLarge',
};


const sjefTableBodyRow = withHooks(({ record, fields, selectedRecords, setSelectedRecords, config }) => {
	const [editableFields, setEditableFields] = useState([]);
	const toggleRecordSelection = (recordId) => {
		const index = selectedRecords.indexOf(recordId);
		if (index >= 0) {
			setSelectedRecords(selectedRecords.filter((recordId) => recordId !== recordId));
		} else {
			setSelectedRecords([recordId, ...selectedRecords]);
		}
	}
	const replaceDynamicLink = (link, record) => {
		return link.replace(/:(\w+)/g, (match, paramName) => {
			return record[paramName];
		});
	};

	const getInputMarker = (name, marker) => {
		name = name.toLowerCase();

		if (marker == 'status') {
			if (name == 'completed') {
				return m(sjefIconCheckbox);
			} else if (name == 'cancelled' || name == 'canceled' || name == 'rejected' || name == 'failed') {
				return m(sjefIconMoodAngry);
			} else if (name == 'open') {
				return m(sjefIconClock);
			}
		}

		return m('', '');
	};

	const editFields = (record, field) => {
		return m(
			'.editInputWrapper',
			{
				class:
					editableFields[record.id] &&
						editableFields[record.id][field.name]
						? ''
						: 'readonly',
			},
			m('input.listEdit', {
				type: 'input',
				readonly: !(
					editableFields[record.id] &&
					editableFields[record.id][field.name]
				),
				value: record[field.name],
				oninput: function (evt) {
					record[field.name] = evt.target.value;
				},
				onclick: () => {
					if (
						!(
							editableFields[record.id] &&
							editableFields[record.id][field.name]
						) &&
						field.link
					) {
						// todo fix link
						m.route.set(field.link);
					}
				},
			}),
			m(sjefIconEdit, {
				class: 'edit',
				onclick: () => {
					const newEditableRecord = { ...editableFields };

					if (newEditableRecord.hasOwnProperty(record.id)) {
						// If the record with the given ID already exists, add properties to it
						newEditableRecord[record.id][field.name] = record[field.name];
					} else {
						// If the record with the given ID doesn't exist, create a new record
						newEditableRecord[record.id] = {
							[field.name]: record[field.name],
						};
					}

					setEditableFields(newEditableRecord)
				},
			}),
			m(sjefIconCheck, {
				class: 'save',
				onclick: async () => {
					const data = {
						id: record.id,
						[field.name]: record[field.name],
					};

					const result = await app.patch(self.endpoint, data);

					if (result.success) {
						app.toast('Record saved', {
							intent: 'positive',
						});

						// Remove the field from this.editableFields
						if (editableFields.hasOwnProperty(record.id)) {
							delete editableFields[record.id][field.name];

							// If there are no more properties in the record.id object, remove it
							if (
								Object.keys(editableFields[record.id]).length === 0
							) {
								delete editableFields[record.id];
							}
						}
					} else {
						app.toast('Something went wrong', {
							intent: 'negative',
						});
					}
				},
			}),
			m(sjefIconX, {
				class: 'cancel',
				onclick: () => {
					record[field.name] = editableFields[record.id][field.name];

					// Remove the field from this.editableFields
					if (editableFields.hasOwnProperty(record.id)) {
						delete editableFields[record.id][field.name];

						// If there are no more properties in the record.id object, remove it
						if (Object.keys(editableFields[record.id]).length === 0) {
							delete editableFields[record.id];
						}
					}
				},
			})
		);
	};

	const renderField = (record, field) => {
		if (field.listEdit === true) {
			return editFields(record, field);
		}

		if (field.link) {
			if (field.onClickAction) {
				return m(sjefLink, {
					label: record[field.name],
					onclick: () => field.onClickAction(record.id),
				});
			} else {
				const newLink = replaceDynamicLink(field.link, record);

				return m(sjefLink, {
					label: record[field.name],
					href: newLink,
				});
			}
		}
		if (field.formInputType === 'password') {
			return '****';
		}

		if (field.type === 'boolean') {
			return m(Switch, {
				defaultChecked: record.active,
				onchange: (evt) => {
					record[field.name] = evt.target.checked;
					if (field.onChange) {
						field.onChange(record.id, evt.target.checked);
					} else {
						app.patch(config.endpoint, record);
					}
				},
			});
		}

		if (field.inputMarker) {
			return m(
				'.inputMarker',
				getInputMarker(record[field.name], field.inputMarker),
				record[field.name]
			);
		}
		if (field.formatter && typeof field.formatter === 'function') {
			return m('', {
				class: 'format-' + field.formatter.name
			}, field.formatter(record[field.name]));
		}
		return m('', record[field.name]);
	};

	return m(
		'tr',
		[
			!config.hideCheckboxes && m(
				'td',
				{ class: 'cSmall table-checkbox' },
				m(sjefCheckbox, {
					class: 'selectRecord',
					checked: selectedRecords.includes(record.id || record.uuid),
					onChange: () => {
						toggleRecordSelection(record.id || record.uuid);
					},
				})
			),
			fields.map((field) => {
				if (field.listShow) {
					return m(
						'td',
						{
							class: [field.name, listSizes[field.listSize]].join(' '),
						},
						m('', renderField(record, field))

					);
				}
				return null;
			}),
			m('td.actions',
				sjefTableRowActions(record, config)
			),

		])
})

export default sjefTableBodyRow
