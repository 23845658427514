import m from 'mithril'
import sjefIconEdit from '@sjefapp/sjeficons/icons/sjefIconEdit';
import sjefModal from '../../../views/components/sjefModal';
import sjefButton from '../../../views/components/sjefButton';
import sjefIconTrash from '@sjefapp/sjeficons/icons/sjefIconTrash';
import sjefIconDownload from '@sjefapp/sjeficons/icons/sjefIconDownload';
import sjefPopover from '../../../views/components/sjefPopover';
import { downloadBase64 } from '../../../utils/files';
import app from '../../../app';

import { _t } from '../../../lib/i18n';


const sjefTableRowActions = (record, config) => {
	if (!record) return;
	if (!config.actions) return;
	const download = async (recordId) => {
		try {
			const response = await app.get(`${config.endpoint}/${recordId}/download`);

			if (!response.success || response.error) {
				return alert(response.error);
			}

			downloadBase64(response.data.base64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', response.data.filename);

			// return response.data;
		} catch (error) {
			alert(error.message);
			// return response.error('3001', error.message);
		}
	}

	const cancel = async (recordId) => {
		try {
			const response = await app.post(`${config.endpoint}/${recordId}/cancel`);
			if (!response.success || response.error) {
				return false;
			}

			return true;
		} catch (error) {
			return false;
		}
	}

	const actions = [];

	// canUpdate action
	if (config?.actions.canUpdate()) {
		actions.push(
			m(sjefButton, {
				buttonLayout: 'inline',
				label: [m(sjefIconEdit), m('label', _t('_.buttons.edit'))],
				href: `${config.route}/${record.id}`,
			})
		);
	}

	// canCancel action
	if (config?.actions.canCancel && config.actions.canCancel(record)) {
		actions.push(
			m(sjefModal, {
				onSubmit: () => cancel(record.id),
				buttonLabel: [m(sjefIconTrash), m('label', _t('_.buttons.delete'))],
				buttonConfirm: _t('_.modals.confirmCancelButton'),
				buttonLayout: 'inline',
				modalTitle: _t('_.modals.confirmCancelTitle', { name: record.name }),
				modalContent: _t('_.modals.confirmCancelContent', { name: record.name }),
			})
		);
	}

	// canDownload action
	if (config?.actions.canDownload && config.actions.canDownload(record)) {
		actions.push(
			m(sjefButton, {
				onclick: () => download(record.id),
				label: [m(sjefIconDownload), m('label', _t('_.buttons.download'))],
				buttonLayout: 'inline',
			})
		);
	}

	// formDropDownButtons actions
	if (config?.formDropDownButtons) {
		config.formDropDownButtons.forEach((button) => {
			const buttonAttrs = typeof button.componentAttrs === 'function' ? button.componentAttrs(record) : button.componentAttrs;

			// hide button if a record status demands it
			if (
				!(
					record.status &&
					((buttonAttrs.hideStatuses &&
						buttonAttrs.hideStatuses.length &&
						buttonAttrs.hideStatuses.includes(record.status.toLowerCase())) ||
						(buttonAttrs.buttonHideStatuses &&
							buttonAttrs.buttonHideStatuses.length &&
							buttonAttrs.buttonHideStatuses.includes(this.record.status.toLowerCase())))
				)
			) {
				actions.push(
					m(button.component, {
						...buttonAttrs,
					})
				);
			}
		});
	}

	// Conditionally render sjefPopover or just the actions
	const content =
		actions.length > 2
			? m(sjefPopover, {
				class: record.status ? record.status.toLowerCase() : '',
				content: actions,
			})
			: actions;

	return content;
}

export default sjefTableRowActions;
