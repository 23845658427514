import m from 'mithril';
import { Form, FormGroup, FormLabel, Input } from 'construct-ui';
import sjefModal from '../components/sjefModal';
import sjefSelectAsync from '../../views/components/sjefSelectAsync';
import { _t } from '../../lib/i18n';
import FormTrait from '../../classes/traits/FormTrait';
import { parseUTC, formatUTC } from '../../lib/helpers';
import sjefInput from '../components/sjefInput';

const schema = {
	properties: {
		projectId: { type: 'integer', examples: [1], minimum: 1 },
		name: { type: 'string', minLength: 1, examples: ['Completion Report for Project X'] },
		metadataStart: { type: 'string', format: 'date-time', examples: ['2021-01-01T00:00:00.000Z'], formatMaximum: { $data: '1/metadataEnd' } },
		metadataEnd: { type: 'string', format: 'date-time', examples: ['2021-01-01T00:00:00.000Z'], formatMinimum: { $data: '1/metadataStart' } },
	},
	required: ['projectId', 'name', 'metadataStart', 'metadataEnd'],
};

/**
 * @class modalFormUnsaved
 * @classdesc Class representing a modal for unsaved forms
 */
class modalReportsCreateProjectCompletion {
	refs = [];

	// endpoint = `http://127.0.0.1:3020/projects/completion`;
	endpoint = `/reporting/reconciliations`;
	isOpen = false;
	form = {};
	record = {};
	schema = schema;
	onSuccess = () => {};

	/**
	 * Constructor to initialize the modal
	 * @param {Object} vnode - Mithril virtual node
	 */
	constructor(vnode) {
		Object.assign(this, FormTrait);

		this.onSuccess = vnode.attrs.onSuccess;
	}

	/**
	 * Handles the event when the form is opened.
	 * It initializes the form using the setForm function.
	 */
	onOpen = () => {
		this.setForm();
	};

	/**
	 * Handles the event when a project ID is selected.
	 * Updates metadata start and end fields based on the provided data.
	 *
	 * @param {object} data - The data containing project start and end dates.
	 */
	onSelectProjectId = (data) => {
		this.setValue(
			{
				field: 'metadataStart',
				value: parseUTC(data.projectStart),
				valueOriginal: parseUTC(data.projectStart),
			},
			'date'
		);

		this.setValue(
			{
				field: 'metadataEnd',
				value: parseUTC(data.projectEnd),
				valueOriginal: parseUTC(data.projectEnd),
			},
			'date'
		);

		console.log(this.formErrors);
		console.log(this.form);
	};

	/**
	 * Handles the event when the form is submitted.
	 * Prepares the metadata section of the form and triggers the onSubmit callback.
	 *
	 * @returns {Promise} A promise representing the form submission.
	 */
	onSubmitForm = async () => {
		this.form.metadata = {
			start: parseUTC(this.form.metadataStart),
			end: parseUTC(this.form.metadataEnd),
		};
		return await this.onSubmit(this.onSubmitSuccessCallback);
	};

	/**
	 * Handles the event when the form submission is successful.
	 * Closes the 'sjefModal' reference and triggers the onSuccess callback.
	 */
	onSubmitSuccessCallback = () => {
		this.refs['sjefModal'].close();
		this.onSuccess();
	};

	/**
	 * View function for Mithril
	 * @param {Object} vnode - Mithril virtual node
	 * @returns {Object} Mithril virtual DOM tree
	 */
	view() {
		return m(sjefModal, {
			classes: ['reportRecon'],
			setRef: (instance) => (this.refs['sjefModal'] = instance),
			onOpen: this.onOpen,
			onSubmit: this.onSubmitForm,
			buttonLabel: _t('_.buttons.new'),
			buttonLayout: 'filled',
			buttonConfirm: _t('_.fields.create'),
			modalTitle: _t('reconciliation.new'),
			modalSize: 'md',
			modalContent: m(
				Form,
				{
					onsubmit: () => {
						return false;
					},
				},
				[
					m(sjefInput, {
						field: 'name',
						label: 'Name',
						intent: this.formErrors.name ? 'negative' : 'none',
						value: this.form.name,
						onChange: (newValue) => {
							this.setValue({ field: 'name', value: newValue });
						},
					}),
					// }
					// m(FormGroup, {
					// 	content: [

					// 		m(FormLabel, { for: 'name' }, 'Name'),
					// 		m(Input, {
					// 			id: 'name',
					// 			value: this.form.name,
					// 			intent: this.formErrors.name ? 'negative' : 'none',
					// 			type: 'text',
					// 			onchange: (ev) => {
					// 				this.setValue({ field: 'name', value: ev.currentTarget.value });
					// 			},
					// 		}),
					// 		m('.error', this.formErrors['name']),
					// 	],
					// }),
					m(sjefSelectAsync, {
						onSelect: (data) => this.setValue(data, 'string', this.onSelectProjectId),
						field: 'projectId',
						label: 'Project',
						endpoint: '/operations/projects',
						intent: this.formErrors.projectId ? 'negative' : 'none',
					}),
					m(
						'.columns-2',
						m(sjefInput, {
							field: 'metadataStart',
							label: _t('_.fields.start'),
							disabled: !this.form.projectId,
							type: 'datetime-local',
							value: this.form.metadataStart, // todo UTC (formatUTC)
							onChange: (newValue) => {
								this.setValue({ field: 'metadataStart', value: newValue });
							},
						}),
						m(sjefInput, {
							field: 'metadataEnd',
							label: _t('_.fields.end'),
							disabled: !this.form.projectId,
							value: this.form.metadataEnd, // todo UTC (formatUTC)
							type: 'datetime-local',
							onChange: (newValue) => {
								this.setValue({ field: 'metadataEnd', value: newValue });
							},
						})
					),
				]
			),
		});
	}
}

export default modalReportsCreateProjectCompletion;
