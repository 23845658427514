{
	"name": "sjef-kantoor-frontend",
	"version": "1.1.3 Beta",
	"description": "",
	"scripts": {
		"test": "eslint ./src/index.js",
		"local": "API_ROOT=http://localhost:3000 parcel serve ./src/index.html --dist-dir ./.dev/ --no-cache --no-autoinstall",
		"dev": "API_ROOT=https://development.sjef.dev parcel serve ./src/index.html --dist-dir ./.dev/ --no-cache --no-autoinstall",
		"prebuild:live": "rm -rf ./dist/*",
		"prebuild:dev": "rm -rf ./dev/*",
		"build:dev": "API_ROOT=https://development.sjef.dev BUILD_ENV=development parcel build ./src/index.html --public-url ./ --dist-dir ./dev/ --no-cache",
		"build:live": "API_ROOT=https://live.sjef.app BUILD_ENV=production parcel build ./src/index.html --public-url ./ --no-source-maps --dist-dir ./dist/ --no-cache",
		"locales": "node ./src/build_scripts/sync_locales.js"
	},
	"eslintIgnore": [
		"./dist/kantoor/vender/*.js"
	],
	"keywords": [],
	"browserslist": "> 0.5%, last 2 versions, not dead",
	"author": "Jeroen Diderik <info@alphamedialab.com>",
	"license": "ISC",
	"devDependencies": {
		"@parcel/optimizer-image": "^2.9.3",
		"@parcel/transformer-js": "^2.9.3",
		"@parcel/transformer-sass": "^2.8.3",
		"@types/mithril": "^2.0.11",
		"eslint": "^8.27.0",
		"eslint-config-prettier": "^9.1.0",
		"eslint-plugin-prettier": "^2.7.0",
		"i18next-json-sync": "^3.1.2",
		"parcel": "^2.8.3",
		"process": "^0.11.10",
		"sass": "^1.55.0"
	},
	"dependencies": {
		"@fullcalendar/core": "^6.1.8",
		"@fullcalendar/daygrid": "^6.1.8",
		"@fullcalendar/list": "^6.1.8",
		"@fullcalendar/timegrid": "^6.1.8",
		"@sjefapp/sjef-validation": "^1.0.1",
		"@sjefapp/sjeficons": "^0.0.10",
		"ajv": "^8.12.0",
		"ajv-errors": "^3.0.0",
		"ajv-formats": "^2.1.1",
		"ajv-keywords": "^5.1.0",
		"apexcharts": "^3.49.0",
		"chart.js": "^4.4.0",
		"construct-ui": "^0.3.4",
		"date.js": "^0.3.3",
		"driver.js": "^1.1.0",
		"dropzone": "^6.0.0-beta.2",
		"fullcalendar": "^6.1.8",
		"html2pdf": "^0.0.11",
		"i18n": "^0.15.1",
		"i18next": "^23.4.4",
		"i18next-scanner": "^4.1.1",
		"lines-logger": "^2.1.2",
		"lodash.camelcase": "^4.3.0",
		"lodash.debounce": "^4.0.8",
		"luxon": "^3.3.0",
		"mithril": "^2.2.2",
		"mithril-hooks": "^0.7.2",
		"moment": "^2.29.4",
		"moment-timezone": "^0.5.43",
		"path-browserify": "^1.0.1",
		"qrcode": "^1.5.3",
		"rapidoc": "^9.3.4",
		"reconnecting-websocket": "^4.4.0",
		"sortablejs": "^1.15.0",
		"swagger-ui-dist": "^5.6.1"
	}
}
