{
    "_": {
        "actions": {
            "save": ""
        },
        "buttons": {
            "activate": "Activer",
            "activateAdyen": "Activer Adyen",
            "applyFilters": "Appliquer les filtres",
            "archive": "Archiver",
            "autoTranslate": "Traduire automatiquement",
            "backToList": "Retourner",
            "cancel": "Annuler",
            "clearFilters": "Effacer les filtres",
            "close": "Fermer",
            "complete": "Compléter",
            "copy": "Copier",
            "create": "Créer",
            "deactivate": "Désactiver",
            "delete": "Supprimer",
            "discard": "Jeter",
            "download": "Télécharger",
            "edit": "Modifier",
            "export": "Exporter",
            "finish": "Finir",
            "generateTickets": "Générer",
            "maintenance": "Entretien",
            "manage": "Gérer",
            "new": "Nouveau",
            "rollback": "Annuler",
            "save": "Sauvegarder",
            "saveAndClose": "Sauvegarder et fermer",
            "setMaintenance": "Définir l'entretien",
            "switch": "Quitter"
        },
        "fields": {
            "${field}": "",
            "account": "Compte",
            "accountId": "ID du compte",
            "active": "Actif",
            "activeInShop": "Actif en magasin",
            "activeKiosk": "Kiosque actif",
            "activePos": "Pos actif",
            "activeWebshop": "Webshop actif",
            "address": "Adresse",
            "address2": "Adresse 2",
            "adyen": {
                "balanceAccounts": "Comptes de solde",
                "businessLineId": "ID de ligne de business",
                "businessLines": "Lignes de business",
                "canDoPayouts": "",
                "canReceiveFromPlatformPayments": "",
                "canReceivePayments": "",
                "capabilities": "Capacités",
                "clientConfig": "",
                "clientMutations": "",
                "merchantId": "ID du marchand",
                "mutations": {
                    "amount": "",
                    "balanceAccount": "",
                    "currency": "",
                    "description": "",
                    "reference": "",
                    "title": "",
                    "type": ""
                },
                "receiveFromBalanceAccount": "Recevoir du compte de solde",
                "receiveFromPlatformPayments": "Recevoir des paiements de plateforme",
                "receivePayments": "Recevoir les paiements",
                "sendToBalanceAccount": "Envoyer aux comptes de solde",
                "sendToTransferInstrument": "Envoyer à l'instrument de transfert",
                "shopperStatement": "Déclaration du client",
                "status": "Statut",
                "storeId": "ID du magasin",
                "stores": "Magasins"
            },
            "adyenCompanyAccount": "Compte de société Adyen",
            "adyenEcomStoreId": "ID du magasin AdyenEcom",
            "adyenEcomStoreName": "Nom du magasin AdyenEcom",
            "adyenMerchantAccount": "Compte marchand Adyen",
            "adyenPosStoreId": "ID du magasin AdyenPos",
            "adyenPosStoreName": "Nom du magasin AdyenPos",
            "allowNegativepayment": "Autoriser le paiement négatif",
            "allowOverpayment": "Autoriser le surpaiement",
            "allowSplitpayment": "Autoriser le paiement fractionné",
            "allowZeropayment": "Autoriser le paiement nul",
            "allowed": "Autorisé",
            "allowedAreas": "Zones autorisées",
            "amount": "Montant",
            "amountAvailable": "Montant disponible",
            "amountCommission": "Montant de la commission",
            "amountCommissionMarkup": "Montant de la majoration de la commission",
            "amountSplit": "Montant fractionné",
            "amountTotal": "Montant total",
            "and": "et",
            "apiKey": "Clé API",
            "archived": "Archivé",
            "area": "Zone",
            "areaId": "ID de la zone",
            "askforAccount": "Demander un compte",
            "balanceAccountId": "balanceAccountId",
            "balancedue": "Solde dû",
            "balanceoverview": {
                "EUR": "EUR",
                "GBP": "GBP",
                "USD": ""
            },
            "balances": "Soldes",
            "barcode": "Code-barres",
            "bic": "BIC",
            "branchId": "ID de la branche",
            "cabinetId": "ID du cabinet",
            "cabinetNumber": "Numéro du cabinet",
            "cancelledAt": "Annulé à",
            "cashfunctionId": "ID de la fonction de caisse",
            "categories": "Catégories",
            "city": "Ville",
            "clerk": "Employé",
            "clerkId": "ID de l'employé",
            "client": "Client",
            "clientCodeKantoorLegacy": "Code client KantoorLegacy",
            "clientId": "ID du client",
            "clientKey": "Clé du client",
            "code": "Code",
            "coinAmount": "Montant de la pièce",
            "coinButtonValues": "Valeurs des boutons de pièce",
            "coinLabel": "Étiquette de la pièce",
            "coinSmallestSize": "Taille la plus petite de la pièce",
            "coinValue": "Valeur de la pièce",
            "color": "Couleur",
            "colorKiosk": "Couleur du kiosque",
            "colorPos": "Couleur du Pos",
            "colorWebshop": "Couleur du Webshop",
            "colorWebshopSecondary": "Couleur secondaire du Webshop",
            "companyNumber": "Numéro de l'entreprise",
            "completedAt": "Complété à",
            "countries": "Pays",
            "country": "Pays",
            "courseId": "ID du cours",
            "create": "Créer",
            "createAllocation": "Créer allocation",
            "createdAt": "Créé à",
            "currencies": "Devises",
            "currency": "Devise",
            "customerCity": "Ville du client",
            "customerCountry": "Pays du client",
            "customerEmail": "Email du client",
            "customerHousenumber": "Numéro de maison du client",
            "customerId": "ID du client",
            "customerName": "Nom du client",
            "customerPhoneNumber": "Numéro de téléphone du client",
            "customerPhoneNumberCountryCode": "Code du pays du numéro de téléphone du client",
            "customerStreetname": "Nom de la rue du client",
            "customerZipcode": "Code postal du client",
            "date": "Date",
            "days": "Jours",
            "daysAgo": "",
            "departmentId": "ID du département",
            "description": "Description",
            "descriptionLongTranslations": "Traductions de la description longue",
            "descriptionTranslations": "Traductions de la description",
            "direction": "Direction",
            "discountAmount": "Montant de la remise",
            "discountId": "ID de la remise",
            "displayName": "Nom affiché",
            "displaygroupId": "ID du groupe d'affichage",
            "domestic/foreign": {
                "domestic": "",
                "foreign": ""
            },
            "edit": "Modifier",
            "electronicShelfLabel": "Étiquette de l'étagère électronique",
            "elockersActive": "Elockers actif",
            "elockersType": "Type de Elockers",
            "email": "Email",
            "emailInvoice": "emailInvoice",
            "emailKyc": "emailKyc",
            "enabled": "Activé",
            "end": "",
            "environment": "Environnement",
            "estimated Arrival Time": "Heure d'arrivée estimée",
            "estimatedArrivalTime": "Heure d'arrivée estimée",
            "eventActivateAt": "Activer l'événement à",
            "eventDeactivateAt": "Désactiver l'événement à",
            "eventEnd": "Fin de l'événement",
            "eventLasts": "Durée de l'événement",
            "eventStart": "Début de l'événement",
            "external_identifier": "Identifiant externe",
            "fulfillment": "Exécution",
            "generalledgerAccount": "Compte général du grand livre",
            "hours": "Heures",
            "hubspotId": "ID Hubspot",
            "iban": "IBAN",
            "id": "ID",
            "imgKioskNormal": "Image Kiosque normale",
            "imgKioskThumbnail": "Vignette de l'image Kiosque",
            "imgNormal": "Image normale",
            "imgPosNormal": "Image Pos normale",
            "imgPosThumbnail": "Vignette de l'image Pos",
            "imgSvg": "Image SVG",
            "imgSvgFallbackItem": "Élément de remplacement de l'image SVG",
            "imgThumbnail": "Vignette",
            "imgWebshopNormal": "Image Webshop normale",
            "imgWebshopThumbnail": "Vignette de l'image Webshop",
            "inAt": "Entré à",
            "inBy": "Entré par",
            "industryCode": "Code de l'industrie",
            "invoiceAddress": "Adresse de facturation",
            "invoiceAddress2": "Adresse de facturation 2",
            "invoiceCity": "Ville de facturation",
            "invoiceCountry": "Pays de facturation",
            "invoiceName": "Nom de facturation",
            "invoicePostalCode": "Code postal de facturation",
            "invoiceState": "État de facturation",
            "isBackorderItem": "Est un article en attente",
            "isCashcardItem": "Est un article de carte de caisse",
            "isCoinPayment": "Est un paiement en pièces",
            "isCoins": "Est en pièces",
            "isDefault": "Est par défaut",
            "isHiddenOnReceipt": "Est caché sur le reçu",
            "isOrderItem": "Est un article de commande",
            "isParentLeading": "Est parent principal",
            "isPlu": "Est PLU",
            "isPreferredPayment": "Est un paiement préféré",
            "isStockItem": "Est un article en stock",
            "isTip": "Est un pourboire",
            "isUniqueItem": "Est un article unique",
            "isVoucherPayment": "Est un paiement par bon",
            "isWallet": "Est un portefeuille",
            "isWalletPayment": "Est un paiement par portefeuille",
            "large": "Grand",
            "latitude": "Latitude",
            "legalEntityId": "ID de l'entité juridique",
            "locale": "Longitude",
            "location": "Emplacement",
            "locationId": "ID de l'emplacement",
            "locationName": "Nom de l'emplacement",
            "lockVersion": "Version de verrouillage",
            "lockerId": "ID du casier",
            "lockerMode": "Mode de casier",
            "lockerNumber": "Numéro du casier",
            "lockerSerialNumber": "Numéro de série du casier",
            "lockerType": "Type de casier",
            "logout": "Déconnexion",
            "longitude": "Longitude",
            "manufacturer": "Fabricant",
            "masBasket": "Panier maximum",
            "medium": "Moyen",
            "merchantAccount": "Compte marchand",
            "merchantName": "Nom du marchand",
            "merchantReference": "Référence du marchand",
            "metaBrand": "Marque méta",
            "metaCategory": "Catégorie méta",
            "metaManufacturer": "Fabricant méta",
            "metaName": "Nom méta",
            "metaSubcategory": "Sous-catégorie méta",
            "metaSupplier": "Fournisseur méta",
            "metaUnitOfPacking": "Unité de conditionnement méta",
            "metaUnitOfSale": "Unité de vente méta",
            "metaVolumM1": "Volume méta M1",
            "modeLocker": "Mode casier",
            "modeStartHour": "Heure de début",
            "modeTimeslot": "Créneau horaire",
            "modeValidHours": "Heures valides",
            "model": "Modèle",
            "name": "Nom",
            "nameId": "ID du nom",
            "nameKioskTranslations": "Traductions du nom du kiosque",
            "nameKitchen": "Nom de la cuisine",
            "namePosTranslations": "Traductions du nom du Pos",
            "nameTranslations": "Traductions du nom",
            "nameWebshopTranslations": "Traductions du nom du Webshop",
            "new": "Nouveau",
            "next": "Suivant",
            "noResults": "Aucun résultat",
            "notes": "Notes",
            "open": "Ouvrir",
            "openAll": "Ouvrir tout",
            "outAt": "Sorti à",
            "outBy": "Sorti par",
            "parentId": "ID du parent",
            "parent_id": "ID du parent",
            "paymentMethods": "Méthodes de paiement",
            "paymentmethod": "Méthode de paiement",
            "paymentmethodId": "ID de la méthode de paiement",
            "paymentprofileId": "ID du profil de paiement",
            "paymentserviceproviderId": "ID du fournisseur de service de paiement",
            "paymentserviceproviderMethod": "Méthode du fournisseur de service de paiement",
            "payoutId": "payoutId",
            "percentage": "Pourcentage",
            "periodType": "periodType",
            "periodValue": "periodValue",
            "phoneNumber": "Numéro de téléphone",
            "phoneNumberCountryCode": "Code du pays du numéro de téléphone",
            "pincode": "Code PIN",
            "placeholders": {
                "active": "Actif",
                "address": "",
                "address2": "",
                "adyenBusinessLines": "",
                "city": "",
                "companyNumber": "Numéro de l'entreprise",
                "country": "",
                "email": "Email",
                "emailInvoice": "Email",
                "emailKyc": "Email",
                "hubspotId": "ID Hubspot",
                "id": "ID",
                "industryCode": "Code de l'industrie",
                "invoiceAddress": "Adresse de facturation",
                "invoiceAddress2": "Adresse de facturation 2",
                "invoiceCity": "Ville de facturation",
                "invoiceCountry": "Pays de facturation",
                "invoiceName": "Nom de facturation",
                "invoicePostalCode": "Code postal de facturation",
                "invoiceState": "État de facturation",
                "merchantId": "",
                "name": "Nom",
                "nameId": "ID du nom",
                "phoneNumber": "Numéro de téléphone",
                "phoneNumberCountryCode": "+ 316",
                "planId": "ID du plan",
                "region": "Region",
                "role": "",
                "shopperStatement": "Shopper statement",
                "taxNumber": "Numéro de taxe",
                "uuid": "UUID",
                "visitAddress": "Adresse de visite",
                "visitAddress2": "Adresse de visite 2",
                "visitCity": "Ville de visite",
                "visitCountry": "Pays de visite",
                "visitName": "Nom de visite",
                "visitPostalCode": "Code postal de visite",
                "visitState": "État de visite",
                "wefactId": "ID WeFact",
                "zipcode": ""
            },
            "planId": "ID du plan",
            "posAdminOnly": "Pos seulement admin",
            "posColor": "Couleur du Pos",
            "posOpendrawer": "Ouvrir le tiroir du Pos",
            "posOpenprice": "Prix ouvert du Pos",
            "posPrintreceipt": "Imprimer le reçu du Pos",
            "posSearchable": "Pos recherchable",
            "posVisible": "Pos visible",
            "position": "Position",
            "positionKiosk": "Position du kiosque",
            "positionKitchen": "Position de la cuisine",
            "positionPos": "Position du Pos",
            "positionWebshop": "Position du Webshop",
            "preprint": "Pré-imprimé",
            "previous": "Précédent",
            "price": "Prix",
            "priceCosts": "Coûts de prix",
            "priceRetail": "Prix de détail",
            "problems": "Problèmes",
            "processed At": "Traité à",
            "processedAt": "Traité à",
            "profileId": "ID du profil",
            "projectEnd": "Fin du projet",
            "projectName": "Nom du projet",
            "projectStart": "Début du projet",
            "pspEftReceipt": "Imprimer le reçu EFT",
            "pspMethod": "Méthode PSP",
            "pspName": "Nom PSP",
            "pspPayload": "Charge utile PSP",
            "pspResponse": "Réponse PSP",
            "pspVariant": "Variante PSP",
            "quantityMaximum": "Quantité maximale",
            "quantityMinimum": "Quantité minimale",
            "reason": "Raison",
            "redirectUrl": "URL de redirection",
            "reference": "Référence",
            "region": "region",
            "requested": "Demandé",
            "requiredAccount": "Compte requis",
            "requiredBookingnumber": "Numéro de réservation requis",
            "reserve": "Réserver",
            "saleschannels": "Canaux de vente",
            "selected": "Sélectionné",
            "sequence": "sequence",
            "serialNumber": "Numéro de série",
            "serialnumber": "Numéro de série",
            "service": "Service",
            "settlementId": "settlementId",
            "shopSelection": "Sélection de magasin",
            "shopperStatement": "shopperStatement",
            "sjefId": "ID Sjef",
            "slug": "Slug",
            "source": "Source",
            "stack": "Pile",
            "start": "",
            "status": "Statut",
            "statusAssets": "Statut des actifs",
            "subtotal": "Sous-total",
            "subtotalId": "ID du sous-total",
            "supplier": "Fournisseur",
            "table": "Table",
            "tableId": "ID de la table",
            "taxNumber": "Numéro de taxe",
            "taxes": "Taxes",
            "taxrateId": "ID du taux de taxe",
            "terminalSequence": "Séquence du terminal",
            "ticketeer": "Ticketeer",
            "tickets_c": "Couleur",
            "tickets_lid": "ID du casier",
            "tickets_lno": "Numéro du casier",
            "tickets_lpin": "PIN du casier",
            "tickets_lt": "Type",
            "tickets_tid": "ID du billet",
            "tickets_w": "Vague",
            "timeWindow": "Fenêtre de temps",
            "timestamp": "Horodatage",
            "timezone": "Fuseau horaire",
            "today": "",
            "token": "Jeton",
            "tokenAmount": "Montant du jeton",
            "tokenDescription": "Description du jeton",
            "total": "Total",
            "totalCoins": "Total des pièces",
            "translations": "Traductions",
            "type": "Type",
            "uniqueItemType": "Type d'article unique",
            "uniqueItemTypePrintjobs": "Type d'article unique pour les travaux d'impression",
            "updatedAt": "Mis à jour à",
            "uuid": "UUID",
            "verificationStatus": "Statut de vérification",
            "videoWebshop": "Vidéo Webshop",
            "viewWebshop": "Vue Webshop",
            "visitAddress": "Adresse de visite",
            "visitAddress2": "Adresse de visite 2",
            "visitCity": "Ville de visite",
            "visitCountry": "Pays de visite",
            "visitName": "Nom de visite",
            "visitPostalCode": "Code postal de visite",
            "visitState": "État de visite",
            "wave": "Vague",
            "webdata": "",
            "wefactId": "ID WeFact",
            "yesterday": "",
            "zipcode": "Code postal"
        },
        "filterTabs": {
            "defaultTabs": "Par défaut",
            "savedTabs": "Enregistré"
        },
        "labels": {
            "active": "Actif",
            "allocations": "Allocations",
            "area": "Zone",
            "areaReport": "Rapport de zone",
            "cabinets": "Cabinets",
            "checklist": "Liste de contrôle",
            "createAllocation": "Créer une allocation",
            "createArea": "Créer une zone",
            "createClientSubtitle": "Remplissez les champs requis,",
            "createEvent": "Créer un événement",
            "createLocation": "Créer un emplacement",
            "deleteArea": "Supprimer la zone",
            "deleteLocation": "Supprimer les emplacements",
            "editAllocation": "Modifier l'allocation",
            "editClientSubtitle": "Modifier les détails de votre client",
            "editLocation": "Modifier l'emplacement",
            "event": "Événement",
            "eventEnd": "Fin de l'événement",
            "eventName": "Nom de l'événement",
            "eventStart": "Début de l'événement",
            "eventTitle": "",
            "eventType": "",
            "filters": "Filtres",
            "generalInfo": "Informations générales",
            "invite": "",
            "locationName": "",
            "locations": "Emplacements",
            "logout": {
                "confirmContent": "Êtes-vous sûr de vouloir vous déconnecter?",
                "confirmTitle": "Confirmer la déconnexion"
            },
            "maintenance": "Entretien",
            "maintenanceCancel": "Annuler l'entretien",
            "maintenanceCancelContent": "Êtes-vous sûr de vouloir annuler l'entretien?",
            "maintenanceComplete": "Terminer l'entretien",
            "maintenanceCompleteContent": "Êtes-vous sûr de vouloir terminer l'entretien?",
            "noAreas": "Aucune zone",
            "openOrganisation": "Ouvrir l'organisation",
            "report": "Rapport",
            "switch": {
                "confirmContent": "Êtes-vous sûr de vouloir quitter {{name}}?",
                "confirmTitle": "Changer"
            },
            "tickets": "Billets",
            "ticketsGenerated": "Billets générés"
        },
        "messages": {
            "notAvailable": ""
        },
        "modals": {
            "cancelDeleteButton": "Annuler",
            "confirmCancelButton": "Confirmer",
            "confirmCancelContent": "Êtes-vous sûr de vouloir annuler?",
            "confirmCancelTitle": "Confirmer l'annulation",
            "confirmDeleteButton": "Supprimer",
            "confirmDeleteContent": "Êtes-vous sûr de vouloir supprimer?",
            "confirmDeleteTitle": "Confirmer la suppression",
            "reload": {
                "description": "Voulez-vous recharger?",
                "title": "Recharger la page"
            }
        },
        "months": {
            "april": "",
            "august": "",
            "december": "",
            "february": "",
            "january": "",
            "july": "",
            "june": "",
            "march": "",
            "may": "",
            "november": "",
            "october": "",
            "september": ""
        },
        "records": {
            "noRecordsAvailable": "Aucun enregistrement disponible"
        },
        "search": {
            "search": "Rechercher..."
        },
        "singular": {
            "accounts": "Compte",
            "administrations": "Administration",
            "areas": "Zone",
            "assets": "Actif",
            "balance_mutations": "",
            "branches": "Branche",
            "cabinets": "Cabinet",
            "cashfunctions": "Fonction de caisse",
            "categories": "Catégorie",
            "clerks": "Employé",
            "clients": "Client",
            "courses": "Cours",
            "departments": "Département",
            "displaygroups": "Groupe d'affichage",
            "events": "Événement",
            "items": "Article",
            "locations": "Emplacement",
            "lockerEvents": "Événement de casier",
            "lockers": "Casier",
            "maintenance": "Entretien",
            "menus": "Menu",
            "modifiers": "Modificateur",
            "organisations": "Organisation",
            "paymentmethods": "Méthode de paiement",
            "paymentprofiles": "Profil de paiement",
            "payments": "Paiement",
            "paymentserviceproviders": "Fournisseur de services de paiement",
            "payouts": "Paiement",
            "projects": "Projet",
            "psps": "PSP",
            "settlements": "Règlement",
            "subtotals": "Sous-total",
            "suppliers": "Fournisseur",
            "taxrates": "Taux de taxe",
            "tickets": "Billet",
            "transactions": "Transaction"
        },
        "th": {
            "name": "Nom"
        }
    },
    "_fields": {
        "name": "Nom"
    },
    "clients": {
        "clientConfig": {
            "balanceAccountId": "",
            "merchantAccount": "",
            "modal": {
                "edit": {
                    "button": "",
                    "title": ""
                },
                "new": {
                    "button": "",
                    "title": ""
                }
            },
            "paymentConfigId": "",
            "region": "",
            "storeId": "",
            "type": ""
        },
        "clientInvite": {
            "actions": {
                "block": "",
                "resend": "",
                "title": ""
            },
            "createdAt": "",
            "createdBy": "",
            "description": "",
            "email": "",
            "expiresAt": "",
            "status": "",
            "title": "",
            "toast": {
                "blocked": "",
                "sent": ""
            }
        },
        "fields": {
            "hubspotId": "ID HubSpot",
            "nameId": "ID du nom",
            "wefactId": "ID WeFact"
        },
        "invoices": {
            "subtitle": "",
            "table": {
                "th": {
                    "amountExcl": "Montant excl.",
                    "amountIncl": "Montant incl.",
                    "amountOutstanding": "Montant dû",
                    "code": "Code",
                    "date": "Date",
                    "status": "Status"
                }
            },
            "title": ""
        },
        "pricequotes": {
            "subtitle": "",
            "title": ""
        },
        "tabs": {
            "adyen": "Adyen",
            "details": "Détails",
            "invoices": "Factures"
        }
    },
    "countries": {
        "AD": "Andorre",
        "AE": "Émirats Arabes Unis",
        "AF": "Afghanistan",
        "AG": "Antigua-et-Barbuda",
        "AI": "Anguilla",
        "AL": "Albanie",
        "AM": "Arménie",
        "AN": "Antilles néerlandaises",
        "AO": "Angola",
        "AQ": "Antarctique",
        "AR": "Argentine",
        "AS": "Samoa américaines",
        "AT": "Autriche",
        "AU": "Australie",
        "AW": "Aruba",
        "AX": "Îles Åland",
        "AZ": "Azerbaïdjan",
        "BA": "Bosnie-Herzégovine",
        "BB": "Barbade",
        "BD": "Bangladesh",
        "BE": "Belgique",
        "BF": "Burkina Faso",
        "BG": "Bulgarie",
        "BH": "Bahreïn",
        "BI": "Burundi",
        "BJ": "Bénin",
        "BM": "Bermudes",
        "BN": "Brunei Darussalam",
        "BO": "Bolivie",
        "BR": "Brésil",
        "BS": "Bahamas",
        "BT": "Bhoutan",
        "BV": "Île Bouvet",
        "BW": "Botswana",
        "BY": "Biélorussie",
        "BZ": "Belize",
        "CA": "Canada",
        "CC": "Îles Cocos (Keeling)",
        "CD": "République Démocratique du Congo",
        "CF": "République Centrafricaine",
        "CG": "Congo",
        "CH": "Suisse",
        "CK": "Îles Cook",
        "CL": "Chili",
        "CM": "Cameroun",
        "CN": "Chine",
        "CO": "Colombie",
        "CR": "Costa Rica",
        "CS": "Serbie-et-Monténégro",
        "CU": "Cuba",
        "CV": "Cap-Vert",
        "CX": "Île Christmas",
        "CY": "Chypre",
        "CZ": "République Tchèque",
        "DE": "Allemagne",
        "DJ": "Djibouti",
        "DK": "Danemark",
        "DM": "Dominique",
        "DO": "République Dominicaine",
        "DZ": "Algérie",
        "EC": "Équateur",
        "EE": "Estonie",
        "EG": "Égypte",
        "EH": "Sahara Occidental",
        "ER": "Érythrée",
        "ES": "Espagne",
        "ET": "Éthiopie",
        "FI": "Finlande",
        "FJ": "Fidji",
        "FK": "Îles Malouines",
        "FM": "États Fédérés de Micronésie",
        "FO": "Îles Féroé",
        "FR": "France",
        "GA": "Gabon",
        "GB": "Royaume-Uni",
        "GD": "Grenade",
        "GE": "Géorgie",
        "GF": "Guyane Française",
        "GG": "Guernesey",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GL": "Groenland",
        "GM": "Gambie",
        "GN": "Guinée",
        "GP": "Guadeloupe",
        "GQ": "Guinée Équatoriale",
        "GR": "Grèce",
        "GS": "Géorgie du Sud et les îles Sandwich du Sud",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guinée-Bissau",
        "GY": "Guyana",
        "HK": "Hong Kong",
        "HM": "Îles Heard et McDonald",
        "HN": "Honduras",
        "HR": "Croatie",
        "HT": "Haïti",
        "HU": "Hongrie",
        "ID": "Indonésie",
        "IE": "Irlande",
        "IL": "Israël",
        "IM": "Île de Man",
        "IN": "Inde",
        "IO": "Territoire Britannique de l'océan Indien",
        "IQ": "Irak",
        "IR": "République Islamique d'Iran",
        "IS": "Islande",
        "IT": "Italie",
        "JE": "Jersey",
        "JM": "Jamaïque",
        "JO": "Jordanie",
        "JP": "Japon",
        "KE": "Kenya",
        "KG": "Kirghizistan",
        "KH": "Cambodge",
        "KI": "Kiribati",
        "KM": "Comores",
        "KN": "Saint-Kitts-et-Nevis",
        "KR": "République de Corée",
        "KW": "Koweït",
        "KY": "Îles Caïmans",
        "KZ": "Kazakhstan",
        "LB": "Liban",
        "LC": "Sainte-Lucie",
        "LI": "Liechtenstein",
        "LK": "Sri Lanka",
        "LR": "Libéria",
        "LS": "Lesotho",
        "LT": "Lituanie",
        "LU": "Luxembourg",
        "LV": "Lettonie",
        "LY": "Libye",
        "MA": "Maroc",
        "MC": "Monaco",
        "MD": "Moldavie",
        "MG": "Madagascar",
        "MH": "Îles Marshall",
        "MK": "Macédoine du Nord",
        "ML": "Mali",
        "MM": "Myanmar",
        "MN": "Mongolie",
        "MO": "Macao",
        "MP": "Îles Mariannes du Nord",
        "MQ": "Martinique",
        "MR": "Mauritanie",
        "MS": "Montserrat",
        "MT": "Malte",
        "MU": "Maurice",
        "MV": "Maldives",
        "MW": "Malawi",
        "MX": "Mexique",
        "MY": "Malaisie",
        "MZ": "Mozambique",
        "NA": "Namibie",
        "NC": "Nouvelle-Calédonie",
        "NE": "Niger",
        "NF": "Île Norfolk",
        "NG": "Nigeria",
        "NI": "Nicaragua",
        "NL": "Pays-Bas",
        "NO": "Norvège",
        "NP": "Népal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "Nouvelle-Zélande",
        "OM": "Oman",
        "PA": "Panama",
        "PE": "Pérou",
        "PF": "Polynésie Française",
        "PG": "Papouasie-Nouvelle-Guinée",
        "PH": "Philippines",
        "PK": "Pakistan",
        "PL": "Pologne",
        "PM": "Saint-Pierre-et-Miquelon",
        "PN": "Pitcairn",
        "PR": "Porto Rico",
        "PS": "Palestine, État de",
        "PT": "Portugal",
        "PW": "Palaos",
        "PY": "Paraguay",
        "QA": "Qatar",
        "RE": "Réunion",
        "RO": "Roumanie",
        "RU": "Fédération de Russie",
        "RW": "Rwanda",
        "SA": "Arabie Saoudite",
        "SB": "Îles Salomon",
        "SC": "Seychelles",
        "SD": "Soudan",
        "SE": "Suède",
        "SG": "Singapour",
        "SH": "Sainte-Hélène, Ascension et Tristan da Cunha",
        "SI": "Slovénie",
        "SJ": "Svalbard et Jan Mayen",
        "SK": "Slovaquie",
        "SL": "Sierra Leone",
        "SM": "Saint-Marin",
        "SN": "Sénégal",
        "SO": "Somalie",
        "SR": "Suriname",
        "ST": "Sao Tomé-et-Principe",
        "SV": "El Salvador",
        "SY": "République Arabe Syrienne",
        "SZ": "Eswatini",
        "TC": "Îles Turques-et-Caïques",
        "TD": "Tchad",
        "TF": "Terres Australes Françaises",
        "TG": "Togo",
        "TH": "Thaïlande",
        "TJ": "Tadjikistan",
        "TK": "Tokelau",
        "TL": "Timor-Leste",
        "TM": "Turkménistan",
        "TN": "Tunisie",
        "TO": "Tonga",
        "TR": "Turquie",
        "TT": "Trinité-et-Tobago",
        "TV": "Tuvalu",
        "TW": "Taïwan",
        "TZ": "République-Unie de Tanzanie",
        "UA": "Ukraine",
        "UG": "Ouganda",
        "UM": "Îles Mineures Éloignées des États-Unis",
        "US": "États-Unis",
        "UY": "Uruguay",
        "UZ": "Ouzbékistan",
        "VA": "Saint-Siège (État de la Cité du Vatican)",
        "VC": "Saint-Vincent-et-les-Grenadines",
        "VE": "République Bolivarienne du Venezuela",
        "VG": "Îles Vierges Britanniques",
        "VI": "Îles Vierges des États-Unis",
        "VN": "Viêt Nam",
        "VU": "Vanuatu",
        "WF": "Wallis-et-Futuna",
        "WS": "Samoa",
        "YE": "Yémen",
        "YT": "Mayotte",
        "ZA": "Afrique du Sud",
        "ZM": "Zambie",
        "ZW": ""
    },
    "modules": {
        "accounts": {
            "tabs": {
                "details": "Détails",
                "example": "Exemple"
            },
            "title": ""
        },
        "administrations": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "areas": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "assets": {
            "tabs": {
                "assets": "",
                "details": "Détails",
                "list": ""
            },
            "title": ""
        },
        "balance_mutations": {
            "tabs": {
                "details": ""
            },
            "title": ""
        },
        "balanceoverview": {
            "account_balance": "Solde du compte",
            "continue_modal": {
                "button": "",
                "description": ""
            },
            "edit_balance_account": "Modifier le compte de solde",
            "intro": {
                "paragraph1": "",
                "paragraph2": "",
                "paragraph3": "",
                "subtitle": "",
                "title": ""
            },
            "recent_mutations": "",
            "recent_payouts": "Paiements récents",
            "redirect_modal": {
                "description": ""
            },
            "widgets": {
                "authorised": "",
                "availableForPayout": "",
                "invoiced": "",
                "payout": "Paiement",
                "sentForSettle": "",
                "settled": ""
            }
        },
        "branches": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "cabinets": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "cashfunctions": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "categories": {
            "tabs": {
                "details": "Détails",
                "locations": "Emplacements",
                "profile": "Profil"
            },
            "title": ""
        },
        "clerks": {
            "tabs": {
                "allowedAreas": "Zones autorisées",
                "details": "Détails"
            },
            "title": ""
        },
        "clients": {
            "tabs": {
                "adyen": "Adyen",
                "details": "Détails",
                "invites": "",
                "paymentScheme": "Schéma de paiement",
                "wefactInvoices": "Factures WeFact",
                "wefactPricequotes": "Devis WeFact"
            },
            "title": ""
        },
        "courses": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "departments": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "displaygroups": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "events": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "home": {
            "balanceplatform": {
                "button": "",
                "paragraph1": "",
                "paragraph2": "",
                "paragraph3": "",
                "subtitle": ""
            },
            "greeting": {
                "goodafternoon": "",
                "goodevening": "",
                "goodmorning": ""
            },
            "intro": {
                "paragraph1": "",
                "paragraph2": ""
            },
            "transactions": {
                "title": ""
            },
            "turnover": {
                "title": ""
            }
        },
        "items": {
            "tabs": {
                "categories": "Catégories",
                "details": "Détails",
                "modifiers": "Modificateurs"
            },
            "title": ""
        },
        "locations": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "lockerEvents": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "lockers": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "maintenance": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "menus": {
            "tabs": {
                "details": "Détails",
                "items": "Articles"
            },
            "title": ""
        },
        "modifiers": {
            "tabs": {
                "details": "Détails",
                "items": "Articles"
            },
            "title": ""
        },
        "organisations": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "paymentmethods": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "paymentprofiles": {
            "tabs": {
                "details": "Détails",
                "paymentMethods": "Méthodes de paiement"
            },
            "title": ""
        },
        "payments": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "paymentserviceproviders": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "payouts": {
            "title": ""
        },
        "projects": {
            "tabs": {
                "assets": "Actifs",
                "details": "Détails",
                "payments": "Paiements"
            },
            "title": ""
        },
        "psps": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "settlements": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "subtotals": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "suppliers": {
            "tabs": {
                "details": "Détails",
                "example": "Exemple"
            },
            "title": ""
        },
        "taxrates": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "tickets": {
            "tabs": {
                "details": "Détails"
            },
            "title": ""
        },
        "transactions": {
            "tabs": {
                "details": "Détails",
                "lines&payments": "Lignes et paiements"
            },
            "title": ""
        }
    },
    "reconciliation": {
        "new": "Nouvelle réconciliation"
    },
    "schema": {
        "tiers": {
            "quantity": "Seuil"
        }
    }
}