import m from 'mithril';
import icons from '../../lib/icons';
import sjefInput from '../../views/components/sjefInput';
import sjefIconPlus from '@sjefapp/sjeficons/icons/sjefIconPlus';
import sjefSelect from '../../views/components/sjefSelect';

const iconLookup = {
	ideal: icons.idealIcon,
	bcmc: icons.bcmcIcon,
	maestro: icons.maestroIcon,
	applepay: icons.applePayIcon,
	mc: icons.mastercardIcon,
	diners: icons.dinersIcon,
	discover: icons.discoverIcon,
	jcb: icons.jcbIcon,
	visa: icons.visaIcon,
	amex: icons.amexIcon,
	bc: icons.amexIcon
};
class vwPaymentConfigs {
	records = [];
	paymentTypes = {};
	activeTab = 'domestic';
	activeTier = 0;
	openPaymentConfigState = {}
	async oninit() {
		await this.fetchData();

		const newStructure = {};
		Object.keys(this.records[0].tiers[0].fees.foreign).forEach(paymentType => {
			newStructure[paymentType] = { tiers: [] };
		});

		this.records[0].tiers.forEach(tier => {
			const threshold = tier.threshold;
			Object.keys(tier.fees.foreign).forEach(paymentType => {
				const tierStructure = { threshold, foreign: { debit: {}, credit: {} }, domestic: { debit: {}, credit: {} } };
				['foreign', 'domestic'].forEach(location => {
					['debit', 'credit'].forEach(cardType => {
						Object.keys(tier.fees[location][paymentType][cardType]).forEach(level => {
							if (!tierStructure[location][cardType][level]) {
								tierStructure[location][cardType][level] = {};
							}
							tierStructure[location][cardType][level] = {
								...tier.fees[location][paymentType][cardType][level]
							};
						});
					});
				});
				newStructure[paymentType].tiers.push(tierStructure);
			});
		});

		this.paymentTypes = newStructure;
	}

	getIcon(type) {
		if (iconLookup.hasOwnProperty(type)) {
			return m(iconLookup[type]);
		}
		return null;
	}

	fetchData = async () => {
		const response = await app.get('/clients/paymentConfigs');
		this.records = response.data;
	};

	renderFee = (title, fee) => {
		if (!fee) return;
		return m('div', { style: { backgroundColor: 'white', borderRadius: '1rem', border: '1px solid', borderRadius: '1rem', padding: '1rem', marginBottom: '1rem', width: '50%', marginRight: '1rem' } }, [
			m('div', title),
			m('div', { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1rem' } }, [
				m(sjefInput, { type: 'number', value: fee.fixed, label: 'Fixed', onchange: (e) => { fee.fixed = e.target.value } }),
				m(sjefInput, { type: 'number', value: fee.variable, label: 'Variable', onchange: (e) => { fee.fixed = e.target.value } }),
			])]
		)
	}

	renderRow(key, { tiers }) {
		return m('div', [
			m('div', { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-around', borderTop: '1px solid rgb(234 232 232)', paddingTop: '1rem', paddingBottom: '1rem' } }, [
				m('div', { style: { display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '150px' } }, [
					m('.icon', { style: { '--font-size': '50px' } }, this.getIcon(key)),
					m('h2', { style: { margin: '0', marginLeft: '1rem' } }, key.toUpperCase())
				]),
				m('div', { style: { minWidth: '150px' } }, m('div', { style: { display: 'flex', flexDirection: 'column' } }, [
					tiers[0] ? this.renderTier(key, tiers[0], 0) : "--"
				])),
				m('div', { style: { minWidth: '150px' } }, m('div', { style: { display: 'flex', flexDirection: 'column' } }, [
					tiers[1] ? this.renderTier(key, tiers[1], 1) : "--"
				])),
				m('div', { style: { minWidth: '150px' } }, m('div', { style: { display: 'flex', flexDirection: 'column' } }, [
					tiers[2] ? this.renderTier(key, tiers[2], 2) : "--"
				])),
				m('div', { style: { minWidth: '150px' } }, m('div', { style: { display: 'flex', flexDirection: 'column' } }, [
					tiers[3] ? this.renderTier(key, tiers[3], 3) : "--"
				])),
			])
		])
	}

	renderTier = (key, tier, index) => {
		return [
			m('div', { style: { display: 'flex', flexDirection: 'row' } }, [
				m('a', {
					onclick: () => {
						this.openPaymentConfigState[key] = !this.openPaymentConfigState[key];
						this.activeTier = index;
					}
				}, `Tier ${index + 1}`),
			]),
			m('div', `Threshold: ${tier.threshold}`),

		];
	}

	renderCollapsable = (key, { tiers }) => {
		return m('div', {
			style: {
				flexDirection: 'column',
				marginBottom: '1.5rem',
				borderLeft: '4px solid green',
				width: '100%',
				backgroundColor: 'rgba(247, 245, 245, 0.3)',
				display: this.openPaymentConfigState[key] ? 'flex' : 'none',

			}
		}, [
			m('div', { style: { paddingLeft: '1.5rem', paddingTop: '1rem' } }, [
				m('div', { style: { textTransform: 'capitalize' } }, this.activeTab),
				m('h1', `Tier  ${this.activeTier + 1}`),
			]),

			[1, 2, 3, 4].map((level) => {
				return [
					m('div', {
						style: {
							backgroundColor: level % 2 === 0 ? 'rgba(247, 245, 245, 0.3)' : 'white', padding: '1.5rem',
						}
					}, [
						m('h3', `Level ${level}`),
						m('div', { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } }, [
							this.renderFee('Debit', tiers[this.activeTier][this.activeTab].debit[level]),
							this.renderFee('Credit', tiers[this.activeTier][this.activeTab].credit[level])
						])
					])
				];
			}),
		])

	}


	view() {
		return this.records && this.records.length > 0 &&
			this.records
				.map((record) => {
					return m('div', { style: { display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '2rem', borderRadius: '1rem' } }, [
						m('div', [
							// m('div', {}, record.name),
							// m('div', {}, record.method),
							// m(sjefInput, { label: 'Taxrate', value: record.taxrate, readonly: true }),
							// m(sjefInput, { label: 'Threshold Type', value: record.thresholdType, readonly: true }),
							// m(sjefInput, { label: 'Threshold Max Amount', value: record.thresholdMaxAmount, readonly: true }),

							// _t('_.fields.domestic/foreign.domestic'),
							// _t('_.fields.domestic/foreign.foreign'),
							m(sjefSelect, {
								prefix: 'domestic/foreign',
								activeOption: this.activeTab,
								options: ['domestic', 'foreign'],
								onChange: (selectedOption) => {
									this.activeTab = selectedOption
									m.redraw();
								},
							}),
						]),
						m('div', { style: { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'left', marginLeft: '3rem', height: '50px' } }, [
							m('h1', { style: { minWidth: '150px' } }, 'Method'),
						]),
						Object.keys(this.paymentTypes).map((paymentType) => {
							return [
								this.renderRow(paymentType, this.paymentTypes[paymentType]),
								this.renderCollapsable(paymentType, this.paymentTypes[paymentType])
							];
						}),
					]);
				})

	}
}

export default vwPaymentConfigs;

