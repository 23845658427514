import m from 'mithril';
import { Dialog, FormGroup, FormLabel, Input } from 'construct-ui';
import sjefButton from '../components/sjefButton';
import { _t } from '../../lib/i18n';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';

/**
 * @class modalFormUnsaved
 * @classdesc Class representing a modal for unsaved forms
 */
class modalFormUnsaved {
	/**
	 * @property {boolean} isOpen - Indicates if the modal is open
	 */
	isOpen = false;

	/**
	 * Constructor to initialize the modal
	 * @param {Object} vnode - Mithril virtual node
	 */
	constructor(vnode) {
		vnode.attrs.setRef(this);
	}

	/**
	 * Opens the modal
	 */
	open() {
		this.isOpen = true;
	}

	/**
	 * Closes the modal
	 */
	close() {
		this.isOpen = false;
	}

	/**
	 * View function for Mithril
	 * @param {Object} vnode - Mithril virtual node
	 * @returns {Object} Mithril virtual DOM tree
	 */
	view(vnode) {
		const {
			attrs: { name = '', width = '', prefix = '', form = '_', formDirtyFields = [], title, content, onclick = void 0, ...htmlAttrs },
		} = vnode;

		const fieldSlug = (slug) => {
			const fieldSlug = prefix + '.fields.' + slug;
			const translation = _t(fieldSlug);

			// if (typeof translation === 'object') {
			// 	Object.keys(translation).forEach(key => {
			// 		console.log(`translation[${key}]:`, translation[key]);
			// 	});
			// }

			return translation;
		};

		return m(Dialog, {
			isOpen: this.isOpen,
			title: title || 'Form has changes',
			class: 'formSaveModal sjefModal',
			content: [
				formDirtyFields.map((field, idx) =>
					m(FormGroup, {}, [
						m(FormLabel, fieldSlug(field)),
						m(Input, {
							readonly: true,
							id: field,
							name: field,
							value: form[field],
						}),
					])
				),
			],
			onClose: () => {
				if (typeof vnode.attrs.onCloseModal == 'function') {
					vnode.attrs.onCloseModal();
				}
			},
			footer: [
				m(
					'.flexer',
					m('.flexer',
						m('.buttons',
							m(sjefButton, {
								label: _t('_.buttons.discard'),
								buttonLayout: 'neutral',
								onclick: () => {
									if (typeof vnode.attrs.onDiscard == 'function') {
										vnode.attrs.onDiscard();
									}
								},
							}),
							m(sjefButton, {
								label: _t('_.buttons.save'),
								buttonLayout: 'filled',
								onclick: async () => {
									if (typeof vnode.attrs.onSave == 'function') {
										vnode.attrs.onSave();
									}
								},
							})
						)
					)
				),
			],
		});
	}
}

export default modalFormUnsaved;
