import vwProfileDashboard from '../modules/customerProfiles/components/vwProfileDashboard';

const moduleName = 'customerProfiles';
const baseRoute = '/profiles';
const endpoint = 'https://olap.sjef.dev/profiles';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;

		// view is not defined, why?
		if (this.view) {
			this.view.sortData = {
				sort: '-updatedAt',
			};
		}
		this.actions = {
			canCreate: () => false,
			canUpdate: () => false,
			canDelete: () => false,
		};

		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'profileId', //
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:profileId`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
					},
					{
						name: 'email',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:profileId`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						link: `${baseRoute}/:profileId`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: '',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'createdAt',
						type: 'date',
						formInputType: 'date',
						formShow: true,
						link: false,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: '',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
				],
			},
		];
	}
}
