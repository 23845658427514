import m from 'mithril';
import { Button, Col, Grid, Icon, Icons, Table } from 'construct-ui';
import moment from 'moment';
import app from '../../app';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { deleteJob, disableJob, enableJob, rerunJob, unlockJob } from './fnJobs';
import { openJob, vwJob } from './vwJob';
import sjefButton from '../../views/components/sjefButton';
import sjefTable from '../../views/components/sjefTable';

const dt = (dt) => {
	return dt ? moment(dt).format(`dd DD MMM HH:mm:ss`) : '-';
};
let jobs = [];

const updateJobData = (data) => {
	// update list
	const jobIndex = jobs.findIndex((j) => j._id === data._id);
	// console.log('update job idx', jobIndex);
	if (jobIndex > -1) {
		console.log('update job', jobs[jobIndex], data.updates.updatedFields);
		jobs[jobIndex] = Object.assign(jobs[jobIndex], data.updates.updatedFields);
		m.redraw();
	}
};

const startWebsocketPing = () => {
	app.socket.send('ping');
	app.wsPingInterval = setInterval(() => {
		app.socket.send('ping');
	}, 10000);
};

const stopWebsocketPing = () => {
	clearInterval(app.wsPingInterval);
};
const startWebsockets = () => {
	console.log('Starting websocket');
	if (app.socket && app.socket instanceof ReconnectingWebSocket) {
		try {
			app.socket.close();
			delete app.socket;
		} catch (err) {
			console.info('No ws close needed', err.message);
		}
	}
	const WS_HOST = app.apiRoot.replace('http', 'ws');

	app.socket = new ReconnectingWebSocket(
		// `${app.apiRoot.replace('http', 'ws')}/jobs/ws?token=${encodeURI(app.apiToken)}`,
		`${WS_HOST}/jobs/ws?token=${encodeURI(app.apiToken)}`,
		[],
		{
			maxReconnectionDelay: 10000,
			// minReconnectionDelay: 1000 + Math.random() * 4000,
			reconnectionDelayGrowFactor: 1.3,
			minUptime: 5000,
			connectionTimeout: 4000,
			maxRetries: Infinity,
			maxEnqueuedMessages: 1,
			startClosed: false,
			debug: false,
		}
	);

	app.socket.addEventListener('open', (msg) => {
		console.log('ws open', msg);
		startWebsocketPing();
	});
	app.socket.addEventListener('close', (msg) => {
		console.log('ws closed', msg);
		stopWebsocketPing();
	});

	app.socket.addEventListener('message', (msg, isBinary) => {
		// console.log('ws msg', msg);
		if (isBinary) msg.data = msg.data.toString();

		// try parsing data as json object
		let data = msg.data;
		try {
			data = JSON.parse(msg.data);
		} catch (err) {
			//
			console.error(err);
		}
		// console.log('ws msg', data);
		switch (data.action) {
			case 'jobUpdate':
				updateJobData(data.data);
				break;
			default:
				if (data === 'hi from server' || data === 'ping') {
					// console.info('ws ping-pong');
				} else {
					console.error(`Unknown msg`, data);
				}
		}
	});
};
export const vwJobs = {
	oninit: async () => {
		try {
			console.log('Loading jobs');
			const data = await app.get('/jobs/list');
			if (data.jobs) jobs = data.jobs;
		} catch (err) {
			// console.error('Loading jobs error');
			console.error(err);
		}
	},
	oncreate: () => {
		startWebsockets();
		// connect ws
	},
	onremove: () => {
		app.socket.close();
		// disconnect ws
	},
	view: () => {
		// console.log('view vwJobs');
		return m(
			'',
			// m(vwJob),
			m(
				'.pageHeader',
				m('h1.title', 'Jobs'),
				m('', {}),
				m(
					'.actions',
					{},
					m(sjefButton, {
						label: 'new',
						onclick: () => {
							m.route.set('/admin/jobs/new/');
						},
					})
				)
			),

			m(sjefTable, {
				content: [
					m('tr', [
						m('th', ''),
						m('th', 'Job ID'),
						m('th', 'Job'),
						m('th', 'Meta Client'),
						m('th', 'Client'),
						m('th', 'Client ID'),
						m('th', 'Stack'),
						m('th', 'Start'),
						m('th', 'End'),
						m('th', 'Interval'),
						m('th', 'Last'),
						m('th', 'Result'),
						m('th', 'Duration'),
						m('th', 'Progress'),
						m('th', 'Next'),
					]),
					jobs.map((job) => {
						// console.log(job);
						return m('tr', [
							!job.name.includes('system:')
								? m('td.actions', [
									job.disabled
										? m(Icon, {
											name: Icons.PLAY_CIRCLE,
											intent: 'negative',
											onclick: () => {
												enableJob(job._id);
											},
										})
										: m(Icon, {
											name: Icons.PAUSE_CIRCLE,
											intent: 'positive',
											onclick: () => {
												disableJob(job._id);
											},
										}),

									job.lockedAt
										? m(Icon, {
											name: Icons.ACTIVITY,
											intent: 'positive',
											onclick: () => {
												unlockJob(job._id);
											},
										})
										: m(Icon, {
											name: Icons.TRASH,
											intent: 'negative',
											onclick: () => {
												deleteJob(job._id);
											},
										}),
									job.lastFinishedAt && !job.repeatAt && !job.repeatInterval && !job.repeatEvery
										? m(Icon, {
											name: Icons.REPEAT,
											intent: 'primary',
											onclick: () => {
												rerunJob(job._id);
											},
										})
										: void 0,
								])
								: m('td', ''),
							m(
								'td.link',
								{
									onclick: () => {
										openJob(job);
									},
								},
								job._id
							),
							m('td', job.name),
							m('td', job.data ? job.data.meta_client : ''),
							m('td', job.data ? job.data.client_name : ''),
							m('td', job.data ? job.data.client_id : ''),
							m('td', job.data ? job.data.stack : ''),
							m('td', dt(job.startDate)),
							m('td', dt(job.endDate)),
							m('td', job.repeatEvery || job.repeatAt || job.repeatInterval || 'once'),
							m('td', job.lastFinishedAt ? moment(job.lastFinishedAt).fromNow() : '-'),
							m('td', job.failedAt === job.lastFinishedAt ? job.failReason : (job.result && job.result.result) || '-'),

							m(
								'td',
								job.lastRunAt > job.lastFinishedAt
									? 'running ' + moment().diff(job.lastRunAt, 'seconds') + 's'
									: moment(job.lastFinishedAt).diff(job.lastRunAt, 'seconds') + 's' || '-'
							),
							m('td', (job.data?.progress || 0) + '%'),
							m('td', dt(job.nextRunAt)),
						]);
					}),
				],
			})
		);
	},
};
