
/* eslint:disable */
import m from 'mithril';

class sjefIconBucket {
    view({ attrs: { classes = [], size = "24", strokeWidth = "2", color = "currentColor", fill = "none", onclick = void(0), ...htmlAttrs  }}){
        return m('.sjefIcon.sjefIconBucket',{
                onclick,
                class  : ['icon-sjefIcon2Fa', ...classes].join(" "),
                ...htmlAttrs
            },
            m('svg[xmlns=http://www.w3.org/2000/svg].icon-svg', {
                width  : size || "24",
                height : size || "24",
                stroke : color || "currentColor",
                fill   : fill || "none",
                "stroke-width": strokeWidth || "2",
                "viewBox": "0 0 24 24",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            }, m.trust('<path stroke="none" d="M0 0h24v24H0z" fill="none"/><ellipse cx="12" cy="7" rx="8" ry="4" /><path d="M4 7c0 .664 .088 1.324 .263 1.965l2.737 10.035c.5 1.5 2.239 2 5 2s4.5 -.5 5 -2c.333 -1 1.246 -4.345 2.737 -10.035a7.45 7.45 0 0 0 .263 -1.965" />'))
        )
    }
}
export default sjefIconBucket;

