import m from 'mithril';

import { _t } from '../lib/i18n';
import FormTrait from './traits/FormTrait';
import FormInputFieldsTrait from './traits/FormInputFieldsTrait';
import tableStore from '../lib/tableStore';
import app from '../app';

/**
 * @class
 * @classdesc Base class for form record views.
 */
class vwBaseRecordForm {
	refs = [];
	id;
	route;
	model;
	endpoint;

	viewMode = 'edit';
	title;
	titleBreadcrumb;

	record = {};

	form = {};
	formDirtyFields = [];
	formErrors = {};

	tabs = [];
	tabActive = {};
	tabStored = false;

	/**
	 * Constructor to initialize the modal
	 */
	constructor() {
		Object.assign(this, FormTrait);
		Object.assign(this, FormInputFieldsTrait);
	}

	/**
	 * Called when a component is initialized. Used for getting a record or setting a tab active.
	 *
	 * @param {Object} attrs - Component attributes.
	 * @param {string} attrs.id - ID of the record to fetch.
	 */
	async oninit({ attrs }) {
		this.id = attrs.id;

		if (attrs.id === 'new') {
			this.title = this.titleBreadcrumb = _t(`${this.model}.new`, { defaultValue: 'New' });
			this.setForm();
		} else {
			await this.getRecord(attrs.id);
		}

		let schema = this.config.schema;
		let fieldName = null;

		schema.some(
			(item) =>
				item.fields &&
				item.fields.some((field) => {
					if (field.title === true) {
						fieldName = field.name;
						return true;
					}
					return false;
				})
		);

		if (fieldName) {
			this.title = this.record[fieldName];
		} else {
			this.title = this.record.name
		}

		if (!this.title) {
			this.title = '';
		}
	}

	// onremove() {
	// app.store('activeFormTab', false);
	// app.store('activeRecordId', false);
	// }

	// onupdate() {
	// 	console.log('update baseRecordForm');

	// 	if (app.restore('activeFormTab')) {
	// 		this.setTabActive(app.restore('activeFormTab'));
	// 	}
	// }

	/**
	 * Fetches a record and sets the title.
	 *
	 * @param {string} recordId - The ID of the record to fetch.
	 */
	async getRecord(recordId) {
		const result = await app.get(`${this.endpoint}/${recordId}`);

		if (result.success === true) {
			this.record = result.data;
			// this.form = JSON.parse(JSON.stringify(result.data));

			this.setForm();
			this.setTitles();
		}
	}

	/**
	 * Deletes a record.
	 *
	 * @param {string} recordId - The ID of the record to fetch.
	 */
	async deleteRecord() {
		const result = await app.delete(this.endpoint, { id: this.record.id });

		if (result.success === true) {
			this.record = result.data;

			m.route.set(this.config.route);
		}
	}

	/**
	 * Sets the title and the breadcrumb title.
	 */
	setTitles() {
		// this.title = `${this.record.id} - ${this.record.name}`;
		this.titleBreadcrumb = this.record.id;
	}

	/**
	 * Check if the tab is hidden.
	 *
	 * @param {Object} tab - The tab to check.
	 * @return {boolean}
	 */
	isTabHidden(tab) {
		return !this.record.id && tab.viewMode === 'read';
	}

	/**
	 * Check if the tab is active.
	 *
	 * @param {Object} tab - The tab to check.
	 * @return {boolean}
	 */
	isTabActive(tab) {
		return this.tabActive.slug === tab.slug;
	}

	requiredClass(field, schema) {
		return schema.properties[field].minLength > 0 ? 'required' : '';
	}

	/**
	 * Set the active tab.
	 *
	 * @param {number} index - The index of the tab to set as active.
	 */
	setTabActive = (index) => {
		app.store(`${this.modulename}/activeFormTab`, index);

		// check for selection of current tab
		// app.store('activeFormTab', index);

		// if (this.id) {
		// 	app.store('activeRecordId', this.id);
		// }

		if (Object.keys(this.tabActive).length !== 0) {
			const processedTabs = this.tabs.filter((tab) => this.isTabHidden(tab) == false);

			if (processedTabs.indexOf(this.tabActive) == index) {
				return;
			}
		}

		if (this.formDirtyFields.length) {
			this.tabStored = index;

			this.refs['modalFormUnsaved'].open();

			return false;
		}
		this.tabActive = this.tabs[index];
		this.viewMode = this.tabActive?.viewMode;
		this.tabStored = false;
		m.redraw();
	};

	/**
	 * Returns an object containing properties and handlers for managing an unsaved modal form.
	 *
	 * @returns {Object} - An object with the following properties:
	 *  - setRef {Function} - Function to set the modal instance.
	 *  - form {Object} - The form data.
	 *  - formDirtyFields {Array} - List of fields that are dirty.
	 *  - onCloseModal {Function} - Function to close the modal.
	 *  - onDiscard {Function} - Function to discard changes and close the modal.
	 *  - onSave {Function} - Async function to save changes and close the modal.
	 */
	getModalUnsavedFormProperties() {
		return {
			setRef: (instance) => (this.refs['modalFormUnsaved'] = instance),
			form: this.form,
			formDirtyFields: this.formDirtyFields,
			onCloseModal: () => {
				this.refs['modalFormUnsaved'].close();
			},
			onDiscard: () => {
				this.refs['modalFormUnsaved'].close();
				this.setForm();
				this.formDirtyFields = [];
				this.setTabActive(this.tabStored);
			},
			onSave: async () => {
				this.refs['modalFormUnsaved'].close();

				await this.onSubmit(false);
				this.setTabActive(this.tabStored);
			},
		};
	}

	/**
	 * View function for mithril.js. Override in subclasses.
	 */
	view() {
		return m('', 'No view implemented');
	}
}

export default vwBaseRecordForm;
