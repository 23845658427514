import m from 'mithril';

import chip from '../../assets/img/bank_card/chip.svg'
import sjefLogo from '../../assets/img/sjef_logo.png'

const sjefBankCard = {
	title: '',
	bankNumber: '',
	oninit: (vnode) => {
		vnode.state.title = vnode.attrs.title || '';
		vnode.state.bankNumber = vnode.attrs.bankNumber
			? vnode.attrs.bankNumber.slice(0, -4).replace(/./g, '•') + vnode.attrs.bankNumber.slice(-4)
			: '';
	},
	view: (vnode) => {
		return m('.bank-card',
			[
				m('.bank-card__content',
					[
						m('.bank-card__header', [
							m('.bank-card__circle'),
							"SjefCard"
						]),
						m('.bank-card__body', [
							m('img', {
								src: chip,
								width: 44,
								height: 31
							}),
							m('div', m('h5', vnode.state.title)),
							m('div', m('p', vnode.state.bankNumber)),
							m('div', m('p', "EXP ••/•• CVC •••"))
						])
					]
				),
				m('.bank-card__logo',
					m('img', {
						src: sjefLogo,
						height: 24
					}),
				)
			]
		)
	}
}

export default sjefBankCard
