import m from 'mithril';
import { _t } from '../../lib/i18n';
import app from '../../app';
import { Card, Tag } from 'construct-ui';
import tableStore from '../../lib/tableStore';
import sjefInput from '../../views/components/sjefInput';
import sjefButton from '../../views/components/sjefButton';
import { Grid, Col } from 'construct-ui';
import sjefIconTrendingUp from '@sjefapp/sjeficons/icons/sjefIconTrendingUp';
import sjefIconCurrencyDollar from '@sjefapp/sjeficons/icons/sjefIconCurrencyDollar';
import vwOnboardAdyenModal from './components/vwOnboardAdyenModal';
import sjefWidget from '../../views/components/sjefWidget';
import sjefBalanceChart from '../../views/components/sjefBalanceChart';
import sjefList from '../../views/components/sjefList';
import COLORS from '../../utils/colors';
import sjefSelect from '../../views/components/sjefSelect';
import sjefLoading from '../../views/components/sjefLoading';
import sjefBankCard from '../../views/components/sjefBankCard';
import sjefIconCards from '@sjefapp/sjeficons/icons/sjefIconCards';
import sjefHeader from '../../assets/img/headers/029.jpg';
import CardWithHeader from '../../views/components/sjefCardWithHeader';
import FeatureFlags, { runningPaymentsFlag } from '../../featureFlags';
import periods from '../../utils/periods';

const blurredData = () => {
	return m('.blur', [
		m(`.columns-4`, [
			m(sjefWidget, {
				color: widgetIcon.settled.color,
				title: _t(`modules.balanceoverview.widgets.settled`),
				subTitle: `${currency['EUR']}100`,
				icon: widgetIcon.settled.icon,
				progress: 100,
				style: { textTransform: 'capitalize' },
			}),
			m(sjefWidget, {
				color: widgetIcon.invoiced.color,
				title: _t(`modules.balanceoverview.widgets.invoiced`),
				subTitle: `${currency['EUR']}100`,
				icon: widgetIcon.invoiced.icon,
				progress: 100,
				style: { textTransform: 'capitalize' },
			}),
			m(sjefWidget, {
				color: widgetIcon.availableForPayout.color,
				title: _t(`modules.balanceoverview.widgets.availableForPayout`),
				subTitle: `${currency['EUR']}100`,
				icon: widgetIcon.availableForPayout.icon,
				progress: 100,
				style: { textTransform: 'capitalize' },
			}),
			m(sjefWidget, {
				color: widgetIcon.payout.color,
				title: _t(`modules.balanceoverview.widgets.payout`),
				subTitle: `${currency['EUR']}100`,
				icon: widgetIcon.payout.icon,
				progress: 100,
				style: { textTransform: 'capitalize' },
			}),

		]),
		m(Grid, { gutter: 36, style: { marginTop: '1rem' } }, [
			[
				m(Col, { span: 8, style: { paddingLeft: '18px', paddingRight: '18px' } }, [
					m(sjefBalanceChart, {
						color: COLORS.purple.default,
						title: _t('modules.balanceoverview.account_balance'),
						amount: 2000,
						options: {
							series: [
								{
									name: 'price',
									data: [0, 100, 150, 200],
								},
							],
							xaxis: {
								type: 'datetime',
								categories: ['2021-01-01', '2021-01-02', '2021-01-03', '2021-01-04'],

							},
						},
					}),
					m(sjefList, {
						title: _t('modules.balanceoverview.recent_payouts'),
						data: [{
							'date/time': '2024-05-23T09:00:00.000Z',
							amount: '100',
							currency: 'EUR',
							reference: '123456',
							type: 'payout',

						}, {
							'date/time': '2024-05-23T09:00:00.000Z',
							amount: '100',
							currency: 'EUR',
							reference: '123456',
							type: 'payout',

						}, {
							'date/time': '2024-05-23T09:00:00.000Z',
							amount: '100',
							currency: 'EUR',
							reference: '123456',
							type: 'payout',

						}, {
							'date/time': '2024-05-23T09:00:00.000Z',
							amount: '100',
							currency: 'EUR',
							reference: '123456',
							type: 'payout',

						}
						].map((item) => {
							return {
								'date/time': new Date(item.createdAt).toDateString(),
								price: m('span.list__cell--bold', `${currency[item.currency]}${item.amount}`),
								reference: m('span.list__cell--bold', item.reference),
								type: m(Tag, {
									label: item.type,
									rounded: true,
									size: 'xs',
									style: tagStyle[item.type],
								}),
							};
						}
						),

					}),
				]),
			],
			m(Col, { span: 4 }, [
				m(
					Card,
					[
						m(sjefBankCard, {
							title: 'Test Bank Card',
							bankNumber: 'this.balanceAccountId',
						}),
						m(sjefSelect, {
							prefix: 'balanceoverview',
							iconLeft: sjefIconCards,
							options: ['1', '2', '3'],
							type: 'wide',
							useValueAsLabel: true,

						}),

					]
				),

			]),
		]),
	]);
}

const tagStyle = {
	payout: { backgroundColor: COLORS.green.muted, color: COLORS.green.default },
	settlement: { backgroundColor: COLORS.purple.muted, color: COLORS.purple.default },
	Failed: {
		backgroundColor: COLORS.red.muted,
		color: COLORS.red.default,
	},
};

const widgetIcon = {
	settled: {
		color: COLORS.green.default,
		icon: sjefIconTrendingUp,
	},
	payout: {
		color: COLORS.yellow.default,
		icon: sjefIconTrendingUp,
	},
	invoiced: {
		color: COLORS.blue.default,
		icon: sjefIconTrendingUp,
	},
	availableForPayout: {
		color: COLORS.purple.default,
		icon: sjefIconTrendingUp,
	},
};

const currency = {
	GBP: '£',
	EUR: '€',
	USD: '$',
};

class vwKYC {
	/**
	 * @constructor
	 * @param {Object} vnode - Mithril vnode
	 */
	chart = null;
	activeTab = 'EUR';
	balanceAccountId = null;
	hasBalanceAccounts = false;
	clientInfo = null;
	loading = false;
	data = {
		clientInfo: null,
		totals: null,
		mutations: null,
		totalGraph: null,
	};

	period;

	async oninit(vnode) {
		this.loading = true;
		const endpoint = `/balanceplatform/me/details`;
		const clientInfoResponse = await app.get(endpoint, {}, false);

		if (clientInfoResponse.success) {
			this.clientInfo = clientInfoResponse.data;
			this.balanceAccountId = clientInfoResponse.data.balanceAccount.id;
		}

		if (this.balanceAccountId) {
			this.loading = false;
		} else {
			this.hasBalanceAccounts = false;
			this.loading = false;
		}
	}

	get balance() {
		return clientInfo.balanceAccount.balances[this.currentBalanceIndex];
	}


	async onClickHandler() {
		const {
			data: { url },
		} = await app.get(`/balanceplatform/me/onboardingUrl`);
		if (url) {
			return window.open(url, '_blank');
		}
	}
	view() {
		if (this.loading) {
			return m(sjefLoading);
		}
		return m('', [
			CardWithHeader({
				title: _t(`modules.balanceoverview.intro.title`),
				imageHeader: sjefHeader,
				width: '60%',
				style: {
					margin: '0 auto'
				},
				headerPaddingTop: '4rem',
				bodyPaddingTop: '2rem',
				children: [
					m('h2', _t(`modules.balanceoverview.intro.subtitle`)),
					m('p', _t('modules.balanceoverview.intro.paragraph1')),
					m('p', _t(`modules.balanceoverview.intro.paragraph2`)),
					m('p', _t(`modules.balanceoverview.intro.paragraph3`)),
					m('.flexer',
						this.balanceAccountId
							? [
								m(sjefButton, {
									label: _t('modules.balanceoverview.edit_balance_account'),
									buttonLayout: 'filled wide',
									onclick: this.onClickHandler,
								}),
							]
							: m(vwOnboardAdyenModal, {
								title: `Board: `,
							}),
					)
				],
			}),
			blurredData()
		]);

	}
}

export default vwKYC;
