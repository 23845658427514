import m, { redraw } from 'mithril';
import { Button, ButtonGroup, Col, FormLabel, Grid, Icon, Icons, Input, ListItem, RadioGroup, Select, SelectList, Switch, Table, Tooltip } from 'construct-ui';
// import moment from 'moment';
import { getClients } from './fnJobs';
import date from 'date.js';
import { dateInput } from '../modules/dateInput';
import app from '../../app';

const jobDefaults = { 
	name: 'transactions',
	data: {
		stack: '',
		client_id: 0,
		client_name: '',
		client_db: '',
		meta_client: 0,
	},
	options: {
		repeatEvery: '',
		repeatAt: '',
		schedule: '',
		startDate: '',
		endDate: '',
		disabled: true
	}
};

let jobFrequency = 'Once';

let job = Object.assign({}, jobDefaults);

const clients = {
	rodeo: [],
	events: [],
	app: []
};
let jobComplete = false;

const isJobComplete = () => {
	console.log('isJobComplete',job);
	if(job.options.repeatEvery || job.options.repeatAt || job.options.schedule){
		if(job.data.stack && job.data.client_id && job.data.client_name && job.data.client_db){
			return true;
		}
	}
	return false;
}

const clientPredicate = (query, item) => {
	return item.name.includes(query);
}

const saveJobNew = () => {
	app.post('/jobs/create', job)
}

export const vwJobNew = {
	oninit: () => {
		job = Object.assign({}, jobDefaults);
	},
	view: () => {
		jobComplete = isJobComplete();
		// console.log('job.schedule', date(job.schedule) );
		return m(Grid, [
			m('h1', 'New job'),
			m(Table, [
				m('tr',
					m('td', m( FormLabel, 'name')),
					m('td', 
						m(Select, {
							options: ['transactions'],
							onchange: (e) => {
								job.name = e.currentTarget.value;
							}
						})
					) 
				),
				m('tr',
					m('td', m( FormLabel, 'stack')),
					m('td', 
						m(Select, {
							options: ['', 'rodeo', 'events', 'app'],
							placeholder: 'Select a stack',
							onchange: async (e) => {
								console.log('stack', e.currentTarget)
								job.data.stack = e.currentTarget.value;
								if(!clients[job.data.stack].length) clients[job.data.stack] = await getClients(job.data.stack);
								redraw();
							}
						})
					) 
				),
				m('tr',
					m('td', m( FormLabel, 'client')),
					m('td', 
						job.data.stack && clients[job.data.stack].length
							? m(SelectList, {
								items: clients[job.data.stack],
								closeOnSelect: true,
								itemPredicate: clientPredicate,
								// filterable: false,
								itemRender: (client) => {
									return m(ListItem, {
										contentLeft: client.code,
										label: client.name,
										selected: job.data.client_id === client.code
									})
								},
								onSelect: (client) => {
									job.data.client_id = client.code;
									job.data.client_name = client.name;
									job.data.client_db = client.database;
								},
								trigger: m(Button, {
									label: job.data.client_name ? job.data.client_name : 'select',
									iconRight: Icons.CHEVRON_DOWN
								})
							})
							: m('.cui-notification', 'Select a stack')
					) 
				),
				m('tr',
					m('td', m( FormLabel, 'Frequency')),
					m('td', 
						m(RadioGroup, {
							options: ['Once', 'Repeat At', 'Repeat Every'],
							value: jobFrequency,
							onchange: (e) => {
								jobFrequency = e.currentTarget.value;
							}
						})
						// m(Select, {
						// 	options: ['Once', 'Repeat At', 'Repeat Every'],
						// 	defaultValue: jobFrequency,
						// 	onchange: async (e) => {
						// 		console.log('Frequency', e.currentTarget.value)
						// 		jobFrequency = e.currentTarget.value;
						// 		redraw();
						// 	}
						// })
					) 
				),

				jobFrequency === 'Once' 
					? m('tr',
						m('td', m( FormLabel, 'When')),
						m('td', 
							m(Switch, { 
								checked: job.options.schedule === 'now',
								label: 'run immediately',
								onchange: () => {
									if(job.options.schedule !== 'now'){
										job.options.schedule = 'now'
									}else{
										job.options.schedule = '';
									}
								}

							}),
							m('br'),
							m(Input, {
								contentLeft: job.options.schedule ? m(Icon, { intent: 'positive', name: Icons.CHECK}) : '',
								defaultValue: job.options.schedule,
								disabled: () => job.options.schedule === 'now',
								oninput: async (e) => {
									// console.log('schedule', e.currentTarget.value)
									job.options.schedule = e.currentTarget.value;
									job.options.repeatAt = '';
									job.options.repeatEvery = '';

									redraw();
								},
								contentRight: m(Tooltip, {
									content: job.options.schedule ? date(job.options.schedule).toString() : `For example: tomorrow 10:00 / in 10 minutes / tomorrow 03:00`,
									trigger: m(Button, { label: '?', basic: true})
								})
							}),
						) 
					)
					
					: void(0),
				
				jobFrequency === 'Repeat At' 
					? m('tr',
						m('td', m( FormLabel, 'Repeat at')),
						m('td', 
							m(Input, {
								contentLeft: job.options.repeatAt ? m(Icon, { intent: 'positive', name: Icons.CHECK}) : '',
								defaultValue: job.options.repeatAt,
								oninput: async (e) => {
									// console.log('repeatAt', e.currentTarget.value)
									job.options.repeatAt = e.currentTarget.value;
									job.options.repeatEvery = '';
									job.options.schedule = '';

									redraw();
								},
								contentRight: m(Tooltip, {
									content: job.options.repeatAt ? ('next: '+date(job.options.repeatAt).toString()) : `For example: 23:37`,
									trigger: m(Button, { label: '?', basic: true})
								})
							})
						) 
					)
					: void(0),
				jobFrequency === 'Repeat Every' 
					? m('tr',
						m('td', m( FormLabel, 'Repeat every')),
						m('td', 
							m(Input, {
								contentLeft: job.options.repeatEvery ? m(Icon, { intent: 'positive', name: Icons.CHECK}) : '',
								defaultValue: job.options.repeatEvery,
								oninput: async (e) => {
									// console.log('repeatEvery', e.currentTarget.value)
									job.options.repeatEvery = e.currentTarget.value;
									job.options.repeatAt = '';
									job.options.schedule = '';
									redraw();
								},
								contentRight: m(Tooltip, {
									content: job.options.repeatEvery ? 'next: '+date(job.options.repeatEvery).toString() : `For example: 1 minutes or 24 hours`,
									trigger: m(Button, { label: '?', basic: true})
								})
							})
						) 
					)
					: void(0),

				jobFrequency !== 'Once' 
					? [
						m('tr',
							m('td', m( FormLabel, 'Start datetime')),
							m('td', 
								m(dateInput, {
									id: 'jobStart',
									start: job.options.startDate,
									cb: (start) => {
										// console.log('start', start);
										job.options.startDate = start;
										redraw();
									}
								})
							) 
						),
						m('tr',
							m('td', m( FormLabel, 'End datetime')),
							m('td', 
								m(dateInput, {
									id: 'jobEnd',
									start: job.options.endDate,
									minDate: job.options.startDate,
									cb: (start) => {
										// console.log('start', start);
										job.options.endDate = start;
										redraw();
									}
								})
							) 
						)
					]
					: void(0)
			]),
			m(Col, { span: 12}, [
				m(ButtonGroup, [
					m(Button, {
						label: 'cancel',
						intent: 'none',
						onclick: () => {
							m.route.set("/admin/jobs");
						}
					}),
					m(Button, {
						label: 'save',
						iconLeft: Icons.SAVE,
						disabled: !jobComplete,
						intent: 'primary',
						onclick: () => {
							console.log('save job', job);
							saveJobNew();
						}
					})
				]),
			]),
		]);
	}
};
