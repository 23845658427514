const moduleName = 'paymentmethods';
const baseRoute = '/masterdata/paymentmethods';
const endpoint = '/masterdata/paymentmethods';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						formTranslations: 'nameTranslations',
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'paymentserviceproviderMethod',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						required: true,
					},
					{
						name: 'type',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					{
						name: 'position',
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listDragSort: true,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						nullable: false,
						required: true,
					},
					{
						name: 'allowNegativepayment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'allowOverpayment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'allowZeropayment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'allowSplitpayment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'askforAccount',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					// {
					// 	name: 'cashfunctionId',
					// 	type: 'integer',
					// 	formInputType: 'number',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 0,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	nullable: true,
					// 	required: true,
					// },

					{
						name: 'cashfunctionId',
						type: 'integer',
						formInputType: 'selectAsync',
						formShow: true,

						asyncEndpoint: '/masterdata/cashfunctions',
						asyncField: 'cashfunctionId',
						asyncFieldLabel: 'cashfunction',
						asyncFilterOn: 'name',

						// link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: false,
						listSize: 'small',
						nullable: true,
						required: true,
					},

					{
						name: 'isCoinPayment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'isDefault',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'isPreferredPayment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'isVoucherPayment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'isWalletPayment',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},

					// {
					// 	name: 'nameTranslations',
					// 	type: 'string',
					// 	formInputType: 'text',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 0,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	required: true,
					// },
					// {
					// 	name: 'nameKioskTranslations',
					// 	type: 'string',
					// 	formInputType: 'text',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 0,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	required: true,
					// },
					// {
					// 	name: 'namePosTranslations',
					// 	type: 'string',
					// 	formInputType: 'text',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 0,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	required: true,
					// },
					// {
					// 	name: 'nameWebshopTranslations',
					// 	type: 'string',
					// 	formInputType: 'text',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listColumnPosition: 0,
					// 	listShow: true,
					// 	listSize: 'small',
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	required: true,
					// },
					{
						name: 'discountId', //
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
						nullable: true,
						required: true,
					},
					{
						name: 'paymentserviceproviderId',
						type: 'integer',
						formInputType: 'selectAsync',
						asyncEndpoint: '/masterdata/paymentserviceproviders',
						asyncField: 'paymentserviceproviderId',
						asyncFieldLabel: 'paymentserviceprovider',
						asyncFilterOn: 'name',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
						nullable: true,
						required: true,
					},
					{
						name: 'posAdminOnly',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'posOpendrawer',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'posPrintreceipt',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'requiredAccount',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},
					{
						name: 'requiredBookingnumber',
						type: 'boolean',
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 0,
						listShow: false,
						required: true,
					},

					{
						name: 'archived',
						type: 'boolean',
						defaultValue: false,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						required: true,
					},
				],
			},
		];
	}
}
