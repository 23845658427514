/**
 * @fileoverview This module renders a table view of balance accounts using the Mithril.js framework and construct-ui library.
 * @module vwClientAdyenBalanceAccounts
 */
import m from 'mithril';
import { Card, FormGroup, FormLabel, List, Table, Tag } from 'construct-ui';
import { _t } from '../../../lib/i18n';
import sjefApexChart from '../../../views/components/sjefApexChart';
import sjefInput from '../../../views/components/sjefInput';
import sjefIconWallet from '@sjefapp/sjeficons/icons/sjefIconWallet';
import { price } from '../../../lib/helpers';
import sjefTag from '../../../views/components/sjefTag';

/**
 * The view module exports an object representing a Mithril component.
 * @namespace
 */
class vwClientAdyenBalanceAccounts {
	/**
	 * @type {Array} balanceAccounts - An array to store balance account information.
	 */
	balanceAccounts = [];

	/**
	 * @type {string} primaryBalanceAccount - The ID of the primary balance account.
	 */
	primaryBalanceAccount = '';

	/**
	 * Initializes the view component with provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {string} vnode.attrs.primaryBalanceAccount - The ID of the primary balance account.
	 * @param {Array} vnode.attrs.balanceAccounts - The balance account information.
	 */
	oninit = async ({ attrs }) => {
		this.primaryBalanceAccount = attrs.primaryBalanceAccount;
		this.balanceAccounts = attrs.balanceAccounts;
	};

	/**
	 * Renders the view component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	view() {
		return m('.adyen', [
			m('.flexer', m('h3', 'Balance Accounts'), m('')),
			this.balanceAccounts.map((ba, idx) => {
				return [
					m(Card, [
						m(FormGroup, {
							content: [m(FormLabel, _t('_.fields.adyen.storeId')), m(sjefInput, { copy: true, value: ba.id, readonly: true })],
						}),

						m(
							'.columns.columns-2.greyed',
							m(
								'.column',
								m(FormGroup, {
									content: [m(FormLabel, _t('_.fields.description')), m('div', [m('div', ba.description)])],
								}),

								m(FormGroup, {
									content: [m(FormLabel, _t('_.fields.currency')), m('div', [m('div', ba.currency)])],
								}),

								m(FormGroup, {
									content: [m(FormLabel, _t('_.fields.timezone')), m('div', [m('div', ba.timezone)])],
								})
							),
							m(
								'.column',
								m(FormGroup, {
									content: [m(FormLabel, _t('_.fields.service')), m('div', [m('div', ba.service)])],
								})
							)
						),

						m(FormGroup, {
							content: [
								m('h4', _t('_.fields.balances')),
								m(
									'',
									ba.balances.map((item) => {
										const categories = Object.keys(item).filter((key) => key !== 'currency');

										return [
											m('h5', item.currency),
											m(
												'.balances',
												categories.map((i) => {
													return m('.balanceItem', {
														class: item[i] > 0 ? 'positive' : item[i] < 0 ? 'negative' : '',
													},[
														m('h4', price(parseFloat(item[i])/100, item.currency)), // Use item[i] to get the value associated with the key
														m('h5', i),
													]);
												})
											)
										];
									})
								),
							],
						}),
					]),
				];
			}),
		]);
	}
}

export default vwClientAdyenBalanceAccounts;
