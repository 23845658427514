export const downloadBase64 = (base64, type, filename) => {
	var binaryString = atob(base64);
	var len = binaryString.length;
	var bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	var blob = new Blob([bytes.buffer], { type: type });
	var link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = filename;
	link.click();
};
