/**
 * @fileOverview View component for displaying and managing business lines.
 * @module vwClientAdyenBusinessLines
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import { Card, FormGroup, FormLabel, Table } from 'construct-ui';

import vwClientAdyenBusinessLinesCreateModal from './vwClientAdyenBusinessLinesCreateModal';
import vwClientAdyenBusinessLinesDeleteModal from './vwClientAdyenBusinessLinesDeleteModal';
import vwClientAdyenBusinessLinesUpdateModal from './vwClientAdyenBusinessLinesUpdateModal';
import sjefInput from '../../../views/components/sjefInput';
import { _t } from '../../../lib/i18n';
import app from '../../../app';
import sjefPopover from '../../../views/components/sjefPopover';
import actionsMenu from '../../../views/components/actionsMenu';

/**
 * The view module exports an object representing a Mithril component.
 * @module vwClientAdyenBusinessLines
 * @namespace
 */
class vwClientAdyenBusinessLines {
	/**
	 * @type {Array} businessLines - An array to store business lines.
	 */
	businessLines = [];

	/**
	 * @type {Function} callbackRedraw - The callback function to be called for redrawing the UI.
	 */
	callbackRedraw;

	/**
	 * @type {string} clientId - The ID of the client.
	 */
	clientId;

	/**
	 * Initializes the view component with provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {string} vnode.attrs.clientId - The client's ID.
	 * @param {Array} vnode.attrs.businessLines - The business lines data.
	 * @param {Function} vnode.attrs.callbackRedraw - Callback to redraw the view.
	 */
	oninit = async ({ attrs }) => {
		this.clientId = attrs.clientId || app.restore('activeRecordId');
		this.businessLines = attrs.businessLines;
		this.callbackRedraw = attrs.callbackRedraw;
	};

	/**
	 * Reassigns the businessLines property to the businessLines variable.
	 *
	 * @async
	 * @function
	 * @returns {Promise<void>} No explicit return.
	 */
	redraw = async () => {
		const data = await this.callbackRedraw();

		this.businessLines = data.legalEntity.businessLines || [];
	};

	/**
	 * Renders the view component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	view() {
		return m('.adyen', [
			m(
				'.flexer',
				m(
					'h3',
					{
						onclick: (e) => {
							let el = e.target;
							while (el && el !== document) {
								if (el.classList.contains('adyen')) {
									el.classList.toggle('isFolded');
									break;
								}
								el = el.parentElement;
							}
						},
					},
					'Business Lines'
				),
				m(vwClientAdyenBusinessLinesCreateModal, { clientId: this.clientId, callbackOnSuccess: this.redraw })
			),
			this.businessLines?.map((bl, idx) => {
				return m(Card, [
					m(
						'.flexer',
						m(FormGroup, {
							content: [
								m(FormLabel, _t('_.fields.adyen.businessLineId')),
								m('.flex.grow-first', [
									m(sjefInput, { copy: true, value: bl.id, readonly: true }),
									m(actionsMenu, {
										closeOnContentClick: false,
										content: [
											m(vwClientAdyenBusinessLinesDeleteModal, {
												clientId: this.clientId,
												businessLineId: bl.id,
												callbackOnSuccess: this.redraw,
											}),

											m(vwClientAdyenBusinessLinesUpdateModal, {
												clientId: this.clientId,
												businessLineId: bl.id,
												callbackOnSuccess: this.redraw,
												data: {
													industryCode: bl.industryCode,
													webData: bl.webData,
												},
											}),
										],
									}),
								]),
							],
						})
					),
					m(
						'.columns.columns-2.greyed',
						m(
							'.column',
							m(FormGroup, {
								content: [m(FormLabel, _t('_.fields.industryCode')), m('div', [m('div', bl.industryCode)])],
							}),

							m(FormGroup, {
								content: [m(FormLabel, _t('_.fields.legalEntityId')), m('div', [m('div', bl.legalEntityId)])],
							}),

							m(FormGroup, {
								content: [m(FormLabel, _t('_.fields.saleschannels')), m('div', [m('div', bl.salesChannels?.join(', '))])],
							}),

							m(FormGroup, {
								content: [m(FormLabel, _t('_.fields.webdata')), m('div', [m('div', bl.webData?.map((w) => w.webAddress).join(', '))]),],
							})
						),
						m(
							'.column',
							m(FormGroup, {
								content: [m(FormLabel, _t('_.fields.service')), m('div', [m('div', bl.service)])],
							}),

							m(FormGroup, {
								content: [m(FormLabel, _t('_.fields.status')), m('div', [m('div', bl.status)])],
							})
						)
					),
				]);
			}),

		]);
	}
}

export default vwClientAdyenBusinessLines;
