import m from 'mithril';
import sjefTable from '../../../views/components/sjefTable';
import app from '../../../app';
import sjefIconDots from '@sjefapp/sjeficons/icons/sjefIconDots';
import { FormGroup, Select, Switch } from 'construct-ui';
import sjefTag from '../../../views/components/sjefTag';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import { price } from '../../../lib/helpers';

class vwProfileTransactions {
	temperatures = ['0-', '0-5', '5-10', '10-15', '15-20', '20-25', '25-30', '30+'];
	filteredTransactionsTemperatures;
	dayTimes = ['morning', 'afternoon', 'evening', 'night'];
	filteredTransactionsDaytime;

	tempAdministrations = {
		1: 'Aviato',
		2: 'Feyenoord - Celtic',
		3: 'Awakenings',
		4: 'Defqon.1',
		5: 'De Amsterdamse Zomer',
	};

	profile;
	transactions = false;
	showFilters = false;

	oninit(vnode) {
		this.profile = vnode.attrs.profile;
		this.transactions = vnode.attrs.transactions;

		console.log(vnode);

		console.log(this.transactions);

		this.fieldsToDisplay = ['item', 'administrationId', 'channel', 'category', 'subcategory', 'quantity', 'total', 'timestamp'];
	}

	filterTransactions(type, value) {
		if (type === 'daytime') {
			if (value) {
				this.filteredTransactionsDaytime = this.transactions.filter((item) => item.daytime.toLowerCase() === value.toLowerCase());
			} else {
				this.filteredTransactionsDaytime = null;
			}
		} else if (type === 'temperature') {
			if (value) {
				if (value === '0-') {
					this.filteredTransactionsTemperatures = this.transactions.filter((item) => item.temperature !== null && item.temperature < 0);
				} else if (value === '30+') {
					this.filteredTransactionsTemperatures = this.transactions.filter((item) => item.temperature !== null && item.temperature > 30);
				} else {
					const [min, max] = value.split('-').map(Number);
					this.filteredTransactionsTemperatures = this.transactions.filter(
						(item) => item.temperature !== null && item.temperature >= min && item.temperature <= max
					);
				}
			} else {
				this.filteredTransactionsTemperatures = null;
			}
		}

		if (this.filteredTransactionsDaytime && this.filteredTransactionsTemperatures) {
			this.filteredTransactions = this.filteredTransactionsDaytime.filter((item) => this.filteredTransactionsTemperatures.includes(item));
		} else if (this.filteredTransactionsDaytime) {
			this.filteredTransactions = this.filteredTransactionsDaytime;
		} else if (this.filteredTransactionsTemperatures) {
			this.filteredTransactions = this.filteredTransactionsTemperatures;
		} else {
			this.filteredTransactions = null;
		}

		console.log(this.filteredTransactions);
	}

	view() {
		return m(
			'.profileComponent.transactions',
			this.transactions
				? [
						m(
							'.flexTitle',
							m('h3', 'Transactions'),
							m(Switch, {
								label: 'Filter',
								checked: this.showFilters,
								onchange: (evt) => {
									this.showFilters = evt.target.checked;
								},
							})
						),
						this.showFilters
							? m(
									'.flexFilters',
									m(
										FormGroup,
										{
											label: 'temperature',
										},
										m(Select, {
											name: 'temperature',
											options: [{ label: '', value: '' }].concat(
												this.temperatures.map((temp) => {
													return { label: temp, value: temp };
												})
											),
											onchange: (evt) => this.filterTransactions('temperature', evt.target.value),
										})
									),
									m(
										FormGroup,
										{
											label: 'daytime',
										},
										m(Select, {
											name: 'daytime',
											options: [{ label: '', value: '' }].concat(
												this.dayTimes.map((time) => {
													return { label: time, value: time };
												})
											),
											onchange: (evt) => this.filterTransactions('daytime', evt.target.value),
										})
									)
							  )
							: void 0,
						m(sjefTable, {
							content: [
								m(
									'tr',
									this.fieldsToDisplay.map((field) => {
										return m('th', field);
									})
								),
								(this.filteredTransactions || this.transactions).map((product) => {
									return m(
										'tr',
										this.fieldsToDisplay.map((field, index) => {
											if (field === 'total') {
												return m('td', price(product[field]))
											}

											if (field === 'name' && product.link) {
												return m(
													'td',
													m(
														'a',
														{
															href: product.link,
															onclick: (e) => {
																// e.preventDefault();
															},
														},
														product[field]
													)
												);
											}

											if (field === 'administrationId') {
												return m('td', this.tempAdministrations[product[field]]);
											}

											return m('td', product[field]);
										})
									);
								}),
							],
						}),
				  ]
				: void 0
		);
	}
}

export default vwProfileTransactions;
