import m from 'mithril';
import sjefIconCalendar from '@sjefapp/sjeficons/icons/sjefIconCalendar';
import sjefIconCopy from '@sjefapp/sjeficons/icons/sjefIconCopy';
import sjefIconEye from '@sjefapp/sjeficons/icons/sjefIconEye';
import sjefIconEyeOff from '@sjefapp/sjeficons/icons/sjefIconEyeOff';
import { Input, Select } from 'construct-ui';

const processIcon = (icon, type = 'iconElement') => {
	if (typeof icon === 'function') {
		return m(icon, { classes: [type] });
	} else if (typeof icon === 'object') {
		const attributes = icon.attrs;
		attributes.classes = [type];
		return m(icon.tag, attributes);
	}
	return void 0;
};

class sjefInput {
	isFocused = false;
	showPassword = false;
	localValue = '';  // Local state to keep track of the input value

	oninit({ attrs }) {
		this.parentOnChange = attrs.onChange;
		// Initialize the local value with the value passed in, if any
		this.localValue = attrs.value || '';
	}

	updateValue(newValue) {
		if (typeof this.parentOnChange === 'function') {
			this.parentOnChange(newValue);
		} else {
			this.localValue = newValue;
		}
		m.redraw();
	}

	view({ attrs }) {
		let {
			classes = [],
			type = 'text',
			name,
			value,
			initialValue,
			readonly = false,
			copy = false,
			disabled = false,
			options,
			emptyOption = false,
			label = '',
			error = false,
			placeholder,
			icon = false,
			description,
			key = void(0),
			color = 'currentColor',
			onclick = void 0,
			onchange = void 0,
			...htmlAttrs
		} = attrs;

		const htmlAttrsClass = htmlAttrs.class || void 0;
		delete htmlAttrs.class;

		// Use the local value if the parent does not provide a value
		value = value !== undefined ? value : this.localValue;

		// Ensure options is an array or set it to an empty array
		const safeOptions = Array.isArray(options) ? options : [];

		// Properly create the empty option object
		const emptyObj = emptyOption ? [{ label: '', value: '' }] : [];

		// Process the options
		const processedOptions = [
			...emptyObj,
			...safeOptions.map(option => ({
				label: option.label ? option.label.toString() : option.toString(),
				value: option.value ? option.value.toString() : option.toString()
			}))
		];

		return [
			m('.sjefInputWrapper', {
				class: [value ? 'hasValue' : '', 'field-type-' + type].join(' '),
				key
			},
				m('.inputWrapper', {
					onclick,
					class: [
						this.isFocused || value?.length && !readonly ? 'no-placeholder' : void 0,
						htmlAttrsClass,
						icon ? 'hasIcon' : void 0,
						...classes,
					].join(' '),
					...htmlAttrs,
				},
					type === 'datetime-local' ? m('.dateIcon', processIcon(sjefIconCalendar)) : void (0),
					label ? m('label.truncate', label) : void (0),
					icon ? m('.labelIcon') : void (0),
					(copy && type !== 'date') &&
					m('.copyIcon', {
						onclick: function () {
							navigator.clipboard.writeText(value).then(() => {
								app.toast('Copied to clipboard!');
							}).catch(err => {
								// alert('Failed to copy: ', err);
							});
						}
					}, m(sjefIconCopy)),
					m('.relative',
						icon ? m('.labelIcon', processIcon(icon)) : void (0),
						m('', {
							class: !readonly ? 'active-state-elem' : void (0)
						},
							type === 'select' ?
								m(Select, {
									options: processedOptions.map(option => ({
										label: option.label,
										value: option.value,
									})),
									value: value ? value : initialValue,
									onchange: (event) => {
										onchange && onchange();
										this.updateValue(event.target.value);
									},
								}) :
								m(Input, {
									type: type === 'password' ? (this.showPassword ? 'text' : 'password') : type,
									class: 'input-' + type,
									name: name,
									value: value ? value : initialValue,
									readonly: readonly,
									placeholder: placeholder,
									disabled: disabled,
									onchange: () => {
										onchange && onchange();
									},
									onfocus: () => {
										this.isFocused = true;
									},
									onblur: () => {
										this.isFocused = false;
									},
									oninput: (e) => {
										if (type !== 'datetime-local') {
											this.updateValue(e.target.value)
											return;
										}

										const value = e.target.value;
										const parsedValue = `${value}:00`;
										this.updateValue(parsedValue)
									},
									...htmlAttrs,
								}),
							type === 'password' ?
								m('span.password-toggle.input-icon', {
									onclick: () => {
										this.showPassword = !this.showPassword;
										m.redraw();
									}
								}, this.showPassword ? m(sjefIconEye) : m(sjefIconEyeOff)) : void 0,
						),
					),
					error ? m('span.error', error) :
						description ? m('span.description', description) : void 0
				)
			),
		];
	}
}

export default sjefInput;
