import m from 'mithril';
import layout from './views/layout';
import login from './views/login';
import forgotPassword from './views/forgotPassword';
import register from './views/register';
import errorPage403 from './views/errorPages/403';
import app from './app';
import modules from './modules/index';
import moduleConfigs from './moduleConfigs/index';

import vwForm from './modules/_default/vwForm';
import vwList from './modules/_default/vwList';
import sjefList from './views/components/sjefList';

import vwDocumentationApi from './modules/documentation/vwDocumentationApi';
import switchingPage from './views/switching/switchingPage';
import auth from './lib/auth';
import FeatureFlags, { administrationFlag } from './featureFlags';

app.breadcrumb = [];

const components = {
	'vwForm': vwForm,
	'vwList': vwList,
	'sjefList': sjefList,
};

let menus = {};
const onmatch = (args, path, route, component) => {
	app.currentRawRoute = route;

	if (!app.isAuthenticated) {
		m.route.set('/login');
	}
	// } else if (component && !app.accessibleComponents.includes(component)) {
	// 	return m.route.set('/error/403');
	// }
	else {
		return;
	}
};

const generateRoutes = (items, parentRoute = [''], nestedIteration = false) => {
	for (const item of items) {
		// if accessRules is not set this check is passed if it is set the route access is validated
		if (auth.validateRouteAccess(item.accessRules) === true) {
			const menuItem = {
				id: item.id,
				label: item.label,
				route: item.route,
				module: item.module,
				config: item.config,
				hidden: item.hidden,
			};

			if (item.module) {
				createRoute(item, parentRoute);
			}

			if (item.children) {
				menuItem.children = [];

				for (const child of item.children) {
					if (auth.validateRouteAccess(child.accessRules) === true) {
						menuItem.children.push(child)
						createRoute(child, [...parentRoute, item.route]);
					}
					generateRoutes(item.children, [...parentRoute, item.route], true);
				}
			}

			if (!nestedIteration) { app.menu.push(menuItem); }
		}
	}
};

const createRoute = (item, parentRoute) => {
	app.routes[[...parentRoute, item.route].join('/') || '/'] = {
		onmatch: (args, requestedPath, route) => onmatch(args, requestedPath, route, item.component),
		render: ({ attrs }) => {
			if (item.config in moduleConfigs) {
				const mComponent = components[item.module] || modules[item.module];
				return m(layout, m(mComponent, { ...attrs, config: moduleConfigs[item.config], key: item.config }));
			} else if (item.module in modules) {
				return m(layout, m(modules[item.module], attrs));
			} else {
				return m(layout, m('.missingModule', `Module ${item.module} is not available`));
			}
		},
	};
};

export const routes = async () => {
	menus = {
		9: await import('./menu/9.json'),
		8: await import('./menu/8.json'),
		7: await import('./menu/7.json'),
		6: await import('./menu/6.json'),
		elockers: await import('./menu/elockers.json'),
	};

	// Menu feature flags
	if (!FeatureFlags.isEnabled(administrationFlag)) {
		menus[7] = menus[7].filter((item) => item.id !== 'administrations');
	}


	// TODO: default lvl
	initRoutes(app.lvl && app.lvl > 1 ? app.lvl : 9);
};

export const initRoutes = (lvl, parentRoute = [''], notReload = false) => {
	const root = document.getElementById('root');
	app.menu = [];

	let routes = {};
	if (app.activeTab == 'elockers') {
		routes = menus.elockers;
	} else {
		routes = menus[lvl];
	}

	//default app routes
	app.routes = {
		'/login': login,
		'/forgot-password': forgotPassword,
		'/register': register,
	};

	if (app.isAuthenticated === true) {
		//add more default routes for authenticated users
		app.routes = {
			...app.routes,
			...{
				'/error/403': errorPage403,
				'/switching': switchingPage,
				'/docs/api': vwDocumentationApi,
			},
		};

		generateRoutes(routes, parentRoute);
		m.route(root, '/', app.routes);
	} else {
		m.route(root, '/login', app.routes);
	}
};
