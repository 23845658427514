import m from 'mithril';
import app from '../../app';
import { Card } from 'construct-ui';
import sjefChart from '../../views/components/sjefChart';
import sjefApexChart from '../../views/components/sjefApexChart';
import COLORS from '../../utils/colors';
/**
 * Represents a view model for a Client form.
 * @class
 */
class vwAssetsOverview {
	project;
	endpoint = '/operations/assets/totals';
	assets;

	oninit = async () => {
		const result = await app.get(this.endpoint);

		console.log(this.endpoint)

		if (result.success) {
			this.assets = result.data;
			console.log(result);
		}

		console.log(this.assets);

	};

	/**
	 * This function generates a view of the form.
	 * @return {Object} Mithril virtual DOM object
	 */

	view() {
		return m('',
			this.assets &&
			[
				m('.columns-2',
					m(Card, {},
						m(sjefApexChart, {
							style: 'minimal',
							color: COLORS.purple.default,
							options: {
								legend: {
									show: true
								},
								tooltip: {
									enabled: false,
								},
								colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
								plotOptions: {
									pie: {
										donut: {
											labels: {
												show: true,

											}
										}
									}
								},
								chart: {
									type: 'donut'
								},
								labels: ['TurboPos', 'AMS1', 'S1F2'],

								series: [
									this.assets.find(item => item.model == 'TurboPos')?.total || 0,
									this.assets.find(item => item.model == 'AMS1')?.total || 0,
									this.assets.find(item => item.model == 'S1F2')?.total || 0,
								]
								,

							}
						})
					),
					m(Card, {},
						m(sjefApexChart, {
							style: 'minimal',
							color: COLORS.purple.default,
							options: {
								legend: {
									show: true
								},
								tooltip: {
									enabled: false,
								},
								colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
								chart: {
									type: 'bar'
								},
								labels: ['TurboPos', 'AMS1', 'S1F2'],

								series: [{
									data: [
										{
											y: this.assets.find(item => item.model == 'TurboPos')?.defect || 0,
											fillColor: COLORS.blue.default
										},
										{
											y: this.assets.find(item => item.model == 'AMS1')?.defect || 0,
											fillColor: COLORS.red.default
										},
										{
											y: this.assets.find(item => item.model == 'S1F2')?.defect || 0,
											fillColor: COLORS.yellow.default
										},]
								}]
							}
						})
					),
				),
				m('.columns-3',
					m(Card, {},
						m('h2', 'TurboPos'),
						m(sjefApexChart, {
							style: 'minimal',
							color: COLORS.purple.default,
							options: {
								legend: {
									show: true
								},
								tooltip: {
									enabled: false,
								},
								colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
								chart: {
									type: 'bar'
								},
								series: [{
									data: [{
										fillColor: COLORS.blue.default,
										x: "Defect",
										y: this.assets.find(item => item.model == 'TurboPos')?.defect || 0
									},
									{
										fillColor: COLORS.green.default,
										x: "In",
										y: this.assets.find(item => item.model == 'TurboPos')?.in || 0
									},
									{
										fillColor: COLORS.yellow.default,
										x: "Operational",
										y: this.assets.find(item => item.model == 'TurboPos')?.operational || 0
									},
									{
										fillColor: COLORS.purple.default,
										x: "Out",
										y: this.assets.find(item => item.model == 'TurboPos')?.out || 0
									},
									{
										fillColor: COLORS.red.default,
										x: "Total",
										y: this.assets.find(item => item.model == 'TurboPos')?.total || 0
									},
									]
								}]
							}
						})
					),
					m(Card, {},
						m('h2', 'S1F2'),
						m(sjefApexChart, {
							style: 'minimal',
							color: COLORS.purple.default,
							options: {
								legend: {
									show: true
								},
								tooltip: {
									enabled: false,
								},
								colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
								chart: {
									type: 'bar'
								},
								series: [{
									data: [{
										fillColor: COLORS.blue.default,
										x: "Defect",
										y: this.assets.find(item => item.model == 'S1F2')?.defect || 0
									},
									{
										fillColor: COLORS.green.default,
										x: "In",
										y: this.assets.find(item => item.model == 'S1F2')?.in || 0
									},
									{
										fillColor: COLORS.yellow.default,
										x: "Operational",
										y: this.assets.find(item => item.model == 'S1F2')?.operational || 0
									},
									{
										fillColor: COLORS.purple.default,
										x: "Out",
										y: this.assets.find(item => item.model == 'S1F2')?.out || 0
									},
									{
										fillColor: COLORS.red.default,
										x: "Total",
										y: this.assets.find(item => item.model == 'S1F2')?.total || 0
									},
									]
								}]
							}
						})
					),
					m(Card, {},
						m('h2', 'AMS1'),
						m(sjefApexChart, {
							style: 'minimal',
							color: COLORS.purple.default,
							options: {
								legend: {
									show: true
								},
								tooltip: {
									enabled: false,
								},
								colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
								chart: {
									type: 'bar'
								},
								series: [{
									data: [{
										fillColor: COLORS.blue.default,
										x: "Defect",
										y: this.assets.find(item => item.model == 'AMS1')?.defect || 0
									},
									{
										fillColor: COLORS.green.default,
										x: "In",
										y: this.assets.find(item => item.model == 'AMS1')?.in || 0
									},
									{
										fillColor: COLORS.yellow.default,
										x: "Operational",
										y: this.assets.find(item => item.model == 'AMS1')?.operational || 0
									},
									{
										fillColor: COLORS.purple.default,
										x: "Out",
										y: this.assets.find(item => item.model == 'AMS1')?.out || 0
									},
									{
										fillColor: COLORS.red.default,
										x: "Total",
										y: this.assets.find(item => item.model == 'AMS1')?.total || 0
									},
									]
								}]
							}
						})
					),
				),
				m('.columns-2',
					m(Card, {},
						m('h2', 'Total Out'),
						m(sjefApexChart, {
							style: 'minimal',
							color: COLORS.purple.default,
							options: {
								legend: {
									show: true
								},
								tooltip: {
									enabled: false,
								},
								colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
								chart: {
									type: 'bar'
								},
								labels: ['TurboPos', 'AMS1', 'S1F2'],

								series: [{
									data: [
										{
											y: this.assets.find(item => item.model == 'TurboPos')?.out || 0,
											fillColor: COLORS.blue.default
										},
										{
											y: this.assets.find(item => item.model == 'AMS1')?.out || 0,
											fillColor: COLORS.red.default
										},
										{
											y: this.assets.find(item => item.model == 'S1F2')?.out || 0,
											fillColor: COLORS.yellow.default
										},
									]
								}]
							}
						})
					),
					m(Card, {},
						m('h2', 'Total In'),
						m(sjefApexChart, {
							style: 'minimal',
							color: COLORS.purple.default,
							options: {
								legend: {
									show: true
								},
								tooltip: {
									enabled: false,
								},
								colors: [COLORS.purple.default, COLORS.blue.default, COLORS.yellow.default],
								chart: {
									type: 'bar'
								},
								labels: ['TurboPos', 'AMS1', 'S1F2'],
								series: [{
									data: [
										{
											y: this.assets.find(item => item.model == 'TurboPos')?.in || 0,
											fillColor: COLORS.blue.default
										},
										{
											y: this.assets.find(item => item.model == 'AMS1')?.in || 0,
											fillColor: COLORS.red.default
										},
										{
											y: this.assets.find(item => item.model == 'S1F2')?.in || 0,
											fillColor: COLORS.yellow.default
										},


									]
								}]
							}
						})
					),
				),
			]);
	}
}

export default vwAssetsOverview;
