import m from 'mithril'
import { _t } from '../../../lib/i18n';
import sjefIconServerCog from '@sjefapp/sjeficons/icons/sjefIconServerCog';
import sjefButton from '../../../views/components/sjefButton';
import { Tooltip } from 'construct-ui';
import sjefTabs from './sjefTabs';
import { withHooks } from 'mithril-hooks';

const sjefDataHeader = withHooks(({ fields, listTabs, moduleName, actions, listButtons, selectedRecords, archiveRecords, deleteRecords, isListTabActive, state }) => {
	const { setIsDrawerOpen } = state;

	return m(
		'.flexer.end',
		m('',
			m('h1', _t(`modules.${moduleName}.title`)),
			sjefTabs({ listTabs, listTabIndexActive: 0, moduleName, isListTabActive }),
		),
		m('.actions.flexer', [
			fields && moduleName
				? m(Tooltip, {
					content: 'Table layout',
					position: 'top-end',
					hasArrow: false,
					trigger: [
						m(
							'.iconHover',
							m(sjefButton, {
								buttonLayout: 'empty',
								label: m(sjefIconServerCog),
								onclick: () => (setIsDrawerOpen(true)),
							})
						),
					]
				})
				: void 0,
			listButtons?.map(function (mComponent) {
				return m(mComponent.component, mComponent.componentAttrs);
			}),
			actions?.canDelete()
				?
				[
					m(sjefButton, {
						buttonLayout: 'ghost',
						label: _t('_.buttons.archive'),
						disabled: !selectedRecords.length,
						onclick: () => archiveRecords(),
					}),
					m(sjefButton, {
						buttonLayout: 'ghost',
						label: _t('_.buttons.delete'),
						disabled: !selectedRecords.length,
						onclick: () => deleteRecords(),
					}),
					// 	),
					// }),
				]
				: void 0,
			actions?.canCreate() &&
			m(sjefButton, {
				buttonLayout: 'filled',
				label: 'New',
				onclick: () => m.route.set(`${config.route}/new`),
			}),
		])
	)
})

export default sjefDataHeader
