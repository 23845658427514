import i18next from 'i18next';
import app from '../app';
import en from '../lang/en.json';
import nl from '../lang/nl.json';
import fr from '../lang/fr.json';
import de from '../lang/de.json';
import es from '../lang/es.json';
import it from '../lang/it.json';
import moment from 'moment';
import FeatureFlags, { notSupportedLanguagesFlag } from '../featureFlags';

export const languageResource = {
	en: { translation: en },
	nl: { translation: nl },
	...(FeatureFlags.isEnabled(notSupportedLanguagesFlag) && {
		it: { translation: it },
		fr: { translation: fr },
		de: { translation: de },
		es: { translation: es },
	}),
};

export const initLocales = async () => {
	await i18next.init({
		lng: 'en',
		fallbackLng: ['nl', 'fr', 'de', 'es', 'it'],
		resources: languageResource,
		nsSeparator: false, // ':',
		keySeparator: '.',

		initImmediate: false,
		interpolation: {
			escapeValue: false,
			skipOnVariables: false,
			format: function (value, format, lng) {
				if (format === 'UPPER') return value.toUpperCase();
				if (format === 'LOWER') return value.toLowerCase();
				if (format === 'INT') {
					return Math.floor(value) || 0;
				}
				if (format === '2DEC') {
					return Number.parseFloat(value).toFixed(2);
				}
				if (format === '1DEC') {
					return Number.parseFloat(value).toFixed(1);
				}
				if (format === 'EUR') {
					const formatter = new Intl.NumberFormat(lng, {
						style: 'currency',
						currency: 'EUR',
					});
					if (typeof value === 'string') {
						value = parseFloat(parseFloat(value).toFixed(2));
					} else {
						value = parseFloat(value.toFixed(2));
					}
					return formatter.format(value);
				}
				if (format === 'GBP') {
					const formatter = new Intl.NumberFormat('en-GB', {
						style: 'currency',
						currency: 'GBP',
					});
					if (typeof value === 'string') {
						value = parseFloat(parseFloat(value).toFixed(2));
					} else {
						value = parseFloat(value.toFixed(2));
					}
					return formatter.format(value);
				}
				if (format === 'USD') {
					const formatter = new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
					});
					if (typeof value === 'string') {
						value = parseFloat(parseFloat(value).toFixed(2));
					} else {
						value = parseFloat(value.toFixed(2));
					}
					return formatter.format(value);
				}
				// if (format === 'EURNL'){
				//     let formatter = new Intl.NumberFormat('nl-NL', {
				//         style: 'currency',
				//         currency: 'EUR',
				//     });
				//     if(typeof value === "string"){
				//         value = parseFloat(parseFloat(value).toFixed(2));
				//     }else{
				//         value = parseFloat(value.toFixed(2));
				//     }
				//     return formatter.format(value);
				// }
				if (value instanceof Date) return moment(value).format(format);
				if (moment.isMoment(value) && value.isValid()) return value.format(format);
				if (format && format.startsWith('PAD')) {
					// console.log('PAD ', format, value);
					try {
						const padLength = parseInt(format.slice(3));
						// console.log('PAD length', padLength);
						if (isNaN(padLength)) {
							return value;
						} else {
							if (typeof value !== 'string') value = value.toString();
							value = value.padStart(padLength, ' ');
							// console.log('padded value', value);
							return value;
						}
					} catch (err) {
						// logger.error(err);
						return value;
					}
				}
				return value;
			},
		},
	});
};

i18next.on('initialized', () => {
	const language = app.restore('language') ?? 'en';

	changeLang(language);
});

export const changeLang = (lang) => {
	// logger.debug('changeLang {}', lang)();
	app.language = lang;
	i18next.changeLanguage(lang, (error) => {
		if (error) {
			console.log(error);
		}
	});

	app.store('language', lang);

	setTimeout(() => {
		document.body.setAttribute('lang', lang);
	}, 300);
};

export const currentLanguage = () => {
	return i18next.language;
};

export const _t = (key, options) => i18next.t(key, options);
export const _ct = _t;
