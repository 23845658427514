import { Input } from 'construct-ui';
import m, { redraw } from 'mithril';
import moment from 'moment';
import DatePicker from './DatePicker';

/**
 * @namespace
 * @property {date} startDate
 * @property {import('moment')} endDate
 */

export class dateInput {
	left = 0;
	top = 0;
	id;

	constructor() {
		this.start = moment().startOf('day'); //.subtract(1, 'day'),
	}

	closeComponent = () => {
		document.querySelector('.daterangepicker').style.display = 'none';
	};

	handleScroll = () => {
		this.closeComponent();
	};

	oninit(vnode) {
		this.start = vnode.attrs.start && moment(vnode.attrs.start || this.start);

		document.querySelector('.colMain').addEventListener('scroll', this.handleScroll); // todo: close on scroll?
	}

	// fixOffsetDropdown(vnode) {
	// 	const DomRect = document.getElementById(this.id + '_1').getBoundingClientRect();
	// 	this.left = DomRect.left;
	// 	this.top = DomRect.top;
	// 	const self = this;

	// 	setTimeout(function() {
	// 	const daterangepicker = document.querySelector('.daterangepicker');
	// 		daterangepicker.style.left = self.left + 'px';
	// 		daterangepicker.style.top = self.top + 'px';
	// 	}, 100);
	// }

	// oncreate (vnode) {
	// 	this.id = vnode.attrs.id;

	// 	this.fixOffsetDropdown(vnode);

	// 	  const handleResize = () => {
	// 		this.fixOffsetDropdown(vnode);
	// 		// Perform actions on resize
	// 	  };

	// 	  // Attach the event listeners
	// 	  window.addEventListener('resize', handleResize);

	// 	  // Store the event handlers in the component's state
	// 	  dateInput.scrollHandler = handleScroll;
	// 	  dateInput.resizeHandler = handleResize;
	// }

	onremove() {
		// Remove the event listeners when the component is removed
		// document.querySelector('.colMain').removeEventListener('scroll', dateInput.scrollHandler);
		// window.removeEventListener('resize', dateInput.resizeHandler);
	}

	view({ attrs: { id, start, minDate, cb } }) {
		// console.log('dateInput', start);

		return m('.cui-datepicker', [
			m(Input, {
				id: 'date-picker-id',
				class: 'cui-datepicker-end',
				readonly: true,
				// 'data-left': this.left,
				// 'data-top': this.top,
				contentRight: m(DatePicker, {
					id: `${id}_1`,
					range: false,
					options: {
						timePicker: true,
						timePicker24Hour: true,
						startDate: start,
						minDate: minDate || moment(),
						maxDate: moment().add(1, 'year'),
					},
					cb: (...vals) => {
						this.start = vals[0];
						if (typeof cb === 'function') {
							cb(this.start);
						}
						redraw();
					},
				}),
				value: !this.start ? '' : moment(this.start).format('DD-MM-YYYY HH:mm:ss'),
			}),
			// m('.blur'),
		]);
	}
}
