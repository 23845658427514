import accounts from './accounts';
import administrations from './administrations';
import areas from './areas';
import branches from './branches';
import categories from './categories';
import cashfunctions from './cashfunctions';
import clerks from './clerks';
import clients from './clients';
import courses from './courses';
import customerProfiles from './customerProfiles';
import departments from './departments';
import displaygroups from './displaygroups';
import events from './events';
import lockerEvents from './lockerEvents';
import items from './items';
import locations from './locations';
import menus from './menus';
import modifiers from './modifiers';
import organisations from './organisations';
import paymentmethods from './paymentmethods';
import paymentprofiles from './paymentprofiles';
import paymentserviceproviders from './paymentserviceproviders';
import payouts from './payouts';
import psps from './psps';
import projects from './projects';
import reportReconciliation from './reportReconciliation';
import settlements from './settlements';
import subtotals from './subtotals';
import suppliers from './suppliers';
import taxrates from './taxrates';
import tickets from './tickets';
import cashfunctions from './cashfunctions';
import areas from './areas';
import organisations from './organisations';
import clients from './clients';
import cabinets from './cabinets';
import administrations from './administrations';
import paymentmethods from './paymentmethods';
import modifiers from './modifiers';
import transactions from './transactions';
import payments from './payments';
import assets from './assets';
import lockers from './lockers';
import maintenance from './maintenance';
import balanceMutations from './balance_mutations';
import invoices from './invoices';

const configs = {
	accounts,
	administrations,
	areas,
	balanceMutations,
	branches,
	categories,
	cashfunctions,
	clerks,
	clients,
	courses,
	customerProfiles,
	departments,
	displaygroups,
	events,
	lockerEvents,
	cabinets,
	items,
	invoices,
	locations,
	lockers,
	maintenance,
	menus,
	modifiers,
	organisations,
	paymentmethods,
	modifiers,
	tickets,
	transactions,
	payments,
	psps,
	assets,
	paymentprofiles,
	paymentserviceproviders,
	payouts,
	projects,
	reportReconciliation,
	settlements,
	subtotals,
	suppliers,
	taxrates,
	tickets,
};

export default configs;
