import m from 'mithril';
import sjefInput from '../../../../views/components/sjefInput';
import { useEffect, withHooks } from 'mithril-hooks';

const clientConfigForm = withHooks(({ form, setForm, key = '0:0:pos', item = { merchantAccount: '', balanceAccountId: '', storeId: 0 } }) => {

	useEffect(() => {
		form = { ...item, ...form };
		form.type = type;
		form.administrationId = administrationId;
		form.locationId = locationId;
		setForm(form)
	}, [])

	const [administrationId, locationId, type] = key.toString().split(':');

	return m('sjefForm', [
		m(sjefInput, {
			label: 'Administration Id',
			placeholder: 'Administration Id',
			type: 'number',
			initialValue: administrationId,
			oninput: e => { setForm({ ...form, administrationId: e.target.value }) }
		}),
		m(sjefInput, {
			label: 'Location Id',
			placeholder: 'Location Id',
			type: 'number',
			initialValue: locationId,
			oninput: e => { setForm({ ...form, locationId: e.target.value }) }
		}),
		m(sjefInput, {
			label: 'Type',
			placeholder: 'Type',
			initialValue: type,
			oninput: e => { setForm({ ...form, type: e.target.value }) }
		}),
		m(sjefInput, {
			label: 'Merchant Account',
			placeholder: 'Merchant Account',
			initialValue: item.merchantAccount,
			oninput: e => { setForm({ ...form, merchantAccount: e.target.value }) }
		}),
		m(sjefInput, {
			label: 'Balance Account',
			placeholder: 'Balance Account',
			initialValue: item.balanceAccountId,
			oninput: e => { setForm({ ...form, balanceAccountId: e.target.value }) }
		}),
		m(sjefInput, {
			label: 'Store',
			placeholder: 'Store',
			initialValue: item.storeId,
			oninput: e => { setForm({ ...form, storeId: e.target.value }) }
		}),
		m(sjefInput, {
			type: 'select',
			options: [
				'EU',
				'UK'
			],
			label: 'Region',
			initialValue: item.region,
			oninput: e => { setForm({ ...form, region: e.target.value }) }
		}),
		m(sjefInput, {
			label: 'paymentConfigId',
			placeholder: 'paymentConfigId',
			type: 'number',
			initialValue: item.paymentConfigId,
			value: form.paymentConfigId,
			oninput: e => { setForm({ ...form, paymentConfigId: e.target.value }) }
		}),
	]
	)
})
export default clientConfigForm;
