import m from 'mithril';
import Ajv from 'ajv';
// require('ajv-keywords')(Ajv, ['formatMinimum', 'formatMaximum']);

// Extend AJV with formats
import addFormats from 'ajv-formats';

// import { _t } from '../../lib/i18n';
import { parseUTC } from '../../lib/helpers';
import app from '../../app';

/**
 * @class
 * @classdesc Base class for form record views.
 */
const FormTrait = {
	form: {},
	formDirtyFields: [],
	formErrors: {},
	formLoading: false,

	/**
	 * Fetches a record and sets the title.
	 *
	 * @param {string} recordId - The ID of the record to fetch.
	 */
	setForm: function () {
		this.formLoading = true;
		this.form = {};
		this.formDirtyFields = [];
		this.formErrors = {};

		const data = JSON.parse(JSON.stringify(this.record || {}));

		if (this.schema && this.schema.required) {
			this.schema.required.forEach((field) => {
				this.form[field] = this.schema.properties[field].default || (this.schema.properties[field].type.includes('null') ? null : '');

				// translations
				if (this.record.hasOwnProperty(field)) {
					this.form[field] = data[field];
				}
			});
		} else {
			console.log('Schema is not defined or does not have required fields');
		}

		this.formLoading = false;
	},

	/**
	 * Validates a form field.
	 *
	 * @param {string} fieldName - The name of the field to validate.
	 */
	validateField: function (fieldName) {
		const errors = this.validateForm();

		if (errors[fieldName]) {
			this.formErrors[fieldName] = errors[fieldName];
		} else {
			delete this.formErrors[fieldName];
		}

		console.log(this.formErrors);
	},

	/**
	 * Validates the form based on the provided schema.
	 *
	 * @returns {Object} - The map of field errors.
	 */
	validateForm: function () {
		const ajv = new Ajv({ allErrors: true, $data: true });
		addFormats(ajv);

		// Clone the form and convert problematic values to strings
		const formToValidate = {};
		Object.keys(this.form).forEach(field => {
			const value = this.form[field];
			// Convert to string if it's a number
			// if (typeof value === 'number') {
			// 	formToValidate[field] = String(value);
			// } else {
			formToValidate[field] = value;
			// }
		});

		const validate = ajv.compile(this.schema);
		const valid = validate(formToValidate);

		if (valid) {
			return {};
		}

		return validate.errors.reduce((errorMap, error) => {
			const fieldName = error.instancePath.substring(1);
			const message = error.message === 'must NOT have fewer than 1 characters' ? 'required' : error.message;
			errorMap[fieldName] = message;
			return errorMap;
		}, {});
	},

	/**
	 * Handle form submission. If successful, a success toast message will be displayed and an optional redirection will occur.
	 *
	 * @param {boolean} [redirect=true] - A flag indicating whether to redirect after form submission.
	 * @return {Promise<void>}
	 */
	onSubmit: async function (redirect = true) {
		// this.formErrors = this.validateForm();

		// if (Object.keys(this.formErrors).length > 0) {
		// 	return;
		// }

		let method = 'post';
		if (this.record.id) {
			this.form.id = this.record.id;
			method = 'patch';
		}


		this.config.schema.forEach(
			(item) =>
				item.fields &&
				item.fields.some((field) => {
					field.allowEmptyValue && console.warn(field.name, this.form[field.name]);
					field.allowEmptyValue ? this.form[field.name] = this.form[field.name] || '' : null;
				})
		);

		const result = await app[method](`${this.endpoint}`, this.form);

		if (result.success === true) {
			app.toast('Success', { intent: 'positive' });

			this.formDirtyFields = [];

			if (redirect === true) {
				return m.route.set(this.route);
			}

			if (typeof redirect === 'function') {
				return redirect();
			}

			if (method === 'post') {
				m.route.set(`${this.route}/${result.data.id}`);
				this.oninit({ attrs: { id: result.data.id } });
			}

			return;
		}

		app.toast(result.message || 'failed', { intent: 'negative' });
	},

	/**
	 * Sets the value of a field and validates it.
	 *
	 * @param {Object} data - Field data.
	 * @param {string} data.field - Field name.
	 * @param {string} data.value - Field value.
	 */
	setValue: function (data, type = 'string', callback = void 0) {
		// Ensure data.value is processed correctly based on the type
		if (type === 'checkbox') {
			data.value = !!data.value;
		} else if (type === 'number' && data.value !== null) {
			// Since it's a number, ensure we're dealing with a number type
			const numValue = parseFloat(data.value);
			if (!isNaN(numValue)) {
				data.value = numValue;
			} else {
				console.error('Failed to convert to number:', data.value);
				data.value = 0; // Or any fallback value you consider appropriate
			}
		} else if (type === 'date') {
			// Handle date conversion if necessary
			data.value = parseUTC(data.value); // Assuming parseUTC is a function that correctly formats/parses the date
		} else if (typeof data.value === 'string') {
			// Only attempt to use .trim() if it's a string
			// data.value = data.value.trim();
			// data.value = String(data.value)
		}

		// Check for changes and update formDirtyFields accordingly
		if (typeof data.value === 'string' ? data.value.trim() !== (data.valueOriginal || '').toString().trim() : data.value !== data.valueOriginal) {
			if (!this.formDirtyFields.includes(data.field)) {
				this.formDirtyFields.push(data.field);
			}

		} else {
			this.formDirtyFields = this.formDirtyFields.filter(item => item !== data.field);
		}

		if ((data.value instanceof Event) === false) {
			this.form[data.field] = data.value;
			this.validateField(data.field);
		}

		if (callback && typeof callback === 'function') {
			callback(data.record);
		}
	},

	// /**
	//  * View function for mithril.js. Override in subclasses.
	//  */
	// view() {
	// 	return m('', 'No view implemented');
	// }

	/**
	 * Parse the value to its type
	 * @param {string} type
	 * @param {string} value
	 * @return {Object}
	 */
	// parseValue(type, value) {
	// 	console.log('++++', type);
	// 	if (type === 'integer' && value !== null) {
	// 		const parsedValue = parseInt(value);
	// 		if (!isNaN(parsedValue)) {
	// 			return parsedValue;
	// 		}
	// 	} else if (type === 'boolean' && value !== null) {
	// 		console.log('parseValue', type, value);
	// 		if (typeof value === 'string') {
	// 			return value.toLowerCase() === 'true';
	// 		} else if (typeof value === 'boolean') {
	// 			return value;
	// 		}
	// 	}
	// 	return value;
	// },
};

export default FormTrait;
