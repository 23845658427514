
/* eslint:disable */
import m from 'mithril';

class sjefIconAdjustmentsHorizontal {
    view({ attrs: { classes = [], size = "24", strokeWidth = "2", color = "currentColor", fill = "none", onclick = void(0), ...htmlAttrs  }}){
        return m('.sjefIcon.sjefIconAdjustmentsHorizontal',{
                onclick,
                class  : ['icon-sjefIcon2Fa', ...classes].join(" "),
                ...htmlAttrs
            },
            m('svg[xmlns=http://www.w3.org/2000/svg].icon-svg', {
                width  : size || "24",
                height : size || "24",
                stroke : color || "currentColor",
                fill   : fill || "none",
                "stroke-width": strokeWidth || "2",
                "viewBox": "0 0 24 24",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            }, m.trust('<path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="14" cy="6" r="2" /><line x1="4" y1="6" x2="12" y2="6" /><line x1="16" y1="6" x2="20" y2="6" /><circle cx="8" cy="12" r="2" /><line x1="4" y1="12" x2="6" y2="12" /><line x1="10" y1="12" x2="20" y2="12" /><circle cx="17" cy="18" r="2" /><line x1="4" y1="18" x2="15" y2="18" /><line x1="19" y1="18" x2="20" y2="18" />'))
        )
    }
}
export default sjefIconAdjustmentsHorizontal;

