import { Dialog } from 'construct-ui';
import m from 'mithril';
import sjefButton from './sjefButton';
import { _t } from '../../lib/i18n';


class reloadModal {
	view(vnode) {
		return m('.reloadModal', 
			m('.backdrop'),
			m(Dialog, {
				isOpen: vnode.attrs.isOpen,
				autofocus: true,
				basic: true,
				closeOnEscapeKey: false,
				closeOnOutsideClick: false,
				hasBackdrop: false,
				inline: true,
				transitionDuration: this.transition ? 200 : 0,
				title: _t('_.modals.reload.title'),
				content: _t('_.modals.reload.description'),
				footer: [
					m(sjefButton, {
						buttonLayout: 'filled',
						label: 'reload',
						onclick: () => {
							window.location.reload();
						}
					})
				]
			}, 
			))
	}
}

export default reloadModal