import m from 'mithril';
import { Table } from 'construct-ui';
import app from '../../app';
import { downloadBase64File } from '../../lib/helpers';
import sjefButton from '../../views/components/sjefButton';
import sjefIconDownload from '@sjefapp/sjeficons/icons/sjefIconDownload';

class vwInvoices {
	records = [];
	async oninit() {
		await this.fetchData();
	}

	fetchData = async () => {
		const response = await app.post('/wefact/invoices/list');
		this.records = response.data;
	};

	downloadInvoice = async (invoiceId) => {
		const result = await app.post('/wefact/invoices/download', { invoiceId });
		if (result.success) {
			downloadBase64File(result.data.base64, result.data.filename);
		}
	}

	view() {
		return m(
			Table,
			{
				bordered: this.bordered,
				interactive: this.interactive,
				striped: this.striped,
			},
			[
				m('tr', [
					m('th', 'date'),
					m('th', 'code'),
					m('th', 'debtorName'),
					m('th', 'amountIncl'),
					m('th', 'status'),
					m('th', 'download')]),
				this.records && this.records.length > 0 &&
				this.records.map((record) => {
					return m('tr.large', [
						m('td', record.date),
						m('td', record.code),
						m('td', record.debtorName),
						m('td', record.amountIncl),
						m('td', record.status),
						m('td',
							m(
								sjefButton,
								{
									label: m(sjefIconDownload),
									buttonLayout: 'iconHover',
									onclick: async () => {
										await this.downloadInvoice(record.id);
									},
								},

							)
						),
					]);
				}),
			]
		);
	}
}

export default vwInvoices;
