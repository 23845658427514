import m from 'mithril';
import { Table } from 'construct-ui';
import sjefModal from '../../../views/components/sjefModal';
import clientConfigForm from './clientConfig/clientConfigForm';
import app from '../../../app';
import { withHooks, useState, useEffect } from 'mithril-hooks';
import { _t } from '../../../lib/i18n';

const vwClientAdyenClientConfig = withHooks((attrs) => {
	const fetchData = async (clientId) => {
		setLoading(true);
		// Fetch data from the API
		const response = await app.get(`/clients/clientConfigs/${clientId}`)
		setData(response.data)
		setLoading(false);

	}

	useEffect(() => {
		setClientId(attrs.clientId)
		setForm({ clientId: attrs.clientId });
		fetchData(attrs.clientId);
	}, []);

	const [clientId, setClientId] = useState('');
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({});


	const submitConfig = async () => {
		const response = await app.post(`/clients/clientConfigs`, form);

		if (response.success) {
			app.toast('success', { intent: 'positive' })
			fetchData(clientId);
			return true;
		}

		app.toast(response.message, { intent: 'negative' });
	}

	if (loading) {
		return m('div', 'Loading...');
	}

	return [
		m(sjefModal, {
			buttonLabel: _t('clients.clientConfig.modal.new.button'),
			modalTitle: _t('clients.clientConfig.modal.new.title'),
			buttonLayout: 'filled',
			modalContent: m(clientConfigForm, { form, setForm }),
			onSubmit: submitConfig
		}), m(Table, {
			style: { marginTop: '1rem' },
		}, [
			m('tr', [
				m('th', { style: { width: '10%' }, title: _t('clients.clientConfig.type') }, _t('clients.clientConfig.type')),
				m('th', { style: { width: '20%' }, title: _t('clients.clientConfig.merchantAccount') }, _t('clients.clientConfig.merchantAccount')),
				m('th', { title: _t('clients.clientConfig.balanceAccountId') }, _t('clients.clientConfig.balanceAccountId')),
				m('th', { title: _t('clients.clientConfig.storeId') }, _t('clients.clientConfig.storeId')),
				m('th', { style: { width: '5%' }, title: _t('clients.clientConfig.region') }, _t('clients.clientConfig.region')),
				m('th', { style: { width: '5%' }, title: _t('clients.clientConfig.paymentConfigId') }, _t('clients.clientConfig.paymentConfigId')),
				m('th', { style: { width: '10%' } }, '')

			]),
			data && Object.entries(data).map(([key, item]) => {
				return m('tr', [
					m('td', key),
					m('td', item.merchantAccount),
					m('td', item.balanceAccountId),
					m('td', item.storeId),
					m('td', item.region),
					m('td', item.paymentConfigId),
					m('td', m(sjefModal, {
						buttonLabel: _t('clients.clientConfig.modal.edit.button'),
						modalTitle: _t('clients.clientConfig.modal.edit.title'),
						buttonLayout: 'filled',
						modalContent: m(clientConfigForm, { form, setForm, key, item }),
						onSubmit: submitConfig
					}))

				]);
			})
		])]

});
export default vwClientAdyenClientConfig;
