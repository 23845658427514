import app from '../app';

const auth = {
	validateRouteAccess: (accessRules) => {
		if (!accessRules) {
			return true;
		}

		// users with super role are always allowed
		if (app.user.roles.includes('super')) {
			return true;
		}

		if (accessRules?.plan && app.user.plan < accessRules.plan) {
			return false;
		}

		if (accessRules?.role && !accessRules.role.some((role) => app.user.roles.includes(role))) {
			return false;
		}

		if (accessRules?.modules && !accessRules.modules.some((modules) => app.user.modules.includes(modules))) {
			return false;
		}

		return true;
	},

	// todo
	canAccessModule: (module) => {},
};

export default auth;
