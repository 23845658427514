import m from 'mithril';
import { Toaster } from 'construct-ui';
import { initRoutes } from './routes';

const app = {
	isAuthenticated: false, // DEV set to true to prevent login as default view
	apiRoot: '',
	// apiVersion: 'v1',
	menu: [],
	menuArray: [],
	menuSelected: [],
	menuOpen: [],
	routes: {},
	apiToken: false,
	tfa: false,
	user: {},
	userSettings: {},
	timestamp: '',
	activeTab: 'sjef',
	version: '',
	currencySymbol: '€',
	language: 'en',
	mode: 'day',
	events: {
		reloadRequired: false,
	},
	init: async ({ apiRoot = '' }) => {
		app.apiRoot = apiRoot;
		// app.apiVersion = apiVersion;
		app.apiToken = app.restore('token', false);
		app.lvl = app.restore('lvl', 0);
		app.tfa = app.restore('tfa', false);
		app.activeTab = app.restore('activeTab', 'sjef');

		if (app.apiToken) {
			const authResult = await app.get('/auth/checkAuth');

			if (authResult?.data?.loggedin && app.tfa !== false) {
				app.user = authResult.data.identity;

				await app.setUser({
					lvl: app.lvl,
					identity: authResult.data.identity,
				});

				// if user has passed tfa
				if (app.lvl > 1) {
					app.isAuthenticated = true;
					await app.boot();
				}

				// console.log('AUTHENTICATED!', authResult);
				return Promise.resolve();
			} else {
				app.logout(false);
			}
		}

		// console.warn('NOT AUTHENTICATED!');
		return Promise.resolve();
	},

	boot: async () => {
		console.log('bootApplication');
		app.accessibleComponents = [];

		// const resultComponents = await app.get('/clients/me/components');
		// console.log(resultComponents);
		// if (resultComponents.error) {
		// 	alert('Could not load components');
		// }
		// app.accessibleComponents = resultComponents.data.components;
	},
	toast: (msg, opts) => {
		let options = {
			key: Date.now(),
			intent: 'default',
			size: 'default',
			timeout: 5000,
			message: msg,
		};
		options = Object.assign(options, opts);
		const toasts = app.toaster.getToasts(options.key);
		if (toasts.length) {
			app.toaster.update(options.key, options);
			app.toaster.show(options);
		} else {
			app.toaster.show(options);
		}
	},

	toaster: new Toaster(),

	logout: async (notifyUser = true) => {
		await app.get('/auth/logout');
		app.store('token', null);
		app.isAuthenticated = false;
		app.apiToken = false;
		app.tfa = false;
		app.store('tfa', false);
		app.user = {};

		if (notifyUser) {
			app.toast('Logged out', { intent: 'positive' });
		}

		m.route.set('/login');
	},
	store: (key, val) => {
		if (val === null) {
			localStorage.removeItem(`dash:${key}`);
		} else {
			val = JSON.stringify(val);
			localStorage.setItem(`dash:${key}`, val);
		}
	},
	restore: (key, defaultValue = '') => {
		// console.log('restore', key);
		let val = localStorage.getItem('dash:' + key);

		if (!val) return defaultValue;
		try {
			val = JSON.parse(val);
			// console.log('restored', key, val)
			return val;
		} catch (err) {
			return val;
		}
	},
	loadStylesheet: async (stylesheet = '') => {
		if (!stylesheet.endsWith('.css')) stylesheet = stylesheet + '.css';
		let el = document.getElementById(stylesheet);
		if (!el && stylesheet) {
			// el.setAttribute("id", stylesheet);
			const cssResult = await fetch(stylesheet);
			if (cssResult.ok) {
				const css = await cssResult.text();
				(el = document.createElement('style')), { id: stylesheet };
				el.textContent = css;
				document.head.appendChild(el);
			}
		}
	},

	/**
	 *
	 * @param {Object} state Pass new params into state, avaliable in attrs of vnode
	 */
	go: (state) => {
		document.body.classList.remove('mobile-menu-open');
		m.route.set(m.route.get(), {}, { state });
	},
	/**
	 * Navigate to module by ID
	 * @param {String} id
	 * @returns {Boolean} true or false, when menuItem can be found or not and when found, navigates to that route
	 */
	goto: (id, params = {}) => {
		const menuItem = app.menuArray.find((n) => n.id === id);
		if (menuItem && 'path' in menuItem) {
			// console.log('goto: ', menuItem.path);
			menuItem.isOpen = true;
			m.route.set(menuItem.path, params);
			return true;
		} else {
			console.warn('No path for menuitem', id);
			// app.toast(`Cannot navigate to ${id}, not allowed or invalid`, { intent: 'warning', id: 'app.goto', position: 'bottom'})
			return false;
		}
	},
	createHeaders: () => {
		const h = {};
		if (app.apiToken) h.Authorization = `Bearer ${app.apiToken}`;
		return h;
	},

	get: async (url, params = {}, showError = true) => {
		console.log(url);

		try {
			// console.log('get', url, app.apiRoot, app.apiToken);

			const getUrl = url.startsWith('http') ? url : app.apiRoot + url;
			const headers = app.createHeaders();

			const result = await m.request({
				url: getUrl,
				method: 'GET',
				headers: headers,
				params: params,
				// withCredentials: true
			});
			if (result.success === false && showError) {
				app.toast(result.message || 'unknown error', {
					key: 'getError',
					intent: 'negative',
				});
			}
			// console.debug('app.get', result);
			return result;
		} catch (err) {
			console.log(err);
			console.warn(err);
			const errMsg = '[' + url + ']: ' + (typeof err === 'string' ? err : err.response?.error || err.message || JSON.stringify(err, null, 2));
			app.toast(errMsg, {
				key: 'getError',
				intent: 'negative',
			});
			return {
				error: `Could not get API data`,
				code: err.code,
				response: err.response,
			};
		}
	},
	patch: async (url, data = {}) => {
		try {
			const portUrl = url.startsWith('http') ? url : app.apiRoot + url;
			const result = await m.request({
				url: portUrl,
				method: 'PATCH',
				headers: app.createHeaders(),
				body: data,
				// withCredentials: true
			});
			return result;
		} catch (err) {
			app.toast(err.message || err, {
				key: 'postError',
				intent: 'negative',
			});
			return {
				result: 'error',
				data: [],
				error: `Could not get API data [${err.message || err}]`,
			};
		}
	},
	post: async (url, data = {}) => {
		try {
			const portUrl = url.startsWith('http') ? url : app.apiRoot + url;
			const result = await m.request({
				url: portUrl,
				method: 'POST',
				headers: app.createHeaders(),
				body: data,
				// withCredentials: true
			});
			return result;
		} catch (err) {
			app.toast(err.message || err, {
				key: 'postError',
				intent: 'negative',
			});
			return {
				result: 'error',
				data: [],
				error: `Could not get API data [${err.message || err}]`,
			};
		}
	},

	delete: async (url, data) => {
		try {
			const portUrl = url.startsWith('http') ? url : app.apiRoot + url;
			const result = await m.request({
				url: portUrl,
				method: 'DELETE',
				headers: app.createHeaders(),
				body: data,
				// withCredentials: true
			});
			return result;
		} catch (err) {
			app.toast(err.message || err, {
				key: 'deleteError',
				intent: 'negative',
			});
			return {
				result: 'error',
				data: [],
				error: `Could not get API data [${err.message || err}]`,
			};
		}
	},

	isIdle: false,
	lastActivity: 0,
	setIdle: () => {
		app.isIdle = true;
		document.body.classList.add('userIdle'); // only add on first fire
		console.debug(`User is idle, screensaver started`);
	},
	clearIdle: () => {
		app.isIdle = false;
		document.body.classList.remove('userIdle');
	},
	checkIdle: () => {
		const idleAfter = 12000; // 120 seconds = 2 minutes
		app.isIdle = false;
		app.lastActivity = Date.now();

		function userIdleCheck() {
			if (!app.isIdle && Date.now() - app.lastActivity >= idleAfter * 1000) {
				app.setIdle();
			}
		}

		function timerReset(ev) {
			if (ev.type !== 'mousemove' && app.isIdle) {
				app.clearIdle();
			}
			app.lastActivity = Date.now();
		}

		window.addEventListener('mousemove', timerReset);
		window.addEventListener('keypress', timerReset);
		window.addEventListener('click', timerReset);

		setInterval(userIdleCheck, 1000); // fire every x second
	},

	switchAccessLevelParent: async () => {
		const levels = {
			9: {
				type: 'organisation',
			},
			8: {
				id: 'oid',
				type: 'organisation',
				value: app.user.oid,
			},
			7: {
				id: 'cid',
				type: 'client',
				value: app.user.cid,
			},
			6: {
				id: 'aid',
				type: 'administration',
				value: app.user.aid,
			},
		};

		if (app.lvl >= 9) {
			console.log('Cannot switch to parent (max lvl reached)');
			return;
		}

		if (app.user.lvl <= app.lvl) {
			console.log('Cannot switch to parent');
			return;
		}

		const newAccessLevel = app.lvl + 1;
		const level = levels[newAccessLevel];
		app.switchAccessLevel(newAccessLevel, level.type, { [level.id]: level.value });
	},

	setUser: async (data) => {
		console.log('setUser', data);

		// console.log(data.lvl = 9)

		if (data.lvl) {
			app.lvl = data.lvl;
			app.store('lvl', data.lvl);
		}
		if (data.token) {
			app.user = data.identity;
			app.apiToken = data.token;
			app.store('token', data.token);
		}
		if (data.identity) {
			app.user = data.identity;
		}
		if (data.tfa) {
			app.tfa = data.tfa;
			app.store('tfa', data.tfa);
		}

		// get the user settings

		const result = await app.get('/user/settings');
		app.userSettings = result.data || {};
		console.log(app.userSettings);
	},

	switchAccessLevel: async (accessLevel, type, payload) => {
		app.store('activeTab', 'sjef');
		app.activeTab = 'sjef';

		try {
			const data = {
				lvl: accessLevel,
			};
			if (accessLevel < 9) {
				// const result = await app.post(`http://127.0.0.1:3999/switch/${type}`, payload);
				const result = await app.post(`/auth/switch/${type}`, payload);

				data.token = result.data.token;
				data.identity = result.data.identity;
				// app.apiToken = result.token;
				// app.user = result.identity;

				// app.store('token', result.token);
			}

			await app.setUser(data);

			const userResult = await app.get(`/user/profile`);
			if (userResult.success) {
				const currentUsername = userResult.data.administrationName || userResult.data.clientName || userResult.data.organisationName;
				app.store('currentUsername', currentUsername);
			}

			// app.lvl = accessLevel;
			// app.store('lvl', accessLevel);

			m.route.set('/switching', { type });

			// Delay route change to the next tick of the event loop
			setTimeout(() => {
				initRoutes(accessLevel, [''], true);

				m.route.set('/');
			}, 1100);
		} catch (e) {
			console.error('An error occurred while switching access levels:', e);
		}
	},
};

window.app = app;

export default app;
