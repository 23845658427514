/**
 * @fileoverview This module renders a table view of balance accounts using the Mithril.js framework and construct-ui library.
 * @module vwClientAdyenBalanceAccounts
 */
import m from 'mithril';
import { Card, FormGroup, FormLabel, Select } from 'construct-ui';
import sjefTier from '../../../views/components/sjefTier';
import sjefInput from '../../../views/components/sjefInput';
import sjefIcon123 from '@sjefapp/sjeficons/icons/sjefIcon123';

const maxTiers = 4;

const schema = {
	type: 'direct', // directExclPaymentfees
	tiers: [
		{
			fees: {
				domestic: {
					mc: {
						// paymentMethod
						debit: {},
						credit: {
							0: {
								// paymentMethodVariants defined in codebase
								fixed: 0.15,
								variable: 0.02,
							},
							1: {
								fixed: 0.15,
								variable: 0.01,
							},
							2: {
								fixed: 0.15,
								variable: 0.01,
							},
						},
					},
					visa: {
						debit: {},
						credit: {
							0: {
								fixed: 0.15,
								variable: 0.02,
							},
							1: {
								fixed: 0.2,
								variable: 0.03,
							},
						},
						default: {},
					},
					default: {
						debit: {
							0: {
								fixed: 0.15,
								variable: 0.02,
							},
						},
						credit: {
							0: {
								fixed: 0.15,
								variable: 0.02,
							},
						},
					},
				},
				crossborder: {}, // same structure as domestic
			},
			threshold: 1000,
		},
	],
	method: 'tiers',
	region: 'GB', // EU
	splits: [
		{
			type: 'fixed', // percentage
			value: 0.25,
			balanceAccount: 'BA3227C223222H5J5L4VXDH8P',
		},
	],
	taxrate: 0.21,
	configName: 'Test Paymentfee',
	thresholdType: 'nrOfTransactions',
	tierMaxAmount: 1000,
	balanceAccountClient: 'BA3227C223222H5HXPTR82TCH',
	balanceAccountClientPending: 'BA3222Z223226K5K85N8243V9',
	balanceAccountCommission: 'BA3222Z223226K5K85NBWBLT4',
	balanceAccountPaymentFees: 'BA32272223226K5K85ND538WC',
};

/**
 * The view module exports an object representing a Mithril component.
 * @namespace
 */
class vwClientAdyenPaymentScheme {
	/**
	 * @type {Array} balanceAccounts - An array to store balance account information.
	 */
	/**
	 * @type {string} primaryBalanceAccount - The ID of the primary balance account.
	 */

	/**
	 * Initializes the view component with provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {string} vnode.attrs.primaryBalanceAccount - The ID of the primary balance account.
	 * @param {Array} vnode.attrs.balanceAccounts - The balance account information.
	 */

	/**
	 * Renders the view component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	schema;
	validationStates = {};

	oninit() {
		this.schema = schema;
	}

	addNewTier = () => {
		if (this.schema && this.schema.tiers && this.schema.tiers.length > 0) {
			const lastTier = this.schema.tiers[this.schema.tiers.length - 1];
			const newTier = JSON.parse(JSON.stringify(lastTier));

			// Increase the threshold of the new tier by 1000
			newTier.threshold = '';

			this.schema.tiers.push(newTier);

			m.redraw();
		}
	};

	removeTier = (index) => {
		let tiers = JSON.parse(JSON.stringify(this.schema.tiers));
		if (tiers.length > 1) {
			tiers.splice(index, 1);
			this.schema.tiers = tiers;

			m.redraw();
		}
	};

	view() {
		return m('.adyen.stained', [
			m('h2', 'Tiers'),
			m(Card, { span: 4 }, [
				m('.columns-2',
					m(
						FormGroup,
						m('label', 'Method'),
						m(Select, {
							class: 'active-state-elem',
							options: ['Transactions', 'Volume'],
							name: 'method',
							value: this.schema.method,
							onchange: (evt) => {
								this.schema.method = evt.target.value;
							},
						})
					),
					m(
						FormGroup,
						m(sjefInput, {
							name: 'type',
							label: 'Type',
							value: this.schema.type,
							onChange: (newValue) => {
								this.schema.type = newValue;
							},
						})
					),
			
					m(
						FormGroup,
						m(sjefInput, {
							name: 'taxrate',
							label: 'Taxrate',
							type: 'number',
							value: this.schema.taxrate,
							onChange: (newValue) => {
								this.schema.taxrate = newValue
							},
						})
					),
				),
				m('.stain', [
				m('h3', 'Splits'),
					this.schema.splits.map((item, idx) => {
						return [
							// m('h4', idx + 1),
							m(FormGroup, [
								m('.columns-2',
									Object.keys(item).map((field) => {
										return [
											field == 'value' ? [
												m(sjefInput, {
													type: 'number',
													label: 'Value',
													value: item[field],
													onChange: (newValue) => {
														item[field] = newValue;
													}
												})
											] 
											: 
											field == 'type' ? [
												m(sjefInput, {
													type: 'select',
													label: 'Type',
													value: item[field],
													options: ['', 'fixed'],
													onChange: (newValue) => {
														item[field] = newValue;
													}
												})
											] 
											: field == 'balanceAccount' ? [
												m(FormGroup, {},
													m(sjefInput, {
														label: 'Balance Account',
														value: item[field],
														onChange: (newValue) => {
															item[field] = newValue;
														}
													})
												)
											] :
											void(0)
										]
									}),
								)
							]),
						];
					}),
				
				m(
					'.tiers',
					this.schema.tiers.map((tier, idx) =>
						m(sjefTier, {
							key: tier.threshold + '-' + idx, // A simple, more unique key
							tier: tier,
							tiersLength: this.schema.tiers.length,
							isValid: this.validationStates[`tier-${idx}`], 
							// Pass validation state

							onRemove: () => this.removeTier(idx),
							onValidate: (newThreshold) => {
								let tiers = this.schema.tiers;
								let tierBefore = tiers[idx - 1] ? parseFloat(tiers[idx - 1].threshold) : undefined;
								let tierAfter = tiers[idx + 1] ? parseFloat(tiers[idx + 1].threshold) : undefined;
								let tierCurrent = parseFloat(newThreshold);

								console.log(tierBefore, tierCurrent, tierAfter);
								// let valid = true;
								let valid = true;

								// Checking the ascending order
								// Case 1: Both 'before' and 'after' are defined
								if (tierBefore !== undefined && tierAfter !== undefined) {
									if (tierBefore < tierCurrent && tierCurrent < tierAfter) {
										console.log('valid - between');
										valid = true;
									} else {
										console.log('invalid - not in ascending order with neighbors');
										valid = false;
									}
								}
								// Case 2: Only 'before' is defined
								else if (tierBefore !== undefined) {
									if (tierBefore < tierCurrent) {
										console.log('valid - greater than before');
										valid = true;
									} else {
										console.log('invalid - not greater than before');
										valid = false;
									}
								}
								// Case 3: Only 'after' is defined
								else if (tierAfter !== undefined) {
									if (tierCurrent < tierAfter) {
										console.log('valid - less than after');
										valid = true;
									} else {
										console.log('invalid - not less than after');
										valid = false;
									}
								}
								// Case 4: Neither 'before' nor 'after' are defined
								else {
									console.log('valid - no neighbors');
									valid = true;
								}

								this.validationStates[`tier-${idx}`] = valid; // Store the validation state
								console.log(this.validationStates);
								m.redraw();
							},
						})
					),
					this.schema.tiers.length < maxTiers
						? m(
								'.tier.ghost',
								{
									onclick: () => {
										this.addNewTier();
									},
								},
								m('.addTier', '+')
						  )
						: void 0
				),
			]),
			]),
		]);
	}
}

export default vwClientAdyenPaymentScheme;
