/**
 * @fileOverview This module provides a form to delete a business line in Adyen Balance Platform.
 * @module vwClientAdyenBusinessLinesDeleteModal
 */

/**
 * Module dependencies.
 */
import m from 'mithril';
import { Form } from 'construct-ui';

import sjefModal from '../../../views/components/sjefModal';
import response from '../../../lib/response';
import app from '../../../app';
import sjefIconX from '@sjefapp/sjeficons/icons/sjefIconX';
import { _t } from '../../../lib/i18n';

/**
 * Object defining the view component of the form, including initialization and rendering logic.
 */
class vwClientAdyenBusinessLinesDeleteModal {
	/**
	 * @type {string} businessLineId - The ID of the businessline.
	 */
	businessLineId = '';

	/**
	 * @type {integer} clientId - The ID of the client.
	 */
	clientId = '';

	/**
	 * @type {Function} callbackOnSuccess - The callback function to be called when an operation completes successfully.
	 */
	callbackOnSuccess;

	/**
	 * Initialize the component with the provided attributes.
	 *
	 * @async
	 * @function
	 * @param {Object} vnode - The virtual DOM node.
	 * @param {Object} vnode.attrs - The attributes passed to the component.
	 * @param {integer} vnode.attrs.clientId - The ID of the client.
	 * @param {string} vnode.attrs.businessLineId - The ID of the client.
	 * @param {Function} vnode.attrs.callbackOnSuccess - The function to call when the action completes with success.
	 */
	oninit = async ({ attrs }) => {
		this.businessLineId = attrs.businessLineId;
		this.clientId = attrs.clientId;
		this.callbackOnSuccess = attrs.callbackOnSuccess;
	};

	/**
	 * Resets the component state.
	 * @function onOpen
	 */
	onOpen = () => {};

	/**
	 * Asynchronous function to handle form submission.
	 * Validates the form data according to the defined schema,
	 * makes a request to create a business line with the validated data,
	 * handles the response, and provides user feedback.
	 *
	 * @returns {object} a Promise resolving to a response object
	 */
	onSubmit = async () => {
		const result = await app.delete(`/balanceplatform/clients/${this.clientId}/businesslines/${this.businessLineId}`);

		if (result.success === false) {
			return response.failed('1111', result.error);
		}

		this.callbackOnSuccess();

		app.toast('Deleted', {
			intent: 'positive',
		});

		return response.success();
	};

	/**
	 * Render the component.
	 *
	 * @function
	 * @returns {Object} Mithril virtual DOM tree.
	 */
	view() {
		return m(sjefModal, {
			onOpen: this.onOpen,
			onSubmit: this.onSubmit,
			modalTitle: 'Delete Business Line',
			buttonLabel: [ m(sjefIconX), m('label', _t('_.buttons.delete'))],
			buttonLayout: 'ghost',
			modalContent: m(
				'',
				m(
					Form,
					{
						onsubmit: () => {
							return false;
						},
					},
					[m('', `Are you sure you want to delete business line: ${this.businessLineId}?`)]
				)
			),
		});
	}
}

export default vwClientAdyenBusinessLinesDeleteModal;
