import m from 'mithril';
import sjefButton from './sjefButton';
import { _t } from '../../lib/i18n';
import IconTitle from './iconTitle';
import { Card, Tabs } from 'construct-ui';

class Slider {
	activeSlideIndex = 0;
	tabs;

	oninit(vnode) {
		this.tabs = (vnode.attrs.slides.map(slide => slide.tabName))

		if (this.tabs.length !== vnode.attrs.slides.length) {
			throw new Error('The number of tab names should be equal to the number of slides')	
		}
	}

	view({ attrs }) {
		return m('.slider', [
			m(
				'.slides',
				attrs.slides.map((slide, index) => {
					return m(
						'.slide',
						{
							class: index === this.activeSlideIndex ? 'active' : 'inactive',
						},
						[
							m(
								Card,
								{
									class: 'padding-' + (attrs.padding || 'default'),
								},
								[
									m(IconTitle, {
										icon: slide.icon,
										title: slide.title,
										subTitle: slide.subTitle,
									}),
									m('.tabs', this.tabs.map(tab => {
										return m('div', {
											class: tab === this.tabs[this.activeSlideIndex] ? 'active' : '',
											// onclick: () => {
											// 	this.activeSlideIndex = this.tabs.indexOf(tab)
											// }
										}, tab)
									
									})),
									slide.tabContent,
									m('.buttons.flexer', [
										m(sjefButton, {
											buttonLayout: 'ghost large',
											label: _t('_.fields.previous'),
											onclick: () => {
												if (this.activeSlideIndex > 0) {
													this.activeSlideIndex--;
												}
											},
										}),
										m(sjefButton, {
											buttonLayout: 'filled large',
											label: _t('_.fields.next'),
											onclick: () => {
												if (this.activeSlideIndex < attrs.slides.length - 1) {
													this.activeSlideIndex++;
												}
											},
										}),
									]),
								]
							),
						]
					);
				})
			),
		]);
	}
}

export default Slider;
