import vwTabToggleColumns from '../modules/_default/tabs/vwTabToggleColumns';
import vwProfileDashboard from '../modules/customerProfiles/components/vwProfileDashboard';

const moduleName = 'categories';
const baseRoute = '/products/categories';
const endpoint = '/masterdata/categories';
// const endpoint = 'http://127.0.0.1:3000/categories';
export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			// {
			// 	slug: 'profile',
			// 	component: vwProfileDashboard
			// },
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
			{
				slug: 'locations',
				viewMode: 'read',
				component: vwTabToggleColumns,
				componentAttrs: {
					endpointData: '/masterdata/locations',
					endpointSubmit: endpoint,
					columns: ['activePos', 'activeKiosk', 'activeWebshop'],
				},
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						formTranslations: 'nameTranslations',
						link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
					// {
					// 	name: 'nameTranslations',
					// 	type: 'object',
					// 	formInputType: 'translation',
					// 	formTranslateField: 'name',
					// 	formShow: true,
					// 	listActiveToggle: true,
					// 	listShow: false,
					// 	listHidden: false, // TODO: Gijs
					// 	maxLength: 255,
					// 	minLength: 1,
					// 	required: true,
					// },
					{
						name: 'activeKiosk',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'activePos',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 3,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'activeWebshop',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 4,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'colorKiosk',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 5,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'colorPos',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 6,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'colorWebshop',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 7,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'colorWebshopSecondary',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 8,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'descriptionLongTranslations',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 9,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'descriptionTranslations',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 10,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						listSize: 'small',
					},
					{
						name: 'imgNormal',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 11,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'imgSvg',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 12,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'imgSvgFallbackItem',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 13,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'imgThumbnail',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 14,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'parent_id', //
						type: 'integer',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 15,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'positionKiosk',
						type: 'integer',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 16,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'positionPos',
						type: 'integer',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 17,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'positionWebshop',
						type: 'integer',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 18,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'viewWebshop',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 19,
						listShow: false,
						listSize: 'small',
						maxLength: 255,
					},
					{
						name: 'archived',
						type: 'boolean',
						defaultValue: false,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						required: true,
					},
				],
			},
		];
	}
}
