[
	{
		"id": "home",
		"label": "Home",
		"route": "",
		"module": "vwHome"
	},
	{
		"id": "reports",
		"label": "Reports",
		"route": "reports",
		"module": false,
		"children": [
			{
				"id": "reportReconciliation",
				"label": "Reconciliation",
				"route": "reconciliation",
				"module": "vwList",
				"config": "reportReconciliation"
			}
		]
	},
	{
		"id": "calendar",
		"label": "Calendar",
		"route": "calendar",
		"module": "vwCalendar"
	},
	{
		"id": "operations",
		"label": "Operations",
		"route": "operations",
		"module": false,
		"children": [
			{
				"id": "projects",
				"label": "Projects",
				"route": "projects",
				"module": "vwList",
				"config": "projects",
				"children": [
					{
						"id": "project",
						"label": "Project",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "projects"
					}
				]
			},
			{
				"id": "assets",
				"label": "Assets",
				"parent": "operations",
				"route": "assets",
				"module": "vwList",
				"config": "assets",
				"children": [
					{
						"id": "asset",
						"label": "asset",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "assets"
					}
				]
			}
		]
	},
	{
		"id": "crm",
		"label": "CRM",
		"route": "crm",
		"module": false,
		"children": [
			{
				"id": "accounts",
				"component": "ACCOUNTS",
				"label": "Accounts",
				"parent": "crm",
				"route": "accounts",
				"module": "vwList",
				"config": "accounts",
				"children": [
					{
						"id": "account",
						"component": "ACCOUNTS",
						"label": "Account",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "accounts"
					}
				]
			},
			{
				"id": "suppliers",
				"label": "Suppliers",
				"parent": "crm",
				"route": "suppliers",
				"module": "vwList",
				"config": "suppliers",
				"children": [
					{
						"id": "supplier",
						"label": "Supplier",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "suppliers"
					}
				]
			},
			{
				"id": "profiles",
				"label": "Customer Profiles",
				"parent": "crm",
				"route": "profiles",
				"module": "vwList",
				"config": "customerProfiles",
				"children": [
					{
						"id": "customerProfile",
						"label": "Customer Profile",
						"hidden": true,
						"route": ":id",
						"module": "vwProfileDashboard"
					}
				]
			}
		]
	},

	{
		"id": "products",
		"label": "Products",
		"route": "products",
		"module": false,
		"children": [
			{
				"id": "menus",
				"label": "Menus",
				"parent": "products",
				"route": "menus",
				"module": "vwList",
				"config": "menus",
				"children": [
					{
						"id": "menu",
						"label": "Menu",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "menus"
					}
				]
			},
			{
				"id": "categories",
				"label": "Categories",
				"parent": "products",
				"route": "categories",
				"module": "vwList",
				"config": "categories",
				"children": [
					{
						"id": "category",
						"label": "Category",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "categories"
					}
				]
			},
			{
				"id": "items",
				"label": "Items",
				"parent": "products",
				"route": "items",
				"module": "vwList",
				"config": "items",
				"children": [
					{
						"id": "item",
						"label": "Item",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "items"
					}
				]
			}
		]
	},

	{
		"id": "masterdata",
		"label": "Masterdata",
		"route": "masterdata",
		"module": false,
		"children": [
			{
				"id": "administrations",
				"label": "Administrations",
				"parent": "masterdata",
				"route": "administrations",
				"module": "vwList",
				"config": "administrations",
				"children": [
					{
						"id": "administration",
						"label": "administration",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "administrations"
					}
				]
			},
			{
				"id": "areas",
				"label": "Areas",
				"parent": "masterdata",
				"route": "areas",
				"module": "vwList",
				"config": "areas",
				"children": [
					{
						"id": "area",
						"label": "area",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "areas"
					}
				]
			},
			{
				"id": "branches",
				"label": "Branches",
				"parent": "products",
				"route": "branches",
				"module": "vwList",
				"config": "branches",
				"children": [
					{
						"id": "branche",
						"label": "Branche",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "branches"
					}
				]
			},
			{
				"id": "cashfunctions",
				"label": "Cashfunctions",
				"parent": "masterdata",
				"route": "cashfunctions",
				"module": "vwList",
				"config": "cashfunctions",
				"children": [
					{
						"id": "cashfunction",
						"label": "cashfunction",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "cashfunctions"
					}
				]
			},
			{
				"id": "clerks",
				"label": "Clerks",
				"parent": "masterdata",
				"route": "clerks",
				"module": "vwList",
				"config": "clerks",
				"children": [
					{
						"id": "clerk",
						"label": "clerk",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "clerks"
					}
				]
			},
			{
				"id": "clients",
				"label": "Clients",
				"parent": "masterdata",
				"route": "clients",
				"module": "vwList",
				"config": "clients",
				"children": [
					{
						"id": "client",
						"label": "client",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "clients"
					}
				]
			},
			{
				"id": "courses",
				"label": "Courses",
				"parent": "masterdata",
				"route": "courses",
				"module": "vwList",
				"config": "courses",
				"children": [
					{
						"id": "course",
						"label": "course",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "courses"
					}
				]
			},
			{
				"id": "displaygroups",
				"label": "Displaygroups",
				"parent": "masterdata",
				"route": "displaygroups",
				"module": "vwList",
				"config": "displaygroups",
				"children": [
					{
						"id": "displaygroup",
						"label": "displaygroup",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "displaygroups"
					}
				]
			},
			{
				"id": "events",
				"label": "Events",
				"parent": "masterdata",
				"route": "events",
				"module": "vwList",
				"config": "events",
				"children": [
					{
						"id": "event",
						"label": "event",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "events"
					}
				]
			},
			{
				"id": "locations",
				"label": "Locations",
				"parent": "products",
				"route": "locations",
				"module": "vwList",
				"config": "locations",
				"children": [
					{
						"id": "location",
						"label": "Location",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "locations"
					}
				]
			},
			{
				"id": "organisations",
				"label": "Organisations",
				"parent": "masterdata",
				"route": "organisations",
				"module": "vwList",
				"config": "organisations",
				"children": [
					{
						"id": "organisation",
						"label": "organisation",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "organisations"
					}
				]
			},
			{
				"id": "paymentmethods",
				"label": "Paymentmethods",
				"parent": "masterdata",
				"route": "paymentmethods",
				"module": "vwList",
				"config": "paymentmethods",
				"children": [
					{
						"id": "paymentmethod",
						"label": "paymentmethod",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "paymentmethods"
					}
				]
			},
			{
				"id": "paymentprofiles",
				"label": "Paymentprofiles",
				"parent": "masterdata",
				"route": "paymentprofiles",
				"module": "vwList",
				"config": "paymentprofiles",
				"children": [
					{
						"id": "paymentprofile",
						"label": "paymentprofile",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "paymentprofiles"
					}
				]
			},
			{
				"id": "paymentserviceproviders",
				"label": "Paymentserviceproviders",
				"parent": "masterdata",
				"route": "paymentserviceproviders",
				"module": "vwList",
				"config": "paymentserviceproviders",
				"children": [
					{
						"id": "paymentserviceprovider",
						"label": "paymentserviceprovider",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "paymentserviceproviders"
					}
				]
			},
			{
				"id": "subtotals",
				"label": "Subtotals",
				"parent": "masterdata",
				"route": "subtotals",
				"module": "vwList",
				"config": "subtotals",
				"children": [
					{
						"id": "subtotal",
						"label": "subtotal",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "subtotals"
					}
				]
			},
			{
				"id": "taxrates",
				"label": "Taxrates",
				"parent": "masterdata",
				"route": "taxrates",
				"module": "vwList",
				"config": "taxrates",
				"children": [
					{
						"id": "taxrate",
						"label": "taxrate",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "taxrates"
					}
				]
			}
		]
	},

	{
		"id": "settings",
		"label": "Settings",
		"route": "settings",
		"module": "vwSettings"
	},

	{
		"id": "admin",
		"label": "Admin",
		"route": "admin",
		"module": false,
		"children": [
			{
				"id": "users",
				"label": "Users",
				"route": "users",
				"module": "vwUsers",
				"children": [
					{
						"id": "user",
						"label": "User",
						"hidden": true,
						"route": ":id",
						"module": "vwUserForm"
					}
				]
			},
			{
				"id": "roles",
				"label": "Roles",
				"route": "roles",
				"module": "vwRoles",
				"children": [
					{
						"id": "role",
						"label": "Role",
						"hidden": true,
						"route": ":id",
						"module": "vwRoleForm"
					}
				]
			},
			{
				"id": "jobs",
				"label": "Jobs",
				"route": "jobs",
				"module": "vwJobs",
				"children": [
					{
						"id": "jobNew",
						"label": "New Job",
						"hidden": true,
						"route": ":id",
						"module": "vwJobNew"
					}
				]
			}
		]
	},

	{
		"id": "masterdata",
		"label": "Masterdata",
		"route": "masterdata",
		"module": false,
		"children": [
			{
				"id": "events",
				"label": "Events",
				"parent": "masterdata",
				"route": "events",
				"module": "vwList",
				"config": "events",
				"children": [
					{
						"id": "event",
						"label": "event",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "events"
					}
				]
			},
			{
				"id": "clerks",
				"label": "Clerks",
				"parent": "masterdata",
				"route": "clerks",
				"module": "vwList",
				"config": "clerks",
				"children": [
					{
						"id": "clerk",
						"label": "clerk",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "clerks"
					}
				]
			},
			{
				"id": "paymentserviceproviders",
				"label": "Paymentserviceproviders",
				"parent": "masterdata",
				"route": "paymentserviceproviders",
				"module": "vwList",
				"config": "paymentserviceproviders",
				"children": [
					{
						"id": "paymentserviceprovider",
						"label": "paymentserviceprovider",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "paymentserviceproviders"
					}
				]
			},
			{
				"id": "departments",
				"label": "Departments",
				"parent": "masterdata",
				"route": "departments",
				"module": "vwList",
				"config": "departments",
				"children": [
					{
						"id": "department",
						"label": "department",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "departments"
					}
				]
			},
			{
				"id": "courses",
				"label": "Courses",
				"parent": "masterdata",
				"route": "courses",
				"module": "vwList",
				"config": "courses",
				"children": [
					{
						"id": "course",
						"label": "course",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "courses"
					}
				]
			},
			{
				"id": "subtotals",
				"label": "Subtotals",
				"parent": "masterdata",
				"route": "subtotals",
				"module": "vwList",
				"config": "subtotals",
				"children": [
					{
						"id": "subtotal",
						"label": "subtotal",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "subtotals"
					}
				]
			},
			{
				"id": "displaygroups",
				"label": "Displaygroups",
				"parent": "masterdata",
				"route": "displaygroups",
				"module": "vwList",
				"config": "displaygroups",
				"children": [
					{
						"id": "displaygroup",
						"label": "displaygroup",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "displaygroups"
					}
				]
			},
			{
				"id": "paymentprofiles",
				"label": "Paymentprofiles",
				"parent": "masterdata",
				"route": "paymentprofiles",
				"module": "vwList",
				"config": "paymentprofiles",
				"children": [
					{
						"id": "paymentprofile",
						"label": "paymentprofile",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "paymentprofiles"
					}
				]
			},
			{
				"id": "taxrates",
				"label": "Taxrates",
				"parent": "masterdata",
				"route": "taxrates",
				"module": "vwList",
				"config": "taxrates",
				"children": [
					{
						"id": "taxrate",
						"label": "taxrate",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "taxrates"
					}
				]
			},
			{
				"id": "organisations",
				"label": "Organisations",
				"parent": "masterdata",
				"route": "organisations",
				"module": "vwList",
				"config": "organisations",
				"children": [
					{
						"id": "organisation",
						"label": "organisation",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "organisations"
					}
				]
			},
			{
				"id": "clients",
				"label": "Clients",
				"parent": "masterdata",
				"route": "clients",
				"module": "vwList",
				"config": "clients",
				"children": [
					{
						"id": "client",
						"label": "client",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "clients"
					}
				]
			},
			{
				"id": "administrations",
				"label": "Administrations",
				"parent": "masterdata",
				"route": "administrations",
				"module": "vwList",
				"config": "administrations",
				"children": [
					{
						"id": "administration",
						"label": "administration",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "administrations"
					}
				]
			},
			{
				"id": "paymentmethods",
				"label": "Paymentmethods",
				"parent": "masterdata",
				"route": "paymentmethods",
				"module": "vwList",
				"config": "paymentmethods",
				"children": [
					{
						"id": "paymentmethod",
						"label": "paymentmethod",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "paymentmethods"
					}
				]
			},
			{
				"id": "cashfunctions",
				"label": "Cashfunctions",
				"parent": "masterdata",
				"route": "cashfunctions",
				"module": "vwList",
				"config": "cashfunctions",
				"children": [
					{
						"id": "cashfunction",
						"label": "cashfunction",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "cashfunctions"
					}
				]
			},
			{
				"id": "areas",
				"label": "Areas",
				"parent": "masterdata",
				"route": "areas",
				"module": "vwList",
				"config": "areas",
				"children": [
					{
						"id": "area",
						"label": "area",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "areas"
					}
				]
			},
			{
				"id": "modifiers",
				"label": "Modifiers",
				"parent": "masterdata",
				"route": "modifiers",
				"module": "vwList",
				"config": "modifiers",
				"children": [
					{
						"id": "modifier",
						"label": "modifier",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "modifiers"
					}
				]
			}
		]
	},
	{
		"id": "transactions",
		"label": "Transactions",
		"route": "transactions",
		"module": false,
		"children": [
			{
				"id": "payments",
				"label": "Payments",
				"parent": "transactions",
				"route": "payments",
				"module": "vwList",
				"config": "payments",
				"children": [
					{
						"id": "payment",
						"label": "payment",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "payments"
					}
				]
			}
		]
	},

	{
		"id": "transactions",
		"label": "Transactions",
		"route": "transactions",
		"module": false,
		"children": [
			{
				"id": "transactions",
				"label": "Transactions",
				"parent": "masterdata",
				"route": "transactions",
				"module": "vwList",
				"config": "transactions",
				"children": [
					{
						"id": "transaction",
						"label": "transaction",
						"hidden": true,
						"route": ":id",
						"module": "vwForm",
						"config": "transactions"
					}
				]
			}
		]
	},

	{
		"id": "settings",
		"label": "Settings",
		"route": "settings",
		"module": "vwSettings"
	},
	{
		"id": "userProfile",
		"label": "Profile",
		"route": "userProfile",
		"module": "vwUserProfile"
	}
]
