import m from 'mithril';
import { Drawer, TabItem, Tabs, Tooltip } from 'construct-ui';
import { _t } from '../../lib/i18n';
import vwBaseRecords from '../../classes/vwBaseRecords';
import sjefButton from '../../views/components/sjefButton';
import sjefTableLayout from '../../views/components/sjefTableLayout';
import sjefIconServerCog from '@sjefapp/sjeficons/icons/sjefIconServerCog';
import sjefTable from '../../views/components/sjefTable';
import app from '../../app';
import sjefIconCheckbox from '@sjefapp/sjeficons/icons/sjefIconCheckbox';
import sjefIconMoodAngry from '@sjefapp/sjeficons/icons/sjefIconMoodAngry';
import tableStore from '../../lib/tableStore';
import sjefIconClock from '@sjefapp/sjeficons/icons/sjefIconClock';
import eventBus from '../../lib/eventBus';

let listDragSort = false;
let listDragSortField = false;

document.addEventListener('keydown', function (event) {
	if (event.key === 'Escape' && listDragSort) {
		listDragSort = false;
		m.redraw();
	}
});

class vwList extends vwBaseRecords {
	previousDragSortField;
	endpointTableLayout = '/user/settings/tableLayout';

	constructor(vnode) {
		super();

		this.config = new vnode.attrs.config(this); // error here means add in index.js
		this.listTabs = this.config.listTabs;

		console.log(this.config)

		this.endpoint = this.config.endpoint;
		this.triggerChildAction = false;

		this.fields = extractFieldsFromSchema(this.config);
	}

	oninit(vnode) {
		console.log('vnode', vnode)

		this.refreshHandler = (data) => {
			console.log('Refresh event received', data);
			// this.loadData();
			this.getRecords();
		};
		eventBus.subscribe('refreshList', this.refreshHandler);
	}

	onremove() {
		eventBus.unsubscribe('refreshList', this.refreshHandler);
	}

	toggleDragSort(field) {
		// todo: when clicking multiple
		listDragSortField = field;
		listDragSort = !listDragSort;
	}

	hasPropertyWithDefaultTrue(obj) {
		console.log(obj);
		return obj.default && obj.default === true;
	}

	/**
	 * Render tabs
	 * @return {Object}
	 */
	renderListTabs(vnode) {
		if (this.listTabs && this.listTabs.length > 0) {
			return m(
				'.subMenuTabs.list',
				m(
					Tabs,
					{
						class: 'list-' + this.listTabs.length + ' active-' + this.listTabIndexActive,
					},
					[
						this.listTabs.map((tab, idx) =>
							m(TabItem, {
								class: 'active-state-elem',
								label: [_t(`modules.${this.config.moduleName}.tabs.${tab.slug}`) || tab.slug],
								active: this.isListTabActive(tab),
								onclick: () => {
									this.setListTabActive(idx);
								},
							})
						),
					]
				)
			);
		}
	}
	triggerAction() {
		this.actionCounter = (this.actionCounter || 0) + 1; // Increment the counter
		m.redraw();
	}

	view(vnode) {
		return m(
			`.${this.config.moduleName}.archive.vw-${this.config.moduleName}`,
			this.fields && this.config
				? m(Drawer, {
					closeOnEscapeKey: true,
					closeOnOutsideClick: true,
					class: 'sjefDrawerlarge',
					content: [
						m(sjefTableLayout, {
							fields: this.fields,
							onModify: async (newFields) => {
								console.log(this.fields);
								this.fields = newFields;

								// todo: return new config for re-render table,
								let result = await app.patch(this.endpointTableLayout, {
									type: this.config.moduleName,
									settings: newFields.reduce(
										(acc, field) => ({
											...acc,
											[field.name]: {
												show: field.listShow,
												position: field.listColumnPosition,
											},
										}),
										{}
									),
								});

								if (result.success) {
									let settingsResult = await app.get('/user/settings');

									app.userSettings = settingsResult.data;

									this.fields = extractFieldsFromSchema(this.config);

									this.triggerAction(vnode);

									console.log(this.fields);
								}

								m.redraw();
							},
							onCloseDrawer: () => (this.drawerOpen = false),
						}),
					],
					hasBackdrop: true,
					transition: 300,
					position: 'right',
					isOpen: this.drawerOpen,
					onClose: () => (this.drawerOpen = false),
				})
				: void 0,
			m(
				'.sjefBreadcrumbWrapper'
			),


			// iconHover
			m(
				'.flexer.end',
				m('',
					m('h1', _t(`modules.${this.config.moduleName}.title`)),
					this.renderListTabs(),
				),
				m('.actions.flexer', [

					this.fields && this.config
						? m(Tooltip, {
							content: 'Table layout',
							position: 'top-end',
							hasArrow: false,
							trigger: [
								m(
									'.iconHover',
									m(sjefButton, {
										buttonLayout: 'empty',
										label: m(sjefIconServerCog),
										onclick: () => (this.drawerOpen = true),
									})
								),
							]
						})
						: void 0,
					this.config.listButtons?.map(function (mComponent) {
						return m(mComponent.component, mComponent.componentAttrs);
					}),
					this.config.actions.canDelete()
						?
						[
							m(sjefButton, {
								buttonLayout: 'ghost',
								label: _t('_.buttons.archive'),
								disabled: !tableStore.selectedRecords().length,
								onclick: () => this.archiveRecords(),
							}),
							m(sjefButton, {
								buttonLayout: 'ghost',
								label: _t('_.buttons.delete'),
								disabled: !tableStore.selectedRecords().length,
								onclick: () => this.deleteRecords(),
							}),
							// 	),
							// }),
						]
						: void 0,
					this.config.actions.canCreate() &&
					m(sjefButton, {
						buttonLayout: 'filled',
						label: 'New',
						onclick: () => m.route.set(`${this.config.route}/new`),
					}),
				])
			),
			this.listTabActive.component
				? m(this.listTabActive.component, {
					...this.listTabActive.componentAttrs,
				})
				: m(
					'',
					[
						m(sjefTable, {
							endpoint: this.endpoint,
							config: this.config.moduleName,
							actionCounter: this.actionCounter, // Pass the counter to the child
							fields: this.fields,
						}),
					]
				)
		);
	}
}

const replaceDynamicLink = (link, record) => {
	return link.replace(/:(\w+)/g, (match, paramName) => {
		return record[paramName];
	});
};
function extractFieldsFromSchema(config) {
	if (!config || !Array.isArray(config.schema)) {
		return [];
	}

	const moduleSettings = ((app.userSettings || {}).tableLayouts || {})[config.moduleName] || {};

	return config.schema.flatMap((group) =>
		(group.fields || []).map((field) => {
			const fieldSettings = moduleSettings[field.name];
			if (fieldSettings) {
				return {
					...field,
					listShow: fieldSettings.show,
					listColumnPosition: fieldSettings.position,
				};
			}
			return field;
		})
	);
}

export default vwList;
