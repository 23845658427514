import sjefButton from '../views/components/sjefButton';
import app from '../app';

const moduleName = 'administrations';
const baseRoute = '/administrations';
const endpoint = '/clients/administrations';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];

		this.formButtons = [
			{
				component: sjefButton,
				componentAttrs: {
					label: 'Open administration',
					buttonLayout: 'neutral',
					onclick: async () => {
						await app.switchAccessLevel(6, 'administration', { aid: this.view.record.id });
					},
				}
			}
		];

		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: true,
						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 2,
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						// link: `${baseRoute}/:id`,
						// onClickAction: async (recordId) => {
						// 	await app.switchAccessLevel(6, 'administration', { aid: recordId });
						// },
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						listSize: 'small',
						maxLength: 255,
						minLength: 1,
						required: true,
					},
				],
			},
		];
	}
}
