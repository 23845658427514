import app from "../app";
import tableStore from "../lib/tableStore";
import sjefButton from "../views/components/sjefButton";
import m from 'mithril';
import sjefModal from "../views/components/sjefModal";
import { _t } from "../lib/i18n";
import { Form } from "construct-ui";
import sjefInput from "../views/components/sjefInput";
import sjefIconLocationBroken from "@sjefapp/sjeficons/icons/sjefIconLocationBroken";

let lockerForm = {
	lockerId: "",
	type: "",
	description: "",
	notes: "",
};
let lockerFormError = '';

const moduleName = 'lockers';
const baseRoute = '/lockermanagement/lockers';
const endpoint = '/lockermanagement/lockers';
// const endpoint = 'http://127.0.0.1:3000/areas';

export default class Config {
	resetLockerForm = () => {
		lockerForm = {
			lockerId: "",
			type: "",
			description: "",
			notes: "",
		};	
	}

	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => false,
			canDelete: () => false,
		};

		this.listButtons = [
			{
				component: sjefButton,
				componentAttrs:{
					// disabled: tableStore.selectedRecords()?.length === 0,
					label: _t('_.buttons.activate'),
					buttonLayout: 'ghost',
					onclick: async () => {
						let activateResult = await app.post('/lockermanagement/lockers/activate', {
							lockerIds: tableStore.selectedRecords(),
						});

						if (activateResult.success) {
							app.toast('success');
						}

						m.redraw()
					},
				}
			},
			{
				component: sjefButton,
				componentAttrs: {
					// disabled: tableStore.selectedRecords()?.length === 0,
					label: _t('_.buttons.deactivate'),
					buttonLayout: 'ghost',
					onclick: async () => {
						let deactivateResult = await app.post('/lockermanagement/lockers/deactivate', {
							lockerIds: tableStore.selectedRecords(),
						});

						if (deactivateResult.success) {
							app.toast('success');
						}

						m.redraw();
					},
				}
			}
		],

		this.formDropDownButtons = [
			{
				component: sjefModal,
				componentAttrs: (record) => ({
					buttonLayout: 'ghost',
					onOpen: () => {
						lockerForm.lockerId = record.id;
					},
					buttonLabel: [ m(sjefIconLocationBroken), m('label', _t('_.buttons.maintenance'))],
					modalTitle: _t('_.labels.maintenance'),
					buttonConfirm: _t('_.buttons.setMaintenance'),
					extraButtons: [
						m(sjefButton, {
							label: 'test label',
							onclick: () => {
								
							}
						})
					],
					modalContent: [ 
						m('p', `Set locker ${record.lockerNumber} to maintenance `),
						m(Form, 
							m(sjefInput, {
								label: _t('_.fields.type'),
								type: 'select',
								name: 'type',
								value: lockerForm.type,
								options: [
									'',
									'DD - doordraaier',
									'B - Batterij'
								],
								onChange: (value) => {
									lockerForm.type = value;
								}
							}),
							m(sjefInput, {
								type: 'text',
								label: _t('_.fields.description'),
								name: 'description',
								value: lockerForm.description,
								onChange: (value) => {
									lockerForm.description = value;
								}
							}),
							m(sjefInput, {
								type: 'text',
								label: _t('_.fields.notes'),
								name: 'notes',
								value: lockerForm.notes,
								onChange: (value) => {
									lockerForm.notes = value;
								}
							})
						),
						lockerFormError &&
						m('p.error', lockerFormError)
					],
					onSubmit: async () => {
						let maintenanceResult = await app.post('/lockermanagement/maintenance', lockerForm)

						if (maintenanceResult.success) {
							lockerFormError = '';
							app.toast('success');
							this.resetLockerForm();
						} else {
							lockerFormError = maintenanceResult.message;
						}

						m.redraw();
					},
				}),
			},
			// {
			// 	component: sjefButton,
			// 	componentAttrs: {
			// 		label: 'Open administration',
			// 		buttonLayout: 'neutral',
			// 		onclick: async () => {
			// 			// console.log('test')
			// 		},
			// 	}
			// },
		];
		// this.buttons = [
		// 	{
		// 		component: sjefButton,
		// 		componentAttrs: {
		// 			label: 'Activate',
		// 			buttonLayout: 'ghost',
		// 			// disabled: !tableStore.selectedRecords().length,
		// 			onclick: async () =>  {
		// 				let activateResult = await app.post('/lockermanagement/lockers/activate', {
		// 					lockerIds: tableStore.selectedRecords()
		// 				})

		// 				if (activateResult.success) {
		// 					tableStore.selectedRecords([])
		// 				}
		// 			}
		// 		},
		// 	},
		// 	{
		// 		component: sjefButton,
		// 		componentAttrs: {
		// 			label: 'Deactivate',
		// 			buttonLayout: 'ghost',
		// 			// disabled: !tableStore.selectedRecords().length,
		// 			onclick: async () =>  {
		// 				let deactivateResult =  await app.post('/lockermanagement/lockers/deactivate', {
		// 					lockerIds: tableStore.selectedRecords()
		// 				})

		// 				if (deactivateResult.success) {
		// 					tableStore.selectedRecords([])
		// 				}
		// 			}
		// 		},
		// 	},
		// ];
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'uuid',
						type: 'string',
						formInputType: false,
						formShow: false,
						link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						filter: false,
						listSize: 'small',
					},
					{
						name: 'cabinetId',
						type: 'integer',
						formInputType: false,
						formShow: true,
						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
					},
					{
						name: 'lockerNumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						popoverFilterType: 'range',

						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
					},
					{
						name: 'serialNumber',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
					},
					{
						name: 'type',
						type: 'string',
						formInputType: 'select',
						formShow: true,
						advancedFilterType: 'select',
						advancedFilterOptions: ['medium', 'large'],
						popoverFilterType: 'select',
						popoverFilterOptions: ['medium', 'large'],
						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
					},
					{
						name: 'active',
						type: 'boolean',
						popoverFilterType: 'select',
						popoverFilterOptions: [true, false],
						defaultValue: true,
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 2,
						listShow: true,
						required: true,
						onChange: async (recordId, value) => {
							if (recordId) {
								let recordResult;
								if (value === true) {
									recordResult = await app.post(endpoint + '/activate',
										{
											lockerIds: [recordId]
										});
								} else {
									recordResult = await app.post(endpoint + '/deactivate',
										{
											lockerIds: [recordId]
										});
								}

								console.log(recordResult)

								if (recordResult.success) {
									app.toast('success');
								} else {
									app.toast('problem');
								}
							}
						}
					},
					{
						name: 'lockVersion',
						type: 'string',
						formInputType: true,
						formShow: true,
						advancedFilterType: 'select',
						advancedFilterOptions: ['2', '5', '6'],
						popoverFilterType: 'select',
						popoverFilterOptions: ['2', '5', '6'],
						// link: `${baseRoute}/:id`,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						// listEdit: true,
					},
					{
						name: 'color',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: false,
						listColumnPosition: 1,
						listShow: true,
						advancedFilterType: 'select',
						advancedFilterOptions: ['PINK', 'BLUE'],
						popoverFilterType: 'select',
						popoverFilterOptions: ['PINK', 'BLUE'],
					},
				],
			},
		];
	}
}
