const moduleName = 'cabinets';
const baseRoute = '/lockermanagement/cabinets';
const endpoint = '/lockermanagement/cabinets';
// const endpoint = 'http://127.0.0.1:3000/areas';

export default class Config {
	constructor(view) {
		this.view = view;
		this.moduleName = moduleName;
		this.route = baseRoute;
		this.endpoint = endpoint;
		this.actions = {
			canCreate: () => true,
			canUpdate: () => true,
			canDelete: () => true,
		};
		this.tabs = [
			{
				default: true,
				slug: 'details',
				viewMode: 'edit',
			},
		];
		this.schema = [
			{
				groupName: 'General',
				class: 'column',
				fields: [
					{
						name: 'id',
						type: 'integer',
						formInputType: false,
						formShow: false,
						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'clientId',
						type: 'integer',
						formInputType: false,
						formShow: false,
						// link: `${baseRoute}/:id`,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
						listSize: 'small',
					},
					{
						name: 'name',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
					},
					{
						name: 'supplier',
						type: 'string',
						formInputType: 'text',
						formShow: true,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: true,
					},
					{
						name: 'color',
						type: 'string',
						formInputType: 'text',
						formShow: false,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
						advancedFilterType: 'select',
						advancedFilterOptions: ['PINK', 'BLUE'],
					},
					{
						name: 'lockVersion',
						type: 'integer',
						formInputType: 'number',
						formShow: true,
						listActiveToggle: true,
						listColumnPosition: 1,
						listShow: true,
						advancedFilterType: 'select',
						advancedFilterOptions: ['2', '5', '6'],
					},
					{
						name: 'active',
						type: 'boolean',
						formInputType: 'checkbox',
						formShow: true,
						listActiveToggle: false,
						listColumnPosition: 0,
						listShow: false,
					},
				],
			},
		];
	}
}
