import m from 'mithril';
import vwUserForm from './UserForm';
import sjefButton from '../../views/components/sjefButton';
import sjefIconArrowLeft from '@sjefapp/sjeficons/icons/sjefIconArrowLeft';

const vwUser = {
	view: ({ attrs }) => {
		return m('.user', [

			// m('.id', `ID: ${attrs.id}`),
			// attrs.id === 0 ? m('', 'New user') : m('', 'Existing user'),
			m(vwUserForm, { user: attrs.user }),
		]);
	},
};

export default vwUser;
