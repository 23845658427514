{
	"_": {
		"actions": {
			"save": "Save"
		},
		"buttons": {
			"activate": "Activate",
			"activateAdyen": "Activate Adyen",
			"applyFilters": "Apply Filters",
			"archive": "Archive",
			"autoTranslate": "Auto translate",
			"backToList": "Go back",
			"cancel": "Cancel",
			"clearFilters": "Clear Filters",
			"close": "Close",
			"complete": "Complete",
			"copy": "Copy",
			"create": "Create",
			"deactivate": "Deactivate",
			"delete": "Delete",
			"discard": "Discard",
			"download": "Download",
			"edit": "Edit",
			"export": "Export",
			"finish": "Finish",
			"generateTickets": "Generate",
			"maintenance": "Maintenance",
			"manage": "Manage",
			"new": "New",
			"rollback": "Rollback",
			"save": "Save",
			"saveAndClose": "Save & Close",
			"setMaintenance": "Set maintenance",
			"switch": "Leave"
		},
		"fields": {
			"${field}": "",
			"account": "Account",
			"accountId": "Account Id",
			"active": "Active",
			"activeInShop": "Active In Shop",
			"activeKiosk": "Kiosk Active",
			"activePos": "Pos Active",
			"activeWebshop": "Webshop Active",
			"address": "Address",
			"address2": "Address 2",
			"adyen": {
				"balanceAccounts": "Balance Accounts",
				"businessLineId": "Business Line ID",
				"businessLines": "Business Lines",
				"canDoPayouts": "Can do payouts",
				"canReceiveFromPlatformPayments": "Can receive from platform payments",
				"canReceivePayments": "Can receive payments",
				"capabilities": "Capabilities",
				"clientConfig": "Client Configs",
				"clientMutations": "Mutations",
				"merchantId": "Merchant ID",
				"mutations": {
					"amount": "Amount",
					"balanceAccount": "Balance Account",
					"currency": "Currency",
					"description": "Description",
					"reference": "Reference",
					"title": "Add mutation",
					"type": "Type"
				},
				"receiveFromBalanceAccount": "Receive from balance account",
				"receiveFromPlatformPayments": "Receive from platform payments",
				"receivePayments": "Receive payments",
				"sendToBalanceAccount": "Send to balance accounts",
				"sendToTransferInstrument": "Send to transfer instrument",
				"shopperStatement": "Shopper Statement",
				"status": "Status",
				"storeId": "Store ID",
				"stores": "Stores"
			},
			"adyenCompanyAccount": "Adyen Company Account",
			"adyenEcomStoreId": "AdyenEcom Store Id",
			"adyenEcomStoreName": "AdyenEcom Store Name",
			"adyenMerchantAccount": "Adyen Merchant Account",
			"adyenPosStoreId": "AdyenPos Store Id",
			"adyenPosStoreName": "AdyenPos Store Name",
			"allowNegativepayment": "Allow Negative Payment",
			"allowOverpayment": "Allow Overpayment",
			"allowSplitpayment": "Allow split payment",
			"allowZeropayment": "Allow Zero Payment",
			"allowed": "Allowed",
			"allowedAreas": "Allowed Areas",
			"amount": "Amount",
			"amountAvailable": "Available",
			"amountCommission": "Commission",
			"amountCommissionMarkup": "Commission Markup",
			"amountSplit": "Split",
			"amountTotal": "Total",
			"and": "and",
			"apiKey": "API Key",
			"archived": "Archived",
			"area": "Area",
			"areaId": "Area ID",
			"askforAccount": "Ask for Account",
			"balanceAccountId": "balanceAccountId",
			"balancedue": "Balance Due",
			"balanceoverview": {
				"EUR": "EUR",
				"GBP": "GBP",
				"USD": "USD"
			},
			"balances": "Balances",
			"barcode": "Barcode",
			"bic": "BIC",
			"branchId": "Branch ID",
			"cabinetId": "Cabinet ID",
			"cabinetNumber": "Cabinet Number",
			"cancelledAt": "Cancelled At",
			"cashfunctionId": "Cashfunction Id",
			"categories": "Categories",
			"city": "City",
			"clerk": "Clerk",
			"clerkId": "Clerk Id",
			"client": "Client",
			"clientCodeKantoorLegacy": "Client Code KantoorLegacy",
			"clientId": "Client Id",
			"clientKey": "Client Key",
			"code": "Code",
			"coinAmount": "Coin Amount",
			"coinButtonValues": "Coin Button Values",
			"coinLabel": "Coin Label",
			"coinSmallestSize": "Coin Smallest Size",
			"coinValue": "Coin Value",
			"color": "Color",
			"colorKiosk": "Kiosk Color",
			"colorPos": "Pos Color",
			"colorWebshop": "Webshop Color",
			"colorWebshopSecondary": "Webshop Secondary Color",
			"companyNumber": "Company Number",
			"completedAt": "Completed At",
			"countries": "Countries",
			"country": "Country",
			"courseId": "Course Id",
			"create": "Create",
			"createAllocation": "Create allocation",
			"createdAt": "Created At",
			"currencies": "Currencies",
			"currency": "Currency",
			"customerCity": "Customer City",
			"customerCountry": "Customer Country",
			"customerEmail": "Customer Email",
			"customerHousenumber": "Customer House Number",
			"customerId": "Customer Id",
			"customerName": "Customer Name",
			"customerPhoneNumber": "Customer Phone Number",
			"customerPhoneNumberCountryCode": "Customer Phone Number Country Code",
			"customerStreetname": "Customer Street Name",
			"customerZipcode": "Customer Zip Code",
			"date": "Date",
			"days": "Days",
			"daysAgo": "days ago",
			"departmentId": "Department Id",
			"description": "Description",
			"descriptionLongTranslations": "Long Description Translations",
			"descriptionTranslations": "Description Translations",
			"direction": "Direction",
			"discountAmount": "Discount Amount",
			"discountId": "Discount Id",
			"displayName": "Display Name",
			"displaygroupId": "Display Group Id",
			"domestic/foreign": {
                "domestic": "Domestic",
                "foreign": "Foreign"
            },
			"edit": "Edit",
			"electronicShelfLabel": "Electronic Shelf Label",
			"elockersActive": "Elockers Active",
			"elockersType": "Elockers Type",
			"email": "Email",
			"emailInvoice": "Email invoice",
			"emailKyc": "Email KYC",
			"enabled": "Enabled",
			"end": "End",
			"environment": "Environment",
			"estimated Arrival Time": "Estimated Arrival Time",
			"estimatedArrivalTime": "Estimated Arrival Time",
			"eventActivateAt": "Event activate at",
			"eventDeactivateAt": "Event deactivate at",
			"eventEnd": "Event end",
			"eventLasts": "Event lasts",
			"eventStart": "Event start",
			"external_identifier": "External identifier",
			"fulfillment": "Fulfillment",
			"generalledgerAccount": "Generalledger Account",
			"hours": "Hours",
			"hubspotId": "Hubspot Id",
			"iban": "Iban",
			"id": "Id",
			"imgKioskNormal": "Kiosk Img Normal",
			"imgKioskThumbnail": "Kiosk Img Thumbnail",
			"imgNormal": "Img Normal",
			"imgPosNormal": "Pos Img Normal",
			"imgPosThumbnail": "Pos Img Thumbnail",
			"imgSvg": "Img SVG",
			"imgSvgFallbackItem": "Img SVG Fallback Item",
			"imgThumbnail": "Img Thumbnail",
			"imgWebshopNormal": "Webshop Img Normal",
			"imgWebshopThumbnail": "Webshop Img Thumbnail",
			"inAt": "In at",
			"inBy": "In by",
			"industryCode": "Industry Code",
			"invoiceAddress": "Invoice Address",
			"invoiceAddress2": "Invoice Address 2",
			"invoiceCity": "Invoice City",
			"invoiceCountry": "Invoice Country",
			"invoiceName": "Invoice Name",
			"invoicePostalCode": "Invoice Postal Code",
			"invoiceState": "Invoice State",
			"isBackorderItem": "Is Backorder Item",
			"isCashcardItem": "Is Cashcard Item",
			"isCoinPayment": "Is Coin Payment",
			"isCoins": "Is Coins",
			"isDefault": "Is Default",
			"isHiddenOnReceipt": "Is Hidden On Receipt",
			"isOrderItem": "Is Order Item",
			"isParentLeading": "Is Parent Leading",
			"isPlu": "Is Plu",
			"isPreferredPayment": "Is Preferred Payment",
			"isStockItem": "Is Stock Item",
			"isTip": "Is Tip",
			"isUniqueItem": "Is Unique Item",
			"isVoucherPayment": "Is Voucher Payment",
			"isWallet": "Is Wallet",
			"isWalletPayment": "Is Wallet Payment",
			"large": "Large",
			"latitude": "Latitude",
			"legalEntityId": "Legal Entity ID",
			"locale": "Longitude",
			"location": "Location",
			"locationId": "Location Id",
			"locationName": "Location Name",
			"lockVersion": "Lock Version",
			"lockerId": "Locker ID",
			"lockerMode": "Locker mode",
			"lockerNumber": "Locker Number",
			"lockerSerialNumber": "Locker Serial Number",
			"lockerType": "Locker Type",
			"logout": "Logout",
			"longitude": "Longitude",
			"manufacturer": "Manufacturer",
			"masBasket": "Max Basket",
			"medium": "Medium",
			"merchantAccount": "Merchant Account",
			"merchantName": "Merchant Name",
			"merchantReference": "Merchant Reference",
			"metaBrand": "Meta Brand",
			"metaCategory": "Meta Category",
			"metaManufacturer": "Meta Manufacturer",
			"metaName": "Meta Name",
			"metaSubcategory": "Meta Subcategory",
			"metaSupplier": "Meta Supplier",
			"metaUnitOfPacking": "Meta Unit Of Packing",
			"metaUnitOfSale": "Meta Unit Of Sale",
			"metaVolumM1": "Meta Volume M1",
			"modeLocker": "Lockermode",
			"modeStartHour": "Start Hour",
			"modeTimeslot": "Timeslot",
			"modeValidHours": "Valid Hours",
			"model": "Model",
			"name": "Name",
			"nameId": "Name ID",
			"nameKioskTranslations": "Kiosk Name Translations",
			"nameKitchen": "Name Kitchen",
			"namePosTranslations": "Pos Name Translations",
			"nameTranslations": "Name Translations",
			"nameWebshopTranslations": "Webshop Name Translations",
			"new": "New",
			"next": "Next",
			"noResults": "No Results",
			"notes": "Notes",
			"open": "Open",
			"openAll": "Open All",
			"outAt": "Out at",
			"outBy": "Out by",
			"parentId": "Parent Id",
			"parent_id": "Parent Id",
			"paymentMethods": "Payment Methods",
			"paymentmethod": "Payment Method",
			"paymentmethodId": "Payment Method Id",
			"paymentprofileId": "Payment Profile Id",
			"paymentserviceproviderId": "Payment Service Provider Id",
			"paymentserviceproviderMethod": "Payment Service Provider Method",
			"payoutId": "payoutId",
			"percentage": "Percentage",
			"periodType": "periodType",
			"periodValue": "periodValue",
			"phoneNumber": "Phone Number",
			"phoneNumberCountryCode": "Phone Number Country Code",
			"pincode": "Pincode",
			"placeholders": {
				"active": "Active",
				"address": "",
				"address2": "",
				"adyenBusinessLines": "",
				"city": "",
				"companyNumber": "Company Number",
				"country": "",
				"email": "Email",
				"emailInvoice": "Email",
				"emailKyc": "Email",
				"hubspotId": "Hubspot ID",
				"id": "ID",
				"industryCode": "Industry Code",
				"invoiceAddress": "Invoice Address",
				"invoiceAddress2": "Invoice Address 2",
				"invoiceCity": "Invoice City",
				"invoiceCountry": "Invoice Country",
				"invoiceName": "Invoice Name",
				"invoicePostalCode": "Invoice Postal Code",
				"invoiceState": "Invoice State",
				"merchantId": "",
				"name": "Name",
				"nameId": "Name ID",
				"phoneNumber": "Phone Number",
				"phoneNumberCountryCode": "+ 316",
				"planId": "Select one of the plans",
				"region": "Select one of the regions",
				"role": "Role",
				"shopperStatement": "Shopper statement",
				"taxNumber": "Tax Number",
				"uuid": "UUID",
				"visitAddress": "Address",
				"visitAddress2": "Address 2",
				"visitCity": "City",
				"visitCountry": "Country",
				"visitName": "Name",
				"visitPostalCode": "Postal Code",
				"visitState": "State",
				"wefactId": "WeFact ID",
				"zipcode": ""
			},
			"planId": "Plan Id",
			"posAdminOnly": "Pos Admin Only",
			"posColor": "Pos Color",
			"posOpendrawer": "Pos Open Drawer",
			"posOpenprice": "Pos Open Price",
			"posPrintreceipt": "Pos Print Receipt",
			"posSearchable": "Pos Searchable",
			"posVisible": "Pos Visible",
			"position": "Position",
			"positionKiosk": "Kiosk Position",
			"positionKitchen": "Kitchen Position",
			"positionPos": "Pos Position",
			"positionWebshop": "Webshop Position",
			"preprint": "Preprint",
			"previous": "Previous",
			"price": "Price",
			"priceCosts": "Price Costs",
			"priceRetail": "Price Retail",
			"problems": "Problems",
			"processed At": "Processed At",
			"processedAt": "Processed At",
			"profileId": "Profile Id",
			"projectEnd": "Project End",
			"projectName": "Project Name",
			"projectStart": "Project Start",
			"pspEftReceipt": "Print Eft Receipt",
			"pspMethod": "PSP Method",
			"pspName": "PSP Name",
			"pspPayload": "PSP Payload",
			"pspResponse": "PSP Response",
			"pspVariant": "PSP Variant",
			"quantityMaximum": "Maximum Quantity",
			"quantityMinimum": "Minimum Quantity",
			"reason": "Reason",
			"redirectUrl": "Redirect URL",
			"reference": "Reference",
			"region": "region",
			"requested": "Requested",
			"requiredAccount": "Account Required",
			"requiredBookingnumber": "Booking Number Required",
			"reserve": "Reserve",
			"saleschannels": "Sales Channels",
			"selected": "Selected",
			"sequence": "sequence",
			"serialNumber": "Serial Number",
			"serialnumber": "Serial Number",
			"service": "Service",
			"settlementId": "settlementId",
			"shopSelection": "Shop Selection",
			"shopperStatement": "shopperStatement",
			"sjefId": "Sjef Id",
			"slug": "Slug",
			"source": "Source",
			"stack": "Stack",
			"start": "",
			"status": "Status",
			"statusAssets": "Assets Status",
			"subtotal": "Subtotal",
			"subtotalId": "Subtotal Id",
			"supplier": "Supplier",
			"table": "Table",
			"tableId": "Table Id",
			"taxNumber": "Tax Number",
			"taxes": "Taxes",
			"taxrateId": "Taxrate Id",
			"terminalSequence": "Terminal Sequence",
			"ticketeer": "Ticketeer",
			"tickets_c": "Color",
			"tickets_lid": "Locker ID",
			"tickets_lno": "Locker Number",
			"tickets_lpin": "Locker Pin",
			"tickets_lt": "Type",
			"tickets_tid": "Ticket ID",
			"tickets_w": "Wave",
			"timeWindow": "Time Window",
			"timestamp": "Timestamp",
			"timezone": "Timezone",
			"today": "Today",
			"token": "Token",
			"tokenAmount": "Token Amount",
			"tokenDescription": "Token Description",
			"total": "Total",
			"totalCoins": "Total Coins",
			"translations": "Translations",
			"type": "Type",
			"uniqueItemType": "Unique Item Type",
			"uniqueItemTypePrintjobs": "Unique Item Type Print Jobs",
			"updatedAt": "Updated At",
			"uuid": "UUID",
			"verificationStatus": "Verification Status",
			"videoWebshop": "Webshop Video",
			"viewWebshop": "Webshop View",
			"visitAddress": "Address",
			"visitAddress2": "Address 2",
			"visitCity": "City",
			"visitCountry": "Country",
			"visitName": "Name",
			"visitPostalCode": "Postal Code",
			"visitState": "State",
			"wave": "Wave",
			"webdata": "",
			"wefactId": "WeFact Id",
			"yesterday": "Yesterday",
			"zipcode": "Zipcode"
		},
		"filterTabs": {
			"defaultTabs": "Default",
			"savedTabs": "Saved"
		},
		"labels": {
			"active": "Active",
			"allocations": "Allocations",
			"area": "Area",
			"areaReport": "Area Report",
			"cabinets": "Cabinets",
			"checklist": "Checklist",
			"createAllocation": "Create allocation",
			"createArea": "Create area",
			"createClientSubtitle": "Fill in the required fields,",
			"createEvent": "Create event",
			"createLocation": "Create Location",
			"deleteArea": "Delete area",
			"deleteLocation": "Delete locations",
			"editAllocation": "Edit allocation",
			"editClientSubtitle": "Edit your client details",
			"editLocation": "Edit location",
			"event": "Event",
			"eventEnd": "Event end",
			"eventName": "Event name",
			"eventStart": "Event start",
			"eventTitle": "",
			"eventType": "",
			"filters": "Filters",
			"generalInfo": "General info",
			"invite": "",
			"locationName": "",
			"locations": "Locations",
			"logout": {
				"confirmContent": "Are you sure you want to logout?",
				"confirmTitle": "Confirm logout"
			},
			"maintenance": "Maintenance",
			"maintenanceCancel": "Cancel maintenance",
			"maintenanceCancelContent": "Are you sure you want to cancel the maintenance?",
			"maintenanceComplete": "Complete maintenance",
			"maintenanceCompleteContent": "Are you sure you want to complete the maintenance?",
			"noAreas": "No areas",
			"openOrganisation": "Open organisation",
			"report": "Report",
			"switch": {
				"confirmContent": "Are you sure you want to leave {{name}}?",
				"confirmTitle": "Switch"
			},
			"tickets": "Tickets",
			"ticketsGenerated": "Tickets generated"
		},
		"messages": {
			"notAvailable": ""
		},
		"modals": {
			"cancelDeleteButton": "Cancel",
			"confirmCancelButton": "Confirm",
			"confirmCancelContent": "Are you sure you want to cancel?",
			"confirmCancelTitle": "Confirm Cancellation",
			"confirmDeleteButton": "Delete",
			"confirmDeleteContent": "Are you sure you want to delete?",
			"confirmDeleteTitle": "Confirm Deletion",
			"reload": {
				"description": "Do you want to reload?",
				"title": "Reload Page"
			}
		},
		"months": {
			"april": "April",
			"august": "August",
			"december": "December",
			"february": "February",
			"january": "January",
			"july": "July",
			"june": "June",
			"march": "March",
			"may": "May",
			"november": "November",
			"october": "October",
			"september": "September"
		},
		"records": {
			"noRecordsAvailable": "No records available"
		},
		"search": {
			"search": "Search..."
		},
		"singular": {
			"accounts": "Account",
			"administrations": "Administration",
			"areas": "Area",
			"assets": "Asset",
			"balance_mutations": "",
			"branches": "Branch",
			"cabinets": "Cabinet",
			"cashfunctions": "Cashfunction",
			"categories": "Category",
			"clerks": "Clerk",
			"clients": "Client",
			"courses": "Course",
			"departments": "Department",
			"displaygroups": "Displaygroup",
			"events": "Event",
			"items": "Item",
			"locations": "Location",
			"lockerEvents": "Locker Event",
			"lockers": "Locker",
			"maintenance": "Maintenance",
			"menus": "Menu",
			"modifiers": "Modifier",
			"organisations": "Organisation",
			"paymentmethods": "Paymentmethod",
			"paymentprofiles": "Paymentprofile",
			"payments": "Payment",
			"paymentserviceproviders": "Paymentserviceprovider",
			"payouts": "Payout",
			"projects": "Project",
			"psps": "Psp",
			"settlements": "Settlement",
			"subtotals": "Subtotal",
			"suppliers": "Supplier",
			"taxrates": "Taxrate",
			"tickets": "Ticket",
			"transactions": "Transaction"
		},
		"th": {
			"name": "Name"
		}
	},
	"_fields": {
		"name": "Name"
	},
	"clients": {
		"clientConfig": {
			"balanceAccountId": "Balance Account ID",
			"merchantAccount": "Merchant Account",
			"modal": {
				"edit": {
					"button": "Edit",
					"title": "Edit the client configuration"
				},
				"new": {
					"button": "New",
					"title": "Add a new client configuration"
				}
			},
			"paymentConfigId": "Payment config ID",
			"region": "Region",
			"storeId": "Store ID",
			"type": "Type"
		},
		"clientInvite": {
			"actions": {
				"block": "Block the invitation",
				"resend": "Resend the invitation",
				"title": "Actions"
			},
			"createdAt": "Created at",
			"createdBy": "Created by",
			"description": "Create an invitation for the client",
			"email": "Email",
			"expiresAt": "Expires at",
			"status": "Status",
			"title": "Send invitation",
			"toast": {
				"blocked": "Invitation is blocked",
				"sent": "Invitation is send"
			}
		},
		"fields": {
			"hubspotId": "HubSpotID",
			"nameId": "nameID",
			"wefactId": "WeFactID"
		},
		"invoices": {
			"subtitle": "",
			"table": {
				"th": {
					"amountExcl": "Amount Exc.",
					"amountIncl": "Amount Inc.",
					"amountOutstanding": "Amount Outstanding",
					"code": "Code",
					"date": "Date",
					"status": "Status"
				}
			},
			"title": ""
		},
		"pricequotes": {
			"subtitle": "",
			"title": ""
		},
		"tabs": {
			"adyen": "Adyen",
			"details": "Details",
			"invoices": "Invoices"
		}
	},
	"countries": {
		"AD": "Andorra",
		"AE": "United Arab Emirates",
		"AF": "Afghanistan",
		"AG": "Antigua and Barbuda",
		"AI": "Anguilla",
		"AL": "Albania",
		"AM": "Armenia",
		"AN": "Netherlands Antilles",
		"AO": "Angola",
		"AQ": "Antarctica",
		"AR": "Argentina",
		"AS": "American Samoa",
		"AT": "Austria",
		"AU": "Australia",
		"AW": "Aruba",
		"AX": "Åland Islands",
		"AZ": "Azerbaijan",
		"BA": "Bosnia and Herzegovina",
		"BB": "Barbados",
		"BD": "Bangladesh",
		"BE": "Belgium",
		"BF": "Burkina Faso",
		"BG": "Bulgaria",
		"BH": "Bahrain",
		"BI": "Burundi",
		"BJ": "Benin",
		"BM": "Bermuda",
		"BN": "Brunei Darussalam",
		"BO": "Bolivia",
		"BR": "Brazil",
		"BS": "Bahamas",
		"BT": "Bhutan",
		"BV": "Bouvet Island",
		"BW": "Botswana",
		"BY": "Belarus",
		"BZ": "Belize",
		"CA": "Canada",
		"CC": "Cocos (Keeling) Islands",
		"CD": "Congo, The Democratic Republic of the",
		"CF": "Central African Republic",
		"CG": "Congo",
		"CH": "Switzerland",
		"CK": "Cook Islands",
		"CL": "Chile",
		"CM": "Cameroon",
		"CN": "China",
		"CO": "Colombia",
		"CR": "Costa Rica",
		"CS": "Serbia and Montenegro",
		"CU": "Cuba",
		"CV": "Cape Verde",
		"CX": "Christmas Island",
		"CY": "Cyprus",
		"CZ": "Czech Republic",
		"DE": "Germany",
		"DJ": "Djibouti",
		"DK": "Denmark",
		"DM": "Dominica",
		"DO": "Dominican Republic",
		"DZ": "Algeria",
		"EC": "Ecuador",
		"EE": "Estonia",
		"EG": "Egypt",
		"EH": "Western Sahara",
		"ER": "Eritrea",
		"ES": "Spain",
		"ET": "Ethiopia",
		"FI": "Finland",
		"FJ": "Fiji",
		"FK": "Falkland Islands (Malvinas)",
		"FM": "Micronesia, Federated States of",
		"FO": "Faroe Islands",
		"FR": "France",
		"GA": "Gabon",
		"GB": "United Kingdom",
		"GD": "Grenada",
		"GE": "Georgia",
		"GF": "French Guiana",
		"GG": "Guernsey",
		"GH": "Ghana",
		"GI": "Gibraltar",
		"GL": "Greenland",
		"GM": "Gambia",
		"GN": "Guinea",
		"GP": "Guadeloupe",
		"GQ": "Equatorial Guinea",
		"GR": "Greece",
		"GS": "South Georgia and the South Sandwich Islands",
		"GT": "Guatemala",
		"GU": "Guam",
		"GW": "Guinea-Bissau",
		"GY": "Guyana",
		"HK": "Hong Kong",
		"HM": "Heard Island and McDonald Islands",
		"HN": "Honduras",
		"HR": "Croatia",
		"HT": "Haiti",
		"HU": "Hungary",
		"ID": "Indonesia",
		"IE": "Ireland",
		"IL": "Israel",
		"IM": "Isle of Man",
		"IN": "India",
		"IO": "British Indian Ocean Territory",
		"IQ": "Iraq",
		"IR": "Iran, Islamic Republic of",
		"IS": "Iceland",
		"IT": "Italy",
		"JE": "Jersey",
		"JM": "Jamaica",
		"JO": "Jordan",
		"JP": "Japan",
		"KE": "Kenya",
		"KG": "Kyrgyzstan",
		"KH": "Cambodia",
		"KI": "Kiribati",
		"KM": "Comoros",
		"KN": "Saint Kitts and Nevis",
		"KR": "Korea, Republic of",
		"KW": "Kuwait",
		"KY": "Cayman Islands",
		"KZ": "Kazakhstan",
		"LB": "Lebanon",
		"LC": "Saint Lucia",
		"LI": "Liechtenstein",
		"LK": "Sri Lanka",
		"LR": "Liberia",
		"LS": "Lesotho",
		"LT": "Lithuania",
		"LU": "Luxembourg",
		"LV": "Latvia",
		"LY": "Libya",
		"MA": "Morocco",
		"MC": "Monaco",
		"MD": "Moldova, Republic of",
		"MG": "Madagascar",
		"MH": "Marshall Islands",
		"MK": "North Macedonia",
		"ML": "Mali",
		"MM": "Myanmar",
		"MN": "Mongolia",
		"MO": "Macao",
		"MP": "Northern Mariana Islands",
		"MQ": "Martinique",
		"MR": "Mauritania",
		"MS": "Montserrat",
		"MT": "Malta",
		"MU": "Mauritius",
		"MV": "Maldives",
		"MW": "Malawi",
		"MX": "Mexico",
		"MY": "Malaysia",
		"MZ": "Mozambique",
		"NA": "Namibia",
		"NC": "New Caledonia",
		"NE": "Niger",
		"NF": "Norfolk Island",
		"NG": "Nigeria",
		"NI": "Nicaragua",
		"NL": "Netherlands",
		"NO": "Norway",
		"NP": "Nepal",
		"NR": "Nauru",
		"NU": "Niue",
		"NZ": "New Zealand",
		"OM": "Oman",
		"PA": "Panama",
		"PE": "Peru",
		"PF": "French Polynesia",
		"PG": "Papua New Guinea",
		"PH": "Philippines",
		"PK": "Pakistan",
		"PL": "Poland",
		"PM": "Saint Pierre and Miquelon",
		"PN": "Pitcairn",
		"PR": "Puerto Rico",
		"PS": "Palestine, State of",
		"PT": "Portugal",
		"PW": "Palau",
		"PY": "Paraguay",
		"QA": "Qatar",
		"RE": "Réunion",
		"RO": "Romania",
		"RU": "Russian Federation",
		"RW": "Rwanda",
		"SA": "Saudi Arabia",
		"SB": "Solomon Islands",
		"SC": "Seychelles",
		"SD": "Sudan",
		"SE": "Sweden",
		"SG": "Singapore",
		"SH": "Saint Helena, Ascension and Tristan da Cunha",
		"SI": "Slovenia",
		"SJ": "Svalbard and Jan Mayen",
		"SK": "Slovakia",
		"SL": "Sierra Leone",
		"SM": "San Marino",
		"SN": "Senegal",
		"SO": "Somalia",
		"SR": "Suriname",
		"ST": "Sao Tome and Principe",
		"SV": "El Salvador",
		"SY": "Syrian Arab Republic",
		"SZ": "Eswatini",
		"TC": "Turks and Caicos Islands",
		"TD": "Chad",
		"TF": "French Southern Territories",
		"TG": "Togo",
		"TH": "Thailand",
		"TJ": "Tajikistan",
		"TK": "Tokelau",
		"TL": "Timor-Leste",
		"TM": "Turkmenistan",
		"TN": "Tunisia",
		"TO": "Tonga",
		"TR": "Turkey",
		"TT": "Trinidad and Tobago",
		"TV": "Tuvalu",
		"TW": "Taiwan",
		"TZ": "Tanzania, United Republic of",
		"UA": "Ukraine",
		"UG": "Uganda",
		"UM": "United States Minor Outlying Islands",
		"US": "United States",
		"UY": "Uruguay",
		"UZ": "Uzbekistan",
		"VA": "Holy See (Vatican City State)",
		"VC": "Saint Vincent and the Grenadines",
		"VE": "Venezuela, Bolivarian Republic of",
		"VG": "Virgin Islands, British",
		"VI": "Virgin Islands, U.S.",
		"VN": "Viet Nam",
		"VU": "Vanuatu",
		"WF": "Wallis and Futuna",
		"WS": "Samoa",
		"YE": "Yemen",
		"YT": "Mayotte",
		"ZA": "South Africa",
		"ZM": "Zambia",
		"ZW": ""
	},
	"modules": {
		"accounts": {
			"tabs": {
				"details": "Details",
				"example": "Example"
			},
			"title": "Accounts"
		},
		"administrations": {
			"tabs": {
				"details": "Details"
			},
			"title": "Administrations"
		},
		"areas": {
			"tabs": {
				"details": "Details"
			},
			"title": "Areas"
		},
		"assets": {
			"tabs": {
				"assets": "Overview",
				"details": "Details",
				"list": "Assets"
			},
			"title": "Assets"
		},
		"balance_mutations": {
			"tabs": {
				"details": ""
			},
			"title": ""
		},
		"balanceoverview": {
			"account_balance": "Account Balance",
			"continue_modal": {
				"button": "Continue",
				"description": "Everything is set! Just one last step to go. Please click the button below to continue.",
			},
			"edit_balance_account": "Edit Balance Account",
			"intro": {
				"paragraph1": "For Sjef to process payments on your company’s behalf, we are required by law to verify our clients’ identities. Please initiate the Know Your Client (KYC) onboarding process by clicking the button below.",
				"paragraph2": "Ensure that all information in the KYC form is filled out accurately. It is important that every letter matches your official documentation, and no nicknames or abbreviations are used. Thank you for your attention to detail.",
				"paragraph3": "Once onboarded, you can enjoy the full capabilities of the Sjef Balance platform. Accept all major e-commerce and in-person payment methods, manage your bank accounts, and take control of your payouts.",
				"subtitle": "Know Your Client",
				"title": "You're not activated yet on our Balance Platform!"
			},
			"recent_mutations": "",
			"recent_payouts": "Recent Payouts",
			"redirect_modal": {
				"description": "We're serious about this: all information must exactly match your bank statement, passport, and company information. If it is not filled in perfectly, we will not be able to approve your application and process payments."
			},
			"widgets": {
				"authorised": "Authorised",
				"availableForPayout": "Available for payout",
				"invoiced": "Invoiced",
				"payout": "Payout",
				"sentForSettle": "Sent for settle",
				"settled": "Settled"
			}
		},
		"branches": {
			"tabs": {
				"details": "Details"
			},
			"title": "Branches"
		},
		"cabinets": {
			"tabs": {
				"details": "Details"
			},
			"title": "Cabinets"
		},
		"cashfunctions": {
			"tabs": {
				"details": "Details"
			},
			"title": "Cash Functions"
		},
		"categories": {
			"tabs": {
				"details": "Details",
				"locations": "Locations",
				"profile": "Profile"
			},
			"title": "Categories"
		},
		"clerks": {
			"tabs": {
				"allowedAreas": "Allowed Areas",
				"details": "Details"
			},
			"title": "Clerks"
		},
		"clients": {
			"tabs": {
				"adyen": "Banking",
				"details": "Details",
				"invites": "",
				"paymentScheme": "Payment Scheme",
				"wefactInvoices": "Invoices",
				"wefactPricequotes": "Price Quotes"
			},
			"title": "Clients"
		},
		"courses": {
			"tabs": {
				"details": "Details"
			},
			"title": "Courses"
		},
		"departments": {
			"tabs": {
				"details": "Details"
			},
			"title": "Departments"
		},
		"displaygroups": {
			"tabs": {
				"details": "Details"
			},
			"title": "Display Groups"
		},
		"events": {
			"tabs": {
				"details": "Details"
			},
			"title": "Events"
		},
		"home": {
			"balanceplatform": {
				"button": "Go to the Balance Platform",
				"paragraph1": "For Sjef to process payments on your company’s behalf, we are required by law to verify our clients’ identities.",
				"paragraph2": "Once onboarded, you can enjoy the full capabilities of the Sjef Balance platform. Accept all major e-commerce and in-person payment methods, manage your bank accounts, and take control of your payouts.",
				"paragraph3": "",
				"subtitle": "Get ready with the Balance Platform"
			},
			"greeting": {
				"goodafternoon": "Good afternoon",
				"goodevening": "Good evening",
				"goodmorning": "Good morning"
			},
			"intro": {
				"paragraph1": "Welcome at Sjef!",
				"paragraph2": ""
			},
			"transactions": {
				"title": "Transactions"
			},
			"turnover": {
				"title": "Turnover"
			}
		},
		"items": {
			"tabs": {
				"categories": "Categories",
				"details": "Details",
				"modifiers": "Modifiers"
			},
			"title": "Items"
		},
		"locations": {
			"tabs": {
				"details": "Details"
			},
			"title": "Locations"
		},
		"lockerEvents": {
			"tabs": {
				"details": "Details"
			},
			"title": "Locker Events"
		},
		"lockers": {
			"tabs": {
				"details": "Details"
			},
			"title": "Lockers"
		},
		"maintenance": {
			"tabs": {
				"details": "Details"
			},
			"title": "Maintenance"
		},
		"menus": {
			"tabs": {
				"details": "Details",
				"items": "Items"
			},
			"title": "Menus"
		},
		"modifiers": {
			"tabs": {
				"details": "Details",
				"items": "Items"
			},
			"title": "Modifiers"
		},
		"organisations": {
			"tabs": {
				"details": "Details"
			},
			"title": "Organisations"
		},
		"paymentmethods": {
			"tabs": {
				"details": "Details"
			},
			"title": "Payment Methods"
		},
		"paymentprofiles": {
			"tabs": {
				"details": "Details",
				"paymentMethods": "Payment Methods"
			},
			"title": "Payment Profiles"
		},
		"payments": {
			"tabs": {
				"details": "Details"
			},
			"title": "Payments"
		},
		"paymentserviceproviders": {
			"tabs": {
				"details": "Details"
			},
			"title": "Payment Service Providers"
		},
		"payouts": {
			"title": "Payouts"
		},
		"projects": {
			"tabs": {
				"assets": "Assets",
				"details": "Details",
				"payments": "Payments"
			},
			"title": "Projects"
		},
		"psps": {
			"tabs": {
				"details": "Details"
			},
			"title": "PSPs"
		},
		"settlements": {
			"tabs": {
				"details": "Details"
			},
			"title": "Settlements"
		},
		"subtotals": {
			"tabs": {
				"details": "Details"
			},
			"title": "Subtotals"
		},
		"suppliers": {
			"tabs": {
				"details": "Details",
				"example": "Example"
			},
			"title": "Suppliers"
		},
		"taxrates": {
			"tabs": {
				"details": "Details"
			},
			"title": "Tax Rates"
		},
		"tickets": {
			"tabs": {
				"details": "Details"
			},
			"title": "Tickets"
		},
		"transactions": {
			"tabs": {
				"details": "Details",
				"lines&payments": "Lines & Payments"
			},
			"title": "Transactions"
		}
	},
	"reconciliation": {
		"new": "New Reconciliation"
	},
	"schema": {
		"tiers": {
			"quantity": "Threshold"
		}
	}
}
