/**
 * Library for data validation
 * @module validation
 */

const Ajv = require('ajv');
const ajv = new Ajv();

/**
 * Validate data
 *
 * @param {Object} validationSchema - JSON schema for data validation
 * @param {Object} data - data to be validated
 * @returns {Object} - Object indicating whether the data is valid or not, along with any errors if invalid
 */
const validate = (validationSchema, data) => {
    const validator = ajv.compile(validationSchema);
    const isValid = validator(data);

    return {
        valid: isValid,
        errors: isValid ? null : validator.errors,
    };
};

module.exports = {
    validate,
};
