import sjefIconCheck from '@sjefapp/sjeficons/icons/sjefIconCheck';
import m from 'mithril';

class sjefCheckbox {
	oninit(vnode) {
		this.checked = vnode.attrs.checked;
	}

	onbeforeupdate(vnode, old) {
		if (vnode.attrs.checked !== this.checked) {
			this.checked = vnode.attrs.checked;
		}
	}

	view(vnode) {
		// Construct class string, including additional classes passed via vnode.attrs.class
		const classes = ['sjefCheckboxOuter', vnode.attrs.class].filter(Boolean).join(' ');


		return [
			m(
				'.sjefCheckboxFlexer',
				{
					class: vnode.attrs.disabled ? 'disabled' : '',
					onclick: () => {
						this.checked = !this.checked;

						if (typeof vnode.attrs.onChange === 'function') {
							vnode.attrs.onChange(this.checked);
						}
					},
				},
				[
					m(
						'.sjefCheckboxOuter.active-state-elem',
						{
							class: classes,
						},
						m(
							'.sjefCheckbox',
							{
								class: this.checked ? 'checked' : '',
							},
							m(sjefIconCheck)
						)
					),
				],
				vnode.attrs.label ? m('label.sjefCheckboxLabel', vnode.attrs.label) : void 0,

			),
		];
	}
}

export default sjefCheckbox;
