import { Grid, Input, Tooltip } from 'construct-ui';
import m from 'mithril';
import app from '../app';
import sideMenu from './components/sideMenu';
import topMenu from './components/topMenu';
import bottomMenu from './components/bottomMenu';
import sjefBlur from './components/sjefBlur';
import reloadModal from './components/reloadModal';
import sjefIconSettings from '@sjefapp/sjeficons/icons/sjefIconSettings';
import sjefMenuTree from './components/sjefMenuTree';
import sjefIconSearch from '@sjefapp/sjeficons/icons/sjefIconSearch';
import sjefIconCamera from '@sjefapp/sjeficons/icons/sjefIconCamera';
import sjefModal from './components/sjefModal';
import sjefInput from './components/sjefInput';
import sjefIconChessKing from '@sjefapp/sjeficons/icons/sjefIconChessKing';
import sjefIconThumbUp from '@sjefapp/sjeficons/icons/sjefIconThumbUp';
import sjefIconGardenCart from '@sjefapp/sjeficons/icons/sjefIconGardenCart';
import { initRoutes } from '../routes';
import sjefIconDoor from '@sjefapp/sjeficons/icons/sjefIconDoor';
import sjefIconCash from '@sjefapp/sjeficons/icons/sjefIconCash';
import FeatureFlags, { bottomMenuFlag, menutreeFlag, elockersFlag } from '../featureFlags';

const logoSrc = new URL('../assets/img/sjef_logo.png', import.meta.url).href;
const activeMenu = null;
const topValue = 0;
let mode = app.restore('mode');
const mobileMenuOpen = false;
let navigationFavourites;

const menuTree = {
	organisations: [
		{
			id: 3,
			name: 'Barproducties',
			clients: [
				{
					id: 3,
					name: 'Barproducties Client',
					organisationId: 3,
					administrations: [],
				},
			],
		},
		{
			id: 2,
			name: 'Elockers',
			clients: [
				{
					id: 2,
					name: 'Klassiek Client',
					organisationId: 2,
					administrations: [],
				},
			],
		},
		{
			id: 1,
			name: 'Sjef Group1',
			clients: [
				{
					id: 1,
					name: 'E-defix',
					organisationId: 1,
					administrations: [
						{
							id: 1,
							name: 'Aviato',
							clientId: 1,
						},
						{
							id: 2,
							name: 'Liquicity 2023',
							clientId: 1,
						},
						{
							id: 3,
							name: 'Awakenings',
							clientId: 1,
						},
						{
							id: 4,
							name: 'Defqon.1',
							clientId: 1,
						},
						{
							id: 5,
							name: 'De Amsterdamse Zomer 2023',
							clientId: 1,
						},
					],
				},
				{
					id: 39,
					name: 'Sjef Group 001',
					organisationId: 1,
					administrations: [],
				},
				{
					id: 48,
					name: 'E-defix',
					organisationId: 1,
					administrations: [],
				},
				{
					id: 56,
					name: 'E-defix',
					organisationId: 1,
					administrations: [],
				},
			],
		},
	],
	clients: [],
	administrations: [],
};

const layout = {
	oncreate: () => {
		const containers = document.querySelectorAll('.js-scroller');
		containers.forEach((container) => {
			container.addEventListener('scroll', () => {
				const layout = document.getElementById('layout');
				if (container.scrollTop >= 250) {
					layout.classList.add('offset-scrolled');
				} else {
					layout.classList.remove('offset-scrolled');
					document.querySelector('.topMenu').classList.remove('js-scroll-active');
					document.querySelector('.topMenu').classList.add('js-scroll-inactive');
				}
			});
		});
	},

	view: ({ attrs, children }) => {
		console.log();
		return m(
			Grid,
			{
				id: 'layout',
				class: 'layout',
				'data-mode': mode,
			},
			[
				m(app.toaster, {
					position: 'top',
				}),

				m(sjefBlur),

				// SIDEMENU COLUMN
				app.events.reloadRequired ? m(reloadModal, { isOpen: true }) : void 0,
				m(
					'.menuFlexer',
					m(
						'.colLeftMenu',
						m(
							'.leftMenuOptions',
							m(
								'.topOptions',
								m(
									'.optionWrapper',
									{
										class: app.activeTab == 'sjef' ? 'active' : '',
									},
									m(Tooltip, {
										content: 'Sjef',
										position: 'right',
										hasArrow: false,
										trigger: m(sjefIconCash, {
											onclick: () => {
												(app.activeTab = 'sjef'), app.store('activeTab', 'sjef');
												initRoutes(app.lvl);
											},
										}),
									})
								),
								FeatureFlags.isEnabled(elockersFlag) &&
									m(
										'.optionWrapper',
										{
											class: app.activeTab == 'elockers' ? 'active' : '',
										},
										m(Tooltip, {
											content: 'Elockers',
											position: 'right',
											hasArrow: false,
											trigger: m(sjefIconDoor, {
												onclick: () => {
													(app.activeTab = 'elockers'), app.store('activeTab', 'elockers');
													initRoutes(app.lvl);
												},
											}),
										})
									),
								FeatureFlags.isEnabled(menutreeFlag) &&
									m(
										'.optionWrapper',

										m(Tooltip, {
											content: 'Impression',
											position: 'right',
											hasArrow: false,
											trigger: m(sjefMenuTree, {
												menuTree: menuTree,
											}),
										})
									)
								// m(
								// 	'.optionWrapper',
								// 	m(Tooltip, {
								// 		content: 'Chess',
								// 		position: 'right',
								// 		hasArrow: false,
								// 		trigger: m(sjefIconChessKing),
								// 	})
								// ),
								// m(
								// 	'.optionWrapper',
								// 	m(Tooltip, {
								// 		content: 'Impression',
								// 		position: 'right',
								// 		hasArrow: false,
								// 		trigger: m(sjefIconThumbUp),
								// 	})
								// ),
							),
							FeatureFlags.isEnabled(bottomMenuFlag) &&
								m(
									'.bottomOptions',
									m(
										'.optionWrapper',
										m(Tooltip, {
											content: 'Search',
											position: 'right',
											hasArrow: false,
											trigger: m(
												'',
												m(sjefModal, {
													buttonLabel: m(sjefIconSearch),
													footer: false,
													modalTitle: 'Search',
													modalContent: m(sjefInput, {
														class: 'masterSearch',
														placeholder: 'Atomic search',
														icon: m(sjefIconSearch),
														name: 'atomicsearch',
														type: 'text',
													}),
												})
											),
										})
									),
									m(
										'.optionWrapper',
										m(Tooltip, {
											content: 'Settings',
											position: 'right',
											hasArrow: false,
											trigger: m(sjefIconSettings, {
												onclick: () => {
													m.route.set('/settings');
												},
											}),
										})
									)
								)
						),
						m(
							'.leftMenuInner',
							m(
								'.leftMenuAbsolute',
								m(
									'.sjefLogoWrapper',
									m(
										'a',
										{ onclick: () => m.route.set('/') },
										m('img.sjefLogo', {
											src: logoSrc,
										})
									)
								),

								// favourites
								// m('.favouritesWrapper',
								// 	m(Tree, {
								// 		class: 'favourites menu',
								// 		interactive: true,
								// 		nodes: [
								// 			navigationFavourites.map((item) => {
								// 				// console.log(app.menu);
								// 				console.log(item);
								// 				const route = findMenuItemById(mockMenu, item)?.route;
								// 				if (route) {
								// 					return m(TreeNode,{
								// 						contentLeft: m(sjefIconStar, {
								// 							class: 'favourite'
								// 						}),
								// 						class: 'main iconElement',
								// 						label: item,
								// 						onclick: () => {
								// 							m.route.set(route)
								// 						}
								// 					})
								// 				}
								// 			})
								// 		]
								// 	})
								// ),
								m(sideMenu, attrs)
							)
						)
					),
					// MAIN COLUMN
					m(
						'.colMain.js-scroller',
						// TOPMENU COLUMN FULL WIDTH
						m(topMenu, {
							onModeChange: (newMode) => {
								mode = newMode;
							},
						}),
						m('.container', children),
						m(bottomMenu)
					)
				),
				m('.menuCloser', {
					onclick: () => {
						document.body.classList.toggle('mobile-menu-open');
					},
				}),
			]
		);
	},
};

export default layout;
